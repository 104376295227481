var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Modal } from 'antd';
import "../../assets/css/Dentobook.css";
import "../../assets/css/PatientPhotos.css";
import { FormattedMessage } from "react-intl";
import '../../assets/css/PatientCreateModal.css';
var SignatureClientErrorModal = function (_a) {
    var isModalOpen = _a.isModalOpen, setIsModalOpen = _a.setIsModalOpen;
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    var footer = _jsx("div", __assign({ className: "modal-btn-wrapper" }, { children: _jsx(Button, __assign({ className: "modal-btn text-blue font-weight-500 modal-btn-full-width", onClick: handleCancel, style: { color: '' } }, { children: _jsx(FormattedMessage, { id: "close" }) })) }));
    return _jsx(Modal, __assign({ transitionName: "", className: "dentobook-modal dentobook-small-modal", width: 570, cancelButtonProps: {
            className: "modal-btn"
        }, footer: footer, open: isModalOpen, onCancel: handleCancel }, { children: _jsxs("div", __assign({ className: "signature-error mb-8" }, { children: [_jsx("p", __assign({ className: "font-weight-500 font-24 font-family-roboto text-align-center" }, { children: _jsx(FormattedMessage, { id: "signature_client_not_found" }) })), _jsxs("p", __assign({ className: "font-weight-500 font-24 font-family-roboto text-align-center" }, { children: [_jsx(FormattedMessage, { id: "signature_client_start" }), " ", _jsx("a", __assign({ className: "text-underline", href: "https://dentobook.com/esign_app/", target: "_blank" }, { children: _jsx(FormattedMessage, { id: "here_msg" }) })), "."] }))] })) }));
};
export default SignatureClientErrorModal;
