import { LOCALES } from "../i18n/locales";
import { accessibilityRoles } from "./accessUtils";
export var menuItems = [
    {
        name: 'dashboard',
        path: '/',
        notAccessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR],
    },
    {
        name: 'users',
        path: '/users',
        accessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR],
    },
    {
        name: 'register',
        path: '/register-new-user',
        accessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR],
    },
    {
        name: 'components',
        path: '/components',
        accessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR],
    },
    {
        name: 'patients',
        path: '/patients',
        notAccessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR, accessibilityRoles.PATIENT]
    },
    {
        name: 'medical_staff',
        path: '/medical/staff',
        accessibleFor: [accessibilityRoles.CLINIC_ADMIN, accessibilityRoles.RECEPTIONIST]
    },
    {
        name: 'calendar',
        path: '/calendar',
        notAccessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR, accessibilityRoles.PATIENT]
    },
    {
        name: 'clinics',
        path: '/organizations',
        accessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR]
    },
    {
        name: 'dental_clinic',
        path: '/clinic',
        accessibleFor: [accessibilityRoles.CLINIC_ADMIN]
    },
    {
        name: 'activity_log',
        path: '/activity-log',
        accessibleFor: [accessibilityRoles.CLINIC_ADMIN]
    },
    {
        name: 'nzis',
        path: '/nzis',
        accessibleFor: [accessibilityRoles.CLINIC_ADMIN],
    },
    {
        name: 'settings',
        path: '/settings',
        bottom: true,
        accessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR],
    },
];
export var langItems = [
    {
        label: 'BG',
        value: LOCALES.BULGARIAN
    },
    {
        label: 'EN',
        value: LOCALES.ENGLISH
    }
];
