var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { DentobookApplicationContext } from "../../context/DentobookApplicationContext";
import { OrganizationApiFactory } from "dentobook-api-typescript-client/api";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { FormattedMessage } from "react-intl";
import { isMobileOnly } from "react-device-detect";
import DoctorImg from "../../assets/doctor.svg";
import moment from "moment";
var DashboardWelcomeCard = function () {
    var _a, _b, _c, _d;
    var daysOfWeek = [
        _jsx(FormattedMessage, { id: "sunday" }, "sunday"),
        _jsx(FormattedMessage, { id: "monday" }, "monday"),
        _jsx(FormattedMessage, { id: "tuesday" }, "tuesday"),
        _jsx(FormattedMessage, { id: "wednesday" }, "wednesday"),
        _jsx(FormattedMessage, { id: "thursday" }, "thursday"),
        _jsx(FormattedMessage, { id: "friday" }, "friday"),
        _jsx(FormattedMessage, { id: "saturday" }, "saturday"),
    ];
    var apiConfig = new Configuration({ basePath: '/api' });
    var organizationApi = OrganizationApiFactory(apiConfig, '/api');
    var principal = React.useContext(DentobookApplicationContext).principal;
    var _e = useState({}), organization = _e[0], setOrganization = _e[1];
    useEffect(function () {
        var _a;
        organizationApi.fetchOrganization((_a = principal === null || principal === void 0 ? void 0 : principal.user) === null || _a === void 0 ? void 0 : _a.organizationId)
            .then(function (response) {
            // @ts-ignore
            setOrganization(response.data);
        })
            .catch(function (error) {
            console.error("Failed to load organization:", error);
            console.log(error);
        });
    }, []);
    if (isMobileOnly) {
        return (_jsxs(Card, __assign({ bordered: true, bodyStyle: { padding: '0' }, style: { height: 278 }, className: "dentobook-dashboard-card dentobook-dashboard-welcome-card" }, { children: [_jsx("div", { children: _jsxs("h3", { children: [_jsx(FormattedMessage, { id: "welcome" }), ", ", _jsxs("strong", { children: [((_a = organization.name) === null || _a === void 0 ? void 0 : _a.substring(0, 40)) + (((_b = organization.name) === null || _b === void 0 ? void 0 : _b.length) > 40 ? '...' : ''), "!"] })] }) }), _jsxs("div", { children: [_jsxs("div", __assign({ style: { float: 'left', width: '49%' } }, { children: [_jsx("h2", __assign({ className: "dentobook-dashboard-time" }, { children: moment(new Date()).format("HH:mm") })), _jsxs("h3", { children: [daysOfWeek[(new Date()).getDay()], ", ", moment(new Date()).format("DD.MM.YYYY")] })] })), _jsx("div", __assign({ style: { float: 'right', width: '49%', height: '100%', marginTop: 20 } }, { children: _jsx("img", { alt: "doctor", src: DoctorImg, height: 120 }) }))] })] })));
    }
    else {
        return (_jsxs(Card, __assign({ bordered: true, style: { height: 270 }, bodyStyle: { padding: '0' }, className: "dentobook-dashboard-card dentobook-dashboard-welcome-card" }, { children: [_jsxs("div", __assign({ style: { float: 'left' } }, { children: [_jsxs("span", __assign({ className: "font-34" }, { children: [_jsx(FormattedMessage, { id: "welcome" }), ", ", _jsx("strong", { children: ((_c = organization.name) === null || _c === void 0 ? void 0 : _c.substring(0, 30)) + (((_d = organization.name) === null || _d === void 0 ? void 0 : _d.length) > 30 ? '...' : '!') })] })), _jsxs("div", __assign({ style: { position: 'absolute', bottom: 10 } }, { children: [_jsx("h1", __assign({ className: "dentobook-dashboard-time" }, { children: moment(new Date()).format("HH:mm") })), _jsxs("h3", __assign({ style: { marginBottom: '16px', marginTop: '12px' } }, { children: [daysOfWeek[(new Date()).getDay()], ", ", moment(new Date()).format("DD.MM.YYYY")] }))] }))] })), _jsx("div", __assign({ style: { float: 'right', marginTop: -20 } }, { children: _jsx("img", { alt: "doctor", src: DoctorImg }) }))] })));
    }
};
export default DashboardWelcomeCard;
