var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import '../../assets/css/Dentobook.css';
import FormButtons from "../common/FormButtons";
import { Button } from "antd";
import { isMobileOnly } from "react-device-detect";
import { FormattedMessage } from "react-intl";
var OrganizationDetailsView = function (_a) {
    var _b;
    var organization = _a.organization, setEditMode = _a.setEditMode;
    return (_jsxs("div", { children: [_jsx("h3", __assign({ className: "dentobook-content font-weight-500 text-align-center" }, { children: _jsx(FormattedMessage, { id: "org_details" }) })), _jsxs("p", { children: ["Name: ", organization.name || _jsx(FormattedMessage, { id: "no_data" })] }), _jsxs("p", { children: ["Email: ", organization.email || _jsx(FormattedMessage, { id: "no_data" })] }), _jsxs("p", { children: ["Phone: ", organization.phone || _jsx(FormattedMessage, { id: "no_data" })] }), _jsxs("p", { children: ["Address: ", organization.address || _jsx(FormattedMessage, { id: "no_data" })] }), _jsxs("p", { children: ["Country: ", organization.country || _jsx(FormattedMessage, { id: "no_data" })] }), _jsxs("p", { children: ["Town: ", organization.town || _jsx(FormattedMessage, { id: "no_data" })] }), _jsxs("p", { children: ["Parent organization name: ", ((_b = organization.parent) === null || _b === void 0 ? void 0 : _b.name) || _jsx(FormattedMessage, { id: "no_data" })] }), _jsx(FormButtons, { children: _jsx(Button, __assign({ onClick: function () { return setEditMode(true); }, className: "edit-btn " + (isMobileOnly ? "mobile-update-btn" : "dentobook-btn-primary"), type: "primary", size: "large", shape: "round" }, { children: _jsx(FormattedMessage, { id: "edit" }) })) })] }));
};
export default OrganizationDetailsView;
