const ekatteKeys = {
    '20482': 'гр. Девня, общ. Девня, обл. Варна',
    '10118': 'с. Вардим, общ. Свищов, обл. Велико Търново',
    '72624': 'гр. Добрич, общ. Добрич, обл. Добрич',
    '14461': 'с. Бов, общ. Своге, обл. София област',
    '02676': 'гр. Банско, общ. Банско, обл. Благоевград',
    '21498': 'гр. Добринище, общ. Банско, обл. Благоевград',
    '17381': 'с. Гостун, общ. Банско, обл. Благоевград',
    '39614': 'с. Кремен, общ. Банско, обл. Благоевград',
    '47857': 'с. Места, общ. Банско, обл. Благоевград',
    '53059': 'с. Обидим, общ. Банско, обл. Благоевград',
    '54136': 'с. Осеново, общ. Банско, обл. Благоевград',
    '76090': 'с. Филипово, общ. Банско, обл. Благоевград',
    '03504': 'гр. Белица, общ. Белица, обл. Благоевград',
    '02107': 'с. Бабяк, общ. Белица, обл. Благоевград',
    '77596': 'с. Горно Краище, общ. Белица, обл. Благоевград',
    '15638': 'с. Гълъбово, общ. Белица, обл. Благоевград',
    '20064': 'с. Дагоново, общ. Белица, обл. Благоевград',
    '14521': 'с. Златарица, общ. Белица, обл. Благоевград',
    '39270': 'с. Краище, общ. Белица, обл. Благоевград',
    '40453': 'с. Кузьово, общ. Белица, обл. Благоевград',
    '44762': 'с. Лютово, общ. Белица, обл. Благоевград',
    '53998': 'с. Орцево, общ. Белица, обл. Благоевград',
    '59330': 'с. Палатик, общ. Белица, обл. Благоевград',
    '80649': 'с. Черешово, общ. Белица, обл. Благоевград',
    '04279': 'гр. Благоевград, общ. Благоевград, обл. Благоевград',
    '03664': 'с. Бело поле, общ. Благоевград, обл. Благоевград',
    '04217': 'с. Бистрица, общ. Благоевград, обл. Благоевград',
    '07168': 'с. Бучино, общ. Благоевград, обл. Благоевград',
    '07377': 'с. Българчево, общ. Благоевград, обл. Благоевград',
    '14204': 'с. Габрово, общ. Благоевград, обл. Благоевград',
    '17004': 'с. Горно Хърсово, общ. Благоевград, обл. Благоевград',
    '20328': 'с. Дебочица, общ. Благоевград, обл. Благоевград',
    '20540': 'с. Делвино, общ. Благоевград, обл. Благоевград',
    '23652': 'с. Дренково, общ. Благоевград, обл. Благоевград',
    '24367': 'с. Дъбрава, общ. Благоевград, обл. Благоевград',
    '27231': 'с. Еленово, общ. Благоевград, обл. Благоевград',
    '30702': 'с. Зелендол, общ. Благоевград, обл. Благоевград',
    '00134': 'с. Изгрев, общ. Благоевград, обл. Благоевград',
    '37263': 'с. Клисура, общ. Благоевград, обл. Благоевград',
    '43520': 'с. Лешко, общ. Благоевград, обл. Благоевград',
    '43863': 'с. Лисия, общ. Благоевград, обл. Благоевград',
    '68792': 'с. Логодаж, общ. Благоевград, обл. Благоевград',
    '47370': 'с. Марулево, общ. Благоевград, обл. Благоевград',
    '49179': 'с. Мощанец, общ. Благоевград, обл. Благоевград',
    '53031': 'с. Обел, общ. Благоевград, обл. Благоевград',
    '55107': 'с. Падеш, общ. Благоевград, обл. Благоевград',
    '57159': 'с. Покровник, общ. Благоевград, обл. Благоевград',
    '62699': 'с. Рилци, общ. Благоевград, обл. Благоевград',
    '66055': 'с. Селище, общ. Благоевград, обл. Благоевград',
    '78464': 'с. Церово, общ. Благоевград, обл. Благоевград',
    '17395': 'гр. Гоце Делчев, общ. Гоце Делчев, обл. Благоевград',
    '02600': 'с. Баничан, общ. Гоце Делчев, обл. Благоевград',
    '05606': 'с. Борово, общ. Гоце Делчев, обл. Благоевград',
    '06306': 'с. Брезница, общ. Гоце Делчев, обл. Благоевград',
    '07003': 'с. Буково, общ. Гоце Делчев, обл. Благоевград',
    '65036': 'с. Савино, общ. Тунджа, обл. Ямбол',
    '17347': 'с. Господинци, общ. Гоце Делчев, обл. Благоевград',
    '20585': 'с. Делчево, общ. Гоце Делчев, обл. Благоевград',
    '21680': 'с. Добротино, общ. Гоце Делчев, обл. Благоевград',
    '38666': 'с. Корница, общ. Гоце Делчев, обл. Благоевград',
    '44416': 'с. Лъжница, общ. Гоце Делчев, обл. Благоевград',
    '49432': 'с. Мосомище, общ. Гоце Делчев, обл. Благоевград',
    '87802': 'с. Попови ливади, общ. Гоце Делчев, обл. Благоевград',
    '02381': 'с. Балдево, общ. Гърмен, обл. Благоевград',
    '16763': 'с. Горно Дряново, общ. Гърмен, обл. Благоевград',
    '18366': 'с. Гърмен, общ. Гърмен, обл. Благоевград',
    '20331': 'с. Дебрен, общ. Гърмен, обл. Благоевград',
    '22616': 'с. Долно Дряново, общ. Гърмен, обл. Благоевград',
    '24267': 'с. Дъбница, общ. Гърмен, обл. Благоевград',
    '37472': 'с. Ковачевица, общ. Гърмен, обл. Благоевград',
    '40138': 'с. Крушево, общ. Гърмен, обл. Благоевград',
    '43606': 'с. Лещен, общ. Гърмен, обл. Благоевград',
    '47408': 'с. Марчево, общ. Гърмен, обл. Благоевград',
    '53326': 'с. Огняново, общ. Гърмен, обл. Благоевград',
    '53727': 'с. Ореше, общ. Гърмен, обл. Благоевград',
    '54184': 'с. Осиково, общ. Гърмен, обл. Благоевград',
    '62640': 'с. Рибново, общ. Гърмен, обл. Благоевград',
    '66874': 'с. Скребатно, общ. Гърмен, обл. Благоевград',
    '77222': 'с. Хвостяне, общ. Гърмен, обл. Благоевград',
    '14492': 'гр. Кресна, общ. Кресна, обл. Благоевград',
    '11569': 'с. Влахи, общ. Кресна, обл. Благоевград',
    '16136': 'с. Горна Брезница, общ. Кресна, обл. Благоевград',
    '22068': 'с. Долна Градешница, общ. Кресна, обл. Благоевград',
    '54537': 'с. Ощава, общ. Кресна, обл. Благоевград',
    '67369': 'с. Сливница, общ. Кресна, обл. Благоевград',
    '39699': 'с. Стара Кресна, общ. Кресна, обл. Благоевград',
    '56126': 'гр. Петрич, общ. Петрич, обл. Благоевград',
    '02806': 'с. Баскалци, общ. Петрич, обл. Благоевград',
    '03294': 'с. Беласица, общ. Петрич, обл. Благоевград',
    '66322': 'с. Богородица, общ. Петрич, обл. Благоевград',
    '05596': 'с. Боровичене, общ. Петрич, обл. Благоевград',
    '11358': 'с. Вишлене, общ. Петрич, обл. Благоевград',
    '12036': 'с. Волно, общ. Петрич, обл. Благоевград',
    '14091': 'с. Габрене, общ. Петрич, обл. Благоевград',
    '14581': 'с. Гега, общ. Петрич, обл. Благоевград',
    '58390': 'с. Генерал Тодоров, общ. Петрич, обл. Благоевград',
    '17302': 'с. Горчево, общ. Петрич, обл. Благоевград',
    '21885': 'с. Долене, общ. Петрич, обл. Благоевград',
    '22143': 'с. Долна Крушица, общ. Петрич, обл. Благоевград',
    '22246': 'с. Долна Рибница, общ. Петрич, обл. Благоевград',
    '22842': 'с. Долно Спанчево, общ. Петрич, обл. Благоевград',
    '23488': 'с. Драгуш, общ. Петрич, обл. Благоевград',
    '23563': 'с. Дрангово, общ. Петрич, обл. Благоевград',
    '23683': 'с. Дреновица, общ. Петрич, обл. Благоевград',
    '23697': 'с. Дреново, общ. Петрич, обл. Благоевград',
    '31317': 'с. Зойчене, общ. Петрич, обл. Благоевград',
    '32086': 'с. Иваново, общ. Петрич, обл. Благоевград',
    '35081': 'с. Кавракирово, общ. Петрич, обл. Благоевград',
    '35688': 'с. Камена, общ. Петрич, обл. Благоевград',
    '36083': 'с. Капатово, общ. Петрич, обл. Благоевград',
    '37143': 'с. Кладенци, общ. Петрич, обл. Благоевград',
    '37349': 'с. Ключ, общ. Петрич, обл. Благоевград',
    '37989': 'с. Коларово, общ. Петрич, обл. Благоевград',
    '39935': 'с. Кромидово, общ. Петрич, обл. Благоевград',
    '40302': 'с. Крънджилица, общ. Петрич, обл. Благоевград',
    '40508': 'с. Кукурахцево, общ. Петрич, обл. Благоевград',
    '40539': 'с. Кулата, общ. Петрич, обл. Благоевград',
    '40912': 'с. Кърналово, общ. Петрич, обл. Благоевград',
    '47189': 'с. Марикостиново, общ. Петрич, обл. Благоевград',
    '47247': 'с. Марино поле, общ. Петрич, обл. Благоевград',
    '47799': 'с. Мендово, общ. Петрич, обл. Благоевград',
    '48458': 'с. Митино, общ. Петрич, обл. Благоевград',
    '48578': 'с. Михнево, общ. Петрич, обл. Благоевград',
    '52091': 'с. Ново Кономлади, общ. Петрич, обл. Благоевград',
    '58061': 'с. Право бърдо, общ. Петрич, обл. Благоевград',
    '59077': 'с. Първомай, общ. Петрич, обл. Благоевград',
    '62623': 'с. Рибник, общ. Петрич, обл. Благоевград',
    '49312': 'с. Рупите, общ. Петрич, обл. Благоевград',
    '63542': 'с. Ръждак, общ. Петрич, обл. Благоевград',
    '65288': 'с. Самуилова крепост, общ. Петрич, обл. Благоевград',
    '65293': 'с. Самуилово, общ. Петрич, обл. Благоевград',
    '66901': 'с. Скрът, общ. Петрич, обл. Благоевград',
    '69119': 'с. Старчево, общ. Петрич, обл. Благоевград',
    '69941': 'с. Струмешница, общ. Петрич, обл. Благоевград',
    '72672': 'с. Тонско дабе, общ. Петрич, обл. Благоевград',
    '72744': 'с. Тополница, общ. Петрич, обл. Благоевград',
    '81788': 'с. Чурилово, общ. Петрич, обл. Благоевград',
    '81791': 'с. Чуричени, общ. Петрич, обл. Благоевград',
    '81832': 'с. Чучулигово, общ. Петрич, обл. Благоевград',
    '87134': 'с. Яворница, общ. Петрич, обл. Благоевград',
    '87312': 'с. Яково, общ. Петрич, обл. Благоевград',
    '61813': 'гр. Разлог, общ. Разлог, обл. Благоевград',
    '02693': 'с. Баня, общ. Разлог, обл. Благоевград',
    '02960': 'с. Бачево, общ. Разлог, обл. Благоевград',
    '15326': 'с. Годлево, общ. Разлог, обл. Благоевград',
    '16754': 'с. Горно Драглище, общ. Разлог, обл. Благоевград',
    '21748': 'с. Добърско, общ. Разлог, обл. Благоевград',
    '22602': 'с. Долно Драглище, общ. Разлог, обл. Благоевград',
    '27293': 'с. Елешница, общ. Разлог, обл. Благоевград',
    '47754': 'гр. Мелник, общ. Сандански, обл. Благоевград',
    '65334': 'гр. Сандански, общ. Сандански, обл. Благоевград',
    '03335': 'с. Белевехчево, общ. Сандански, обл. Благоевград',
    '03784': 'с. Бельово, общ. Сандански, обл. Благоевград',
    '04933': 'с. Бождово, общ. Сандански, обл. Благоевград',
    '11168': 'с. Виногради, общ. Сандански, обл. Благоевград',
    '11315': 'с. Вихрен, общ. Сандански, обл. Благоевград',
    '12200': 'с. Враня, общ. Сандански, обл. Благоевград',
    '12471': 'с. Вълково, общ. Сандански, обл. Благоевград',
    '15549': 'с. Голем Цалим, общ. Сандански, обл. Благоевград',
    '15583': 'с. Голешово, общ. Сандански, обл. Благоевград',
    '16403': 'с. Горна Сушица, общ. Сандански, обл. Благоевград',
    '16969': 'с. Горно Спанчево, общ. Сандански, обл. Благоевград',
    '20105': 'с. Дамяница, общ. Сандански, обл. Благоевград',
    '20345': 'с. Дебрене, общ. Сандански, обл. Благоевград',
    '20780': 'с. Джигурово, общ. Сандански, обл. Благоевград',
    '21909': 'с. Долени, общ. Сандански, обл. Благоевград',
    '31139': 'с. Златолист, общ. Сандански, обл. Благоевград',
    '20910': 'с. Зорница, общ. Сандански, обл. Благоевград',
    '35362': 'с. Калиманци, общ. Сандански, обл. Благоевград',
    '36693': 'с. Катунци, общ. Сандански, обл. Благоевград',
    '36734': 'с. Кашина, общ. Сандански, обл. Благоевград',
    '37486': 'с. Ковачево, общ. Сандански, обл. Благоевград',
    '40378': 'с. Кръстилци, общ. Сандански, обл. Благоевград',
    '40926': 'с. Кърланово, общ. Сандански, обл. Благоевград',
    '43030': 'с. Ладарево, общ. Сандански, обл. Благоевград',
    '43150': 'с. Ласкарево, общ. Сандански, обл. Благоевград',
    '43181': 'с. Лебница, общ. Сандански, обл. Благоевград',
    '43243': 'с. Левуново, общ. Сандански, обл. Благоевград',
    '43503': 'с. Лехово, общ. Сандански, обл. Благоевград',
    '43565': 'с. Лешница, общ. Сандански, обл. Благоевград',
    '43699': 'с. Лиляново, общ. Сандански, обл. Благоевград',
    '44121': 'с. Лозеница, общ. Сандански, обл. Благоевград',
    '44625': 'с. Любовище, общ. Сандански, обл. Благоевград',
    '44639': 'с. Любовка, общ. Сандански, обл. Благоевград',
    '46526': 'с. Малки Цалим, общ. Сандански, обл. Благоевград',
    '52074': 'с. Ново Делчево, общ. Сандански, обл. Благоевград',
    '52307': 'с. Ново Ходжово, общ. Сандански, обл. Благоевград',
    '56174': 'с. Петрово, общ. Сандански, обл. Благоевград',
    '56352': 'с. Пиперица, общ. Сандански, обл. Благоевград',
    '56410': 'с. Пирин, общ. Сандански, обл. Благоевград',
    '56839': 'с. Плоски, общ. Сандански, обл. Благоевград',
    '57176': 'с. Поленица, общ. Сандански, обл. Благоевград',
    '62918': 'с. Рожен, общ. Сандански, обл. Благоевград',
    '66785': 'с. Склаве, общ. Сандански, обл. Благоевград',
    '68223': 'с. Спатово, общ. Сандански, обл. Благоевград',
    '69290': 'с. Стожа, общ. Сандански, обл. Благоевград',
    '69938': 'с. Струма, общ. Сандански, обл. Благоевград',
    '70189': 'с. Сугарево, общ. Сандански, обл. Благоевград',
    '77361': 'с. Хотово, общ. Сандански, обл. Благоевград',
    '77414': 'с. Храсна, общ. Сандански, обл. Благоевград',
    '77565': 'с. Хърсово, общ. Сандански, обл. Благоевград',
    '80618': 'с. Черешница, общ. Сандански, обл. Благоевград',
    '87446': 'с. Яново, общ. Сандански, обл. Благоевград',
    '04707': 'с. Боголин, общ. Сатовча, обл. Благоевград',
    '10046': 'с. Ваклиново, общ. Сатовча, обл. Благоевград',
    '12499': 'с. Вълкосел, общ. Сатовча, обл. Благоевград',
    '15312': 'с. Годешево, общ. Сатовча, обл. Благоевград',
    '21868': 'с. Долен, общ. Сатовча, обл. Благоевград',
    '29372': 'с. Жижево, общ. Сатовча, обл. Благоевград',
    '39089': 'с. Кочан, общ. Сатовча, обл. Благоевград',
    '39726': 'с. Крибул, общ. Сатовча, обл. Благоевград',
    '54225': 'с. Осина, общ. Сатовча, обл. Благоевград',
    '56740': 'с. Плетена, общ. Сатовча, обл. Благоевград',
    '65440': 'с. Сатовча, общ. Сатовча, обл. Благоевград',
    '67270': 'с. Слащен, общ. Сатовча, обл. Благоевград',
    '73519': 'с. Туховища, общ. Сатовча, обл. Благоевград',
    '76203': 'с. Фъргово, общ. Сатовча, обл. Благоевград',
    '66460': 'гр. Симитли, общ. Симитли, обл. Благоевград',
    '06238': 'с. Брежани, общ. Симитли, обл. Благоевград',
    '06522': 'с. Брестово, общ. Симитли, обл. Благоевград',
    '16870': 'с. Горно Осеново, общ. Симитли, обл. Благоевград',
    '17405': 'с. Градево, общ. Симитли, обл. Благоевград',
    '21837': 'с. Докатичево, общ. Симитли, обл. Благоевград',
    '22753': 'с. Долно Осеново, общ. Симитли, обл. Благоевград',
    '29146': 'с. Железница, общ. Симитли, обл. Благоевград',
    '40052': 'с. Крупник, общ. Симитли, обл. Благоевград',
    '48012': 'с. Мечкул, общ. Симитли, обл. Благоевград',
    '57162': 'с. Полена, общ. Симитли, обл. Благоевград',
    '57203': 'с. Полето, общ. Симитли, обл. Благоевград',
    '61978': 'с. Ракитна, общ. Симитли, обл. Благоевград',
    '66247': 'с. Сенокос, общ. Симитли, обл. Благоевград',
    '70370': 'с. Сухострел, общ. Симитли, обл. Благоевград',
    '35105': 'с. Сушица, общ. Симитли, обл. Благоевград',
    '73184': 'с. Тросково, общ. Симитли, обл. Благоевград',
    '81013': 'с. Черниче, общ. Симитли, обл. Благоевград',
    '10608': 'с. Велющец, общ. Струмяни, обл. Благоевград',
    '12142': 'с. Вракуповица, общ. Струмяни, обл. Благоевград',
    '16033': 'с. Гореме, общ. Струмяни, обл. Благоевград',
    '16273': 'с. Горна Крушица, общ. Струмяни, обл. Благоевград',
    '16362': 'с. Горна Рибница, общ. Струмяни, обл. Благоевград',
    '21467': 'с. Добри лаки, общ. Струмяни, обл. Благоевград',
    '23532': 'с. Драката, общ. Струмяни, обл. Благоевград',
    '32322': 'с. Игралище, общ. Струмяни, обл. Благоевград',
    '32665': 'с. Илинденци, общ. Струмяни, обл. Благоевград',
    '35818': 'с. Каменица, общ. Струмяни, обл. Благоевград',
    '37191': 'с. Клепало, общ. Струмяни, обл. Благоевград',
    '38056': 'с. Колибите, общ. Струмяни, обл. Благоевград',
    '40957': 'с. Кърпелево, общ. Струмяни, обл. Благоевград',
    '47473': 'с. Махалата, общ. Струмяни, обл. Благоевград',
    '49686': 'с. Микрево, общ. Струмяни, обл. Благоевград',
    '51737': 'с. Никудин, общ. Струмяни, обл. Благоевград',
    '55227': 'с. Палат, общ. Струмяни, обл. Благоевград',
    '61772': 'с. Раздол, общ. Струмяни, обл. Благоевград',
    '65930': 'с. Седелец, общ. Струмяни, обл. Благоевград',
    '69969': 'с. Струмяни, общ. Струмяни, обл. Благоевград',
    '78046': 'с. Цапарево, общ. Струмяни, обл. Благоевград',
    '77058': 'гр. Хаджидимово, общ. Хаджидимово, обл. Благоевград',
    '00014': 'с. Абланица, общ. Хаджидимово, обл. Благоевград',
    '03976': 'с. Беслен, общ. Хаджидимово, обл. Благоевград',
    '04399': 'с. Блатска, общ. Хаджидимово, обл. Благоевград',
    '14341': 'с. Гайтаниново, общ. Хаджидимово, обл. Благоевград',
    '32648': 'с. Илинден, общ. Хаджидимово, обл. Благоевград',
    '38532': 'с. Копривлен, общ. Хаджидимово, обл. Благоевград',
    '44464': 'с. Лъки, общ. Хаджидимово, обл. Благоевград',
    '51860': 'с. Нова Ловча, общ. Хаджидимово, обл. Благоевград',
    '52101': 'с. Ново Лески, общ. Хаджидимово, обл. Благоевград',
    '55467': 'с. Парил, общ. Хаджидимово, обл. Благоевград',
    '56109': 'с. Петрелик, общ. Хаджидимово, обл. Благоевград',
    '65108': 'с. Садово, общ. Хаджидимово, обл. Благоевград',
    '72268': 'с. Теплен, общ. Хаджидимово, обл. Благоевград',
    '72374': 'с. Тешово, общ. Хаджидимово, обл. Благоевград',
    '87338': 'гр. Якоруда, общ. Якоруда, обл. Благоевград',
    '00059': 'с. Аврамово, общ. Якоруда, обл. Благоевград',
    '03561': 'с. Бел камен, общ. Якоруда, обл. Благоевград',
    '07822': 'с. Бунцево, общ. Якоруда, обл. Благоевград',
    '38220': 'с. Конарско, общ. Якоруда, обл. Благоевград',
    '32603': 'с. Смолево, общ. Якоруда, обл. Благоевград',
    '81850': 'с. Черна Места, общ. Якоруда, обл. Благоевград',
    '86101': 'с. Юруково, общ. Якоруда, обл. Благоевград',
    '00151': 'гр. Айтос, общ. Айтос, обл. Бургас',
    '23889': 'с. Дрянковец, общ. Айтос, обл. Бургас',
    '30805': 'с. Зетьово, общ. Айтос, обл. Бургас',
    '36227': 'с. Карагеоргиево, общ. Айтос, обл. Бургас',
    '36381': 'с. Караново, общ. Айтос, обл. Бургас',
    '44817': 'с. Лясково, общ. Айтос, обл. Бургас',
    '46440': 'с. Малка поляна, общ. Айтос, обл. Бургас',
    '49477': 'с. Мъглен, общ. Айтос, обл. Бургас',
    '56321': 'с. Пещерско, общ. Айтос, обл. Бургас',
    '56438': 'с. Пирне, общ. Айтос, обл. Бургас',
    '57426': 'с. Поляново, общ. Айтос, обл. Бургас',
    '62013': 'с. Раклиново, общ. Айтос, обл. Бургас',
    '70473': 'с. Съдиево, общ. Айтос, обл. Бургас',
    '72727': 'с. Тополица, общ. Айтос, обл. Бургас',
    '80813': 'с. Черна могила, общ. Айтос, обл. Бургас',
    '81102': 'с. Черноград, общ. Айтос, обл. Бургас',
    '81640': 'с. Чукарка, общ. Айтос, обл. Бургас',
    '07079': 'гр. Бургас, общ. Бургас, обл. Бургас',
    '07332': 'гр. Българово, общ. Бургас, обл. Бургас',
    '06152': 'с. Братово, общ. Бургас, обл. Бургас',
    '06776': 'с. Брястовец, общ. Бургас, обл. Бургас',
    '21141': 'с. Димчево, общ. Бургас, обл. Бургас',
    '23090': 'с. Драганово, общ. Бургас, обл. Бургас',
    '32367': 'с. Извор, общ. Бургас, обл. Бургас',
    '32425': 'с. Изворище, общ. Бургас, обл. Бургас',
    '47202': 'с. Маринка, общ. Бургас, обл. Бургас',
    '48409': 'с. Миролюбово, общ. Бургас, обл. Бургас',
    '61145': 'с. Равнец, общ. Бургас, обл. Бургас',
    '72151': 'с. Твърдица, общ. Бургас, обл. Бургас',
    '35883': 'гр. Камено, общ. Камено, обл. Бургас',
    '11096': 'с. Винарско, общ. Камено, обл. Бургас',
    '12245': 'с. Вратица, общ. Камено, обл. Бургас',
    '29249': 'с. Желязово, общ. Камено, обл. Бургас',
    '52279': 'с. Константиново, общ. Камено, обл. Бургас',
    '40381': 'с. Кръстина, общ. Камено, обл. Бургас',
    '43623': 'с. Ливада, общ. Камено, обл. Бургас',
    '57337': 'с. Полски извор, общ. Камено, обл. Бургас',
    '63478': 'с. Русокастро, общ. Камено, обл. Бургас',
    '65783': 'с. Свобода, общ. Камено, обл. Бургас',
    '73211': 'с. Трояново, общ. Камено, обл. Бургас',
    '73388': 'с. Тръстиково, общ. Камено, обл. Бургас',
    '80916': 'с. Черни връх, общ. Камено, обл. Бургас',
    '36525': 'гр. Карнобат, общ. Карнобат, обл. Бургас',
    '00775': 'с. Аспарухово, общ. Карнобат, обл. Бургас',
    '10625': 'с. Венец, общ. Карнобат, обл. Бургас',
    '15182': 'с. Глумче, общ. Карнобат, обл. Бургас',
    '20417': 'с. Деветак, общ. Карнобат, обл. Бургас',
    '20448': 'с. Деветинци, общ. Карнобат, обл. Бургас',
    '02765': 'с. Детелина, общ. Карнобат, обл. Бургас',
    '21508': 'с. Добриново, общ. Карнобат, обл. Бургас',
    '23176': 'с. Драганци, общ. Карнобат, обл. Бургас',
    '23306': 'с. Драгово, общ. Карнобат, обл. Бургас',
    '27169': 'с. Екзарх Антимово, общ. Карнобат, обл. Бургас',
    '29129': 'с. Железник, общ. Карнобат, обл. Бургас',
    '29492': 'с. Житосвят, общ. Карнобат, обл. Бургас',
    '30853': 'с. Зимен, общ. Карнобат, обл. Бургас',
    '32809': 'с. Искра, общ. Карнобат, обл. Бургас',
    '37201': 'с. Кликач, общ. Карнобат, обл. Бургас',
    '37650': 'с. Козаре, общ. Карнобат, обл. Бургас',
    '40021': 'с. Крумово градище, общ. Карнобат, обл. Бургас',
    '40230': 'с. Крушово, общ. Карнобат, обл. Бургас',
    '49549': 'с. Мъдрино, общ. Карнобат, обл. Бургас',
    '51202': 'с. Невестино, общ. Карнобат, обл. Бургас',
    '53312': 'с. Огнен, общ. Карнобат, обл. Бургас',
    '62027': 'с. Раклица, общ. Карнобат, обл. Бургас',
    '65351': 'с. Сан-Стефано, общ. Карнобат, обл. Бургас',
    '66408': 'с. Сигмен, общ. Карнобат, обл. Бургас',
    '72080': 'с. Смолник, общ. Карнобат, обл. Бургас',
    '67920': 'с. Соколово, общ. Карнобат, обл. Бургас',
    '70576': 'с. Сърнево, общ. Карнобат, обл. Бургас',
    '77089': 'с. Хаджиите, общ. Карнобат, обл. Бургас',
    '78416': 'с. Церковски, общ. Карнобат, обл. Бургас',
    '80755': 'с. Черково, общ. Карнобат, обл. Бургас',
    '46663': 'гр. Малко Търново, общ. Малко Търново, обл. Бургас',
    '04412': 'с. Близнак, общ. Малко Търново, обл. Бургас',
    '06687': 'с. Бръшлян, общ. Малко Търново, обл. Бургас',
    '07627': 'с. Бяла вода, общ. Малко Търново, обл. Бургас',
    '11051': 'с. Визица, общ. Малко Търново, обл. Бургас',
    '17693': 'с. Граматиково, общ. Малко Търново, обл. Бургас',
    '27022': 'с. Евренозово, общ. Малко Търново, обл. Бургас',
    '30020': 'с. Заберново, общ. Малко Търново, обл. Бургас',
    '30483': 'с. Звездец, общ. Малко Търново, обл. Бургас',
    '35451': 'с. Калово, общ. Малко Търново, обл. Бургас',
    '48667': 'с. Младежко, общ. Малко Търново, обл. Бургас',
    '67307': 'с. Сливарово, общ. Малко Търново, обл. Бургас',
    '69328': 'с. Стоилово, общ. Малко Търново, обл. Бургас',
    '51500': 'гр. Несебър, общ. Несебър, обл. Бургас',
    '53045': 'гр. Обзор, общ. Несебър, обл. Бургас',
    '11538': 'гр. Свети Влас, общ. Несебър, обл. Бургас',
    '02703': 'с. Баня, общ. Несебър, обл. Бургас',
    '18469': 'с. Гюльовца, общ. Несебър, обл. Бургас',
    '27454': 'с. Емона, общ. Несебър, обл. Бургас',
    '37825': 'с. Козница, общ. Несебър, обл. Бургас',
    '39164': 'с. Кошарица, общ. Несебър, обл. Бургас',
    '53822': 'с. Оризаре, общ. Несебър, обл. Бургас',
    '55350': 'с. Паницово, общ. Несебър, обл. Бургас',
    '58431': 'с. Приселци, общ. Несебър, обл. Бургас',
    '61056': 'с. Равда, общ. Несебър, обл. Бургас',
    '62102': 'с. Раковсково, общ. Несебър, обл. Бургас',
    '73571': 'с. Тънково, общ. Несебър, обл. Бургас',
    '00833': 'гр. Ахелой, общ. Поморие, обл. Бургас',
    '35033': 'гр. Каблешково, общ. Поморие, обл. Бургас',
    '57491': 'гр. Поморие, общ. Поморие, обл. Бургас',
    '00271': 'с. Александрово, общ. Поморие, обл. Бургас',
    '02810': 'с. Бата, общ. Поморие, обл. Бургас',
    '27440': 'с. Белодол, общ. Поморие, обл. Бургас',
    '14057': 'с. Габерово, общ. Поморие, обл. Бургас',
    '16064': 'с. Горица, общ. Поморие, обл. Бургас',
    '18229': 'с. Гълъбец, общ. Поморие, обл. Бургас',
    '24253': 'с. Дъбник, общ. Поморие, обл. Бургас',
    '35691': 'с. Каменар, общ. Поморие, обл. Бургас',
    '37753': 'с. Козичино, общ. Поморие, обл. Бургас',
    '38769': 'с. Косовец, общ. Поморие, обл. Бургас',
    '44425': 'с. Лъка, общ. Поморие, обл. Бургас',
    '47651': 'с. Медово, общ. Поморие, обл. Бургас',
    '57790': 'с. Порой, общ. Поморие, обл. Бургас',
    '69746': 'с. Страцин, общ. Поморие, обл. Бургас',
    '37023': 'гр. Китен, общ. Приморско, обл. Бургас',
    '58356': 'гр. Приморско, общ. Приморско, обл. Бургас',
    '10731': 'с. Веселие, общ. Приморско, обл. Бургас',
    '52129': 'с. Ново Паничарево, общ. Приморско, обл. Бургас',
    '56513': 'с. Писменово, общ. Приморско, обл. Бургас',
    '87655': 'с. Ясна поляна, общ. Приморско, обл. Бургас',
    '04056': 'с. Билка, общ. Руен, обл. Бургас',
    '11363': 'с. Вишна, общ. Руен, обл. Бургас',
    '12303': 'с. Вресово, общ. Руен, обл. Бургас',
    '21333': 'с. Добра поляна, общ. Руен, обл. Бургас',
    '21614': 'с. Добромир, общ. Руен, обл. Бургас',
    '23755': 'с. Дропла, общ. Руен, обл. Бургас',
    '24699': 'с. Дъскотна, общ. Руен, обл. Бургас',
    '24757': 'с. Дюля, общ. Руен, обл. Бургас',
    '30234': 'с. Заимчево, общ. Руен, обл. Бургас',
    '30243': 'с. Зайчар, общ. Руен, обл. Бургас',
    '30435': 'с. Звезда, общ. Руен, обл. Бургас',
    '35955': 'с. Каменяк, общ. Руен, обл. Бургас',
    '36213': 'с. Каравельово, общ. Руен, обл. Бургас',
    '43880': 'с. Листец, общ. Руен, обл. Бургас',
    '44690': 'с. Люляково, общ. Руен, обл. Бургас',
    '49254': 'с. Мрежичко, общ. Руен, обл. Бургас',
    '56647': 'с. Планиница, общ. Руен, обл. Бургас',
    '15967': 'с. Подгорец, общ. Руен, обл. Бургас',
    '51055': 'с. Преображенци, общ. Руен, обл. Бургас',
    '46930': 'с. Припек, общ. Руен, обл. Бургас',
    '58640': 'с. Просеник, общ. Руен, обл. Бургас',
    '61666': 'с. Разбойна, общ. Руен, обл. Бургас',
    '62548': 'с. Речица, общ. Руен, обл. Бургас',
    '62904': 'с. Рожден, общ. Руен, обл. Бургас',
    '63166': 'с. Рудина, общ. Руен, обл. Бургас',
    '63224': 'с. Руен, общ. Руен, обл. Бургас',
    '63375': 'с. Рупча, общ. Руен, обл. Бургас',
    '63598': 'с. Ръжица, общ. Руен, обл. Бургас',
    '80296': 'с. Сини рид, общ. Руен, обл. Бургас',
    '66706': 'с. Скалак, общ. Руен, обл. Бургас',
    '67725': 'с. Снежа, общ. Руен, обл. Бургас',
    '67767': 'с. Снягово, общ. Руен, обл. Бургас',
    '67876': 'с. Соколец, общ. Руен, обл. Бургас',
    '68401': 'с. Средна махала, общ. Руен, обл. Бургас',
    '69990': 'с. Струя, общ. Руен, обл. Бургас',
    '72864': 'с. Топчийско, общ. Руен, обл. Бургас',
    '73287': 'с. Трънак, общ. Руен, обл. Бургас',
    '80563': 'с. Череша, общ. Руен, обл. Бургас',
    '83154': 'с. Шиварово, общ. Руен, обл. Бургас',
    '87093': 'с. Ябълчево, общ. Руен, обл. Бургас',
    '87638': 'с. Ясеново, общ. Руен, обл. Бургас',
    '67800': 'гр. Созопол, общ. Созопол, обл. Бургас',
    '81178': 'гр. Черноморец, общ. Созопол, обл. Бургас',
    '63015': 'с. Атия, общ. Созопол, обл. Бургас',
    '12975': 'с. Вършило, общ. Созопол, обл. Бургас',
    '14249': 'с. Габър, общ. Созопол, обл. Бургас',
    '30822': 'с. Зидарово, общ. Созопол, обл. Бургас',
    '32737': 'с. Индже войвода, общ. Созопол, обл. Бургас',
    '40124': 'с. Крушевец, общ. Созопол, обл. Бургас',
    '58400': 'с. Присад, общ. Созопол, обл. Бургас',
    '61042': 'с. Равадиново, общ. Созопол, обл. Бургас',
    '61114': 'с. Равна гора, общ. Созопол, обл. Бургас',
    '63029': 'с. Росен, общ. Созопол, обл. Бургас',
    '17974': 'гр. Средец, общ. Средец, обл. Бургас',
    '03349': 'с. Белеврен, общ. Средец, обл. Бургас',
    '03455': 'с. Белила, общ. Средец, обл. Бургас',
    '04176': 'с. Бистрец, общ. Средец, обл. Бургас',
    '04621': 'с. Богданово, общ. Средец, обл. Бургас',
    '10166': 'с. Варовник, общ. Средец, обл. Бургас',
    '72059': 'с. Вълчаново, общ. Средец, обл. Бургас',
    '15816': 'с. Голямо Буково, общ. Средец, обл. Бургас',
    '17035': 'с. Горно Ябълково, общ. Средец, обл. Бургас',
    '17720': 'с. Гранитец, общ. Средец, обл. Бургас',
    '17779': 'с. Граничар, общ. Средец, обл. Бургас',
    '20273': 'с. Дебелт, общ. Средец, обл. Бургас',
    '22914': 'с. Долно Ябълково, общ. Средец, обл. Бургас',
    '23529': 'с. Драка, общ. Средец, обл. Бургас',
    '23604': 'с. Драчево, общ. Средец, обл. Бургас',
    '24712': 'с. Дюлево, общ. Средец, обл. Бургас',
    '30168': 'с. Загорци, общ. Средец, обл. Бургас',
    '31379': 'с. Зорница, общ. Средец, обл. Бургас',
    '36938': 'с. Кирово, общ. Средец, обл. Бургас',
    '40419': 'с. Кубадин, общ. Средец, обл. Бургас',
    '46348': 'с. Малина, общ. Средец, обл. Бургас',
    '29221': 'с. Момина църква, общ. Средец, обл. Бургас',
    '53984': 'с. Орлинци, общ. Средец, обл. Бургас',
    '58712': 'с. Проход, общ. Средец, обл. Бургас',
    '59015': 'с. Пънчево, общ. Средец, обл. Бургас',
    '61563': 'с. Радойново, общ. Средец, обл. Бургас',
    '63055': 'с. Росеново, общ. Средец, обл. Бургас',
    '65560': 'с. Светлина, общ. Средец, обл. Бургас',
    '66585': 'с. Синьо камене, общ. Средец, обл. Бургас',
    '67413': 'с. Сливово, общ. Средец, обл. Бургас',
    '70322': 'с. Суходол, общ. Средец, обл. Бургас',
    '72967': 'с. Тракийци, общ. Средец, обл. Бургас',
    '76039': 'с. Факия, общ. Средец, обл. Бургас',
    '70247': 'гр. Сунгурларе, общ. Сунгурларе, обл. Бургас',
    '03959': 'с. Бероново, общ. Сунгурларе, обл. Бургас',
    '05726': 'с. Босилково, общ. Сунгурларе, обл. Бургас',
    '10313': 'с. Ведрово, общ. Сунгурларе, обл. Бургас',
    '10327': 'с. Везенково, общ. Сунгурларе, обл. Бургас',
    '76056': 'с. Велислав, общ. Сунгурларе, обл. Бургас',
    '12591': 'с. Вълчин, общ. Сунгурларе, обл. Бургас',
    '17049': 'с. Горово, общ. Сунгурларе, обл. Бургас',
    '17909': 'с. Грозден, общ. Сунгурларе, обл. Бургас',
    '83137': 'с. Дъбовица, общ. Сунгурларе, обл. Бургас',
    '27615': 'с. Есен, общ. Сунгурларе, обл. Бургас',
    '30051': 'с. Завет, общ. Сунгурларе, обл. Бургас',
    '36004': 'с. Камчия, общ. Сунгурларе, обл. Бургас',
    '37215': 'с. Климаш, общ. Сунгурларе, обл. Бургас',
    '38889': 'с. Костен, общ. Сунгурларе, обл. Бургас',
    '44029': 'с. Лозарево, общ. Сунгурларе, обл. Бургас',
    '44149': 'с. Лозица, общ. Сунгурларе, обл. Бургас',
    '47096': 'с. Манолич, общ. Сунгурларе, обл. Бургас',
    '56959': 'с. Подвис, общ. Сунгурларе, обл. Бургас',
    '58325': 'с. Прилеп, общ. Сунгурларе, обл. Бургас',
    '58857': 'с. Пчелин, общ. Сунгурларе, обл. Бургас',
    '65111': 'с. Садово, общ. Сунгурларе, обл. Бургас',
    '66682': 'с. Скала, общ. Сунгурларе, обл. Бургас',
    '67115': 'с. Славянци, общ. Сунгурларе, обл. Бургас',
    '70514': 'с. Съединение, общ. Сунгурларе, обл. Бургас',
    '72299': 'с. Терзийско, общ. Сунгурларе, обл. Бургас',
    '81006': 'с. Черница, общ. Сунгурларе, обл. Бургас',
    '81582': 'с. Чубра, общ. Сунгурларе, обл. Бургас',
    '00878': 'гр. Ахтопол, общ. Царево, обл. Бургас',
    '48619': 'гр. Царево, общ. Царево, обл. Бургас',
    '06553': 'с. Бродилово, общ. Царево, обл. Бургас',
    '07291': 'с. Българи, общ. Царево, обл. Бургас',
    '10094': 'с. Варвара, общ. Царево, обл. Бургас',
    '10361': 'с. Велика, общ. Царево, обл. Бургас',
    '32514': 'с. Изгрев, общ. Царево, обл. Бургас',
    '38251': 'с. Кондолово, общ. Царево, обл. Бургас',
    '38947': 'с. Кости, общ. Царево, обл. Бургас',
    '44094': 'с. Лозенец, общ. Царево, обл. Бургас',
    '62459': 'с. Резово, общ. Царево, обл. Бургас',
    '66528': 'с. Синеморец, общ. Царево, обл. Бургас',
    '76025': 'с. Фазаново, общ. Царево, обл. Бургас',
    '00084': 'с. Аврен, общ. Аврен, обл. Варна',
    '03811': 'с. Бенковски, общ. Аврен, обл. Варна',
    '04426': 'с. Близнаци, общ. Аврен, обл. Варна',
    '05311': 'с. Болярци, общ. Аврен, обл. Варна',
    '21419': 'с. Добри дол, общ. Аврен, обл. Варна',
    '24400': 'с. Дъбравино, общ. Аврен, обл. Варна',
    '30627': 'с. Здравец, общ. Аврен, обл. Варна',
    '35208': 'с. Казашка река, общ. Аврен, обл. Варна',
    '37068': 'с. Китка, общ. Аврен, обл. Варна',
    '40066': 'с. Круша, общ. Аврен, обл. Варна',
    '58445': 'с. Приселци, общ. Аврен, обл. Варна',
    '61128': 'с. Равна гора, общ. Аврен, обл. Варна',
    '65125': 'с. Садово, общ. Аврен, обл. Варна',
    '66490': 'с. Синдел, общ. Аврен, обл. Варна',
    '73393': 'с. Тръстиково, общ. Аврен, обл. Варна',
    '70220': 'с. Царевци, общ. Аврен, обл. Варна',
    '86057': 'с. Юнак, общ. Аврен, обл. Варна',
    '00182': 'гр. Аксаково, общ. Аксаково, обл. Варна',
    '32278': 'гр. Игнатиево, общ. Аксаково, обл. Варна',
    '05829': 'с. Ботево, общ. Аксаково, обл. Варна',
    '11702': 'с. Водица, общ. Аксаково, обл. Варна',
    '12406': 'с. Въглен, общ. Аксаково, обл. Варна',
    '14653': 'с. Генерал Кантарджиево, общ. Аксаково, обл. Варна',
    '21587': 'с. Доброглед, общ. Аксаково, обл. Варна',
    '21988': 'с. Долище, общ. Аксаково, обл. Варна',
    '30363': 'с. Засмяно, общ. Аксаково, обл. Варна',
    '31334': 'с. Зорница, общ. Аксаково, обл. Варна',
    '32490': 'с. Изворско, общ. Аксаково, обл. Варна',
    '37099': 'с. Кичево, общ. Аксаково, обл. Варна',
    '37246': 'с. Климентово, общ. Аксаково, обл. Варна',
    '39983': 'с. Крумово, общ. Аксаково, обл. Варна',
    '40590': 'с. Куманово, общ. Аксаково, обл. Варна',
    '44519': 'с. Любен Каравелово, общ. Аксаково, обл. Варна',
    '51826': 'с. Новаково, общ. Аксаково, обл. Варна',
    '53691': 'с. Орешак, общ. Аксаково, обл. Варна',
    '54145': 'с. Осеново, общ. Аксаково, обл. Варна',
    '58373': 'с. Припек, общ. Аксаково, обл. Варна',
    '61306': 'с. Радево, общ. Аксаково, обл. Варна',
    '67489': 'с. Слънчево, общ. Аксаково, обл. Варна',
    '87518': 'с. Яребична, общ. Аксаково, обл. Варна',
    '03719': 'гр. Белослав, общ. Белослав, обл. Варна',
    '27125': 'с. Езерово, общ. Белослав, обл. Варна',
    '61741': 'с. Разделна, общ. Белослав, обл. Варна',
    '69763': 'с. Страшимирово, общ. Белослав, обл. Варна',
    '07598': 'гр. Бяла, общ. Бяла, обл. Варна',
    '16078': 'с. Горица, общ. Бяла, обл. Варна',
    '17323': 'с. Господиново, общ. Бяла, обл. Варна',
    '24739': 'с. Дюлино, общ. Бяла, обл. Варна',
    '57635': 'с. Попович, общ. Бяла, обл. Варна',
    '65259': 'с. Самотино, общ. Бяла, обл. Варна',
    '10135': 'гр. Варна, общ. Варна, обл. Варна',
    '30497': 'с. Звездица, общ. Варна, обл. Варна',
    '35211': 'с. Казашко, общ. Варна, обл. Варна',
    '35701': 'с. Каменар, общ. Варна, обл. Варна',
    '38354': 'с. Константиново, общ. Варна, обл. Варна',
    '72709': 'с. Тополи, общ. Варна, обл. Варна',
    '03602': 'с. Белоградец, общ. Ветрино, обл. Варна',
    '10865': 'с. Ветрино, общ. Ветрино, обл. Варна',
    '14252': 'с. Габърница, общ. Ветрино, обл. Варна',
    '21645': 'с. Доброплодно, общ. Ветрино, обл. Варна',
    '48653': 'с. Млада гвардия, общ. Ветрино, обл. Варна',
    '49000': 'с. Момчилово, общ. Ветрино, обл. Варна',
    '51233': 'с. Невша, общ. Ветрино, обл. Варна',
    '51487': 'с. Неофит Рилски, общ. Ветрино, обл. Варна',
    '68480': 'с. Средно село, общ. Ветрино, обл. Варна',
    '87206': 'с. Ягнило, общ. Ветрино, обл. Варна',
    '12574': 'гр. Вълчи дол, общ. Вълчи дол, обл. Варна',
    '05978': 'с. Бояна, общ. Вълчи дол, обл. Варна',
    '06416': 'с. Брестак, общ. Вълчи дол, обл. Варна',
    '11836': 'с. Войводино, общ. Вълчи дол, обл. Варна',
    '14667': 'с. Генерал Киселово, общ. Вълчи дол, обл. Варна',
    '14670': 'с. Генерал Колево, общ. Вълчи дол, обл. Варна',
    '21717': 'с. Добротич, общ. Вълчи дол, обл. Варна',
    '27629': 'с. Есеница, общ. Вълчи дол, обл. Варна',
    '30555': 'с. Звънец, общ. Вълчи дол, обл. Варна',
    '32442': 'с. Изворник, общ. Вълчи дол, обл. Варна',
    '32860': 'с. Искър, общ. Вълчи дол, обл. Варна',
    '35506': 'с. Калоян, общ. Вълчи дол, обл. Варна',
    '36302': 'с. Караманите, общ. Вълчи дол, обл. Варна',
    '39373': 'с. Кракра, общ. Вълчи дол, обл. Варна',
    '47874': 'с. Метличина, общ. Вълчи дол, обл. Варна',
    '48524': 'с. Михалич, общ. Вълчи дол, обл. Варна',
    '53093': 'с. Оборище, общ. Вълчи дол, обл. Варна',
    '61265': 'с. Радан войвода, общ. Вълчи дол, обл. Варна',
    '69170': 'с. Стефан Караджа, общ. Вълчи дол, обл. Варна',
    '69701': 'с. Страхил, общ. Вълчи дол, обл. Варна',
    '80529': 'с. Червенци, общ. Вълчи дол, обл. Варна',
    '84022': 'с. Щипско, общ. Вълчи дол, обл. Варна',
    '36868': 'с. Кипра, общ. Девня, обл. Варна',
    '55110': 'с. Падина, общ. Девня, обл. Варна',
    '21912': 'гр. Долни чифлик, общ. Долни чифлик, обл. Варна',
    '07034': 'с. Булаир, общ. Долни чифлик, обл. Варна',
    '07394': 'с. Бърдарево, общ. Долни чифлик, обл. Варна',
    '10611': 'с. Венелин, общ. Долни чифлик, обл. Варна',
    '15597': 'с. Голица, общ. Долни чифлик, обл. Варна',
    '16050': 'с. Горен чифлик, общ. Долни чифлик, обл. Варна',
    '17912': 'с. Гроздьово, общ. Долни чифлик, обл. Варна',
    '20701': 'с. Детелина, общ. Долни чифлик, обл. Варна',
    '39801': 'с. Кривини, общ. Долни чифлик, обл. Варна',
    '51963': 'с. Нова Шипка, общ. Долни чифлик, обл. Варна',
    '52115': 'с. Ново Оряхово, общ. Долни чифлик, обл. Варна',
    '58921': 'с. Пчелник, общ. Долни чифлик, обл. Варна',
    '63197': 'с. Рудник, общ. Долни чифлик, обл. Варна',
    '68028': 'с. Солник, общ. Долни чифлик, обл. Варна',
    '68998': 'с. Старо Оряхово, общ. Долни чифлик, обл. Варна',
    '83404': 'с. Шкорпиловци, общ. Долни чифлик, обл. Варна',
    '86088': 'с. Юнец, общ. Долни чифлик, обл. Варна',
    '24565': 'гр. Дългопол, общ. Дългопол, обл. Варна',
    '00624': 'с. Арковна, общ. Дългопол, обл. Варна',
    '00789': 'с. Аспарухово, общ. Дългопол, обл. Варна',
    '05699': 'с. Боряна, общ. Дългопол, обл. Варна',
    '10495': 'с. Величково, общ. Дългопол, обл. Варна',
    '20599': 'с. Дебелец, общ. Дългопол, обл. Варна',
    '35777': 'с. Камен дял, общ. Дългопол, обл. Варна',
    '38162': 'с. Комунари, общ. Дългопол, обл. Варна',
    '39551': 'с. Красимир, общ. Дългопол, обл. Варна',
    '44294': 'с. Лопушна, общ. Дългопол, обл. Варна',
    '47620': 'с. Медовец, общ. Дългопол, обл. Варна',
    '55470': 'с. Партизани, общ. Дългопол, обл. Варна',
    '57457': 'с. Поляците, общ. Дългопол, обл. Варна',
    '63135': 'с. Рояк, общ. Дългопол, обл. Варна',
    '65019': 'с. Сава, общ. Дългопол, обл. Варна',
    '67129': 'с. Сладка вода, общ. Дългопол, обл. Варна',
    '78519': 'с. Цонево, общ. Дългопол, обл. Варна',
    '58503': 'гр. Провадия, общ. Провадия, обл. Варна',
    '04457': 'с. Блъсково, общ. Провадия, обл. Варна',
    '05102': 'с. Бозвелийско, общ. Провадия, обл. Варна',
    '07507': 'с. Бързица, общ. Провадия, обл. Варна',
    '10663': 'с. Венчан, общ. Провадия, обл. Варна',
    '17508': 'с. Градинарово, общ. Провадия, обл. Варна',
    '21484': 'с. Добрина, общ. Провадия, обл. Варна',
    '29458': 'с. Житница, общ. Провадия, обл. Варна',
    '31005': 'с. Златина, общ. Провадия, обл. Варна',
    '37037': 'с. Китен, общ. Провадия, обл. Варна',
    '38114': 'с. Комарево, общ. Провадия, обл. Варна',
    '39829': 'с. Кривня, общ. Провадия, обл. Варна',
    '46975': 'с. Манастир, общ. Провадия, обл. Варна',
    '51456': 'с. Неново, общ. Провадия, обл. Варна',
    '53182': 'с. Овчага, общ. Провадия, обл. Варна',
    '56143': 'с. Петров дол, общ. Провадия, обл. Варна',
    '61073': 'с. Равна, общ. Провадия, обл. Варна',
    '66963': 'с. Славейково, общ. Провадия, обл. Варна',
    '67739': 'с. Снежина, общ. Провадия, обл. Варна',
    '69020': 'с. Староселец, общ. Провадия, обл. Варна',
    '73506': 'с. Тутраканци, общ. Провадия, обл. Варна',
    '77387': 'с. Храброво, общ. Провадия, обл. Варна',
    '80073': 'с. Чайка, общ. Провадия, обл. Варна',
    '80710': 'с. Черковна, общ. Провадия, обл. Варна',
    '81195': 'с. Черноок, общ. Провадия, обл. Варна',
    '70175': 'гр. Суворово, общ. Суворово, обл. Варна',
    '02662': 'с. Баново, общ. Суворово, обл. Варна',
    '23858': 'с. Дръндар, общ. Суворово, обл. Варна',
    '32528': 'с. Изгрев, общ. Суворово, обл. Варна',
    '35376': 'с. Калиманци, общ. Суворово, обл. Варна',
    '43222': 'с. Левски, общ. Суворово, обл. Варна',
    '51584': 'с. Николаевка, общ. Суворово, обл. Варна',
    '58654': 'с. Просечен, общ. Суворово, обл. Варна',
    '80861': 'с. Чернево, общ. Суворово, обл. Варна',
    '10447': 'гр. Велико Търново, общ. Велико Търново, обл. Велико Търново',
    '20242': 'гр. Дебелец, общ. Велико Търново, обл. Велико Търново',
    '36837': 'гр. Килифарево, общ. Велико Търново, обл. Велико Търново',
    '00583': 'с. Арбанаси, общ. Велико Търново, обл. Велико Търново',
    '02364': 'с. Балван, общ. Велико Търново, обл. Велико Търново',
    '03753': 'с. Белчевци, общ. Велико Търново, обл. Велико Търново',
    '03798': 'с. Беляковец, общ. Велико Търново, обл. Велико Търново',
    '04011': 'с. Бижовци, общ. Велико Търново, обл. Велико Търново',
    '05222': 'с. Бойчеви колиби, общ. Велико Търново, обл. Велико Търново',
    '05240': 'с. Бойчовци, общ. Велико Търново, обл. Велико Търново',
    '05935': 'с. Бочковци, общ. Велико Търново, обл. Велико Търново',
    '06015': 'с. Бояновци, общ. Велико Търново, обл. Велико Търново',
    '06118': 'с. Бранковци, общ. Велико Търново, обл. Велико Търново',
    '06940': 'с. Буковец, общ. Велико Търново, обл. Велико Търново',
    '10553': 'с. Велчево, общ. Велико Търново, обл. Велико Търново',
    '10879': 'с. Ветринци, общ. Велико Търново, обл. Велико Търново',
    '11065': 'с. Виларе, общ. Велико Търново, обл. Велико Търново',
    '11795': 'с. Водолей, общ. Велико Търново, обл. Велико Търново',
    '11898': 'с. Войнежа, общ. Велико Търново, обл. Велико Търново',
    '12098': 'с. Вонеща вода, общ. Велико Търново, обл. Велико Търново',
    '12396': 'с. Въглевци, общ. Велико Търново, обл. Велико Търново',
    '12913': 'с. Върлинка, общ. Велико Търново, обл. Велико Търново',
    '14235': 'с. Габровци, общ. Велико Търново, обл. Велико Търново',
    '14578': 'с. Гащевци, общ. Велико Търново, обл. Велико Търново',
    '15401': 'с. Големаните, общ. Велико Търново, обл. Велико Търново',
    '16016': 'с. Горановци, общ. Велико Търново, обл. Велико Търново',
    '16047': 'с. Горен Еневец, общ. Велико Търново, обл. Велико Търново',
    '20434': 'с. Деветаците, общ. Велико Търново, обл. Велико Търново',
    '20715': 'с. Дечковци, общ. Велико Търново, обл. Велико Търново',
    '21066': 'с. Димитровци, общ. Велико Търново, обл. Велико Търново',
    '21110': 'с. Димовци, общ. Велико Търново, обл. Велико Търново',
    '21244': 'с. Дичин, общ. Велико Търново, обл. Велико Търново',
    '21796': 'с. Дойновци, общ. Велико Търново, обл. Велико Търново',
    '21899': 'с. Долен Еневец, общ. Велико Търново, обл. Велико Търново',
    '22383': 'с. Долни Дамяновци, общ. Велико Търново, обл. Велико Търново',
    '24058': 'с. Дунавци, общ. Велико Търново, обл. Велико Търново',
    '27423': 'с. Емен, общ. Велико Търново, обл. Велико Търново',
    '32127': 'с. Ивановци, общ. Велико Търново, обл. Велико Търново',
    '32617': 'с. Илевци, общ. Велико Търново, обл. Велико Търново',
    '34076': 'с. Йовчевци, общ. Велико Търново, обл. Велико Търново',
    '37006': 'с. Кисьовци, общ. Велико Търново, обл. Велико Търново',
    '37160': 'с. Кладни дял, общ. Велико Търново, обл. Велико Търново',
    '37335': 'с. Клъшка река, общ. Велико Търново, обл. Велико Търново',
    '40779': 'с. Куцаровци, общ. Велико Търново, обл. Велико Търново',
    '36107': 'с. Къпиново, общ. Велико Търново, обл. Велико Търново',
    '43013': 'с. Лагерите, общ. Велико Търново, обл. Велико Търново',
    '43253': 'с. Леденик, общ. Велико Търново, обл. Велико Търново',
    '46532': 'с. Малки чифлик, общ. Велико Търново, обл. Велико Търново',
    '46855': 'с. Малчовци, общ. Велико Търново, обл. Велико Търново',
    '47175': 'с. Марговци, общ. Велико Търново, обл. Велико Търново',
    '48278': 'с. Миндя, общ. Велико Търново, обл. Велико Търново',
    '48636': 'с. Мишеморков хан, общ. Велико Търново, обл. Велико Търново',
    '48951': 'с. Момин сбор, общ. Велико Търново, обл. Велико Търново',
    '51175': 'с. Нацовци, общ. Велико Търново, обл. Велико Търново',
    '51740': 'с. Никюп, общ. Велико Търново, обл. Велико Търново',
    '52177': 'с. Ново село, общ. Велико Търново, обл. Велико Търново',
    '54095': 'с. Осенарите, общ. Велико Търново, обл. Велико Търново',
    '56383': 'с. Пирамидата, общ. Велико Търново, обл. Велико Търново',
    '56602': 'с. Плаково, общ. Велико Търново, обл. Велико Търново',
    '57114': 'с. Пожерник, общ. Велико Търново, обл. Велико Търново',
    '57666': 'с. Поповци, общ. Велико Търново, обл. Велико Търново',
    '58459': 'с. Присово, общ. Велико Търново, обл. Велико Търново',
    '58520': 'с. Продановци, общ. Велико Търново, обл. Велико Търново',
    '58791': 'с. Пушево, общ. Велико Търново, обл. Велико Търново',
    '58918': 'с. Пчелище, общ. Велико Търново, обл. Велико Търново',
    '59121': 'с. Пъровци, общ. Велико Търново, обл. Велико Търново',
    '61435': 'с. Радковци, общ. Велико Търново, обл. Велико Търново',
    '61861': 'с. Райковци, общ. Велико Търново, обл. Велико Търново',
    '62270': 'с. Рашевци, общ. Велико Търново, обл. Велико Търново',
    '62517': 'с. Ресен, общ. Велико Търново, обл. Велико Търново',
    '63416': 'с. Русаля, общ. Велико Търново, обл. Велико Търново',
    '63464': 'с. Русковци, общ. Велико Търново, обл. Велико Търново',
    '65200': 'с. Самоводене, общ. Велико Търново, обл. Велико Търново',
    '65262': 'с. Самсиите, общ. Велико Търново, обл. Велико Търново',
    '66010': 'с. Сеймените, общ. Велико Търново, обл. Велико Търново',
    '66189': 'с. Семковци, общ. Велико Търново, обл. Велико Търново',
    '70264': 'с. Суха река, общ. Велико Търново, обл. Велико Търново',
    '70603': 'с. Сърненци, общ. Велико Търново, обл. Велико Търново',
    '72312': 'с. Терзиите, общ. Велико Търново, обл. Велико Търново',
    '72583': 'с. Тодоровци, общ. Велико Търново, обл. Велико Търново',
    '75229': 'с. Ушевци, общ. Велико Търново, обл. Велико Търново',
    '77356': 'с. Хотница, общ. Велико Търново, обл. Велико Търново',
    '78389': 'с. Цепераните, общ. Велико Търново, обл. Велико Търново',
    '78428': 'с. Церова кория, общ. Велико Търново, обл. Велико Търново',
    '78536': 'с. Цонковци, общ. Велико Търново, обл. Велико Търново',
    '83586': 'с. Шемшево, общ. Велико Търново, обл. Велико Търново',
    '83123': 'с. Шереметя, общ. Велико Търново, обл. Велико Търново',
    '83418': 'с. Шодековци, общ. Велико Търново, обл. Велико Търново',
    '87360': 'с. Ялово, общ. Велико Търново, обл. Велико Търново',
    '16359': 'гр. Горна Оряховица, общ. Горна Оряховица, обл. Велико Търново',
    '22232': 'гр. Долна Оряховица, общ. Горна Оряховица, обл. Велико Търново',
    '12735': 'с. Върбица, общ. Горна Оряховица, обл. Велико Търново',
    '17107': 'с. Горски горен Тръмбеш, общ. Горна Оряховица, обл. Велико Търново',
    '17124': 'с. Горски долен Тръмбеш, общ. Горна Оряховица, обл. Велико Търново',
    '23100': 'с. Драганово, общ. Горна Оряховица, обл. Велико Търново',
    '40172': 'с. Крушето, общ. Горна Оряховица, обл. Велико Търново',
    '55196': 'с. Паисий, общ. Горна Оряховица, обл. Велико Търново',
    '56472': 'с. Писарево, общ. Горна Оряховица, обл. Велико Търново',
    '57217': 'с. Поликраище, общ. Горна Оряховица, обл. Велико Търново',
    '57981': 'с. Правда, общ. Горна Оряховица, обл. Велико Търново',
    '59094': 'с. Първомайци, общ. Горна Оряховица, обл. Велико Търново',
    '69780': 'с. Стрелец, общ. Горна Оряховица, обл. Велико Търново',
    '87453': 'с. Янтра, общ. Горна Оряховица, обл. Велико Търново',
    '27190': 'гр. Елена, общ. Елена, обл. Велико Търново',
    '00521': 'с. Аплаци, общ. Елена, обл. Велико Търново',
    '02110': 'с. Багалевци, общ. Елена, обл. Велико Търново',
    '02169': 'с. Бадевци, общ. Елена, обл. Велико Търново',
    '02191': 'с. Баевци, общ. Елена, обл. Велико Търново',
    '02213': 'с. Баждари, общ. Елена, обл. Велико Търново',
    '02498': 'с. Балуци, общ. Елена, обл. Велико Търново',
    '03054': 'с. Беброво, общ. Елена, обл. Велико Търново',
    '03232': 'с. Бейковци, общ. Елена, обл. Велико Търново',
    '03945': 'с. Берковци, общ. Елена, обл. Велико Търново',
    '04460': 'с. Блъсковци, общ. Елена, обл. Велико Търново',
    '04652': 'с. Богданско, общ. Елена, обл. Велико Търново',
    '05164': 'с. Бойковци, общ. Елена, обл. Велико Търново',
    '05709': 'с. Босевци, общ. Елена, обл. Велико Търново',
    '06344': 'с. Брезово, общ. Елена, обл. Велико Търново',
    '06662': 'с. Бръчковци, общ. Елена, обл. Велико Търново',
    '06896': 'с. Буйновци, общ. Елена, обл. Велико Търново',
    '07805': 'с. Бялковци, общ. Елена, обл. Велико Търново',
    '10063': 'с. Валето, общ. Елена, обл. Велико Търново',
    '10522': 'с. Велковци, общ. Елена, обл. Велико Търново',
    '10598': 'с. Велювци, общ. Елена, обл. Велико Търново',
    '10745': 'с. Веселина, общ. Елена, обл. Велико Търново',
    '11274': 'с. Високовци, общ. Елена, обл. Велико Търново',
    '12615': 'с. Вълчовци, общ. Елена, обл. Велико Търново',
    '12629': 'с. Вълчовци, общ. Елена, обл. Велико Търново',
    '12855': 'с. Вързилковци, общ. Елена, обл. Велико Търново',
    '14088': 'с. Габрака, общ. Елена, обл. Велико Търново',
    '14430': 'с. Ганев дол, общ. Елена, обл. Велико Търново',
    '15120': 'с. Глоговец, общ. Елена, обл. Велико Търново',
    '15391': 'с. Големани, общ. Елена, обл. Велико Търново',
    '16568': 'с. Горни край, общ. Елена, обл. Велико Търново',
    '16626': 'с. Горни Танчевци, общ. Елена, обл. Велико Търново',
    '17083': 'с. Горска, общ. Елена, обл. Велико Търново',
    '17703': 'с. Граматици, общ. Елена, обл. Велико Търново',
    '18335': 'с. Гърдевци, общ. Елена, обл. Велико Търново',
    '20027': 'с. Давери, общ. Елена, обл. Велико Търново',
    '20081': 'с. Дайновци, общ. Елена, обл. Велико Търново',
    '20268': 'с. Дебели рът, общ. Елена, обл. Велико Търново',
    '21364': 'с. Добревци, общ. Елена, обл. Велико Търново',
    '22455': 'с. Долни Марян, общ. Елена, обл. Велико Търново',
    '22513': 'с. Долни Танчевци, общ. Елена, обл. Велико Търново',
    '22976': 'с. Донковци, общ. Елена, обл. Велико Търново',
    '23131': 'с. Драгановци, общ. Елена, обл. Велико Търново',
    '23162': 'с. Драганосковци, общ. Елена, обл. Велико Търново',
    '23220': 'с. Драгийци, общ. Елена, обл. Велико Търново',
    '23265': 'с. Драгневци, общ. Елена, обл. Велико Търново',
    '23710': 'с. Дрента, общ. Елена, обл. Велико Търново',
    '24027': 'с. Дуковци, общ. Елена, обл. Велико Търново',
    '24654': 'с. Дърлевци, общ. Елена, обл. Велико Търново',
    '30721': 'с. Зеленик, общ. Елена, обл. Велико Търново',
    '32055': 'с. Иванивановци, общ. Елена, обл. Велико Търново',
    '32305': 'с. Игнатовци, общ. Елена, обл. Велико Търново',
    '32593': 'с. Илаков рът, общ. Елена, обл. Велико Търново',
    '32682': 'с. Илиювци, общ. Елена, обл. Велико Търново',
    '35729': 'с. Каменари, общ. Елена, обл. Велико Търново',
    '36052': 'с. Кантари, общ. Елена, обл. Велико Търново',
    '36275': 'с. Караиванци, общ. Елена, обл. Велико Търново',
    '36378': 'с. Карандили, общ. Елена, обл. Велико Търново',
    '36871': 'с. Киревци, общ. Елена, обл. Велико Търново',
    '37616': 'с. Кожлювци, общ. Елена, обл. Велико Търново',
    '37842': 'с. Козя река, общ. Елена, обл. Велико Търново',
    '37976': 'с. Колари, общ. Елена, обл. Велико Търново',
    '38337': 'с. Константин, общ. Елена, обл. Велико Търново',
    '38738': 'с. Косевци, общ. Елена, обл. Велико Търново',
    '38861': 'с. Костел, общ. Елена, обл. Велико Търново',
    '39075': 'с. Котуци, общ. Елена, обл. Велико Търново',
    '39880': 'с. Крилювци, общ. Елена, обл. Велико Търново',
    '40035': 'с. Крумчевци, общ. Елена, обл. Велико Търново',
    '43061': 'с. Лазарци, общ. Елена, обл. Велико Търново',
    '43342': 'с. Лесиче, общ. Елена, обл. Велико Търново',
    '46125': 'с. Майско, общ. Елена, обл. Велико Търново',
    '47158': 'с. Марафелци, общ. Елена, обл. Велико Търново',
    '47227': 'с. Мариновци, общ. Елена, обл. Велико Търново',
    '47425': 'с. Марян, общ. Елена, обл. Велико Търново',
    '47487': 'с. Махалници, общ. Елена, обл. Велико Търново',
    '48057': 'с. Мийковци, общ. Елена, обл. Велико Търново',
    '48283': 'с. Миневци, общ. Елена, обл. Велико Търново',
    '48430': 'с. Мирчовци, общ. Елена, обл. Велико Търново',
    '49583': 'с. Мъртвината, общ. Елена, обл. Велико Търново',
    '51367': 'с. Недялковци, общ. Елена, обл. Велико Търново',
    '51531': 'с. Нешевци, общ. Елена, обл. Велико Търново',
    '51696': 'с. Николовци, общ. Елена, обл. Велико Търново',
    '51706': 'с. Николчовци, общ. Елена, обл. Велико Търново',
    '51771': 'с. Ничовци, общ. Елена, обл. Велико Търново',
    '52386': 'с. Нюшковци, общ. Елена, обл. Велико Търново',
    '55261': 'с. Палици, общ. Елена, обл. Велико Търново',
    '55442': 'с. Папратлива, общ. Елена, обл. Велико Търново',
    '55693': 'с. Пейковци, общ. Елена, обл. Велико Търново',
    '56011': 'с. Петковци, общ. Елена, обл. Велико Търново',
    '57738': 'с. Попрусевци, общ. Елена, обл. Велико Търново',
    '57741': 'с. Попска, общ. Елена, обл. Велико Търново',
    '61515': 'с. Радовци, общ. Елена, обл. Велико Търново',
    '61892': 'с. Райновци, общ. Елена, обл. Велико Търново',
    '62133': 'с. Ралиновци, общ. Елена, обл. Велико Търново',
    '62325': 'с. Раювци, общ. Елена, обл. Велико Търново',
    '62360': 'с. Ребревци, общ. Елена, обл. Велико Търново',
    '63495': 'с. Руховци, общ. Елена, обл. Велико Търново',
    '65632': 'с. Светославци, общ. Елена, обл. Велико Търново',
    '68429': 'с. Средни колиби, общ. Елена, обл. Велико Търново',
    '69393': 'с. Стойчевци, общ. Елена, обл. Велико Търново',
    '69540': 'с. Стояновци, общ. Елена, обл. Велико Търново',
    '70216': 'с. Султани, общ. Елена, обл. Велико Търново',
    '70439': 'с. Събковци, общ. Елена, обл. Велико Търново',
    '72446': 'с. Титевци, общ. Елена, обл. Велико Търново',
    '72607': 'с. Тодювци, общ. Елена, обл. Велико Търново',
    '72641': 'с. Томбето, общ. Елена, обл. Велико Търново',
    '72847': 'с. Топузи, общ. Елена, обл. Велико Търново',
    '73331': 'с. Трънковци, общ. Елена, обл. Велико Търново',
    '73448': 'с. Тумбевци, общ. Елена, обл. Велико Търново',
    '73568': 'с. Тънки рът, общ. Елена, обл. Велико Търново',
    '73634': 'с. Търкашени, общ. Елена, обл. Велико Търново',
    '75040': 'с. Угорялковци, общ. Елена, обл. Велико Търново',
    '77178': 'с. Харваловци, общ. Елена, обл. Велико Търново',
    '77445': 'с. Христовци, общ. Елена, обл. Велико Търново',
    '77534': 'с. Хъневци, общ. Елена, обл. Велико Търново',
    '78269': 'с. Цвеклювци, общ. Елена, обл. Велико Търново',
    '80042': 'с. Чавдарци, общ. Елена, обл. Велико Търново',
    '80100': 'с. Чакали, общ. Елена, обл. Велико Търново',
    '80515': 'с. Червенковци, общ. Елена, обл. Велико Търново',
    '80978': 'с. Черни дял, общ. Елена, обл. Велико Търново',
    '83168': 'с. Шиливери, общ. Елена, обл. Велико Търново',
    '83171': 'с. Шилковци, общ. Елена, обл. Велико Търново',
    '83452': 'с. Шубеци, общ. Елена, обл. Велико Търново',
    '87326': 'с. Яковци, общ. Елена, обл. Велико Търново',
    '30962': 'гр. Златарица, общ. Златарица, обл. Велико Търново',
    '16417': 'с. Горна Хаджийска, общ. Златарица, обл. Велико Търново',
    '17213': 'с. Горско ново село, общ. Златарица, обл. Велико Търново',
    '17227': 'с. Горско Писарево, общ. Златарица, обл. Велико Търново',
    '20506': 'с. Дедина, общ. Златарица, обл. Велико Търново',
    '20514': 'с. Дединци, общ. Златарица, обл. Велико Търново',
    '20571': 'с. Делова махала, общ. Златарица, обл. Велико Търново',
    '22890': 'с. Долно Шивачево, общ. Златарица, обл. Велико Търново',
    '24126': 'с. Дуровци, общ. Златарица, обл. Велико Търново',
    '24548': 'с. Дълги припек, общ. Златарица, обл. Велико Търново',
    '35273': 'с. Калайджии, общ. Златарица, обл. Велико Търново',
    '52057': 'с. Новогорци, общ. Златарица, обл. Велико Търново',
    '53165': 'с. Овощна, общ. Златарица, обл. Велико Търново',
    '61217': 'с. Равново, общ. Златарица, обл. Велико Търново',
    '61830': 'с. Разсоха, общ. Златарица, обл. Велико Търново',
    '62431': 'с. Резач, общ. Златарица, обл. Велико Търново',
    '62476': 'с. Рекичка, общ. Златарица, обл. Велико Търново',
    '62877': 'с. Родина, общ. Златарица, обл. Велико Търново',
    '63118': 'с. Росно, общ. Златарица, обл. Велико Търново',
    '67395': 'с. Сливовица, общ. Златарица, обл. Велико Търново',
    '68494': 'с. Средно село, общ. Златарица, обл. Велико Търново',
    '81339': 'с. Чешма, общ. Златарица, обл. Велико Търново',
    '81428': 'с. Чистово, общ. Златарица, обл. Велико Търново',
    '81668': 'с. Чуката, общ. Златарица, обл. Велико Търново',
    '44793': 'гр. Лясковец, общ. Лясковец, обл. Велико Търново',
    '20835': 'с. Джулюница, общ. Лясковец, обл. Велико Търново',
    '21453': 'с. Добри дял, общ. Лясковец, обл. Велико Търново',
    '23217': 'с. Драгижево, общ. Лясковец, обл. Велико Търново',
    '37664': 'с. Козаревец, общ. Лясковец, обл. Велико Търново',
    '47826': 'с. Мерданя, общ. Лясковец, обл. Велико Търново',
    '07716': 'гр. Бяла черква, общ. Павликени, обл. Велико Търново',
    '55052': 'гр. Павликени, общ. Павликени, обл. Велико Търново',
    '02823': 'с. Батак, общ. Павликени, обл. Велико Търново',
    '07123': 'с. Бутово, общ. Павликени, обл. Велико Търново',
    '11380': 'с. Вишовград, общ. Павликени, обл. Велико Търново',
    '12783': 'с. Върбовка, общ. Павликени, обл. Велико Търново',
    '16290': 'с. Горна Липница, общ. Павликени, обл. Велико Търново',
    '21138': 'с. Димча, общ. Павликени, обл. Велико Търново',
    '22160': 'с. Долна Липница, общ. Павликени, обл. Велико Търново',
    '24685': 'с. Дъскот, общ. Павликени, обл. Велико Търново',
    '36289': 'с. Караисен, общ. Павликени, обл. Велико Търново',
    '43356': 'с. Лесичери, общ. Павликени, обл. Велико Търново',
    '48550': 'с. Михалци, общ. Павликени, обл. Велико Търново',
    '49429': 'с. Мусина, общ. Павликени, обл. Велико Търново',
    '51295': 'с. Недан, общ. Павликени, обл. Велико Търново',
    '55508': 'с. Паскалевец, общ. Павликени, обл. Велико Търново',
    '55573': 'с. Патреш, общ. Павликени, обл. Велико Търново',
    '63080': 'с. Росица, общ. Павликени, обл. Велико Търново',
    '67475': 'с. Сломер, общ. Павликени, обл. Велико Търново',
    '68708': 'с. Стамболово, общ. Павликени, обл. Велико Търново',
    '57354': 'гр. Полски Тръмбеш, общ. Полски Тръмбеш, обл. Велико Търново',
    '12869': 'с. Вързулица, общ. Полски Тръмбеш, обл. Велико Търново',
    '32175': 'с. Иванча, общ. Полски Тръмбеш, обл. Велико Търново',
    '36405': 'с. Каранци, общ. Полски Тръмбеш, обл. Велико Търново',
    '41246': 'с. Климентово, общ. Полски Тръмбеш, обл. Велико Търново',
    '40782': 'с. Куцина, общ. Полски Тръмбеш, обл. Велико Търново',
    '47439': 'с. Масларево, общ. Полски Тръмбеш, обл. Велико Търново',
    '53014': 'с. Обединение, общ. Полски Тръмбеш, обл. Велико Търново',
    '53905': 'с. Орловец, общ. Полски Тръмбеш, обл. Велико Търново',
    '55018': 'с. Павел, общ. Полски Тръмбеш, обл. Велико Търново',
    '65471': 'с. Петко Каравелово, общ. Полски Тръмбеш, обл. Велико Търново',
    '57340': 'с. Полски Сеновец, общ. Полски Тръмбеш, обл. Велико Търново',
    '61279': 'с. Раданово, общ. Полски Тръмбеш, обл. Велико Търново',
    '17467': 'с. Стефан Стамболово, общ. Полски Тръмбеш, обл. Велико Търново',
    '69732': 'с. Страхилово, общ. Полски Тръмбеш, обл. Велико Търново',
    '65766': 'гр. Свищов, общ. Свищов, обл. Велико Търново',
    '00237': 'с. Алеково, общ. Свищов, обл. Велико Търново',
    '00285': 'с. Александрово, общ. Свищов, обл. Велико Търново',
    '07363': 'с. Българско Сливово, общ. Свищов, обл. Велико Търново',
    '16393': 'с. Горна Студена, общ. Свищов, обл. Велико Търново',
    '20630': 'с. Деляновци, общ. Свищов, обл. Велико Търново',
    '23431': 'с. Драгомирово, общ. Свищов, обл. Велико Търново',
    '37784': 'с. Козловец, общ. Свищов, обл. Велико Търново',
    '49028': 'с. Морава, общ. Свищов, обл. Велико Търново',
    '53196': 'с. Овча могила, общ. Свищов, обл. Велико Търново',
    '53672': 'с. Ореш, общ. Свищов, обл. Велико Търново',
    '67787': 'с. Совата, общ. Свищов, обл. Велико Търново',
    '77013': 'с. Хаджидимитрово, общ. Свищов, обл. Велико Търново',
    '78121': 'с. Царевец, общ. Свищов, обл. Велико Търново',
    '80457': 'с. Червена, общ. Свищов, обл. Велико Търново',
    '69633': 'гр. Стражица, общ. Стражица, обл. Велико Търново',
    '00730': 'с. Асеново, общ. Стражица, обл. Велико Търново',
    '02470': 'с. Балканци, общ. Стражица, обл. Велико Търново',
    '04282': 'с. Благоево, общ. Стражица, обл. Велико Търново',
    '06731': 'с. Бряговица, общ. Стражица, обл. Велико Търново',
    '11140': 'с. Виноград, общ. Стражица, обл. Велико Търново',
    '11466': 'с. Владислав, общ. Стражица, обл. Велико Търново',
    '11750': 'с. Водно, общ. Стражица, обл. Велико Търново',
    '17155': 'с. Горски Сеновец, общ. Стражица, обл. Велико Търново',
    '29091': 'с. Железарци, общ. Стражица, обл. Велико Търново',
    '35078': 'с. Кавлак, общ. Стражица, обл. Велико Търново',
    '35657': 'с. Камен, общ. Стражица, обл. Велико Търново',
    '36782': 'с. Кесарево, общ. Стражица, обл. Велико Търново',
    '44046': 'с. Лозен, общ. Стражица, обл. Велико Търново',
    '44567': 'с. Любенци, общ. Стражица, обл. Велико Търново',
    '48341': 'с. Мирово, общ. Стражица, обл. Велико Търново',
    '51593': 'с. Николаево, общ. Стражица, обл. Велико Търново',
    '51799': 'с. Нова Върбовка, общ. Стражица, обл. Велико Търново',
    '52060': 'с. Ново градище, общ. Стражица, обл. Велико Търново',
    '70408': 'с. Сушица, общ. Стражица, обл. Велико Търново',
    '72237': 'с. Теменуга, общ. Стражица, обл. Велико Търново',
    '80203': 'с. Царски извор, общ. Стражица, обл. Велико Търново',
    '70295': 'гр. Сухиндол, общ. Сухиндол, обл. Велико Търново',
    '07661': 'с. Бяла река, общ. Сухиндол, обл. Велико Търново',
    '17192': 'с. Горско Калугерово, общ. Сухиндол, обл. Велико Търново',
    '17208': 'с. Горско Косово, общ. Сухиндол, обл. Велико Търново',
    '37561': 'с. Коевци, общ. Сухиндол, обл. Велико Търново',
    '39582': 'с. Красно градище, общ. Сухиндол, обл. Велико Търново',
    '03616': 'гр. Белоградчик, общ. Белоградчик, обл. Видин',
    '05582': 'с. Боровица, общ. Белоградчик, обл. Видин',
    '10937': 'с. Вещица, общ. Белоградчик, обл. Видин',
    '12632': 'с. Върба, общ. Белоградчик, обл. Видин',
    '17734': 'с. Гранитово, общ. Белоградчик, обл. Видин',
    '17765': 'с. Граничак, общ. Белоградчик, обл. Видин',
    '24431': 'с. Дъбравка, общ. Белоградчик, обл. Видин',
    '39606': 'с. Крачимир, общ. Белоградчик, обл. Видин',
    '54517': 'с. Ошане, общ. Белоградчик, обл. Видин',
    '58092': 'с. Праужда, общ. Белоградчик, обл. Видин',
    '58582': 'с. Пролазница, общ. Белоградчик, обл. Видин',
    '61011': 'с. Рабиша, общ. Белоградчик, обл. Видин',
    '62339': 'с. Раяновци, общ. Белоградчик, обл. Видин',
    '65173': 'с. Салаш, общ. Белоградчик, обл. Видин',
    '67400': 'с. Сливовник, общ. Белоградчик, обл. Видин',
    '68655': 'с. Стакевци, общ. Белоградчик, обл. Видин',
    '69910': 'с. Струиндол, общ. Белоградчик, обл. Видин',
    '81459': 'с. Чифлик, общ. Белоградчик, обл. Видин',
    '05195': 'с. Бойница, общ. Бойница, обл. Видин',
    '05428': 'с. Бориловец, общ. Бойница, обл. Видин',
    '17614': 'с. Градсковски колиби, общ. Бойница, обл. Видин',
    '36049': 'с. Каниц, общ. Бойница, обл. Видин',
    '55868': 'с. Периловец, общ. Бойница, обл. Видин',
    '61039': 'с. Раброво, общ. Бойница, обл. Видин',
    '83185': 'с. Шипикова махала, общ. Бойница, обл. Видин',
    '83329': 'с. Шишенци, общ. Бойница, обл. Видин',
    '06224': 'гр. Брегово, общ. Брегово, обл. Видин',
    '02395': 'с. Балей, общ. Брегово, обл. Видин',
    '12317': 'с. Връв, общ. Брегово, обл. Видин',
    '18304': 'с. Гъмзово, общ. Брегово, обл. Видин',
    '20568': 'с. Делейна, общ. Брегово, обл. Видин',
    '35384': 'с. Калина, общ. Брегово, обл. Видин',
    '38772': 'с. Косово, общ. Брегово, обл. Видин',
    '40441': 'с. Куделин, общ. Брегово, обл. Видин',
    '63700': 'с. Ракитница, общ. Брегово, обл. Видин',
    '72518': 'с. Тияновци, общ. Брегово, обл. Видин',
    '10971': 'гр. Видин, общ. Видин, обл. Видин',
    '24061': 'гр. Дунавци, общ. Видин, обл. Видин',
    '00179': 'с. Акациево, общ. Видин, обл. Видин',
    '00919': 'с. Антимово, общ. Видин, обл. Видин',
    '03280': 'с. Бела Рада, общ. Видин, обл. Видин',
    '05832': 'с. Ботево, общ. Видин, обл. Видин',
    '06954': 'с. Буковец, общ. Видин, обл. Видин',
    '11925': 'с. Войница, общ. Видин, обл. Видин',
    '12958': 'с. Въртоп, общ. Видин, обл. Видин',
    '14369': 'с. Гайтанци, общ. Видин, обл. Видин',
    '14698': 'с. Генерал Мариново, общ. Видин, обл. Видин',
    '15998': 'с. Гомотарци, общ. Видин, обл. Видин',
    '17422': 'с. Градец, общ. Видин, обл. Видин',
    '21186': 'с. Динковица, общ. Видин, обл. Видин',
    '22318': 'с. Долни Бошняк, общ. Видин, обл. Видин',
    '23786': 'с. Дружба, общ. Видин, обл. Видин',
    '29043': 'с. Жеглица, общ. Видин, обл. Видин',
    '32130': 'с. Ивановци, общ. Видин, обл. Видин',
    '32754': 'с. Иново, общ. Видин, обл. Видин',
    '35328': 'с. Каленик, общ. Видин, обл. Видин',
    '36141': 'с. Капитановци, общ. Видин, обл. Видин',
    '39147': 'с. Кошава, общ. Видин, обл. Видин',
    '41263': 'с. Кутово, общ. Видин, обл. Видин',
    '46111': 'с. Майор Узуново, общ. Видин, обл. Видин',
    '52283': 'с. Новоселци, общ. Видин, обл. Видин',
    '56252': 'с. Пешаково, общ. Видин, обл. Видин',
    '56616': 'с. Плакудер, общ. Видин, обл. Видин',
    '57131': 'с. Покрайна, общ. Видин, обл. Видин',
    '63358': 'с. Рупци, общ. Видин, обл. Видин',
    '66473': 'с. Синаговци, общ. Видин, обл. Видин',
    '67180': 'с. Слана бара, общ. Видин, обл. Видин',
    '67194': 'с. Сланотрън, общ. Видин, обл. Видин',
    '73746': 'с. Търняне, общ. Видин, обл. Видин',
    '66442': 'с. Цар Симеоново, общ. Видин, обл. Видин',
    '17645': 'гр. Грамада, общ. Грамада, обл. Видин',
    '05997': 'с. Бояново, общ. Грамада, обл. Видин',
    '06121': 'с. Бранковци, общ. Грамада, обл. Видин',
    '11720': 'с. Водна, общ. Грамада, обл. Видин',
    '47583': 'с. Медешевци, общ. Грамада, обл. Видин',
    '48266': 'с. Милчина лъка, общ. Грамада, обл. Видин',
    '68268': 'с. Срацимирово, общ. Грамада, обл. Видин',
    '72919': 'с. Тошевци, общ. Грамада, обл. Видин',
    '21097': 'гр. Димово, общ. Димово, обл. Видин',
    '00672': 'с. Арчар, общ. Димово, обл. Видин',
    '03263': 'с. Бела, общ. Димово, обл. Видин',
    '11497': 'с. Владиченци, общ. Димово, обл. Видин',
    '11778': 'с. Воднянци, общ. Димово, обл. Видин',
    '12824': 'с. Върбовчец, общ. Димово, обл. Видин',
    '24579': 'с. Дълго поле, общ. Димово, обл. Видин',
    '24640': 'с. Държаница, общ. Димово, обл. Видин',
    '32370': 'с. Извор, общ. Димово, обл. Видин',
    '36440': 'с. Карбинци, общ. Димово, обл. Видин',
    '37188': 'с. Кладоруб, общ. Димово, обл. Видин',
    '38995': 'с. Костичовци, общ. Димово, обл. Видин',
    '43027': 'с. Лагошевци, общ. Димово, обл. Видин',
    '46320': 'с. Мали Дреновец, общ. Димово, обл. Видин',
    '47648': 'с. Медовница, общ. Димово, обл. Видин',
    '53758': 'с. Орешец, общ. Димово, обл. Видин',
    '14489': 'с. Орешец, общ. Димово, обл. Видин',
    '54434': 'с. Острокапци, общ. Димово, обл. Видин',
    '66278': 'с. Септемврийци, общ. Димово, обл. Видин',
    '66843': 'с. Скомля, общ. Димово, обл. Видин',
    '83239': 'с. Шипот, общ. Димово, обл. Видин',
    '87477': 'с. Яньовец, общ. Димово, обл. Видин',
    '87549': 'с. Ярловица, общ. Димово, обл. Видин',
    '40525': 'гр. Кула, общ. Кула, обл. Видин',
    '15415': 'с. Големаново, общ. Кула, обл. Видин',
    '32439': 'с. Извор махала, общ. Кула, обл. Видин',
    '38858': 'с. Коста Перчево, общ. Кула, обл. Видин',
    '57193': 'с. Полетковци, общ. Кула, обл. Видин',
    '69002': 'с. Старопатица, общ. Кула, обл. Видин',
    '72775': 'с. Тополовец, общ. Кула, обл. Видин',
    '78224': 'с. Цар-Петрово, общ. Кула, обл. Видин',
    '81503': 'с. Чичил, общ. Кула, обл. Видин',
    '12557': 'с. Вълчек, общ. Макреш, обл. Видин',
    '36885': 'с. Киреево, общ. Макреш, обл. Видин',
    '46245': 'с. Макреш, общ. Макреш, обл. Видин',
    '56980': 'с. Подгоре, общ. Макреш, обл. Видин',
    '62044': 'с. Раковица, общ. Макреш, обл. Видин',
    '72638': 'с. Толовица, общ. Макреш, обл. Видин',
    '83363': 'с. Цар Шишманово, общ. Макреш, обл. Видин',
    '11079': 'с. Винарово, общ. Ново село, обл. Видин',
    '51264': 'с. Неговановци, общ. Ново село, обл. Видин',
    '52180': 'с. Ново село, общ. Ново село, обл. Видин',
    '76145': 'с. Флорентин, общ. Ново село, обл. Видин',
    '87583': 'с. Ясен, общ. Ново село, обл. Видин',
    '03678': 'с. Бело поле, общ. Ружинци, обл. Видин',
    '18486': 'с. Гюргич, общ. Ружинци, обл. Видин',
    '21193': 'с. Динково, общ. Ружинци, обл. Видин',
    '23515': 'с. Дражинци, общ. Ружинци, обл. Видин',
    '23672': 'с. Дреновец, общ. Ружинци, обл. Видин',
    '56753': 'с. Плешивец, общ. Ружинци, обл. Видин',
    '62791': 'с. Роглец, общ. Ружинци, обл. Видин',
    '63255': 'с. Ружинци, общ. Ружинци, обл. Видин',
    '73924': 'с. Тополовец, общ. Ружинци, обл. Видин',
    '81247': 'с. Черно поле, общ. Ружинци, обл. Видин',
    '12797': 'с. Върбово, общ. Чупрене, обл. Видин',
    '16571': 'с. Горни Лом, общ. Чупрене, обл. Видин',
    '22424': 'с. Долни Лом, общ. Чупрене, обл. Видин',
    '58671': 'с. Протопопинци, общ. Чупрене, обл. Видин',
    '62493': 'с. Репляна, общ. Чупрене, обл. Видин',
    '68518': 'с. Средогрив, общ. Чупрене, обл. Видин',
    '73612': 'с. Търговище, общ. Чупрене, обл. Видин',
    '81757': 'с. Чупрене, общ. Чупрене, обл. Видин',
    '05548': 'с. Борован, общ. Борован, обл. Враца',
    '21600': 'с. Добролево, общ. Борован, обл. Враца',
    '46810': 'с. Малорад, общ. Борован, обл. Враца',
    '51559': 'с. Нивянин, общ. Борован, обл. Враца',
    '66617': 'с. Сираково, общ. Борован, обл. Враца',
    '07702': 'гр. Бяла Слатина, общ. Бяла Слатина, обл. Враца',
    '00401': 'с. Алтимир, общ. Бяла Слатина, обл. Враца',
    '06968': 'с. Буковец, общ. Бяла Слатина, обл. Враца',
    '07418': 'с. Бърдарски геран, общ. Бяла Слатина, обл. Враца',
    '07538': 'с. Бъркачево, общ. Бяла Слатина, обл. Враца',
    '12214': 'с. Враняк, общ. Бяла Слатина, обл. Враца',
    '14012': 'с. Габаре, общ. Бяла Слатина, обл. Враца',
    '14406': 'с. Галиче, общ. Бяла Слатина, обл. Враца',
    '23618': 'с. Драшан, общ. Бяла Слатина, обл. Враца',
    '38128': 'с. Комарево, общ. Бяла Слатина, обл. Враца',
    '57594': 'с. Попица, общ. Бяла Слатина, обл. Враца',
    '67845': 'с. Соколаре, общ. Бяла Слатина, обл. Враца',
    '72521': 'с. Тлачене, общ. Бяла Слатина, обл. Враца',
    '73643': 'с. Търнава, общ. Бяла Слатина, обл. Враца',
    '73660': 'с. Търнак, общ. Бяла Слатина, обл. Враца',
    '12259': 'гр. Враца, общ. Враца, обл. Враца',
    '02590': 'с. Баница, общ. Враца, обл. Враца',
    '03438': 'с. Бели извор, общ. Враца, обл. Враца',
    '10789': 'с. Веслец, общ. Враца, обл. Враца',
    '11185': 'с. Вировско, общ. Враца, обл. Враца',
    '11555': 'с. Власатица, общ. Враца, обл. Враца',
    '12749': 'с. Върбица, общ. Враца, обл. Враца',
    '15521': 'с. Голямо Пещене, общ. Враца, обл. Враца',
    '16897': 'с. Горно Пещене, общ. Враца, обл. Враца',
    '20376': 'с. Девене, общ. Враца, обл. Враца',
    '30606': 'с. Згориград, общ. Враца, обл. Враца',
    '38875': 'с. Костелево, общ. Враца, обл. Враца',
    '43712': 'с. Лиляче, общ. Враца, обл. Враца',
    '44728': 'с. Лютаджик, общ. Враца, обл. Враца',
    '46807': 'с. Мало Пещене, общ. Враца, обл. Враца',
    '49223': 'с. Мраморен, общ. Враца, обл. Враца',
    '51528': 'с. Нефела, общ. Враца, обл. Враца',
    '54482': 'с. Оходен, общ. Враца, обл. Враца',
    '55070': 'с. Паволче, общ. Враца, обл. Враца',
    '72504': 'с. Тишевица, общ. Враца, обл. Враца',
    '73119': 'с. Три кладенци, общ. Враца, обл. Враца',
    '80311': 'с. Челопек, общ. Враца, обл. Враца',
    '81400': 'с. Чирен, общ. Враца, обл. Враца',
    '37798': 'гр. Козлодуй, общ. Козлодуй, обл. Враца',
    '07116': 'с. Бутан, общ. Козлодуй, обл. Враца',
    '18505': 'с. Гложене, общ. Козлодуй, обл. Враца',
    '39730': 'с. Крива бара, общ. Козлодуй, обл. Враца',
    '77548': 'с. Хърлец, общ. Козлодуй, обл. Враца',
    '39846': 'гр. Криводол, общ. Криводол, обл. Враца',
    '02912': 'с. Баурене, общ. Криводол, обл. Враца',
    '05894': 'с. Ботуня, общ. Криводол, обл. Враца',
    '14390': 'с. Галатин, общ. Криводол, обл. Враца',
    '15014': 'с. Главаци, общ. Криводол, обл. Враца',
    '15494': 'с. Големо Бабино, общ. Криводол, обл. Враца',
    '17453': 'с. Градешница, общ. Криводол, обл. Враца',
    '21734': 'с. Добруша, общ. Криводол, обл. Враца',
    '39236': 'с. Краводер, общ. Криводол, обл. Враца',
    '43462': 'с. Лесура, общ. Криводол, обл. Враца',
    '54064': 'с. Осен, общ. Криводол, обл. Враца',
    '58788': 'с. Пудрия, общ. Криводол, обл. Враца',
    '61933': 'с. Ракево, общ. Криводол, обл. Враца',
    '75143': 'с. Уровене, общ. Криводол, обл. Враца',
    '76193': 'с. Фурен, общ. Криводол, обл. Враца',
    '47714': 'гр. Мездра, общ. Мездра, обл. Враца',
    '04827': 'с. Боденец, общ. Мездра, обл. Враца',
    '06598': 'с. Брусен, общ. Мездра, обл. Враца',
    '12704': 'с. Върбешница, общ. Мездра, обл. Враца',
    '16122': 'с. Горна Бешовица, общ. Мездра, обл. Враца',
    '16256': 'с. Горна Кремена, общ. Мездра, обл. Враца',
    '22126': 'с. Долна Кремена, общ. Мездра, обл. Враца',
    '24668': 'с. Дърманци, общ. Мездра, обл. Враца',
    '27317': 'с. Елисейна, общ. Мездра, обл. Враца',
    '30510': 'с. Зверино, общ. Мездра, обл. Враца',
    '31190': 'с. Злидол, общ. Мездра, обл. Враца',
    '32281': 'с. Игнатица, общ. Мездра, обл. Враца',
    '35314': 'с. Кален, общ. Мездра, обл. Враца',
    '39483': 'с. Крапец, общ. Мездра, обл. Враца',
    '39709': 'с. Крета, общ. Мездра, обл. Враца',
    '43654': 'с. Лик, общ. Мездра, обл. Враца',
    '44745': 'с. Лютиброд, общ. Мездра, обл. Враца',
    '44759': 'с. Лютидол, общ. Мездра, обл. Враца',
    '49031': 'с. Моравица, общ. Мездра, обл. Враца',
    '54047': 'с. Оселна, общ. Мездра, обл. Враца',
    '54256': 'с. Ослен Криводол, общ. Мездра, обл. Враца',
    '54506': 'с. Очиндол, общ. Мездра, обл. Враца',
    '62390': 'с. Ребърково, общ. Мездра, обл. Враца',
    '63450': 'с. Руска Бела, общ. Мездра, обл. Враца',
    '69050': 'с. Старо село, общ. Мездра, обл. Враца',
    '72432': 'с. Типченица, общ. Мездра, обл. Враца',
    '78015': 'с. Цаконица, общ. Мездра, обл. Враца',
    '78135': 'с. Царевец, общ. Мездра, обл. Враца',
    '48043': 'гр. Мизия, общ. Мизия, обл. Враца',
    '11853': 'с. Войводово, общ. Мизия, обл. Враца',
    '40200': 'с. Крушовица, общ. Мизия, обл. Враца',
    '43774': 'с. Липница, общ. Мизия, обл. Враца',
    '65396': 'с. Сараево, общ. Мизия, обл. Враца',
    '68148': 'с. Софрониево, общ. Мизия, обл. Враца',
    '54020': 'гр. Оряхово, общ. Оряхово, обл. Враца',
    '14427': 'с. Галово, общ. Оряхово, обл. Враца',
    '16451': 'с. Горни Вадин, общ. Оряхово, обл. Враца',
    '22321': 'с. Долни Вадин, общ. Оряхово, обл. Враца',
    '43400': 'с. Лесковец, общ. Оряхово, обл. Враца',
    '54386': 'с. Остров, общ. Оряхово, обл. Враца',
    '70723': 'с. Селановци, общ. Оряхово, обл. Враца',
    '62997': 'гр. Роман, общ. Роман, обл. Враца',
    '22023': 'с. Долна Бешовица, общ. Роман, обл. Враца',
    '35910': 'с. Камено поле, общ. Роман, обл. Враца',
    '36436': 'с. Караш, общ. Роман, обл. Враца',
    '40645': 'с. Кунино, общ. Роман, обл. Враца',
    '40693': 'с. Курново, общ. Роман, обл. Враца',
    '47322': 'с. Марково равнище, общ. Роман, обл. Враца',
    '61488': 'с. Радовене, общ. Роман, обл. Враца',
    '66576': 'с. Синьо бърдо, общ. Роман, обл. Враца',
    '68432': 'с. Средни рът, общ. Роман, обл. Враца',
    '69554': 'с. Стояновци, общ. Роман, обл. Враца',
    '69972': 'с. Струпец, общ. Роман, обл. Враца',
    '77493': 'с. Хубавене, общ. Роман, обл. Враца',
    '05846': 'с. Ботево, общ. Хайредин, обл. Враца',
    '07497': 'с. Бързина, общ. Хайредин, обл. Враца',
    '47010': 'с. Манастирище, общ. Хайредин, обл. Враца',
    '48492': 'с. Михайлово, общ. Хайредин, обл. Враца',
    '62815': 'с. Рогозен, общ. Хайредин, обл. Враца',
    '77102': 'с. Хайредин, общ. Хайредин, обл. Враца',
    '14218': 'гр. Габрово, общ. Габрово, обл. Габрово',
    '00456': 'с. Ангелов, общ. Габрово, обл. Габрово',
    '00638': 'с. Армените, общ. Габрово, обл. Габрово',
    '02207': 'с. Баевци, общ. Габрово, обл. Габрово',
    '02347': 'с. Баланите, общ. Габрово, обл. Габрово',
    '02419': 'с. Балиновци, общ. Габрово, обл. Габрово',
    '02631': 'с. Банковци, общ. Габрово, обл. Габрово',
    '03246': 'с. Бекриите, общ. Габрово, обл. Габрово',
    '03650': 'с. Беломъжите, общ. Габрово, обл. Габрово',
    '04488': 'с. Бобевци, общ. Габрово, обл. Габрово',
    '04683': 'с. Богданчовци, общ. Габрово, обл. Габрово',
    '04964': 'с. Боженците, общ. Габрово, обл. Габрово',
    '05219': 'с. Бойновци, общ. Габрово, обл. Габрово',
    '07908': 'с. Бойчета, общ. Габрово, обл. Габрово',
    '05267': 'с. Болтата, общ. Габрово, обл. Габрово',
    '05400': 'с. Борики, общ. Габрово, обл. Габрово',
    '05668': 'с. Борското, общ. Габрово, обл. Габрово',
    '06642': 'с. Брънеците, общ. Габрово, обл. Габрово',
    '07795': 'с. Бялково, общ. Габрово, обл. Габрово',
    '10536': 'с. Велковци, общ. Габрово, обл. Габрово',
    '10896': 'с. Ветрово, общ. Габрово, обл. Габрово',
    '11524': 'с. Влайчовци, общ. Габрово, обл. Габрово',
    '11586': 'с. Влаховци, общ. Габрово, обл. Габрово',
    '12111': 'с. Врабците, общ. Габрово, обл. Габрово',
    '12168': 'с. Враниловци, общ. Габрово, обл. Габрово',
    '12454': 'с. Вълков дол, общ. Габрово, обл. Габрово',
    '14324': 'с. Гайкини, общ. Габрово, обл. Габрово',
    '14355': 'с. Гайтаните, общ. Габрово, обл. Габрово',
    '14533': 'с. Гарван, общ. Габрово, обл. Габрово',
    '14742': 'с. Геновци, общ. Габрово, обл. Габрово',
    '14756': 'с. Генчовци, общ. Габрово, обл. Габрово',
    '14814': 'с. Гергини, общ. Габрово, обл. Габрово',
    '15059': 'с. Гледаци, общ. Габрово, обл. Габрово',
    '16715': 'с. Горнова могила, общ. Габрово, обл. Габрово',
    '17991': 'с. Гръблевци, общ. Габрово, обл. Габрово',
    '18215': 'с. Гъбене, общ. Габрово, обл. Габрово',
    '20225': 'с. Дебел дял, общ. Габрово, обл. Габрово',
    '20852': 'с. Джумриите, общ. Габрово, обл. Габрово',
    '20969': 'с. Дивеци, общ. Габрово, обл. Габрово',
    '22959': 'с. Донино, общ. Габрово, обл. Габрово',
    '23159': 'с. Драгановци, общ. Габрово, обл. Габрово',
    '23185': 'с. Драганчетата, общ. Габрово, обл. Габрово',
    '23203': 'с. Драгиевци, общ. Габрово, обл. Габрово',
    '23412': 'с. Драгомани, общ. Габрово, обл. Габрово',
    '24854': 'с. Думници, общ. Габрово, обл. Габрово',
    '27677': 'с. Езерото, общ. Габрово, обл. Габрово',
    '29324': 'с. Живко, общ. Габрово, обл. Габрово',
    '29547': 'с. Жълтеш, общ. Габрово, обл. Габрово',
    '30661': 'с. Здравковец, общ. Габрово, обл. Габрово',
    '30764': 'с. Зелено дърво, общ. Габрово, обл. Габрово',
    '30973': 'с. Златевци, общ. Габрово, обл. Габрово',
    '32069': 'с. Иванили, общ. Габрово, обл. Габрово',
    '32072': 'с. Иванковци, общ. Габрово, обл. Габрово',
    '32233': 'с. Иглика, общ. Габрово, обл. Габрово',
    '32582': 'с. Източник, общ. Габрово, обл. Габрово',
    '35626': 'с. Калчовци, общ. Габрово, обл. Габрово',
    '35986': 'с. Камещица, общ. Габрово, обл. Габрово',
    '36292': 'с. Карали, общ. Габрово, обл. Габрово',
    '36823': 'с. Киевци, общ. Габрово, обл. Габрово',
    '37352': 'с. Кметовци, общ. Габрово, обл. Габрово',
    '37366': 'с. Кметчета, общ. Габрово, обл. Габрово',
    '37722': 'с. Кози рог, общ. Габрово, обл. Габрово',
    '38060': 'с. Колишовци, общ. Габрово, обл. Габрово',
    '38577': 'с. Копчелиите, общ. Габрово, обл. Габрово',
    '38813': 'с. Костадините, общ. Габрово, обл. Габрово',
    '38920': 'с. Костенковци, общ. Габрово, обл. Габрово',
    '43339': 'с. Лесичарка, общ. Габрово, обл. Габрово',
    '44015': 'с. Лоза, общ. Габрово, обл. Габрово',
    '46365': 'с. Малини, общ. Габрово, обл. Габрово',
    '49655': 'с. Малуша, общ. Габрово, обл. Габрово',
    '47706': 'с. Междени, общ. Габрово, обл. Габрово',
    '47994': 'с. Мечковица, общ. Габрово, обл. Габрово',
    '48235': 'с. Милковци, общ. Габрово, обл. Габрово',
    '48516': 'с. Михайловци, общ. Габрово, обл. Габрово',
    '48605': 'с. Мичковци, общ. Габрово, обл. Габрово',
    '49062': 'с. Моровеците, общ. Габрово, обл. Габрово',
    '49237': 'с. Мрахори, общ. Габрово, обл. Габрово',
    '49299': 'с. Музга, общ. Габрово, обл. Габрово',
    '51715': 'с. Николчовци, общ. Габрово, обл. Габрово',
    '51843': 'с. Новаковци, общ. Габрово, обл. Габрово',
    '53151': 'с. Овощарци, общ. Габрово, обл. Габрово',
    '53948': 'с. Орловци, общ. Габрово, обл. Габрово',
    '55498': 'с. Парчовци, общ. Габрово, обл. Габрово',
    '55717': 'с. Пейовци, общ. Габрово, обл. Габрово',
    '55796': 'с. Пенковци, общ. Габрово, обл. Габрово',
    '56191': 'с. Петровци, общ. Габрово, обл. Габрово',
    '56229': 'с. Пецовци, общ. Габрово, обл. Габрово',
    '57532': 'с. Попари, общ. Габрово, обл. Габрово',
    '57675': 'с. Поповци, общ. Габрово, обл. Габрово',
    '59327': 'с. Поток, общ. Габрово, обл. Габрово',
    '58102': 'с. Прахали, общ. Габрово, обл. Габрово',
    '59300': 'с. Продановци, общ. Габрово, обл. Габрово',
    '59135': 'с. Пъртевци, общ. Габрово, обл. Габрово',
    '61902': 'с. Райновци, общ. Габрово, обл. Габрово',
    '62245': 'с. Раховци, общ. Габрово, обл. Габрово',
    '62253': 'с. Рачевци, общ. Габрово, обл. Габрово',
    '62400': 'с. Редешковци, общ. Габрово, обл. Габрово',
    '63296': 'с. Руйчовци, общ. Габрово, обл. Габрово',
    '63690': 'с. Рязковци, общ. Габрово, обл. Габрово',
    '65680': 'с. Свинарски дол, общ. Габрово, обл. Габрово',
    '65989': 'с. Седянковци, общ. Габрово, обл. Габрово',
    '66007': 'с. Сейковци, общ. Габрово, обл. Габрово',
    '66161': 'с. Семерджиите, общ. Габрово, обл. Габрово',
    '67533': 'с. Смиловци, общ. Габрово, обл. Габрово',
    '67996': 'с. Солари, общ. Габрово, обл. Габрово',
    '68165': 'с. Спанци, общ. Габрово, обл. Габрово',
    '68210': 'с. Спасовци, общ. Габрово, обл. Габрово',
    '68919': 'с. Старилковци, общ. Габрово, обл. Габрово',
    '69211': 'с. Стефаново, общ. Габрово, обл. Габрово',
    '69287': 'с. Стоевци, общ. Габрово, обл. Габрово',
    '70768': 'с. Стойковци, общ. Габрово, обл. Габрово',
    '69403': 'с. Стойчовци, общ. Габрово, обл. Габрово',
    '69479': 'с. Стоманеците, общ. Габрово, обл. Габрово',
    '70442': 'с. Съботковци, общ. Габрово, обл. Габрово',
    '72597': 'с. Тодоровци, общ. Габрово, обл. Габрово',
    '81637': 'с. Тодорчета, общ. Габрово, обл. Габрово',
    '72878': 'с. Торбалъжите, общ. Габрово, обл. Габрово',
    '72998': 'с. Трапесковци, общ. Габрово, обл. Габрово',
    '73290': 'с. Трънито, общ. Габрово, обл. Габрово',
    '75099': 'с. Узуните, общ. Габрово, обл. Габрово',
    '76217': 'с. Фърговци, общ. Габрово, обл. Габрово',
    '77164': 'с. Харачерите, общ. Габрово, обл. Габрово',
    '78302': 'с. Цвятковци, общ. Габрово, обл. Габрово',
    '80056': 'с. Чавеи, общ. Габрово, обл. Габрово',
    '81904': 'с. Чарково, общ. Габрово, обл. Габрово',
    '81918': 'с. Червена локва, общ. Габрово, обл. Габрово',
    '80889': 'с. Черневци, общ. Габрово, обл. Габрово',
    '81431': 'с. Читаковци, общ. Габрово, обл. Габрово',
    '81685': 'с. Чукилите, общ. Габрово, обл. Габрово',
    '83034': 'с. Шарани, общ. Габрово, обл. Габрово',
    '83260': 'с. Шипчените, общ. Габрово, обл. Габрово',
    '87120': 'с. Яворец, общ. Габрово, обл. Габрово',
    '87432': 'с. Янковци, общ. Габрово, обл. Габрово',
    '87607': 'с. Ясените, общ. Габрово, обл. Габрово',
    '23947': 'гр. Дряново, общ. Дряново, обл. Габрово',
    '02333': 'с. Балалея, общ. Дряново, обл. Габрово',
    '02378': 'с. Балванците, общ. Дряново, обл. Габрово',
    '02556': 'с. Банари, общ. Дряново, обл. Габрово',
    '04068': 'с. Билкини, общ. Дряново, обл. Габрово',
    '07185': 'с. Бучуковци, общ. Дряново, обл. Габрово',
    '14458': 'с. Ганчовец, общ. Дряново, обл. Габрово',
    '14762': 'с. Геня, общ. Дряново, обл. Габрово',
    '14859': 'с. Геша, общ. Дряново, обл. Габрово',
    '15223': 'с. Глушка, общ. Дряново, обл. Габрово',
    '15357': 'с. Гоздейка, общ. Дряново, обл. Габрово',
    '15463': 'с. Големи Българени, общ. Дряново, обл. Габрово',
    '16479': 'с. Горни Върпища, общ. Дряново, обл. Габрово',
    '16523': 'с. Горни Драгойча, общ. Дряново, обл. Габрово',
    '17350': 'с. Гостилица, общ. Дряново, обл. Габрово',
    '18397': 'с. Гърня, общ. Дряново, обл. Габрово',
    '20660': 'с. Денчевци, общ. Дряново, обл. Габрово',
    '20907': 'с. Джуровци, общ. Дряново, обл. Габрово',
    '21261': 'с. Длъгня, общ. Дряново, обл. Габрово',
    '21395': 'с. Добрените, общ. Дряново, обл. Габрово',
    '22352': 'с. Долни Върпища, общ. Дряново, обл. Габрово',
    '22397': 'с. Долни Драгойча, общ. Дряново, обл. Габрово',
    '23042': 'с. Доча, общ. Дряново, обл. Габрово',
    '24133': 'с. Дурча, общ. Дряново, обл. Габрово',
    '27276': 'с. Еленците, общ. Дряново, обл. Габрово',
    '30418': 'с. Зая, общ. Дряново, обл. Габрово',
    '32319': 'с. Игнатовци, общ. Дряново, обл. Габрово',
    '32812': 'с. Искра, общ. Дряново, обл. Габрово',
    '35465': 'с. Каломен, общ. Дряново, обл. Габрово',
    '36261': 'с. Караиванца, общ. Дряново, обл. Габрово',
    '36628': 'с. Катранджии, общ. Дряново, обл. Габрово',
    '36751': 'с. Керека, общ. Дряново, обл. Габрово',
    '38710': 'с. Косарка, общ. Дряново, обл. Габрово',
    '38741': 'с. Косилка, общ. Дряново, обл. Габрово',
    '40316': 'с. Крънча, общ. Дряново, обл. Габрово',
    '40470': 'с. Кукля, общ. Дряново, обл. Габрово',
    '40587': 'с. Куманите, общ. Дряново, обл. Габрово',
    '40960': 'с. Къртипъня, общ. Дряново, обл. Габрово',
    '46485': 'с. Малки Българени, общ. Дряново, обл. Габрово',
    '47127': 'с. Маноя, общ. Дряново, обл. Габрово',
    '49463': 'с. Муця, общ. Дряново, обл. Габрово',
    '51439': 'с. Нейчовци, общ. Дряново, обл. Габрово',
    '55703': 'с. Пейна, общ. Дряново, обл. Габрово',
    '56023': 'с. Петковци, общ. Дряново, обл. Габрово',
    '56705': 'с. Плачка, общ. Дряново, обл. Габрово',
    '59152': 'с. Пърша, общ. Дряново, обл. Габрово',
    '61296': 'с. Раданчето, общ. Дряново, обл. Габрово',
    '61529': 'с. Радовци, общ. Дряново, обл. Габрово',
    '62726': 'с. Ритя, общ. Дряново, обл. Габрово',
    '63330': 'с. Руня, общ. Дряново, обл. Габрово',
    '63447': 'с. Русиновци, общ. Дряново, обл. Габрово',
    '65162': 'с. Саласука, общ. Дряново, обл. Габрово',
    '66768': 'с. Скалско, общ. Дряново, обл. Габрово',
    '66977': 'с. Славейково, общ. Дряново, обл. Габрово',
    '67934': 'с. Соколово, общ. Дряново, обл. Габрово',
    '68816': 'с. Станча, общ. Дряново, обл. Габрово',
    '70367': 'с. Сухолоевци, общ. Дряново, обл. Габрово',
    '70696': 'с. Сяровци, общ. Дряново, обл. Габрово',
    '73465': 'с. Туркинча, общ. Дряново, обл. Габрово',
    '12677': 'с. Царева ливада, общ. Дряново, обл. Габрово',
    '81726': 'с. Чуково, общ. Дряново, обл. Габрово',
    '83555': 'с. Шушня, общ. Дряново, обл. Габрово',
    '87463': 'с. Янтра, общ. Дряново, обл. Габрово',
    '65927': 'гр. Севлиево, общ. Севлиево, обл. Габрово',
    '00103': 'с. Агатово, общ. Севлиево, обл. Габрово',
    '02885': 'с. Батошево, общ. Севлиево, обл. Габрово',
    '03914': 'с. Бериево, общ. Севлиево, обл. Габрово',
    '04474': 'с. Боазът, общ. Севлиево, обл. Габрово',
    '04555': 'с. Богатово, общ. Севлиево, обл. Габрово',
    '07082': 'с. Буря, общ. Севлиево, обл. Габрово',
    '13069': 'с. Валевци, общ. Севлиево, обл. Габрово',
    '11960': 'с. Войнишка, общ. Севлиево, обл. Габрово',
    '16376': 'с. Горна Росица, общ. Севлиево, обл. Габрово',
    '17542': 'с. Градище, общ. Севлиево, обл. Габрово',
    '17587': 'с. Градница, общ. Севлиево, обл. Габрово',
    '20119': 'с. Дамяново, общ. Севлиево, обл. Габрово',
    '20287': 'с. Дебелцово, общ. Севлиево, обл. Габрово',
    '21227': 'с. Дисманица, общ. Севлиево, обл. Габрово',
    '21628': 'с. Добромирка, общ. Севлиево, обл. Габрово',
    '24178': 'с. Душево, общ. Севлиево, обл. Габрово',
    '24181': 'с. Душевски колиби, общ. Севлиево, обл. Габрово',
    '27485': 'с. Енев рът, общ. Севлиево, обл. Габрово',
    '32336': 'с. Идилево, общ. Севлиево, обл. Габрово',
    '36347': 'с. Карамичевци, общ. Севлиево, обл. Габрово',
    '36600': 'с. Кастел, общ. Севлиево, обл. Габрово',
    '38621': 'с. Корията, общ. Севлиево, обл. Габрово',
    '38652': 'с. Кормянско, общ. Севлиево, обл. Габрово',
    '39431': 'с. Крамолин, общ. Севлиево, обл. Габрово',
    '40141': 'с. Крушево, общ. Севлиево, обл. Габрово',
    '40275': 'с. Кръвеник, общ. Севлиево, обл. Габрово',
    '40659': 'с. Купен, общ. Севлиево, обл. Габрово',
    '43966': 'с. Ловнидол, общ. Севлиево, обл. Габрово',
    '46509': 'с. Малки Вършец, общ. Севлиево, обл. Габрово',
    '48670': 'с. Младен, общ. Севлиево, обл. Габрово',
    '48742': 'с. Млечево, общ. Севлиево, обл. Габрово',
    '56037': 'с. Петко Славейков, общ. Севлиево, обл. Габрово',
    '57755': 'с. Попска, общ. Севлиево, обл. Габрово',
    '63673': 'с. Ряховците, общ. Севлиево, обл. Габрово',
    '70799': 'с. Селище, общ. Севлиево, обл. Габрово',
    '66216': 'с. Сенник, общ. Севлиево, обл. Габрово',
    '69417': 'с. Стоките, общ. Севлиево, обл. Габрово',
    '69451': 'с. Столът, общ. Севлиево, обл. Габрово',
    '72031': 'с. Табашка, общ. Севлиево, обл. Габрово',
    '73434': 'с. Тумбалово, общ. Севлиево, обл. Габрово',
    '73763': 'с. Търхово, общ. Севлиево, обл. Габрово',
    '75037': 'с. Угорелец, общ. Севлиево, обл. Габрово',
    '77253': 'с. Хирево, общ. Севлиево, обл. Габрово',
    '83435': 'с. Шопите, общ. Севлиево, обл. Габрово',
    '83497': 'с. Шумата, общ. Севлиево, обл. Габрово',
    '56719': 'гр. Плачковци, общ. Трявна, обл. Габрово',
    '73403': 'гр. Трявна, общ. Трявна, обл. Габрово',
    '00117': 'с. Азманите, общ. Трявна, обл. Габрово',
    '00641': 'с. Армянковци, общ. Трявна, обл. Габрово',
    '02563': 'с. Бангейци, общ. Трявна, обл. Габрово',
    '02943': 'с. Бахреци, общ. Трявна, обл. Габрово',
    '03513': 'с. Белица, общ. Трявна, обл. Габрово',
    '04025': 'с. Бижовци, общ. Трявна, обл. Габрово',
    '06241': 'с. Брежниците, общ. Трявна, обл. Габрово',
    '07404': 'с. Бърдарите, общ. Трявна, обл. Габрово',
    '07421': 'с. Бърдени, общ. Трявна, обл. Габрово',
    '10344': 'с. Веленци, общ. Трявна, обл. Габрово',
    '10519': 'с. Велково, общ. Трявна, обл. Габрово',
    '10570': 'с. Велчовци, общ. Трявна, обл. Габрово',
    '11507': 'с. Владовци, общ. Трявна, обл. Габрово',
    '11541': 'с. Власатили, общ. Трявна, обл. Габрово',
    '11956': 'с. Войниците, общ. Трявна, обл. Габрово',
    '12485': 'с. Вълковци, общ. Трявна, обл. Габрово',
    '14310': 'с. Гайдари, общ. Трявна, обл. Габрово',
    '56304': 'с. Генчовци, общ. Трявна, обл. Габрово',
    '15196': 'с. Глутниците, общ. Трявна, обл. Габрово',
    '15480': 'с. Големи Станчовци, общ. Трявна, обл. Габрово',
    '16506': 'с. Горни Дамяновци, общ. Трявна, обл. Габрово',
    '16585': 'с. Горни Маренци, общ. Трявна, обл. Габрово',
    '16609': 'с. Горни Радковци, общ. Трявна, обл. Габрово',
    '16643': 'с. Горни Цоневци, общ. Трявна, обл. Габрово',
    '17316': 'с. Горяни, общ. Трявна, обл. Габрово',
    '20078': 'с. Даевци, общ. Трявна, обл. Габрово',
    '21035': 'с. Димиевци, общ. Трявна, обл. Габрово',
    '21378': 'с. Добревци, общ. Трявна, обл. Габрово',
    '22441': 'с. Долни Маренци, общ. Трявна, обл. Габрово',
    '22486': 'с. Долни Радковци, общ. Трявна, обл. Габрово',
    '22527': 'с. Долни Томчевци, общ. Трявна, обл. Габрово',
    '22962': 'с. Донкино, общ. Трявна, обл. Габрово',
    '22993': 'с. Дончовци, общ. Трявна, обл. Габрово',
    '23279': 'с. Драгневци, общ. Трявна, обл. Габрово',
    '23594': 'с. Драндарите, общ. Трявна, обл. Габрово',
    '24606': 'с. Дървари, общ. Трявна, обл. Габрово',
    '24671': 'с. Дъскарите, общ. Трявна, обл. Габрово',
    '27526': 'с. Енчовци, общ. Трявна, обл. Габрово',
    '30733': 'с. Зеленика, общ. Трявна, обл. Габрово',
    '32041': 'с. Иван Димов, общ. Трявна, обл. Габрово',
    '32785': 'с. Иринеци, общ. Трявна, обл. Габрово',
    '34062': 'с. Йововци, общ. Трявна, обл. Габрово',
    '36720': 'с. Кашенци, общ. Трявна, обл. Габрово',
    '36765': 'с. Керените, общ. Трявна, обл. Габрово',
    '36988': 'с. Киселковци, общ. Трявна, обл. Габрово',
    '36991': 'с. Кисийците, общ. Трявна, обл. Габрово',
    '37575': 'с. Коевци, общ. Трявна, обл. Габрово',
    '37890': 'с. Койчовци, общ. Трявна, обл. Габрово',
    '38090': 'с. Колю Ганев, общ. Трявна, обл. Габрово',
    '38234': 'с. Конарското, общ. Трявна, обл. Габрово',
    '39685': 'с. Креслювци, общ. Трявна, обл. Габрово',
    '40350': 'с. Кръстеняците, общ. Трявна, обл. Габрово',
    '40364': 'с. Кръстец, общ. Трявна, обл. Габрово',
    '46512': 'с. Малки Станчовци, общ. Трявна, обл. Габрово',
    '46869': 'с. Малчовци, общ. Трявна, обл. Габрово',
    '47072': 'с. Маневци, общ. Трявна, обл. Габрово',
    '47384': 'с. Маруцековци, общ. Трявна, обл. Габрово',
    '47456': 'с. Матешовци, общ. Трявна, обл. Габрово',
    '48177': 'с. Милевци, общ. Трявна, обл. Габрово',
    '48581': 'с. Миховци, общ. Трявна, обл. Габрово',
    '48800': 'с. Могилите, общ. Трявна, обл. Габрово',
    '49271': 'с. Мръзеци, общ. Трявна, обл. Габрово',
    '51370': 'с. Недялковци, общ. Трявна, обл. Габрово',
    '51469': 'с. Неновци, общ. Трявна, обл. Габрово',
    '51562': 'с. Никачковци, общ. Трявна, обл. Габрово',
    '51603': 'с. Николаево, общ. Трявна, обл. Габрово',
    '52355': 'с. Ножерите, общ. Трявна, обл. Габрово',
    '52369': 'с. Носеите, общ. Трявна, обл. Габрово',
    '53477': 'с. Околиите, общ. Трявна, обл. Габрово',
    '54523': 'с. Ошаните, общ. Трявна, обл. Габрово',
    '55049': 'с. Павлевци, общ. Трявна, обл. Габрово',
    '56678': 'с. Планинци, общ. Трявна, обл. Габрово',
    '56900': 'с. Побък, общ. Трявна, обл. Габрово',
    '57546': 'с. Попгергевци, общ. Трявна, обл. Габрово',
    '57707': 'с. Попрайковци, общ. Трявна, обл. Габрово',
    '58284': 'с. Престой, общ. Трявна, обл. Габрово',
    '59104': 'с. Пържиграх, общ. Трявна, обл. Габрово',
    '61323': 'с. Радевци, общ. Трявна, обл. Габрово',
    '61409': 'с. Радино, общ. Трявна, обл. Габрово',
    '61546': 'с. Радоевци, общ. Трявна, обл. Габрово',
    '61649': 'с. Раевци, общ. Трявна, обл. Габрово',
    '61916': 'с. Райнушковци, общ. Трявна, обл. Габрово',
    '62120': 'с. Ралевци, общ. Трявна, обл. Габрово',
    '62267': 'с. Рачовци, общ. Трявна, обл. Габрово',
    '62308': 'с. Рашовите, общ. Трявна, обл. Габрово',
    '63210': 'с. Руевци, общ. Трявна, обл. Габрово',
    '65735': 'с. Свирци, общ. Трявна, обл. Габрово',
    '66344': 'с. Сечен камък, общ. Трявна, обл. Габрово',
    '66857': 'с. Скорците, общ. Трявна, обл. Габрово',
    '67427': 'с. Сливово, общ. Трявна, обл. Габрово',
    '68624': 'с. Стайновци, общ. Трявна, обл. Габрово',
    '68823': 'с. Станчов хан, общ. Трявна, обл. Габрово',
    '69609': 'с. Стражата, общ. Трявна, обл. Габрово',
    '70007': 'с. Стръмци, общ. Трявна, обл. Габрово',
    '72535': 'с. Тодореците, общ. Трявна, обл. Габрово',
    '72669': 'с. Томчевци, общ. Трявна, обл. Габрово',
    '75139': 'с. Урвата, общ. Трявна, обл. Габрово',
    '76220': 'с. Фъревци, общ. Трявна, обл. Габрово',
    '76234': 'с. Фъртуни, общ. Трявна, обл. Габрово',
    '77459': 'с. Христовци, общ. Трявна, обл. Габрово',
    '80114': 'с. Чакалите, общ. Трявна, обл. Габрово',
    '81058': 'с. Черновръх, общ. Трявна, обл. Габрово',
    '87081': 'с. Ябълковци, общ. Трявна, обл. Габрово',
    '87117': 'с. Явор, общ. Трявна, обл. Габрово',
    '02508': 'гр. Балчик, общ. Балчик, обл. Добрич',
    '03174': 'с. Безводица, общ. Балчик, обл. Добрич',
    '04515': 'с. Бобовец, общ. Балчик, обл. Добрич',
    '06793': 'с. Брястово, общ. Балчик, обл. Добрич',
    '18160': 'с. Гурково, общ. Балчик, обл. Добрич',
    '23769': 'с. Дропла, общ. Балчик, обл. Добрич',
    '24387': 'с. Дъбрава, общ. Балчик, обл. Добрич',
    '31245': 'с. Змеево, общ. Балчик, обл. Добрич',
    '36453': 'с. Карвуна, общ. Балчик, обл. Добрич',
    '39459': 'с. Кранево, общ. Балчик, обл. Добрич',
    '39623': 'с. Кремена, общ. Балчик, обл. Добрич',
    '44882': 'с. Ляхово, общ. Балчик, обл. Добрич',
    '53120': 'с. Оброчище, общ. Балчик, обл. Добрич',
    '58270': 'с. Преспа, общ. Балчик, обл. Добрич',
    '62788': 'с. Рогачево, общ. Балчик, обл. Добрич',
    '66250': 'с. Сенокос, общ. Балчик, обл. Добрич',
    '67951': 'с. Соколово, общ. Балчик, обл. Добрич',
    '69643': 'с. Стражица, общ. Балчик, обл. Добрич',
    '73095': 'с. Тригорци, общ. Балчик, обл. Добрич',
    '77390': 'с. Храброво, общ. Балчик, обл. Добрич',
    '48982': 'с. Царичино, общ. Балчик, обл. Добрич',
    '78639': 'с. Църква, общ. Балчик, обл. Добрич',
    '14711': 'гр. Генерал Тошево, общ. Генерал Тошево, обл. Добрич',
    '00360': 'с. Александър Стамболийски, общ. Генерал Тошево, обл. Добрич',
    '02484': 'с. Балканци, общ. Генерал Тошево, обл. Добрич',
    '03157': 'с. Бежаново, общ. Генерал Тошево, обл. Добрич',
    '10183': 'с. Василево, общ. Генерал Тошево, обл. Добрич',
    '10422': 'с. Великово, общ. Генерал Тошево, обл. Добрич',
    '11329': 'с. Вичово, общ. Генерал Тошево, обл. Добрич',
    '18575': 'с. Горица, общ. Генерал Тошево, обл. Добрич',
    '17511': 'с. Градини, общ. Генерал Тошево, обл. Добрич',
    '24339': 'с. Дъбовик, общ. Генерал Тошево, обл. Добрич',
    '29444': 'с. Житен, общ. Генерал Тошево, обл. Добрич',
    '31303': 'с. Зограф, общ. Генерал Тошево, обл. Добрич',
    '32467': 'с. Изворово, общ. Генерал Тошево, обл. Добрич',
    '34045': 'с. Йовково, общ. Генерал Тошево, обл. Добрич',
    '35393': 'с. Калина, общ. Генерал Тошево, обл. Добрич',
    '36467': 'с. Кардам, общ. Генерал Тошево, обл. Добрич',
    '38217': 'с. Конаре, общ. Генерал Тошево, обл. Добрич',
    '39284': 'с. Краище, общ. Генерал Тошево, обл. Добрич',
    '39534': 'с. Красен, общ. Генерал Тошево, обл. Добрич',
    '40885': 'с. Къпиново, общ. Генерал Тошево, обл. Добрич',
    '44179': 'с. Лозница, общ. Генерал Тошево, обл. Добрич',
    '44714': 'с. Люляково, общ. Генерал Тошево, обл. Добрич',
    '46351': 'с. Малина, общ. Генерал Тошево, обл. Добрич',
    '53391': 'с. Огражден, общ. Генерал Тошево, обл. Добрич',
    '56040': 'с. Петлешково, общ. Генерал Тошево, обл. Добрич',
    '56509': 'с. Писарово, общ. Генерал Тошево, обл. Добрич',
    '56736': 'с. Пленимир, общ. Генерал Тошево, обл. Добрич',
    '58181': 'с. Преселенци, общ. Генерал Тошево, обл. Добрич',
    '58414': 'с. Присад, общ. Генерал Тошево, обл. Добрич',
    '58832': 'с. Пчеларово, общ. Генерал Тошево, обл. Добрич',
    '61159': 'с. Равнец, общ. Генерал Тошево, обл. Добрич',
    '62829': 'с. Рогозина, общ. Генерал Тошево, обл. Добрич',
    '63046': 'с. Росен, общ. Генерал Тошево, обл. Добрич',
    '63094': 'с. Росица, общ. Генерал Тошево, обл. Добрич',
    '66620': 'с. Сираково, общ. Генерал Тошево, обл. Добрич',
    '67756': 'с. Сноп, общ. Генерал Тошево, обл. Добрич',
    '67773': 'с. Снягово, общ. Генерал Тошево, обл. Добрич',
    '68196': 'с. Спасово, общ. Генерал Тошево, обл. Добрич',
    '68326': 'с. Средина, общ. Генерал Тошево, обл. Добрич',
    '70634': 'с. Сърнино, общ. Генерал Тошево, обл. Добрич',
    '75068': 'с. Узово, общ. Генерал Тошево, обл. Добрич',
    '81219': 'с. Чернооково, общ. Генерал Тошево, обл. Добрич',
    '00429': 'с. Алцек, общ. Добрич-селска, обл. Добрич',
    '02871': 'с. Батово, общ. Добрич-селска, обл. Добрич',
    '03040': 'с. Бдинци, общ. Добрич-селска, обл. Добрич',
    '03860': 'с. Бенковски, общ. Добрич-селска, обл. Добрич',
    '04580': 'с. Богдан, общ. Добрич-селска, обл. Добрич',
    '05061': 'с. Божурово, общ. Добрич-селска, обл. Добрич',
    '06104': 'с. Бранище, общ. Добрич-селска, обл. Добрич',
    '10307': 'с. Ведрина, общ. Добрич-селска, обл. Добрич',
    '11421': 'с. Владимирово, общ. Добрич-селска, обл. Добрич',
    '11781': 'с. Воднянци, общ. Добрич-селска, обл. Добрич',
    '12231': 'с. Вратарите, общ. Добрич-селска, обл. Добрич',
    '12262': 'с. Врачанци, общ. Добрич-селска, обл. Добрич',
    '14684': 'с. Генерал Колево, общ. Добрич-селска, обл. Добрич',
    '14862': 'с. Гешаново, общ. Добрич-селска, обл. Добрич',
    '20359': 'с. Дебрене, общ. Добрич-селска, обл. Добрич',
    '21350': 'с. Добрево, общ. Добрич-селска, обл. Добрич',
    '21957': 'с. Долина, общ. Добрич-селска, обл. Добрич',
    '22988': 'с. Дончево, общ. Добрич-селска, обл. Добрич',
    '23128': 'с. Драганово, общ. Добрич-селска, обл. Добрич',
    '23933': 'с. Дряновец, общ. Добрич-селска, обл. Добрич',
    '27468': 'с. Енево, общ. Добрич-селска, обл. Добрич',
    '29489': 'с. Житница, общ. Добрич-селска, обл. Добрич',
    '31067': 'с. Златия, общ. Добрич-селска, обл. Добрич',
    '35674': 'с. Камен, общ. Добрич-селска, обл. Добрич',
    '36419': 'с. Карапелит, общ. Добрич-селска, обл. Добрич',
    '37808': 'с. Козлодуйци, общ. Добрич-селска, обл. Добрич',
    '39061': 'с. Котленци, общ. Добрич-селска, обл. Добрич',
    '39242': 'с. Крагулево, общ. Добрич-селска, обл. Добрич',
    '43997': 'с. Ловчанци, общ. Добрич-селска, обл. Добрич',
    '44272': 'с. Ломница, общ. Добрич-селска, обл. Добрич',
    '43431': 'с. Лясково, общ. Добрич-селска, обл. Добрич',
    '46454': 'с. Малка Смолница, общ. Добрич-селска, обл. Добрич',
    '47679': 'с. Медово, общ. Добрич-селска, обл. Добрич',
    '47901': 'с. Методиево, общ. Добрич-селска, обл. Добрич',
    '48088': 'с. Миладиновци, общ. Добрич-селска, обл. Добрич',
    '52038': 'с. Ново Ботево, общ. Добрич-селска, обл. Добрич',
    '53210': 'с. Овчарово, общ. Добрич-селска, обл. Добрич',
    '53432': 'с. Одринци, общ. Добрич-селска, обл. Добрич',
    '53450': 'с. Одърци, общ. Добрич-селска, обл. Добрич',
    '53597': 'с. Опанец, общ. Добрич-селска, обл. Добрич',
    '53881': 'с. Орлова могила, общ. Добрич-селска, обл. Добрич',
    '55511': 'с. Паскалево, общ. Добрич-селска, обл. Добрич',
    '56695': 'с. Плачидол, общ. Добрич-селска, обл. Добрич',
    '21083': 'с. Победа, общ. Добрич-селска, обл. Добрич',
    '57087': 'с. Подслон, общ. Добрич-селска, обл. Добрич',
    '68103': 'с. Полковник Иваново, общ. Добрич-селска, обл. Добрич',
    '59402': 'с. Полковник Минково, общ. Добрич-селска, обл. Добрич',
    '57279': 'с. Полковник Свещарово, общ. Добрич-селска, обл. Добрич',
    '57550': 'с. Попгригорово, общ. Добрич-селска, обл. Добрич',
    '58339': 'с. Прилеп, общ. Добрич-селска, обл. Добрич',
    '58360': 'с. Приморци, общ. Добрич-селска, обл. Добрич',
    '58880': 'с. Пчелино, общ. Добрич-селска, обл. Добрич',
    '58935': 'с. Пчелник, общ. Добрич-селска, обл. Добрич',
    '63063': 'с. Росеново, общ. Добрич-селска, обл. Добрич',
    '00374': 'с. Самуилово, общ. Добрич-селска, обл. Добрич',
    '65824': 'с. Свобода, общ. Добрич-селска, обл. Добрич',
    '66946': 'с. Славеево, общ. Добрич-селска, обл. Добрич',
    '70812': 'с. Сливенци, общ. Добрич-селска, обл. Добрич',
    '67622': 'с. Смолница, общ. Добрич-селска, обл. Добрич',
    '67917': 'с. Соколник, общ. Добрич-селска, обл. Добрич',
    '69198': 'с. Стефан Караджа, общ. Добрич-селска, обл. Добрич',
    '69242': 'с. Стефаново, общ. Добрич-селска, обл. Добрич',
    '69300': 'с. Стожер, общ. Добрич-селска, обл. Добрич',
    '73818': 'с. Тянево, общ. Добрич-селска, обл. Добрич',
    '76064': 'с. Фелдфебел Денково, общ. Добрич-селска, обл. Добрич',
    '77284': 'с. Хитово, общ. Добрич-селска, обл. Добрич',
    '78152': 'с. Царевец, общ. Добрич-селска, обл. Добрич',
    '80769': 'с. Черна, общ. Добрич-селска, обл. Добрич',
    '35064': 'гр. Каварна, общ. Каварна, обл. Добрич',
    '03318': 'с. Белгун, общ. Каварна, обл. Добрич',
    '04090': 'с. Било, общ. Каварна, обл. Добрич',
    '05009': 'с. Божурец, общ. Каварна, обл. Добрич',
    '07257': 'с. Българево, общ. Каварна, обл. Добрич',
    '11003': 'с. Видно, общ. Каварна, обл. Добрич',
    '12173': 'с. Вранино, общ. Каварна, обл. Добрич',
    '32768': 'с. Иречек, общ. Каварна, обл. Добрич',
    '35746': 'с. Камен бряг, общ. Каварна, обл. Добрич',
    '40049': 'с. Крупен, общ. Каварна, обл. Добрич',
    '48828': 'с. Могилище, общ. Каварна, обл. Добрич',
    '51408': 'с. Нейково, общ. Каварна, обл. Добрич',
    '57861': 'с. Поручик Чунчево, общ. Каварна, обл. Добрич',
    '62092': 'с. Раковски, общ. Каварна, обл. Добрич',
    '65543': 'с. Свети Никола, общ. Каварна, обл. Добрич',
    '66113': 'с. Селце, общ. Каварна, обл. Добрич',
    '66281': 'с. Септемврийци, общ. Каварна, обл. Добрич',
    '72693': 'с. Топола, общ. Каварна, обл. Добрич',
    '72936': 'с. Травник, общ. Каварна, обл. Добрич',
    '77044': 'с. Хаджи Димитър, общ. Каварна, обл. Добрич',
    '80340': 'с. Челопечене, общ. Каварна, обл. Добрич',
    '00031': 'с. Абрит, общ. Крушари, обл. Добрич',
    '00268': 'с. Александрия, общ. Крушари, обл. Добрич',
    '04193': 'с. Бистрец, общ. Крушари, обл. Добрич',
    '14043': 'с. Габер, общ. Крушари, обл. Добрич',
    '21470': 'с. Добрин, общ. Крушари, обл. Добрич',
    '27656': 'с. Ефрейтор Бакалово, общ. Крушари, обл. Добрич',
    '30185': 'с. Загорци, общ. Крушари, обл. Добрич',
    '30781': 'с. Земенци, общ. Крушари, обл. Добрич',
    '30884': 'с. Зимница, общ. Крушари, обл. Добрич',
    '36138': 'с. Капитан Димитрово, общ. Крушари, обл. Добрич',
    '38618': 'с. Коритен, общ. Крушари, обл. Добрич',
    '40097': 'с. Крушари, общ. Крушари, обл. Добрич',
    '44104': 'с. Лозенец, общ. Крушари, обл. Добрич',
    '53357': 'с. Огняново, общ. Крушари, обл. Добрич',
    '57234': 'с. Полковник Дяково, общ. Крушари, обл. Добрич',
    '57858': 'с. Поручик Кърджиево, общ. Крушари, обл. Добрич',
    '65906': 'с. Северняк, общ. Крушари, обл. Добрич',
    '65913': 'с. Северци, общ. Крушари, обл. Добрич',
    '72196': 'с. Телериг, общ. Крушари, обл. Добрич',
    '72271': 'гр. Тервел, общ. Тервел, обл. Добрич',
    '00432': 'с. Ангеларий, общ. Тервел, обл. Добрич',
    '02405': 'с. Балик, общ. Тервел, обл. Добрич',
    '03215': 'с. Безмер, общ. Тервел, обл. Добрич',
    '04916': 'с. Божан, общ. Тервел, обл. Добрич',
    '05342': 'с. Бонево, общ. Тервел, обл. Добрич',
    '06464': 'с. Брестница, общ. Тервел, обл. Добрич',
    '11911': 'с. Войниково, общ. Тервел, обл. Добрич',
    '14982': 'с. Главанци, общ. Тервел, обл. Добрич',
    '17590': 'с. Градница, общ. Тервел, обл. Добрич',
    '18191': 'с. Гуслар, общ. Тервел, обл. Добрич',
    '29035': 'с. Жегларци, общ. Тервел, обл. Добрич',
    '31396': 'с. Зърнево, общ. Тервел, обл. Добрич',
    '35050': 'с. Каблешково, общ. Тервел, обл. Добрич',
    '37157': 'с. Кладенци, общ. Тервел, обл. Добрич',
    '38025': 'с. Коларци, общ. Тервел, обл. Добрич',
    '39127': 'с. Кочмар, общ. Тервел, обл. Добрич',
    '46334': 'с. Мали извор, общ. Тервел, обл. Добрич',
    '51812': 'с. Нова Камена, общ. Тервел, обл. Добрич',
    '53549': 'с. Оногур, общ. Тервел, обл. Добрич',
    '53953': 'с. Орляк, общ. Тервел, обл. Добрич',
    '57265': 'с. Полковник Савово, общ. Тервел, обл. Добрич',
    '57563': 'с. Попгруево, общ. Тервел, обл. Добрич',
    '58685': 'с. Професор Златарски, общ. Тервел, обл. Добрич',
    '70617': 'с. Сърнец, общ. Тервел, обл. Добрич',
    '81270': 'с. Честименско, общ. Тервел, обл. Добрич',
    '83017': 'гр. Шабла, общ. Шабла, обл. Добрич',
    '04927': 'с. Божаново, общ. Шабла, обл. Добрич',
    '10032': 'с. Ваклино, общ. Шабла, обл. Добрич',
    '16095': 'с. Горичане, общ. Шабла, обл. Добрич',
    '17275': 'с. Горун, общ. Шабла, обл. Добрич',
    '17782': 'с. Граничар, общ. Шабла, обл. Добрич',
    '24102': 'с. Дуранкулак, общ. Шабла, обл. Добрич',
    '27108': 'с. Езерец, общ. Шабла, обл. Добрич',
    '30394': 'с. Захари Стояново, общ. Шабла, обл. Добрич',
    '39493': 'с. Крапец, общ. Шабла, обл. Добрич',
    '58596': 'с. Пролез, общ. Шабла, обл. Добрич',
    '67550': 'с. Смин, общ. Шабла, обл. Добрич',
    '68610': 'с. Стаевци, общ. Шабла, обл. Добрич',
    '72179': 'с. Твърдица, общ. Шабла, обл. Добрич',
    '73780': 'с. Тюленово, общ. Шабла, обл. Добрич',
    '81181': 'с. Черноморци, общ. Шабла, обл. Добрич',
    '00607': 'гр. Ардино, общ. Ардино, обл. Кърджали',
    '00062': 'с. Аврамово, общ. Ардино, обл. Кърджали',
    '00864': 'с. Ахрянско, общ. Ардино, обл. Кърджали',
    '02991': 'с. Башево, общ. Ардино, обл. Кърджали',
    '04248': 'с. Бистроглед, общ. Ардино, обл. Кърджали',
    '07925': 'с. Богатино, общ. Ардино, обл. Кърджали',
    '80176': 'с. Боровица, общ. Ардино, обл. Кърджали',
    '77116': 'с. Брезен, общ. Ардино, обл. Кърджали',
    '07747': 'с. Бял извор, общ. Ардино, обл. Кърджали',
    '18544': 'с. Главник, общ. Ардино, обл. Кърджали',
    '15607': 'с. Голобрад, общ. Ардино, обл. Кърджали',
    '16910': 'с. Горно Прахово, общ. Ардино, обл. Кърджали',
    '18321': 'с. Гърбище, общ. Ардино, обл. Кърджали',
    '24880': 'с. Дедино, общ. Ардино, обл. Кърджали',
    '21806': 'с. Дойранци, общ. Ардино, обл. Кърджали',
    '22784': 'с. Долно Прахово, общ. Ардино, обл. Кърджали',
    '24788': 'с. Дядовци, общ. Ардино, обл. Кърджали',
    '27543': 'с. Еньовче, общ. Ардино, обл. Кърджали',
    '29605': 'с. Жълтуша, общ. Ардино, обл. Кърджали',
    '32946': 'с. Искра, общ. Ардино, обл. Кърджали',
    '37085': 'с. Китница, общ. Ардино, обл. Кърджали',
    '39952': 'с. Кроячево, общ. Ардино, обл. Кърджали',
    '43164': 'с. Латинка, общ. Ардино, обл. Кърджали',
    '44896': 'с. Левци, общ. Ардино, обл. Кърджали',
    '43270': 'с. Ленище, общ. Ардино, обл. Кърджали',
    '44584': 'с. Любино, общ. Ардино, обл. Кърджали',
    '46156': 'с. Мак, общ. Ардино, обл. Кърджали',
    '48756': 'с. Млечино, общ. Ардино, обл. Кърджали',
    '49401': 'с. Мусево, общ. Ардино, обл. Кърджали',
    '55124': 'с. Падина, общ. Ардино, обл. Кърджали',
    '55525': 'с. Паспал, общ. Ардино, обл. Кърджали',
    '55912': 'с. Песнопой, общ. Ардино, обл. Кърджали',
    '58013': 'с. Правдолюб, общ. Ардино, обл. Кърджали',
    '54260': 'с. Рибарци, общ. Ардино, обл. Кърджали',
    '62894': 'с. Родопско, общ. Ардино, обл. Кърджали',
    '63402': 'с. Русалско, общ. Ардино, обл. Кърджали',
    '65646': 'с. Светулка, общ. Ардино, обл. Кърджали',
    '70771': 'с. Седларци, общ. Ардино, обл. Кърджали',
    '66562': 'с. Синчец, общ. Ардино, обл. Кърджали',
    '68240': 'с. Сполука, общ. Ардино, обл. Кърджали',
    '68597': 'с. Срънско, общ. Ардино, обл. Кърджали',
    '69122': 'с. Стар читак, общ. Ардино, обл. Кърджали',
    '69518': 'с. Стояново, общ. Ардино, обл. Кърджали',
    '70319': 'с. Сухово, общ. Ардино, обл. Кърджали',
    '47809': 'с. Теменуга, общ. Ардино, обл. Кърджали',
    '73897': 'с. Търна, общ. Ардино, обл. Кърджали',
    '73732': 'с. Търносливка, общ. Ардино, обл. Кърджали',
    '77487': 'с. Хромица, общ. Ардино, обл. Кърджали',
    '81921': 'с. Червена скала, общ. Ардино, обл. Кърджали',
    '80964': 'с. Чернигово, общ. Ардино, обл. Кърджали',
    '81596': 'с. Чубрика, общ. Ардино, обл. Кърджали',
    '87059': 'с. Ябълковец, общ. Ардино, обл. Кърджали',
    '20746': 'гр. Джебел, общ. Джебел, обл. Кърджали',
    '00206': 'с. Албанци, общ. Джебел, обл. Кърджали',
    '07939': 'с. Брежана, общ. Джебел, обл. Кърджали',
    '10389': 'с. Великденче, общ. Джебел, обл. Кърджали',
    '11689': 'с. Воденичарско, общ. Джебел, обл. Кърджали',
    '12468': 'с. Вълкович, общ. Джебел, обл. Кърджали',
    '14647': 'с. Генерал Гешево, общ. Джебел, обл. Кърджали',
    '21525': 'с. Добринци, общ. Джебел, обл. Кърджали',
    '24195': 'с. Душинково, общ. Джебел, обл. Кърджали',
    '29180': 'с. Желъдово, общ. Джебел, обл. Кърджали',
    '29564': 'с. Жълти рид, общ. Джебел, обл. Кърджали',
    '29619': 'с. Жълтика, общ. Джебел, обл. Кърджали',
    '32634': 'с. Илийско, общ. Джебел, обл. Кърджали',
    '35184': 'с. Казаците, общ. Джебел, обл. Кърджали',
    '35972': 'с. Каменяне, общ. Джебел, обл. Кърджали',
    '37736': 'с. Козица, общ. Джебел, обл. Кърджали',
    '38371': 'с. Контил, общ. Джебел, обл. Кърджали',
    '40676': 'с. Купците, общ. Джебел, обл. Кърджали',
    '43178': 'с. Лебед, общ. Джебел, обл. Кърджали',
    '48622': 'с. Мишевско, общ. Джебел, обл. Кърджали',
    '49268': 'с. Мрежичко, общ. Джебел, обл. Кърджали',
    '53271': 'с. Овчево, общ. Джебел, обл. Кърджали',
    '55436': 'с. Папрат, общ. Джебел, обл. Кърджали',
    '56589': 'с. Плазище, общ. Джебел, обл. Кърджали',
    '56976': 'с. Подвръх, общ. Джебел, обл. Кърджали',
    '57412': 'с. Полянец, общ. Джебел, обл. Кърджали',
    '57947': 'с. Поточе, общ. Джебел, обл. Кърджали',
    '58387': 'с. Припек, общ. Джебел, обл. Кърджали',
    '62654': 'с. Ридино, общ. Джебел, обл. Кърджали',
    '62801': 'с. Рогозари, общ. Джебел, обл. Кърджали',
    '62846': 'с. Рогозче, общ. Джебел, обл. Кърджали',
    '63714': 'с. Рожденско, общ. Джебел, обл. Кърджали',
    '63611': 'с. Рът, общ. Джебел, обл. Кърджали',
    '70809': 'с. Сипец, общ. Джебел, обл. Кърджали',
    '66723': 'с. Скалина, общ. Джебел, обл. Кърджали',
    '67502': 'с. Слънчоглед, общ. Джебел, обл. Кърджали',
    '68120': 'с. Софийци, общ. Джебел, обл. Кърджали',
    '72211': 'с. Телчарка, общ. Джебел, обл. Кърджали',
    '73715': 'с. Търновци, общ. Джебел, обл. Кърджали',
    '73804': 'с. Тютюнче, общ. Джебел, обл. Кърджали',
    '75201': 'с. Устрен, общ. Джебел, обл. Кърджали',
    '78313': 'с. Цвятово, общ. Джебел, обл. Кърджали',
    '78642': 'с. Църквица, общ. Джебел, обл. Кърджали',
    '80128': 'с. Чакалци, общ. Джебел, обл. Кърджали',
    '81935': 'с. Черешка, общ. Джебел, обл. Кърджали',
    '84019': 'с. Щерна, общ. Джебел, обл. Кърджали',
    '87388': 'с. Ямино, общ. Джебел, обл. Кърджали',
    '00905': 'с. Априлци, общ. Кирково, обл. Кърджали',
    '03825': 'с. Бенковски, общ. Кирково, обл. Кърджали',
    '07942': 'с. Брегово, общ. Кирково, обл. Кърджали',
    '13055': 'с. Вълчанка, общ. Кирково, обл. Кърджали',
    '00120': 'с. Върбен, общ. Кирково, обл. Кърджали',
    '12886': 'с. Върли дол, общ. Кирково, обл. Кърджали',
    '18558': 'с. Горно Кирково, общ. Кирково, обл. Кърджали',
    '16821': 'с. Горно Къпиново, общ. Кирково, обл. Кърджали',
    '17138': 'с. Горски извор, общ. Кирково, обл. Кърджали',
    '17871': 'с. Гривяк, общ. Кирково, обл. Кърджали',
    '20496': 'с. Дедец, общ. Кирково, обл. Кърджали',
    '20554': 'с. Делвино, общ. Кирково, обл. Кърджали',
    '20777': 'с. Джерово, общ. Кирково, обл. Кърджали',
    '21631': 'с. Добромирци, общ. Кирково, обл. Кърджали',
    '22678': 'с. Долно Къпиново, общ. Кирково, обл. Кърджали',
    '22928': 'с. Домище, общ. Кирково, обл. Кърджали',
    '23577': 'с. Дрангово, общ. Кирково, обл. Кърджали',
    '23803': 'с. Дружинци, общ. Кирково, обл. Кърджали',
    '23892': 'с. Дрянова глава, общ. Кирково, обл. Кърджали',
    '24743': 'с. Дюлица, общ. Кирково, обл. Кърджали',
    '27591': 'с. Еровете, общ. Кирково, обл. Кърджали',
    '30106': 'с. Завоя, общ. Кирково, обл. Кърджали',
    '30140': 'с. Загорски, общ. Кирково, обл. Кърджали',
    '30675': 'с. Здравчец, общ. Кирково, обл. Кърджали',
    '36748': 'с. Каялоба, общ. Кирково, обл. Кърджали',
    '36926': 'с. Кирково, общ. Кирково, обл. Кърджали',
    '37071': 'с. Китна, общ. Кирково, обл. Кърджали',
    '37767': 'с. Козлево, общ. Кирково, обл. Кърджали',
    '39013': 'с. Костурино, общ. Кирково, обл. Кърджали',
    '39445': 'с. Кран, общ. Кирково, обл. Кърджали',
    '41174': 'с. Кремен, общ. Кирково, обл. Кърджали',
    '39877': 'с. Крилатица, общ. Кирково, обл. Кърджали',
    '40511': 'с. Кукуряк, общ. Кирково, обл. Кърджали',
    '40988': 'с. Кърчовско, общ. Кирково, обл. Кърджали',
    '44080': 'с. Лозенградци, общ. Кирково, обл. Кърджали',
    '46680': 'с. Малкоч, общ. Кирково, обл. Кърджали',
    '47531': 'с. Медевци, общ. Кирково, обл. Кърджали',
    '47888': 'с. Метличина, общ. Кирково, обл. Кърджали',
    '47891': 'с. Метличка, общ. Кирково, обл. Кърджали',
    '48845': 'с. Могиляне, общ. Кирково, обл. Кърджали',
    '49480': 'с. Мъглене, общ. Кирково, обл. Кърджали',
    '51086': 'с. Нане, общ. Кирково, обл. Кърджали',
    '53878': 'с. Орлица, общ. Кирково, обл. Кърджали',
    '54393': 'с. Островец, общ. Кирково, обл. Кърджали',
    '56808': 'с. Пловка, общ. Кирково, обл. Кърджали',
    '57039': 'с. Подкова, общ. Кирково, обл. Кърджали',
    '58178': 'с. Пресека, общ. Кирково, обл. Кърджали',
    '59054': 'с. Първенци, общ. Кирково, обл. Кърджали',
    '59063': 'с. Първица, общ. Кирково, обл. Кърджали',
    '62236': 'с. Растник, общ. Кирково, обл. Кърджали',
    '65214': 'с. Самодива, общ. Кирково, обл. Кърджали',
    '65228': 'с. Самокитка, общ. Кирково, обл. Кърджали',
    '35016': 'с. Светлен, общ. Кирково, обл. Кърджали',
    '66024': 'с. Секирка, общ. Кирково, обл. Кърджали',
    '68577': 'с. Средско, общ. Кирково, обл. Кърджали',
    '68895': 'с. Старейшино, общ. Кирково, обл. Кърджали',
    '68953': 'с. Старово, общ. Кирково, обл. Кърджали',
    '69482': 'с. Стоманци, общ. Кирково, обл. Кърджали',
    '69866': 'с. Стрижба, общ. Кирково, обл. Кърджали',
    '72463': 'с. Тихомир, общ. Кирково, обл. Кърджали',
    '76159': 'с. Фотиново, общ. Кирково, обл. Кърджали',
    '77092': 'с. Хаджийско, общ. Кирково, обл. Кърджали',
    '78183': 'с. Царино, общ. Кирково, обл. Кърджали',
    '80065': 'с. Чавка, общ. Кирково, обл. Кърджали',
    '80090': 'с. Чакаларово, общ. Кирково, обл. Кърджали',
    '81493': 'с. Чичево, общ. Кирково, обл. Кърджали',
    '81565': 'с. Чорбаджийско, общ. Кирково, обл. Кърджали',
    '83226': 'с. Шипок, общ. Кирково, обл. Кърджали',
    '83449': 'с. Шопци, общ. Кирково, обл. Кърджали',
    '83538': 'с. Шумнатица, общ. Кирково, обл. Кърджали',
    '87309': 'с. Яковица, общ. Кирково, обл. Кърджали',
    '87415': 'с. Янино, общ. Кирково, обл. Кърджали',
    '39970': 'гр. Крумовград, общ. Крумовград, обл. Кърджали',
    '00093': 'с. Аврен, общ. Крумовград, обл. Кърджали',
    '02141': 'с. Багрилци, общ. Крумовград, обл. Кърджали',
    '02751': 'с. Бараци, общ. Крумовград, обл. Кърджали',
    '04306': 'с. Благун, общ. Крумовград, обл. Кърджали',
    '05181': 'с. Бойник, общ. Крумовград, обл. Кърджали',
    '06728': 'с. Бряговец, общ. Крумовград, обл. Кърджали',
    '06906': 'с. Бук, общ. Крумовград, обл. Кърджали',
    '12190': 'с. Вранско, общ. Крумовград, обл. Кърджали',
    '15727': 'с. Голям Девесил, общ. Крумовград, обл. Кърджали',
    '15713': 'с. Голяма Чинка, общ. Крумовград, обл. Кърджали',
    '15878': 'с. Голямо Каменяне, общ. Крумовград, обл. Кърджали',
    '16287': 'с. Горна кула, общ. Крумовград, обл. Кърджали',
    '16660': 'с. Горни Юруци, общ. Крумовград, обл. Кърджали',
    '17868': 'с. Гривка, общ. Крумовград, обл. Кърджали',
    '18071': 'с. Гулийка, общ. Крумовград, обл. Кърджали',
    '18085': 'с. Гулия, общ. Крумовград, обл. Кърджали',
    '20393': 'с. Девесилица, общ. Крумовград, обл. Кърджали',
    '20403': 'с. Девесилово, общ. Крумовград, обл. Кърджали',
    '20732': 'с. Джанка, общ. Крумовград, обл. Кърджали',
    '21302': 'с. Доборско, общ. Крумовград, обл. Кърджали',
    '22157': 'с. Долна кула, общ. Крумовград, обл. Кърджали',
    '22544': 'с. Долни Юруци, общ. Крумовград, обл. Кърджали',
    '24459': 'с. Дъждовник, общ. Крумовград, обл. Кърджали',
    '27036': 'с. Егрек, общ. Крумовград, обл. Кърджали',
    '27680': 'с. Едрино, общ. Крумовград, обл. Кърджали',
    '30538': 'с. Звънарка, общ. Крумовград, обл. Кърджали',
    '30911': 'с. Зиморница, общ. Крумовград, обл. Кърджали',
    '31142': 'с. Златолист, общ. Крумовград, обл. Кърджали',
    '35269': 'с. Калайджиево, общ. Крумовград, обл. Кърджали',
    '35849': 'с. Каменка, общ. Крумовград, обл. Кърджали',
    '36021': 'с. Кандилка, общ. Крумовград, обл. Кърджали',
    '36717': 'с. Качулка, общ. Крумовград, обл. Кърджали',
    '37558': 'с. Ковил, общ. Крумовград, обл. Кърджали',
    '37622': 'с. Кожухарци, общ. Крумовград, обл. Кърджали',
    '39058': 'с. Котлари, общ. Крумовград, обл. Кърджали',
    '39565': 'с. Красино, общ. Крумовград, обл. Кърджали',
    '40823': 'с. Къклица, общ. Крумовград, обл. Кърджали',
    '43596': 'с. Лещарка, общ. Крумовград, обл. Кърджали',
    '43726': 'с. Лимец, общ. Крумовград, обл. Кърджали',
    '44344': 'с. Луличка, общ. Крумовград, обл. Кърджали',
    '46471': 'с. Малка Чинка, общ. Крумовград, обл. Кърджали',
    '46601': 'с. Малко Каменяне, общ. Крумовград, обл. Кърджали',
    '46872': 'с. Малък Девесил, общ. Крумовград, обл. Кърджали',
    '47860': 'с. Метлика, общ. Крумовград, обл. Кърджали',
    '49103': 'с. Морянци, общ. Крумовград, обл. Кърджали',
    '53206': 'с. Овчари, общ. Крумовград, обл. Кърджали',
    '54821': 'с. Орех, общ. Крумовград, обл. Кърджали',
    '53713': 'с. Орешари, общ. Крумовград, обл. Кърджали',
    '55097': 'с. Падало, общ. Крумовград, обл. Кърджали',
    '55614': 'с. Пашинци, общ. Крумовград, обл. Кърджали',
    '55751': 'с. Пелин, общ. Крумовград, обл. Кърджали',
    '55899': 'с. Перуника, общ. Крумовград, обл. Кърджали',
    '57062': 'с. Подрумче, общ. Крумовград, обл. Кърджали',
    '57248': 'с. Полковник Желязово, общ. Крумовград, обл. Кърджали',
    '57933': 'с. Поточарка, общ. Крумовград, обл. Кърджали',
    '57950': 'с. Поточница, общ. Крумовград, обл. Кърджали',
    '62178': 'с. Раличево, общ. Крумовград, обл. Кърджали',
    '62617': 'с. Рибино, общ. Крумовград, обл. Кърджали',
    '62774': 'с. Рогач, общ. Крумовград, обл. Кърджали',
    '63505': 'с. Ручей, общ. Крумовград, обл. Кърджали',
    '65190': 'с. Самовила, общ. Крумовград, обл. Кърджали',
    '65454': 'с. Сбор, общ. Крумовград, обл. Кърджали',
    '66545': 'с. Синигер, общ. Крумовград, обл. Кърджали',
    '66716': 'с. Скалак, общ. Крумовград, обл. Кърджали',
    '67132': 'с. Сладкодум, общ. Крумовград, обл. Кърджали',
    '67297': 'с. Сливарка, общ. Крумовград, обл. Кърджали',
    '68936': 'с. Стари чал, общ. Крумовград, обл. Кърджали',
    '81743': 'с. Стражец, общ. Крумовград, обл. Кърджали',
    '69688': 'с. Странджево, общ. Крумовград, обл. Кърджали',
    '70104': 'с. Студен кладенец, общ. Крумовград, обл. Кърджали',
    '70559': 'с. Сърнак, общ. Крумовград, обл. Кърджали',
    '72429': 'с. Тинтява, общ. Крумовград, обл. Кърджали',
    '72610': 'с. Токачка, общ. Крумовград, обл. Кърджали',
    '72730': 'с. Тополка, общ. Крумовград, обл. Кърджали',
    '77267': 'с. Хисар, общ. Крумовград, обл. Кърджали',
    '77428': 'с. Храстово, общ. Крумовград, обл. Кърджали',
    '80145': 'с. Чал, общ. Крумовград, обл. Кърджали',
    '81027': 'с. Черничево, общ. Крумовград, обл. Кърджали',
    '81205': 'с. Чернооки, общ. Крумовград, обл. Кърджали',
    '40909': 'гр. Кърджали, общ. Кърджали, обл. Кърджали',
    '00148': 'с. Айрово, общ. Кърджали, обл. Кърджали',
    '02124': 'с. Багра, общ. Кърджали, обл. Кърджали',
    '03006': 'с. Бащино, общ. Кърджали, обл. Кърджали',
    '03499': 'с. Бели пласт, общ. Кърджали, обл. Кърджали',
    '04409': 'с. Бленика, общ. Кърджали, обл. Кърджали',
    '04902': 'с. Божак, общ. Кърджали, обл. Кърджали',
    '05205': 'с. Бойно, общ. Кърджали, обл. Кърджали',
    '05325': 'с. Болярци, общ. Кърджали, обл. Кърджали',
    '06567': 'с. Брош, общ. Кърджали, обл. Кърджали',
    '07658': 'с. Бяла поляна, общ. Кърджали, обл. Кърджали',
    '07764': 'с. Бялка, общ. Кърджали, обл. Кърджали',
    '10358': 'с. Велешани, общ. Кърджали, обл. Кърджали',
    '11226': 'с. Висока, общ. Кърджали, обл. Кърджали',
    '11243': 'с. Висока поляна, общ. Кърджали, обл. Кърджали',
    '11346': 'с. Вишеград, общ. Кърджали, обл. Кърджали',
    '12053': 'с. Воловарци, общ. Кърджали, обл. Кърджали',
    '12694': 'с. Върбенци, общ. Кърджали, обл. Кърджали',
    '15000': 'с. Главатарци, общ. Кърджали, обл. Кърджали',
    '15216': 'с. Глухар, общ. Кърджали, обл. Кърджали',
    '15268': 'с. Гняздово, общ. Кърджали, обл. Кърджали',
    '15641': 'с. Голяма бара, общ. Кърджали, обл. Кърджали',
    '16267': 'с. Горна крепост, общ. Кърджали, обл. Кърджали',
    '18410': 'с. Гъсково, общ. Кърджали, обл. Кърджали',
    '21511': 'с. Добриново, общ. Кърджали, обл. Кърджали',
    '21991': 'с. Долище, общ. Кърджали, обл. Кърджали',
    '22134': 'с. Долна крепост, общ. Кърджали, обл. Кърджали',
    '24445': 'с. Дъждино, общ. Кърджали, обл. Кърджали',
    '24462': 'с. Дъждовница, общ. Кърджали, обл. Кърджали',
    '24596': 'с. Дънгово, общ. Кърджали, обл. Кърджали',
    '27512': 'с. Енчец, общ. Кърджали, обл. Кърджали',
    '29407': 'с. Жинзифово, общ. Кърджали, обл. Кърджали',
    '29413': 'с. Житарник, общ. Кърджали, обл. Кърджали',
    '30260': 'с. Зайчино, общ. Кърджали, обл. Кърджали',
    '30466': 'с. Звезделина, общ. Кърджали, обл. Кърджали',
    '30475': 'с. Звезден, общ. Кърджали, обл. Кърджали',
    '30524': 'с. Звиница, общ. Кърджали, обл. Кърджали',
    '30569': 'с. Звъника, общ. Кърджали, обл. Кърджали',
    '30586': 'с. Звънче, общ. Кърджали, обл. Кърджали',
    '30747': 'с. Зелениково, общ. Кърджали, обл. Кърджали',
    '30867': 'с. Зимзелен, общ. Кърджали, обл. Кърджали',
    '31348': 'с. Зорница, общ. Кърджали, обл. Кърджали',
    '32161': 'с. Иванци, общ. Кърджали, обл. Кърджали',
    '32679': 'с. Илиница, общ. Кърджали, обл. Кърджали',
    '35403': 'с. Калинка, общ. Кърджали, обл. Кърджали',
    '35540': 'с. Калоянци, общ. Кърджали, обл. Кърджали',
    '35732': 'с. Каменарци, общ. Кърджали, обл. Кърджали',
    '37438': 'с. Кобиляне, общ. Кърджали, обл. Кърджали',
    '37928': 'с. Кокиче, общ. Кърджали, обл. Кърджали',
    '37962': 'с. Кокошане, общ. Кърджали, обл. Кърджали',
    '38282': 'с. Конево, общ. Кърджали, обл. Кърджали',
    '38981': 'с. Костино, общ. Кърджали, обл. Кърджали',
    '39342': 'с. Крайно село, общ. Кърджали, обл. Кърджали',
    '39894': 'с. Крин, общ. Кърджали, обл. Кърджали',
    '40169': 'с. Крушевска, общ. Кърджали, обл. Кърджали',
    '40186': 'с. Крушка, общ. Кърджали, обл. Кърджали',
    '41085': 'с. Кьосево, общ. Кърджали, обл. Кърджали',
    '43856': 'с. Лисиците, общ. Кърджали, обл. Кърджали',
    '44361': 'с. Лъвово, общ. Кърджали, обл. Кърджали',
    '44700': 'с. Люляково, общ. Кърджали, обл. Кърджали',
    '46139': 'с. Майсторово, общ. Кърджали, обл. Кърджали',
    '46214': 'с. Македонци, общ. Кърджали, обл. Кърджали',
    '47342': 'с. Мартино, общ. Кърджали, обл. Кърджали',
    '48074': 'с. Миладиново, общ. Кърджали, обл. Кърджали',
    '49120': 'с. Мост, общ. Кърджали, обл. Кърджали',
    '49343': 'с. Мургово, общ. Кърджали, обл. Кърджали',
    '49521': 'с. Мъдрец, общ. Кърджали, обл. Кърджали',
    '35136': 'с. Невестино, общ. Кърджали, обл. Кърджали',
    '51442': 'с. Ненково, общ. Кърджали, обл. Кърджали',
    '53638': 'с. Опълченско, общ. Кърджали, обл. Кърджали',
    '53816': 'с. Орешница, общ. Кърджали, обл. Кърджали',
    '54403': 'с. Островица, общ. Кърджали, обл. Кърджали',
    '54479': 'с. Охлювец, общ. Кърджали, обл. Кърджали',
    '55422': 'с. Панчево, общ. Кърджали, обл. Кърджали',
    '55823': 'с. Пеньово, общ. Кърджали, обл. Кърджали',
    '55840': 'с. Пепелище, общ. Кърджали, обл. Кърджали',
    '55885': 'с. Перперек, общ. Кърджали, обл. Кърджали',
    '56054': 'с. Петлино, общ. Кърджали, обл. Кърджали',
    '56928': 'с. Повет, общ. Кърджали, обл. Кърджали',
    '58342': 'с. Прилепци, общ. Кърджали, обл. Кърджали',
    '58623': 'с. Пропаст, общ. Кърджали, обл. Кърджали',
    '58997': 'с. Пъдарци, общ. Кърджали, обл. Кърджали',
    '62195': 'с. Рани лист, общ. Кърджали, обл. Кърджали',
    '62445': 'с. Резбарци, общ. Кърджали, обл. Кърджали',
    '62668': 'с. Ридово, общ. Кърджали, обл. Кърджали',
    '63170': 'с. Рудина, общ. Кърджали, обл. Кърджали',
    '65512': 'с. Сватбаре, общ. Кърджали, обл. Кърджали',
    '65890': 'с. Севдалина, общ. Кърджали, обл. Кърджали',
    '65975': 'с. Седловина, общ. Кърджали, обл. Кърджали',
    '66336': 'с. Сестринско, общ. Кърджали, обл. Кърджали',
    '66603': 'с. Сипей, общ. Кърджали, обл. Кърджали',
    '66740': 'с. Скалище, общ. Кърджали, обл. Кърджали',
    '66754': 'с. Скална глава, общ. Кърджали, обл. Кърджали',
    '66929': 'с. Скърбино, общ. Кърджали, обл. Кърджали',
    '67742': 'с. Снежинка, общ. Кърджали, обл. Кърджали',
    '67979': 'с. Соколско, общ. Кърджали, обл. Кърджали',
    '67982': 'с. Соколяне, общ. Кърджали, обл. Кърджали',
    '68014': 'с. Солище, общ. Кърджали, обл. Кърджали',
    '68336': 'с. Срединка, общ. Кърджали, обл. Кърджали',
    '68984': 'с. Старо място, общ. Кърджали, обл. Кърджали',
    '69612': 'с. Стражевци, общ. Кърджали, обл. Кърджали',
    '69715': 'с. Страхил войвода, общ. Кърджали, обл. Кърджали',
    '69849': 'с. Стремово, общ. Кърджали, обл. Кърджали',
    '69852': 'с. Стремци, общ. Кърджали, обл. Кърджали',
    '72120': 'с. Татково, общ. Кърджали, обл. Кърджали',
    '72802': 'с. Тополчане, общ. Кърджали, обл. Кърджали',
    '73136': 'с. Три могили, общ. Кърджали, обл. Кърджали',
    '77339': 'с. Ходжовци, общ. Кърджали, обл. Кърджали',
    '78149': 'с. Царевец, общ. Кърджали, обл. Кърджали',
    '80248': 'с. Чеганци, общ. Кърджали, обл. Кърджали',
    '80594': 'с. Черешица, общ. Кърджали, обл. Кърджали',
    '80844': 'с. Черна скала, общ. Кърджали, обл. Кърджали',
    '81267': 'с. Черньовци, общ. Кърджали, обл. Кърджали',
    '81362': 'с. Чилик, общ. Кърджали, обл. Кърджали',
    '81462': 'с. Чифлик, общ. Кърджали, обл. Кърджали',
    '83315': 'с. Широко поле, общ. Кърджали, обл. Кърджали',
    '87494': 'с. Яребица, общ. Кърджали, обл. Кърджали',
    '87672': 'с. Ястреб, общ. Кърджали, обл. Кърджали',
    '48996': 'гр. Момчилград, общ. Момчилград, обл. Кърджали',
    '00828': 'с. Ауста, общ. Момчилград, обл. Кърджали',
    '02155': 'с. Багрянка, общ. Момчилград, обл. Кърджали',
    '02292': 'с. Балабаново, общ. Момчилград, обл. Кърджали',
    '04008': 'с. Биволяне, общ. Момчилград, обл. Кърджали',
    '12293': 'с. Врело, общ. Момчилград, обл. Кърджали',
    '86030': 'с. Върхари, общ. Момчилград, обл. Кърджали',
    '17186': 'с. Горско Дюлево, общ. Момчилград, обл. Кърджали',
    '17988': 'с. Груево, общ. Момчилград, обл. Кърджали',
    '18126': 'с. Гургулица, общ. Момчилград, обл. Кърджали',
    '24966': 'с. Девинци, общ. Момчилград, обл. Кърджали',
    '20755': 'с. Джелепско, общ. Момчилград, обл. Кърджали',
    '23830': 'с. Друмче, общ. Момчилград, обл. Кърджали',
    '30154': 'с. Загорско, общ. Момчилград, обл. Кърджали',
    '30452': 'с. Звездел, общ. Момчилград, обл. Кърджали',
    '03258': 'с. Каменец, общ. Момчилград, обл. Кърджали',
    '36350': 'с. Карамфил, общ. Момчилград, обл. Кърджали',
    '38409': 'с. Конче, общ. Момчилград, обл. Кърджали',
    '38697': 'с. Кос, общ. Момчилград, обл. Кърджали',
    '80131': 'с. Кременец, общ. Момчилград, обл. Кърджали',
    '43102': 'с. Лале, общ. Момчилград, обл. Кърджали',
    '43493': 'с. Летовник, общ. Момчилград, обл. Кърджали',
    '47144': 'с. Манчево, общ. Момчилград, обл. Кърджали',
    '48917': 'с. Момина сълза, общ. Момчилград, обл. Кърджали',
    '51097': 'с. Нановица, общ. Момчилград, обл. Кърджали',
    '51473': 'с. Неофит Бозвелиево, общ. Момчилград, обл. Кърджали',
    '53076': 'с. Обичник, общ. Момчилград, обл. Кърджали',
    '55169': 'с. Пазарци, общ. Момчилград, обл. Кърджали',
    '56575': 'с. Пиявец, общ. Момчилград, обл. Кърджали',
    '56767': 'с. Плешинци, общ. Момчилград, обл. Кърджали',
    '57892': 'с. Постник, общ. Момчилград, обл. Кърджали',
    '59361': 'с. Прогрес, общ. Момчилград, обл. Кърджали',
    '58757': 'с. Птичар, общ. Момчилград, обл. Кърджали',
    '61063': 'с. Равен, общ. Момчилград, обл. Кърджали',
    '62147': 'с. Ралица, общ. Момчилград, обл. Кърджали',
    '65098': 'с. Садовица, общ. Момчилград, обл. Кърджали',
    '65797': 'с. Свобода, общ. Момчилград, обл. Кърджали',
    '65944': 'с. Седефче, общ. Момчилград, обл. Кърджали',
    '65961': 'с. Седлари, общ. Момчилград, обл. Кърджали',
    '70785': 'с. Сенце, общ. Момчилград, обл. Кърджали',
    '66500': 'с. Синделци, общ. Момчилград, обл. Кърджали',
    '67882': 'с. Соколино, общ. Момчилград, обл. Кърджали',
    '70706': 'с. Сярци, общ. Момчилград, обл. Кърджали',
    '72134': 'с. Татул, общ. Момчилград, обл. Кърджали',
    '80087': 'с. Чайка, общ. Момчилград, обл. Кърджали',
    '81517': 'с. Чобанка, общ. Момчилград, обл. Кърджали',
    '81548': 'с. Чомаково, общ. Момчилград, обл. Кърджали',
    '81734': 'с. Чуково, общ. Момчилград, обл. Кърджали',
    '86060': 'с. Юнаци, общ. Момчилград, обл. Кърджали',
    '02261': 'с. Бакалите, общ. Черноочене, обл. Кърджали',
    '03188': 'с. Безводно, общ. Черноочене, обл. Кърджали',
    '03410': 'с. Бели вир, общ. Черноочене, обл. Кърджали',
    '03986': 'с. Беснурка, общ. Черноочене, обл. Кърджали',
    '05089': 'с. Божурци, общ. Черноочене, обл. Кърджали',
    '05640': 'с. Боровско, общ. Черноочене, обл. Кърджали',
    '05712': 'с. Босилица, общ. Черноочене, обл. Кърджали',
    '05791': 'с. Бостанци, общ. Черноочене, обл. Кърджали',
    '07472': 'с. Бърза река, общ. Черноочене, обл. Кърджали',
    '10700': 'с. Верско, общ. Черноочене, обл. Кърджали',
    '11613': 'с. Водач, общ. Черноочене, обл. Кърджали',
    '11805': 'с. Вождово, общ. Черноочене, обл. Кърджали',
    '11987': 'с. Войново, общ. Черноочене, обл. Кърджали',
    '12423': 'с. Възел, общ. Черноочене, обл. Кърджали',
    '14221': 'с. Габрово, общ. Черноочене, обл. Кърджали',
    '20167': 'с. Даскалово, общ. Черноочене, обл. Кърджали',
    '23114': 'с. Драганово, общ. Черноочене, обл. Кърджали',
    '24219': 'с. Душка, общ. Черноочене, обл. Кърджали',
    '24774': 'с. Дядовско, общ. Черноочене, обл. Кърджали',
    '29132': 'с. Железник, общ. Черноочене, обл. Кърджали',
    '29252': 'с. Женда, общ. Черноочене, обл. Кърджали',
    '29461': 'с. Житница, общ. Черноочене, обл. Кърджали',
    '34117': 'с. Йончово, общ. Черноочене, обл. Кърджали',
    '35047': 'с. Каблешково, общ. Черноочене, обл. Кърджали',
    '36066': 'с. Каняк, общ. Черноочене, обл. Кърджали',
    '38176': 'с. Комунига, общ. Черноочене, обл. Кърджали',
    '38491': 'с. Копитник, общ. Черноочене, обл. Кърджали',
    '40796': 'с. Куцово, общ. Черноочене, обл. Кърджали',
    '44820': 'с. Лясково, общ. Черноочене, обл. Кърджали',
    '48307': 'с. Минзухар, общ. Черноочене, обл. Кърджали',
    '49326': 'с. Мурга, общ. Черноочене, обл. Кърджали',
    '51192': 'с. Небеска, общ. Черноочене, обл. Кърджали',
    '51994': 'с. Нови пазар, общ. Черноочене, обл. Кърджали',
    '52153': 'с. Новоселище, общ. Черноочене, обл. Кърджали',
    '52372': 'с. Ночево, общ. Черноочене, обл. Кърджали',
    '55395': 'с. Паничково, общ. Черноочене, обл. Кърджали',
    '55568': 'с. Патица, общ. Черноочене, обл. Кърджали',
    '55960': 'с. Петелово, общ. Черноочене, обл. Кърджали',
    '58731': 'с. Пряпорец, общ. Черноочене, обл. Кърджали',
    '58829': 'с. Пчеларово, общ. Черноочене, обл. Кърджали',
    '63392': 'с. Русалина, общ. Черноочене, обл. Кърджали',
    '65841': 'с. Свободиново, общ. Черноочене, обл. Кърджали',
    '67893': 'с. Соколите, общ. Черноочене, обл. Кърджали',
    '68415': 'с. Среднево, общ. Черноочене, обл. Кърджали',
    '68566': 'с. Средска, общ. Черноочене, обл. Кърджали',
    '69657': 'с. Стражница, общ. Черноочене, обл. Кърджали',
    '80830': 'с. Черна нива, общ. Черноочене, обл. Кърджали',
    '81236': 'с. Черноочене, общ. Черноочене, обл. Кърджали',
    '87103': 'с. Ябълчени, общ. Черноочене, обл. Кърджали',
    '87165': 'с. Яворово, общ. Черноочене, обл. Кърджали',
    '04501': 'гр. Бобов дол, общ. Бобов дол, обл. Кюстендил',
    '02049': 'с. Бабино, общ. Бобов дол, обл. Кюстендил',
    '02052': 'с. Бабинска река, общ. Бобов дол, обл. Кюстендил',
    '04371': 'с. Блато, общ. Бобов дол, обл. Кюстендил',
    '15446': 'с. Голема Фуча, общ. Бобов дол, обл. Кюстендил',
    '15535': 'с. Големо село, общ. Бобов дол, обл. Кюстендил',
    '15457': 'с. Голям Върбовник, общ. Бобов дол, обл. Кюстендил',
    '16242': 'с. Горна Козница, общ. Бобов дол, обл. Кюстендил',
    '21974': 'с. Долистово, общ. Бобов дол, обл. Кюстендил',
    '38635': 'с. Коркина, общ. Бобов дол, обл. Кюстендил',
    '44210': 'с. Локвата, общ. Бобов дол, обл. Кюстендил',
    '46262': 'с. Мала Фуча, общ. Бобов дол, обл. Кюстендил',
    '46317': 'с. Мали Върбовник, общ. Бобов дол, обл. Кюстендил',
    '46824': 'с. Мало село, общ. Бобов дол, обл. Кюстендил',
    '48711': 'с. Мламолово, общ. Бобов дол, обл. Кюстендил',
    '52297': 'с. Новоселяне, общ. Бобов дол, обл. Кюстендил',
    '55364': 'с. Паничарево, общ. Бобов дол, обл. Кюстендил',
    '83082': 'с. Шатрово, общ. Бобов дол, обл. Кюстендил',
    '04532': 'гр. Бобошево, общ. Бобошево, обл. Кюстендил',
    '02172': 'с. Бадино, общ. Бобошево, обл. Кюстендил',
    '04314': 'с. Блажиево, общ. Бобошево, обл. Кюстендил',
    '11232': 'с. Висока могила, общ. Бобошево, обл. Кюстендил',
    '12351': 'с. Вуково, общ. Бобошево, обл. Кюстендил',
    '21565': 'с. Доброво, общ. Бобошево, обл. Кюстендил',
    '35804': 'с. Каменик, общ. Бобошево, обл. Кюстендил',
    '66888': 'с. Скрино, общ. Бобошево, обл. Кюстендил',
    '67252': 'с. Слатино, общ. Бобошево, обл. Кюстендил',
    '68062': 'с. Сопово, общ. Бобошево, обл. Кюстендил',
    '75174': 'с. Усойка, общ. Бобошево, обл. Кюстендил',
    '78495': 'с. Циклово, общ. Бобошево, обл. Кюстендил',
    '68789': 'гр. Дупница, общ. Дупница, обл. Кюстендил',
    '02350': 'с. Баланово, общ. Дупница, обл. Кюстендил',
    '04220': 'с. Бистрица, общ. Дупница, обл. Кюстендил',
    '04354': 'с. Блатино, общ. Дупница, обл. Кюстендил',
    '17659': 'с. Грамаде, общ. Дупница, обл. Кюстендил',
    '20612': 'с. Делян, общ. Дупница, обл. Кюстендил',
    '20763': 'с. Джерман, общ. Дупница, обл. Кюстендил',
    '24791': 'с. Дяково, общ. Дупница, обл. Кюстендил',
    '39325': 'с. Крайни дол, общ. Дупница, обл. Кюстендил',
    '39339': 'с. Крайници, общ. Дупница, обл. Кюстендил',
    '39640': 'с. Кременик, общ. Дупница, обл. Кюстендил',
    '55230': 'с. Палатово, общ. Дупница, обл. Кюстендил',
    '56349': 'с. Пиперево, общ. Дупница, обл. Кюстендил',
    '65245': 'с. Самораново, общ. Дупница, обл. Кюстендил',
    '72758': 'с. Тополница, общ. Дупница, обл. Кюстендил',
    '80491': 'с. Червен брег, общ. Дупница, обл. Кюстендил',
    '87727': 'с. Яхиново, общ. Дупница, обл. Кюстендил',
    '39116': 'гр. Кочериново, общ. Кочериново, обл. Кюстендил',
    '02748': 'с. Бараково, общ. Кочериново, обл. Кюстендил',
    '05565': 'с. Боровец, общ. Кочериново, обл. Кюстендил',
    '07065': 'с. Бураново, общ. Кочериново, обл. Кюстендил',
    '23323': 'с. Драгодан, общ. Кочериново, обл. Кюстендил',
    '39997': 'с. Крумово, общ. Кочериново, обл. Кюстендил',
    '49360': 'с. Мурсалево, общ. Кочериново, обл. Кюстендил',
    '57830': 'с. Пороминово, общ. Кочериново, обл. Кюстендил',
    '69261': 'с. Стоб, общ. Кочериново, обл. Кюстендил',
    '76180': 'с. Фролош, общ. Кочериново, обл. Кюстендил',
    '78625': 'с. Цървище, общ. Кочериново, обл. Кюстендил',
    '41112': 'гр. Кюстендил, общ. Кюстендил, обл. Кюстендил',
    '02138': 'с. Багренци, общ. Кюстендил, обл. Кюстендил',
    '03962': 'с. Берсин, общ. Кюстендил, обл. Кюстендил',
    '04323': 'с. Блатец, общ. Кюстендил, обл. Кюстендил',
    '04491': 'с. Бобешино, общ. Кюстендил, обл. Кюстендил',
    '04796': 'с. Богослов, общ. Кюстендил, обл. Кюстендил',
    '07048': 'с. Буново, общ. Кюстендил, обл. Кюстендил',
    '48355': 'с. Вратца, общ. Кюстендил, обл. Кюстендил',
    '14917': 'с. Гирчевци, общ. Кюстендил, обл. Кюстендил',
    '16026': 'с. Горановци, общ. Кюстендил, обл. Кюстендил',
    '16141': 'с. Горна Брестница, общ. Кюстендил, обл. Кюстендил',
    '16198': 'с. Горна Гращица, общ. Кюстендил, обл. Кюстендил',
    '16995': 'с. Горно Уйно, общ. Кюстендил, обл. Кюстендил',
    '17680': 'с. Грамаждано, общ. Кюстендил, обл. Кюстендил',
    '17751': 'с. Граница, общ. Кюстендил, обл. Кюстендил',
    '18112': 'с. Гурбановци, общ. Кюстендил, обл. Кюстендил',
    '18318': 'с. Гърбино, общ. Кюстендил, обл. Кюстендил',
    '18352': 'с. Гърляно, общ. Кюстендил, обл. Кюстендил',
    '18455': 'с. Гюешево, общ. Кюстендил, обл. Кюстендил',
    '20208': 'с. Дворище, общ. Кюстендил, обл. Кюстендил',
    '21782': 'с. Дождевица, общ. Кюстендил, обл. Кюстендил',
    '22071': 'с. Долна Гращица, общ. Кюстендил, обл. Кюстендил',
    '22811': 'с. Долно село, общ. Кюстендил, обл. Кюстендил',
    '22862': 'с. Долно Уйно, общ. Кюстендил, обл. Кюстендил',
    '23282': 'с. Драговищица, общ. Кюстендил, обл. Кюстендил',
    '29026': 'с. Жабокрът, общ. Кюстендил, обл. Кюстендил',
    '29266': 'с. Жеравино, общ. Кюстендил, обл. Кюстендил',
    '29386': 'с. Жиленци, общ. Кюстендил, обл. Кюстендил',
    '32144': 'с. Ивановци, общ. Кюстендил, обл. Кюстендил',
    '35835': 'с. Каменичка Скакавица, общ. Кюстендил, обл. Кюстендил',
    '36659': 'с. Катрище, общ. Кюстендил, обл. Кюстендил',
    '38432': 'с. Коняво, общ. Кюстендил, обл. Кюстендил',
    '38474': 'с. Копиловци, общ. Кюстендил, обл. Кюстендил',
    '38515': 'с. Коприва, общ. Кюстендил, обл. Кюстендил',
    '40765': 'с. Кутугерци, общ. Кюстендил, обл. Кюстендил',
    '40991': 'с. Кършалево, общ. Кюстендил, обл. Кюстендил',
    '43267': 'с. Лелинци, общ. Кюстендил, обл. Кюстендил',
    '43373': 'с. Леска, общ. Кюстендил, обл. Кюстендил',
    '43815': 'с. Лисец, общ. Кюстендил, обл. Кюстендил',
    '44183': 'с. Лозно, общ. Кюстендил, обл. Кюстендил',
    '44255': 'с. Ломница, общ. Кюстендил, обл. Кюстендил',
    '46108': 'с. Мазарачево, общ. Кюстендил, обл. Кюстендил',
    '52400': 'с. Николичевци, общ. Кюстендил, обл. Кюстендил',
    '52026': 'с. Нови чифлик, общ. Кюстендил, обл. Кюстендил',
    '52194': 'с. Ново село, общ. Кюстендил, обл. Кюстендил',
    '56378': 'с. Пиперков чифлик, общ. Кюстендил, обл. Кюстендил',
    '57188': 'с. Полетинци, общ. Кюстендил, обл. Кюстендил',
    '57319': 'с. Полска Скакавица, общ. Кюстендил, обл. Кюстендил',
    '58129': 'с. Преколница, общ. Кюстендил, обл. Кюстендил',
    '61457': 'с. Радловци, общ. Кюстендил, обл. Кюстендил',
    '63536': 'с. Раждавица, общ. Кюстендил, обл. Кюстендил',
    '62181': 'с. Раненци, общ. Кюстендил, обл. Кюстендил',
    '62428': 'с. Режинци, общ. Кюстендил, обл. Кюстендил',
    '63608': 'с. Ръсово, общ. Кюстендил, обл. Кюстендил',
    '65047': 'с. Савойски, общ. Кюстендил, обл. Кюстендил',
    '65142': 'с. Сажденик, общ. Кюстендил, обл. Кюстендил',
    '66891': 'с. Скриняно, общ. Кюстендил, обл. Кюстендил',
    '67461': 'с. Слокощица, общ. Кюстендил, обл. Кюстендил',
    '67790': 'с. Соволяно, общ. Кюстендил, обл. Кюстендил',
    '69167': 'с. Стенско, общ. Кюстендил, обл. Кюстендил',
    '72045': 'с. Таваличево, общ. Кюстендил, обл. Кюстендил',
    '73691': 'с. Търновлаг, общ. Кюстендил, обл. Кюстендил',
    '73750': 'с. Търсино, общ. Кюстендил, обл. Кюстендил',
    '78447': 'с. Церовица, общ. Кюстендил, обл. Кюстендил',
    '78543': 'с. Црешново, общ. Кюстендил, обл. Кюстендил',
    '78598': 'с. Цървена ябълка, общ. Кюстендил, обл. Кюстендил',
    '78608': 'с. Цървендол, общ. Кюстендил, обл. Кюстендил',
    '78611': 'с. Цървеняно, общ. Кюстендил, обл. Кюстендил',
    '81606': 'с. Чудинци, общ. Кюстендил, обл. Кюстендил',
    '83257': 'с. Шипочано, общ. Кюстендил, обл. Кюстендил',
    '83354': 'с. Шишковци, общ. Кюстендил, обл. Кюстендил',
    '87062': 'с. Ябълково, общ. Кюстендил, обл. Кюстендил',
    '10050': 'с. Ваксево, общ. Невестино, обл. Кюстендил',
    '10817': 'с. Ветрен, общ. Невестино, обл. Кюстендил',
    '21292': 'с. Длъхчево-Сабляр, общ. Невестино, обл. Кюстендил',
    '22112': 'с. Долна Козница, общ. Невестино, обл. Кюстендил',
    '23827': 'с. Друмохар, общ. Невестино, обл. Кюстендил',
    '27574': 'с. Еремия, общ. Невестино, обл. Кюстендил',
    '30613': 'с. Згурово, общ. Невестино, обл. Кюстендил',
    '32696': 'с. Илия, общ. Невестино, обл. Кюстендил',
    '35119': 'с. Кадровица, общ. Невестино, обл. Кюстендил',
    '43709': 'с. Лиляч, общ. Невестино, обл. Кюстендил',
    '49566': 'с. Мърводол, общ. Невестино, обл. Кюстендил',
    '51216': 'с. Невестино, общ. Невестино, обл. Кюстендил',
    '51336': 'с. Неделкова Гращица, общ. Невестино, обл. Кюстендил',
    '55542': 'с. Пастух, общ. Невестино, обл. Кюстендил',
    '55734': 'с. Пелатиково, общ. Невестино, обл. Кюстендил',
    '62058': 'с. Раково, общ. Невестино, обл. Кюстендил',
    '62284': 'с. Рашка Гращица, общ. Невестино, обл. Кюстендил',
    '67619': 'с. Смоличано, общ. Невестино, обл. Кюстендил',
    '69571': 'с. Страдалово, общ. Невестино, обл. Кюстендил',
    '72494': 'с. Тишаново, общ. Невестино, обл. Кюстендил',
    '78584': 'с. Църварица, общ. Невестино, обл. Кюстендил',
    '80251': 'с. Чеканец, общ. Невестино, обл. Кюстендил',
    '81284': 'с. Четирци, общ. Невестино, обл. Кюстендил',
    '62671': 'гр. Рила, общ. Рила, обл. Кюстендил',
    '62685': 'ман. Рилски манастир, общ. Рила, обл. Кюстендил',
    '55083': 'с. Падала, общ. Рила, обл. Кюстендил',
    '55539': 'с. Пастра, общ. Рила, обл. Кюстендил',
    '67698': 'с. Смочево, общ. Рила, обл. Кюстендил',
    '65365': 'гр. Сапарева баня, общ. Сапарева баня, обл. Кюстендил',
    '53254': 'с. Овчарци, общ. Сапарева баня, обл. Кюстендил',
    '59358': 'с. Паничище, общ. Сапарева баня, обл. Кюстендил',
    '62520': 'с. Ресилово, общ. Сапарева баня, обл. Кюстендил',
    '65379': 'с. Сапарево, общ. Сапарева баня, обл. Кюстендил',
    '06392': 'с. Брест, общ. Трекляно, обл. Кюстендил',
    '07226': 'с. Бъзовица, общ. Трекляно, обл. Кюстендил',
    '14101': 'с. Габрешевци, общ. Трекляно, обл. Кюстендил',
    '16554': 'с. Горни Коритен, общ. Трекляно, обл. Кюстендил',
    '16804': 'с. Горно Кобиле, общ. Трекляно, обл. Кюстендил',
    '21422': 'с. Добри дол, общ. Трекляно, обл. Кюстендил',
    '22410': 'с. Долни Коритен, общ. Трекляно, обл. Кюстендил',
    '22650': 'с. Долно Кобиле, общ. Трекляно, обл. Кюстендил',
    '23399': 'с. Драгойчинци, общ. Трекляно, обл. Кюстендил',
    '31200': 'с. Злогош, общ. Трекляно, обл. Кюстендил',
    '36960': 'с. Киселица, общ. Трекляно, обл. Кюстендил',
    '38786': 'с. Косово, общ. Трекляно, обл. Кюстендил',
    '47929': 'с. Метохия, общ. Трекляно, обл. Кюстендил',
    '56887': 'с. Побит камък, общ. Трекляно, обл. Кюстендил',
    '68535': 'с. Средорек, общ. Трекляно, обл. Кюстендил',
    '70411': 'с. Сушица, общ. Трекляно, обл. Кюстендил',
    '73047': 'с. Трекляно, общ. Трекляно, обл. Кюстендил',
    '75232': 'с. Уши, общ. Трекляно, обл. Кюстендил',
    '81325': 'с. Чешлянци, общ. Трекляно, обл. Кюстендил',
    '52218': 'гр. Априлци, общ. Априлци, обл. Ловеч',
    '10567': 'с. Велчево, общ. Априлци, обл. Ловеч',
    '23621': 'с. Драшкова поляна, общ. Априлци, обл. Ловеч',
    '66771': 'с. Скандалото, общ. Априлци, обл. Ловеч',
    '43476': 'гр. Летница, общ. Летница, обл. Ловеч',
    '17244': 'с. Горско Сливово, общ. Летница, обл. Ловеч',
    '40261': 'с. Крушуна, общ. Летница, обл. Ловеч',
    '40943': 'с. Кърпачево, общ. Летница, обл. Ловеч',
    '43952': 'гр. Ловеч, общ. Ловеч, обл. Ловеч',
    '00028': 'с. Абланица, общ. Ловеч, обл. Ловеч',
    '00299': 'с. Александрово, общ. Ловеч, обл. Ловеч',
    '02935': 'с. Баховица, общ. Ловеч, обл. Ловеч',
    '06536': 'с. Брестово, общ. Ловеч, обл. Ловеч',
    '07260': 'с. Българене, общ. Ловеч, обл. Ловеч',
    '11452': 'с. Владиня, общ. Ловеч, обл. Ловеч',
    '16002': 'с. Горан, общ. Ловеч, обл. Ловеч',
    '16883': 'с. Горно Павликене, общ. Ловеч, обл. Ловеч',
    '17378': 'с. Гостиня, общ. Ловеч, обл. Ловеч',
    '20420': 'с. Деветаки, общ. Ловеч, обл. Ловеч',
    '21823': 'с. Дойренци, общ. Ловеч, обл. Ловеч',
    '23666': 'с. Дренов, общ. Ловеч, обл. Ловеч',
    '24373': 'с. Дъбрава, общ. Ловеч, обл. Ловеч',
    '32500': 'с. Изворче, общ. Ловеч, обл. Ловеч',
    '34093': 'с. Йоглав, общ. Ловеч, обл. Ловеч',
    '35198': 'с. Казачево, общ. Ловеч, обл. Ловеч',
    '40837': 'с. Къкрина, общ. Ловеч, обл. Ловеч',
    '43579': 'с. Лешница, общ. Ловеч, обл. Ловеч',
    '43829': 'с. Лисец, общ. Ловеч, обл. Ловеч',
    '46396': 'с. Малиново, общ. Ловеч, обл. Ловеч',
    '58150': 'с. Прелом, общ. Ловеч, обл. Ловеч',
    '58308': 'с. Пресяка, общ. Ловеч, обл. Ловеч',
    '69523': 'с. Радювене, общ. Ловеч, обл. Ловеч',
    '66799': 'с. Скобелево, общ. Ловеч, обл. Ловеч',
    '67060': 'с. Славяни, общ. Ловеч, обл. Ловеч',
    '67218': 'с. Слатина, общ. Ловеч, обл. Ловеч',
    '67324': 'с. Сливек, общ. Ловеч, обл. Ловеч',
    '67670': 'с. Смочан, общ. Ловеч, обл. Ловеч',
    '67948': 'с. Соколово, общ. Ловеч, обл. Ловеч',
    '69225': 'с. Стефаново, общ. Ловеч, обл. Ловеч',
    '72254': 'с. Тепава, общ. Ловеч, обл. Ловеч',
    '75112': 'с. Умаревци, общ. Ловеч, обл. Ловеч',
    '77311': 'с. Хлевене, общ. Ловеч, обл. Ловеч',
    '80039': 'с. Чавдарци, общ. Ловеч, обл. Ловеч',
    '44327': 'гр. Луковит, общ. Луковит, обл. Ловеч',
    '03143': 'с. Бежаново, общ. Луковит, обл. Ловеч',
    '03373': 'с. Беленци, общ. Луковит, обл. Ловеч',
    '20688': 'с. Дерманци, общ. Луковит, обл. Ловеч',
    '24236': 'с. Дъбен, общ. Луковит, обл. Ловеч',
    '36511': 'с. Карлуково, общ. Луковит, обл. Ловеч',
    '56099': 'с. Петревене, общ. Луковит, обл. Ловеч',
    '56318': 'с. Пещерна, общ. Луковит, обл. Ловеч',
    '63327': 'с. Румянцево, общ. Луковит, обл. Ловеч',
    '72552': 'с. Тодоричене, общ. Луковит, обл. Ловеч',
    '43058': 'с. Торос, общ. Луковит, обл. Ловеч',
    '85010': 'с. Ъглен, общ. Луковит, обл. Ловеч',
    '72343': 'гр. Тетевен, общ. Тетевен, обл. Ловеч',
    '07884': 'с. Бабинци, общ. Тетевен, обл. Ловеч',
    '07357': 'с. Български извор, общ. Тетевен, обл. Ловеч',
    '10272': 'с. Васильово, общ. Тетевен, обл. Ловеч',
    '14386': 'с. Галата, общ. Тетевен, обл. Ловеч',
    '15148': 'с. Глогово, общ. Тетевен, обл. Ловеч',
    '15165': 'с. Гложене, общ. Тетевен, обл. Ловеч',
    '15758': 'с. Голям извор, общ. Тетевен, обл. Ловеч',
    '17419': 'с. Градежница, общ. Тетевен, обл. Ловеч',
    '20996': 'с. Дивчовото, общ. Тетевен, обл. Ловеч',
    '46437': 'с. Малка Желязна, общ. Тетевен, обл. Ловеч',
    '62579': 'с. Рибарица, общ. Тетевен, обл. Ловеч',
    '80902': 'с. Черни Вит, общ. Тетевен, обл. Ловеч',
    '73198': 'гр. Троян, общ. Троян, обл. Ловеч',
    '02302': 'с. Балабанско, общ. Троян, обл. Ловеч',
    '02448': 'с. Балканец, общ. Троян, обл. Ловеч',
    '03486': 'с. Бели Осъм, общ. Троян, обл. Ловеч',
    '03558': 'с. Белиш, общ. Троян, обл. Ловеч',
    '05445': 'с. Борима, общ. Троян, обл. Ловеч',
    '12108': 'с. Врабево, общ. Троян, обл. Ловеч',
    '15703': 'с. Голяма Желязна, общ. Троян, обл. Ловеч',
    '16986': 'с. Горно трапе, общ. Троян, обл. Ловеч',
    '18109': 'с. Гумощник, общ. Троян, обл. Ловеч',
    '20300': 'с. Дебнево, общ. Троян, обл. Ловеч',
    '21590': 'с. Добродан, общ. Троян, обл. Ловеч',
    '24476': 'с. Дълбок дол, общ. Троян, обл. Ловеч',
    '35290': 'с. Калейца, общ. Троян, обл. Ловеч',
    '44241': 'с. Ломец, общ. Троян, обл. Ловеч',
    '53707': 'с. Орешак, общ. Троян, обл. Ловеч',
    '55587': 'с. Патрешко, общ. Троян, обл. Ловеч',
    '70500': 'с. Старо село, общ. Троян, обл. Ловеч',
    '72309': 'с. Терзийско, общ. Троян, обл. Ловеч',
    '80981': 'с. Черни Осъм, общ. Троян, обл. Ловеч',
    '81476': 'с. Чифлик, общ. Троян, обл. Ловеч',
    '83212': 'с. Шипково, общ. Троян, обл. Ловеч',
    '75054': 'гр. Угърчин, общ. Угърчин, обл. Ловеч',
    '15552': 'с. Голец, общ. Угърчин, обл. Ловеч',
    '23060': 'с. Драгана, общ. Угърчин, обл. Ловеч',
    '35331': 'с. Каленик, общ. Угърчин, обл. Ловеч',
    '36662': 'с. Катунец, общ. Угърчин, обл. Ловеч',
    '36943': 'с. Кирчево, общ. Угърчин, обл. Ловеч',
    '43325': 'с. Лесидрен, общ. Угърчин, обл. Ловеч',
    '48060': 'с. Микре, общ. Угърчин, обл. Ловеч',
    '53967': 'с. Орляне, общ. Угърчин, обл. Ловеч',
    '67057': 'с. Славщица, общ. Угърчин, обл. Ловеч',
    '68076': 'с. Сопот, общ. Угърчин, обл. Ловеч',
    '87014': 'гр. Ябланица, общ. Ябланица, обл. Ловеч',
    '02909': 'с. Батулци, общ. Ябланица, обл. Ловеч',
    '06450': 'с. Брестница, общ. Ябланица, обл. Ловеч',
    '15655': 'с. Голяма Брестница, общ. Ябланица, обл. Ловеч',
    '21381': 'с. Добревци, общ. Ябланица, обл. Ловеч',
    '24390': 'с. Дъбравата, общ. Ябланица, обл. Ловеч',
    '31098': 'с. Златна Панега, общ. Ябланица, обл. Ловеч',
    '46886': 'с. Малък извор, общ. Ябланица, обл. Ловеч',
    '53730': 'с. Орешене, общ. Ябланица, обл. Ловеч',
    '03928': 'гр. Берковица, общ. Берковица, обл. Монтана',
    '02542': 'с. Балювица, общ. Берковица, обл. Монтана',
    '04203': 'с. Бистрилица, общ. Берковица, обл. Монтана',
    '05253': 'с. Бокиловци, общ. Берковица, обл. Монтана',
    '05654': 'с. Боровци, общ. Берковица, обл. Монтана',
    '07510': 'с. Бързия, общ. Берковица, обл. Монтана',
    '14297': 'с. Гаганица, общ. Берковица, обл. Монтана',
    '30301': 'с. Замфирово, общ. Берковица, обл. Монтана',
    '38131': 'с. Комарево, общ. Берковица, обл. Монтана',
    '38933': 'с. Костенци, общ. Берковица, обл. Монтана',
    '39044': 'с. Котеновци, общ. Берковица, обл. Монтана',
    '43414': 'с. Лесковец, общ. Берковица, обл. Монтана',
    '47723': 'с. Мездрея, общ. Берковица, обл. Монтана',
    '55930': 'с. Песочница, общ. Берковица, обл. Монтана',
    '59118': 'с. Пърличево, общ. Берковица, обл. Монтана',
    '62311': 'с. Рашовица, общ. Берковица, обл. Монтана',
    '67221': 'с. Слатина, общ. Берковица, обл. Монтана',
    '78286': 'с. Цветкова бара, общ. Берковица, обл. Монтана',
    '80635': 'с. Черешовица, общ. Берковица, обл. Монтана',
    '87237': 'с. Ягодово, общ. Берковица, обл. Монтана',
    '05236': 'гр. Бойчиновци, общ. Бойчиновци, обл. Монтана',
    '03383': 'с. Бели брег, общ. Бойчиновци, обл. Монтана',
    '03397': 'с. Бели брод, общ. Бойчиновци, обл. Монтана',
    '11418': 'с. Владимирово, общ. Бойчиновци, обл. Монтана',
    '17943': 'с. Громшин, общ. Бойчиновци, обл. Монтана',
    '27557': 'с. Ерден, общ. Бойчиновци, обл. Монтана',
    '37424': 'с. Кобиляк, общ. Бойчиновци, обл. Монтана',
    '43517': 'с. Лехчево, общ. Бойчиновци, обл. Монтана',
    '46036': 'с. Мадан, общ. Бойчиновци, обл. Монтана',
    '49607': 'с. Мърчево, общ. Бойчиновци, обл. Монтана',
    '54496': 'с. Охрид, общ. Бойчиновци, обл. Монтана',
    '55258': 'с. Палилула, общ. Бойчиновци, обл. Монтана',
    '57844': 'с. Портитовци, общ. Бойчиновци, обл. Монтана',
    '06570': 'гр. Брусарци, общ. Брусарци, обл. Монтана',
    '06971': 'с. Буковец, общ. Брусарци, обл. Монтана',
    '10255': 'с. Василовци, общ. Брусарци, обл. Монтана',
    '22945': 'с. Дондуково, общ. Брусарци, обл. Монтана',
    '24298': 'с. Дъбова махала, общ. Брусарци, обл. Монтана',
    '36957': 'с. Киселево, общ. Брусарци, обл. Монтана',
    '37397': 'с. Княжева махала, общ. Брусарци, обл. Монтана',
    '39743': 'с. Крива бара, общ. Брусарци, обл. Монтана',
    '53401': 'с. Одоровци, общ. Брусарци, обл. Монтана',
    '67564': 'с. Смирненски, общ. Брусарци, обл. Монтана',
    '12543': 'гр. Вълчедръм, общ. Вълчедръм, обл. Монтана',
    '05852': 'с. Ботево, общ. Вълчедръм, обл. Монтана',
    '07209': 'с. Бъзовец, общ. Вълчедръм, обл. Монтана',
    '16639': 'с. Горни Цибър, общ. Вълчедръм, обл. Монтана',
    '22530': 'с. Долни Цибър, общ. Вълчедръм, обл. Монтана',
    '31053': 'с. Златия, общ. Вълчедръм, обл. Монтана',
    '32295': 'с. Игнатово, общ. Вълчедръм, обл. Монтана',
    '48859': 'с. Мокреш, общ. Вълчедръм, обл. Монтана',
    '61707': 'с. Разград, общ. Вълчедръм, обл. Монтана',
    '16184': 'с. Септемврийци, общ. Вълчедръм, обл. Монтана',
    '80933': 'с. Черни връх, общ. Вълчедръм, обл. Монтана',
    '12961': 'гр. Вършец, общ. Вършец, обл. Монтана',
    '37318': 'ман. Клисурски манастир, общ. Вършец, обл. Монтана',
    '16119': 'с. Горна Бела речка, общ. Вършец, обл. Монтана',
    '16866': 'с. Горно Озирово, общ. Вършец, обл. Монтана',
    '22019': 'с. Долна Бела речка, общ. Вършец, обл. Монтана',
    '22747': 'с. Долно Озирово, общ. Вършец, обл. Монтана',
    '23073': 'с. Драганица, общ. Вършец, обл. Монтана',
    '68179': 'с. Спанчевци, общ. Вършец, обл. Монтана',
    '69537': 'с. Стояново, общ. Вършец, обл. Монтана',
    '80683': 'с. Черкаски, общ. Вършец, обл. Монтана',
    '10999': 'с. Видлица, общ. Георги Дамяново, обл. Монтана',
    '14283': 'с. Гаврил Геново, общ. Георги Дамяново, обл. Монтана',
    '14773': 'с. Георги Дамяново, общ. Георги Дамяново, обл. Монтана',
    '14965': 'с. Главановци, общ. Георги Дамяново, обл. Монтана',
    '15299': 'с. Говежда, общ. Георги Дамяново, обл. Монтана',
    '20941': 'с. Дива Слатина, общ. Георги Дамяново, обл. Монтана',
    '24534': 'с. Дълги дел, общ. Георги Дамяново, обл. Монтана',
    '27348': 'с. Еловица, общ. Георги Дамяново, обл. Монтана',
    '35871': 'с. Каменна Рикса, общ. Георги Дамяново, обл. Монтана',
    '38488': 'с. Копиловци, общ. Георги Дамяново, обл. Монтана',
    '47771': 'с. Меляне, общ. Георги Дамяново, обл. Монтана',
    '57474': 'с. Помеждин, общ. Георги Дамяново, обл. Монтана',
    '80368': 'с. Чемиш, общ. Георги Дамяново, обл. Монтана',
    '44238': 'гр. Лом, общ. Лом, обл. Монтана',
    '21436': 'с. Добри дол, общ. Лом, обл. Монтана',
    '22695': 'с. Долно Линево, общ. Лом, обл. Монтана',
    '30291': 'с. Замфир, общ. Лом, обл. Монтана',
    '37544': 'с. Ковачица, общ. Лом, обл. Монтана',
    '53970': 'с. Орсоя, общ. Лом, обл. Монтана',
    '67310': 'с. Сливата, общ. Лом, обл. Монтана',
    '68672': 'с. Сталийска махала, общ. Лом, обл. Монтана',
    '68758': 'с. Станево, общ. Лом, обл. Монтана',
    '72940': 'с. Трайково, общ. Лом, обл. Монтана',
    '00792': 'с. Аспарухово, общ. Медковец, обл. Монтана',
    '47593': 'с. Медковец, общ. Медковец, обл. Монтана',
    '56544': 'с. Пишурка, общ. Медковец, обл. Монтана',
    '62222': 'с. Расово, общ. Медковец, обл. Монтана',
    '67386': 'с. Сливовик, общ. Медковец, обл. Монтана',
    '48489': 'гр. Монтана, общ. Монтана, обл. Монтана',
    '03201': 'с. Безденица, общ. Монтана, обл. Монтана',
    '03722': 'с. Белотинци, общ. Монтана, обл. Монтана',
    '04251': 'с. Благово, общ. Монтана, обл. Монтана',
    '11137': 'с. Винище, общ. Монтана, обл. Монтана',
    '11171': 'с. Вирове, общ. Монтана, обл. Монтана',
    '11939': 'с. Войници, общ. Монтана, обл. Монтана',
    '14180': 'с. Габровница, общ. Монтана, обл. Монтана',
    '16153': 'с. Горна Вереница, общ. Монтана, обл. Монтана',
    '17018': 'с. Горно Церовене, общ. Монтана, обл. Монтана',
    '21840': 'с. Доктор Йосифово, общ. Монтана, обл. Монтана',
    '22040': 'с. Долна Вереница, общ. Монтана, обл. Монтана',
    '22250': 'с. Долна Рикса, общ. Монтана, обл. Монтана',
    '03736': 'с. Долно Белотинци, общ. Монтана, обл. Монтана',
    '37304': 'с. Клисурица, общ. Монтана, обл. Монтана',
    '39503': 'с. Крапчене, общ. Монтана, обл. Монтана',
    '43730': 'с. Липен, общ. Монтана, обл. Монтана',
    '51665': 'с. Николово, общ. Монтана, обл. Монтана',
    '67043': 'с. Славотин, общ. Монтана, обл. Монтана',
    '67667': 'с. Смоляновци, общ. Монтана, обл. Монтана',
    '70024': 'с. Стубел, общ. Монтана, обл. Монтана',
    '70113': 'с. Студено буче, общ. Монтана, обл. Монтана',
    '70233': 'с. Сумер, общ. Монтана, обл. Монтана',
    '73167': 'с. Трифоново, общ. Монтана, обл. Монтана',
    '81390': 'гр. Чипровци, общ. Чипровци, обл. Монтана',
    '03469': 'с. Белимел, общ. Чипровци, обл. Монтана',
    '16239': 'с. Горна Ковачица, общ. Чипровци, обл. Монтана',
    '16300': 'с. Горна Лука, общ. Чипровци, обл. Монтана',
    '29115': 'с. Железна, общ. Чипровци, обл. Монтана',
    '47353': 'с. Мартиново, общ. Чипровци, обл. Монтана',
    '48475': 'с. Митровци, общ. Чипровци, обл. Монтана',
    '58116': 'с. Превала, общ. Чипровци, обл. Монтана',
    '61090': 'с. Равна, общ. Чипровци, обл. Монтана',
    '80354': 'с. Челюстница, общ. Чипровци, обл. Монтана',
    '22873': 'с. Долно Церовене, общ. Якимово, обл. Монтана',
    '24551': 'с. Дългоделци, общ. Якимово, обл. Монтана',
    '38159': 'с. Комощица, общ. Якимово, обл. Монтана',
    '87299': 'с. Якимово, общ. Якимово, обл. Монтана',
    '02837': 'гр. Батак, общ. Батак, обл. Пазарджик',
    '51874': 'с. Нова махала, общ. Батак, обл. Пазарджик',
    '76162': 'с. Фотиново, общ. Батак, обл. Пазарджик',
    '03592': 'гр. Белово, общ. Белово, обл. Пазарджик',
    '00165': 'с. Аканджиево, общ. Белово, обл. Пазарджик',
    '14163': 'с. Габровица, общ. Белово, обл. Пазарджик',
    '15802': 'с. Голямо Белово, общ. Белово, обл. Пазарджик',
    '24414': 'с. Дъбравите, общ. Белово, обл. Пазарджик',
    '47812': 'с. Мененкьово, общ. Белово, обл. Пазарджик',
    '48903': 'с. Момина клисура, общ. Белово, обл. Пазарджик',
    '66319': 'с. Сестримо, общ. Белово, обл. Пазарджик',
    '06207': 'гр. Брацигово, общ. Брацигово, обл. Пазарджик',
    '07586': 'с. Бяга, общ. Брацигово, обл. Пазарджик',
    '29522': 'с. Жребичко, общ. Брацигово, обл. Пазарджик',
    '32888': 'с. Исперихово, общ. Брацигово, обл. Пазарджик',
    '37705': 'с. Козарско, общ. Брацигово, обл. Пазарджик',
    '61220': 'с. Равногор, общ. Брацигово, обл. Пазарджик',
    '62973': 'с. Розово, общ. Брацигово, обл. Пазарджик',
    '10450': 'гр. Велинград, общ. Велинград, обл. Пазарджик',
    '00881': 'с. Абланица, общ. Велинград, обл. Пазарджик',
    '83421': 'с. Алендарова, общ. Велинград, обл. Пазарджик',
    '07836': 'с. Биркова, общ. Велинград, обл. Пазарджик',
    '07853': 'с. Бозьова, общ. Велинград, обл. Пазарджик',
    '07867': 'с. Бутрева, общ. Велинград, обл. Пазарджик',
    '12989': 'с. Враненци, общ. Велинград, обл. Пазарджик',
    '12334': 'с. Всемирци, общ. Велинград, обл. Пазарджик',
    '18513': 'с. Горна Биркова, общ. Велинград, обл. Пазарджик',
    '18527': 'с. Горна Дъбева, общ. Велинград, обл. Пазарджик',
    '17823': 'с. Грашево, общ. Велинград, обл. Пазарджик',
    '24325': 'с. Долна Дъбева, общ. Велинград, обл. Пазарджик',
    '23234': 'с. Драгиново, общ. Велинград, обл. Пазарджик',
    '36035': 'с. Кандови, общ. Велинград, обл. Пазарджик',
    '41136': 'с. Кръстава, общ. Велинград, обл. Пазарджик',
    '55631': 'с. Пашови, общ. Велинград, обл. Пазарджик',
    '63687': 'с. Рохлева, общ. Велинград, обл. Пазарджик',
    '65534': 'с. Света Петка, общ. Велинград, обл. Пазарджик',
    '78272': 'с. Цветино, общ. Велинград, обл. Пазарджик',
    '81863': 'с. Чолакова, общ. Велинград, обл. Пазарджик',
    '86115': 'с. Юндола, общ. Велинград, обл. Пазарджик',
    '05459': 'с. Боримечково, общ. Лесичово, обл. Пазарджик',
    '21172': 'с. Динката, общ. Лесичово, обл. Пазарджик',
    '35571': 'с. Калугерово, общ. Лесичово, обл. Пазарджик',
    '43369': 'с. Лесичово, общ. Лесичово, обл. Пазарджик',
    '55275': 'с. Памидово, общ. Лесичово, обл. Пазарджик',
    '78478': 'с. Церово, общ. Лесичово, обл. Пазарджик',
    '84067': 'с. Щърково, общ. Лесичово, обл. Пазарджик',
    '55155': 'гр. Пазарджик, общ. Пазарджик, обл. Пазарджик',
    '00254': 'с. Алеко Константиново, общ. Пазарджик, обл. Пазарджик',
    '00571': 'с. Априлци, общ. Пазарджик, обл. Пазарджик',
    '06149': 'с. Братаница, общ. Пазарджик, обл. Пазарджик',
    '10505': 'с. Величково, общ. Пазарджик, обл. Пазарджик',
    '14619': 'с. Гелеменово, общ. Пазарджик, обл. Пазарджик',
    '15028': 'с. Главиница, общ. Пазарджик, обл. Пазарджик',
    '15271': 'с. Говедаре, общ. Пазарджик, обл. Пазарджик',
    '20362': 'с. Дебръщица, общ. Пазарджик, обл. Пазарджик',
    '21556': 'с. Добровница, общ. Пазарджик, обл. Пазарджик',
    '23457': 'с. Драгор, общ. Пазарджик, обл. Пазарджик',
    '30572': 'с. Звъничево, общ. Пазарджик, обл. Пазарджик',
    '32010': 'с. Ивайло, общ. Пазарджик, обл. Пазарджик',
    '39428': 'с. Крали Марко, общ. Пазарджик, обл. Пазарджик',
    '44879': 'с. Ляхово, общ. Пазарджик, обл. Пазарджик',
    '46749': 'с. Мало Конаре, общ. Пазарджик, обл. Пазарджик',
    '48444': 'с. Мирянци, общ. Пазарджик, обл. Пазарджик',
    '48876': 'с. Мокрище, общ. Пазарджик, обл. Пазарджик',
    '53285': 'с. Овчеполци, общ. Пазарджик, обл. Пазарджик',
    '53335': 'с. Огняново, общ. Пазарджик, обл. Пазарджик',
    '55556': 'с. Паталеница, общ. Пазарджик, обл. Пазарджик',
    '56561': 'с. Пищигово, общ. Пазарджик, обл. Пазарджик',
    '63032': 'с. Росен, общ. Пазарджик, обл. Пазарджик',
    '65437': 'с. Сарая, общ. Пазарджик, обл. Пазарджик',
    '65468': 'с. Сбор, общ. Пазарджик, обл. Пазарджик',
    '66559': 'с. Синитово, общ. Пазарджик, обл. Пазарджик',
    '72713': 'с. Тополи дол, общ. Пазарджик, обл. Пазарджик',
    '77061': 'с. Хаджиево, общ. Пазарджик, обл. Пазарджик',
    '78056': 'с. Цар Асен, общ. Пазарджик, обл. Пазарджик',
    '78570': 'с. Црънча, общ. Пазарджик, обл. Пазарджик',
    '81089': 'с. Черногорово, общ. Пазарджик, обл. Пазарджик',
    '86074': 'с. Юнаците, общ. Пазарджик, обл. Пазарджик',
    '55302': 'гр. Панагюрище, общ. Панагюрище, обл. Пазарджик',
    '02717': 'с. Баня, общ. Панагюрище, обл. Пазарджик',
    '07572': 'с. Бъта, общ. Панагюрище, обл. Пазарджик',
    '27406': 'с. Елшица, общ. Панагюрище, обл. Пазарджик',
    '22681': 'с. Левски, общ. Панагюрище, обл. Пазарджик',
    '53103': 'с. Оборище, общ. Панагюрище, обл. Пазарджик',
    '59375': 'с. Панагюрски колонии, общ. Панагюрище, обл. Пазарджик',
    '57128': 'с. Поибрене, общ. Панагюрище, обл. Пазарджик',
    '57580': 'с. Попинци, общ. Панагюрище, обл. Пазарджик',
    '68271': 'с. Сребриново, общ. Панагюрище, обл. Пазарджик',
    '56277': 'гр. Пещера, общ. Пещера, обл. Пазарджик',
    '36124': 'с. Капитан Димитриево, общ. Пещера, обл. Пазарджик',
    '61371': 'с. Радилово, общ. Пещера, обл. Пазарджик',
    '87825': 'с. Свети Константин, общ. Пещера, обл. Пазарджик',
    '38844': 'гр. Костандово, общ. Ракитово, обл. Пазарджик',
    '62004': 'гр. Ракитово, общ. Ракитово, обл. Пазарджик',
    '23008': 'с. Дорково, общ. Ракитово, обл. Пазарджик',
    '10820': 'гр. Ветрен, общ. Септември, обл. Пазарджик',
    '66264': 'гр. Септември, общ. Септември, обл. Пазарджик',
    '05949': 'с. Бошуля, общ. Септември, обл. Пазарджик',
    '10104': 'с. Варвара, общ. Септември, обл. Пазарджик',
    '10851': 'с. Ветрен дол, общ. Септември, обл. Пазарджик',
    '11154': 'с. Виноградец, общ. Септември, обл. Пазарджик',
    '16732': 'с. Горно вършило, общ. Септември, обл. Пазарджик',
    '22592': 'с. Долно вършило, общ. Септември, обл. Пазарджик',
    '31214': 'с. Злокучене, общ. Септември, обл. Пазарджик',
    '36172': 'с. Карабунар, общ. Септември, обл. Пазарджик',
    '37491': 'с. Ковачево, общ. Септември, обл. Пазарджик',
    '44053': 'с. Лозен, общ. Септември, обл. Пазарджик',
    '66202': 'с. Семчиново, общ. Септември, обл. Пазарджик',
    '66439': 'с. Симеоновец, общ. Септември, обл. Пазарджик',
    '67009': 'с. Славовица, общ. Септември, обл. Пазарджик',
    '69835': 'гр. Стрелча, общ. Стрелча, обл. Пазарджик',
    '04368': 'с. Блатница, общ. Стрелча, обл. Пазарджик',
    '24726': 'с. Дюлево, общ. Стрелча, обл. Пазарджик',
    '65807': 'с. Свобода, общ. Стрелча, обл. Пазарджик',
    '67516': 'с. Смилец, общ. Стрелча, обл. Пазарджик',
    '70648': 'гр. Сърница, общ. Сърница, обл. Пазарджик',
    '47559': 'с. Медени поляни, общ. Сърница, обл. Пазарджик',
    '59224': 'с. Побит камък, общ. Сърница, обл. Пазарджик',
    '06286': 'гр. Брезник, общ. Брезник, обл. Перник',
    '00610': 'с. Арзан, общ. Брезник, обл. Перник',
    '02066': 'с. Бабица, общ. Брезник, обл. Перник',
    '02614': 'с. Банище, общ. Брезник, обл. Перник',
    '03099': 'с. Бегуновци, общ. Брезник, обл. Перник',
    '04042': 'с. Билинци, общ. Брезник, обл. Перник',
    '06313': 'с. Брезнишки извор, общ. Брезник, обл. Перник',
    '06639': 'с. Брусник, общ. Брезник, обл. Перник',
    '10548': 'с. Велковци, общ. Брезник, обл. Перник',
    '11034': 'с. Видрица, общ. Брезник, обл. Перник',
    '14893': 'с. Гигинци, общ. Брезник, обл. Перник',
    '15331': 'с. Гоз, общ. Брезник, обл. Перник',
    '16382': 'с. Горна Секирна, общ. Брезник, обл. Перник',
    '16612': 'с. Горни Романци, общ. Брезник, обл. Перник',
    '18349': 'с. Гърло, общ. Брезник, обл. Перник',
    '22263': 'с. Долна Секирна, общ. Брезник, обл. Перник',
    '22506': 'с. Долни Романци, общ. Брезник, обл. Перник',
    '24205': 'с. Душинци, общ. Брезник, обл. Перник',
    '30048': 'с. Завала, общ. Брезник, обл. Перник',
    '41126': 'с. Конска, общ. Брезник, обл. Перник',
    '39150': 'с. Кошарево, общ. Брезник, обл. Перник',
    '39517': 'с. Красава, общ. Брезник, обл. Перник',
    '39855': 'с. Кривонос, общ. Брезник, обл. Перник',
    '49374': 'с. Муртинци, общ. Брезник, обл. Перник',
    '51490': 'с. Непразненци, общ. Брезник, обл. Перник',
    '52324': 'с. Ноевци, общ. Брезник, обл. Перник',
    '53463': 'с. Озърновци, общ. Брезник, обл. Перник',
    '62373': 'с. Ребро, общ. Брезник, обл. Перник',
    '17796': 'с. Режанци, общ. Брезник, обл. Перник',
    '63519': 'с. Ръжавец, общ. Брезник, обл. Перник',
    '65084': 'с. Садовик, общ. Брезник, обл. Перник',
    '67163': 'с. Слаковци, общ. Брезник, обл. Перник',
    '68059': 'с. Сопица, общ. Брезник, обл. Перник',
    '68833': 'с. Станьовци, общ. Брезник, обл. Перник',
    '87579': 'с. Ярославци, общ. Брезник, обл. Перник',
    '30778': 'гр. Земен, общ. Земен, обл. Перник',
    '03887': 'с. Беренде, общ. Земен, обл. Перник',
    '04340': 'с. Блатешница, общ. Земен, обл. Перник',
    '12228': 'с. Враня стена, общ. Земен, обл. Перник',
    '14146': 'с. Габровдол, общ. Земен, обл. Перник',
    '16167': 'с. Горна Врабча, общ. Земен, обл. Перник',
    '16170': 'с. Горна Глоговица, общ. Земен, обл. Перник',
    '20972': 'с. Дивля, общ. Земен, обл. Перник',
    '22054': 'с. Долна Врабча, общ. Земен, обл. Перник',
    '27320': 'с. Еловдол, общ. Земен, обл. Перник',
    '29012': 'с. Жабляно, общ. Земен, обл. Перник',
    '35482': 'с. Калотинци, общ. Земен, обл. Перник',
    '49357': 'с. Мурено, общ. Земен, обл. Перник',
    '53415': 'с. Одраница, общ. Земен, обл. Перник',
    '55141': 'с. Падине, общ. Земен, обл. Перник',
    '56280': 'с. Пещера, общ. Земен, обл. Перник',
    '62356': 'с. Раянци, общ. Земен, обл. Перник',
    '67595': 'с. Смиров дол, общ. Земен, обл. Перник',
    '27043': 'с. Егълница, общ. Ковачевци, обл. Перник',
    '35434': 'с. Калище, общ. Ковачевци, обл. Перник',
    '37513': 'с. Ковачевци, общ. Ковачевци, обл. Перник',
    '38724': 'с. Косача, общ. Ковачевци, обл. Перник',
    '43918': 'с. Лобош, общ. Ковачевци, обл. Перник',
    '61947': 'с. Ракиловци, общ. Ковачевци, обл. Перник',
    '65591': 'с. Светля, общ. Ковачевци, обл. Перник',
    '66648': 'с. Сирищник, общ. Ковачевци, обл. Перник',
    '67266': 'с. Слатино, общ. Ковачевци, обл. Перник',
    '80385': 'с. Чепино, общ. Ковачевци, обл. Перник',
    '72223': 'гр. Батановци, общ. Перник, обл. Перник',
    '55871': 'гр. Перник, общ. Перник, обл. Перник',
    '04618': 'с. Богданов дол, общ. Перник, обл. Перник',
    '05760': 'с. Боснек, общ. Перник, обл. Перник',
    '11209': 'с. Вискяр, общ. Перник, обл. Перник',
    '11288': 'с. Витановци, общ. Перник, обл. Перник',
    '15504': 'с. Големо Бучино, общ. Перник, обл. Перник',
    '20986': 'с. Дивотино, общ. Перник, обл. Перник',
    '23251': 'с. Драгичево, общ. Перник, обл. Перник',
    '30836': 'с. Зидарци, общ. Перник, обл. Перник',
    '37174': 'с. Кладница, общ. Перник, обл. Перник',
    '39387': 'с. Кралев дол, общ. Перник, обл. Перник',
    '43428': 'с. Лесковец, общ. Перник, обл. Перник',
    '44656': 'с. Люлин, общ. Перник, обл. Перник',
    '48037': 'с. Мещица, общ. Перник, обл. Перник',
    '56650': 'с. Планиница, общ. Перник, обл. Перник',
    '61618': 'с. Радуй, общ. Перник, обл. Перник',
    '62219': 'с. Расник, общ. Перник, обл. Перник',
    '63152': 'с. Рударци, общ. Перник, обл. Перник',
    '66072': 'с. Селищен дол, общ. Перник, обл. Перник',
    '70038': 'с. Студена, общ. Перник, обл. Перник',
    '81952': 'с. Черна гора, общ. Перник, обл. Перник',
    '81623': 'с. Чуйпетлово, общ. Перник, обл. Перник',
    '87480': 'с. Ярджиловци, общ. Перник, обл. Перник',
    '61577': 'гр. Радомир, общ. Радомир, обл. Перник',
    '02230': 'с. Байкалско, общ. Радомир, обл. Перник',
    '03277': 'с. Беланица, общ. Радомир, обл. Перник',
    '04529': 'с. Бобораци, общ. Радомир, обл. Перник',
    '05534': 'с. Борнарево, общ. Радомир, обл. Перник',
    '11404': 'с. Владимир, общ. Радомир, обл. Перник',
    '16208': 'с. Горна Диканя, общ. Радомир, обл. Перник',
    '18263': 'с. Гълъбник, общ. Радомир, обл. Перник',
    '20256': 'с. Дебели лаг, общ. Радомир, обл. Перник',
    '22085': 'с. Долна Диканя, общ. Радомир, обл. Перник',
    '22490': 'с. Долни Раковец, общ. Радомир, обл. Перник',
    '23443': 'с. Драгомирово, общ. Радомир, обл. Перник',
    '23649': 'с. Дрен, общ. Радомир, обл. Перник',
    '24832': 'с. Друган, общ. Радомир, обл. Перник',
    '29060': 'с. Жедна, общ. Радомир, обл. Перник',
    '29622': 'с. Житуша, общ. Радомир, обл. Перник',
    '32384': 'с. Извор, общ. Радомир, обл. Перник',
    '36566': 'с. Касилаг, общ. Радомир, обл. Перник',
    '41232': 'с. Кленовик, общ. Радомир, обл. Перник',
    '38265': 'с. Кондофрей, общ. Радомир, обл. Перник',
    '38460': 'с. Копаница, общ. Радомир, обл. Перник',
    '41251': 'с. Кошарите, общ. Радомир, обл. Перник',
    '51278': 'с. Негованци, общ. Радомир, обл. Перник',
    '51617': 'с. Николаево, общ. Радомир, обл. Перник',
    '57964': 'с. Поцърненци, общ. Радомир, обл. Перник',
    '58311': 'с. Прибой, общ. Радомир, обл. Перник',
    '61354': 'с. Радибош, общ. Радомир, обл. Перник',
    '69064': 'с. Старо село, общ. Радомир, обл. Перник',
    '69239': 'с. Стефаново, общ. Радомир, обл. Перник',
    '75023': 'с. Углярци, общ. Радомир, обл. Перник',
    '80488': 'с. Червена могила, общ. Радомир, обл. Перник',
    '81709': 'с. Чуковец, общ. Радомир, обл. Перник',
    '73273': 'гр. Трън, общ. Трън, обл. Перник',
    '02645': 'с. Банкя, общ. Трън, обл. Перник',
    '03873': 'с. Берайнци, общ. Трън, обл. Перник',
    '04697': 'с. Богойна, общ. Трън, обл. Перник',
    '05918': 'с. Бохова, общ. Трън, обл. Перник',
    '07096': 'с. Бусинци, общ. Трън, обл. Перник',
    '07137': 'с. Бутроинци, общ. Трън, обл. Перник',
    '10478': 'с. Велиново, общ. Трън, обл. Перник',
    '11017': 'с. Видрар, общ. Трън, обл. Перник',
    '12139': 'с. Врабча, общ. Трън, обл. Перник',
    '12348': 'с. Вукан, общ. Трън, обл. Перник',
    '14979': 'с. Главановци, общ. Трън, обл. Перник',
    '15134': 'с. Глоговица, общ. Трън, обл. Перник',
    '16331': 'с. Горна Мелна, общ. Трън, обл. Перник',
    '17077': 'с. Горочевци, общ. Трън, обл. Перник',
    '20818': 'с. Джинчовци, общ. Трън, обл. Перник',
    '21854': 'с. Докьовци, общ. Трън, обл. Перник',
    '22201': 'с. Долна Мелна, общ. Трън, обл. Перник',
    '24517': 'с. Дълга лука, общ. Трън, обл. Перник',
    '27084': 'с. Ездимирци, общ. Трън, обл. Перник',
    '27351': 'с. Еловица, общ. Трън, обл. Перник',
    '27601': 'с. Ерул, общ. Трън, обл. Перник',
    '30017': 'с. Забел, общ. Трън, обл. Перник',
    '30716': 'с. Зелениград, общ. Трън, обл. Перник',
    '37602': 'с. Кожинци, общ. Трън, обл. Перник',
    '39027': 'с. Костуринци, общ. Трън, обл. Перник',
    '41040': 'с. Къшле, общ. Трън, обл. Перник',
    '43195': 'с. Лева река, общ. Трън, обл. Перник',
    '43551': 'с. Лешниковци, общ. Трън, обл. Перник',
    '44269': 'с. Ломница, общ. Трън, обл. Перник',
    '44781': 'с. Лялинци, общ. Трън, обл. Перник',
    '48249': 'с. Милкьовци, общ. Трън, обл. Перник',
    '48252': 'с. Милославци, общ. Трън, обл. Перник',
    '49182': 'с. Мракетинци, общ. Трън, обл. Перник',
    '49196': 'с. Мрамор, общ. Трън, обл. Перник',
    '51130': 'с. Насалевци, общ. Трън, обл. Перник',
    '51343': 'с. Неделково, общ. Трън, обл. Перник',
    '55453': 'с. Парамун, общ. Трън, обл. Перник',
    '55814': 'с. Пенкьовци, общ. Трън, обл. Перник',
    '58551': 'с. Проданча, общ. Трън, обл. Перник',
    '61501': 'с. Радово, общ. Трън, обл. Перник',
    '62205': 'с. Рани луг, общ. Трън, обл. Перник',
    '62565': 'с. Реяновци, общ. Трън, обл. Перник',
    '67458': 'с. Слишовци, общ. Трън, обл. Перник',
    '68641': 'с. Стайчовци, общ. Трън, обл. Перник',
    '69777': 'с. Стрезимировци, общ. Трън, обл. Перник',
    '70086': 'с. Студен извор, общ. Трън, обл. Перник',
    '73479': 'с. Туроковци, общ. Трън, обл. Перник',
    '76114': 'с. Филиповци, общ. Трън, обл. Перник',
    '78327': 'с. Цегриловци, общ. Трън, обл. Перник',
    '83209': 'с. Шипковица, общ. Трън, обл. Перник',
    '87566': 'с. Ярловци, общ. Трън, обл. Перник',
    '03366': 'гр. Белене, общ. Белене, обл. Плевен',
    '07630': 'с. Бяла вода, общ. Белене, обл. Плевен',
    '20537': 'с. Деков, общ. Белене, обл. Плевен',
    '40573': 'с. Кулина вода, общ. Белене, обл. Плевен',
    '56085': 'с. Петокладенци, общ. Белене, обл. Плевен',
    '72117': 'с. Татари, общ. Белене, обл. Плевен',
    '18099': 'гр. Гулянци, общ. Гулянци, обл. Плевен',
    '06402': 'с. Брест, общ. Гулянци, обл. Плевен',
    '14876': 'с. Гиген, общ. Гулянци, обл. Плевен',
    '22335': 'с. Долни Вит, общ. Гулянци, обл. Плевен',
    '24308': 'с. Дъбован, общ. Гулянци, обл. Плевен',
    '30199': 'с. Загражден, общ. Гулянци, обл. Плевен',
    '14888': 'с. Искър, общ. Гулянци, обл. Плевен',
    '39712': 'с. Крета, общ. Гулянци, обл. Плевен',
    '43284': 'с. Ленково, общ. Гулянци, обл. Плевен',
    '48204': 'с. Милковица, общ. Гулянци, обл. Плевен',
    '68045': 'с. Сомовит, общ. Гулянци, обл. Плевен',
    '83394': 'с. Шияково, общ. Гулянци, обл. Плевен',
    '22215': 'гр. Долна Митрополия, общ. Долна Митрополия, обл. Плевен',
    '73359': 'гр. Тръстеник, общ. Долна Митрополия, обл. Плевен',
    '02227': 'с. Байкал, общ. Долна Митрополия, обл. Плевен',
    '03993': 'с. Биволаре, общ. Долна Митрополия, обл. Плевен',
    '05013': 'с. Божурица, общ. Долна Митрополия, обл. Плевен',
    '06210': 'с. Брегаре, общ. Долна Митрополия, обл. Плевен',
    '16345': 'с. Горна Митрополия, общ. Долна Митрополия, обл. Плевен',
    '17364': 'с. Гостиля, общ. Долна Митрополия, обл. Плевен',
    '38145': 'с. Комарево, общ. Долна Митрополия, обл. Плевен',
    '40195': 'с. Крушовене, общ. Долна Митрополия, обл. Плевен',
    '53655': 'с. Ореховица, общ. Долна Митрополия, обл. Плевен',
    '56865': 'с. Победа, общ. Долна Митрополия, обл. Плевен',
    '57025': 'с. Подем, общ. Долна Митрополия, обл. Плевен',
    '62596': 'с. Рибен, общ. Долна Митрополия, обл. Плевен',
    '67012': 'с. Славовица, общ. Долна Митрополия, обл. Плевен',
    '68607': 'с. Ставерци, общ. Долна Митрополия, обл. Плевен',
    '12601': 'с. Вълчитрън, общ. Пордим, обл. Плевен',
    '22407': 'гр. Долни Дъбник, общ. Долни Дъбник, обл. Плевен',
    '07524': 'с. Бъркач, общ. Долни Дъбник, обл. Плевен',
    '16537': 'с. Горни Дъбник, общ. Долни Дъбник, обл. Плевен',
    '17470': 'с. Градина, общ. Долни Дъбник, обл. Плевен',
    '40213': 'с. Крушовица, общ. Долни Дъбник, обл. Плевен',
    '56201': 'с. Петърница, общ. Долни Дъбник, обл. Плевен',
    '65070': 'с. Садовец, общ. Долни Дъбник, обл. Плевен',
    '55782': 'гр. Искър, общ. Искър, обл. Плевен',
    '22438': 'с. Долни Луковит, общ. Искър, обл. Плевен',
    '56493': 'с. Писарово, общ. Искър, обл. Плевен',
    '69095': 'с. Староселци, общ. Искър, обл. Плевен',
    '37376': 'гр. Кнежа, общ. Кнежа, обл. Плевен',
    '06375': 'с. Бреница, общ. Кнежа, обл. Плевен',
    '27509': 'с. Еница, общ. Кнежа, обл. Плевен',
    '43044': 'с. Лазарово, общ. Кнежа, обл. Плевен',
    '43236': 'гр. Левски, общ. Левски, обл. Плевен',
    '00761': 'с. Асеновци, общ. Левски, обл. Плевен',
    '00802': 'с. Аспарухово, общ. Левски, обл. Плевен',
    '05044': 'с. Божурлук, общ. Левски, обл. Плевен',
    '07274': 'с. Българене, общ. Левски, обл. Плевен',
    '10080': 'с. Варана, общ. Левски, обл. Плевен',
    '17556': 'с. Градище, общ. Левски, обл. Плевен',
    '32531': 'с. Изгрев, общ. Левски, обл. Плевен',
    '37647': 'с. Козар Белене, общ. Левски, обл. Плевен',
    '46841': 'с. Малчика, общ. Левски, обл. Плевен',
    '53089': 'с. Обнова, общ. Левски, обл. Плевен',
    '69153': 'с. Стежерово, общ. Левски, обл. Плевен',
    '73345': 'с. Трънчовица, общ. Левски, обл. Плевен',
    '51723': 'гр. Никопол, общ. Никопол, обл. Плевен',
    '00744': 'с. Асеново, общ. Никопол, обл. Плевен',
    '02957': 'с. Бацова махала, общ. Никопол, обл. Плевен',
    '12365': 'с. Въбел, общ. Никопол, обл. Плевен',
    '20314': 'с. Дебово, общ. Никопол, обл. Плевен',
    '23193': 'с. Драгаш войвода, общ. Никопол, обл. Плевен',
    '27019': 'с. Евлогиево, общ. Никопол, обл. Плевен',
    '29310': 'с. Жернов, общ. Никопол, обл. Плевен',
    '44152': 'с. Лозица, общ. Никопол, обл. Плевен',
    '44536': 'с. Любеново, общ. Никопол, обл. Плевен',
    '49415': 'с. Муселиево, общ. Никопол, обл. Плевен',
    '51932': 'с. Новачене, общ. Никопол, обл. Плевен',
    '65320': 'с. Санадиново, общ. Никопол, обл. Плевен',
    '80697': 'с. Черковица, общ. Никопол, обл. Плевен',
    '56722': 'гр. Плевен, общ. Плевен, обл. Плевен',
    '67088': 'гр. Славяново, общ. Плевен, обл. Плевен',
    '03068': 'с. Беглеж, общ. Плевен, обл. Плевен',
    '05921': 'с. Бохот, общ. Плевен, обл. Плевен',
    '06495': 'с. Брестовец, общ. Плевен, обл. Плевен',
    '06690': 'с. Бръшляница, общ. Плевен, обл. Плевен',
    '06999': 'с. Буковлък, общ. Плевен, обл. Плевен',
    '12752': 'с. Върбица, общ. Плевен, обл. Плевен',
    '17258': 'с. Горталово, общ. Плевен, обл. Плевен',
    '17854': 'с. Гривица, общ. Плевен, обл. Плевен',
    '24935': 'с. Дисевица, общ. Плевен, обл. Плевен',
    '37856': 'с. Коиловци, общ. Плевен, обл. Плевен',
    '40974': 'с. Къртожабене, общ. Плевен, обл. Плевен',
    '41037': 'с. Къшин, общ. Плевен, обл. Плевен',
    '43147': 'с. Ласкар, общ. Плевен, обл. Плевен',
    '47963': 'с. Мечка, общ. Плевен, обл. Плевен',
    '51620': 'с. Николаево, общ. Плевен, обл. Плевен',
    '53583': 'с. Опанец, общ. Плевен, обл. Плевен',
    '55765': 'с. Пелишат, общ. Плевен, обл. Плевен',
    '61426': 'с. Радишево, общ. Плевен, обл. Плевен',
    '62116': 'с. Ралево, общ. Плевен, обл. Плевен',
    '72566': 'с. Тодорово, общ. Плевен, обл. Плевен',
    '73523': 'с. Тученица, общ. Плевен, обл. Плевен',
    '73674': 'с. Търнене, общ. Плевен, обл. Плевен',
    '87597': 'с. Ясен, общ. Плевен, обл. Плевен',
    '57772': 'гр. Пордим, общ. Пордим, обл. Плевен',
    '05493': 'с. Борислав, общ. Пордим, обл. Плевен',
    '30590': 'с. Згалево, общ. Пордим, обл. Плевен',
    '35780': 'с. Каменец, общ. Пордим, обл. Плевен',
    '36614': 'с. Катерица, общ. Пордим, обл. Плевен',
    '53446': 'с. Одърне, общ. Пордим, обл. Плевен',
    '72881': 'с. Тотлебен, общ. Пордим, обл. Плевен',
    '37863': 'гр. Койнаре, общ. Червен бряг, обл. Плевен',
    '80501': 'гр. Червен бряг, общ. Червен бряг, обл. Плевен',
    '06433': 'с. Бресте, общ. Червен бряг, обл. Плевен',
    '14934': 'с. Глава, общ. Червен бряг, обл. Плевен',
    '16540': 'с. Горник, общ. Червен бряг, обл. Плевен',
    '20383': 'с. Девенци, общ. Червен бряг, обл. Плевен',
    '43311': 'с. Лепица, общ. Червен бряг, обл. Плевен',
    '61580': 'с. Радомирци, общ. Червен бряг, обл. Плевен',
    '61950': 'с. Ракита, общ. Червен бряг, обл. Плевен',
    '62503': 'с. Реселец, общ. Червен бряг, обл. Плевен',
    '63361': 'с. Рупци, общ. Червен бряг, обл. Плевен',
    '70281': 'с. Сухаче, общ. Червен бряг, обл. Плевен',
    '72206': 'с. Телиш, общ. Червен бряг, обл. Плевен',
    '81551': 'с. Чомаковци, общ. Червен бряг, обл. Плевен',
    '00702': 'гр. Асеновград, общ. Асеновград, обл. Пловдив',
    '02974': 'с. Бачково, общ. Асеновград, обл. Пловдив',
    '07870': 'с. Бор, общ. Асеновград, обл. Пловдив',
    '06029': 'с. Боянци, общ. Асеновград, обл. Пловдив',
    '12992': 'с. Врата, общ. Асеновград, обл. Пловдив',
    '16955': 'с. Горнослав, общ. Асеновград, обл. Пловдив',
    '21676': 'с. Добростан, общ. Асеновград, обл. Пловдив',
    '22839': 'с. Долнослав, общ. Асеновград, обл. Пловдив',
    '29581': 'с. Жълт камък, общ. Асеновград, обл. Пловдив',
    '31108': 'с. Златовръх, общ. Асеновград, обл. Пловдив',
    '32341': 'с. Избеглии, общ. Асеновград, обл. Пловдив',
    '37633': 'с. Козаново, общ. Асеновград, обл. Пловдив',
    '38385': 'с. Конуш, общ. Асеновград, обл. Пловдив',
    '38799': 'с. Косово, общ. Асеновград, обл. Пловдив',
    '43298': 'с. Леново, общ. Асеновград, обл. Пловдив',
    '44834': 'с. Лясково, общ. Асеновград, обл. Пловдив',
    '49151': 'с. Мостово, общ. Асеновград, обл. Пловдив',
    '49309': 'с. Мулдава, общ. Асеновград, обл. Пловдив',
    '51127': 'с. Нареченски бани, общ. Асеновград, обл. Пловдив',
    '51830': 'с. Новаково, общ. Асеновград, обл. Пловдив',
    '32398': 'с. Нови извор, общ. Асеновград, обл. Пловдив',
    '53761': 'с. Орешец, общ. Асеновград, обл. Пловдив',
    '55590': 'с. Патриарх Евтимово, общ. Асеновград, обл. Пловдив',
    '70737': 'с. Сини връх, общ. Асеновград, обл. Пловдив',
    '69273': 'с. Стоево, общ. Асеновград, обл. Пловдив',
    '72789': 'с. Тополово, общ. Асеновград, обл. Пловдив',
    '73147': 'с. Три могили, общ. Асеновград, обл. Пловдив',
    '75254': 'с. Узуново, общ. Асеновград, обл. Пловдив',
    '80437': 'с. Червен, общ. Асеновград, обл. Пловдив',
    '06361': 'гр. Брезово, общ. Брезово, обл. Пловдив',
    '02035': 'с. Бабек, общ. Брезово, обл. Пловдив',
    '05356': 'с. Борец, общ. Брезово, обл. Пловдив',
    '12680': 'с. Върбен, общ. Брезово, обл. Пловдив',
    '23580': 'с. Дрангово, общ. Брезово, обл. Пловдив',
    '30750': 'с. Зелениково, общ. Брезово, обл. Пловдив',
    '31160': 'с. Златосел, общ. Брезово, обл. Пловдив',
    '54451': 'с. Отец Кирилово, общ. Брезово, обл. Пловдив',
    '58983': 'с. Пъдарско, общ. Брезово, обл. Пловдив',
    '62966': 'с. Розовец, общ. Брезово, обл. Пловдив',
    '65526': 'с. Свежен, общ. Брезово, обл. Пловдив',
    '69818': 'с. Стрелци, общ. Брезово, обл. Пловдив',
    '70593': 'с. Сърнегор, общ. Брезово, обл. Пловдив',
    '73794': 'с. Тюркмен, общ. Брезово, обл. Пловдив',
    '81308': 'с. Чехларе, общ. Брезово, обл. Пловдив',
    '81975': 'с. Чоба, общ. Брезово, обл. Пловдив',
    '03085': 'с. Бегово, общ. Калояново, обл. Пловдив',
    '14996': 'с. Главатар, общ. Калояново, обл. Пловдив',
    '16328': 'с. Горна махала, общ. Калояново, обл. Пловдив',
    '22191': 'с. Долна махала, общ. Калояново, обл. Пловдив',
    '24000': 'с. Дуванлии, общ. Калояново, обл. Пловдив',
    '24582': 'с. Дълго поле, общ. Калояново, обл. Пловдив',
    '29475': 'с. Житница, общ. Калояново, обл. Пловдив',
    '32038': 'с. Иван Вазово, общ. Калояново, обл. Пловдив',
    '35523': 'с. Калояново, общ. Калояново, обл. Пловдив',
    '54465': 'с. Отец Паисиево, общ. Калояново, обл. Пловдив',
    '55926': 'с. Песнопой, общ. Калояново, обл. Пловдив',
    '63553': 'с. Ръжево, общ. Калояново, обл. Пловдив',
    '63567': 'с. Ръжево Конаре, общ. Калояново, обл. Пловдив',
    '70353': 'с. Сухозем, общ. Калояново, обл. Пловдив',
    '81133': 'с. Черноземен, общ. Калояново, обл. Пловдив',
    '02720': 'гр. Баня, общ. Карлово, обл. Пловдив',
    '35496': 'гр. Калофер, общ. Карлово, обл. Пловдив',
    '36498': 'гр. Карлово, общ. Карлово, обл. Пловдив',
    '37277': 'гр. Клисура, общ. Карлово, обл. Пловдив',
    '03109': 'с. Бегунци, общ. Карлово, обл. Пловдив',
    '04563': 'с. Богдан, общ. Карлово, обл. Пловдив',
    '10207': 'с. Васил Левски, общ. Карлово, обл. Пловдив',
    '10291': 'с. Ведраре, общ. Карлово, обл. Пловдив',
    '12005': 'с. Войнягово, общ. Карлово, обл. Пловдив',
    '16513': 'с. Горни Домлян, общ. Карлово, обл. Пловдив',
    '22931': 'с. Домлян, общ. Карлово, обл. Пловдив',
    '24241': 'с. Дъбене, общ. Карлово, обл. Пловдив',
    '32226': 'с. Иганово, общ. Карлово, обл. Пловдив',
    '36186': 'с. Каравелово, общ. Карлово, обл. Пловдив',
    '37229': 'с. Климент, общ. Карлово, обл. Пловдив',
    '40703': 'с. Куртово, общ. Карлово, обл. Пловдив',
    '40939': 'с. Кърнаре, общ. Карлово, обл. Пловдив',
    '47250': 'с. Марино поле, общ. Карлово, обл. Пловдив',
    '49117': 'с. Московец, общ. Карлово, обл. Пловдив',
    '49240': 'с. Мраченик, общ. Карлово, обл. Пловдив',
    '55676': 'с. Певците, общ. Карлово, обл. Пловдив',
    '58616': 'с. Пролом, общ. Карлово, обл. Пловдив',
    '62949': 'с. Розино, общ. Карлово, обл. Пловдив',
    '67235': 'с. Слатина, общ. Карлово, обл. Пловдив',
    '67903': 'с. Соколица, общ. Карлово, обл. Пловдив',
    '69420': 'с. Столетово, общ. Карлово, обл. Пловдив',
    '77462': 'с. Христо Даново, общ. Карлово, обл. Пловдив',
    '39921': 'гр. Кричим, общ. Кричим, обл. Пловдив',
    '40467': 'гр. Куклен, общ. Куклен, обл. Пловдив',
    '18277': 'с. Гълъбово, общ. Куклен, обл. Пловдив',
    '21324': 'с. Добралък, общ. Куклен, обл. Пловдив',
    '63238': 'с. Руен, общ. Куклен, обл. Пловдив',
    '78207': 'с. Цар Калоян, общ. Куклен, обл. Пловдив',
    '87179': 'с. Яврово, общ. Куклен, обл. Пловдив',
    '44478': 'гр. Лъки, общ. Лъки, обл. Пловдив',
    '02453': 'с. Балкан махала, общ. Лъки, обл. Пловдив',
    '03530': 'с. Белица, общ. Лъки, обл. Пловдив',
    '05623': 'с. Борово, общ. Лъки, обл. Пловдив',
    '20883': 'с. Джурково, общ. Лъки, обл. Пловдив',
    '23950': 'с. Дряново, общ. Лъки, обл. Пловдив',
    '31423': 'с. Здравец, общ. Лъки, обл. Пловдив',
    '40244': 'с. Крушово, общ. Лъки, обл. Пловдив',
    '44447': 'с. Лъкавица, общ. Лъки, обл. Пловдив',
    '46989': 'с. Манастир, общ. Лъки, обл. Пловдив',
    '86012': 'с. Югово, общ. Лъки, обл. Пловдив',
    '03839': 'с. Бенковски, общ. Марица, обл. Пловдив',
    '11845': 'с. Войводиново, общ. Марица, обл. Пловдив',
    '12019': 'с. Войсил, общ. Марица, обл. Пловдив',
    '17806': 'с. Граф Игнатиево, общ. Марица, обл. Пловдив',
    '21169': 'с. Динк, общ. Марица, обл. Пловдив',
    '29235': 'с. Желязно, общ. Марица, обл. Пловдив',
    '35300': 'с. Калековец, общ. Марица, обл. Пловдив',
    '38950': 'с. Костиево, общ. Марица, обл. Пловдив',
    '39918': 'с. Крислово, общ. Марица, обл. Пловдив',
    '47086': 'с. Маноле, общ. Марица, обл. Пловдив',
    '47113': 'с. Манолско Конаре, общ. Марица, обл. Пловдив',
    '61412': 'с. Радиново, общ. Марица, обл. Пловдив',
    '62858': 'с. Рогош, общ. Марица, обл. Пловдив',
    '66915': 'с. Скутаре, общ. Марица, обл. Пловдив',
    '69874': 'с. Строево, общ. Марица, обл. Пловдив',
    '73122': 'с. Трилистник, общ. Марица, обл. Пловдив',
    '73242': 'с. Труд, общ. Марица, обл. Пловдив',
    '78080': 'с. Царацово, общ. Марица, обл. Пловдив',
    '87669': 'с. Ясно поле, общ. Марица, обл. Пловдив',
    '55909': 'гр. Перущица, общ. Перущица, обл. Пловдив',
    '56784': 'гр. Пловдив, общ. Пловдив, обл. Пловдив',
    '59080': 'гр. Първомай, общ. Първомай, обл. Пловдив',
    '06745': 'с. Брягово, общ. Първомай, обл. Пловдив',
    '56335': 'с. Буково, общ. Първомай, обл. Пловдив',
    '07675': 'с. Бяла река, общ. Първомай, обл. Пловдив',
    '11123': 'с. Виница, общ. Първомай, обл. Пловдив',
    '11627': 'с. Воден, общ. Първомай, обл. Пловдив',
    '17484': 'с. Градина, общ. Първомай, обл. Пловдив',
    '21440': 'с. Добри дол, общ. Първомай, обл. Пловдив',
    '23385': 'с. Драгойново, общ. Първомай, обл. Пловдив',
    '24493': 'с. Дълбок извор, общ. Първомай, обл. Пловдив',
    '27139': 'с. Езерово, общ. Първомай, обл. Пловдив',
    '32826': 'с. Искра, общ. Първомай, обл. Пловдив',
    '36230': 'с. Караджалово, общ. Първомай, обл. Пловдив',
    '40155': 'с. Крушево, общ. Първомай, обл. Пловдив',
    '57806': 'с. Поройна, общ. Първомай, обл. Пловдив',
    '58089': 'с. Православен, общ. Първомай, обл. Пловдив',
    '72093': 'с. Татарево, общ. Първомай, обл. Пловдив',
    '62075': 'гр. Раковски, общ. Раковски, обл. Пловдив',
    '03620': 'с. Белозем, общ. Раковски, обл. Пловдив',
    '05270': 'с. Болярино, общ. Раковски, обл. Пловдив',
    '48948': 'с. Момино село, общ. Раковски, обл. Пловдив',
    '70010': 'с. Стряма, общ. Раковски, обл. Пловдив',
    '80162': 'с. Чалъкови, общ. Раковски, обл. Пловдив',
    '83380': 'с. Шишманци, общ. Раковски, обл. Пловдив',
    '03304': 'с. Белащица, общ. Родопи, обл. Пловдив',
    '05150': 'с. Бойково, общ. Родопи, обл. Пловдив',
    '06077': 'с. Браниполе, общ. Родопи, обл. Пловдив',
    '06447': 'с. Брестник, общ. Родопи, обл. Пловдив',
    '06505': 'с. Брестовица, общ. Родопи, обл. Пловдив',
    '20523': 'с. Дедово, общ. Родопи, обл. Пловдив',
    '31036': 'с. Златитрап, общ. Родопи, обл. Пловдив',
    '32408': 'с. Извор, общ. Родопи, обл. Пловдив',
    '35095': 'с. Кадиево, общ. Родопи, обл. Пловдив',
    '40004': 'с. Крумово, общ. Родопи, обл. Пловдив',
    '43671': 'с. Лилково, общ. Родопи, обл. Пловдив',
    '47295': 'с. Марково, общ. Родопи, обл. Пловдив',
    '53833': 'с. Оризари, общ. Родопи, обл. Пловдив',
    '59032': 'с. Първенец, общ. Родопи, обл. Пловдив',
    '66651': 'с. Ситово, общ. Родопи, обл. Пловдив',
    '66809': 'с. Скобелево, общ. Родопи, обл. Пловдив',
    '75188': 'с. Устина, общ. Родопи, обл. Пловдив',
    '77373': 'с. Храбрино, общ. Родопи, обл. Пловдив',
    '78029': 'с. Цалапица, общ. Родопи, обл. Пловдив',
    '81774': 'с. Чурен, общ. Родопи, обл. Пловдив',
    '87240': 'с. Ягодово, общ. Родопи, обл. Пловдив',
    '65139': 'гр. Садово, общ. Садово, обл. Пловдив',
    '00847': 'с. Ахматово, общ. Садово, обл. Пловдив',
    '04594': 'с. Богданица, общ. Садово, обл. Пловдив',
    '05339': 'с. Болярци, общ. Садово, обл. Пловдив',
    '36244': 'с. Караджово, общ. Садово, обл. Пловдив',
    '36676': 'с. Катуница, общ. Садово, обл. Пловдив',
    '39102': 'с. Кочево, общ. Садово, обл. Пловдив',
    '48152': 'с. Милево, общ. Садово, обл. Пловдив',
    '48965': 'с. Моминско, общ. Садово, обл. Пловдив',
    '57621': 'с. Поповица, общ. Садово, обл. Пловдив',
    '66127': 'с. Селци, общ. Садово, обл. Пловдив',
    '81342': 'с. Чешнегирово, общ. Садово, обл. Пловдив',
    '68080': 'гр. Сопот, общ. Сопот, обл. Пловдив',
    '00480': 'с. Анево, общ. Сопот, обл. Пловдив',
    '51980': 'гр. Стамболийски, общ. Стамболийски, обл. Пловдив',
    '34028': 'с. Йоаким Груево, общ. Стамболийски, обл. Пловдив',
    '40717': 'с. Куртово Конаре, общ. Стамболийски, обл. Пловдив',
    '52221': 'с. Ново село, общ. Стамболийски, обл. Пловдив',
    '73081': 'с. Триводици, общ. Стамболийски, обл. Пловдив',
    '70528': 'гр. Съединение, общ. Съединение, обл. Пловдив',
    '15970': 'с. Голям чардак, общ. Съединение, обл. Пловдив',
    '23426': 'с. Драгомир, общ. Съединение, обл. Пловдив',
    '44481': 'с. Любен, общ. Съединение, обл. Пловдив',
    '46944': 'с. Малък чардак, общ. Съединение, обл. Пловдив',
    '51069': 'с. Найден Герово, общ. Съединение, обл. Пловдив',
    '51305': 'с. Неделево, общ. Съединение, обл. Пловдив',
    '58058': 'с. Правище, общ. Съединение, обл. Пловдив',
    '78171': 'с. Царимир, общ. Съединение, обл. Пловдив',
    '78392': 'с. Церетелево, общ. Съединение, обл. Пловдив',
    '77270': 'гр. Хисаря, общ. Хисаря, обл. Пловдив',
    '03589': 'с. Беловица, общ. Хисаря, обл. Пловдив',
    '39579': 'с. Красново, общ. Хисаря, обл. Пловдив',
    '40333': 'с. Кръстевич, общ. Хисаря, обл. Пловдив',
    '46752': 'с. Мало Крушево, общ. Хисаря, обл. Пловдив',
    '48564': 'с. Михилци, общ. Хисаря, обл. Пловдив',
    '49624': 'с. Мътеница, общ. Хисаря, обл. Пловдив',
    '52088': 'с. Ново Железаре, общ. Хисаря, обл. Пловдив',
    '55378': 'с. Паничери, общ. Хисаря, обл. Пловдив',
    '68967': 'с. Старо Железаре, общ. Хисаря, обл. Пловдив',
    '69016': 'с. Старосел, общ. Хисаря, обл. Пловдив',
    '81030': 'с. Черничево, общ. Хисаря, обл. Пловдив',
    '30065': 'гр. Завет, общ. Завет, обл. Разград',
    '06481': 'с. Брестовене, общ. Завет, обл. Разград',
    '10714': 'с. Веселец, общ. Завет, обл. Разград',
    '32192': 'с. Иван Шишманово, общ. Завет, обл. Разград',
    '54417': 'с. Острово, общ. Завет, обл. Разград',
    '58147': 'с. Прелез, общ. Завет, обл. Разград',
    '70384': 'с. Сушево, общ. Завет, обл. Разград',
    '32874': 'гр. Исперих, общ. Исперих, обл. Разград',
    '03472': 'с. Белинци, общ. Исперих, обл. Разград',
    '07452': 'с. Бърдоква, общ. Исперих, обл. Разград',
    '10015': 'с. Вазово, общ. Исперих, обл. Разград',
    '15953': 'с. Голям Поровец, общ. Исперих, обл. Разград',
    '20609': 'с. Делчево, общ. Исперих, обл. Разград',
    '24921': 'с. Драгомъж, общ. Исперих, обл. Разград',
    '24150': 'с. Духовец, общ. Исперих, обл. Разград',
    '34103': 'с. Йонково, общ. Исперих, обл. Разград',
    '37010': 'с. Китанчево, общ. Исперих, обл. Разград',
    '38296': 'с. Конево, общ. Исперих, обл. Разград',
    '40899': 'с. Къпиновци, общ. Исперих, обл. Разград',
    '24116': 'с. Лудогорци, общ. Исперих, обл. Разград',
    '44358': 'с. Лъвино, общ. Исперих, обл. Разград',
    '50512': 'с. Малко Йонково, общ. Исперих, обл. Разград',
    '46913': 'с. Малък Поровец, общ. Исперих, обл. Разград',
    '56232': 'с. Печеница, общ. Исперих, обл. Разград',
    '56945': 'с. Подайва, общ. Исперих, обл. Разград',
    '61875': 'с. Райнино, общ. Исперих, обл. Разград',
    '65650': 'с. Свещари, общ. Исперих, обл. Разград',
    '68552': 'с. Средоселци, общ. Исперих, обл. Разград',
    '69047': 'с. Старо селище, общ. Исперих, обл. Разград',
    '72578': 'с. Тодорово, общ. Исперих, обл. Разград',
    '87285': 'с. Яким Груево, общ. Исперих, обл. Разград',
    '40422': 'гр. Кубрат, общ. Кубрат, обл. Разград',
    '03575': 'с. Беловец, общ. Кубрат, обл. Разград',
    '04131': 'с. Бисерци, общ. Кубрат, обл. Разград',
    '05058': 'с. Божурово, общ. Кубрат, обл. Разград',
    '16105': 'с. Горичево, общ. Кубрат, обл. Разград',
    '30212': 'с. Задруга, общ. Кубрат, обл. Разград',
    '30541': 'с. Звънарци, общ. Кубрат, обл. Разград',
    '35897': 'с. Каменово, общ. Кубрат, обл. Разград',
    '47617': 'с. Медовене, общ. Кубрат, обл. Разград',
    '49518': 'с. Мъдрево, общ. Кубрат, обл. Разград',
    '61203': 'с. Равно, общ. Кубрат, обл. Разград',
    '65022': 'с. Савин, общ. Кубрат, обл. Разград',
    '65886': 'с. Севар, общ. Кубрат, обл. Разград',
    '66295': 'с. Сеслав, общ. Кубрат, обл. Разград',
    '72337': 'с. Тертер, общ. Кубрат, обл. Разград',
    '72895': 'с. Точилари, общ. Кубрат, обл. Разград',
    '86091': 'с. Юпер, общ. Кубрат, обл. Разград',
    '44166': 'гр. Лозница, общ. Лозница, обл. Разград',
    '24092': 'с. Бели Лом, общ. Лозница, обл. Разград',
    '10759': 'с. Веселина, общ. Лозница, обл. Разград',
    '17066': 'с. Гороцвет, общ. Лозница, обл. Разград',
    '17498': 'с. Градина, общ. Лозница, обл. Разград',
    '35715': 'с. Каменар, общ. Лозница, обл. Разград',
    '39949': 'с. Крояч, общ. Лозница, обл. Разград',
    '44937': 'с. Ловско, общ. Лозница, обл. Разград',
    '47038': 'с. Манастирско, общ. Лозница, обл. Разград',
    '47041': 'с. Манастирци, общ. Лозница, обл. Разград',
    '65992': 'с. Сейдол, общ. Лозница, обл. Разград',
    '66593': 'с. Синя вода, общ. Лозница, обл. Разград',
    '70069': 'с. Студенец, общ. Лозница, обл. Разград',
    '73002': 'с. Трапище, общ. Лозница, обл. Разград',
    '73262': 'с. Тръбач, общ. Лозница, обл. Разград',
    '81619': 'с. Чудомир, общ. Лозница, обл. Разград',
    '61710': 'гр. Разград, общ. Разград, обл. Разград',
    '02467': 'с. Балкански, общ. Разград, обл. Разград',
    '04296': 'с. Благоево, общ. Разград, обл. Разград',
    '18589': 'с. Гецово, общ. Разград, обл. Разград',
    '23902': 'с. Дряновец, общ. Разград, обл. Разград',
    '24829': 'с. Дянково, общ. Разград, обл. Разград',
    '37109': 'с. Киченица, общ. Разград, обл. Разград',
    '43760': 'с. Липник, общ. Разград, обл. Разград',
    '49093': 'с. Мортагоново, общ. Разград, обл. Разград',
    '51353': 'с. Недоклан, общ. Разград, обл. Разград',
    '54105': 'с. Осенец, общ. Разград, обл. Разград',
    '54420': 'с. Островче, общ. Разград, обл. Разград',
    '56890': 'с. Побит камък, общ. Разград, обл. Разград',
    '59416': 'с. Пороище, общ. Разград, обл. Разград',
    '58668': 'с. Просторно, общ. Разград, обл. Разград',
    '61385': 'с. Радинград, общ. Разград, обл. Разград',
    '62089': 'с. Раковски, общ. Разград, обл. Разград',
    '70860': 'с. Стражец, общ. Разград, обл. Разград',
    '72850': 'с. Топчии, общ. Разград, обл. Разград',
    '75246': 'с. Ушинци, общ. Разград, обл. Разград',
    '80724': 'с. Черковна, общ. Разград, обл. Разград',
    '87624': 'с. Ясеновец, общ. Разград, обл. Разград',
    '04666': 'с. Богданци, общ. Самуил, обл. Разград',
    '04741': 'с. Богомилци, общ. Самуил, обл. Разград',
    '11449': 'с. Владимировци, общ. Самуил, обл. Разград',
    '15761': 'с. Голям извор, общ. Самуил, обл. Разград',
    '15669': 'с. Голяма вода, общ. Самуил, обл. Разград',
    '29218': 'с. Желязковец, общ. Самуил, обл. Разград',
    '30630': 'с. Здравец, общ. Самуил, обл. Разград',
    '36333': 'с. Кара Михал, общ. Самуил, обл. Разград',
    '39815': 'с. Кривица, общ. Самуил, обл. Разград',
    '52341': 'с. Ножарово, общ. Самуил, обл. Разград',
    '58877': 'с. Пчелина, общ. Самуил, обл. Разград',
    '65276': 'с. Самуил, общ. Самуил, обл. Разград',
    '77517': 'с. Хума, общ. Самуил, обл. Разград',
    '77579': 'с. Хърсово, общ. Самуил, обл. Разград',
    '77308': 'гр. Цар Калоян, общ. Цар Калоян, обл. Разград',
    '27156': 'с. Езерче, общ. Цар Калоян, обл. Разград',
    '38830': 'с. Костанденец, общ. Цар Калоян, обл. Разград',
    '05611': 'гр. Борово, общ. Борово, обл. Русе',
    '02854': 'с. Батин, общ. Борово, обл. Русе',
    '06519': 'с. Брестовица, общ. Борово, обл. Русе',
    '12067': 'с. Волово, общ. Борово, обл. Русе',
    '16674': 'с. Горно Абланово, общ. Борово, обл. Русе',
    '27173': 'с. Екзарх Йосиф, общ. Борово, обл. Русе',
    '53117': 'с. Обретеник, общ. Борово, обл. Русе',
    '07603': 'гр. Бяла, общ. Бяла, обл. Русе',
    '04162': 'с. Бистренци, общ. Бяла, обл. Русе',
    '05743': 'с. Босилковци, общ. Бяла, обл. Русе',
    '05877': 'с. Ботров, общ. Бяла, обл. Русе',
    '23916': 'с. Дряновец, общ. Бяла, обл. Русе',
    '38529': 'с. Копривец, общ. Бяла, обл. Русе',
    '05133': 'с. Лом Черковна, общ. Бяла, обл. Русе',
    '55720': 'с. Пейчиново, общ. Бяла, обл. Русе',
    '55988': 'с. Пет кладенци, общ. Бяла, обл. Русе',
    '57368': 'с. Полско Косово, общ. Бяла, обл. Русе',
    '70130': 'с. Стърмен, общ. Бяла, обл. Русе',
    '10803': 'гр. Ветово, общ. Ветово, обл. Русе',
    '15151': 'гр. Глоджево, общ. Ветово, обл. Русе',
    '66229': 'гр. Сеново, общ. Ветово, обл. Русе',
    '39832': 'с. Кривня, общ. Ветово, обл. Русе',
    '56441': 'с. Писанец, общ. Ветово, обл. Русе',
    '67578': 'с. Смирненски, общ. Ветово, обл. Русе',
    '20184': 'гр. Две могили, общ. Две могили, обл. Русе',
    '02587': 'с. Баниска, общ. Две могили, обл. Русе',
    '02868': 'с. Батишница, общ. Две могили, обл. Русе',
    '07212': 'с. Бъзовец, общ. Две могили, обл. Русе',
    '36364': 'с. Каран Върбовка, общ. Две могили, обл. Русе',
    '36703': 'с. Кацелово, общ. Две могили, обл. Русе',
    '30380': 'с. Могилино, общ. Две могили, обл. Русе',
    '54362': 'с. Острица, общ. Две могили, обл. Русе',
    '55837': 'с. Пепелина, общ. Две могили, обл. Русе',
    '57488': 'с. Помен, общ. Две могили, обл. Русе',
    '81373': 'с. Чилнов, общ. Две могили, обл. Русе',
    '83301': 'с. Широково, общ. Две могили, обл. Русе',
    '04981': 'с. Божичен, общ. Иваново, обл. Русе',
    '32095': 'с. Иваново, общ. Иваново, обл. Русе',
    '39205': 'с. Кошов, общ. Иваново, обл. Русе',
    '39520': 'с. Красен, общ. Иваново, обл. Русе',
    '47977': 'с. Мечка, общ. Иваново, обл. Русе',
    '51768': 'с. Нисово, общ. Иваново, обл. Русе',
    '56397': 'с. Пиргово, общ. Иваново, обл. Русе',
    '65509': 'с. Сваленик, общ. Иваново, обл. Русе',
    '72028': 'с. Табачка, общ. Иваново, обл. Русе',
    '73362': 'с. Тръстеник, общ. Иваново, обл. Русе',
    '78433': 'с. Церовец, общ. Иваново, обл. Русе',
    '80443': 'с. Червен, общ. Иваново, обл. Русе',
    '84049': 'с. Щръклево, общ. Иваново, обл. Русе',
    '47336': 'гр. Мартен, общ. Русе, обл. Русе',
    '63427': 'гр. Русе, общ. Русе, обл. Русе',
    '02796': 'с. Басарбово, общ. Русе, обл. Русе',
    '07231': 'с. Бъзън, общ. Русе, обл. Русе',
    '22558': 'с. Долно Абланово, общ. Русе, обл. Русе',
    '51679': 'с. Николово, общ. Русе, обл. Русе',
    '52235': 'с. Ново село, общ. Русе, обл. Русе',
    '58637': 'с. Просена, общ. Русе, обл. Русе',
    '65348': 'с. Сандрово, общ. Русе, обл. Русе',
    '66158': 'с. Семерджиево, общ. Русе, обл. Русе',
    '72357': 'с. Тетово, общ. Русе, обл. Русе',
    '77342': 'с. Хотанца, общ. Русе, обл. Русе',
    '80460': 'с. Червена вода, общ. Русе, обл. Русе',
    '87700': 'с. Ястребово, общ. Русе, обл. Русе',
    '67444': 'гр. Сливо поле, общ. Сливо поле, обл. Русе',
    '02083': 'с. Бабово, общ. Сливо поле, обл. Русе',
    '05517': 'с. Борисово, общ. Сливо поле, обл. Русе',
    '06673': 'с. Бръшлен, общ. Сливо поле, обл. Русе',
    '15829': 'с. Голямо Враново, общ. Сливо поле, обл. Русе',
    '39178': 'с. Кошарна, общ. Сливо поле, обл. Русе',
    '46560': 'с. Малко Враново, общ. Сливо поле, обл. Русе',
    '63668': 'с. Ряхово, общ. Сливо поле, обл. Русе',
    '68713': 'с. Стамболово, общ. Сливо поле, обл. Русе',
    '80652': 'с. Черешово, общ. Сливо поле, обл. Русе',
    '86026': 'с. Юделник, общ. Сливо поле, обл. Русе',
    '03745': 'с. Белцов, общ. Ценово, обл. Русе',
    '03808': 'с. Беляново, общ. Ценово, обл. Русе',
    '20849': 'с. Джулюница, общ. Ценово, обл. Русе',
    '22277': 'с. Долна Студена, общ. Ценово, обл. Русе',
    '36316': 'с. Караманово, общ. Ценово, обл. Русе',
    '39788': 'с. Кривина, общ. Ценово, обл. Русе',
    '51977': 'с. Новград, общ. Ценово, обл. Русе',
    '56366': 'с. Пиперково, общ. Ценово, обл. Русе',
    '78361': 'с. Ценово, общ. Ценово, обл. Русе',
    '00415': 'гр. Алфатар, общ. Алфатар, обл. Силистра',
    '00240': 'с. Алеково, общ. Алфатар, обл. Силистра',
    '04145': 'с. Бистра, общ. Алфатар, обл. Силистра',
    '10210': 'с. Васил Левски, общ. Алфатар, обл. Силистра',
    '40751': 'с. Кутловица, общ. Алфатар, обл. Силистра',
    '78063': 'с. Цар Асен, общ. Алфатар, обл. Силистра',
    '81712': 'с. Чуковец, общ. Алфатар, обл. Силистра',
    '15031': 'гр. Главиница, общ. Главиница, обл. Силистра',
    '03017': 'с. Бащино, общ. Главиница, обл. Силистра',
    '04670': 'с. Богданци, общ. Главиница, обл. Силистра',
    '12440': 'с. Вълкан, общ. Главиница, обл. Силистра',
    '21230': 'с. Дичево, общ. Главиница, обл. Силистра',
    '22798': 'с. Долно Ряхово, общ. Главиница, обл. Силистра',
    '31406': 'с. Зарица, общ. Главиница, обл. Силистра',
    '30377': 'с. Зафирово, общ. Главиница, обл. Силистра',
    '30507': 'с. Звенимир, общ. Главиница, обл. Силистра',
    '30689': 'с. Зебил, общ. Главиница, обл. Силистра',
    '35554': 'с. Калугерене, общ. Главиница, обл. Силистра',
    '37993': 'с. Коларово, общ. Главиница, обл. Силистра',
    '38707': 'с. Косара, общ. Главиница, обл. Силистра',
    '43894': 'с. Листец, общ. Главиница, обл. Силистра',
    '46927': 'с. Малък Преславец, общ. Главиница, обл. Силистра',
    '52338': 'с. Ножарево, общ. Главиница, обл. Силистра',
    '54078': 'с. Осен, общ. Главиница, обл. Силистра',
    '55138': 'с. Падина, общ. Главиница, обл. Силистра',
    '57056': 'с. Подлес, общ. Главиница, обл. Силистра',
    '67828': 'с. Сокол, общ. Главиница, обл. Силистра',
    '69184': 'с. Стефан Караджа, общ. Главиница, обл. Силистра',
    '70336': 'с. Суходол, общ. Главиница, обл. Силистра',
    '81075': 'с. Черногор, общ. Главиница, обл. Силистра',
    '24030': 'гр. Дулово, общ. Дулово, обл. Силистра',
    '05124': 'с. Боил, общ. Дулово, обл. Силистра',
    '11764': 'с. Водно, общ. Дулово, обл. Силистра',
    '12022': 'с. Вокил, общ. Дулово, обл. Силистра',
    '12721': 'с. Върбино, общ. Дулово, обл. Силистра',
    '18018': 'с. Грънчарово, общ. Дулово, обл. Силистра',
    '21926': 'с. Долец, общ. Дулово, обл. Силистра',
    '31125': 'с. Златоклас, общ. Дулово, обл. Силистра',
    '37839': 'с. Козяк, общ. Дулово, обл. Силистра',
    '38073': 'с. Колобър, общ. Дулово, обл. Силистра',
    '47696': 'с. Межден, общ. Дулово, обл. Силистра',
    '53148': 'с. Овен, общ. Дулово, обл. Силистра',
    '53494': 'с. Окорш, общ. Дулово, обл. Силистра',
    '53744': 'с. Орешене, общ. Дулово, обл. Силистра',
    '55186': 'с. Паисиево, общ. Дулово, обл. Силистра',
    '57296': 'с. Полковник Таслаково, общ. Дулово, обл. Силистра',
    '57813': 'с. Поройно, общ. Дулово, обл. Силистра',
    '57995': 'с. Правда, общ. Дулово, обл. Силистра',
    '58709': 'с. Прохлада, общ. Дулово, обл. Силистра',
    '61724': 'с. Раздел, общ. Дулово, обл. Силистра',
    '63286': 'с. Руйно, общ. Дулово, обл. Силистра',
    '66038': 'с. Секулово, общ. Дулово, обл. Силистра',
    '66696': 'с. Скала, общ. Дулово, обл. Силистра',
    '80738': 'с. Черковна, общ. Дулово, обл. Силистра',
    '81966': 'с. Черник, общ. Дулово, обл. Силистра',
    '81150': 'с. Чернолик, общ. Дулово, обл. Силистра',
    '87504': 'с. Яребица, общ. Дулово, обл. Силистра',
    '11990': 'с. Войново, общ. Кайнарджа, обл. Силистра',
    '15566': 'с. Голеш, общ. Кайнарджа, обл. Силистра',
    '17333': 'с. Господиново, общ. Кайнарджа, обл. Силистра',
    '20047': 'с. Давидово, общ. Кайнарджа, обл. Силистра',
    '21720': 'с. Добруджанка, общ. Кайнарджа, обл. Силистра',
    '30346': 'с. Зарник, общ. Кайнарджа, обл. Силистра',
    '35242': 'с. Кайнарджа, общ. Кайнарджа, обл. Силистра',
    '35938': 'с. Каменци, общ. Кайнарджа, обл. Силистра',
    '39462': 'с. Краново, общ. Кайнарджа, обл. Силистра',
    '57306': 'с. Полковник Чолаково, общ. Кайнарджа, обл. Силистра',
    '57724': 'с. Попрусаново, общ. Кайнарджа, обл. Силистра',
    '57889': 'с. Посев, общ. Кайнарджа, обл. Силистра',
    '65615': 'с. Светослав, общ. Кайнарджа, обл. Силистра',
    '68357': 'с. Средище, общ. Кайнарджа, обл. Силистра',
    '69804': 'с. Стрелково, общ. Кайнарджа, обл. Силистра',
    '66425': 'гр. Силистра, общ. Силистра, обл. Силистра',
    '00895': 'с. Айдемир, общ. Силистра, обл. Силистра',
    '02097': 'с. Бабук, общ. Силистра, обл. Силистра',
    '04772': 'с. Богорово, общ. Силистра, обл. Силистра',
    '06032': 'с. Брадвари, общ. Силистра, обл. Силистра',
    '07329': 'с. Българка, общ. Силистра, обл. Силистра',
    '10834': 'с. Ветрен, общ. Силистра, обл. Силистра',
    '14948': 'с. Главан, общ. Силистра, обл. Силистра',
    '34134': 'с. Йорданово, общ. Силистра, обл. Силистра',
    '35225': 'с. Казимир, общ. Силистра, обл. Силистра',
    '41143': 'с. Калипетрово, общ. Силистра, обл. Силистра',
    '57251': 'с. Полковник Ламбриново, общ. Силистра, обл. Силистра',
    '57604': 'с. Попкралево, общ. Силистра, обл. Силистра',
    '58699': 'с. Професор Иширково, общ. Силистра, обл. Силистра',
    '67526': 'с. Смилец, общ. Силистра, обл. Силистра',
    '68254': 'с. Срацимир, общ. Силистра, обл. Силистра',
    '68299': 'с. Сребърна, общ. Силистра, обл. Силистра',
    '70665': 'с. Сърпово, общ. Силистра, обл. Силистра',
    '78358': 'с. Ценович, общ. Силистра, обл. Силистра',
    '05757': 'с. Босна, общ. Ситово, обл. Силистра',
    '14547': 'с. Гарван, общ. Ситово, обл. Силистра',
    '21693': 'с. Добротица, общ. Ситово, обл. Силистра',
    '32799': 'с. Ирник, общ. Ситово, обл. Силистра',
    '32839': 'с. Искра, общ. Ситово, обл. Силистра',
    '44495': 'с. Любен, общ. Ситово, обл. Силистра',
    '51901': 'с. Нова Попина, общ. Ситово, обл. Силистра',
    '57385': 'с. Поляна, общ. Ситово, обл. Силистра',
    '57577': 'с. Попина, общ. Ситово, обл. Силистра',
    '66665': 'с. Ситово, общ. Ситово, обл. Силистра',
    '67249': 'с. Слатина, общ. Ситово, обл. Силистра',
    '87694': 'с. Ястребна, общ. Ситово, обл. Силистра',
    '73496': 'гр. Тутракан, общ. Тутракан, обл. Силистра',
    '00494': 'с. Антимово, общ. Тутракан, обл. Силистра',
    '03527': 'с. Белица, общ. Тутракан, обл. Силистра',
    '06389': 'с. Бреница, общ. Тутракан, обл. Силистра',
    '10149': 'с. Варненци, общ. Тутракан, обл. Силистра',
    '24044': 'с. Дунавец, общ. Тутракан, обл. Силистра',
    '51956': 'с. Нова Черна, общ. Тутракан, обл. Силистра',
    '57090': 'с. Пожарево, общ. Тутракан, обл. Силистра',
    '58253': 'с. Преславци, общ. Тутракан, обл. Силистра',
    '69078': 'с. Старо село, общ. Тутракан, обл. Силистра',
    '70682': 'с. Сяново, общ. Тутракан, обл. Силистра',
    '73729': 'с. Търновци, общ. Тутракан, обл. Силистра',
    '78238': 'с. Цар Самуил, общ. Тутракан, обл. Силистра',
    '78118': 'с. Царев дол, общ. Тутракан, обл. Силистра',
    '83524': 'с. Шуменци, общ. Тутракан, обл. Силистра',
    '39030': 'гр. Котел, общ. Котел, обл. Сливен',
    '05480': 'с. Боринци, общ. Котел, обл. Сливен',
    '06135': 'с. Братан, общ. Котел, обл. Сливен',
    '17436': 'с. Градец, общ. Котел, обл. Сливен',
    '24270': 'с. Дъбова, общ. Котел, обл. Сливен',
    '29283': 'с. Жеравна, общ. Котел, обл. Сливен',
    '36681': 'с. Катунище, общ. Котел, обл. Сливен',
    '36854': 'с. Кипилово, общ. Котел, обл. Сливен',
    '46646': 'с. Малко село, общ. Котел, обл. Сливен',
    '47528': 'с. Медвен, общ. Котел, обл. Сливен',
    '00045': 'с. Мокрен, общ. Котел, обл. Сливен',
    '51398': 'с. Нейково, общ. Котел, обл. Сливен',
    '53919': 'с. Орлово, общ. Котел, обл. Сливен',
    '54300': 'с. Остра могила, общ. Котел, обл. Сливен',
    '58966': 'с. Пъдарево, общ. Котел, обл. Сливен',
    '65958': 'с. Седларево, общ. Котел, обл. Сливен',
    '67859': 'с. Соколарци, общ. Котел, обл. Сливен',
    '69821': 'с. Стрелци, общ. Котел, обл. Сливен',
    '72480': 'с. Тича, общ. Котел, обл. Сливен',
    '72833': 'с. Топузево, общ. Котел, обл. Сливен',
    '76073': 'с. Филаретово, общ. Котел, обл. Сливен',
    '87031': 'с. Ябланово, общ. Котел, обл. Сливен',
    '51809': 'гр. Нова Загора, общ. Нова Загора, обл. Сливен',
    '00713': 'с. Асеновец, общ. Нова Загора, обл. Сливен',
    '02734': 'с. Баня, общ. Нова Загора, обл. Сливен',
    '04635': 'с. Богданово, общ. Нова Загора, обл. Сливен',
    '06788': 'с. Брястово, общ. Нова Загора, обл. Сливен',
    '07778': 'с. Бял кладенец, общ. Нова Загора, обл. Сливен',
    '24760': 'с. Дядово, общ. Нова Загора, обл. Сливен',
    '27111': 'с. Езеро, общ. Нова Загора, обл. Сливен',
    '27245': 'с. Еленово, общ. Нова Загора, обл. Сливен',
    '30171': 'с. Загорци, общ. Нова Загора, обл. Сливен',
    '35907': 'с. Каменово, общ. Нова Загора, обл. Сливен',
    '36395': 'с. Караново, общ. Нова Загора, обл. Сливен',
    '38426': 'с. Коньово, общ. Нова Загора, обл. Сливен',
    '38683': 'с. Кортен, общ. Нова Загора, обл. Сливен',
    '39757': 'с. Крива круша, общ. Нова Загора, обл. Сливен',
    '44505': 'с. Любенец, общ. Нова Загора, обл. Сливен',
    '44522': 'с. Любенова махала, общ. Нова Загора, обл. Сливен',
    '48725': 'с. Млекарево, общ. Нова Загора, обл. Сливен',
    '51161': 'с. Научене, общ. Нова Загора, обл. Сливен',
    '52146': 'с. Новоселец, общ. Нова Загора, обл. Сливен',
    '53518': 'с. Омарчево, общ. Нова Загора, обл. Сливен',
    '56068': 'с. Пет могили, общ. Нова Загора, обл. Сливен',
    '56527': 'с. Питово, общ. Нова Загора, обл. Сливен',
    '57371': 'с. Полско Пъдарево, общ. Нова Загора, обл. Сливен',
    '58726': 'с. Прохорово, общ. Нова Загора, обл. Сливен',
    '61310': 'с. Радево, общ. Нова Загора, обл. Сливен',
    '61340': 'с. Радецки, общ. Нова Загора, обл. Сливен',
    '67831': 'с. Сокол, общ. Нова Загора, обл. Сливен',
    '69314': 'с. Стоил войвода, общ. Нова Загора, обл. Сливен',
    '70456': 'с. Събрано, общ. Нова Загора, обл. Сливен',
    '70487': 'с. Съдиево, общ. Нова Загора, обл. Сливен',
    '70490': 'с. Съдийско поле, общ. Нова Загора, обл. Сливен',
    '78344': 'с. Ценино, общ. Нова Загора, обл. Сливен',
    '36779': 'гр. Кермен, общ. Сливен, обл. Сливен',
    '67338': 'гр. Сливен, общ. Сливен, обл. Сливен',
    '04039': 'с. Биково, общ. Сливен, обл. Сливен',
    '04114': 'с. Бинкос, общ. Сливен, обл. Сливен',
    '04337': 'с. Блатец, общ. Сливен, обл. Сливен',
    '04947': 'с. Божевци, общ. Сливен, обл. Сливен',
    '05092': 'с. Бозаджии, общ. Сливен, обл. Сливен',
    '07613': 'с. Бяла, общ. Сливен, обл. Сливен',
    '12414': 'с. Въглен, общ. Сливен, обл. Сливен',
    '14275': 'с. Гавраилово, общ. Сливен, обл. Сливен',
    '14800': 'с. Гергевец, общ. Сливен, обл. Сливен',
    '15206': 'с. Глуфишево, общ. Сливен, обл. Сливен',
    '15237': 'с. Глушник, общ. Сливен, обл. Сливен',
    '15936': 'с. Голямо Чочовени, общ. Сливен, обл. Сливен',
    '16688': 'с. Горно Александрово, общ. Сливен, обл. Сливен',
    '17600': 'с. Градско, общ. Сливен, обл. Сливен',
    '23337': 'с. Драгоданово, общ. Сливен, обл. Сливен',
    '29194': 'с. Желю войвода, общ. Сливен, обл. Сливен',
    '30257': 'с. Зайчари, общ. Сливен, обл. Сливен',
    '30990': 'с. Злати войвода, общ. Сливен, обл. Сливен',
    '32545': 'с. Изгрев, общ. Сливен, обл. Сливен',
    '32915': 'с. Ичера, общ. Сливен, обл. Сливен',
    '35537': 'с. Калояново, общ. Сливен, обл. Сливен',
    '35660': 'с. Камен, общ. Сливен, обл. Сливен',
    '37530': 'с. Ковачите, общ. Сливен, обл. Сливен',
    '40083': 'с. Крушаре, общ. Сливен, обл. Сливен',
    '46694': 'с. Малко Чочовени, общ. Сливен, обл. Сливен',
    '47980': 'с. Мечкарево, общ. Сливен, обл. Сливен',
    '48708': 'с. Младово, общ. Сливен, обл. Сливен',
    '51634': 'с. Николаево, общ. Сливен, обл. Сливен',
    '51929': 'с. Новачево, общ. Сливен, обл. Сливен',
    '55333': 'с. Панаретовци, общ. Сливен, обл. Сливен',
    '62061': 'с. Раково, общ. Сливен, обл. Сливен',
    '65303': 'с. Самуилово, общ. Сливен, обл. Сливен',
    '66041': 'с. Селиминово, общ. Сливен, обл. Сливен',
    '66812': 'с. Скобелево, общ. Сливен, обл. Сливен',
    '68117': 'с. Сотиря, общ. Сливен, обл. Сливен',
    '68549': 'с. Средорек, общ. Сливен, обл. Сливен',
    '68864': 'с. Стара река, общ. Сливен, обл. Сливен',
    '69081': 'с. Старо село, общ. Сливен, обл. Сливен',
    '69986': 'с. Струпец, общ. Сливен, обл. Сливен',
    '72816': 'с. Тополчане, общ. Сливен, обл. Сливен',
    '73016': 'с. Трапоклово, общ. Сливен, обл. Сливен',
    '81387': 'с. Чинтулово, общ. Сливен, обл. Сливен',
    '81534': 'с. Чокоба, общ. Сливен, обл. Сливен',
    '72165': 'гр. Твърдица, общ. Твърдица, обл. Сливен',
    '15944': 'гр. Шивачево, общ. Твърдица, обл. Сливен',
    '04443': 'с. Близнец, общ. Твърдица, обл. Сливен',
    '05551': 'с. Боров дол, общ. Твърдица, обл. Сливен',
    '07644': 'с. Бяла паланка, общ. Твърдица, обл. Сливен',
    '29533': 'с. Жълт бряг, общ. Твърдица, обл. Сливен',
    '53847': 'с. Оризари, общ. Твърдица, обл. Сливен',
    '65499': 'с. Сборище, общ. Твърдица, обл. Сливен',
    '70679': 'с. Сърцево, общ. Твърдица, обл. Сливен',
    '80474': 'с. Червенаково, общ. Твърдица, обл. Сливен',
    '44402': 'с. Баните, общ. Баните, обл. Смолян',
    '05737': 'с. Босилково, общ. Баните, обл. Смолян',
    '11377': 'с. Вишнево, общ. Баните, обл. Смолян',
    '12526': 'с. Вълчан дол, общ. Баните, обл. Смолян',
    '15117': 'с. Глогино, общ. Баните, обл. Смолян',
    '00850': 'с. Гълъбово, общ. Баните, обл. Смолян',
    '20033': 'с. Давидково, общ. Баните, обл. Смолян',
    '20198': 'с. Две тополи, общ. Баните, обл. Смолян',
    '20290': 'с. Дебеляново, общ. Баните, обл. Смолян',
    '23875': 'с. Дрянка, общ. Баните, обл. Смолян',
    '30209': 'с. Загражден, общ. Баните, обл. Смолян',
    '40326': 'с. Кръстатица, общ. Баните, обл. Смолян',
    '46406': 'с. Малка Арда, общ. Баните, обл. Смолян',
    '46629': 'с. Малко Крушево, общ. Баните, обл. Смолян',
    '54002': 'с. Оряховец, общ. Баните, обл. Смолян',
    '59313': 'с. Планинско, общ. Баните, обл. Смолян',
    '62606': 'с. Рибен дол, общ. Баните, обл. Смолян',
    '67355': 'с. Сливка, общ. Баните, обл. Смолян',
    '70158': 'с. Стърница, общ. Баните, обл. Смолян',
    '72922': 'с. Траве, общ. Баните, обл. Смолян',
    '05462': 'с. Борино, общ. Борино, обл. Смолян',
    '06879': 'с. Буйново, общ. Борино, обл. Смолян',
    '37592': 'с. Кожари, общ. Борино, обл. Смолян',
    '80159': 'с. Чала, общ. Борино, обл. Смолян',
    '87223': 'с. Ягодина, общ. Борино, обл. Смолян',
    '20465': 'гр. Девин, общ. Девин, обл. Смолян',
    '03112': 'с. Беден, общ. Девин, обл. Смолян',
    '06269': 'с. Брезе, общ. Девин, обл. Смолян',
    '11733': 'с. Водни пад, общ. Девин, обл. Смолян',
    '17957': 'с. Грохотно, общ. Девин, обл. Смолян',
    '18424': 'с. Гьоврен, общ. Девин, обл. Смолян',
    '29502': 'с. Жребево, общ. Девин, обл. Смолян',
    '36796': 'с. Кестен, общ. Девин, обл. Смолян',
    '44848': 'с. Лясково, общ. Девин, обл. Смолян',
    '48547': 'с. Михалково, общ. Девин, обл. Смолян',
    '54198': 'с. Осиково, общ. Девин, обл. Смолян',
    '66130': 'с. Селча, общ. Девин, обл. Смолян',
    '69465': 'с. Стоманево, общ. Девин, обл. Смолян',
    '72360': 'с. Тешел, общ. Девин, обл. Смолян',
    '73105': 'с. Триград, общ. Девин, обл. Смолян',
    '81815': 'с. Чуруково, общ. Девин, обл. Смолян',
    '23025': 'гр. Доспат, общ. Доспат, обл. Смолян',
    '02779': 'с. Барутин, общ. Доспат, обл. Смолян',
    '06700': 'с. Бръщен, общ. Доспат, обл. Смолян',
    '31259': 'с. Змеица, общ. Доспат, обл. Смолян',
    '41006': 'с. Късак, общ. Доспат, обл. Смолян',
    '44642': 'с. Любча, общ. Доспат, обл. Смолян',
    '78567': 'с. Црънча, общ. Доспат, обл. Смолян',
    '80025': 'с. Чавдар, общ. Доспат, обл. Смолян',
    '31111': 'гр. Златоград, общ. Златоград, обл. Смолян',
    '00196': 'с. Аламовци, общ. Златоград, обл. Смолян',
    '21871': 'с. Долен, общ. Златоград, обл. Смолян',
    '27588': 'с. Ерма река, общ. Златоград, обл. Смолян',
    '40806': 'с. Кушла, общ. Златоград, обл. Смолян',
    '59344': 'с. Пресока, общ. Златоград, обл. Смолян',
    '69105': 'с. Старцево, общ. Златоград, обл. Смолян',
    '69759': 'с. Страшимир, общ. Златоград, обл. Смолян',
    '78241': 'с. Цацаровци, общ. Златоград, обл. Смолян',
    '46045': 'гр. Мадан, общ. Мадан, обл. Смолян',
    '00669': 'с. Арпаджик, общ. Мадан, обл. Смолян',
    '05387': 'с. Борика, общ. Мадан, обл. Смолян',
    '05476': 'с. Бориново, общ. Мадан, обл. Смолян',
    '05579': 'с. Боровина, общ. Мадан, обл. Смолян',
    '06937': 'с. Букова поляна, общ. Мадан, обл. Смолян',
    '07017': 'с. Буково, общ. Мадан, обл. Смолян',
    '10910': 'с. Вехтино, общ. Мадан, обл. Смолян',
    '11260': 'с. Високите, общ. Мадан, обл. Смолян',
    '12187': 'с. Вранинци, общ. Мадан, обл. Смолян',
    '12655': 'с. Върба, общ. Мадан, обл. Смолян',
    '12718': 'с. Върбина, общ. Мадан, обл. Смолян',
    '12841': 'с. Въргов дол, общ. Мадан, обл. Смолян',
    '14115': 'с. Габрина, общ. Мадан, обл. Смолян',
    '14413': 'с. Галище, общ. Мадан, обл. Смолян',
    '21213': 'с. Дирало, общ. Мадан, обл. Смолян',
    '21943': 'с. Долие, общ. Мадан, обл. Смолян',
    '36556': 'с. Касапско, общ. Мадан, обл. Смолян',
    '38594': 'с. Кориите, общ. Мадан, обл. Смолян',
    '39311': 'с. Крайна, общ. Мадан, обл. Смолян',
    '40110': 'с. Крушев дол, общ. Мадан, обл. Смолян',
    '40662': 'с. Купен, общ. Мадан, обл. Смолян',
    '43387': 'с. Леска, общ. Мадан, обл. Смолян',
    '43582': 'с. Лещак, общ. Мадан, обл. Смолян',
    '43637': 'с. Ливаде, общ. Мадан, обл. Смолян',
    '43983': 'с. Ловци, общ. Мадан, обл. Смолян',
    '48146': 'с. Миле, общ. Мадан, обл. Смолян',
    '48461': 'с. Митовска, общ. Мадан, обл. Смолян',
    '49504': 'с. Мъглища, общ. Мадан, обл. Смолян',
    '56157': 'с. Петров дол, общ. Мадан, обл. Смолян',
    '56246': 'с. Печинска, общ. Мадан, обл. Смолян',
    '56681': 'с. Планинци, общ. Мадан, обл. Смолян',
    '61162': 'с. Равнил, общ. Мадан, обл. Смолян',
    '61176': 'с. Равнища, общ. Мадан, обл. Смолян',
    '61234': 'с. Равно нивище, общ. Мадан, обл. Смолян',
    '63481': 'с. Рустан, общ. Мадан, обл. Смолян',
    '68451': 'с. Средногорци, общ. Мадан, обл. Смолян',
    '68638': 'с. Стайчин дол, общ. Мадан, обл. Смолян',
    '70041': 'с. Студена, общ. Мадан, обл. Смолян',
    '73599': 'с. Тънкото, общ. Мадан, обл. Смолян',
    '75157': 'с. Уручевци, общ. Мадан, обл. Смолян',
    '78505': 'с. Цирка, общ. Мадан, обл. Смолян',
    '81801': 'с. Чурка, общ. Мадан, обл. Смолян',
    '83048': 'с. Шаренска, общ. Мадан, обл. Смолян',
    '51319': 'гр. Неделино, общ. Неделино, обл. Смолян',
    '07911': 'с. Бурево, общ. Неделино, обл. Смолян',
    '12896': 'с. Върли дол, общ. Неделино, обл. Смолян',
    '12927': 'с. Върлино, общ. Неделино, обл. Смолян',
    '18374': 'с. Гърнати, общ. Неделино, обл. Смолян',
    '21004': 'с. Диманово, общ. Неделино, обл. Смолян',
    '24952': 'с. Дуня, общ. Неделино, обл. Смолян',
    '41157': 'с. Еленка, общ. Неделино, обл. Смолян',
    '32559': 'с. Изгрев, общ. Неделино, обл. Смолян',
    '37695': 'с. Козарка, общ. Неделино, обл. Смолян',
    '39092': 'с. Кочани, общ. Неделино, обл. Смолян',
    '41277': 'с. Крайна, общ. Неделино, обл. Смолян',
    '40628': 'с. Кундево, общ. Неделино, обл. Смолян',
    '53388': 'с. Оградна, общ. Неделино, обл. Смолян',
    '68309': 'с. Средец, общ. Неделино, обл. Смолян',
    '73883': 'с. Тънка бара, общ. Неделино, обл. Смолян',
    '63207': 'гр. Рудозем, общ. Рудозем, обл. Смолян',
    '04861': 'с. Боево, общ. Рудозем, обл. Смолян',
    '05365': 'с. Борие, общ. Рудозем, обл. Смолян',
    '06255': 'с. Бреза, общ. Рудозем, обл. Смолян',
    '07569': 'с. Бърчево, общ. Рудозем, обл. Смолян',
    '07689': 'с. Бяла река, общ. Рудозем, обл. Смолян',
    '11301': 'с. Витина, общ. Рудозем, обл. Смолян',
    '11870': 'с. Войкова лъка, общ. Рудозем, обл. Смолян',
    '17676': 'с. Грамаде, общ. Рудозем, обл. Смолян',
    '21347': 'с. Добрева череша, общ. Рудозем, обл. Смолян',
    '24284': 'с. Дъбова, общ. Рудозем, обл. Смолян',
    '27365': 'с. Елховец, общ. Рудозем, обл. Смолян',
    '32929': 'с. Иваново, общ. Рудозем, обл. Смолян',
    '37959': 'с. Кокорци, общ. Рудозем, обл. Смолян',
    '38604': 'с. Коритата, общ. Рудозем, обл. Смолян',
    '49165': 'с. Мочуре, общ. Рудозем, обл. Смолян',
    '53309': 'с. Оглед, общ. Рудозем, обл. Смолян',
    '56798': 'с. Пловдивци, общ. Рудозем, обл. Смолян',
    '57399': 'с. Поляна, общ. Рудозем, обл. Смолян',
    '61193': 'с. Равнината, общ. Рудозем, обл. Смолян',
    '62637': 'с. Рибница, общ. Рудозем, обл. Смолян',
    '68093': 'с. Сопотот, общ. Рудозем, обл. Смолян',
    '80399': 'с. Чепинци, общ. Рудозем, обл. Смолян',
    '67653': 'гр. Смолян, общ. Смолян, обл. Смолян',
    '00388': 'с. Алиговска, общ. Смолян, обл. Смолян',
    '00597': 'с. Арда, общ. Смолян, обл. Смолян',
    '02076': 'с. Баблон, общ. Смолян, обл. Смолян',
    '03321': 'с. Белев дол, общ. Смолян, обл. Смолян',
    '04100': 'с. Билянска, общ. Смолян, обл. Смолян',
    '05414': 'с. Бориково, общ. Смолян, обл. Смолян',
    '05801': 'с. Бостина, общ. Смолян, обл. Смолян',
    '06923': 'с. Буката, общ. Смолян, обл. Смолян',
    '02988': 'с. Букаците, общ. Смолян, обл. Смолян',
    '11048': 'с. Виево, общ. Смолян, обл. Смолян',
    '11572': 'с. Влахово, общ. Смолян, обл. Смолян',
    '11604': 'с. Водата, общ. Смолян, обл. Смолян',
    '12512': 'с. Вълчан, общ. Смолян, обл. Смолян',
    '12807': 'с. Върбово, общ. Смолян, обл. Смолян',
    '14129': 'с. Габрица, общ. Смолян, обл. Смолян',
    '14605': 'с. Гела, общ. Смолян, обл. Смолян',
    '15343': 'с. Гоздевица, общ. Смолян, обл. Смолян',
    '02782': 'с. Горна Арда, общ. Смолян, обл. Смолян',
    '17052': 'с. Горово, общ. Смолян, обл. Смолян',
    '17631': 'с. Градът, общ. Смолян, обл. Смолян',
    '17837': 'с. Гращица, общ. Смолян, обл. Смолян',
    '18068': 'с. Гудевица, общ. Смолян, обл. Смолян',
    '21107': 'с. Димово, общ. Смолян, обл. Смолян',
    '24089': 'с. Дунево, общ. Смолян, обл. Смолян',
    '27262': 'с. Еленска, общ. Смолян, обл. Смолян',
    '27415': 'с. Ельово, общ. Смолян, обл. Смолян',
    '30226': 'с. Заевите, общ. Смолян, обл. Смолян',
    '31285': 'с. Змиево, общ. Смолян, обл. Смолян',
    '32891': 'с. Исьовци, общ. Смолян, обл. Смолян',
    '36631': 'с. Катраница, общ. Смолян, обл. Смолян',
    '36974': 'с. Киселчово, общ. Смолян, обл. Смолян',
    '37945': 'с. Кокорово, общ. Смолян, обл. Смолян',
    '39181': 'с. Кошница, общ. Смолян, обл. Смолян',
    '39637': 'с. Кремене, общ. Смолян, обл. Смолян',
    '40498': 'с. Кукувица, общ. Смолян, обл. Смолян',
    '40748': 'с. Кутела, общ. Смолян, обл. Смолян',
    '43219': 'с. Левочево, общ. Смолян, обл. Смолян',
    '43743': 'с. Липец, общ. Смолян, обл. Смолян',
    '44433': 'с. Лъка, общ. Смолян, обл. Смолян',
    '44673': 'с. Люлка, общ. Смолян, обл. Смолян',
    '46019': 'с. Магарджица, общ. Смолян, обл. Смолян',
    '48218': 'с. Милково, общ. Смолян, обл. Смолян',
    '48814': 'с. Могилица, общ. Смолян, обл. Смолян',
    '49014': 'с. Момчиловци, общ. Смолян, обл. Смолян',
    '49285': 'с. Мугла, общ. Смолян, обл. Смолян',
    '51038': 'с. Надарци, общ. Смолян, обл. Смолян',
    '53792': 'с. Орешец, общ. Смолян, обл. Смолян',
    '54359': 'с. Остри пазлак, общ. Смолян, обл. Смолян',
    '55991': 'с. Петково, общ. Смолян, обл. Смолян',
    '56294': 'с. Пещера, общ. Смолян, обл. Смолян',
    '56455': 'с. Писаница, общ. Смолян, обл. Смолян',
    '56962': 'с. Подвис, общ. Смолян, обл. Смолян',
    '57282': 'с. Полковник Серафимово, общ. Смолян, обл. Смолян',
    '57710': 'с. Попрелка, общ. Смолян, обл. Смолян',
    '57916': 'с. Потока, общ. Смолян, обл. Смолян',
    '62462': 'с. Река, общ. Смолян, обл. Смолян',
    '62534': 'с. Речани, общ. Смолян, обл. Смолян',
    '62760': 'с. Ровина, общ. Смолян, обл. Смолян',
    '66069': 'с. Селище, общ. Смолян, обл. Смолян',
    '66384': 'с. Сивино, общ. Смолян, обл. Смолян',
    '66957': 'с. Славейно, общ. Смолян, обл. Смолян',
    '67430': 'с. Сливово, общ. Смолян, обл. Смолян',
    '67547': 'с. Смилян, общ. Смолян, обл. Смолян',
    '67965': 'с. Соколовци, общ. Смолян, обл. Смолян',
    '68000': 'с. Солища, общ. Смолян, обл. Смолян',
    '68521': 'с. Средок, общ. Смолян, обл. Смолян',
    '69256': 'с. Стикъл, общ. Смолян, обл. Смолян',
    '69345': 'с. Стойките, общ. Смолян, обл. Смолян',
    '69585': 'с. Стража, общ. Смолян, обл. Смолян',
    '70620': 'с. Сърнино, общ. Смолян, обл. Смолян',
    '72391': 'с. Тикале, общ. Смолян, обл. Смолян',
    '73033': 'с. Требище, общ. Смолян, обл. Смолян',
    '73482': 'с. Турян, общ. Смолян, обл. Смолян',
    '73777': 'с. Търън, общ. Смолян, обл. Смолян',
    '75215': 'с. Ухловица, общ. Смолян, обл. Смолян',
    '76042': 'с. Фатово, общ. Смолян, обл. Смолян',
    '77205': 'с. Хасовица, общ. Смолян, обл. Смолян',
    '80193': 'с. Чамла, общ. Смолян, обл. Смолян',
    '80412': 'с. Чеплетен, общ. Смолян, обл. Смолян',
    '80604': 'с. Черешките, общ. Смолян, обл. Смолян',
    '80666': 'с. Черешово, общ. Смолян, обл. Смолян',
    '80678': 'с. Черешовска река, общ. Смолян, обл. Смолян',
    '81520': 'с. Чокманово, общ. Смолян, обл. Смолян',
    '31841': 'с. Чучур, общ. Смолян, обл. Смолян',
    '83274': 'с. Широка лъка, общ. Смолян, обл. Смолян',
    '80371': 'гр. Чепеларе, общ. Чепеларе, обл. Смолян',
    '04801': 'с. Богутево, общ. Чепеларе, обл. Смолян',
    '23929': 'с. Дряновец, общ. Чепеларе, обл. Смолян',
    '30034': 'с. Забърдо, общ. Чепеларе, обл. Смолян',
    '31351': 'с. Зорница, общ. Чепеларе, обл. Смолян',
    '43668': 'с. Лилеково, общ. Чепеларе, обл. Смолян',
    '46286': 'с. Малево, общ. Чепеларе, обл. Смолян',
    '53669': 'с. Орехово, общ. Чепеларе, обл. Смолян',
    '54376': 'с. Острица, общ. Чепеларе, обл. Смолян',
    '55035': 'с. Павелско, общ. Чепеларе, обл. Смолян',
    '58517': 'с. Проглед, общ. Чепеларе, обл. Смолян',
    '70072': 'с. Студенец, общ. Чепеларе, обл. Смолян',
    '77219': 'с. Хвойна, общ. Чепеларе, обл. Смолян',
    '02659': 'гр. Банкя, общ. Столична, обл. София град',
    '07140': 'гр. Бухово, общ. Столична, обл. София град',
    '00357': 'гр. Нови Искър, общ. Столична, обл. София град',
    '68134': 'гр. София, общ. Столична, обл. София град',
    '02511': 'с. Балша, общ. Столична, обл. София град',
    '04234': 'с. Бистрица, общ. Столична, обл. София град',
    '07106': 'с. Бусманци, общ. Столична, обл. София град',
    '11394': 'с. Владая, общ. Столична, обл. София град',
    '11884': 'с. Войнеговци, общ. Столична, обл. София град',
    '12084': 'с. Волуяк, общ. Столична, обл. София град',
    '14831': 'с. Герман, общ. Столична, обл. София град',
    '16448': 'с. Горни Богров, общ. Столична, обл. София град',
    '21662': 'с. Доброславци, общ. Столична, обл. София град',
    '22304': 'с. Долни Богров, общ. Столична, обл. София град',
    '22472': 'с. Долни Пасарел, общ. Столична, обл. София град',
    '29150': 'с. Железница, общ. Столична, обл. София град',
    '29204': 'с. Желява, общ. Столична, обл. София град',
    '29430': 'с. Житен, общ. Столична, обл. София град',
    '32216': 'с. Иваняне, общ. Столична, обл. София град',
    '35239': 'с. Казичене, общ. Столична, обл. София град',
    '37280': 'с. Клисура, общ. Столична, обл. София град',
    '37914': 'с. Кокаляне, общ. Столична, обл. София град',
    '39791': 'с. Кривина, общ. Столична, обл. София град',
    '40436': 'с. Кубратово, общ. Столична, обл. София град',
    '41010': 'с. Кътина, общ. Столична, обл. София град',
    '44063': 'с. Лозен, общ. Столична, обл. София град',
    '44224': 'с. Локорско, общ. Столична, обл. София град',
    '46721': 'с. Мало Бучино, общ. Столична, обл. София град',
    '48393': 'с. Мировяне, общ. Столична, обл. София град',
    '49206': 'с. Мрамор, общ. Столична, обл. София град',
    '49597': 'с. Мърчаево, общ. Столична, обл. София град',
    '51250': 'с. Негован, общ. Столична, обл. София град',
    '55419': 'с. Панчарево, общ. Столична, обл. София град',
    '56624': 'с. Плана, общ. Столична, обл. София град',
    '57011': 'с. Подгумер, общ. Столична, обл. София град',
    '65601': 'с. Световрачене, общ. Столична, обл. София град',
    '80409': 'с. Чепинци, общ. Столична, обл. София град',
    '87401': 'с. Яна, общ. Столична, обл. София град',
    '00504': 'с. Антон, общ. Антон, обл. София област',
    '05027': 'гр. Божурище, общ. Божурище, обл. София област',
    '18174': 'с. Гурмазово, общ. Божурище, обл. София област',
    '20626': 'с. Делян, общ. Божурище, обл. София област',
    '31187': 'с. Златуша, общ. Божурище, обл. София област',
    '46259': 'с. Мала Раковица, общ. Божурище, обл. София област',
    '57100': 'с. Пожарево, общ. Божурище, обл. София област',
    '58606': 'с. Пролеша, общ. Божурище, обл. София област',
    '63121': 'с. Росоман, общ. Божурище, обл. София област',
    '77246': 'с. Хераково, общ. Божурище, обл. София област',
    '77400': 'с. Храбърско, общ. Божурище, обл. София област',
    '05815': 'гр. Ботевград, общ. Ботевград, обл. София област',
    '04950': 'с. Боженица, общ. Ботевград, обл. София област',
    '12283': 'с. Врачеш, общ. Ботевград, обл. София област',
    '18143': 'с. Гурково, общ. Ботевград, обл. София област',
    '27334': 'с. Елов дол, общ. Ботевград, обл. София област',
    '39267': 'с. Краево, общ. Ботевград, обл. София област',
    '43788': 'с. Липница, общ. Ботевград, обл. София област',
    '43904': 'с. Литаково, общ. Ботевград, обл. София област',
    '51946': 'с. Новачене, общ. Ботевград, обл. София област',
    '61594': 'с. Радотина, общ. Ботевград, обл. София област',
    '62298': 'с. Рашково, общ. Ботевград, обл. София област',
    '66860': 'с. Скравена, общ. Ботевград, обл. София област',
    '73256': 'с. Трудовец, общ. Ботевград, обл. София област',
    '15309': 'гр. Годеч, общ. Годеч, обл. София област',
    '06050': 'с. Бракьовци, общ. Годеч, обл. София област',
    '07020': 'с. Букоровци, общ. Годеч, обл. София област',
    '07555': 'с. Бърля, общ. Годеч, обл. София област',
    '12320': 'с. Връдловци, общ. Годеч, обл. София област',
    '12770': 'с. Върбница, общ. Годеч, обл. София област',
    '14903': 'с. Гинци, общ. Годеч, обл. София област',
    '15572': 'с. Голеш, общ. Годеч, обл. София област',
    '18037': 'с. Губеш, общ. Годеч, обл. София област',
    '35345': 'с. Каленовци, общ. Годеч, обл. София област',
    '38186': 'с. Комщица, общ. Годеч, обл. София област',
    '44300': 'с. Лопушня, общ. Годеч, обл. София област',
    '49334': 'с. Мургаш, общ. Годеч, обл. София област',
    '61100': 'с. Равна, общ. Годеч, обл. София област',
    '61652': 'с. Разбоище, общ. Годеч, обл. София област',
    '63001': 'с. Ропот, общ. Годеч, обл. София област',
    '67641': 'с. Смолча, общ. Годеч, обл. София област',
    '68775': 'с. Станинци, общ. Годеч, обл. София област',
    '73417': 'с. Туден, общ. Годеч, обл. София област',
    '83466': 'с. Шума, общ. Годеч, обл. София област',
    '00549': 'с. Априлово, общ. Горна Малина, обл. София област',
    '02258': 'с. Байлово, общ. Горна Малина, обл. София област',
    '03705': 'с. Белопопци, общ. Горна Малина, обл. София област',
    '14338': 'с. Гайтанево, общ. Горна Малина, обл. София област',
    '16314': 'с. Горна Малина, общ. Горна Малина, обл. София област',
    '16794': 'с. Горно Камарци, общ. Горна Малина, обл. София област',
    '22174': 'с. Долна Малина, общ. Горна Малина, обл. София област',
    '22647': 'с. Долно Камарци, общ. Горна Малина, обл. София област',
    '46231': 'с. Макоцево, общ. Горна Малина, обл. София област',
    '51281': 'с. Негушево, общ. Горна Малина, обл. София област',
    '54287': 'с. Осоица, общ. Горна Малина, обл. София област',
    '65419': 'с. Саранци, общ. Горна Малина, обл. София област',
    '70127': 'с. Стъргел, общ. Горна Малина, обл. София област',
    '80282': 'с. Чеканчево, общ. Горна Малина, обл. София област',
    '22006': 'гр. Долна баня, общ. Долна баня, обл. София област',
    '87816': 'с. Свети Спас, общ. Долна баня, обл. София област',
    '23409': 'гр. Драгоман, общ. Драгоман, обл. София област',
    '03890': 'с. Беренде, общ. Драгоман, обл. София област',
    '03900': 'с. Беренде извор, общ. Драгоман, обл. София област',
    '10269': 'с. Василовци, общ. Драгоман, обл. София област',
    '11332': 'с. Вишан, общ. Драгоман, обл. София област',
    '11483': 'с. Владиславци, общ. Драгоман, обл. София област',
    '14034': 'с. Габер, общ. Драгоман, обл. София област',
    '15518': 'с. Големо Малово, общ. Драгоман, обл. София област',
    '16938': 'с. Горно село, общ. Драгоман, обл. София област',
    '18006': 'с. Грълска падина, общ. Драгоман, обл. София област',
    '22229': 'с. Долна Невля, общ. Драгоман, обл. София област',
    '22722': 'с. Долно ново село, общ. Драгоман, обл. София област',
    '23354': 'с. Драгоил, общ. Драгоман, обл. София област',
    '23635': 'с. Дреатин, общ. Драгоман, обл. София област',
    '35479': 'с. Калотина, общ. Драгоман, обл. София област',
    '35630': 'с. Камбелевци, общ. Драгоман, обл. София област',
    '40070': 'с. Круша, общ. Драгоман, обл. София област',
    '43484': 'с. Летница, общ. Драгоман, обл. София област',
    '43757': 'с. Липинци, общ. Драгоман, обл. София област',
    '46766': 'с. Мало Малово, общ. Драгоман, обл. София област',
    '51189': 'с. Начево, общ. Драгоман, обл. София област',
    '51322': 'с. Неделище, общ. Драгоман, обл. София област',
    '51514': 'с. Несла, общ. Драгоман, обл. София област',
    '52043': 'с. Ново бърдо, общ. Драгоман, обл. София област',
    '58133': 'с. Прекръсте, общ. Драгоман, обл. София област',
    '62342': 'с. Раяновци, общ. Драгоман, обл. София област',
    '72014': 'с. Табан, общ. Драгоман, обл. София област',
    '78255': 'с. Цацаровци, общ. Драгоман, обл. София област',
    '78553': 'с. Цръклевци, общ. Драгоман, обл. София област',
    '80265': 'с. Чеканец, общ. Драгоман, обл. София област',
    '80426': 'с. Чепърлинци, общ. Драгоман, обл. София област',
    '81579': 'с. Чорул, общ. Драгоман, обл. София област',
    '81699': 'с. Чуковезер, общ. Драгоман, обл. София област',
    '87343': 'с. Ялботина, общ. Драгоман, обл. София област',
    '27303': 'гр. Елин Пелин, общ. Елин Пелин, обл. София област',
    '04604': 'с. Богданлия, общ. Елин Пелин, обл. София област',
    '14074': 'с. Габра, общ. Елин Пелин, обл. София област',
    '15432': 'с. Голема Раковица, общ. Елин Пелин, обл. София област',
    '17885': 'с. Григорево, общ. Елин Пелин, обл. София област',
    '21779': 'с. Доганово, общ. Елин Пелин, обл. София област',
    '34120': 'с. Елешница, общ. Елин Пелин, обл. София област',
    '18490': 'с. Елин Пелин, общ. Елин Пелин, обл. София област',
    '36422': 'с. Караполци, общ. Елин Пелин, обл. София област',
    '40227': 'с. Крушовица, общ. Елин Пелин, обл. София област',
    '43445': 'с. Лесново, общ. Елин Пелин, обл. София област',
    '49388': 'с. Мусачево, общ. Елин Пелин, обл. София област',
    '52012': 'с. Нови хан, общ. Елин Пелин, обл. София област',
    '53343': 'с. Огняново, общ. Елин Пелин, обл. София област',
    '56006': 'с. Петково, общ. Елин Пелин, обл. София област',
    '57921': 'с. Потоп, общ. Елин Пелин, обл. София област',
    '61248': 'с. Равно поле, общ. Елин Пелин, обл. София област',
    '69448': 'с. Столник, общ. Елин Пелин, обл. София област',
    '81760': 'с. Чурек, общ. Елин Пелин, обл. София област',
    '27632': 'гр. Етрополе, общ. Етрополе, обл. София област',
    '05147': 'с. Бойковец, общ. Етрополе, обл. София област',
    '06608': 'с. Брусен, общ. Етрополе, обл. София област',
    '17289': 'с. Горунака, общ. Етрополе, обл. София област',
    '44313': 'с. Лопян, общ. Етрополе, обл. София област',
    '44389': 'с. Лъга, общ. Етрополе, обл. София област',
    '49641': 'с. Малки Искър, общ. Етрополе, обл. София област',
    '54050': 'с. Оселна, общ. Етрополе, обл. София област',
    '62582': 'с. Рибарица, общ. Етрополе, обл. София област',
    '87391': 'с. Ямна, общ. Етрополе, обл. София област',
    '31044': 'гр. Златица, общ. Златица, обл. София област',
    '36484': 'с. Карлиево, общ. Златица, обл. София област',
    '56137': 'с. Петрич, общ. Златица, обл. София област',
    '78669': 'с. Църквище, общ. Златица, обл. София област',
    '32901': 'гр. Ихтиман, общ. Ихтиман, обл. София област',
    '02539': 'с. Бальовци, общ. Ихтиман, обл. София област',
    '02689': 'с. Банчовци, общ. Ихтиман, обл. София област',
    '07960': 'с. Белица, общ. Ихтиман, обл. София област',
    '04649': 'с. Богдановци, общ. Ихтиман, обл. София област',
    '04892': 'с. Боерица, общ. Ихтиман, обл. София област',
    '05390': 'с. Борика, общ. Ихтиман, обл. София област',
    '06851': 'с. Бузяковци, общ. Ихтиман, обл. София област',
    '07435': 'с. Бърдо, общ. Ихтиман, обл. София област',
    '10029': 'с. Вакарел, общ. Ихтиман, обл. София област',
    '10656': 'с. Венковец, общ. Ихтиман, обл. София област',
    '10690': 'с. Веринско, общ. Ихтиман, обл. София област',
    '20729': 'с. Джамузовци, общ. Ихтиман, обл. София област',
    '29338': 'с. Живково, общ. Ихтиман, обл. София област',
    '41191': 'с. Костадинкино, общ. Ихтиман, обл. София област',
    '44611': 'с. Любница, общ. Ихтиман, обл. София област',
    '48009': 'с. Мечковци, общ. Ихтиман, обл. София област',
    '48369': 'с. Мирово, общ. Ихтиман, обл. София област',
    '49450': 'с. Мухово, общ. Ихтиман, обл. София област',
    '51411': 'с. Нейкьовец, общ. Ихтиман, обл. София област',
    '55405': 'с. Пановци, общ. Ихтиман, обл. София област',
    '55600': 'с. Пауново, общ. Ихтиман, обл. София област',
    '57443': 'с. Полянци, общ. Ихтиман, обл. София област',
    '57683': 'с. Поповци, общ. Ихтиман, обл. София област',
    '63522': 'с. Ръжана, общ. Ихтиман, обл. София област',
    '66144': 'с. Селянин, общ. Ихтиман, обл. София област',
    '04830': 'с. Стамболово, общ. Ихтиман, обл. София област',
    '81253': 'с. Черньово, общ. Ихтиман, обл. София област',
    '38558': 'гр. Копривщица, общ. Копривщица, обл. София област',
    '38902': 'гр. Костенец, общ. Костенец, обл. София област',
    '50245': 'гр. Момин проход, общ. Костенец, обл. София област',
    '15374': 'с. Голак, общ. Костенец, обл. София област',
    '18561': 'с. Горна Василица, общ. Костенец, обл. София област',
    '24894': 'с. Долна Василица, общ. Костенец, обл. София област',
    '38916': 'с. Костенец, общ. Костенец, обл. София област',
    '54554': 'с. Очуша, общ. Костенец, обл. София област',
    '56993': 'с. Подгорие, общ. Костенец, обл. София област',
    '58863': 'с. Пчелин, общ. Костенец, обл. София област',
    '38978': 'гр. Костинброд, общ. Костинброд, обл. София област',
    '03191': 'с. Безден, общ. Костинброд, обл. София област',
    '04813': 'с. Богьовци, общ. Костинброд, обл. София област',
    '07171': 'с. Бучин проход, общ. Костинброд, обл. София област',
    '15984': 'с. Голяновци, общ. Костинброд, обл. София област',
    '17449': 'с. Градец, общ. Костинброд, обл. София област',
    '23296': 'с. Драговищица, общ. Костинброд, обл. София област',
    '23707': 'с. Дреново, общ. Костинброд, обл. София област',
    '23844': 'с. Дръмша, общ. Костинброд, обл. София област',
    '53607': 'с. Опицвет, общ. Костинброд, обл. София област',
    '56215': 'с. Петърч, общ. Костинброд, обл. София област',
    '57529': 'с. Понор, общ. Костинброд, обл. София област',
    '78197': 'с. Царичина, общ. Костинброд, обл. София област',
    '81356': 'с. Чибаовци, общ. Костинброд, обл. София област',
    '03842': 'с. Бенковски, общ. Мирково, обл. София област',
    '06421': 'с. Брестака, общ. Мирково, обл. София област',
    '07051': 'с. Буново, общ. Мирково, обл. София област',
    '32651': 'с. Илинден, общ. Мирково, обл. София област',
    '35821': 'с. Каменица, общ. Мирково, обл. София област',
    '48324': 'с. Мирково, общ. Мирково, обл. София област',
    '56842': 'с. Плъзище, общ. Мирково, обл. София област',
    '58267': 'с. Преспа, общ. Мирково, обл. София област',
    '67636': 'с. Смолско, общ. Мирково, обл. София област',
    '77236': 'с. Хвърчил, общ. Мирково, обл. София област',
    '80707': 'с. Черковище, общ. Мирково, обл. София област',
    '56407': 'гр. Пирдоп, общ. Пирдоп, обл. София област',
    '24164': 'с. Душанци, общ. Пирдоп, обл. София област',
    '58030': 'гр. Правец, общ. Правец, обл. София област',
    '11020': 'с. Видраре, общ. Правец, обл. София област',
    '20897': 'с. Джурово, общ. Правец, обл. София област',
    '35585': 'с. Калугерово, общ. Правец, обл. София област',
    '46961': 'с. Манаселска река, общ. Правец, обл. София област',
    '54170': 'с. Осиковица, общ. Правец, обл. София област',
    '54211': 'с. Осиковска Лакавица, общ. Правец, обл. София област',
    '58044': 'с. Правешка Лакавица, общ. Правец, обл. София област',
    '61189': 'с. Равнище, общ. Правец, обл. София област',
    '61807': 'с. Разлив, общ. Правец, обл. София област',
    '65872': 'с. Своде, общ. Правец, обл. София област',
    '65231': 'гр. Самоков, общ. Самоков, обл. София област',
    '00391': 'с. Алино, общ. Самоков, обл. София област',
    '03441': 'с. Бели Искър, общ. Самоков, обл. София област',
    '03767': 'с. Белчин, общ. Самоков, обл. София област',
    '03770': 'с. Белчински бани, общ. Самоков, обл. София област',
    '15285': 'с. Говедарци, общ. Самоков, обл. София област',
    '16599': 'с. Горни Окол, общ. Самоков, обл. София област',
    '18201': 'с. Гуцал, общ. Самоков, обл. София област',
    '22469': 'с. Долни Окол, общ. Самоков, обл. София област',
    '23039': 'с. Доспей, общ. Самоков, обл. София област',
    '23491': 'с. Драгушиново, общ. Самоков, обл. София област',
    '31228': 'с. Злокучене, общ. Самоков, обл. София област',
    '37294': 'с. Клисура, общ. Самоков, обл. София област',
    '37527': 'с. Ковачевци, общ. Самоков, обл. София област',
    '43832': 'с. Лисец, общ. Самоков, обл. София област',
    '46067': 'с. Маджаре, общ. Самоков, обл. София област',
    '46276': 'с. Мала църква, общ. Самоков, обл. София област',
    '47264': 'с. Марица, общ. Самоков, обл. София област',
    '52249': 'с. Ново село, общ. Самоков, обл. София област',
    '57697': 'с. Поповяне, общ. Самоков, обл. София област',
    '58548': 'с. Продановци, общ. Самоков, обл. София област',
    '61604': 'с. Радуил, общ. Самоков, обл. София област',
    '61922': 'с. Райово, общ. Самоков, обл. София област',
    '62486': 'с. Рельово, общ. Самоков, обл. София област',
    '83243': 'с. Шипочане, общ. Самоков, обл. София област',
    '83291': 'с. Широки дол, общ. Самоков, обл. София област',
    '87535': 'с. Яребковица, общ. Самоков, обл. София област',
    '87552': 'с. Ярлово, общ. Самоков, обл. София област',
    '65869': 'гр. Своге, общ. Своге, обл. София област',
    '02289': 'с. Бакьово, общ. Своге, обл. София област',
    '02899': 'с. Батулия, общ. Своге, обл. София област',
    '04546': 'с. Бов, общ. Своге, обл. София област',
    '06272': 'с. Брезе, общ. Своге, обл. София област',
    '06327': 'с. Брезовдол, общ. Своге, обл. София област',
    '06985': 'с. Буковец, общ. Своге, обл. София област',
    '11510': 'с. Владо Тричков, общ. Своге, обл. София област',
    '14194': 'с. Габровница, общ. Своге, обл. София област',
    '18040': 'с. Губислав, общ. Своге, обл. София област',
    '21316': 'с. Добравица, общ. Своге, обл. София област',
    '21751': 'с. Добърчин, общ. Своге, обл. София област',
    '23798': 'с. Дружево, общ. Своге, обл. София област',
    '27228': 'с. Еленов дол, общ. Своге, обл. София област',
    '29163': 'с. Желен, общ. Своге, обл. София област',
    '30082': 'с. Завидовци, общ. Своге, обл. София област',
    '30329': 'с. Заноге, общ. Своге, обл. София област',
    '30350': 'с. Заселе, общ. Своге, обл. София област',
    '30847': 'с. Зимевица, общ. Своге, обл. София област',
    '32843': 'с. Искрец, общ. Своге, обл. София област',
    '14475': 'с. Лакатник, общ. Своге, обл. София област',
    '43092': 'с. Лакатник, общ. Своге, обл. София област',
    '44906': 'с. Левище, общ. Своге, обл. София област',
    '43390': 'с. Лесковдол, общ. Своге, обл. София област',
    '44330': 'с. Луково, общ. Своге, обл. София област',
    '47024': 'с. Манастирище, общ. Своге, обл. София област',
    '48129': 'с. Миланово, общ. Своге, обл. София област',
    '53374': 'с. Огоя, общ. Своге, обл. София област',
    '53610': 'с. Оплетня, общ. Своге, обл. София област',
    '54122': 'с. Осеновлаг, общ. Своге, обл. София област',
    '62387': 'с. Реброво, общ. Своге, обл. София област',
    '62414': 'с. Редина, общ. Своге, обл. София област',
    '65663': 'с. Свидня, общ. Своге, обл. София област',
    '72655': 'с. Томпсън, общ. Своге, обл. София област',
    '78402': 'с. Церецел, общ. Своге, обл. София област',
    '78481': 'с. Церово, общ. Своге, обл. София област',
    '87028': 'с. Ябланица, общ. Своге, обл. София област',
    '67372': 'гр. Сливница, общ. Сливница, обл. София област',
    '00223': 'с. Алдомировци, общ. Сливница, обл. София област',
    '02926': 'с. Бахалин, общ. Сливница, обл. София област',
    '06175': 'с. Братушково, общ. Сливница, обл. София област',
    '07541': 'с. Бърложница, общ. Сливница, обл. София област',
    '18133': 'с. Гургулят, общ. Сливница, обл. София област',
    '18294': 'с. Гълъбовци, общ. Сливница, обл. София област',
    '23474': 'с. Драготинци, общ. Сливница, обл. София област',
    '32411': 'с. Извор, общ. Сливница, обл. София област',
    '56558': 'с. Пищане, общ. Сливница, обл. София област',
    '56914': 'с. Повалиръж, общ. Сливница, обл. София област',
    '61621': 'с. Радуловци, общ. Сливница, обл. София област',
    '61964': 'с. Ракита, общ. Сливница, обл. София област',
    '80011': 'с. Чавдар, общ. Чавдар, обл. София област',
    '80323': 'с. Челопеч, общ. Челопеч, обл. София област',
    '06183': 'с. Братя Даскалови, общ. Братя Даскалови, обл. Стара Загора',
    '10673': 'с. Верен, общ. Братя Даскалови, обл. Стара Загора',
    '15744': 'с. Голям дол, общ. Братя Даскалови, обл. Стара Загора',
    '16691': 'с. Горно Белево, общ. Братя Даскалови, обл. Стара Загора',
    '16852': 'с. Горно ново село, общ. Братя Даскалови, обл. Стара Загора',
    '17717': 'с. Гранит, общ. Братя Даскалови, обл. Стара Загора',
    '22736': 'с. Долно ново село, общ. Братя Даскалови, обл. Стара Загора',
    '38100': 'с. Колю Мариново, общ. Братя Даскалови, обл. Стара Загора',
    '46588': 'с. Малко Дряново, общ. Братя Даскалови, обл. Стара Загора',
    '49998': 'с. Малък дол, общ. Братя Даскалови, обл. Стара Загора',
    '47305': 'с. Марково, общ. Братя Даскалови, обл. Стара Загора',
    '47665': 'с. Медово, общ. Братя Даскалови, обл. Стара Загора',
    '48372': 'с. Мирово, общ. Братя Даскалови, обл. Стара Загора',
    '51072': 'с. Найденово, общ. Братя Даскалови, обл. Стара Загора',
    '53624': 'с. Опълченец, общ. Братя Даскалови, обл. Стара Загора',
    '53850': 'с. Оризово, общ. Братя Даскалови, обл. Стара Загора',
    '55484': 'с. Партизанин, общ. Братя Даскалови, обл. Стара Загора',
    '56811': 'с. Плодовитово, общ. Братя Даскалови, обл. Стара Загора',
    '58075': 'с. Православ, общ. Братя Даскалови, обл. Стара Загора',
    '67074': 'с. Славянин, общ. Братя Даскалови, обл. Стара Загора',
    '70531': 'с. Съединение, общ. Братя Даскалови, обл. Стара Загора',
    '70562': 'с. Сърневец, общ. Братя Даскалови, обл. Стара Загора',
    '80793': 'с. Черна гора, общ. Братя Даскалови, обл. Стара Загора',
    '18157': 'гр. Гурково, общ. Гурково, обл. Стара Загора',
    '06478': 'с. Брестова, общ. Гурково, обл. Стара Загора',
    '20211': 'с. Дворище, общ. Гурково, обл. Стара Загора',
    '21124': 'с. Димовци, общ. Гурково, обл. Стара Загора',
    '29297': 'с. Жерговец, общ. Гурково, обл. Стара Загора',
    '29595': 'с. Жълтопоп, общ. Гурково, обл. Стара Загора',
    '31022': 'с. Златирът, общ. Гурково, обл. Стара Загора',
    '38203': 'с. Конаре, общ. Гурково, обл. Стара Загора',
    '44776': 'с. Лява река, общ. Гурково, обл. Стара Загора',
    '22767': 'с. Паничерево, общ. Гурково, обл. Стара Загора',
    '58894': 'с. Пчелиново, общ. Гурково, обл. Стара Загора',
    '18280': 'гр. Гълъбово, общ. Гълъбово, обл. Стара Загора',
    '00552': 'с. Априлово, общ. Гълъбово, обл. Стара Загора',
    '10416': 'с. Великово, общ. Гълъбово, обл. Стара Загора',
    '14951': 'с. Главан, общ. Гълъбово, обл. Стара Загора',
    '32857': 'с. Искрица, общ. Гълъбово, обл. Стара Загора',
    '47603': 'с. Медникарово, общ. Гълъбово, обл. Стара Загора',
    '49391': 'с. Мусачево, общ. Гълъбово, обл. Стара Загора',
    '49535': 'с. Мъдрец, общ. Гълъбово, обл. Стара Загора',
    '53134': 'с. Обручище, общ. Гълъбово, обл. Стара Загора',
    '57515': 'с. Помощник, общ. Гълъбово, обл. Стара Загора',
    '61755': 'с. Разделна, общ. Гълъбово, обл. Стара Загора',
    '35167': 'гр. Казанлък, общ. Казанлък, обл. Стара Загора',
    '40292': 'гр. Крън, общ. Казанлък, обл. Стара Загора',
    '83199': 'гр. Шипка, общ. Казанлък, обл. Стара Загора',
    '06848': 'с. Бузовград, общ. Казанлък, обл. Стара Загора',
    '15864': 'с. Голямо Дряново, общ. Казанлък, обл. Стара Загора',
    '16780': 'с. Горно Изворово, общ. Казанлък, обл. Стара Загора',
    '49076': 'с. Горно Черковище, общ. Казанлък, обл. Стара Загора',
    '22633': 'с. Долно Изворово, общ. Казанлък, обл. Стара Загора',
    '24075': 'с. Дунавци, общ. Казанлък, обл. Стара Загора',
    '27499': 'с. Енина, общ. Казанлък, обл. Стара Загора',
    '38563': 'с. Копринка, общ. Казанлък, обл. Стара Загора',
    '40868': 'с. Кънчево, общ. Казанлък, обл. Стара Загора',
    '53179': 'с. Овощник, общ. Казанлък, обл. Стара Загора',
    '62983': 'с. Розово, общ. Казанлък, обл. Стара Загора',
    '63570': 'с. Ръжена, общ. Казанлък, обл. Стара Загора',
    '68446': 'с. Средногорово, общ. Казанлък, обл. Стара Загора',
    '77027': 'с. Хаджидимитрово, общ. Казанлък, обл. Стара Загора',
    '80532': 'с. Черганово, общ. Казанлък, обл. Стара Загора',
    '83106': 'с. Шейново, общ. Казанлък, обл. Стара Загора',
    '87641': 'с. Ясеново, общ. Казанлък, обл. Стара Загора',
    '49494': 'гр. Мъглиж, общ. Мъглиж, обл. Стара Загора',
    '05671': 'с. Борущица, общ. Мъглиж, обл. Стара Загора',
    '07380': 'с. Бънзарето, общ. Мъглиж, обл. Стара Загора',
    '10848': 'с. Ветрен, общ. Мъглиж, обл. Стара Загора',
    '24342': 'с. Дъбово, общ. Мъглиж, обл. Стара Загора',
    '24637': 'с. Държавен, общ. Мъглиж, обл. Стара Загора',
    '30870': 'с. Зимница, общ. Мъглиж, обл. Стара Загора',
    '61635': 'с. Радунци, общ. Мъглиж, обл. Стара Загора',
    '66103': 'с. Селце, общ. Мъглиж, обл. Стара Загора',
    '67341': 'с. Сливито, общ. Мъглиж, обл. Стара Загора',
    '73420': 'с. Тулово, общ. Мъглиж, обл. Стара Загора',
    '83020': 'с. Шаново, общ. Мъглиж, обл. Стара Загора',
    '86043': 'с. Юлиево, общ. Мъглиж, обл. Стара Загора',
    '87148': 'с. Яворовец, общ. Мъглиж, обл. Стара Загора',
    '87212': 'с. Ягода, общ. Мъглиж, обл. Стара Загора',
    '51648': 'гр. Николаево, общ. Николаево, обл. Стара Загора',
    '27070': 'с. Едрево, общ. Николаево, обл. Стара Загора',
    '48163': 'с. Елхово, общ. Николаево, обл. Стара Загора',
    '51888': 'с. Нова махала, общ. Николаево, обл. Стара Загора',
    '03023': 'с. Бащино, общ. Опан, обл. Стара Загора',
    '07750': 'с. Бял извор, общ. Опан, обл. Стара Загора',
    '07819': 'с. Бяло поле, общ. Опан, обл. Стара Загора',
    '10224': 'с. Васил Левски, общ. Опан, обл. Стара Загора',
    '10639': 'с. Венец, общ. Опан, обл. Стара Загора',
    '61791': 'с. Княжевско, общ. Опан, обл. Стара Загора',
    '39222': 'с. Кравино, общ. Опан, обл. Стара Загора',
    '53576': 'с. Опан, общ. Опан, обл. Стара Загора',
    '59166': 'с. Пъстрен, общ. Опан, обл. Стара Загора',
    '68312': 'с. Средец, общ. Опан, обл. Стара Загора',
    '69434': 'с. Столетово, общ. Опан, обл. Стара Загора',
    '72970': 'с. Тракия, общ. Опан, обл. Стара Загора',
    '87713': 'с. Ястребово, общ. Опан, обл. Стара Загора',
    '55021': 'гр. Павел баня, общ. Павел баня, обл. Стара Загора',
    '00309': 'с. Александрово, общ. Павел баня, обл. Стара Загора',
    '00697': 'с. Асен, общ. Павел баня, обл. Стара Загора',
    '10954': 'с. Виден, общ. Павел баня, обл. Стара Загора',
    '14026': 'с. Габарево, общ. Павел баня, обл. Стара Загора',
    '16924': 'с. Горно Сахране, общ. Павел баня, обл. Стара Загора',
    '22808': 'с. Долно Сахране, общ. Павел баня, обл. Стара Загора',
    '47101': 'с. Манолово, общ. Павел баня, обл. Стара Загора',
    '54153': 'с. Осетеново, общ. Павел баня, обл. Стара Загора',
    '66826': 'с. Скобелево, общ. Павел баня, обл. Стара Загора',
    '73451': 'с. Турия, общ. Павел баня, обл. Стара Загора',
    '73540': 'с. Тъжа, общ. Павел баня, обл. Стара Загора',
    '73688': 'с. Търничени, общ. Павел баня, обл. Стара Загора',
    '61460': 'гр. Раднево, общ. Раднево, обл. Стара Загора',
    '03407': 'с. Бели бряг, общ. Раднево, обл. Стара Загора',
    '17960': 'с. Боздуганово, общ. Раднево, обл. Стара Загора',
    '07288': 'с. Българене, общ. Раднево, обл. Стара Загора',
    '15062': 'с. Гледачево, общ. Раднево, обл. Стара Загора',
    '20153': 'с. Даскал-Атанасово, общ. Раднево, обл. Стара Загора',
    '21209': 'с. Диня, общ. Раднево, обл. Стара Загора',
    '30795': 'с. Землен, общ. Раднево, обл. Стара Загора',
    '31293': 'с. Знаменосец, общ. Раднево, обл. Стара Загора',
    '37455': 'с. Ковач, общ. Раднево, обл. Стара Загора',
    '37507': 'с. Ковачево, общ. Раднево, обл. Стара Загора',
    '38008': 'с. Коларово, общ. Раднево, обл. Стара Загора',
    '38340': 'с. Константиновец, общ. Раднево, обл. Стара Загора',
    '44540': 'с. Любеново, общ. Раднево, обл. Стара Загора',
    '47500': 'с. Маца, общ. Раднево, обл. Стара Загора',
    '53268': 'с. Овчарци, общ. Раднево, обл. Стара Загора',
    '57323': 'с. Полски Градец, общ. Раднево, обл. Стара Загора',
    '62712': 'с. Рисиманово, общ. Раднево, обл. Стара Загора',
    '65838': 'с. Свободен, общ. Раднево, обл. Стара Загора',
    '70586': 'с. Сърнево, общ. Раднево, обл. Стара Загора',
    '72477': 'с. Тихомирово, общ. Раднево, обл. Стара Загора',
    '72824': 'с. Тополяне, общ. Раднево, обл. Стара Загора',
    '73225': 'с. Трояново, общ. Раднево, обл. Стара Загора',
    '73314': 'с. Трънково, общ. Раднево, обл. Стара Загора',
    '68850': 'гр. Стара Загора, общ. Стара Загора, обл. Стара Загора',
    '00655': 'с. Арнаутито, общ. Стара Загора, обл. Стара Загора',
    '03856': 'с. Бенковски, общ. Стара Загора, обл. Стара Загора',
    '04738': 'с. Богомилово, общ. Стара Загора, обл. Стара Загора',
    '05431': 'с. Борилово, общ. Стара Загора, обл. Стара Загора',
    '05637': 'с. Борово, общ. Стара Загора, обл. Стара Загора',
    '06197': 'с. Братя Кунчеви, общ. Стара Загора, обл. Стара Загора',
    '07199': 'с. Бъдеще, общ. Стара Загора, обл. Стара Загора',
    '11675': 'с. Воденичарово, общ. Стара Загора, обл. Стара Загора',
    '16701': 'с. Горно Ботево, общ. Стара Загора, обл. Стара Загора',
    '24482': 'с. Дълбоки, общ. Стара Загора, обл. Стара Загора',
    '27214': 'с. Еленино, общ. Стара Загора, обл. Стара Загора',
    '27379': 'с. Елхово, общ. Стара Загора, обл. Стара Загора',
    '30119': 'с. Загоре, общ. Стара Загора, обл. Стара Загора',
    '31276': 'с. Змейово, общ. Стара Загора, обл. Стара Загора',
    '35153': 'с. Казанка, общ. Стара Загора, обл. Стара Загора',
    '35420': 'с. Калитиново, общ. Стара Загора, обл. Стара Загора',
    '35515': 'с. Калояновец, общ. Стара Загора, обл. Стара Загора',
    '36899': 'с. Кирилово, общ. Стара Загора, обл. Стара Загора',
    '37678': 'с. Козаревец, общ. Стара Загора, обл. Стара Загора',
    '38039': 'с. Колена, общ. Стара Загора, обл. Стара Загора',
    '43921': 'с. Ловец, общ. Стара Загора, обл. Стара Загора',
    '63149': 'с. Лозен, общ. Стара Загора, обл. Стара Загора',
    '44687': 'с. Люляк, общ. Стара Загора, обл. Стара Загора',
    '44851': 'с. Лясково, общ. Стара Загора, обл. Стара Загора',
    '46098': 'с. Маджерито, общ. Стара Загора, обл. Стара Загора',
    '46417': 'с. Малка Верея, общ. Стара Загора, обл. Стара Загора',
    '46591': 'с. Малко Кадиево, общ. Стара Загора, обл. Стара Загора',
    '48502': 'с. Михайлово, общ. Стара Загора, обл. Стара Загора',
    '48765': 'с. Могила, общ. Стара Загора, обл. Стара Загора',
    '52252': 'с. Ново село, общ. Стара Загора, обл. Стара Загора',
    '54016': 'с. Оряховица, общ. Стара Загора, обл. Стара Загора',
    '54314': 'с. Остра могила, общ. Стара Загора, обл. Стара Загора',
    '55289': 'с. Памукчии, общ. Стара Загора, обл. Стара Загора',
    '56188': 'с. Петрово, общ. Стара Загора, обл. Стара Загора',
    '56825': 'с. Плоска могила, общ. Стара Загора, обл. Стара Загора',
    '57073': 'с. Подслон, общ. Стара Загора, обл. Стара Загора',
    '58236': 'с. Преславен, общ. Стара Загора, обл. Стара Загора',
    '58743': 'с. Пряпорец, общ. Стара Загора, обл. Стара Загора',
    '58952': 'с. Пшеничево, общ. Стара Загора, обл. Стара Загора',
    '59170': 'с. Пъстрово, общ. Стара Загора, обл. Стара Загора',
    '61995': 'с. Ракитница, общ. Стара Загора, обл. Стара Загора',
    '63301': 'с. Руманя, общ. Стара Загора, обл. Стара Загора',
    '65317': 'с. Самуилово, общ. Стара Загора, обл. Стара Загора',
    '67154': 'с. Сладък кладенец, общ. Стара Загора, обл. Стара Загора',
    '68970': 'с. Старозагорски бани, общ. Стара Загора, обл. Стара Загора',
    '69794': 'с. Стрелец, общ. Стара Загора, обл. Стара Загора',
    '70202': 'с. Сулица, общ. Стара Загора, обл. Стара Загора',
    '68730': 'с. Хан Аспарухово, общ. Стара Загора, обл. Стара Загора',
    '77431': 'с. Християново, общ. Стара Загора, обл. Стара Загора',
    '77476': 'с. Хрищени, общ. Стара Загора, обл. Стара Загора',
    '87182': 'с. Яворово, общ. Стара Загора, обл. Стара Загора',
    '81414': 'гр. Чирпан, общ. Чирпан, обл. Стара Загора',
    '11082': 'с. Винарово, общ. Чирпан, обл. Стара Загора',
    '12042': 'с. Воловарово, общ. Чирпан, обл. Стара Загора',
    '14920': 'с. Гита, общ. Чирпан, обл. Стара Загора',
    '21049': 'с. Димитриево, общ. Чирпан, обл. Стара Загора',
    '24623': 'с. Държава, общ. Чирпан, обл. Стара Загора',
    '30819': 'с. Зетьово, общ. Чирпан, обл. Стара Загора',
    '31070': 'с. Златна ливада, общ. Чирпан, обл. Стара Загора',
    '32456': 'с. Изворово, общ. Чирпан, обл. Стара Загора',
    '46658': 'с. Малко Тръново, общ. Чирпан, обл. Стара Загора',
    '48831': 'с. Могилово, общ. Чирпан, обл. Стара Загора',
    '54242': 'с. Осларка, общ. Чирпан, обл. Стара Загора',
    '63344': 'с. Рупките, общ. Чирпан, обл. Стара Загора',
    '65810': 'с. Свобода, общ. Чирпан, обл. Стара Загора',
    '68182': 'с. Спасово, общ. Чирпан, обл. Стара Загора',
    '68463': 'с. Средно градище, общ. Чирпан, обл. Стара Загора',
    '69496': 'с. Стоян-Заимово, общ. Чирпан, обл. Стара Загора',
    '78330': 'с. Целина, общ. Чирпан, обл. Стара Загора',
    '78375': 'с. Ценово, общ. Чирпан, обл. Стара Загора',
    '87254': 'с. Яздач, общ. Чирпан, обл. Стара Загора',
    '00518': 'гр. Антоново, общ. Антоново, обл. Търговище',
    '02628': 'с. Банковец, общ. Антоново, обл. Търговище',
    '04755': 'с. Богомолско, общ. Антоново, обл. Търговище',
    '06919': 'с. Букак, общ. Антоново, обл. Търговище',
    '10433': 'с. Великовци, общ. Антоново, обл. Търговище',
    '10584': 'с. Вельово, общ. Антоново, обл. Търговище',
    '15045': 'с. Глашатай, общ. Антоново, обл. Търговище',
    '15850': 'с. Голямо Доляне, общ. Антоново, обл. Търговище',
    '16211': 'с. Горна Златица, общ. Антоново, обл. Търговище',
    '17840': 'с. Греевци, общ. Антоново, обл. Търговище',
    '20479': 'с. Девино, общ. Антоново, обл. Търговище',
    '21275': 'с. Длъжка поляна, общ. Антоново, обл. Търговище',
    '21703': 'с. Добротица, общ. Антоново, обл. Търговище',
    '22099': 'с. Долна Златица, общ. Антоново, обл. Търговище',
    '24428': 'с. Дъбравица, общ. Антоново, обл. Търговище',
    '32473': 'с. Изворово, общ. Антоново, обл. Търговище',
    '35448': 'с. Калнище, общ. Антоново, обл. Търговище',
    '36169': 'с. Капище, общ. Антоново, обл. Търговище',
    '37040': 'с. Китино, общ. Антоново, обл. Търговище',
    '38323': 'с. Коноп, общ. Антоново, обл. Търговище',
    '39368': 'с. Крайполе, общ. Антоново, обл. Търговище',
    '40258': 'с. Крушолак, общ. Антоново, обл. Търговище',
    '40871': 'с. Къпинец, общ. Антоново, обл. Търговище',
    '41099': 'с. Кьосевци, общ. Антоново, обл. Търговище',
    '44598': 'с. Любичево, общ. Антоново, обл. Търговище',
    '46468': 'с. Малка Черковна, общ. Антоново, обл. Търговище',
    '46735': 'с. Малоградец, общ. Антоново, обл. Търговище',
    '47130': 'с. Манушевци, общ. Антоново, обл. Търговище',
    '48026': 'с. Мечово, общ. Антоново, обл. Търговище',
    '48194': 'с. Милино, общ. Антоново, обл. Търговище',
    '49045': 'с. Моравица, общ. Антоново, обл. Търговище',
    '49059': 'с. Моравка, общ. Антоново, обл. Търговище',
    '53641': 'с. Орач, общ. Антоново, обл. Търговище',
    '56424': 'с. Пиринец, общ. Антоново, обл. Търговище',
    '57827': 'с. Поройно, общ. Антоново, обл. Търговище',
    '58476': 'с. Присойна, общ. Антоново, обл. Търговище',
    '58949': 'с. Пчелно, общ. Антоново, обл. Търговище',
    '61251': 'с. Равно село, общ. Антоново, обл. Търговище',
    '61769': 'с. Разделци, общ. Антоново, обл. Търговище',
    '65752': 'с. Свирчово, общ. Антоново, обл. Търговище',
    '65855': 'с. Свободица, общ. Антоново, обл. Търговище',
    '66175': 'с. Семерци, общ. Антоново, обл. Търговище',
    '67492': 'с. Слънчовец, общ. Антоново, обл. Търговище',
    '68881': 'с. Стара речка, общ. Антоново, обл. Търговище',
    '69136': 'с. Старчище, общ. Антоново, обл. Търговище',
    '69146': 'с. Стеврек, общ. Антоново, обл. Търговище',
    '69376': 'с. Стойново, общ. Антоново, обл. Търговище',
    '69897': 'с. Стройновци, общ. Антоново, обл. Търговище',
    '72062': 'с. Таймище, общ. Антоново, обл. Търговище',
    '72452': 'с. Тиховец, общ. Антоново, обл. Търговище',
    '73078': 'с. Трескавец, общ. Антоново, обл. Търговище',
    '77120': 'с. Халваджийско, общ. Антоново, обл. Търговище',
    '80279': 'с. Чеканци, общ. Антоново, обл. Търговище',
    '80786': 'с. Черна вода, общ. Антоново, обл. Търговище',
    '80892': 'с. Черни бряг, общ. Антоново, обл. Търговище',
    '83332': 'с. Шишковица, общ. Антоново, обл. Търговище',
    '87271': 'с. Язовец, общ. Антоново, обл. Търговище',
    '87521': 'с. Яребично, общ. Антоново, обл. Търговище',
    '87686': 'с. Ястребино, общ. Антоново, обл. Търговище',
    '53535': 'гр. Омуртаг, общ. Омуртаг, обл. Търговище',
    '03647': 'с. Беломорци, общ. Омуртаг, обл. Търговище',
    '07243': 'с. Българаново, общ. Омуртаг, обл. Търговище',
    '10392': 'с. Великденче, общ. Омуртаг, обл. Търговище',
    '10481': 'с. Величка, общ. Омуртаг, обл. Търговище',
    '10687': 'с. Веренци, общ. Омуртаг, обл. Търговище',
    '10728': 'с. Веселец, общ. Омуртаг, обл. Търговище',
    '11212': 'с. Висок, общ. Омуртаг, обл. Търговище',
    '12156': 'с. Врани кон, общ. Омуртаг, обл. Търговище',
    '15922': 'с. Голямо църквище, общ. Омуртаг, обл. Търговище',
    '16420': 'с. Горна Хубавка, общ. Омуртаг, обл. Търговище',
    '16818': 'с. Горно Козарево, общ. Омуртаг, обл. Търговище',
    '16849': 'с. Горно Новково, общ. Омуртаг, обл. Търговище',
    '17230': 'с. Горско село, общ. Омуртаг, обл. Търговище',
    '22280': 'с. Долна Хубавка, общ. Омуртаг, обл. Търговище',
    '22664': 'с. Долно Козарево, общ. Омуртаг, обл. Търговище',
    '22719': 'с. Долно Новково, общ. Омуртаг, обл. Търговище',
    '31416': 'с. Звездица, общ. Омуртаг, обл. Търговище',
    '30692': 'с. Зелена морава, общ. Омуртаг, обл. Търговище',
    '31262': 'с. Змейно, общ. Омуртаг, обл. Търговище',
    '32620': 'с. Илийно, общ. Омуртаг, обл. Търговище',
    '35643': 'с. Камбурово, общ. Омуртаг, обл. Търговище',
    '36806': 'с. Кестеново, общ. Омуртаг, обл. Търговище',
    '37811': 'с. Козма презвитер, общ. Омуртаг, обл. Търговище',
    '39596': 'с. Красноселци, общ. Омуртаг, обл. Търговище',
    '48790': 'с. Могилец, общ. Омуртаг, обл. Търговище',
    '53062': 'с. Обител, общ. Омуртаг, обл. Търговище',
    '55327': 'с. Панайот Хитово, общ. Омуртаг, обл. Търговище',
    '55381': 'с. Паничино, общ. Омуртаг, обл. Търговище',
    '56112': 'с. Петрино, общ. Омуртаг, обл. Търговище',
    '56856': 'с. Плъстина, общ. Омуртаг, обл. Търговище',
    '58774': 'с. Птичево, общ. Омуртаг, обл. Търговище',
    '58972': 'с. Пъдарино, общ. Омуртаг, обл. Търговище',
    '59029': 'с. Първан, общ. Омуртаг, обл. Търговище',
    '63104': 'с. Росица, общ. Омуртаг, обл. Търговище',
    '63639': 'с. Рътлина, общ. Омуртаг, обл. Търговище',
    '68761': 'с. Станец, общ. Омуртаг, обл. Търговище',
    '73609': 'с. Тъпчилещово, общ. Омуртаг, обл. Търговище',
    '75013': 'с. Угледно, общ. Омуртаг, обл. Търговище',
    '78166': 'с. Царевци, общ. Омуртаг, обл. Търговище',
    '78450': 'с. Церовище, общ. Омуртаг, обл. Търговище',
    '81147': 'с. Чернокапци, общ. Омуртаг, обл. Търговище',
    '53552': 'гр. Опака, общ. Опака, обл. Търговище',
    '15833': 'с. Голямо градище, общ. Опака, обл. Търговище',
    '17172': 'с. Горско Абланово, общ. Опака, обл. Търговище',
    '18407': 'с. Гърчиново, общ. Опака, обл. Търговище',
    '39671': 'с. Крепча, общ. Опака, обл. Търговище',
    '44608': 'с. Люблен, общ. Опака, обл. Търговище',
    '57649': 'гр. Попово, общ. Попово, обл. Търговище',
    '00566': 'с. Априлово, общ. Попово, обл. Търговище',
    '02021': 'с. Баба Тонка, общ. Попово, обл. Търговище',
    '03931': 'с. Берковски, общ. Попово, обл. Търговище',
    '06046': 'с. Бракница, общ. Попово, обл. Търговище',
    '11716': 'с. Водица, общ. Попово, обл. Търговище',
    '14307': 'с. Гагово, общ. Попово, обл. Търговище',
    '15103': 'с. Глогинка, общ. Попово, обл. Търговище',
    '16081': 'с. Горица, общ. Попово, обл. Търговище',
    '21937': 'с. Долец, общ. Попово, обл. Търговище',
    '22109': 'с. Долна Кабда, общ. Попово, обл. Търговище',
    '23738': 'с. Дриново, общ. Попово, обл. Търговище',
    '27259': 'с. Еленово, общ. Попово, обл. Търговище',
    '30079': 'с. Заветно, общ. Попово, обл. Търговище',
    '30332': 'с. Зараево, общ. Попово, обл. Търговище',
    '30404': 'с. Захари Стояново, общ. Попово, обл. Търговище',
    '30449': 'с. Звезда, общ. Попово, обл. Търговище',
    '32189': 'с. Иванча, общ. Попово, обл. Търговище',
    '36470': 'с. Кардам, общ. Попово, обл. Търговище',
    '37469': 'с. Ковачевец, общ. Попово, обл. Търговище',
    '37748': 'с. Козица, общ. Попово, обл. Търговище',
    '38193': 'с. Конак, общ. Попово, обл. Търговище',
    '44286': 'с. Ломци, общ. Попово, обл. Търговище',
    '47007': 'с. Манастирица, общ. Попово, обл. Търговище',
    '47411': 'с. Марчино, общ. Попово, обл. Търговище',
    '47634': 'с. Медовина, общ. Попово, обл. Търговище',
    '54208': 'с. Осиково, общ. Попово, обл. Търговище',
    '55213': 'с. Паламарца, общ. Попово, обл. Търговище',
    '57501': 'с. Помощица, общ. Попово, обл. Търговище',
    '57875': 'с. Посабина, общ. Попово, обл. Търговище',
    '65067': 'с. Садина, общ. Попово, обл. Търговище',
    '65557': 'с. Светлен, общ. Попово, обл. Търговище',
    '67091': 'с. Славяново, общ. Попово, обл. Търговище',
    '73376': 'с. Тръстика, общ. Попово, обл. Търговище',
    '78077': 'с. Цар Асен, общ. Попово, обл. Търговище',
    '73626': 'гр. Търговище, общ. Търговище, обл. Търговище',
    '00215': 'с. Алваново, общ. Търговище, обл. Търговище',
    '00312': 'с. Александрово, общ. Търговище, обл. Търговище',
    '03037': 'с. Баячево, общ. Търговище, обл. Търговище',
    '04159': 'с. Бистра, общ. Търговище, обл. Търговище',
    '05030': 'с. Божурка, общ. Търговище, обл. Търговище',
    '06166': 'с. Братово, общ. Търговище, обл. Търговище',
    '06882': 'с. Буйново, общ. Търговище, обл. Търговище',
    '07154': 'с. Буховци, общ. Търговище, обл. Търговище',
    '10121': 'с. Вардун, общ. Търговище, обл. Търговище',
    '10238': 'с. Васил Левски, общ. Търговище, обл. Търговище',
    '15895': 'с. Голямо ново, общ. Търговище, обл. Търговище',
    '15919': 'с. Голямо Соколово, общ. Търговище, обл. Търговище',
    '16225': 'с. Горна Кабда, общ. Търговище, обл. Търговище',
    '20050': 'с. Давидово, общ. Търговище, обл. Търговище',
    '23087': 'с. Драгановец, общ. Търговище, обл. Търговище',
    '23546': 'с. Дралфа, общ. Търговище, обл. Търговище',
    '24520': 'с. Дългач, общ. Търговище, обл. Търговище',
    '30644': 'с. Здравец, общ. Търговище, обл. Търговище',
    '38501': 'с. Копрец, общ. Търговище, обл. Търговище',
    '39195': 'с. Кошничари, общ. Търговище, обл. Търговище',
    '39390': 'с. Кралево, общ. Търговище, обл. Търговище',
    '40395': 'с. Кръшно, общ. Търговище, обл. Търговище',
    '43685': 'с. Лиляк, общ. Търговище, обл. Търговище',
    '43935': 'с. Ловец, общ. Търговище, обл. Търговище',
    '46190': 'с. Макариополско, общ. Търговище, обл. Търговище',
    '46228': 'с. Маково, общ. Търговище, обл. Търговище',
    '48091': 'с. Миладиновци, общ. Търговище, обл. Търговище',
    '48338': 'с. Мировец, общ. Търговище, обл. Търговище',
    '48920': 'с. Момино, общ. Търговище, обл. Търговище',
    '51024': 'с. Надарево, общ. Търговище, обл. Търговище',
    '53223': 'с. Овчарово, общ. Търговище, обл. Търговище',
    '54081': 'с. Осен, общ. Търговище, обл. Търговище',
    '54345': 'с. Острец, общ. Търговище, обл. Търговище',
    '55201': 'с. Пайдушко, общ. Търговище, обл. Търговище',
    '55662': 'с. Певец, общ. Търговище, обл. Търговище',
    '57008': 'с. Подгорица, общ. Търговище, обл. Търговище',
    '58195': 'с. Преселец, общ. Търговище, обл. Търговище',
    '58219': 'с. Пресиян, общ. Търговище, обл. Търговище',
    '58298': 'с. Пресяк, общ. Търговище, обл. Търговище',
    '58493': 'с. Пробуда, общ. Търговище, обл. Търговище',
    '58579': 'с. Пролаз, общ. Търговище, обл. Търговище',
    '61676': 'с. Разбойна, общ. Търговище, обл. Търговище',
    '62164': 'с. Ралица, общ. Търговище, обл. Търговище',
    '63077': 'с. Росина, общ. Търговище, обл. Търговище',
    '63241': 'с. Руец, общ. Търговище, обл. Търговище',
    '69599': 'с. Стража, общ. Търговище, обл. Търговище',
    '70545': 'с. Съединение, общ. Търговище, обл. Търговище',
    '72148': 'с. Твърдинци, общ. Търговище, обл. Търговище',
    '73701': 'с. Търновца, общ. Търговище, обл. Търговище',
    '78297': 'с. Цветница, общ. Търговище, обл. Търговище',
    '80741': 'с. Черковна, общ. Търговище, обл. Търговище',
    '21052': 'гр. Димитровград, общ. Димитровград, обл. Хасково',
    '47843': 'гр. Меричлери, общ. Димитровград, обл. Хасково',
    '04844': 'с. Бодрово, общ. Димитровград, обл. Хасково',
    '06547': 'с. Брод, общ. Димитровград, обл. Хасково',
    '06762': 'с. Бряст, общ. Димитровград, обл. Хасково',
    '10375': 'с. Великан, общ. Димитровград, обл. Хасково',
    '11644': 'с. Воден, общ. Димитровград, обл. Хасково',
    '76087': 'с. Върбица, общ. Димитровград, обл. Хасково',
    '15792': 'с. Голямо Асеново, общ. Димитровград, обл. Хасково',
    '17141': 'с. Горски извор, общ. Димитровград, обл. Хасково',
    '21258': 'с. Длъгнево, общ. Димитровград, обл. Хасково',
    '21539': 'с. Добрич, общ. Димитровград, обл. Хасково',
    '22561': 'с. Долно Белево, общ. Димитровград, обл. Хасково',
    '30658': 'с. Здравец, общ. Димитровград, обл. Хасково',
    '31156': 'с. Златополе, общ. Димитровград, обл. Хасково',
    '36573': 'с. Каснаково, общ. Димитровград, обл. Хасково',
    '39668': 'с. Крепост, общ. Димитровград, обл. Хасково',
    '39966': 'с. Крум, общ. Димитровград, обл. Хасково',
    '46543': 'с. Малко Асеново, общ. Димитровград, обл. Хасково',
    '61368': 'с. Радиево, общ. Димитровград, обл. Хасково',
    '61889': 'с. Райново, общ. Димитровград, обл. Хасково',
    '65574': 'с. Светлина, общ. Димитровград, обл. Хасково',
    '66831': 'с. Скобелево, общ. Димитровград, обл. Хасково',
    '68669': 'с. Сталево, общ. Димитровград, обл. Хасково',
    '69691': 'с. Странско, общ. Димитровград, обл. Хасково',
    '81092': 'с. Черногорово, общ. Димитровград, обл. Хасково',
    '87076': 'с. Ябълково, общ. Димитровград, обл. Хасково',
    '14060': 'с. Габерово, общ. Маджарово, обл. Хасково',
    '32024': 'гр. Ивайловград, общ. Ивайловград, обл. Хасково',
    '03424': 'с. Бели дол, общ. Ивайловград, обл. Хасково',
    '03681': 'с. Белополци, общ. Ивайловград, обл. Хасково',
    '03695': 'с. Белополяне, общ. Ивайловград, обл. Хасково',
    '05904': 'с. Ботурче, общ. Ивайловград, обл. Хасково',
    '06625': 'с. Брусино, общ. Ивайловград, обл. Хасково',
    '06817': 'с. Бубино, общ. Ивайловград, обл. Хасково',
    '07733': 'с. Бялградец, общ. Ивайловград, обл. Хасково',
    '10906': 'с. Ветрушка, общ. Ивайловград, обл. Хасково',
    '11199': 'с. Вис, общ. Ивайловград, обл. Хасково',
    '15179': 'с. Глумово, общ. Ивайловград, обл. Хасково',
    '15240': 'с. Гнездаре, общ. Ивайловград, обл. Хасково',
    '16835': 'с. Горно Луково, общ. Ивайловград, обл. Хасково',
    '16941': 'с. Горноселци, общ. Ивайловград, обл. Хасково',
    '17169': 'с. Горско, общ. Ивайловград, обл. Хасково',
    '18054': 'с. Гугутка, общ. Ивайловград, обл. Хасково',
    '22705': 'с. Долно Луково, общ. Ивайловград, обл. Хасково',
    '22825': 'с. Долноселци, общ. Ивайловград, обл. Хасково',
    '23056': 'с. Драбишна, общ. Ивайловград, обл. Хасково',
    '29074': 'с. Железари, общ. Ивайловград, обл. Хасково',
    '29101': 'с. Железино, общ. Ивайловград, обл. Хасково',
    '35143': 'с. Казак, общ. Ивайловград, обл. Хасково',
    '35997': 'с. Камилски дол, общ. Ивайловград, обл. Хасково',
    '36508': 'с. Карловско, общ. Ивайловград, обл. Хасково',
    '37410': 'с. Кобилино, общ. Ивайловград, обл. Хасково',
    '38248': 'с. Кондово, общ. Ивайловград, обл. Хасково',
    '38317': 'с. Конници, общ. Ивайловград, обл. Хасково',
    '38964': 'с. Костилково, общ. Ивайловград, обл. Хасково',
    '43128': 'с. Ламбух, общ. Ивайловград, обл. Хасково',
    '43308': 'с. Ленско, общ. Ивайловград, обл. Хасково',
    '47069': 'с. Мандрица, общ. Ивайловград, обл. Хасково',
    '47545': 'с. Меден бук, общ. Ивайловград, обл. Хасково',
    '51857': 'с. Нова ливада, общ. Ивайловград, обл. Хасково',
    '53429': 'с. Одринци, общ. Ивайловград, обл. Хасково',
    '53789': 'с. Орешино, общ. Ивайловград, обл. Хасково',
    '55628': 'с. Пашкул, общ. Ивайловград, обл. Хасково',
    '56633': 'с. Планинец, общ. Ивайловград, обл. Хасково',
    '55748': 'с. Плевун, общ. Ивайловград, обл. Хасково',
    '57145': 'с. Покрован, общ. Ивайловград, обл. Хасково',
    '57769': 'с. Попско, общ. Ивайловград, обл. Хасково',
    '59197': 'с. Пъстроок, общ. Ивайловград, обл. Хасково',
    '62935': 'с. Розино, общ. Ивайловград, обл. Хасково',
    '65485': 'с. Сборино, общ. Ивайловград, обл. Хасково',
    '65704': 'с. Свирачи, общ. Ивайловград, обл. Хасково',
    '66398': 'с. Сив кладенец, общ. Ивайловград, обл. Хасково',
    '66932': 'с. Славеево, общ. Ивайловград, обл. Хасково',
    '67862': 'с. Соколенци, общ. Ивайловград, обл. Хасково',
    '77520': 'с. Хухла, общ. Ивайловград, обл. Хасково',
    '80995': 'с. Черни рид, общ. Ивайловград, обл. Хасково',
    '81044': 'с. Черничино, общ. Ивайловград, обл. Хасково',
    '81829': 'с. Чучулига, общ. Ивайловград, обл. Хасково',
    '44570': 'гр. Любимец, общ. Любимец, обл. Хасково',
    '03544': 'с. Белица, общ. Любимец, обл. Хасково',
    '10286': 'с. Васково, общ. Любимец, обл. Хасково',
    '12560': 'с. Вълче поле, общ. Любимец, обл. Хасково',
    '14787': 'с. Георги Добрево, общ. Любимец, обл. Хасково',
    '24311': 'с. Дъбовец, общ. Любимец, обл. Хасково',
    '34014': 'с. Йерусалимово, общ. Любимец, обл. Хасково',
    '44077': 'с. Лозен, общ. Любимец, обл. Хасково',
    '46574': 'с. Малко градище, общ. Любимец, обл. Хасково',
    '54033': 'с. Оряхово, общ. Любимец, обл. Хасково',
    '46084': 'гр. Маджарово, общ. Маджарово, обл. Хасково',
    '05503': 'с. Бориславци, общ. Маджарово, обл. Хасково',
    '06584': 'с. Брусевци, общ. Маджарово, обл. Хасково',
    '15698': 'с. Голяма долина, общ. Маджарово, обл. Хасково',
    '16496': 'с. Горни Главанак, общ. Маджарово, обл. Хасково',
    '16907': 'с. Горно поле, общ. Маджарово, обл. Хасково',
    '22375': 'с. Долни Главанак, общ. Маджарово, обл. Хасково',
    '22856': 'с. Долно Съдиево, общ. Маджарово, обл. Хасково',
    '27663': 'с. Ефрем, общ. Маджарово, обл. Хасково',
    '31173': 'с. Златоустово, общ. Маджарово, обл. Хасково',
    '46499': 'с. Малки Воден, общ. Маджарово, обл. Хасково',
    '46557': 'с. Малко Брягово, общ. Маджарово, обл. Хасково',
    '46632': 'с. Малко Попово, общ. Маджарово, обл. Хасково',
    '63313': 'с. Румелия, общ. Маджарово, обл. Хасково',
    '63584': 'с. Ръженово, общ. Маджарово, обл. Хасково',
    '66086': 'с. Селска поляна, общ. Маджарово, обл. Хасково',
    '66233': 'с. Сеноклас, общ. Маджарово, обл. Хасково',
    '72792': 'с. Тополово, общ. Маджарово, обл. Хасково',
    '00446': 'с. Ангел войвода, общ. Минерални бани, обл. Хасково',
    '05983': 'с. Боян Ботево, общ. Минерални бани, обл. Хасково',
    '06803': 'с. Брястово, общ. Минерални бани, обл. Хасково',
    '11106': 'с. Винево, общ. Минерални бани, обл. Хасково',
    '36325': 'с. Караманци, общ. Минерални бани, обл. Хасково',
    '38042': 'с. Колец, общ. Минерални бани, обл. Хасково',
    '48297': 'с. Минерални бани, общ. Минерални бани, обл. Хасково',
    '66634': 'с. Сираково, общ. Минерални бани, обл. Хасково',
    '68237': 'с. Спахиево, общ. Минерални бани, обл. Хасково',
    '70250': 'с. Сусам, общ. Минерални бани, обл. Хасково',
    '70651': 'с. Сърница, общ. Минерални бани, обл. Хасково',
    '72103': 'с. Татарево, общ. Минерални бани, обл. Хасково',
    '65677': 'гр. Свиленград, общ. Свиленград, обл. Хасково',
    '10152': 'с. Варник, общ. Свиленград, обл. Хасково',
    '14708': 'с. Генералово, общ. Свиленград, обл. Хасково',
    '20674': 'с. Дервишка могила, общ. Свиленград, обл. Хасково',
    '21078': 'с. Димитровче, общ. Свиленград, обл. Хасково',
    '36110': 'с. Капитан Андреево, общ. Свиленград, обл. Хасково',
    '39001': 'с. Костур, общ. Свиленград, обл. Хасково',
    '43205': 'с. Левка, общ. Свиленград, обл. Хасково',
    '43877': 'с. Лисово, общ. Свиленград, обл. Хасково',
    '47468': 'с. Маточина, общ. Свиленград, обл. Хасково',
    '47737': 'с. Мезек, общ. Свиленград, обл. Хасково',
    '48533': 'с. Михалич, общ. Свиленград, обл. Хасково',
    '48698': 'с. Младиново, общ. Свиленград, обл. Хасково',
    '48979': 'с. Момково, общ. Свиленград, обл. Хасково',
    '49446': 'с. Мустрак, общ. Свиленград, обл. Хасково',
    '55645': 'с. Пашово, общ. Свиленград, обл. Хасково',
    '59183': 'с. Пъстрогор, общ. Свиленград, обл. Хасково',
    '61131': 'с. Равна гора, общ. Свиленград, обл. Хасково',
    '61844': 'с. Райкова могила, общ. Свиленград, обл. Хасково',
    '66370': 'с. Сива река, общ. Свиленград, обл. Хасково',
    '67146': 'с. Сладун, общ. Свиленград, обл. Хасково',
    '70055': 'с. Студена, общ. Свиленград, обл. Хасково',
    '81116': 'с. Чернодъб, общ. Свиленград, обл. Хасково',
    '84036': 'с. Щит, общ. Свиленград, обл. Хасково',
    '47278': 'гр. Симеоновград, общ. Симеоновград, обл. Хасково',
    '23964': 'с. Дряново, общ. Симеоновград, обл. Хасково',
    '35599': 'с. Калугерово, общ. Симеоновград, обл. Хасково',
    '38368': 'с. Константиново, общ. Симеоновград, обл. Хасково',
    '51010': 'с. Навъсен, общ. Симеоновград, обл. Хасково',
    '59210': 'с. Пясъчево, общ. Симеоновград, обл. Хасково',
    '65721': 'с. Свирково, общ. Симеоновград, обл. Хасково',
    '73208': 'с. Троян, общ. Симеоновград, обл. Хасково',
    '73821': 'с. Тянево, общ. Симеоновград, обл. Хасково',
    '02436': 'с. Балкан, общ. Стамболово, обл. Хасково',
    '07781': 'с. Бял кладенец, общ. Стамболово, обл. Хасково',
    '11692': 'с. Воденци, общ. Стамболово, обл. Хасково',
    '11822': 'с. Войводенец, общ. Стамболово, обл. Хасково',
    '15093': 'с. Гледка, общ. Стамболово, обл. Хасково',
    '15610': 'с. Голобрадово, общ. Стамболово, обл. Хасково',
    '15775': 'с. Голям извор, общ. Стамболово, обл. Хасково',
    '22575': 'с. Долно Ботево, общ. Стамболово, обл. Хасково',
    '22770': 'с. Долно поле, общ. Стамболово, обл. Хасково',
    '22887': 'с. Долно Черковище, общ. Стамболово, обл. Хасково',
    '29550': 'с. Жълти бряг, общ. Стамболово, обл. Хасково',
    '30908': 'с. Зимовина, общ. Стамболово, обл. Хасково',
    '37126': 'с. Кладенец, общ. Стамболово, обл. Хасково',
    '39400': 'с. Кралево, общ. Стамболово, обл. Хасково',
    '44803': 'с. Лясковец, общ. Стамболово, обл. Хасково',
    '46070': 'с. Маджари, общ. Стамболово, обл. Хасково',
    '46899': 'с. Малък извор, общ. Стамболово, обл. Хасково',
    '57618': 'с. Поповец, общ. Стамболово, обл. Хасково',
    '58815': 'с. Пчелари, общ. Стамболово, обл. Хасково',
    '59207': 'с. Пътниково, общ. Стамболово, обл. Хасково',
    '61025': 'с. Рабово, общ. Стамболово, обл. Хасково',
    '65629': 'с. Светослав, общ. Стамболово, обл. Хасково',
    '66411': 'с. Силен, общ. Стамболово, обл. Хасково',
    '68727': 'с. Стамболово, общ. Стамболово, обл. Хасково',
    '73585': 'с. Тънково, общ. Стамболово, обл. Хасково',
    '78094': 'с. Царева поляна, общ. Стамболово, обл. Хасково',
    '72761': 'гр. Тополовград, общ. Тополовград, обл. Хасково',
    '07346': 'с. Българска поляна, общ. Тополовград, обл. Хасково',
    '11435': 'с. Владимирово, общ. Тополовград, обл. Хасково',
    '21659': 'с. Доброселец, общ. Тополовград, обл. Хасково',
    '35866': 'с. Каменна река, общ. Тополовград, обл. Хасково',
    '24013': 'с. Капитан Петко войвода, общ. Тополовград, обл. Хасково',
    '37407': 'с. Княжево, общ. Тополовград, обл. Хасково',
    '49219': 'с. Мрамор, общ. Тополовград, обл. Хасково',
    '53802': 'с. Орешник, общ. Тополовград, обл. Хасково',
    '53895': 'с. Орлов дол, общ. Тополовград, обл. Хасково',
    '56664': 'с. Планиново, общ. Тополовград, обл. Хасково',
    '58428': 'с. Присадец, общ. Тополовград, обл. Хасково',
    '61491': 'с. Радовец, общ. Тополовград, обл. Хасково',
    '65156': 'с. Сакарци, общ. Тополовград, обл. Хасково',
    '65588': 'с. Светлина, общ. Тополовград, обл. Хасково',
    '66487': 'с. Синапово, общ. Тополовград, обл. Хасково',
    '68583': 'с. Срем, общ. Тополовград, обл. Хасково',
    '75191': 'с. Устрем, общ. Тополовград, обл. Хасково',
    '76100': 'с. Филипово, общ. Тополовград, обл. Хасково',
    '77325': 'с. Хлябово, общ. Тополовград, обл. Хасково',
    '81654': 'с. Чукарово, общ. Тополовград, обл. Хасково',
    '77181': 'гр. Харманли, общ. Харманли, обл. Хасково',
    '04128': 'с. Бисер, общ. Харманли, обл. Хасково',
    '04724': 'с. Богомил, общ. Харманли, обл. Хасково',
    '05298': 'с. Болярски извор, общ. Харманли, обл. Хасково',
    '06080': 'с. Браница, общ. Харманли, обл. Хасково',
    '07315': 'с. Българин, общ. Харманли, обл. Хасково',
    '12810': 'с. Върбово, общ. Харманли, обл. Хасково',
    '23011': 'с. Доситеево, общ. Харманли, обл. Хасково',
    '23741': 'с. Дрипчево, общ. Харманли, обл. Хасково',
    '32100': 'с. Иваново, общ. Харманли, обл. Хасково',
    '32487': 'с. Изворово, общ. Харманли, обл. Хасково',
    '38011': 'с. Коларово, общ. Харманли, обл. Хасково',
    '43548': 'с. Лешниково, общ. Харманли, обл. Хасково',
    '51041': 'с. Надежден, общ. Харманли, обл. Хасково',
    '53237': 'с. Овчарово, общ. Харманли, обл. Хасково',
    '53775': 'с. Орешец, общ. Харманли, обл. Хасково',
    '54448': 'с. Остър камък, общ. Харманли, обл. Хасково',
    '57434': 'с. Поляново, общ. Харманли, обл. Хасково',
    '58244': 'с. Преславец, общ. Харманли, обл. Хасково',
    '62832': 'с. Рогозиново, общ. Харманли, обл. Хасково',
    '67101': 'с. Славяново, общ. Харманли, обл. Хасково',
    '67581': 'с. Смирненци, общ. Харманли, обл. Хасково',
    '80552': 'с. Черепово, общ. Харманли, обл. Хасково',
    '80827': 'с. Черна могила, общ. Харманли, обл. Хасково',
    '83377': 'с. Шишманово, общ. Харманли, обл. Хасково',
    '77195': 'гр. Хасково, общ. Хасково, обл. Хасково',
    '00326': 'с. Александрово, общ. Хасково, обл. Хасково',
    '06759': 'с. Брягово, общ. Хасково, обл. Хасково',
    '11867': 'с. Войводово, общ. Хасково, обл. Хасково',
    '12382': 'с. Въгларово, общ. Хасково, обл. Хасково',
    '14550': 'с. Гарваново, общ. Хасково, обл. Хасково',
    '15429': 'с. Големанци, общ. Хасково, обл. Хасково',
    '16729': 'с. Горно Войводино, общ. Хасково, обл. Хасково',
    '18232': 'с. Гълъбец, общ. Хасково, обл. Хасково',
    '21155': 'с. Динево, общ. Хасково, обл. Хасково',
    '22589': 'с. Долно Войводино, общ. Хасково, обл. Хасково',
    '24949': 'с. Долно Големанци, общ. Хасково, обл. Хасково',
    '27200': 'с. Елена, общ. Хасково, обл. Хасково',
    '31365': 'с. Зорница, общ. Хасково, обл. Хасково',
    '37321': 'с. Клокотница, общ. Хасково, обл. Хасково',
    '37383': 'с. Книжовник, общ. Хасково, обл. Хасково',
    '37770': 'с. Козлец, общ. Хасково, обл. Хасково',
    '38399': 'с. Конуш, общ. Хасково, обл. Хасково',
    '38580': 'с. Корен, общ. Хасково, обл. Хасково',
    '39863': 'с. Криво поле, общ. Хасково, обл. Хасково',
    '44553': 'с. Любеново, общ. Хасково, обл. Хасково',
    '46293': 'с. Малево, общ. Хасково, обл. Хасково',
    '46992': 'с. Манастир, общ. Хасково, обл. Хасково',
    '47055': 'с. Мандра, общ. Хасково, обл. Хасково',
    '47442': 'с. Маслиново, общ. Хасково, обл. Хасково',
    '48934': 'с. Момино, общ. Хасково, обл. Хасково',
    '51682': 'с. Николово, общ. Хасково, обл. Хасково',
    '51891': 'с. Нова Надежда, общ. Хасково, обл. Хасково',
    '53936': 'с. Орлово, общ. Хасково, обл. Хасково',
    '57042': 'с. Подкрепа, общ. Хасково, обл. Хасково',
    '62880': 'с. Родопи, общ. Хасково, обл. Хасково',
    '68692': 'с. Стамболийски, общ. Хасково, обл. Хасково',
    '69359': 'с. Стойково, общ. Хасково, обл. Хасково',
    '72182': 'с. Текето, общ. Хасково, обл. Хасково',
    '72953': 'с. Тракиец, общ. Хасково, обл. Хасково',
    '75085': 'с. Узунджово, общ. Хасково, обл. Хасково',
    '83288': 'с. Широка поляна, общ. Хасково, обл. Хасково',
    '58222': 'гр. Велики Преслав, общ. Велики Преслав, обл. Шумен',
    '23340': 'с. Драгоево, общ. Велики Преслав, обл. Шумен',
    '30942': 'с. Златар, общ. Велики Преслав, обл. Шумен',
    '32723': 'с. Имренчево, общ. Велики Преслав, обл. Шумен',
    '39133': 'с. Кочово, общ. Велики Преслав, обл. Шумен',
    '48132': 'с. Миланово, общ. Велики Преслав, обл. Шумен',
    '48862': 'с. Мокреш, общ. Велики Преслав, обл. Шумен',
    '49148': 'с. Мостич, общ. Велики Преслав, обл. Шумен',
    '54273': 'с. Осмар, общ. Велики Преслав, обл. Шумен',
    '70278': 'с. Суха река, общ. Велики Преслав, обл. Шумен',
    '73170': 'с. Троица, общ. Велики Преслав, обл. Шумен',
    '78210': 'с. Хан Крум, общ. Велики Преслав, обл. Шумен',
    '05685': 'с. Борци, общ. Венец, обл. Шумен',
    '05966': 'с. Боян, общ. Венец, обл. Шумен',
    '06865': 'с. Буйновица, общ. Венец, обл. Шумен',
    '10642': 'с. Венец, общ. Венец, обл. Шумен',
    '14132': 'с. Габрица, общ. Венец, обл. Шумен',
    '20643': 'с. Денница, общ. Венец, обл. Шумен',
    '23724': 'с. Дренци, общ. Венец, обл. Шумен',
    '32562': 'с. Изгрев, общ. Венец, обл. Шумен',
    '36155': 'с. Капитан Петко, общ. Венец, обл. Шумен',
    '54119': 'с. Осеновец, общ. Венец, обл. Шумен',
    '69729': 'с. Страхилица, общ. Венец, обл. Шумен',
    '81061': 'с. Черноглавци, общ. Венец, обл. Шумен',
    '87610': 'с. Ясенково, общ. Венец, обл. Шумен',
    '12766': 'гр. Върбица, общ. Върбица, обл. Шумен',
    '05075': 'с. Божурово, общ. Върбица, обл. Шумен',
    '07692': 'с. Бяла река, общ. Върбица, обл. Шумен',
    '32113': 'с. Иваново, общ. Върбица, обл. Шумен',
    '38306': 'с. Конево, общ. Върбица, обл. Шумен',
    '39298': 'с. Крайгорци, общ. Върбица, обл. Шумен',
    '41054': 'с. Кьолмен, общ. Върбица, обл. Шумен',
    '43949': 'с. Ловец, общ. Върбица, обл. Шумен',
    '46773': 'с. Маломир, общ. Върбица, обл. Шумен',
    '47785': 'с. Менгишево, общ. Върбица, обл. Шумен',
    '47915': 'с. Методиево, общ. Върбица, обл. Шумен',
    '51785': 'с. Нова бяла река, общ. Върбица, обл. Шумен',
    '68847': 'с. Станянци, общ. Върбица, обл. Шумен',
    '70398': 'с. Сушина, общ. Върбица, обл. Шумен',
    '73537': 'с. Тушовица, общ. Върбица, обл. Шумен',
    '81222': 'с. Чернооково, общ. Върбица, обл. Шумен',
    '36079': 'гр. Каолиново, общ. Каолиново, обл. Шумен',
    '06094': 'с. Браничево, общ. Каолиново, обл. Шумен',
    '18188': 'с. Гусла, общ. Каолиново, обл. Шумен',
    '21811': 'с. Дойранци, общ. Каолиново, обл. Шумен',
    '21960': 'с. Долина, общ. Каолиново, обл. Шумен',
    '30137': 'с. Загориче, общ. Каолиново, обл. Шумен',
    '37232': 'с. Климент, общ. Каолиново, обл. Шумен',
    '43846': 'с. Лиси връх, общ. Каолиново, обл. Шумен',
    '44865': 'с. Лятно, общ. Каолиново, обл. Шумен',
    '51158': 'с. Наум, общ. Каолиново, обл. Шумен',
    '53521': 'с. Омарчево, общ. Каолиново, обл. Шумен',
    '58485': 'с. Пристое, общ. Каолиново, обл. Шумен',
    '66531': 'с. Сини вир, общ. Каолиново, обл. Шумен',
    '68388': 'с. Средковец, общ. Каолиново, обл. Шумен',
    '72549': 'с. Тодор Икономово, общ. Каолиново, обл. Шумен',
    '73554': 'с. Тъкач, общ. Каолиново, обл. Шумен',
    '36587': 'гр. Каспичан, общ. Каспичан, обл. Шумен',
    '56770': 'гр. Плиска, общ. Каспичан, обл. Шумен',
    '12838': 'с. Върбяне, общ. Каспичан, обл. Шумен',
    '31084': 'с. Златна нива, общ. Каспичан, обл. Шумен',
    '36590': 'с. Каспичан, общ. Каспичан, обл. Шумен',
    '38804': 'с. Косово, общ. Каспичан, обл. Шумен',
    '41109': 'с. Кюлевча, общ. Каспичан, обл. Шумен',
    '47319': 'с. Марково, общ. Каспичан, обл. Шумен',
    '48773': 'с. Могила, общ. Каспичан, обл. Шумен',
    '10330': 'с. Векилски, общ. Никола Козлево, обл. Шумен',
    '12509': 'с. Вълнари, общ. Никола Козлево, обл. Шумен',
    '36194': 'с. Каравелово, общ. Никола Козлево, обл. Шумен',
    '39548': 'с. Красен дол, общ. Никола Козлево, обл. Шумен',
    '39760': 'с. Крива река, общ. Никола Козлево, обл. Шумен',
    '51651': 'с. Никола Козлево, общ. Никола Козлево, обл. Шумен',
    '56071': 'с. Пет могили, общ. Никола Козлево, обл. Шумен',
    '63269': 'с. Ружица, общ. Никола Козлево, обл. Шумен',
    '77582': 'с. Хърсово, общ. Никола Козлево, обл. Шумен',
    '78032': 'с. Цани Гинчево, общ. Никола Козлево, обл. Шумен',
    '78656': 'с. Църквица, общ. Никола Козлево, обл. Шумен',
    '52009': 'гр. Нови пазар, общ. Нови пазар, обл. Шумен',
    '03126': 'с. Беджене, общ. Нови пазар, обл. Шумен',
    '11819': 'с. Войвода, общ. Нови пазар, обл. Шумен',
    '27471': 'с. Енево, общ. Нови пазар, обл. Шумен',
    '29391': 'с. Жилино, общ. Нови пазар, обл. Шумен',
    '30274': 'с. Зайчино ореше, общ. Нови пазар, обл. Шумен',
    '32353': 'с. Избул, общ. Нови пазар, обл. Шумен',
    '48386': 'с. Мировци, общ. Нови пазар, обл. Шумен',
    '55292': 'с. Памукчии, общ. Нови пазар, обл. Шумен',
    '56486': 'с. Писарево, общ. Нови пазар, обл. Шумен',
    '58027': 'с. Правенци, общ. Нови пазар, обл. Шумен',
    '58205': 'с. Преселка, общ. Нови пазар, обл. Шумен',
    '66353': 'с. Сечище, общ. Нови пазар, обл. Шумен',
    '68744': 'с. Стан, общ. Нови пазар, обл. Шумен',
    '69506': 'с. Стоян Михайловски, общ. Нови пазар, обл. Шумен',
    '73300': 'с. Тръница, общ. Нови пазар, обл. Шумен',
    '67708': 'гр. Смядово, общ. Смядово, обл. Шумен',
    '00330': 'с. Александрово, общ. Смядово, обл. Шумен',
    '07729': 'с. Бял бряг, общ. Смядово, обл. Шумен',
    '10762': 'с. Веселиново, общ. Смядово, обл. Шумен',
    '29177': 'с. Желъд, общ. Смядово, обл. Шумен',
    '40840': 'с. Кълново, общ. Смядово, обл. Шумен',
    '52310': 'с. Ново Янково, общ. Смядово, обл. Шумен',
    '62732': 'с. Риш, общ. Смядово, обл. Шумен',
    '80950': 'с. Черни връх, общ. Смядово, обл. Шумен',
    '87429': 'с. Янково, общ. Смядово, обл. Шумен',
    '02244': 'с. Байково, общ. Хитрино, обл. Шумен',
    '04430': 'с. Близнаци, общ. Хитрино, обл. Шумен',
    '11257': 'с. Висока поляна, общ. Хитрино, обл. Шумен',
    '12663': 'с. Върбак, общ. Хитрино, обл. Шумен',
    '21289': 'с. Длъжко, общ. Хитрино, обл. Шумен',
    '21405': 'с. Добри Войниково, общ. Хитрино, обл. Шумен',
    '27067': 'с. Единаковци, общ. Хитрино, обл. Шумен',
    '29341': 'с. Живково, общ. Хитрино, обл. Шумен',
    '30421': 'с. Звегор, общ. Хитрино, обл. Шумен',
    '32250': 'с. Иглика, общ. Хитрино, обл. Шумен',
    '35417': 'с. Калино, общ. Хитрино, обл. Шумен',
    '35969': 'с. Каменяк, общ. Хитрино, обл. Шумен',
    '61697': 'с. Развигорово, общ. Хитрино, обл. Шумен',
    '67283': 'с. Сливак, общ. Хитрино, обл. Шумен',
    '68802': 'с. Становец, общ. Хитрино, обл. Шумен',
    '70099': 'с. Студеница, общ. Хитрино, обл. Шумен',
    '72285': 'с. Тервел, общ. Хитрино, обл. Шумен',
    '72401': 'с. Тимарево, общ. Хитрино, обл. Шумен',
    '73050': 'с. Трем, общ. Хитрино, обл. Шумен',
    '14516': 'с. Хитрино, общ. Хитрино, обл. Шумен',
    '80772': 'с. Черна, общ. Хитрино, обл. Шумен',
    '83510': 'гр. Шумен, общ. Шумен, обл. Шумен',
    '03633': 'с. Белокопитово, общ. Шумен, обл. Шумен',
    '04265': 'с. Благово, общ. Шумен, обл. Шумен',
    '10176': 'с. Васил Друмев, общ. Шумен, обл. Шумен',
    '10464': 'с. Велино, общ. Шумен, обл. Шумен',
    '10882': 'с. Ветрище, общ. Шумен, обл. Шумен',
    '10923': 'с. Вехтово, общ. Шумен, обл. Шумен',
    '17573': 'с. Градище, общ. Шумен, обл. Шумен',
    '20938': 'с. Дибич, общ. Шумен, обл. Шумен',
    '23813': 'с. Друмево, общ. Шумен, обл. Шумен',
    '32158': 'с. Ивански, общ. Шумен, обл. Шумен',
    '32706': 'с. Илия Блъсково, общ. Шумен, обл. Шумен',
    '37135': 'с. Кладенец, общ. Шумен, обл. Шумен',
    '41188': 'с. Коньовец, общ. Шумен, обл. Шумен',
    '38892': 'с. Костена река, общ. Шумен, обл. Шумен',
    '44032': 'с. Лозево, общ. Шумен, обл. Шумен',
    '46053': 'с. Мадара, общ. Шумен, обл. Шумен',
    '47161': 'с. Мараш, общ. Шумен, обл. Шумен',
    '52132': 'с. Новосел, общ. Шумен, обл. Шумен',
    '53240': 'с. Овчарово, общ. Шумен, обл. Шумен',
    '55316': 'с. Панайот Волово, общ. Шумен, обл. Шумен',
    '61443': 'с. Радко Димитриево, общ. Шумен, обл. Шумен',
    '65187': 'с. Салманово, общ. Шумен, обл. Шумен',
    '68504': 'с. Средня, общ. Шумен, обл. Шумен',
    '69924': 'с. Струино, общ. Шумен, обл. Шумен',
    '78104': 'с. Царев брод, общ. Шумен, обл. Шумен',
    '80546': 'с. Черенча, общ. Шумен, обл. Шумен',
    '05284': 'гр. Болярово, общ. Болярово, обл. Ямбол',
    '11658': 'с. Воден, общ. Болярово, обл. Ямбол',
    '12588': 'с. Вълчи извор, общ. Болярово, обл. Ямбол',
    '15881': 'с. Голямо Крушево, общ. Болярово, обл. Ямбол',
    '17097': 'с. Горска поляна, общ. Болярово, обл. Ямбол',
    '20657': 'с. Денница, общ. Болярово, обл. Ямбол',
    '24356': 'с. Дъбово, общ. Болярово, обл. Ямбол',
    '31019': 'с. Златиница, общ. Болярово, обл. Ямбол',
    '32264': 'с. Иглика, общ. Болярово, обл. Ямбол',
    '35756': 'с. Камен връх, общ. Болярово, обл. Ямбол',
    '39356': 'с. Крайново, общ. Болярово, обл. Ямбол',
    '46704': 'с. Малко Шарково, общ. Болярово, обл. Ямбол',
    '46958': 'с. Мамарчево, общ. Болярово, обл. Ямбол',
    '53504': 'с. Оман, общ. Болярово, обл. Ямбол',
    '57652': 'с. Попово, общ. Болярово, обл. Ямбол',
    '63272': 'с. Ружица, общ. Болярово, обл. Ямбол',
    '66679': 'с. Ситово, общ. Болярово, обл. Ямбол',
    '69208': 'с. Стефан Караджово, общ. Болярово, обл. Ямбол',
    '69674': 'с. Странджа, общ. Болярово, обл. Ямбол',
    '83051': 'с. Шарково, общ. Болярово, обл. Ямбол',
    '27382': 'гр. Елхово, общ. Елхово, обл. Ямбол',
    '05520': 'с. Борисово, общ. Елхово, обл. Ямбол',
    '06001': 'с. Бояново, общ. Елхово, обл. Ямбол',
    '12530': 'с. Вълча поляна, общ. Елхово, обл. Ямбол',
    '15730': 'с. Голям Дервент, общ. Елхово, обл. Ямбол',
    '17748': 'с. Гранитово, общ. Елхово, обл. Ямбол',
    '21542': 'с. Добрич, общ. Елхово, обл. Ямбол',
    '29516': 'с. Жребино, общ. Елхово, обл. Ямбол',
    '32576': 'с. Изгрев, общ. Елхово, обл. Ямбол',
    '36909': 'с. Кирилово, общ. Елхово, обл. Ямбол',
    '43116': 'с. Лалково, общ. Елхово, обл. Ямбол',
    '43459': 'с. Лесово, общ. Елхово, обл. Ямбол',
    '46615': 'с. Малко Кирилово, общ. Елхово, обл. Ямбол',
    '46797': 'с. Маломирово, общ. Елхово, обл. Ямбол',
    '46904': 'с. Малък манастир, общ. Елхово, обл. Ямбол',
    '47768': 'с. Мелница, общ. Елхово, обл. Ямбол',
    '58801': 'с. Пчела, общ. Елхово, обл. Ямбол',
    '61738': 'с. Раздел, общ. Елхово, обл. Ямбол',
    '66980': 'с. Славейково, общ. Елхово, обл. Ямбол',
    '69883': 'с. Стройно, общ. Елхово, обл. Ямбол',
    '73328': 'с. Трънково, общ. Елхово, обл. Ямбол',
    '81121': 'с. Чернозем, общ. Елхово, обл. Ямбол',
    '69660': 'гр. Стралджа, общ. Стралджа, обл. Ямбол',
    '00343': 'с. Александрово, общ. Стралджа, обл. Ямбол',
    '00816': 'с. Атолово, общ. Стралджа, обл. Ямбол',
    '04786': 'с. Богорово, общ. Стралджа, обл. Ямбол',
    '11661': 'с. Воденичане, общ. Стралджа, обл. Ямбол',
    '11908': 'с. Войника, общ. Стралджа, обл. Ямбол',
    '20804': 'с. Джинот, общ. Стралджа, обл. Ямбол',
    '30898': 'с. Зимница, общ. Стралджа, обл. Ямбол',
    '32771': 'с. Иречеково, общ. Стралджа, обл. Ямбол',
    '35794': 'с. Каменец, общ. Стралджа, обл. Ямбол',
    '43615': 'с. Леярово, общ. Стралджа, обл. Ямбол',
    '44118': 'с. Лозенец, общ. Стралджа, обл. Ямбол',
    '44666': 'с. Люлин, общ. Стралджа, обл. Ямбол',
    '46303': 'с. Маленово, общ. Стралджа, обл. Ямбол',
    '51384': 'с. Недялско, общ. Стралджа, обл. Ямбол',
    '55244': 'с. Палаузово, общ. Стралджа, обл. Ямбол',
    '57409': 'с. Поляна, общ. Стралджа, обл. Ямбол',
    '58003': 'с. Правдино, общ. Стралджа, обл. Ямбол',
    '59046': 'с. Първенец, общ. Стралджа, обл. Ямбол',
    '65406': 'с. Саранско, общ. Стралджа, обл. Ямбол',
    '72076': 'с. Тамарино, общ. Стралджа, обл. Ямбол',
    '80220': 'с. Чарда, общ. Стралджа, обл. Ямбол',
    '00758': 'с. Асеново, общ. Тунджа, обл. Ямбол',
    '03229': 'с. Безмер, общ. Тунджа, обл. Ямбол',
    '05308': 'с. Болярско, общ. Тунджа, обл. Ямбол',
    '05863': 'с. Ботево, общ. Тунджа, обл. Ямбол',
    '05952': 'с. Бояджик, общ. Тунджа, обл. Ямбол',
    '10776': 'с. Веселиново, общ. Тунджа, обл. Ямбол',
    '10985': 'с. Видинци, общ. Тунджа, обл. Ямбол',
    '32740': 'с. Генерал Инзово, общ. Тунджа, обл. Ямбол',
    '14725': 'с. Генерал Тошево, общ. Тунджа, обл. Ямбол',
    '15789': 'с. Голям манастир, общ. Тунджа, обл. Ямбол',
    '18259': 'с. Гълъбинци, общ. Тунджа, обл. Ямбол',
    '23501': 'с. Дражево, общ. Тунджа, обл. Ямбол',
    '23557': 'с. Драма, общ. Тунджа, обл. Ямбол',
    '23978': 'с. Дряново, общ. Тунджа, обл. Ямбол',
    '30096': 'с. Завой, общ. Тунджа, обл. Ямбол',
    '30956': 'с. Златари, общ. Тунджа, обл. Ямбол',
    '35028': 'с. Кабиле, общ. Тунджа, обл. Ямбол',
    '35609': 'с. Калчево, общ. Тунджа, обл. Ямбол',
    '36200': 'с. Каравелово, общ. Тунджа, обл. Ямбол',
    '37681': 'с. Козарево, общ. Тунджа, обл. Ямбол',
    '38279': 'с. Коневец, общ. Тунджа, обл. Ямбол',
    '40018': 'с. Крумово, общ. Тунджа, обл. Ямбол',
    '40484': 'с. Кукорево, общ. Тунджа, обл. Ямбол',
    '46783': 'с. Маломир, общ. Тунджа, обл. Ямбол',
    '47562': 'с. Меден кладенец, общ. Тунджа, обл. Ямбол',
    '47682': 'с. Межда, общ. Тунджа, обл. Ямбол',
    '48101': 'с. Миладиновци, общ. Тунджа, обл. Ямбол',
    '48787': 'с. Могила, общ. Тунджа, обл. Ямбол',
    '53299': 'с. Овчи кладенец, общ. Тунджа, обл. Ямбол',
    '53480': 'с. Окоп, общ. Тунджа, обл. Ямбол',
    '56873': 'с. Победа, общ. Тунджа, обл. Ямбол',
    '62757': 'с. Робово, общ. Тунджа, обл. Ямбол',
    '62921': 'с. Роза, общ. Тунджа, обл. Ямбол',
    '66456': 'с. Симеоново, общ. Тунджа, обл. Ямбол',
    '66737': 'с. Скалица, общ. Тунджа, обл. Ямбол',
    '67177': 'с. Сламино, общ. Тунджа, обл. Ямбол',
    '68878': 'с. Стара река, общ. Тунджа, обл. Ямбол',
    '72240': 'с. Тенево, общ. Тунджа, обл. Ямбол',
    '73657': 'с. Търнава, общ. Тунджа, обл. Ямбол',
    '77030': 'с. Хаджидимитрово, общ. Тунджа, обл. Ямбол',
    '77150': 'с. Ханово, общ. Тунджа, обл. Ямбол',
    '80217': 'с. Чарган, общ. Тунджа, обл. Ямбол',
    '80306': 'с. Челник, общ. Тунджа, обл. Ямбол',
    '87374': 'гр. Ямбол, общ. Ямбол, обл. Ямбол',
};

const constructLocaleEKATTE = (_locale, index) => {
    return {
        ekatte: Object.keys(ekatteKeys).reduce((acc, key) => {
            acc[key] = ekatteKeys[key][index];
            return acc;
        }, {})
    };
};

export const ekatteMessages = Object.keys(ekatteKeys).map(key => {
    const fullLabel = `${key} | ${ekatteKeys[key]}`;
    const searchLabel = ekatteKeys[key].split(',')[0].toLowerCase();
    return {
        value: key,
        label: fullLabel,
        searchLabel: searchLabel
    };
});

export const location = Object.keys(ekatteKeys).map(key => {
    const fullLabel = `${key} | ${ekatteKeys[key]}`;
    const searchLabel = ekatteKeys[key].split(',')[0].toLowerCase();
    return {
        value: key,
        label: fullLabel,
        searchLabel: searchLabel,
        displayLabel: ekatteKeys[key]
    };
});

export const cityOnlyKeys = Object.entries(ekatteKeys).map(([key, value]) => ({
    value: key,
    label: value.split(',')[0].trim(),
    searchLabel: `${key} | ${value.split(',')[0].toLowerCase()}`
})).reduce((unique, item) => {
    const exists = unique.some(city => city.label === item.label);
    return exists ? unique : [...unique, item];
}, []);


