export var bitwiseOr = function (roleValues) {
    var rolesDisjunction = 0;
    // Traverse the array compute AND
    for (var _i = 0, roleValues_1 = roleValues; _i < roleValues_1.length; _i++) {
        var roleValue = roleValues_1[_i];
        rolesDisjunction = (rolesDisjunction | roleValue);
    }
    return rolesDisjunction;
};
export function isAccessibleFor(principal, accessibilityRolesSum) {
    if (!principal) {
        return false;
    }
    var role = accessibilityRoles[principal.role];
    return (role & accessibilityRolesSum) === role;
}
export function isNotAccessibleFor(principal, accessibilityRolesSum) {
    if (!principal) {
        return false;
    }
    var role = accessibilityRoles[principal.role];
    return (role & ~accessibilityRolesSum) === role;
}
export var accessibilityRoles = {
    PATIENT: 1,
    RECEPTIONIST: 2,
    MEDICAL_ASSISTANT: 4,
    MEDICAL_DOCTOR: 8,
    CLINIC_ADMIN: 16,
    PLATFORM_ADMINISTRATOR: 32,
};
