var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect } from "react";
import { Card } from "antd";
import { isMobileOnly } from "react-device-detect";
import DashboardMobile from "../components/dashboard/DashboardMobile";
import DashboardDesktop from "../components/dashboard/DashboardDesktop";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
import { DentobookApplicationContext } from "../context/DentobookApplicationContext";
import { useNavigate } from "react-router-dom";
var Home = function () {
    var navigate = useNavigate();
    var principal = useContext(DentobookApplicationContext).principal;
    useEffect(function () {
        var _a;
        if (((_a = principal === null || principal === void 0 ? void 0 : principal.user) === null || _a === void 0 ? void 0 : _a.role) === 'PLATFORM_ADMINISTRATOR') {
            navigate('/users');
        }
    }, []);
    return (_jsx(AccessControl, __assign({ notAccessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR] }, { children: _jsx(Card, __assign({ bordered: false, className: "dentobook-content" }, { children: isMobileOnly ? _jsx(DashboardMobile, {}) : _jsx(DashboardDesktop, {}) })) })));
};
export default Home;
