var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import '../../assets/css/DesktopLayout.css';
import { SettingOutlined } from "@ant-design/icons";
import { menuItems } from "../../utils/utils";
import { FormattedMessage } from "react-intl";
import { RightHeaderMenu } from "./RightHeaderMenu";
import { AlertContext } from "../../context/AlertContext";
import { bitwiseOr, isAccessibleFor, isNotAccessibleFor } from "../../utils/accessUtils";
import { DentobookApplicationContext } from "../../context/DentobookApplicationContext";
import CustomAlert from "./CustomAlert";
import PoliciesLinks from "./PoliciesLinks";
import DentobookLink from "../common/DentobookLink";
export var DesktopLayout = function () {
    var principal = useContext(DentobookApplicationContext).principal;
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    var _b = useState(''), error = _b[0], setError = _b[1];
    var _c = useState(''), successMessage = _c[0], setSuccessMessage = _c[1];
    var _d = useState(''), redirectUrl = _d[0], setRedirectUrl = _d[1];
    var _e = useState([{}]), menuItemsArr = _e[0], setMenuItemsArr = _e[1];
    var location = useLocation();
    var Sider = Layout.Sider;
    useEffect(function () {
        var visibleItems = menuItems.filter(function (item) {
            if (item.accessibleFor) {
                return isAccessibleFor(principal === null || principal === void 0 ? void 0 : principal.user, bitwiseOr(item.accessibleFor));
            }
            if (item.notAccessibleFor) {
                return isNotAccessibleFor(principal === null || principal === void 0 ? void 0 : principal.user, bitwiseOr(item.notAccessibleFor));
            }
            return true;
        });
        setMenuItemsArr(visibleItems.map(function (m) {
            var cssClassOne = m.path !== '/' && location.pathname.indexOf(m.path) > -1 ? 'ant-menu-item-selected' : '';
            return {
                key: m.path,
                className: cssClassOne,
                icon: m.bottom ? _jsx(SettingOutlined, {}) : null,
                label: (_jsx(DentobookLink, __assign({ to: m.path }, { children: _jsx(FormattedMessage, { id: m.name }) })))
            };
        }));
    }, [location.pathname, principal]);
    return (_jsx(AlertContext.Provider, __assign({ value: {
            visible: visible,
            setVisible: setVisible,
            error: error,
            setError: setError,
            successMessage: successMessage,
            setSuccessMessage: setSuccessMessage,
            redirectUrl: redirectUrl,
            setRedirectUrl: setRedirectUrl
        } }, { children: _jsxs(Layout, { children: [_jsx(CustomAlert, {}), _jsxs("nav", __assign({ className: "nav" }, { children: [_jsx("div", { children: _jsx("img", { alt: "logo", src: require('../../assets/logo.png'), height: 56 }) }), _jsx("div", { children: _jsx(RightHeaderMenu, {}) })] })), _jsxs(Layout, { children: [_jsx(CustomAlert, {}), _jsxs("nav", __assign({ className: "nav" }, { children: [_jsx("div", { children: _jsx("img", { alt: "logo", src: require('../../assets/logo.png'), height: 56 }) }), _jsx("div", { children: _jsx(RightHeaderMenu, {}) })] })), _jsxs(Layout, { children: [_jsx(PoliciesLinks, {}), _jsx(Sider, __assign({ width: 262, className: "sider-layout" }, { children: _jsx(Menu, { mode: "inline", defaultSelectedKeys: ['/'], selectedKeys: [location.pathname], className: "menu-layout", items: menuItemsArr }) })), _jsx(Layout, __assign({ className: "layout" }, { children: _jsx(Layout, { children: _jsx("div", { children: _jsx(Outlet, {}) }) }) }))] })] })] }) })));
};
