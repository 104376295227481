var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Col, Divider, } from 'antd';
import { accessibilityRoles } from "../../utils/accessUtils";
import AccessControl from "../common/AccessControl";
import ClinicSearchForm from "./ClinicSearchFrom";
import ClinicTreatmentPlanCard from "./cards/ClinicTreatmentPlanCard";
import ClinicProceduresPlanCard from "./cards/ClinicProceduresPlanCard";
var Treatments = function () {
    var toothTab = 0;
    var _a = useState(''), keyword = _a[0], setKeyword = _a[1];
    var _b = useState(false), isTreatmentModalVisible = _b[0], setIsTreatmentModalVisible = _b[1];
    var _c = useState(false), isProcedureModalVisible = _c[0], setIsProcedureModalVisible = _c[1];
    var onAddButtonClick = function () {
        if (toothTab === 0) {
            setIsTreatmentModalVisible(true);
        }
        else if (toothTab === 1) {
            setIsProcedureModalVisible(true);
        }
    };
    var closeModal = function () {
        setIsTreatmentModalVisible(false);
        setIsProcedureModalVisible(false);
    };
    return (_jsxs(AccessControl, __assign({ accessibleFor: [accessibilityRoles.CLINIC_ADMIN, accessibilityRoles.MEDICAL_DOCTOR, accessibilityRoles.MEDICAL_ASSISTANT, accessibilityRoles.RECEPTIONIST] }, { children: [_jsx(Col, { children: _jsx(ClinicSearchForm, { keyword: keyword, setKeyword: setKeyword, loadData: function () { }, onAddButtonClick: onAddButtonClick }) }), _jsxs("div", __assign({ className: "dentobook-content" }, { children: [_jsx(Divider, { style: { marginTop: -1 } }), toothTab === 0 ?
                        _jsx(ClinicTreatmentPlanCard, { showModal: isTreatmentModalVisible, onClose: function () { return closeModal(); }, keyword: keyword }) :
                        _jsx(ClinicProceduresPlanCard, { showModal: isProcedureModalVisible, onClose: function () { return closeModal(); }, keyword: keyword })] }))] })));
};
export default Treatments;
