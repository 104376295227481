var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { Button, Col, Divider, Row, Space } from "antd";
import React, { useContext } from "react";
import '../../assets/css/Dentobook.css';
import { isMobileOnly } from 'react-device-detect';
import { EnvironmentOutlined, UserOutlined } from "@ant-design/icons";
import UserIcon from "../common/UserIcon";
import FormButtons from "../common/FormButtons";
import parse from 'html-react-parser';
import { MEDICAL_PHOTO_URL_PREFIX, PHOTO_URL_SUFFIX } from "../common/Constants";
import { allCountries } from "../../i18n/countries";
import { DentobookApplicationContext } from "../../context/DentobookApplicationContext";
var MedicalStaffDetailsView = function (_a) {
    var _b;
    var medicalStaff = _a.medicalStaff, setEditMode = _a.setEditMode;
    var locale = useContext(DentobookApplicationContext).locale;
    var countryObject = allCountries.find(function (country) { return country.code === medicalStaff.country; });
    var countryName = locale === 'BG' ? countryObject === null || countryObject === void 0 ? void 0 : countryObject.name_bg : countryObject === null || countryObject === void 0 ? void 0 : countryObject.name_en;
    return (_jsxs(_Fragment, { children: [_jsxs("h4", { children: [_jsx("span", __assign({ className: "text-blue mr-8" }, { children: _jsx(UserOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "personal_information" })] }), _jsxs(Space, __assign({ direction: isMobileOnly ? "vertical" : "horizontal" }, { children: [!medicalStaff.avatar ? _jsx(UserIcon, { size: isMobileOnly ? "medium" : "large" }) :
                        _jsx("img", { className: "photo-img", width: 150, src: MEDICAL_PHOTO_URL_PREFIX + medicalStaff.id + PHOTO_URL_SUFFIX, alt: "avatar" }), _jsxs(Space, __assign({ direction: "vertical", className: isMobileOnly ? "mb-2" : "ml-56 mb-2 mt-2" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: "name" }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: medicalStaff.firstName + ' ' + (medicalStaff.secondName || '') + ' ' + medicalStaff.lastName })), _jsxs(Space, __assign({ direction: "vertical", className: "mt-24" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: "role" }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: _jsx(FormattedMessage, { id: medicalStaff.role }) }))] }))] })), _jsxs(Space, __assign({ direction: "vertical", className: isMobileOnly ? "" : "ml-56 mb-2 mt-2" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: "uin" }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: (_b = medicalStaff.uin) !== null && _b !== void 0 ? _b : _jsx(FormattedMessage, { id: "not_available" }) })), _jsxs(Space, __assign({ direction: "vertical", className: "mt-24" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: "specialisation" }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: medicalStaff.specialisation ?
                                            _jsx(FormattedMessage, { id: medicalStaff.specialisation }) :
                                            _jsx(FormattedMessage, { id: "not_available" }) }))] }))] }))] })), _jsx(Divider, {}), _jsxs("h4", { children: [_jsx("span", __assign({ className: "text-blue mr-8" }, { children: _jsx(EnvironmentOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "contact" })] }), _jsxs(Space, __assign({ direction: isMobileOnly ? "vertical" : "horizontal" }, { children: [_jsxs(Space, __assign({ direction: "vertical", className: "mt-3" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: "email" }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: medicalStaff.email ? medicalStaff.email :
                                    _jsx(FormattedMessage, { id: "not_available" }) }))] })), _jsxs(Space, __assign({ direction: "vertical", className: isMobileOnly ? "mt-3" : "mt-3 ml-56" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: "phone" }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: medicalStaff.phone ? medicalStaff.phone :
                                    _jsx(FormattedMessage, { id: "not_available" }) }))] })), _jsxs(Space, __assign({ direction: "vertical", className: isMobileOnly ? "mt-3" : "mt-3 ml-56" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: "town" }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: medicalStaff.town ? medicalStaff.town :
                                    _jsx(FormattedMessage, { id: "not_available" }) }))] })), _jsxs(Space, __assign({ direction: "vertical", className: isMobileOnly ? "mt-3" : "mt-3 ml-56" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: "country" }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: medicalStaff.country ? (countryName !== null && countryName !== void 0 ? countryName : medicalStaff.country) :
                                    _jsx(FormattedMessage, { id: "not_available" }) }))] }))] })), _jsx(Row, __assign({ className: "mt-32" }, { children: _jsx(Col, __assign({ md: 24 }, { children: _jsxs(Space, __assign({ direction: "vertical" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: "address" }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: medicalStaff.addressOne ? medicalStaff.addressOne : _jsx(FormattedMessage, { id: "not_available" }) }))] })) })) })), _jsx(Row, __assign({ className: "mt-32 mb-112", style: isMobileOnly ? { marginBottom: 150 } : {} }, { children: _jsx(Col, __assign({ md: 24 }, { children: _jsxs(Space, __assign({ direction: "vertical" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: "notes" }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: medicalStaff.notes ? parse(medicalStaff.notes || "") :
                                    _jsx(FormattedMessage, { id: "not_available" }) }))] })) })) })), _jsx(FormButtons, { children: _jsx(Button, __assign({ onClick: function () { return setEditMode(true); }, className: "edit-btn " + (isMobileOnly ? "mobile-update-btn" : "dentobook-btn-primary"), type: "primary", size: "large", shape: "round" }, { children: _jsx(FormattedMessage, { id: "edit" }) })) })] }));
};
export default MedicalStaffDetailsView;
