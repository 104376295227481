var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PatientForm from "./PatientForm";
import PatientDetailsView from "./PatientDetailsView";
import React, { useContext, useState } from "react";
import { Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { isMobileOnly } from "react-device-detect";
import { CloseOutlined } from "@ant-design/icons";
import { ExitPromptContext } from "../../context/ExitPromptContext";
var PatientDetailsItem = function (_a) {
    var patient = _a.patient, setPatient = _a.setPatient, editMode = _a.editMode, setEditMode = _a.setEditMode;
    var setFormDirty = useContext(ExitPromptContext).setFormDirty;
    var _b = useState(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var initialPatient = __assign({}, patient);
    var handleOk = function () {
        setFormDirty(false);
        setIsModalOpen(false);
        setPatient(initialPatient);
        setEditMode(false);
    };
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    var footer = _jsxs("div", __assign({ className: "modal-btn-wrapper" }, { children: [_jsx(Button, __assign({ className: "modal-btn", onClick: handleCancel }, { children: _jsx(FormattedMessage, { id: "cancel" }) })), _jsx(Button, __assign({ className: "modal-btn", type: "primary", onClick: handleOk }, { children: _jsx(FormattedMessage, { id: "confirm" }) }))] }));
    return (_jsxs(_Fragment, { children: [_jsx(Modal, __assign({ transitionName: "", className: "dentobook-modal", footer: footer, title: _jsx(FormattedMessage, { id: "edit_patient" }), closeIcon: _jsx(CloseOutlined, { className: 'icon-24px', onClick: handleCancel }), open: isModalOpen, onOk: handleOk, onCancel: handleCancel }, { children: _jsx("h3", __assign({ className: "dentobook-content font-weight-500 font-24 text-align-center " + (isMobileOnly ? "mt-112" : "") }, { children: _jsx(FormattedMessage, { id: "unsaved_changes" }) })) })), editMode ?
                _jsx(PatientForm, { mode: 2, setEditMode: setEditMode, openModal: function () { return setIsModalOpen(true); }, patient: patient, setPatient: setPatient })
                :
                    _jsx(PatientDetailsView, { patient: patient, setEditMode: setEditMode })] }));
};
export default PatientDetailsItem;
