import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { MedicalStaffApiFactory, PatientApiFactory, UserApiFactory } from "dentobook-api-typescript-client/api";
import { DentobookApplicationContext } from "../context/DentobookApplicationContext";
var Authentication = function (props) {
    var setPrincipal = useContext(DentobookApplicationContext).setPrincipal;
    var _a = useState(false), isAuthenticated = _a[0], setIsAuthenticated = _a[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var userApi = UserApiFactory(apiConfig, '/api');
    var medicalApi = MedicalStaffApiFactory(apiConfig, '/api');
    var patientApi = PatientApiFactory(apiConfig, '/api');
    var _b = useState(false), isAnonymousPath = _b[0], setIsAnonymousPath = _b[1];
    useEffect(function () {
        var isPathAnonymous = window.location.pathname.indexOf('self-register') > -1;
        setIsAnonymousPath(isPathAnonymous);
        if (isPathAnonymous) {
            setPrincipal({
                user: undefined,
                medicalPerson: undefined,
                patient: undefined
            });
            return;
        }
        userApi.fetchPrincipal()
            .then(function (userResponse) {
            var currentPrincipal = {
                user: userResponse.data,
                medicalPerson: undefined,
                patient: undefined
            };
            if (userResponse.data.medicalStaffId) {
                medicalApi.fetchMedicalStaffById(userResponse.data.medicalStaffId)
                    .then(function (medicalResponse) {
                    return currentPrincipal.medicalPerson = medicalResponse.data;
                });
            }
            else if (userResponse.data.patientId) {
                patientApi.fetchPatient(userResponse.data.patientId)
                    .then(function (patientResponse) {
                    return currentPrincipal.patient = patientResponse.data;
                });
            }
            setPrincipal(currentPrincipal);
            setIsAuthenticated(true);
        })
            .catch(function (error) {
            if (error.code === "ERR_NETWORK") {
                window.location.href = '/oauth2/authorization/login-client/';
            }
            else {
                console.log(error.code);
            }
        });
    }, []);
    if (isAnonymousPath) {
        return (_jsx(_Fragment, { children: props.children }));
    }
    return (_jsx(_Fragment, { children: isAuthenticated &&
            _jsx(_Fragment, { children: props.children }) }));
};
export default Authentication;
