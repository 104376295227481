var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownOutlined, DeleteOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Image, Modal, Row, Select, Upload } from 'antd';
import "../../assets/css/Dentobook.css";
import "../../assets/css/PatientPhotos.css";
import { FormattedMessage, useIntl } from "react-intl";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { PatientApiFactory } from "dentobook-api-typescript-client/api";
import { AlertContext } from "../../context/AlertContext";
import { isMobileOnly } from "react-device-detect";
var Dragger = Upload.Dragger;
var Option = Select.Option;
var PatientPhotos = function (_a) {
    var _b, _c;
    var patient = _a.patient;
    var PHOTO_URL = '/api/patients/photos/';
    var X_RAY_CATEGORY = 'X_RAY';
    var PATIENT_CATEGORY = 'PATIENT';
    var defaultValueArr = (_b = {},
        _b[X_RAY_CATEGORY] = [],
        _b[PATIENT_CATEGORY] = [],
        _b);
    var defaultValueBool = (_c = {},
        _c[X_RAY_CATEGORY] = false,
        _c[PATIENT_CATEGORY] = false,
        _c);
    var intl = useIntl();
    var _d = useContext(AlertContext), setVisible = _d.setVisible, setError = _d.setError;
    var _e = useState(false), isModalOpen = _e[0], setIsModalOpen = _e[1];
    var _f = useState(false), previewButtonsOpen = _f[0], setPreviewButtonsOpen = _f[1];
    var _g = useState(false), isConfirmModalOpen = _g[0], setConfirmModalOpen = _g[1];
    var _h = useState(__assign({}, defaultValueBool)), categoryPreviewVisible = _h[0], setCategoryPreviewVisible = _h[1];
    var _j = useState(false), previewOpen = _j[0], setPreviewOpen = _j[1];
    var previewImage = '';
    var previewTitle = '';
    var _k = useState(__assign({}, defaultValueArr)), photos = _k[0], setPhotos = _k[1];
    var _l = useState(__assign({}, defaultValueBool)), categoryLoading = _l[0], setCategoryLoading = _l[1];
    var _m = useState(__assign({}, defaultValueBool)), categoryError = _m[0], setCategoryError = _m[1];
    var _o = useState('X_RAY'), category = _o[0], setCategory = _o[1];
    var _p = useState([]), uploadQueue = _p[0], setUploadQueue = _p[1];
    var _q = useState(''), selectedAction = _q[0], setSelectedAction = _q[1];
    var _r = useState(''), selectedPhoto = _r[0], setSelectedPhoto = _r[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var patientApi = PatientApiFactory(apiConfig, '/api');
    var props = {
        showUploadList: false,
        name: 'file',
        beforeUpload: function (file, fileList) {
            setUploadQueue(fileList);
            setIsModalOpen(true);
        },
        multiple: true,
        onChange: function (info) {
            var status = info.file.status;
            console.log('file info', info);
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                console.log('upload success');
            }
            else if (status === 'error') {
                console.log('upload error');
            }
        },
        onDrop: function (e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    useEffect(function () {
        loadPhotos();
    }, []);
    var loadPhotos = function () {
        if (!patient.id) {
            return;
        }
        patientApi.fetchPhotoIds(patient.id, {})
            .then(function (res) {
            var photosObj = JSON.parse(JSON.stringify(defaultValueArr));
            res.data.forEach(function (item) {
                photosObj[item.type.toUpperCase()].push({
                    uid: item.id,
                    name: '',
                    status: 'done',
                    url: PHOTO_URL + item.id + '/',
                });
            });
            setPhotos(photosObj);
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    var handleCancel = function () { return setPreviewOpen(false); };
    var handleOk = function () {
        setIsModalOpen(false);
        var categoryObject = __assign({}, categoryLoading);
        categoryObject[category] = true;
        setCategoryLoading(categoryObject);
        uploadFileQueue(uploadQueue);
    };
    var error = false;
    var uploadFileQueue = function (rcFiles) {
        for (var _i = 0, rcFiles_1 = rcFiles; _i < rcFiles_1.length; _i++) {
            var rcFile = rcFiles_1[_i];
            var categoryObject = __assign({}, categoryLoading);
            categoryObject[category] = false;
            setCategoryLoading(categoryObject);
            if (!rcFile.type.endsWith('jpg') && !rcFile.type.endsWith('jpeg') && !rcFile.type.endsWith('png')) {
                setError(intl.formatMessage({ id: 'invalid_image_format' }));
                setTimeout(function () {
                    setVisible(false);
                    setError('');
                }, 10000);
                continue;
            }
            // 12 MB
            if (rcFile.size > 12000000) {
                setError(intl.formatMessage({ id: 'invalid_photo_image_size' }));
                setTimeout(function () {
                    setVisible(false);
                    setError('');
                }, 10000);
                continue;
            }
            patientApi.uploadPhoto(patient.id, category, rcFile, {})
                .then(function (res) {
                var photoObject = __assign({}, photos);
                var photoId = res.data.id;
                photoObject[category].push({
                    uid: photoId,
                    name: '',
                    status: 'done',
                    url: PHOTO_URL + photoId + '/',
                });
                setPhotos(photoObject);
                if (!error) {
                    var categoryObject_1 = __assign({}, categoryError);
                    for (var _i = 0, _a = Object.keys(categoryObject_1); _i < _a.length; _i++) {
                        var key = _a[_i];
                        categoryObject_1[key] = false;
                    }
                    setCategoryError(categoryObject_1);
                    setVisible(true);
                    setTimeout(function () { return setVisible(false); }, 10000);
                }
                error = false;
            })
                .catch(function (err) {
                setVisible(true);
                setError(err.response.data.message);
                setTimeout(function () {
                    setVisible(false);
                    setError('');
                }, 10000);
                var categoryObject = __assign({}, categoryLoading);
                for (var _i = 0, _a = Object.keys(categoryObject); _i < _a.length; _i++) {
                    var key = _a[_i];
                    categoryObject[key] = false;
                }
                setCategoryLoading(categoryObject);
                categoryObject = __assign({}, categoryError);
                categoryObject[category] = true;
                setCategoryError(categoryObject);
            });
        }
    };
    var handleCancelModal = function () {
        setIsModalOpen(false);
        setUploadQueue([]);
    };
    var handleConfirmCancel = function () {
        setConfirmModalOpen(false);
    };
    var handleConfirmOk = function () {
        setConfirmModalOpen(false);
        if (selectedAction === 'MOVE') {
            patientApi.updatePhoto(selectedPhoto, { type: 'PATIENT' })
                .then(function () {
                loadPhotos();
                setVisible(true);
                setTimeout(function () { return setVisible(false); }, 10000);
            })
                .catch(function (err) {
                console.log(err);
            });
        }
        else if (selectedAction === 'DELETE') {
            patientApi.deletePhoto(selectedPhoto)
                .then(function () {
                loadPhotos();
                setVisible(true);
                setTimeout(function () { return setVisible(false); }, 10000);
            })
                .catch(function (err) {
                console.log(err);
            });
        }
    };
    var openConfirmModal = function (action) {
        setConfirmModalOpen(true);
        setPreviewButtonsOpen(false);
        var categoryObject = __assign({}, categoryPreviewVisible);
        for (var _i = 0, _a = Object.keys(categoryObject); _i < _a.length; _i++) {
            var key = _a[_i];
            categoryObject[key] = false;
        }
        setCategoryPreviewVisible(categoryObject);
        setSelectedAction(action);
    };
    return (_jsxs("div", __assign({ className: "dentobook-content", style: isMobileOnly ? { marginBottom: 150 } : {} }, { children: [_jsxs(Modal, __assign({ transitionName: "", okText: _jsx(FormattedMessage, { id: "confirm" }), style: { width: '100%', height: '100%' }, className: "dentobook-modal", okButtonProps: { className: "modal-btn" }, cancelButtonProps: { className: "modal-btn" }, cancelText: _jsx(FormattedMessage, { id: "cancel" }), open: isConfirmModalOpen, onOk: handleConfirmOk, onCancel: handleConfirmCancel }, { children: [_jsx("br", {}), _jsx("strong", { children: _jsx(FormattedMessage, { id: "confirmation_text" }) })] })), previewButtonsOpen ?
                _jsx("div", __assign({ className: "upload-image-preview-wrapper" }, { children: _jsxs("div", __assign({ className: "upload-image-preview-buttons" }, { children: [_jsxs(Button, __assign({ shape: "round", style: { color: 'red', opacity: '0.8' }, onClick: function () { return openConfirmModal('DELETE'); } }, { children: [_jsx(DeleteOutlined, {}), " ", _jsx(FormattedMessage, { id: "delete_text" })] })), _jsx(Button, __assign({ type: "primary", shape: "round", onClick: function () { return openConfirmModal('MOVE'); } }, { children: _jsx(FormattedMessage, { id: "move_text" }) }))] })) }))
                : null, _jsx(Modal, __assign({ transitionName: "", className: "dentobook-modal", title: _jsx(FormattedMessage, { id: "choose_image_category" }), okText: _jsx(FormattedMessage, { id: "confirm" }), okButtonProps: { className: "modal-btn" }, cancelButtonProps: { className: "modal-btn" }, cancelText: _jsx(FormattedMessage, { id: "cancel" }), width: 600, open: isModalOpen, onOk: handleOk, onCancel: handleCancelModal }, { children: _jsx(Form, __assign({ layout: "vertical" }, { children: _jsx(Form.Item, __assign({ name: "category", className: "dentobook-form-item choose-photo-type-form-item", label: _jsx(FormattedMessage, { id: "category" }) }, { children: _jsxs(Select, __assign({ onChange: function (e) { return setCategory(e); }, defaultValue: "X_RAY", suffixIcon: _jsx(CaretDownOutlined, {}) }, { children: [_jsx(Option, __assign({ value: "X_RAY" }, { children: _jsx(FormattedMessage, { id: "x_ray_photos" }) })), _jsx(Option, __assign({ value: "PATIENT" }, { children: _jsx(FormattedMessage, { id: "patient_photos" }) }))] })) })) })) })), Object.keys(photos).map(function (currentCategory) { return (_jsxs(_Fragment, { children: [_jsx("h4", { children: currentCategory === X_RAY_CATEGORY ?
                            _jsx(FormattedMessage, { id: "x_ray_photos" }) :
                            _jsx(FormattedMessage, { id: "patient_photos" }) }), photos[currentCategory].length === 0 ? _jsx(FormattedMessage, { id: "not_available" }) : null, _jsx(Upload, { showUploadList: false, listType: "picture-card", fileList: photos[currentCategory], className: "photo-thumbnail" }), _jsxs(Row, { children: [_jsx(Image.PreviewGroup, __assign({ preview: {
                                    visible: categoryPreviewVisible[currentCategory],
                                    onVisibleChange: function (value, prevValue, currentIndex) {
                                        var tmp = __assign({}, categoryPreviewVisible);
                                        tmp[currentCategory] = value;
                                        setCategoryPreviewVisible(tmp);
                                        setPreviewButtonsOpen(value);
                                        if (typeof currentIndex !== 'undefined') {
                                            setSelectedPhoto(photos[currentCategory][currentIndex].uid);
                                        }
                                    }
                                } }, { children: photos[currentCategory].map(function (photo) { return (_jsx(Image, { className: "photo-img m-24", width: 150, src: photo.url }, photo.uid)); }) })), categoryLoading[currentCategory] ?
                                _jsx("div", __assign({ className: "photo-img-loading mt-24" }, { children: "Uploading..." })) : null] })] })); }), _jsx(Modal, __assign({ transitionName: "", className: "dentobook-modal", open: previewOpen, title: previewTitle, footer: null, onCancel: handleCancel }, { children: _jsx("img", { alt: "example", style: { width: '100%' }, src: previewImage }) })), _jsx(Divider, { className: "divider-none" }), _jsxs(Dragger, __assign({}, props, { children: [_jsx("p", __assign({ className: "dentobook-drag-icon" }, { children: _jsx(InboxOutlined, {}) })), _jsx("p", __assign({ className: "ant-upload-text dentobook-content" }, { children: _jsx(FormattedMessage, { id: "drag_or_choose" }) })), _jsx("p", __assign({ className: "mt-3" }, { children: _jsxs(Button, __assign({ className: "dentobook-drag-btn", shape: "round", type: "default", size: "large" }, { children: [_jsx(PlusOutlined, {}), " ", _jsx(FormattedMessage, { id: "choose" })] })) }))] }))] })));
};
export default PatientPhotos;
