var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { PatientApiFactory } from "dentobook-api-typescript-client/api";
import { Link } from "react-router-dom";
var DashboardPatientsCard = function () {
    var apiConfig = new Configuration({ basePath: '/api' });
    var _a = useState(0), count = _a[0], setCount = _a[1];
    var patientsApi = PatientApiFactory(apiConfig, '/api');
    useEffect(function () {
        patientsApi.fetchPatientsCount()
            .then(function (res) {
            setCount(res.data);
        })
            .catch(function (err) { return console.log(err); });
    }, []);
    return (_jsxs(Card, __assign({ bordered: true, bodyStyle: { padding: '0' }, style: { height: 336 }, className: "dentobook-dashboard-card dentobook-dashboard-calendar-card" }, { children: [_jsxs("span", __assign({ className: "font-24" }, { children: [_jsx("span", __assign({ className: "text-blue mr-1" }, { children: _jsx(UserOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "patients" })] })), _jsxs("div", __assign({ style: { textAlign: 'center' } }, { children: [_jsx("h1", __assign({ className: 'dentobook-dashboard-patients-count' }, { children: count })), _jsx(Link, __assign({ to: "/patients/create", style: { display: 'inline-block', marginTop: '56px' } }, { children: _jsx(Button, __assign({ className: "dentobook-btn-secondary dentobook-btn-medium", style: { margin: "auto" } }, { children: _jsx(FormattedMessage, { id: 'saveNew' }) })) }))] }))] })));
};
export default DashboardPatientsCard;
