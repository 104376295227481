var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal } from "antd";
import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import '../assets/css/Dentobook.css';
import { FormattedMessage } from "react-intl";
import DetailWrapper from "../components/common/DetailWrapper";
import MedicalStaffForm from "../components/medicalStaff/MedicalStaffForm";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
import { ExitPromptContext } from "../context/ExitPromptContext";
var MedicalStaffCreate = function () {
    var setFormDirty = useContext(ExitPromptContext).setFormDirty;
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1];
    var _b = useState(false), goBack = _b[0], setGoBack = _b[1];
    var medicalStaff = {};
    var handleOk = function () {
        setFormDirty(false);
        setIsModalOpen(false);
        setGoBack(true);
    };
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    if (goBack) {
        return _jsx(Navigate, { to: "/medical/staff" });
    }
    var footer = _jsxs("div", __assign({ className: "modal-btn-wrapper" }, { children: [_jsx(Button, __assign({ className: "modal-btn", onClick: handleCancel }, { children: _jsx(FormattedMessage, { id: "cancel" }) })), _jsx(Button, __assign({ className: "modal-btn", type: "primary", onClick: handleOk }, { children: _jsx(FormattedMessage, { id: "confirm" }) }))] }));
    return (_jsxs(AccessControl, __assign({ accessibleFor: [accessibilityRoles.CLINIC_ADMIN, accessibilityRoles.RECEPTIONIST] }, { children: [_jsxs(Modal, __assign({ transitionName: "", className: "dentobook-modal", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, footer: footer, title: _jsx(FormattedMessage, { id: "add_medical_staff" }), open: isModalOpen, onOk: handleOk, onCancel: handleCancel }, { children: [_jsx("br", {}), _jsx("h3", __assign({ className: "dentobook-content font-weight-500 font-24 text-align-center" }, { children: _jsx(FormattedMessage, { id: "unsaved_changes" }) }))] })), _jsxs(DetailWrapper, __assign({ navigateBack: "/medical/staff" }, { children: [_jsx("h2", { children: _jsx(FormattedMessage, { id: "add_medical_staff" }) }), _jsx(MedicalStaffForm, { setEditMode: function () {
                        }, openModal: function () { return setIsModalOpen(true); }, medicalStaff: medicalStaff, mode: 0 })] }))] })));
};
export default MedicalStaffCreate;
