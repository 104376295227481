var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Divider, Form, Input, Radio, Row, Select, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { CaretDownOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { calculateTotalPrice } from "../../../utils/medicalUtils";
var ProcedureItem = function (_a) {
    var form = _a.form, procedure = _a.procedure, procedures = _a.procedures, onDelete = _a.onDelete;
    var Option = Select.Option;
    var _b = useState(procedure.price === 0), isPriceCalculatedInTreatment = _b[0], setIsPriceCalculatedInTreatment = _b[1];
    useEffect(function () {
        calculateTotalPrice(procedure.price || 0, procedure.discount || 0, procedure.laboratoryPrice || 0, function (calculation) {
            return form.setFieldValue("procedureTotalPrice-" + procedure.uid, calculation.toFixed(2));
        });
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(Row, __assign({ gutter: 24 }, { children: [_jsx(Col, __assign({ md: 17 }, { children: _jsx(Form.Item, __assign({ name: "procedureName-" + procedure.uid, className: "dentobook-form-item input-full-width", label: _jsx(FormattedMessage, { id: "procedure" }), initialValue: procedure.procedureId, rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Select, __assign({ size: "large", className: "dentobook-input", suffixIcon: _jsx(CaretDownOutlined, {}), value: procedure.procedureId, onChange: function (e) {
                                    procedure.procedureId = e;
                                    var medicalProcedure = procedures.filter(function (procedureItem) { return procedureItem.id === e; })[0];
                                    procedure.isPriceCalculatedInTreatment = medicalProcedure.price === 0;
                                    setIsPriceCalculatedInTreatment(medicalProcedure.price === 0);
                                    form.setFieldValue('procedurePrice-' + procedure.uid, medicalProcedure.price || 0);
                                    procedure.price = medicalProcedure.price || 0;
                                }, allowClear: true }, { children: procedures.sort(function (procedureOne, procedureTwo) { var _a; return (_a = procedureOne.name) === null || _a === void 0 ? void 0 : _a.localeCompare(procedureTwo.name); })
                                    .map(function (procedure) { return (_jsx(Option, __assign({ value: procedure.id }, { children: procedure.name }), procedure.id)); }) })) })) })), _jsx(Col, __assign({ md: 7 }, { children: _jsx(Form.Item, __assign({ name: "procedureStatus-" + procedure.uid, className: "dentobook-form-item input-full-width", label: _jsx(FormattedMessage, { id: "status" }), initialValue: procedure.status, rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsxs(Select, __assign({ size: "large", className: "dentobook-input", suffixIcon: _jsx(CaretDownOutlined, {}), value: procedure.status, onChange: function (e) { return procedure.status = e; }, allowClear: true }, { children: [_jsx(Option, __assign({ value: "IN_PROGRESS" }, { children: _jsx(FormattedMessage, { id: "in_progress" }) })), _jsx(Option, __assign({ value: "DONE" }, { children: _jsx(FormattedMessage, { id: "done" }) })), _jsx(Option, __assign({ value: "OFFERED" }, { children: _jsx(FormattedMessage, { id: "offered" }) })), _jsx(Option, __assign({ value: "CANCELED" }, { children: _jsx(FormattedMessage, { id: "canceled" }) }))] })) })) }))] })), _jsx(Row, __assign({ gutter: 24 }, { children: _jsx(Col, __assign({ md: 24 }, { children: _jsx(Form.Item, __assign({ name: "procedureDate-" + procedure.uid, className: "dentobook-form-item input-full-width", initialValue: dayjs(procedure.date), label: _jsx(FormattedMessage, { id: "date" }), hidden: true, rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(DatePicker, { defaultPickerValue: dayjs(procedure.date), size: "large", bordered: true, className: "input-ninety-nine-percent", format: "DD.MM.YYYY", onChange: function (e) {
                                if (e === null) {
                                    procedure.date = undefined;
                                }
                                else {
                                    procedure.date = e.toString();
                                }
                            }, placeholder: "" }) })) })) })), _jsx(Radio.Group, __assign({ value: !!isPriceCalculatedInTreatment, onChange: function (e) {
                    var _a;
                    procedure.isPriceCalculatedInTreatment = e.target.value;
                    setIsPriceCalculatedInTreatment(e.target.value);
                    if (e.target.value) {
                        procedure.price = 0;
                        procedure.discount = 0;
                        procedure.laboratoryPrice = 0;
                        form.setFieldsValue((_a = {},
                            _a['procedurePrice-' + procedure.uid] = 0,
                            _a['procedureDiscount-' + procedure.uid] = 0,
                            _a['procedureLaboratoryPrice-' + procedure.uid] = 0,
                            _a['procedureTotalPrice-' + procedure.uid] = 0,
                            _a));
                    }
                }, className: "mb-24" }, { children: _jsxs(Space, __assign({ direction: "vertical" }, { children: [_jsx(Radio, __assign({ value: true }, { children: _jsx(FormattedMessage, { id: "procedure_calculated_in_treatment" }) })), _jsx(Radio, __assign({ value: false }, { children: _jsx(FormattedMessage, { id: "procedure_not_calculated_in_treatment" }) }))] })) })), !isPriceCalculatedInTreatment ?
                _jsxs(_Fragment, { children: [_jsxs(Row, __assign({ gutter: 24 }, { children: [_jsx(Col, __assign({ md: 8 }, { children: _jsx(Form.Item, __assign({ name: "procedurePrice-" + procedure.uid, label: _jsx(FormattedMessage, { id: "price" }), className: "dentobook-form-item dentobook-simple-input", initialValue: procedure.price, rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Input, { bordered: false, className: "input-ninety-percents dentobook-inputs", value: procedure.price, onChange: function (e) {
                                                procedure.price = +e.target.value;
                                                calculateTotalPrice(+e.target.value, procedure.discount || 0, procedure.laboratoryPrice || 0, function (calculation) {
                                                    return form.setFieldValue("procedureTotalPrice-" + procedure.uid, calculation.toFixed(2));
                                                });
                                            } }) })) })), _jsx(Col, __assign({ md: 8 }, { children: _jsx(Form.Item, __assign({ name: "procedureDiscount-" + procedure.uid, label: _jsx(FormattedMessage, { id: "discount" }), className: "dentobook-form-item dentobook-simple-input", initialValue: procedure.discount || 0, rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Input, { bordered: false, className: "input-ninety-percents dentobook-inputs", value: procedure.discount, onChange: function (e) {
                                                procedure.discount = +e.target.value;
                                                calculateTotalPrice(procedure.price || 0, +e.target.value, procedure.laboratoryPrice || 0, function (calculation) {
                                                    return form.setFieldValue("procedureTotalPrice-" + procedure.uid, calculation.toFixed(2));
                                                });
                                            } }) })) })), _jsx(Col, __assign({ md: 8 }, { children: _jsx(Form.Item, __assign({ name: "procedureLaboratoryPrice-" + procedure.uid, label: _jsx(FormattedMessage, { id: "laboratory_price" }), className: "dentobook-form-item dentobook-simple-input", initialValue: procedure.laboratoryPrice, rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Input, { bordered: false, className: "input-ninety-percents dentobook-inputs", value: procedure.laboratoryPrice, onChange: function (e) {
                                                procedure.laboratoryPrice = +e.target.value;
                                                calculateTotalPrice(procedure.price || 0, procedure.discount || 0, +e.target.value, function (calculation) {
                                                    return form.setFieldValue("procedureTotalPrice-" + procedure.uid, calculation.toFixed(2));
                                                });
                                            } }) })) }))] })), _jsxs(Row, __assign({ gutter: 24 }, { children: [_jsx(Col, __assign({ md: 12 }, { children: _jsx(Form.Item, __assign({ label: _jsx(FormattedMessage, { id: "total" }), className: "dentobook-form-item dentobook-simple-input", name: "procedureTotalPrice-" + procedure.uid, initialValue: '0.00' }, { children: _jsx(Input, { disabled: true, bordered: false, className: "input-ninety-percents dentobook-inputs" }) })) })), _jsx(Col, __assign({ md: 12 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input mt-32", name: "procedurePaidAt-" + procedure.uid, initialValue: procedure.paidAt ? '1' : '0', rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsxs(Select, __assign({ size: "large", className: "dentobook-input", suffixIcon: _jsx(CaretDownOutlined, {}), value: procedure.paidAt ? '1' : '0', onChange: function (e) {
                                                if (+e) {
                                                    procedure.paidAt = (new Date()).toISOString();
                                                }
                                                else {
                                                    procedure.paidAt = undefined;
                                                }
                                            } }, { children: [_jsx(Select.Option, __assign({ value: "1" }, { children: _jsx(FormattedMessage, { id: "paidFinance" }) })), _jsx(Select.Option, __assign({ value: "0" }, { children: _jsx(FormattedMessage, { id: "unpaidFinance" }) }))] })) })) }))] }))] })
                : null, _jsxs(Button, __assign({ shape: "round", className: "dentobook-btn-medium color-error float-right mb-32 " + (isPriceCalculatedInTreatment ? "mt-5" : "mt-72"), type: "default", onClick: onDelete }, { children: [_jsx(DeleteOutlined, {}), ' ', _jsx(FormattedMessage, { id: "delete_text" })] })), _jsx(Divider, {})] }));
};
export default ProcedureItem;
