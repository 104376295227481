var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, Divider, Form, Input, Row, Select, Upload } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MedicalStaffApiFactory, UserApiFactory } from "dentobook-api-typescript-client/api";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { useNavigate } from "react-router-dom";
import '../../assets/css/Dentobook.css';
import '../../assets/css/GeneralForm.css';
import { CameraOutlined, CaretDownOutlined, EnvironmentOutlined, UserOutlined } from "@ant-design/icons";
import UserIcon from "../common/UserIcon";
import FormButtons from "../common/FormButtons";
import ReactQuill from "react-quill";
import { AlertContext } from "../../context/AlertContext";
import { MEDICAL_PHOTO_URL_PREFIX, PHOTO_URL_SUFFIX } from "../common/Constants";
import { ekatteMessages } from "../../i18n/ekatte";
import { isMobile } from "react-device-detect";
import { allCountries } from "../../i18n/countries";
import { DentobookApplicationContext } from "../../context/DentobookApplicationContext";
import { ExitPromptContext } from "../../context/ExitPromptContext";
import { checkFormDirty } from "../../i18n/utils";
var Option = Select.Option;
var MedicalStaffForm = function (_a) {
    var medicalStaff = _a.medicalStaff, setMedicalStaff = _a.setMedicalStaff, mode = _a.mode, openModal = _a.openModal, setEditMode = _a.setEditMode;
    var navigate = useNavigate();
    var intl = useIntl();
    var setFormDirty = useContext(ExitPromptContext).setFormDirty;
    var _b = useContext(AlertContext), setVisible = _b.setVisible, setError = _b.setError;
    var locale = useContext(DentobookApplicationContext).locale;
    var _c = useState(''), imagePreview = _c[0], setImagePreview = _c[1];
    var _d = useState(__assign({}, medicalStaff)), formStaff = _d[0], setFormStaff = _d[1];
    var _e = useState(undefined), avatar = _e[0], setAvatar = _e[1];
    var _f = useState(false), avatarMouseIn = _f[0], setAvatarMouseIn = _f[1];
    var _g = useState(), imagePreviewJsx = _g[0], setImagePreviewJsx = _g[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var medicalApi = MedicalStaffApiFactory(apiConfig, '/api');
    var userApi = UserApiFactory(apiConfig, '/api');
    var form = Form.useForm()[0];
    var richTextEditorToolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike']
    ];
    var fileValidationError = '';
    var specializationToCodeMapping = {
        'specialization1': '64',
        'specialization2': '61',
        'specialization3': '62',
        'specialization4': '68'
    };
    var codeToSpecializationMapping = {
        '64': 'specialization1',
        '61': 'specialization2',
        '62': 'specialization3',
        '68': 'specialization4'
    };
    var _h = useState([
        { label: _jsx(FormattedMessage, { id: "specialization1" }), value: 'specialization1' },
        { label: _jsx(FormattedMessage, { id: "specialization2" }), value: 'specialization2' },
        { label: _jsx(FormattedMessage, { id: "specialization3" }), value: 'specialization3' },
        { label: _jsx(FormattedMessage, { id: "specialization4" }), value: 'specialization4' }
    ]), specializationOptions = _h[0], setSpecializationOptions = _h[1];
    var handleSpecializationChange = function (value) {
        var code = specializationToCodeMapping[value];
        if (code) {
            setFormStaff(function (prevFormStaff) { return (__assign(__assign({}, prevFormStaff), { specialisation: value, specialisationCode: code })); });
        }
        else {
            setFormStaff(function (prevFormStaff) { return (__assign(__assign({}, prevFormStaff), { specialisation: value })); });
        }
        form.setFieldsValue({ specialisation_code: code });
    };
    var handleSpecialisationCodeChange = function (code) {
        var specialization = codeToSpecializationMapping[code];
        if (specialization) {
            setFormStaff(function (prevFormStaff) { return (__assign(__assign({}, prevFormStaff), { specialisationCode: code, specialisation: specialization })); });
        }
        else {
            setFormStaff(function (prevFormStaff) { return (__assign(__assign({}, prevFormStaff), { specialisationCode: code })); });
        }
        form.setFieldsValue({ specialisation: specialization });
    };
    var registerUser = function (medicalStaffId, organizationId) { return userApi.registerUser({
        email: formStaff.email,
        phone: formStaff.phone,
        firstName: formStaff.firstName,
        lastName: formStaff.lastName,
        role: formStaff.role,
        medicalStaffId: medicalStaffId,
        organizationId: organizationId
    }); };
    var _j = useState(undefined), formInitialState = _j[0], setFormInitialState = _j[1];
    useEffect(function () {
        if (!formInitialState) {
            setFormInitialState(form.getFieldsValue(true));
        }
    }, [form]);
    useEffect(function () {
        if (imagePreview) {
            setImagePreviewJsx(_jsx("button", __assign({ onMouseEnter: function () { return setAvatarMouseIn(true); }, onMouseLeave: function () { return setAvatarMouseIn(false); }, className: "avatar-upload-photo-img plain-button" }, { children: _jsx("img", { width: 150, src: imagePreview, alt: "avatar" }) })));
        }
        else if (!medicalStaff.avatar) {
            setImagePreviewJsx(_jsx("button", __assign({ onMouseEnter: function () { return setAvatarMouseIn(true); }, onMouseLeave: function () { return setAvatarMouseIn(false); }, className: "avatar-upload-photo-img plain-button" }, { children: _jsx(UserIcon, { size: "large", className: "mb-4" }) })));
        }
        else {
            setImagePreviewJsx(_jsx("button", __assign({ onMouseEnter: function () { return setAvatarMouseIn(true); }, onMouseLeave: function () { return setAvatarMouseIn(false); }, className: "avatar-upload-photo-img plain-button" }, { children: _jsx("img", { width: 150, src: MEDICAL_PHOTO_URL_PREFIX + medicalStaff.id + PHOTO_URL_SUFFIX, alt: "avatar" }) })));
        }
    }, [imagePreview, medicalStaff.avatar]);
    useEffect(function () {
        if (!formStaff.country) {
            setFormStaff(__assign(__assign({}, formStaff), { country: 'BG' }));
            form.setFieldValue('country', 'BG');
        }
    }, []);
    var deleteMedicalStaff = function (medicalStaffId) { return medicalApi.deleteMedicalStaffPermanently(medicalStaffId); };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var myHeaders, err_1, blankFieldExists;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    form.submit();
                    return [4 /*yield*/, form.validateFields()];
                case 2:
                    _a.sent();
                    myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    if (formStaff.role === 'RECEPTIONIST' || formStaff.role === 'MEDICAL_ASSISTANT') {
                        formStaff.specialisation = '';
                        formStaff.specialisationCode = '';
                    }
                    if (!medicalStaff.id) {
                        handleCreateMedicalStaff();
                    }
                    else {
                        handleUpdateMedicalStaff();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    blankFieldExists = Object.values(err_1.values).filter(function (item) { return !item; }).length > 0;
                    if (blankFieldExists) {
                        setError(intl.formatMessage({ id: 'all_fields_required' }));
                        setTimeout(function () { return setError(''); }, 10000);
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCreateMedicalStaff = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, medicalId, organizationId, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 6, , 7]);
                    return [4 /*yield*/, medicalApi.createMedicalStaff(formStaff)];
                case 1:
                    res = _b.sent();
                    medicalId = res.data.id;
                    organizationId = (_a = res.data.organization) === null || _a === void 0 ? void 0 : _a.id;
                    if (!avatar) return [3 /*break*/, 3];
                    return [4 /*yield*/, handleAvatarAndRegister(medicalId, organizationId)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, handleRegisterUser(medicalId, organizationId)];
                case 4:
                    _b.sent();
                    _b.label = 5;
                case 5:
                    if (setMedicalStaff) {
                        setMedicalStaff(__assign({}, formStaff));
                    }
                    setVisible(true);
                    setTimeout(function () { return setVisible(false); }, 10000);
                    navigate('/medical/staff');
                    return [3 /*break*/, 7];
                case 6:
                    error_1 = _b.sent();
                    handleCreateMedicalStaffError(error_1);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleUpdateMedicalStaff = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    if (!(medicalStaff.id != null)) return [3 /*break*/, 2];
                    return [4 /*yield*/, medicalApi.updateMedicalStaff(medicalStaff.id, formStaff)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    if (!avatar) return [3 /*break*/, 4];
                    return [4 /*yield*/, medicalApi.updateMedicalStaffAvatar(medicalStaff.id, avatar)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    if (setMedicalStaff) {
                        setMedicalStaff(__assign({}, formStaff));
                    }
                    setVisible(true);
                    setTimeout(function () { return setVisible(false); }, 10000);
                    setEditMode(false);
                    return [3 /*break*/, 6];
                case 5:
                    error_2 = _a.sent();
                    handleUpdateMedicalStaffError(error_2);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleAvatarAndRegister = function (medicalId, organizationId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Promise.all([
                            medicalApi.updateMedicalStaffAvatar(medicalId, avatar),
                            registerUser(medicalId, organizationId)
                        ])];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    handleRegistrationError(error_3, medicalId);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleRegisterUser = function (medicalId, organizationId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, registerUser(medicalId, organizationId)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    handleRegistrationError(error_4, medicalId);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleCreateMedicalStaffError = function (error) {
        if (error.response.data.additionalMessage) {
            var msg = error.response.data.additionalMessage.toLowerCase();
            if (msg.indexOf('email') > -1) {
                setError(intl.formatMessage({ id: 'duplicate_email' }));
                setTimeout(function () { return setError(''); }, 10000);
            }
        }
        else {
            console.log('error', error);
        }
    };
    var handleUpdateMedicalStaffError = function (error) {
        if (error.response.data.additionalMessage) {
            var message = error.response.data.additionalMessage.toLowerCase();
            if (message.indexOf('email') > -1) {
                setError(intl.formatMessage({ id: 'duplicate_email' }));
                setTimeout(function () { return setError(''); }, 10000);
            }
        }
        else {
            console.log('error', error);
        }
    };
    var handleRegistrationError = function (error, medicalId) {
        setError(intl.formatMessage({ id: 'registration_error' }));
        setTimeout(function () { return setError(''); }, 10000);
        deleteMedicalStaff(medicalId);
    };
    var props = {
        beforeUpload: function (file) {
            var fileName = file.name.toLowerCase();
            var validFormat = fileName.endsWith('png') || fileName.endsWith('jpg') || fileName.endsWith('jpeg');
            var validSize = file.size && file.size <= 1000000;
            if (!validFormat) {
                fileValidationError = intl.formatMessage({ id: 'invalid_image_format' });
            }
            else if (!validSize) {
                fileValidationError = intl.formatMessage({ id: 'invalid_image_size' });
            }
            else {
                fileValidationError = '';
            }
        },
        name: 'file',
        customRequest: function () {
        },
        showUploadList: false,
        onChange: function (info) {
            var _a;
            if (!fileValidationError) {
                formStaff.avatar = (_a = info.file.originFileObj) === null || _a === void 0 ? void 0 : _a.name;
                setAvatar(info.file.originFileObj);
                setImagePreview(URL.createObjectURL(info.file.originFileObj));
            }
            else {
                setError(fileValidationError);
                setTimeout(function () {
                    setVisible(false);
                    setError('');
                }, 10000);
            }
        },
    };
    var filterOption = function (input, option) {
        var searchText = input.toLowerCase();
        return (option === null || option === void 0 ? void 0 : option.searchLabel.includes(searchText)) || (option === null || option === void 0 ? void 0 : option.value.includes(searchText));
    };
    var handleTownChange = function (value) {
        setFormStaff(__assign(__assign({}, formStaff), { town: value }));
    };
    return (_jsxs(Form, __assign({ form: form, scrollToFirstError: true, name: "horizontal_login", layout: "vertical", onFieldsChange: function (changedFields, allFields) { return setFormDirty(checkFormDirty(allFields, formInitialState)); } }, { children: [_jsxs("h4", { children: [_jsx("span", __assign({ className: "text-blue mr-1" }, { children: _jsx(UserOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "personal_information" })] }), _jsx(Upload, __assign({}, props, { children: _jsxs("div", __assign({ className: avatarMouseIn ? "avatar-upload-photo-mask" : "" }, { children: [_jsx(CameraOutlined, { className: avatarMouseIn ? "avatar-upload-icon" : "d-none" }), imagePreviewJsx] })) })), _jsxs(Row, __assign({ gutter: 16, className: "mt-4" }, { children: [_jsx(Col, __assign({ flex: 1.5 }, { children: _jsx(Form.Item, __assign({ label: _jsx(FormattedMessage, { id: "first_name" }), className: "dentobook-form-item dentobook-simple-input", name: "FirstName", initialValue: formStaff.firstName, rules: [{
                                    pattern: /^[a-zA-Zа-яА-Я\-\s]+$/,
                                    message: _jsx(FormattedMessage, { id: "letters_only_field" })
                                },
                                { required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Input, { value: formStaff.firstName, onChange: function (e) { return formStaff.firstName = e.target.value; } }) })) })), _jsx(Col, __assign({ flex: 1.5 }, { children: _jsx(Form.Item, __assign({ label: _jsx(FormattedMessage, { id: "surname" }), name: "Surname", className: "dentobook-form-item dentobook-simple-input", initialValue: formStaff.secondName, rules: [{
                                    pattern: /^[a-zA-Zа-яА-Я\-\s]+$/,
                                    message: _jsx(FormattedMessage, { id: "letters_only_field" })
                                }, { required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Input, { value: formStaff.secondName, onChange: function (e) { return formStaff.secondName = e.target.value; } }) })) })), _jsx(Col, __assign({ flex: 1.5 }, { children: _jsx(Form.Item, __assign({ label: _jsx(FormattedMessage, { id: "last_name" }), name: "LastName", initialValue: formStaff.lastName, className: "dentobook-form-item dentobook-simple-input", rules: [{
                                    pattern: /^[a-zA-Zа-яА-Я\-\s]+$/,
                                    message: _jsx(FormattedMessage, { id: "letters_only_field" })
                                },
                                { required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Input, { value: formStaff.lastName, onChange: function (e) { return formStaff.lastName = e.target.value; } }) })) }))] })), _jsxs(Row, __assign({ gutter: [16, 16] }, { children: [_jsx(Col, __assign({ md: 8 }, { children: _jsx(Form.Item, __assign({ name: "role", className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "role" }), rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }], initialValue: formStaff.role }, { children: _jsxs(Select, __assign({ disabled: mode === 1, size: "large", value: formStaff.role, suffixIcon: _jsx(CaretDownOutlined, {}), onChange: function (e) {
                                    formStaff.role = e;
                                    if (['MEDICAL_DOCTOR', 'CLINIC_ADMIN'].includes(e)) {
                                        setSpecializationOptions([
                                            { label: _jsx(FormattedMessage, { id: "specialization1" }), value: 'specialization1' },
                                            { label: _jsx(FormattedMessage, { id: "specialization2" }), value: 'specialization2' },
                                            { label: _jsx(FormattedMessage, { id: "specialization3" }), value: 'specialization3' },
                                            { label: _jsx(FormattedMessage, { id: "specialization4" }), value: 'specialization4' }
                                        ]);
                                    }
                                    else {
                                        setSpecializationOptions([
                                            { label: _jsx(FormattedMessage, { id: "dentist" }), value: 'dentist' }
                                        ]);
                                    }
                                }, allowClear: true }, { children: [_jsx(Select.Option, __assign({ value: "CLINIC_ADMIN" }, { children: _jsx(FormattedMessage, { id: "CLINIC_ADMIN" }) })), _jsx(Select.Option, __assign({ value: "MEDICAL_DOCTOR" }, { children: _jsx(FormattedMessage, { id: "MEDICAL_DOCTOR" }) })), _jsx(Select.Option, __assign({ value: "MEDICAL_ASSISTANT" }, { children: _jsx(FormattedMessage, { id: "MEDICAL_ASSISTANT" }) })), _jsx(Select.Option, __assign({ value: "RECEPTIONIST" }, { children: _jsx(FormattedMessage, { id: "RECEPTIONIST" }) }))] })) })) })), formStaff.role !== 'MEDICAL_ASSISTANT' && formStaff.role !== 'RECEPTIONIST' ?
                        _jsxs(_Fragment, { children: [_jsx(Col, __assign({ md: 8 }, { children: _jsx(Form.Item, __assign({ name: "specialisation", className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "specialisation" }), rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }], initialValue: formStaff.specialisation
                                            ? intl.formatMessage({ id: formStaff.specialisation })
                                            : '' }, { children: _jsx(Select, __assign({ size: "large", value: formStaff.specialisation, suffixIcon: _jsx(CaretDownOutlined, {}), onChange: handleSpecializationChange, allowClear: true }, { children: specializationOptions.map(function (option) { return (_jsx(Select.Option, __assign({ value: option.value }, { children: option.label }), option.value)); }) }), formStaff.specialisationCode) })) })), _jsx(Col, __assign({ md: 8 }, { children: _jsx(Form.Item, __assign({ name: "specialisation_code", className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "specialisation_code" }), rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }], initialValue: formStaff.specialisationCode }, { children: _jsxs(Select, __assign({ size: "large", value: formStaff.specialisationCode, suffixIcon: _jsx(CaretDownOutlined, {}), onChange: handleSpecialisationCodeChange, allowClear: true }, { children: [_jsx(Option, __assign({ value: "61" }, { children: _jsx(FormattedMessage, { id: "61" }) })), _jsx(Option, __assign({ value: "62" }, { children: _jsx(FormattedMessage, { id: "62" }) })), _jsx(Option, __assign({ value: "64" }, { children: _jsx(FormattedMessage, { id: "64" }) })), _jsx(Option, __assign({ value: "68" }, { children: _jsx(FormattedMessage, { id: "68" }) }))] }), formStaff.specialisation) })) }))] })
                        : null] })), _jsx(Row, __assign({ gutter: 16, className: "mt-4" }, { children: _jsx(Col, __assign({ md: 8 }, { children: _jsx(Form.Item, __assign({ label: _jsx(FormattedMessage, { id: "uin" }), name: "uin", initialValue: formStaff.uin, className: "dentobook-form-item dentobook-simple-input input-full-width", rules: [{ pattern: /^\d+$/, message: _jsx(FormattedMessage, { id: "digits_only_field" }) },
                            { required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Input, { value: formStaff.uin, onChange: function (e) { return formStaff.uin = e.target.value; } }) })) })) })), _jsx(Divider, {}), _jsxs("h4", { children: [_jsx("span", __assign({ className: "text-blue mr-1" }, { children: _jsx(EnvironmentOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "contact" })] }), _jsxs(Row, __assign({ gutter: 24 }, { children: [_jsx(Col, __assign({ flex: 1.25 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "email" }), name: "email", rules: [{
                                    required: true,
                                    type: "email",
                                    message: _jsx(FormattedMessage, { id: "invalid_email" })
                                }], initialValue: formStaff.email }, { children: _jsx(Input, { value: formStaff.email, onChange: function (e) { return formStaff.email = e.target.value; } }) })) })), _jsx(Col, __assign({ flex: 1.25 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "phone_label_two" }), name: "phone", rules: [{
                                    required: true,
                                    pattern: new RegExp(/^[+()\d\s]+$/gm),
                                    message: _jsx(FormattedMessage, { id: "invalid_phone_number" })
                                }], initialValue: formStaff.phone }, { children: _jsx(Input, { type: "tel", value: formStaff.phone, onChange: function (e) { return formStaff.phone = e.target.value; } }) })) })), _jsx(Col, __assign({ flex: 1.25, id: "town-field" }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item input-full-width single-select", label: _jsx(FormattedMessage, { id: "town" }), name: "town", rules: [{
                                    message: _jsx(FormattedMessage, { id: "letters_only_field" })
                                },
                                { required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }], initialValue: formStaff.town }, { children: _jsx(Select, { className: "dentobook-input", showSearch: true, suffixIcon: _jsx(CaretDownOutlined, {}), placeholder: "\u0422\u044A\u0440\u0441\u0435\u043D\u0435 \u0438 \u0438\u0437\u0431\u043E\u0440", optionFilterProp: "label", filterOption: filterOption, onChange: handleTownChange, options: ekatteMessages }) })) })), _jsx(Col, __assign({ flex: 1.25 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item input-full-width single-select", label: _jsx(FormattedMessage, { id: "country" }), name: "country", initialValue: formStaff.country, rules: [{
                                    message: _jsx(FormattedMessage, { id: "letters_only_field" })
                                },
                                { required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Select, __assign({ suffixIcon: _jsx(CaretDownOutlined, {}), className: "dentobook-input", value: formStaff.country, onChange: function (e) { return formStaff.country = e; } }, { children: allCountries.map(function (countryItem) { return (_jsx(Select.Option, __assign({ value: countryItem.code }, { children: locale === 'BG' ? countryItem.name_bg : countryItem.name_en }), countryItem.code)); }) })) })) }))] })), _jsx(Row, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-fill-width", label: _jsx(FormattedMessage, { id: "addressOne" }), name: "addressOne", initialValue: formStaff.addressOne }, { children: _jsx(Input, { value: formStaff.addressOne, onChange: function (e) { return formStaff.addressOne = e.target.value; } }) })) }), _jsx(Form.Item, __assign({ label: _jsx(FormattedMessage, { id: "notes" }), name: "notes", initialValue: formStaff.notes, className: "dentobook-form-item dentobook-simple-input mt-4", style: { marginBottom: isMobile ? 200 : 80 } }, { children: _jsx(ReactQuill, { value: formStaff.notes, onChange: function (e) { return formStaff.notes = e; }, modules: {
                        toolbar: richTextEditorToolbarOptions
                    } }) })), _jsxs(FormButtons, { children: [_jsx(Button, __assign({ className: "dentobook-btn-secondary modal-btn", htmlType: "button", onClick: function () { return openModal(); }, type: "default" }, { children: _jsx(FormattedMessage, { id: "cancel" }) })), _jsx(Button, __assign({ className: "dentobook-btn-primary modal-btn", onClick: handleSubmit, htmlType: "submit", type: "primary" }, { children: _jsx(FormattedMessage, { id: "save" }) }))] })] })));
};
export default MedicalStaffForm;
