var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Form, Table } from "antd";
import '../../assets/css/Dentobook.css';
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import UserIcon from "../common/UserIcon";
import dayjs from 'dayjs';
import { PATIENT_PHOTO_URL_PREFIX, PHOTO_URL_SUFFIX } from "../common/Constants";
import { DentobookApplicationContext } from "../../context/DentobookApplicationContext";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { MedicalStaffApiFactory, PatientApiFactory } from "dentobook-api-typescript-client/api";
import { useNavigate } from 'react-router-dom';
import ExcuseNoteModal from "./ExcuseNoteModal";
var ExcuseNotes = function () {
    var navigate = useNavigate();
    var _a = useState([]), patients = _a[0], setPatients = _a[1];
    var _b = useState({}), selectedPatient = _b[0], setSelectedPatient = _b[1];
    var _c = useState([]), statuses = _c[0], setStatuses = _c[1];
    var locale = React.useContext(DentobookApplicationContext).locale;
    dayjs.locale(locale);
    var _d = useState(false), isModalOpen = _d[0], setIsModalOpen = _d[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var patientApi = PatientApiFactory(apiConfig, '/api');
    var medicalApi = MedicalStaffApiFactory(apiConfig, '/api');
    useEffect(function () {
        loadData();
    }, []);
    var loadData = function () {
        patientApi.fetchPatientsWithSentNzisData()
            .then(function (response) {
            if (typeof response.data === 'undefined') {
                return;
            }
            setPatients(response.data);
            medicalApi.fetchMedicalStatuses([])
                .then(function (res) {
                setStatuses(res.data);
            })
                .catch(function (err) { return console.log(err); });
        })
            .catch(function (err) { return console.log(err); });
    };
    var openExcuseNoteModal = function (patient) {
        setSelectedPatient(patient);
        setIsModalOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ExcuseNoteModal, { isModalOpen: isModalOpen, setIsModalOpen: setIsModalOpen, statuses: statuses, patient: selectedPatient }), _jsxs("div", __assign({ style: { paddingTop: '10px' } }, { children: [_jsx(Form.Item, { className: "dentobook-form-item", rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }), _jsxs(Table, __assign({ dataSource: patients, pagination: false, style: { paddingTop: '10px' }, className: "dentobook-table" }, { children: [_jsx(Table.Column, { width: "25%", render: function (patient) { return (_jsxs("div", __assign({ className: "username-icon" }, { children: [!patient.avatar ? _jsx(UserIcon, { size: "small" }) :
                                            _jsx("img", { className: "thumbnail-img", width: 10, src: PATIENT_PHOTO_URL_PREFIX + patient.id + PHOTO_URL_SUFFIX, alt: "avatar" }), _jsx("span", __assign({ className: "font-weight-500" }, { children: patient.firstName + ' ' + patient.lastName }))] }))); }, title: _jsx(FormattedMessage, { id: "name" }), sorter: {} }, "id"), _jsx(Table.Column, { width: "15%", title: "\u0415\u0413\u041D", sorter: {}, render: function (patient) { return _jsx("span", __assign({ className: "font-weight-500" }, { children: patient.egn })); } }, "egn"), _jsx(Table.Column, { width: "55%", render: function (patient) { return _jsx("span", __assign({ className: "font-weight-500" }, { children: patient.phone })); }, title: _jsx(FormattedMessage, { id: "phone" }) }, "phone"), _jsx(Table.Column, { width: "5%", render: function (patient) { return _jsx(Button, __assign({ type: "link", onClick: function () { return openExcuseNoteModal(patient); }, className: "font-weight-500 font-family-roboto font-16" }, { children: _jsx(FormattedMessage, { id: "create_note" }) })); }, title: _jsx("div", {}) }, "excuseNoteButton")] }))] }))] }));
};
export default ExcuseNotes;
