import { jsx as _jsx } from "react/jsx-runtime";
import { UserOutlined } from "@ant-design/icons";
import React from "react";
import '../../assets/css/Dentobook.css';
var UserIcon = function (_a) {
    var size = _a.size, className = _a.className, rounded = _a.rounded;
    var styles = {};
    switch (size) {
        case "large":
            styles = { fontSize: '60px', padding: '40px', borderRadius: rounded ? '200px' : '' };
            break;
        case "medium":
            styles = { fontSize: '40px', padding: '20px', borderRadius: rounded ? '200px' : '' };
            break;
        case "xs":
            styles = { fontSize: '20px', padding: '10px', borderRadius: rounded ? '200px' : '' };
            break;
        default:
            styles = { borderRadius: rounded ? '200px' : '' };
            break;
    }
    return (_jsx(UserOutlined, { className: "user-button " + className, style: styles }));
};
export default UserIcon;
