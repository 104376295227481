var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Outlet, useLocation } from 'react-router-dom';
import { Button, Divider, Drawer, Layout, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { MenuOutlined, SettingOutlined } from '@ant-design/icons';
import { menuItems } from "../../utils/utils";
import '../../assets/css/MobileLayout.css';
import { DentobookApplicationContext } from "../../context/DentobookApplicationContext";
import { FormattedMessage } from "react-intl";
import RightHeaderMenu from "./RightHeaderMenu";
import { AlertContext } from '../../context/AlertContext';
import { bitwiseOr, isAccessibleFor, isNotAccessibleFor } from "../../utils/accessUtils";
import CustomAlert from "./CustomAlert";
import PoliciesLinks from "./PoliciesLinks";
import DentobookLink from '../common/DentobookLink';
export var MobileLayout = function () {
    var principal = React.useContext(DentobookApplicationContext).principal;
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    var _b = useState(''), error = _b[0], setError = _b[1];
    var _c = useState(''), successMessage = _c[0], setSuccessMessage = _c[1];
    var _d = useState(''), redirectUrl = _d[0], setRedirectUrl = _d[1];
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var _f = useState(''), selectedMenuItem = _f[0], setSelectedMenuItem = _f[1];
    var _g = useState([]), accessibleMenuItems = _g[0], setAccessibleMenuItems = _g[1];
    var location = useLocation();
    var showDrawer = function () {
        setOpen(true);
    };
    var closeDrawer = function (path) {
        setOpen(false);
        if (path) {
            setSelectedMenuItem(path);
        }
    };
    useEffect(function () {
        var currentPath = location.pathname;
        var matchedPath = currentPath.includes('/patients/create') ? '/patients' : currentPath;
        setSelectedMenuItem(matchedPath);
        var accessibleMenuItems = menuItems.filter(function (item) {
            if (item.accessibleFor) {
                return isAccessibleFor(principal === null || principal === void 0 ? void 0 : principal.user, bitwiseOr(item.accessibleFor));
            }
            if (item.notAccessibleFor) {
                return isNotAccessibleFor(principal === null || principal === void 0 ? void 0 : principal.user, bitwiseOr(item.notAccessibleFor));
            }
            return true;
        });
        setAccessibleMenuItems(accessibleMenuItems);
    }, [location.pathname, principal]);
    return (_jsx(AlertContext.Provider, __assign({ value: {
            visible: visible,
            setVisible: setVisible,
            error: error,
            setError: setError,
            successMessage: successMessage,
            setSuccessMessage: setSuccessMessage,
            redirectUrl: redirectUrl,
            setRedirectUrl: setRedirectUrl
        } }, { children: _jsxs(Layout, { children: [_jsx(CustomAlert, {}), _jsxs("nav", __assign({ className: "nav" }, { children: [(principal === null || principal === void 0 ? void 0 : principal.user) ?
                            _jsx(Button, __assign({ shape: "circle", className: "button", onClick: showDrawer }, { children: _jsx(MenuOutlined, {}) }))
                            : _jsx("div", { children: _jsx("img", { src: require('../../assets/logo.png'), alt: "", style: { height: 40 } }) }), _jsx("div", { children: _jsx(RightHeaderMenu, {}) })] })), (principal === null || principal === void 0 ? void 0 : principal.user) ?
                    _jsx(Drawer, __assign({ title: _jsx("img", { src: require('../../assets/logo.png'), alt: "", height: 40 }), placement: "left", closable: false, onClose: function () { return closeDrawer(""); }, open: open, className: "menu-layout", extra: _jsx(Space, { children: _jsx(Button, __assign({ shape: "circle", className: "close-button", onClick: function () { return closeDrawer(""); } }, { children: _jsx(MenuOutlined, {}) })) }) }, { children: accessibleMenuItems.map(function (m) {
                            var cssClassOne = selectedMenuItem === m.path ? 'menu-item menu-item-selected' : 'menu-item';
                            var cssClassTwo = m.bottom ? 'bottom-menu' : cssClassOne;
                            return _jsxs(_Fragment, { children: [m.bottom ? _jsx(Divider, { className: "bottom-menu" }) : null, _jsxs(DentobookLink, __assign({ cssClassName: cssClassTwo, to: m.path, cb: function () { return closeDrawer(m.path); } }, { children: [m.bottom ? _jsx(SettingOutlined, { className: "settings-icon" }) : null, _jsx("span", { children: _jsx(FormattedMessage, { id: m.name }) })] }))] });
                        }).concat(_jsx(PoliciesLinks, {})) }), "drawer")
                    : null, _jsx(Layout, { children: _jsx("div", { children: _jsx(Outlet, {}) }) })] }) })));
};
