var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select, Table } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { MedicalStaffApiFactory, NZIZApiFactory } from "dentobook-api-typescript-client/api";
import { CaretDownOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { useForm } from "antd/es/form/Form";
var ClinicTreatmentPlanCard = function (_a) {
    var showModal = _a.showModal, onClose = _a.onClose, keyword = _a.keyword;
    var intl = useIntl();
    var form = useForm()[0];
    var Option = Select.Option;
    var _b = useState(''), newTreatmentName = _b[0], setNewTreatmentName = _b[1];
    var _c = useState(''), newNzisCondition = _c[0], setNewNzisCondition = _c[1];
    var _d = useState(0), newTreatmentPrice = _d[0], setNewTreatmentPrice = _d[1];
    var _e = useState(0), newTreatmentLaboratoryPrice = _e[0], setNewTreatmentLaboratoryPrice = _e[1];
    var _f = useState(''), editTreatmentId = _f[0], setEditTreatmentId = _f[1];
    var _g = useState(false), editMode = _g[0], setEditMode = _g[1];
    var _h = useState(false), isDeleteModalVisible = _h[0], setIsDeleteModalVisible = _h[1];
    var _j = useState([]), treatments = _j[0], setTreatments = _j[1];
    var _k = useState([]), displayedTreatments = _k[0], setDisplayedTreatments = _k[1];
    var _l = useState([]), nzisDentalActivityCodes = _l[0], setNzisDentalActivityCodes = _l[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var medicalApi = MedicalStaffApiFactory(apiConfig, '/api');
    var nzisApi = NZIZApiFactory(apiConfig, '/api');
    var treatment = {};
    var showEditModal = function (treatment) {
        var _a, _b, _c, _d, _e;
        if (treatment) {
            setEditTreatmentId((_a = treatment.id) !== null && _a !== void 0 ? _a : '');
            setNewTreatmentName((_b = treatment.name) !== null && _b !== void 0 ? _b : '');
            setNewNzisCondition((_c = treatment.nzisCondition) !== null && _c !== void 0 ? _c : '');
            setNewTreatmentPrice((_d = treatment.price) !== null && _d !== void 0 ? _d : 0);
            setNewTreatmentLaboratoryPrice((_e = treatment.laboratoryPrice) !== null && _e !== void 0 ? _e : 0);
            form.setFieldsValue({
                treatment: treatment.name,
                nzisTreatment: treatment.nzisCondition
            });
            setEditMode(true);
        }
    };
    useEffect(function () {
        loadData();
    }, []);
    var loadData = function () {
        medicalApi.fetchMedicalTreatments()
            .then(function (response) {
            setTreatments(response.data.sort(function (treatmentOne, treatmentTwo) { var _a; return (_a = treatmentOne.name) === null || _a === void 0 ? void 0 : _a.localeCompare(treatmentTwo.name); }));
            setDisplayedTreatments(response.data);
            nzisApi.fetchDentalActivityTypes()
                .then(function (res) {
                setNzisDentalActivityCodes(res.data);
            })
                .catch(function (err) { return console.log(err); });
        })
            .catch(function (error) { return console.error("Failed to load treatment:", error); });
    };
    useEffect(function () {
        if (keyword) {
            var filteredRooms = treatments.filter(function (treatment) { var _a; return (_a = treatment.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(keyword.toLowerCase()); });
            setDisplayedTreatments(filteredRooms);
        }
        else {
            setDisplayedTreatments(treatments);
        }
    }, [treatments, keyword]);
    var showDeleteModal = function (treatmentId) {
        setEditTreatmentId(treatmentId);
        setIsDeleteModalVisible(true);
    };
    var resetState = function () {
        setEditTreatmentId('');
        setNewTreatmentName('');
        setNewNzisCondition('');
        setNewTreatmentPrice(0);
        setNewTreatmentLaboratoryPrice(0);
        setEditMode(false);
        form.setFieldsValue({
            treatment: '',
            nzisTreatment: ''
        });
        setIsDeleteModalVisible(false);
    };
    var closeAddTreatmentModal = function () {
        onClose();
    };
    var handleCancel = function () {
        closeAddTreatmentModal();
        setEditTreatmentId('');
        setNewTreatmentName('');
        setNewNzisCondition('');
        setNewTreatmentPrice(0);
        setNewTreatmentLaboratoryPrice(0);
        setEditMode(false);
    };
    var handleAddOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, form.validateFields()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, handleCreatetreatment()];
                case 2:
                    _a.sent();
                    closeAddTreatmentModal();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCreatetreatment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    treatment.name = newTreatmentName;
                    treatment.nzisCondition = newNzisCondition;
                    treatment.price = newTreatmentPrice;
                    treatment.laboratoryPrice = newTreatmentLaboratoryPrice;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, medicalApi.createMedicalTreatment(treatment)];
                case 2:
                    _a.sent();
                    loadData();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Failed to create treatment:", error_1);
                    return [3 /*break*/, 4];
                case 4:
                    resetState();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleEditTreatmentOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_2, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 7, , 8]);
                    return [4 /*yield*/, form.validateFields()];
                case 1:
                    _a.sent();
                    treatment.name = newTreatmentName;
                    treatment.price = newTreatmentPrice;
                    treatment.laboratoryPrice = newTreatmentLaboratoryPrice;
                    treatment.nzisCondition = newNzisCondition;
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 5, , 6]);
                    if (!(editTreatmentId != null)) return [3 /*break*/, 4];
                    return [4 /*yield*/, medicalApi.updateMedicalTreatment(editTreatmentId, treatment)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    loadData();
                    return [3 /*break*/, 6];
                case 5:
                    error_2 = _a.sent();
                    console.error("Failed to create treatment:", error_2);
                    return [3 /*break*/, 6];
                case 6:
                    resetState();
                    return [3 /*break*/, 8];
                case 7:
                    err_2 = _a.sent();
                    console.log(err_2);
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var handleEditTreatmentCancel = function () {
        resetState();
    };
    var handleDeletetreatmentOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!(editTreatmentId != null)) return [3 /*break*/, 2];
                    return [4 /*yield*/, medicalApi.deleteMedicalTreatment(editTreatmentId)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    loadData();
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.error("Failed to delete treatment:", error_3);
                    return [3 /*break*/, 4];
                case 4:
                    resetState();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteTreatmentCancel = function () {
        resetState();
    };
    var modalContent = _jsx("div", __assign({ className: "anamnesis-modal-group" }, { children: _jsxs(Form, __assign({ form: form, layout: "vertical" }, { children: [_jsx(Form.Item, __assign({ name: "treatment", rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }], className: "font-weight-400", label: _jsx(FormattedMessage, { id: "naming" }) }, { children: _jsx(Input, { value: newTreatmentName, onChange: function (e) { return setNewTreatmentName(e.target.value); }, className: "custom-input" }) })), _jsx(Form.Item, __assign({ name: "nzisTreatment", rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }], className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "nzis_treatment_label" }) }, { children: _jsx(Select, __assign({ suffixIcon: _jsx(CaretDownOutlined, {}), size: "large", className: "dentobook-input", value: newNzisCondition, onChange: function (value) { return setNewNzisCondition(value); } }, { children: nzisDentalActivityCodes.sort(function (activityOne, activityTwo) { var _a; return (_a = activityOne.descriptionBG) === null || _a === void 0 ? void 0 : _a.localeCompare(activityTwo.descriptionBG); })
                            .map(function (activity) { return (_jsx(Option, __assign({ value: activity.key }, { children: activity.descriptionBG }), activity.key)); }) })) })), _jsx(Row, __assign({ gutter: 24 }, { children: _jsx(Col, __assign({ flex: 5 }, { children: _jsx(Form.Item, __assign({ className: "font-weight-400", label: _jsx(FormattedMessage, { id: "price" }) }, { children: _jsx(Input, { value: newTreatmentPrice, onChange: function (e) {
                                    var value = Number(e.target.value);
                                    if (!isNaN(value)) {
                                        setNewTreatmentPrice(value);
                                    }
                                }, className: "custom-input" }) })) })) }))] })) }));
    return (_jsxs(_Fragment, { children: [_jsxs(Table, __assign({ dataSource: displayedTreatments, pagination: false, className: "dentobook-table", style: { marginTop: '5px' } }, { children: [_jsx(Table.Column, { className: 'custom-column-style', title: _jsx(FormattedMessage, { id: "operation" }), sorter: function (treatmentOne, treatmentTwo) { return treatmentOne.name.localeCompare(treatmentTwo.name); }, render: function (treatment) { return _jsx("span", __assign({ className: "font-weight-500" }, { children: treatment.name })); } }, "id"), _jsx(Table.Column, { className: 'custom-column-style', title: _jsx(FormattedMessage, { id: "nzis_treatment_label" }), render: function (treatment) { var _a; return _jsx("span", __assign({ className: "font-weight-500" }, { children: (_a = nzisDentalActivityCodes.find(function (activityCode) { return activityCode.key === treatment.nzisCondition; })) === null || _a === void 0 ? void 0 : _a.descriptionBG })); } }, "nzisName"), _jsx(Table.Column, { className: 'custom-column-style', title: _jsx(FormattedMessage, { id: "price" }), render: function (treatment) { return _jsx("span", __assign({ className: "font-weight-500" }, { children: treatment.price })); } }, "price"), _jsx(Table.Column, { title: _jsx("div", {}), className: 'custom-column-style', render: function () { return (_jsx("div", { style: { display: 'flex', alignItems: 'center' } })); } }, "color"), _jsx(Table.Column, { title: _jsx("div", {}), className: 'custom-column-style', render: function (treatment) {
                            return (_jsxs("div", __assign({ style: { display: 'flex', flexDirection: 'row', alignItems: 'right' } }, { children: [_jsx(Button, { className: "text-black font-20 ml-1", type: "link", icon: _jsx(EditOutlined, {}), style: { marginRight: '10px' }, onClick: function () { return showEditModal(treatment); } }), _jsx(Button, { className: "text-black font-20", type: "link", icon: _jsx(DeleteOutlined, {}), onClick: function () { return showDeleteModal(treatment.id); } })] })));
                        } }, "actions")] })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, title: _jsx(FormattedMessage, { id: "operation" }), open: showModal, onOk: handleAddOk, onCancel: handleCancel, className: "dentobook-modal" }, { children: modalContent })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, title: _jsx(FormattedMessage, { id: "editTreatment" }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: editMode, onOk: handleEditTreatmentOk, onCancel: handleEditTreatmentCancel, className: "dentobook-modal" }, { children: modalContent })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, className: "dentobook-modal", title: _jsx(FormattedMessage, { id: "deleteTreatment" }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: isDeleteModalVisible, onOk: handleDeletetreatmentOk, onCancel: handleDeleteTreatmentCancel }, { children: _jsx("p", { children: _jsx(FormattedMessage, { id: "tratmentDeleteConfirmation" }) }) }))] }));
};
export default ClinicTreatmentPlanCard;
