var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormattedMessage } from "react-intl";
import { DentobookApplicationContext } from "../../context/DentobookApplicationContext";
var PoliciesLinks = function () {
    var locale = React.useContext(DentobookApplicationContext).locale;
    return (_jsxs("ul", __assign({ className: "dentobook-policy-group" }, { children: [_jsx("li", __assign({ className: "mb-2" }, { children: _jsx("a", __assign({ href: "https://dentobook.com/" + locale.toLowerCase() + "/cookie-policy/", className: "link-decoration-none font-family-roboto body-regular mb-2 font-weight-400 font-14", style: { color: '#3B4863' }, target: "_blank" }, { children: _jsx(FormattedMessage, { id: "cookies_policy" }) })) })), _jsx("li", __assign({ className: "mb-2" }, { children: _jsx("a", __assign({ href: "https://dentobook.com/" + locale.toLowerCase() + "/terms-of-use/", className: "link-decoration-none font-family-roboto body-regular mb-2 font-weight-400 font-14", style: { color: '#3B4863' }, target: "_blank" }, { children: _jsx(FormattedMessage, { id: "usage_rules" }) })) })), _jsx("li", __assign({ className: "mb-2" }, { children: _jsx("a", __assign({ href: "https://dentobook.com/" + locale.toLowerCase() + "/terms-and-conditions/", className: "link-decoration-none font-family-roboto body-regular mb-2 font-weight-400 font-14", style: { color: '#3B4863' }, target: "_blank" }, { children: _jsx(FormattedMessage, { id: "terms_and_conditions" }) })) })), _jsx("li", __assign({ className: "mb-2" }, { children: _jsx("a", __assign({ href: "https://dentobook.com/" + locale.toLowerCase() + "/gdpr/", className: "link-decoration-none font-family-roboto body-regular mb-2 font-weight-400 font-14", style: { color: '#3B4863' }, target: "_blank" }, { children: _jsx(FormattedMessage, { id: "gdpr" }) })) }))] })));
};
export default PoliciesLinks;
