var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Button, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { accessibilityRoles } from "../../utils/accessUtils";
import AccessControl from "../common/AccessControl";
import ClinicSearchForm from "./ClinicSearchFrom";
import ClinicGeneralCard from "./cards/ClinicGeneralCard";
var Anamnesis = function () {
    var _a = useState(0), toothTab = _a[0], setToothTab = _a[1];
    var _b = useState(''), keyword = _b[0], setKeyword = _b[1];
    var _c = useState(false), isIllnessModalVisible = _c[0], setIsIllnessModalVisible = _c[1];
    var _d = useState(false), isAllergyModalVisible = _d[0], setIsAllergyModalVisible = _d[1];
    var _e = useState(false), isMedicationModalVisible = _e[0], setIsMedicationModalVisible = _e[1];
    var closeModal = function () {
        setIsIllnessModalVisible(false);
        setIsAllergyModalVisible(false);
        setIsMedicationModalVisible(false);
    };
    var onAddButtonClick = function () {
        if (toothTab === 0) {
            setIsIllnessModalVisible(true);
        }
        else if (toothTab === 1) {
            setIsAllergyModalVisible(true);
        }
        else if (toothTab === 2) {
            setIsMedicationModalVisible(true);
        }
    };
    var renderCard = function () {
        if (toothTab === 0) {
            return _jsx(ClinicGeneralCard, { type: "ILLNESS", showModal: isIllnessModalVisible, onClose: function () { return closeModal(); }, keyword: keyword });
        }
        else if (toothTab === 1) {
            return _jsx(ClinicGeneralCard, { type: "ALLERGY", showModal: isAllergyModalVisible, onClose: function () { return closeModal(); }, keyword: keyword });
        }
        else if (toothTab === 2) {
            return _jsx(ClinicGeneralCard, { type: "MEDICATION", showModal: isMedicationModalVisible, onClose: function () { return closeModal(); }, keyword: keyword });
        }
    };
    return (_jsxs(AccessControl, __assign({ accessibleFor: [accessibilityRoles.CLINIC_ADMIN, accessibilityRoles.MEDICAL_DOCTOR, accessibilityRoles.MEDICAL_ASSISTANT, accessibilityRoles.RECEPTIONIST] }, { children: [_jsx(Col, { children: _jsx(ClinicSearchForm, { keyword: keyword, setKeyword: setKeyword, loadData: function () {
                    }, onAddButtonClick: onAddButtonClick }) }), _jsxs("div", __assign({ className: "dentobook-content" }, { children: [_jsxs("div", __assign({ style: { display: 'flex', height: '10%' } }, { children: [_jsx(Button, __assign({ className: "tooth-status-btn", type: toothTab === 0 ? "primary" : "default", onClick: function () { return setToothTab(0); } }, { children: _jsx(FormattedMessage, { id: "illnesses" }) })), _jsx(Button, __assign({ className: "anamnesis-allergy-btn", type: toothTab === 1 ? "primary" : "default", onClick: function () { return setToothTab(1); } }, { children: _jsx(FormattedMessage, { id: "allergies" }) })), _jsx(Button, __assign({ className: "tooth-plan-btn", type: toothTab === 2 ? "primary" : "default", onClick: function () { return setToothTab(2); } }, { children: _jsx(FormattedMessage, { id: "medications" }) }))] })), renderCard()] }))] })));
};
export default Anamnesis;
