var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import '../../assets/css/Dentobook.css';
import '../../assets/css/ExcuseNoteModal.css';
import React, { useContext, useEffect, useState } from "react";
import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { CaretDownOutlined, CloseOutlined } from "@ant-design/icons";
import { AlertContext } from "../../context/AlertContext";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";
import { MedicalStaffApiFactory, NZIZApiFactory, PatientApiFactory } from "dentobook-api-typescript-client/api";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { uuid } from "@ant-design/plots/es/utils";
import { getAdditionalKeyByTownKey } from "../../i18n/health-area";
import { transliterateCyrillicToLatin } from "../../utils/medicalUtils";
import moment from "moment/moment";
import { esignAppEndpoint, getQualificationCode, getToken, validateNzisMedicalNoticeIssueRequest } from '../../i18n/nzis-utils';
var ExcuseNotes = function (_a) {
    var isModalOpen = _a.isModalOpen, setIsModalOpen = _a.setIsModalOpen, statuses = _a.statuses, patient = _a.patient;
    var intl = useIntl();
    var Option = Select.Option;
    var _b = useContext(AlertContext), setError = _b.setError, setVisible = _b.setVisible, setSuccessMessage = _b.setSuccessMessage;
    var form = useForm()[0];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(dayjs()), startDate = _d[0], setStartDate = _d[1];
    var _e = useState(dayjs()), endDate = _e[0], setEndDate = _e[1];
    var _f = useState(''), diagnose = _f[0], setDiagnose = _f[1];
    var _g = useState(''), institution = _g[0], setInstitution = _g[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var nzisApi = NZIZApiFactory(apiConfig, '/api');
    var medicalApi = MedicalStaffApiFactory(apiConfig, '/api');
    var patientApi = PatientApiFactory(apiConfig, '/api');
    useEffect(function () {
        var patientFullName = patient.firstName + ' ' + patient.secondName + ' ' + patient.lastName;
        form.setFieldValue('patientName', patientFullName);
        form.setFieldValue('startDate', dayjs());
        form.setFieldValue('endDate', dayjs());
        form.setFieldValue('institution', '');
    }, [isModalOpen]);
    var handleServerError = function (err) {
        console.log(err);
        setLoading(false);
        setError(intl.formatMessage({ id: 'nzis_data_fail' }));
        setTimeout(function () {
            setError('');
        }, 10000);
    };
    var mkbItems = [
        { key: 1, description: "Кариес на зъбите", code: 'K02.1' },
        { key: 2, description: "Пулпит на зъбите", code: 'K04.0' },
        { key: 3, description: "Периодонтит", code: 'K04.5' },
        { key: 4, description: "Фрактура на зъбите", code: 'K08.8' },
        { key: 5, description: "Частична адентия", code: 'K00.0' },
        { key: 6, description: "Тотална адентия", code: 'K00.0' },
        { key: 7, description: "Пародонтит / гингивит / периимплантит", code: 'K05' },
        { key: 8, description: "Състояние след девитализация", code: 'K04.9' },
        { key: 9, description: "Девитализиране по протетична причина", code: 'K04.9' }
    ];
    var handleOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, mkb, toothTreatments, foundToothTreatment, today, todayDate, startDateFormatted, endDateFormatted, patientBirthDate, patientAge, medicalStaff, qualification, missingFields, fieldsString, errorMessage, header, contents, medicalNoticeIssueRequest, token, responseXml, responseSignedXml, signedXml, responseOne, err_1;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0: return [4 /*yield*/, form.validateFields()];
                case 1:
                    res = _g.sent();
                    if ((_a = res.errorFields) === null || _a === void 0 ? void 0 : _a.length) {
                        return [2 /*return*/];
                    }
                    _g.label = 2;
                case 2:
                    _g.trys.push([2, 13, , 14]);
                    setLoading(true);
                    mkb = (_b = mkbItems.find(function (item) { return item.key === +diagnose; })) === null || _b === void 0 ? void 0 : _b.code;
                    return [4 /*yield*/, patientApi.fetchAllPatientTreatments(patient.id)];
                case 3:
                    toothTreatments = (_g.sent()).data;
                    foundToothTreatment = toothTreatments
                        .filter(function (toothTreatment) { return toothTreatment.nrnDental; })
                        .sort(function (toothTreatmentOne, toothTreatmentTwo) { var _a; return (_a = toothTreatmentTwo.createdAt) === null || _a === void 0 ? void 0 : _a.localeCompare(toothTreatmentOne.createdAt); })[0];
                    today = moment().format('yyyy-MM-DDThh:mm:ss');
                    todayDate = moment().format('yyyy-MM-DD');
                    startDateFormatted = startDate.format('YYYY-MM-DD');
                    endDateFormatted = endDate.format('YYYY-MM-DD');
                    patientBirthDate = moment(patient === null || patient === void 0 ? void 0 : patient.birthDate).format('yyyy-MM-DD');
                    patientAge = patient.birthDate ? dayjs(new Date())
                        .diff(patient.birthDate.toString(), 'year').toString() : '0';
                    return [4 /*yield*/, medicalApi.fetchMedicalStaffById(foundToothTreatment.medicalStaffId)];
                case 4:
                    medicalStaff = (_g.sent()).data;
                    qualification = void 0;
                    if (medicalStaff.specialisationCode !== undefined) {
                        qualification = getQualificationCode(medicalStaff.specialisationCode);
                    }
                    else {
                        qualification = '';
                    }
                    missingFields = validateNzisMedicalNoticeIssueRequest(medicalStaff, patient, foundToothTreatment, intl.locale);
                    if (missingFields.length) {
                        fieldsString = missingFields.join(', ');
                        errorMessage = intl.formatMessage({ id: 'nzis_mandatory_fields' }).replace('[FIELDS]', fieldsString);
                        setLoading(false);
                        setError(errorMessage);
                        return [2 /*return*/];
                    }
                    header = {
                        sender: "2",
                        senderId: '010000027',
                        senderISName: "Dentobook 1.0",
                        recipient: "4",
                        recipientId: "NHIS",
                        messageId: uuid(),
                        messageType: "C041",
                        createdOn: today,
                    };
                    contents = {
                        medicalNotice: {
                            lrn: uuid(),
                            basedOn: foundToothTreatment.nrnDental,
                            authoredOn: todayDate,
                            reason: '1',
                            code: mkb,
                            fromDate: startDateFormatted,
                            toDate: endDateFormatted,
                            location: '1',
                            institution: transliterateCyrillicToLatin(institution),
                            purpose: '',
                            note: '',
                        },
                        subject: {
                            identifierType: '5',
                            identifier: patient.egn,
                            nhifInsuranceNumber: '',
                            birthDate: patientBirthDate,
                            gender: '1',
                            name: {
                                given: patient.firstName,
                                middle: patient.secondName,
                                family: patient.lastName,
                            },
                            address: {
                                country: 'BG',
                                county: getAdditionalKeyByTownKey(patient.healthArea, intl.locale),
                                ekatte: patient.ekatte,
                                city: patient.town ? transliterateCyrillicToLatin(patient.town) : '',
                                line: patient.addressOne ? transliterateCyrillicToLatin(patient.addressOne) : '',
                                postalCode: '',
                            },
                            nationality: '',
                            phone: (_c = patient.phone) !== null && _c !== void 0 ? _c : '',
                            email: (_d = patient.email) !== null && _d !== void 0 ? _d : '',
                            various: {
                                age: patientAge
                            }
                        },
                        performer: {
                            pmi: '010000027',
                            qualification: qualification,
                            role: '1',
                            practiceNumber: '1232344545',
                            rhifAreaNumber: '',
                            nhifNumber: '',
                            phone: (_e = medicalStaff.phone) !== null && _e !== void 0 ? _e : '',
                            email: (_f = medicalStaff.email) !== null && _f !== void 0 ? _f : '',
                        },
                    };
                    medicalNoticeIssueRequest = {
                        header: header,
                        contents: contents
                    };
                    return [4 /*yield*/, getToken(nzisApi)];
                case 5:
                    token = (_g.sent()).data.token;
                    return [4 /*yield*/, nzisApi.issueNZISmedicalNoticeXml(medicalNoticeIssueRequest)];
                case 6:
                    responseXml = (_g.sent()).data;
                    return [4 /*yield*/, fetch(esignAppEndpoint, {
                            method: 'post',
                            headers: {
                                'Accept': 'application/xml',
                                'Content-Type': 'application/xml'
                            },
                            body: responseXml
                        })];
                case 7:
                    responseSignedXml = _g.sent();
                    return [4 /*yield*/, responseSignedXml.text()];
                case 8:
                    signedXml = _g.sent();
                    return [4 /*yield*/, nzisApi.issueNZISmedicalNotice({ token: token, data: signedXml })];
                case 9:
                    responseOne = _g.sent();
                    setLoading(false);
                    if (!!responseOne.data) return [3 /*break*/, 11];
                    return [4 /*yield*/, patientApi.createNotice({
                            patient: { id: patient.id },
                            mkb: mkb,
                            institution: transliterateCyrillicToLatin(institution),
                            startDate: startDate === null || startDate === void 0 ? void 0 : startDate.toISOString(),
                            endDate: endDate === null || endDate === void 0 ? void 0 : endDate.toISOString()
                        })];
                case 10:
                    _g.sent();
                    setLoading(false);
                    setIsModalOpen(false);
                    setVisible(true);
                    setSuccessMessage(intl.formatMessage({ id: 'success_message' }));
                    setTimeout(function () { return setVisible(false); }, 10000);
                    return [3 /*break*/, 12];
                case 11:
                    setLoading(false);
                    setIsModalOpen(false);
                    handleServerError({});
                    return [2 /*return*/];
                case 12: return [3 /*break*/, 14];
                case 13:
                    err_1 = _g.sent();
                    console.log(err_1);
                    setLoading(false);
                    handleServerError(err_1);
                    return [2 /*return*/];
                case 14: return [2 /*return*/];
            }
        });
    }); };
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    return (_jsx(Modal, __assign({ transitionName: "", className: 'dentobook-modal', okButtonProps: {
            className: "modal-btn"
        }, cancelButtonProps: {
            className: "modal-btn"
        }, title: _jsx(FormattedMessage, { id: "create_note" }), width: 720, confirmLoading: loading, okText: _jsx(FormattedMessage, { id: "confirm" }), cancelText: _jsx(FormattedMessage, { id: "cancel" }), closeIcon: _jsx(CloseOutlined, { className: 'icon-24px', onClick: handleCancel }), open: isModalOpen, onOk: handleOk, onCancel: handleCancel }, { children: _jsxs(Form, __assign({ layout: "vertical", form: form }, { children: [_jsx(Form.Item, __assign({ name: "patientName", className: "dentobook-form-item dentobook-simple-input mb-32", initialValue: patient.firstName, label: _jsx(FormattedMessage, { id: "name" }) }, { children: _jsx(Input, { disabled: true, bordered: false, value: patient.firstName }) })), _jsxs(Row, __assign({ gutter: 24 }, { children: [_jsx(Col, __assign({ md: 12 }, { children: _jsx(Form.Item, __assign({ name: "startDate", className: "dentobook-form-item", initialValue: startDate, label: _jsx(FormattedMessage, { id: "start_date" }), rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(DatePicker, { allowClear: false, className: "input-fill-width", size: "large", bordered: true, name: "date", format: "DD.MM.YYYY", onChange: function (e) {
                                        if (e === null) {
                                            setStartDate(undefined);
                                        }
                                        else {
                                            setStartDate(e);
                                        }
                                    }, placeholder: "" }) })) })), _jsx(Col, __assign({ md: 12 }, { children: _jsx(Form.Item, __assign({ name: "endDate", className: "dentobook-form-item", initialValue: endDate, label: _jsx(FormattedMessage, { id: "end_date" }), rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(DatePicker, { allowClear: false, className: "input-fill-width", size: "large", bordered: true, name: "date", format: "DD.MM.YYYY", onChange: function (e) {
                                        if (e === null) {
                                            setEndDate(undefined);
                                        }
                                        else {
                                            setEndDate(e);
                                        }
                                    }, placeholder: "" }) })) }))] })), _jsx(Form.Item, __assign({ name: "diagnose", className: "dentobook-form-item dentobook-simple-input input-fill-width", label: _jsx(FormattedMessage, { id: "condition" }), rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Select, __assign({ placeholder: _jsx(FormattedMessage, { id: "selectPlaceholder" }), size: "large", value: diagnose, onChange: function (e) { return setDiagnose(e); }, suffixIcon: _jsx(CaretDownOutlined, {}), allowClear: true }, { children: mkbItems.map(function (item) {
                            return (_jsx(Option, __assign({ value: item.key }, { children: item.description }), item.key));
                        }) })) })), _jsx(Form.Item, __assign({ name: "institution", className: "dentobook-form-item dentobook-simple-input mb-32", initialValue: institution, label: _jsx(FormattedMessage, { id: "serve_to" }), rules: [
                        {
                            required: true,
                            message: _jsx(FormattedMessage, { id: "required_field" })
                        },
                        {
                            pattern: /^[a-zA-Zа-яА-Я\-\s]+$/,
                            message: _jsx(FormattedMessage, { id: "letters_only_field" })
                        }
                    ] }, { children: _jsx(Input, { value: institution, onChange: function (e) { return setInstitution(e.target.value); } }) }))] })) })));
};
export default ExcuseNotes;
