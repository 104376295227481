var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import "../../assets/css/Dentobook.css";
import { InvoiceDocumentTypeEnum, InvoicesApiFactory } from "dentobook-api-typescript-client/api";
import { notification, Select, Space, Table, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { CaretDownFilled, CheckCircleOutlined, EditOutlined, FileAddOutlined, PrinterOutlined } from "@ant-design/icons";
import '../../assets/css/PatientFinances.css';
import { Configuration } from "dentobook-api-typescript-client/configuration";
import dayjs from "dayjs";
import PatientProformaUpdateModal from "./PatientProformaUpdateModal";
import PatientInvoiceVoidModal from "./PatientInvoiceVoidModal";
import PatientInvoiceGenerateModal from "./PatientInvoiceGenerateModal";
var PatientFinancesProformaInvoices = function (_a) {
    var patient = _a.patient;
    var _b = useState([]), patientInvoices = _b[0], setPatientInvoices = _b[1];
    var _c = useState(false), updateProformaModalOpen = _c[0], setUpdateProformaModalOpen = _c[1];
    var _d = useState(null), selectedInvoice = _d[0], setSelectedInvoice = _d[1];
    var _e = useState(0), totalPaid = _e[0], setTotalPaid = _e[1];
    var _f = useState(0), totalUnpaid = _f[0], setTotalUnpaid = _f[1];
    var _g = useState(false), loading = _g[0], setLoading = _g[1];
    var _h = useState(false), isVoidModalOpen = _h[0], setIsVoidModalOpen = _h[1];
    var _j = useState(false), isInvoiceGenerateOpen = _j[0], setIsInvoiceGenerateOpen = _j[1];
    var intl = useIntl();
    var apiConfig = new Configuration({ basePath: '/api' });
    var invoicesApi = InvoicesApiFactory(apiConfig, '/api');
    useEffect(function () {
        if (!patient.id) {
            return;
        }
        loadData();
    }, []);
    var loadData = function () {
        invoicesApi.fetchInvoicesByPatientId(patient.id)
            .then(function (res) {
            var filteredInvoices = res.data.filter(function (invoice) { return invoice.documentType === InvoiceDocumentTypeEnum.ProformaInvoice; });
            setPatientInvoices(filteredInvoices);
            var paidInvoices = filteredInvoices.filter(function (invoice) { return invoice.status === 'PAID'; });
            var unpaidInvoices = filteredInvoices.filter(function (invoice) { return invoice.status === 'UNPAID_PROFORMA'; });
            var totalPaidSum = paidInvoices.reduce(function (acc, invoice) { return acc + (invoice.total || 0); }, 0);
            var totalUnpaidSum = unpaidInvoices.reduce(function (acc, invoice) { return acc + (invoice.total || 0); }, 0);
            setTotalPaid(totalPaidSum);
            setTotalUnpaid(totalUnpaidSum);
        })
            .catch(function (err) { return console.log(err); });
    };
    var handleStatusChange = function (invoiceId, value) {
        var updatedStatus = value === '0' ? 'UNPAID_PROFORMA' : 'CANCELLED_PROFORMA';
        var invoiceToUpdate = patientInvoices.find(function (invoice) { return invoice.id === invoiceId; });
        if (invoiceToUpdate) {
            var updatedInvoice = __assign(__assign({}, invoiceToUpdate), { status: updatedStatus });
            if (updatedInvoice.id != null) {
                if (updatedStatus === 'CANCELLED_PROFORMA') {
                    setIsVoidModalOpen(true);
                    setSelectedInvoice(invoiceToUpdate);
                }
                else {
                    invoicesApi.updateInvoice(updatedInvoice.id, updatedInvoice)
                        .then(function () {
                        loadData();
                    })
                        .catch(function (err) { return console.error(err); });
                }
            }
        }
    };
    var handleEditClick = function (invoice) {
        setSelectedInvoice(invoice);
        setUpdateProformaModalOpen(true);
    };
    var handleConvertProforma = function (invoice) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    if (!(invoice.id != null)) return [3 /*break*/, 2];
                    return [4 /*yield*/, invoicesApi.convertInvoice(invoice.id)];
                case 1:
                    _a.sent();
                    notification.success({
                        message: 'Фактурата е създадена успешно.',
                        description: '',
                        placement: 'topRight',
                        duration: 3,
                        style: {
                            backgroundColor: '#f6ffed',
                            border: '1px solid #b7eb8f',
                            borderRadius: '4px',
                            padding: '16px',
                            display: 'flex',
                            alignItems: 'center',
                        },
                        icon: _jsx(CheckCircleOutlined, { style: { color: '#52c41a' } })
                    });
                    _a.label = 2;
                case 2: return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 5];
                case 4:
                    loadData();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var generatePdf = function (invoice) { return __awaiter(void 0, void 0, void 0, function () {
        var pdfResponse, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, invoicesApi.generateInvoicePdf(invoice.id)];
                case 2:
                    pdfResponse = _a.sent();
                    window.open(pdfResponse.request.responseURL);
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    console.error(error_2);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var cashPaymentMethod = intl.formatMessage({ id: 'cash_payment_type' });
    var cardPaymentMethod = intl.formatMessage({ id: 'card_payment_type' });
    var bankTransferPaymentMethod = intl.formatMessage({ id: 'bank_transfer' });
    var data = patientInvoices.map(function (invoice) {
        var selectClass = '';
        var selectValue = '';
        var selectDisabled = false;
        switch (invoice.status) {
            case 'CANCELLED_PROFORMA':
                selectClass = 'dentobook-finances-select-canceled';
                selectValue = '2';
                selectDisabled = true;
                break;
            case 'UNPAID_PROFORMA':
                selectClass = 'dentobook-finances-select-unpaid';
                selectValue = '0';
                break;
        }
        var paymentMethod = '';
        switch (invoice.paymentMethod) {
            case "BANK_TRANSFER":
                paymentMethod = bankTransferPaymentMethod;
                break;
            case "CARD":
                paymentMethod = cardPaymentMethod;
                break;
            default:
                paymentMethod = cashPaymentMethod;
                break;
        }
        return {
            key: invoice.id,
            number: invoice.invoiceNumber,
            date: dayjs(invoice.date).utc().format('DD.MM.YYYY'),
            maturity: dayjs(invoice.paymentDueDate).utc().format('DD.MM.YYYY'),
            price_value: invoice.total.toString(),
            payment_type: paymentMethod,
            status: _jsxs("div", __assign({ style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } }, { children: [_jsxs(Select, __assign({ dropdownStyle: { width: 130 }, className: selectClass, suffixIcon: _jsx(CaretDownFilled, {}), value: selectValue, onChange: function (value) { return handleStatusChange(invoice.id, value); }, disabled: selectDisabled }, { children: [_jsx(Select.Option, __assign({ value: "2" }, { children: _jsx(FormattedMessage, { id: "canceled_invoice" }) })), _jsx(Select.Option, __assign({ value: "0" }, { children: _jsx(FormattedMessage, { id: "unpaid_invoice" }) }))] }), invoice.id), _jsxs(Space, __assign({ size: "large" }, { children: [_jsx(Tooltip, __assign({ title: intl.formatMessage({ id: 'generate_invoice_tooltip' }) }, { children: _jsx(FileAddOutlined, { style: { fontSize: 22 }, onClick: function () {
                                        setSelectedInvoice(invoice);
                                        setIsInvoiceGenerateOpen(true);
                                    } }) })), _jsx(Tooltip, __assign({ title: intl.formatMessage({ id: 'edit_tooltip' }) }, { children: _jsx(EditOutlined, { style: { fontSize: 22 }, onClick: function () { return handleEditClick(invoice); } }) })), _jsx(Tooltip, __assign({ title: intl.formatMessage({ id: 'print_tooltip' }) }, { children: _jsx(PrinterOutlined, { onClick: function () { return generatePdf(invoice); }, style: { fontSize: 22 } }) }))] }))] }))
        };
    });
    var proformasColumns = [
        {
            title: '№:',
            dataIndex: 'number',
            key: 'number',
            width: '12%'
        },
        {
            title: useIntl().formatMessage({ id: 'date' }),
            dataIndex: 'date',
            key: 'date',
            width: '12%'
        },
        {
            title: useIntl().formatMessage({ id: 'maturity' }),
            dataIndex: 'maturity',
            key: 'maturity',
            width: '12%'
        },
        {
            title: useIntl().formatMessage({ id: 'price_value' }),
            dataIndex: 'price_value',
            key: 'price_value',
            width: '12%'
        },
        {
            title: useIntl().formatMessage({ id: 'payment_type' }),
            dataIndex: 'payment_type',
            key: 'payment_type',
            width: '12%'
        },
        {
            title: useIntl().formatMessage({ id: 'status' }),
            dataIndex: 'status',
            key: 'status'
        },
    ];
    if (loading) {
        return (_jsx("h1", { children: _jsx(FormattedMessage, { id: "loading" }) }));
    }
    var tableFooter = function () { return (_jsxs("div", __assign({ className: "dentobook-operations-table-footer" }, { children: [_jsxs("span", __assign({ className: "font-weight-700" }, { children: [_jsx(FormattedMessage, { id: "paid_obligations" }), ": ", totalPaid ? totalPaid + ' лв' : '00.00 лв'] })), _jsxs("span", __assign({ className: "font-weight-700" }, { children: [_jsx(FormattedMessage, { id: "obligations" }), ": ", totalUnpaid ? totalUnpaid + ' лв' : '00.00 лв'] }))] }))); };
    return (_jsxs(_Fragment, { children: [selectedInvoice && (_jsxs(_Fragment, { children: [_jsx(PatientProformaUpdateModal, { patient: patient, isModalOpen: updateProformaModalOpen, setIsModalOpen: function (isOpen) {
                            setUpdateProformaModalOpen(isOpen);
                            if (!isOpen)
                                loadData(); // Call loadData when modal is closed
                        }, proforma: selectedInvoice }), _jsx(PatientInvoiceVoidModal, { proforma: true, isModalOpen: isVoidModalOpen, setIsModalOpen: setIsVoidModalOpen, invoice: selectedInvoice, loadData: loadData }), _jsx(PatientInvoiceGenerateModal, { invoice: selectedInvoice, isModalOpen: isInvoiceGenerateOpen, setIsModalOpen: setIsInvoiceGenerateOpen, convertProforma: handleConvertProforma })] })), _jsx("h3", __assign({ className: "font-family-roboto font-weight-500 font-24" }, { children: _jsx(FormattedMessage, { id: "proforma_invoices" }) })), _jsx("div", __assign({ style: { marginTop: -28 }, className: "dentobook-content dentobook-scrollable-table mb-32" }, { children: _jsx(Table, { footer: tableFooter, className: "teeth-table pt-2", columns: proformasColumns, dataSource: data, pagination: false }) }))] }));
};
export default PatientFinancesProformaInvoices;
