var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Select, Space } from 'antd';
import "../../assets/css/Dentobook.css";
import "../../assets/css/PatientPhotos.css";
import { MedicalStaffApiFactory, NZIZApiFactory, PatientApiFactory } from "dentobook-api-typescript-client/api";
import { FormattedMessage, useIntl } from "react-intl";
import { CaretDownOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import PatientAppointmentModal from "./PatientAppontmentModal";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import PatientToothStatusCard from "./PatientToothStatusCard";
import PatientTreatmentPlanCard from "./PatientTreatmentPlanCard";
import { useForm } from "antd/es/form/Form";
import "../../assets/css/PatientTreatment.css";
import { isMobileOnly } from "react-device-detect";
var PatientTreatment = function (_a) {
    var patient = _a.patient, selectedAppointment = _a.selectedAppointment, setSelectedAppointment = _a.setSelectedAppointment;
    var _b = useState(false), multiSelectionMode = _b[0], setMultiSelectionMode = _b[1];
    var _c = useState(''), mode = _c[0], setMode = _c[1];
    var _d = useState(false), isAppointmentModalOpen = _d[0], setIsAppointmentModalOpen = _d[1];
    var _e = useState([]), appointments = _e[0], setAppointments = _e[1];
    var _f = useState(new Map()), appointmentsMap = _f[0], setAppointmentsMap = _f[1];
    var _g = useState(0), toothTab = _g[0], setToothTab = _g[1];
    var _h = useState([]), teethPositions = _h[0], setTeethPositions = _h[1];
    var _j = useState([]), statuses = _j[0], setStatuses = _j[1];
    var _k = useState([]), nzisToothConditionCodes = _k[0], setNzisToothConditionCodes = _k[1];
    var _l = useState([]), nzisActivities = _l[0], setNzisActivities = _l[1];
    var appointmentForm = useForm()[0];
    var apiConfig = new Configuration({ basePath: '/api' });
    var patientApi = PatientApiFactory(apiConfig, '/api');
    var medicalApi = MedicalStaffApiFactory(apiConfig, '/api');
    var nzisApi = NZIZApiFactory(apiConfig, '/api');
    var handleKeyDown = function (e) {
        if (e.ctrlKey || e.key === 'Meta') {
            setMultiSelectionMode(true);
        }
    };
    var handleKeyUp = function () {
        setMultiSelectionMode(false);
    };
    useEffect(function () {
        window.addEventListener('keydown', handleKeyDown, true);
        window.addEventListener('keyup', handleKeyUp, true);
        loadData();
        return function () {
            window.removeEventListener('keydown', handleKeyDown, true);
            window.removeEventListener('keyup', handleKeyUp, true);
        };
    }, []);
    var loadData = function () {
        if (!patient.id) {
            return;
        }
        patientApi.fetchPatientAppointments(patient.id)
            .then(function (res) {
            var appointmentsArray = res.data
                .sort(function (appointmentOne, appointmentTwo) { var _a; return (_a = appointmentTwo.createdAt) === null || _a === void 0 ? void 0 : _a.localeCompare(appointmentOne.createdAt); });
            setAppointments(appointmentsArray);
            if (res.data.length) {
                res.data = res.data
                    .sort(function (appointmentItemOne, appointmentItemTwo) { var _a; return (_a = appointmentItemTwo.createdAt) === null || _a === void 0 ? void 0 : _a.localeCompare(appointmentItemOne.createdAt); });
                var map = new Map();
                for (var _i = 0, _a = res.data; _i < _a.length; _i++) {
                    var appointmentItem = _a[_i];
                    map.set(appointmentItem.id, appointmentItem);
                }
                setAppointmentsMap(map);
                if (mode === 'create' || !selectedAppointment.id) {
                    setSelectedAppointment(res.data[0]);
                    appointmentForm.setFieldValue('appointmentName', res.data[0].id);
                }
                setMode('');
            }
            medicalApi.fetchMedicalStatuses([])
                .then(function (res) {
                setStatuses(res.data);
                nzisApi.fetchToothConditionCodes()
                    .then(function (res) {
                    setNzisToothConditionCodes(res.data);
                    nzisApi.fetchDentalActivityTypes()
                        .then(function (res) {
                        setNzisActivities(res.data);
                    })
                        .catch(function (err) { return console.log(err); });
                })
                    .catch(function (err) { return console.log(err); });
            });
        })
            .catch(function (err) { return console.log(err); });
    };
    var fetchTeeth = function () {
        if (!selectedAppointment) {
            return;
        }
        return patientApi.fetchPatientTeeth(selectedAppointment.id)
            .then(function (res) {
            setTeethPositions(res.data.sort(function (toothOne, toothTwo) {
                if (!toothOne.order_number || !toothTwo.order_number)
                    return 0;
                return toothOne.order_number - toothTwo.order_number;
            }));
        })
            .catch(function (err) { return console.log(err); });
    };
    var openAppointmentModal = function (modalMode) {
        setMode(modalMode);
        setIsAppointmentModalOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx(PatientAppointmentModal, { mode: mode, appointment: selectedAppointment, patient: patient, loadData: loadData, appointments: appointments, isModalOpen: isAppointmentModalOpen, setIsModalOpen: setIsAppointmentModalOpen }), _jsxs("div", __assign({ className: "dentobook-content" }, { children: [_jsxs(Row, __assign({ className: "mt-16 mb-12" }, { children: [_jsx(Form, __assign({ layout: "vertical", form: appointmentForm }, { children: _jsx(Form.Item, __assign({ name: "appointmentName", label: _jsx(FormattedMessage, { id: "appointments" }), className: "dentobook-form-item", initialValue: selectedAppointment.id }, { children: _jsx(Select, __assign({ style: !isMobileOnly ? { width: '70vw' } : {}, value: selectedAppointment.id, size: "large", className: "icon-select", suffixIcon: _jsx(CaretDownOutlined, {}), placeholder: useIntl().formatMessage({ id: 'choose_appointment' }), onChange: function (e) { return setSelectedAppointment(appointmentsMap.get(e)); } }, { children: appointments.map(function (appointment) { return (_jsx(Select.Option, __assign({ value: appointment.id }, { children: appointment.name }), appointment.id)); }) })) })) })), _jsxs(Space, __assign({ direction: "horizontal", className: "mt-8" }, { children: [_jsx(Button, __assign({ disabled: !selectedAppointment.id, shape: "circle", size: "large", className: "ml-16", onClick: function () { return openAppointmentModal('edit'); } }, { children: _jsx(EditOutlined, { className: "color-primary" }) })), _jsx(Button, __assign({ shape: "circle", size: "large", className: "ml-4", onClick: function () { return openAppointmentModal('create'); } }, { children: _jsx(PlusOutlined, { className: "color-primary" }) }))] }))] })), selectedAppointment.id ?
                        _jsxs(_Fragment, { children: [_jsx(Button, __assign({ className: "tooth-status-btn", type: toothTab === 0 ? "primary" : "default", onClick: function () { return setToothTab(0); } }, { children: _jsx(FormattedMessage, { id: "tooth_status" }) })), _jsx(Button, __assign({ className: "tooth-plan-btn", type: toothTab === 1 ? "primary" : "default", onClick: function () { return setToothTab(1); } }, { children: _jsx(FormattedMessage, { id: "treatment_plan" }) })), toothTab === 0 ?
                                    _jsx(PatientToothStatusCard, { multiSelectionMode: multiSelectionMode, appointment: selectedAppointment, fetchTeeth: fetchTeeth, teethPositions: teethPositions, statuses: statuses, nzisToothConditionCodes: nzisToothConditionCodes, patient: patient }) :
                                    _jsx(PatientTreatmentPlanCard, { multiSelectionMode: multiSelectionMode, appointment: selectedAppointment, fetchTeeth: fetchTeeth, statuses: statuses, teethPositions: teethPositions, nzisActivities: nzisActivities, nzisToothConditionCodes: nzisToothConditionCodes, patient: patient })] })
                        : _jsx("h3", { children: _jsx(FormattedMessage, { id: "no_selected_appointment" }) })] }))] }));
};
export default PatientTreatment;
