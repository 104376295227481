var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import "../../assets/css/Dentobook.css";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import PatientFinancesOperations from "./PatientFinancesOperations";
import PatientFinancesInvoices from "./PatientFinancesInvoices";
import PatientFinancesProformaInvoices from "./PatientFinancesProformaInvoices";
var PatientFinancesTabs = function (_a) {
    var patient = _a.patient;
    var _b = useState('operations'), selectedTab = _b[0], setSelectedTab = _b[1];
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "mt-24 mb-32", style: { display: 'flex', flexDirection: 'row', overflowX: 'scroll', borderBottom: 'solid 1px #B8B8B8' } }, { children: [_jsx(Button, __assign({ className: "tabs-start-btn", onClick: function () { return setSelectedTab('operations'); }, type: selectedTab === 'operations' ? 'primary' : 'default' }, { children: _jsx(FormattedMessage, { id: "done_operations" }) })), _jsx(Button, __assign({ className: "tabs-middle-btn", onClick: function () { return setSelectedTab('invoices'); }, type: selectedTab === 'invoices' ? 'primary' : 'default' }, { children: _jsx(FormattedMessage, { id: "invoices" }) })), _jsx(Button, __assign({ className: "tabs-end-btn", onClick: function () { return setSelectedTab('proforma'); }, type: selectedTab === 'proforma' ? 'primary' : 'default' }, { children: _jsx(FormattedMessage, { id: "proforma_invoices" }) }))] })), selectedTab === 'operations' ? _jsx(PatientFinancesOperations, { patient: patient }) : null, selectedTab === 'invoices' ? _jsx(PatientFinancesInvoices, { patient: patient }) : null, selectedTab === 'proforma' ? _jsx(PatientFinancesProformaInvoices, { patient: patient }) : null] }));
};
export default PatientFinancesTabs;
