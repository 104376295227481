import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import '../assets/css/Dentobook.css';
import PatientForm from "../components/patient/PatientForm";
import DetailWrapper from "../components/common/DetailWrapper";
var PatientSelfRegister = function () {
    var patient = {};
    return (_jsx(DetailWrapper, { children: _jsx(PatientForm, { setEditMode: function () {
            }, mode: 1, openModal: function () { }, patient: patient }) }));
};
export default PatientSelfRegister;
