var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import "../../assets/css/Dentobook.css";
import "../../assets/css/PatientPhotos.css";
import { FormattedMessage, useIntl } from "react-intl";
import { CloseOutlined } from '@ant-design/icons';
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { PatientApiFactory } from "dentobook-api-typescript-client/api";
import { AlertContext } from "../../context/AlertContext";
import dayjs from "dayjs";
var PatientAppointmentModal = function (_a) {
    var mode = _a.mode, appointments = _a.appointments, appointment = _a.appointment, patient = _a.patient, loadData = _a.loadData, isModalOpen = _a.isModalOpen, setIsModalOpen = _a.setIsModalOpen;
    var intl = useIntl();
    var setVisible = useContext(AlertContext).setVisible;
    var _b = useState(false), duplicatedName = _b[0], setDuplicatedName = _b[1];
    var _c = useState(''), name = _c[0], setName = _c[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var patientApi = PatientApiFactory(apiConfig, '/api');
    useEffect(function () {
        var formName = appointment === null || appointment === void 0 ? void 0 : appointment.name;
        if (mode === 'create') {
            formName = dayjs().format('DD.MM.YYYY');
        }
        setName(formName);
        form.setFieldValue('formAppointmentName', formName);
    }, [isModalOpen]);
    var handleCancel = function () {
        setDuplicatedName(false);
        setIsModalOpen(false);
    };
    var handleOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isUniqueName, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!patient.id) {
                        return [2 /*return*/];
                    }
                    setDuplicatedName(false);
                    return [4 /*yield*/, form.validateFields()];
                case 1:
                    _a.sent();
                    isUniqueName = appointments.filter(function (appointmentItem) { return appointmentItem.name === name; }).length === 0;
                    if ((!isUniqueName && mode === 'create')
                        || (!isUniqueName && mode === 'edit' && name !== (appointment === null || appointment === void 0 ? void 0 : appointment.name))) {
                        setDuplicatedName(true);
                        return [2 /*return*/];
                    }
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 7, , 8]);
                    if (!(mode === 'create')) return [3 /*break*/, 4];
                    return [4 /*yield*/, patientApi.createPatientAppointment(patient.id, { name: name })];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, patientApi.updatePatientAppointment(patient.id, appointment === null || appointment === void 0 ? void 0 : appointment.id, { name: name })];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6:
                    setVisible(true);
                    setTimeout(function () { return setVisible(false); }, 10000);
                    loadData();
                    setDuplicatedName(false);
                    setIsModalOpen(false);
                    return [3 /*break*/, 8];
                case 7:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var modalTitle = mode === 'create' ? intl.formatMessage({ id: 'add_appointment' }) :
        intl.formatMessage({ id: 'edit_appointment' });
    var form = Form.useForm()[0];
    return (_jsx(Modal, __assign({ transitionName: "", className: 'dentobook-modal', okButtonProps: {
            className: "modal-btn"
        }, cancelButtonProps: {
            className: "modal-btn"
        }, title: modalTitle, width: 800, okText: mode === 'create' ? _jsx(FormattedMessage, { id: "create_button_text" }) : _jsx(FormattedMessage, { id: "confirm" }), cancelText: _jsx(FormattedMessage, { id: "cancel" }), closeIcon: _jsx(CloseOutlined, { className: 'icon-24px', onClick: handleCancel }), open: isModalOpen, onOk: handleOk, onCancel: handleCancel }, { children: _jsx(Form, __assign({ layout: "vertical", form: form }, { children: _jsxs(Form.Item, __assign({ name: "formAppointmentName", className: "dentobook-form-item dentobook-simple-input mb-32", initialValue: name, label: _jsx(FormattedMessage, { id: "name" }), rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: [_jsx(Input, { bordered: false, value: name, onChange: function (e) { return setName(e.target.value); } }), duplicatedName ? _jsx("span", __assign({ className: "color-error" }, { children: _jsx(FormattedMessage, { id: "duplicated_appointment" }) })) : null] })) })) })));
};
export default PatientAppointmentModal;
