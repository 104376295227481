import { LOCALES } from './locales';

const healthRegionKeys = {
    "0101": ['Благоевград - Банско', 'Blagoevgrad - Bansko'],
    "0102": ['Благоевград - Белица', 'Blagoevgrad - Belitsa'],
    "0103": ['Благоевград - Благоевград', 'Blagoevgrad - Blagoevgrad'],
    "0104": ['Благоевград - Гоце Делчев', 'Blagoevgrad - Gotse Delchev'],
    "0105": ['Благоевград - Гърмен', 'Blagoevgrad - Garmen'],
    "0106": ['Благоевград - Кресна', 'Blagoevgrad - Kresna'],
    "0107": ['Благоевград - Петрич', 'Blagoevgrad - Petrich'],
    "0108": ['Благоевград - Разлог', 'Blagoevgrad - Razlog'],
    "0109": ['Благоевград - Сандански', 'Blagoevgrad - Sandanski'],
    "0110": ['Благоевград - Сатовча', 'Blagoevgrad - Satovcha'],
    "0111": ['Благоевград - Симитли', 'Blagoevgrad - Simitli'],
    "0112": ['Благоевград - Струмяни', 'Blagoevgrad - Strumyani'],
    "0113": ['Благоевград - Хаджидимово', 'Blagoevgrad - Hadzhidimovo'],
    "0114": ['Благоевград - Якоруда', 'Blagoevgrad - Yakoruda'],
    "0201": ['Бургас - Айтос', 'Burgas - Aytos'],
    "0202": ['Бургас - Бургас', 'Burgas - Burgas'],
    "0203": ['Бургас - Камено', 'Burgas - Kameno'],
    "0204": ['Бургас - Карнобат', 'Burgas - Karnobat'],
    "0205": ['Бургас - Малко Търново', 'Burgas - Malko Tarnovo'],
    "0206": ['Бургас - Несебър', 'Burgas - Nessebar'],
    "0207": ['Бургас - Поморие', 'Burgas - Pomorie'],
    "0208": ['Бургас - Приморско', 'Burgas - Primorsko'],
    "0209": ['Бургас - Руен', 'Burgas - Ruen'],
    "0210": ['Бургас - Созопол', 'Burgas - Sozopol'],
    "0211": ['Бургас - Средец', 'Burgas - Sredets'],
    "0212": ['Бургас - Сунгурларе', 'Burgas - Sungurlare'],
    "0213": ['Бургас - Царево', 'Burgas - Tsarevo'],
    "0301": ['Варна - Аврен', 'Varna - Avren'],
    "0302": ['Варна - Аксаково', 'Varna - Aksakovo'],
    "0303": ['Варна - Белослав', 'Varna - Beloslav'],
    "0304": ['Варна - Бяла', 'Varna - Byala'],
    "0305": ['Варна - Варна', 'Varna - Varna'],
    "0306": ['Варна - Ветрино', 'Varna - Vetrino'],
    "0307": ['Варна - Вълчи дол', 'Varna - Valchi Dol'],
    "0308": ['Варна - Девня', 'Varna - Devnya'],
    "0309": ['Варна - Долни чифлик', 'Varna - Dolni Chiflik'],
    "0310": ['Варна - Дългопол', 'Varna - Dalgopol'],
    "0311": ['Варна - Провадия', 'Varna - Provadia'],
    "0312": ['Варна - Суворово', 'Varna - Suvorovo'],
    "0401": ['Велико Търново - Велико Търново', 'Veliko Tarnovo - Veliko Tarnovo'],
    "0402": ['Велико Търново - Горна Оряховица', 'Veliko Tarnovo - Gorna Oryahovitsa'],
    "0403": ['Велико Търново - Елена', 'Veliko Tarnovo - Elena'],
    "0404": ['Велико Търново - Златарица', 'Veliko Tarnovo - Zlataritsa'],
    "0405": ['Велико Търново - Лясковец', 'Veliko Tarnovo - Lyaskovets'],
    "0406": ['Велико Търново - Павликени', 'Veliko Tarnovo - Pavlikeni'],
    "0407": ['Велико Търново - Полски Тръмбеш', 'Veliko Tarnovo - Polski Trambesh'],
    "0408": ['Велико Търново - Свищов', 'Veliko Tarnovo - Svishtov'],
    "0409": ['Велико Търново - Стражица', 'Veliko Tarnovo - Strazhitsa'],
    "0410": ['Велико Търново - Сухиндол', 'Veliko Tarnovo - Suhindol'],
    "0501": ['Видин - Белоградчик', 'Vidin - Belogradchik'],
    "0502": ['Видин - Бойница', 'Vidin - Boynitsa'],
    "0503": ['Видин - Брегово', 'Vidin - Bregovo'],
    "0504": ['Видин - Видин', 'Vidin - Vidin'],
    "0505": ['Видин - Грамада', 'Vidin - Gramada'],
    "0506": ['Видин - Димово', 'Vidin - Dimovo'],
    "0507": ['Видин - Кула', 'Vidin - Kula'],
    "0508": ['Видин - Макреш', 'Vidin - Makresh'],
    "0509": ['Видин - Ново село', 'Vidin - Novo Selo'],
    "0510": ['Видин - Ружинци', 'Vidin - Ruzhintsi'],
    "0511": ['Видин - Чупрене', 'Vidin - Chuprene'],
    "0601": ['Враца - Борован', 'Vratsa - Borovan'],
    "0602": ['Враца - Бяла Слатина', 'Vratsa - Byala Slatina'],
    "0603": ['Враца - Враца', 'Vratsa - Vratsa'],
    "0605": ['Враца - Козлодуй', 'Vratsa - Kozloduy'],
    "0606": ['Враца - Криводол', 'Vratsa - Krivodol'],
    "0607": ['Враца - Мездра', 'Vratsa - Mezdra'],
    "0608": ['Враца - Мизия', 'Vratsa - Mizia'],
    "0609": ['Враца - Оряхово', 'Vratsa - Oryahovo'],
    "0610": ['Враца - Роман', 'Vratsa - Roman'],
    "0611": ['Враца - Хайредин', 'Vratsa - Hayredin'],
    "0701": ['Габрово - Габрово', 'Gabrovo - Gabrovo'],
    "0702": ['Габрово - Дряново', 'Gabrovo - Dryanovo'],
    "0703": ['Габрово - Севлиево', 'Gabrovo - Sevlievo'],
    "0704": ['Габрово - Трявна', 'Gabrovo - Tryavna'],
    "0801": ['Добрич - Балчик', 'Dobrich - Balchik'],
    "0802": ['Добрич - Генерал Тошево', 'Dobrich - General Toshevo'],
    "0803": ['Добрич - Добрич', 'Dobrich - Dobrich'],
    "0804": ['Добрич - Добрич - селска', 'Dobrich - Dobrichka Municipality'],
    "0805": ['Добрич - Каварна', 'Dobrich - Kavarna'],
    "0806": ['Добрич - Крушари', 'Dobrich - Krushari'],
    "0807": ['Добрич - Тервел', 'Dobrich - Tervel'],
    "0808": ['Добрич - Шабла', 'Dobrich - Shabla'],
    "0901": ['Кърджали - Ардино', 'Kardzhali - Ardino'],
    "0902": ['Кърджали - Джебел', 'Kardzhali - Dzhebel'],
    "0903": ['Кърджали - Кирково', 'Kardzhali - Kirkovo'],
    "0904": ['Кърджали - Крумовград', 'Kardzhali - Krumovgrad'],
    "0905": ['Кърджали - Кърджали', 'Kardzhali - Kardzhali'],
    "0906": ['Кърджали - Момчилград', 'Kardzhali - Momchilgrad'],
    "0907": ['Кърджали - Черноочене', 'Kardzhali - Chernoochene'],
    "1001": ['Кюстендил - Бобов дол', 'Kyustendil - Bobov Dol'],
    "1002": ['Кюстендил - Бобошево', 'Kyustendil - Boboshevo'],
    "1003": ['Кюстендил - Дупница', 'Kyustendil - Dupnica'],
    "1004": ['Кюстендил - Кочериново', 'Kyustendil - Kocherinovo'],
    "1005": ['Кюстендил - Кюстендил', 'Kyustendil - Kyustendil'],
    "1006": ['Кюстендил - Невестино', 'Kyustendil - Nevestino'],
    "1007": ['Кюстендил - Рила', 'Kyustendil - Rila'],
    "1008": ['Кюстендил - Сапарева баня', 'Kyustendil - Sapareva Banya'],
    "1009": ['Кюстендил - Трекляно', 'Kyustendil - Treklyano'],
    "1101": ['Ловеч - Априлци', 'Lovech - Apriltsi'],
    "1102": ['Ловеч - Летница', 'Lovech - Letnitza'],
    "1103": ['Ловеч - Ловеч', 'Lovech - Lovech'],
    "1104": ['Ловеч - Луковит', 'Lovech - Lukovit'],
    "1105": ['Ловеч - Тетевен', 'Lovech - Teteven'],
    "1106": ['Ловеч - Троян', 'Lovech - Troyan'],
    "1107": ['Ловеч - Угърчин', 'Lovech - Ugarchin'],
    "1108": ['Ловеч - Ябланица', 'Lovech - Yablanitsa'],
    "1201": ['Монтана - Берковица', 'Montana - Berkovitsa'],
    "1202": ['Монтана - Бойчиновци', 'Montana - Boychinovtsi'],
    "1203": ['Монтана - Брусарци', 'Montana - Brusartsi'],
    "1204": ['Монтана - Вълчедръм', 'Montana - Valchedram'],
    "1205": ['Монтана - Вършец', 'Montana - Varshets'],
    "1206": ['Монтана - Георги Дамяново', 'Montana - Georgi Damyanovo'],
    "1207": ['Монтана - Лом', 'Montana - Lom'],
    "1208": ['Монтана - Медковец', 'Montana - Medkovets'],
    "1209": ['Монтана - Монтана', 'Montana - Montana'],
    "1210": ['Монтана - Чипровци', 'Montana - Chiprovtsi'],
    "1211": ['Монтана - Якимово', 'Montana - Yakimovo'],
    "1301": ['Пазарджик - Батак', 'Pazardzhik - Batak'],
    "1302": ['Пазарджик - Белово', 'Pazardzhik - Belovo'],
    "1303": ['Пазарджик - Брацигово', 'Pazardzhik - Bratsigovo'],
    "1304": ['Пазарджик - Велинград', 'Pazardzhik - Velingrad'],
    "1305": ['Пазарджик - Лесичово', 'Pazardzhik - Lesichovo'],
    "1306": ['Пазарджик - Пазарджик', 'Pazardzhik - Pazardzhik'],
    "1307": ['Пазарджик - Панагюрище', 'Pazardzhik - Panagyurishte'],
    "1308": ['Пазарджик - Пещера', 'Pazardzhik - Peshtera'],
    "1309": ['Пазарджик - Ракитово', 'Pazardzhik - Rakitovo'],
    "1310": ['Пазарджик - Септември', 'Pazardzhik - Septemvri'],
    "1311": ['Пазарджик - Стрелча', 'Pazardzhik - Strelcha'],
    "1312": ['Пазарджик - Сърница', 'Pazardzhik - Sarnitsa'],
    "1401": ['Перник - Брезник', 'Pernik - Breznik'],
    "1402": ['Перник - Земен', 'Pernik - Zemen'],
    "1403": ['Перник - Ковачевци', 'Pernik - Kovachevtsi'],
    "1404": ['Перник - Перник', 'Pernik - Pernik'],
    "1405": ['Перник - Радомир', 'Pernik - Radomir'],
    "1406": ['Перник - Трън', 'Pernik - Tran'],
    "1501": ['Плевен - Белене', 'Pleven - Belene'],
    "1502": ['Плевен - Гулянци', 'Pleven - Gulyantsi'],
    "1503": ['Плевен - Долна Митрополия', 'Pleven - Dolna Mitropoliya'],
    "1504": ['Плевен - Долни Дъбник', 'Pleven - Dolni Dabnik'],
    "1505": ['Плевен - Искър', 'Pleven - Iskar'],
    "1506": ['Плевен - Левски', 'Pleven - Levski'],
    "1507": ['Плевен - Никопол', 'Pleven - Nikopol'],
    "1508": ['Плевен - Плевен', 'Pleven - Pleven'],
    "1509": ['Плевен - Пордим', 'Pleven - Pordim'],
    "1510": ['Плевен - Червен бряг', 'Pleven - Cherven Bryag'],
    "1511": ['Плевен - Кнежа', 'Pleven - Knezha'],
    "1601": ['Пловдив - Асеновград', 'Plovdiv - Asenovgrad'],
    "1602": ['Пловдив - Брезово', 'Plovdiv - Brezovo'],
    "1603": ['Пловдив - Калояново', 'Plovdiv - Kaloyanovo'],
    "1604": ['Пловдив - Карлово', 'Plovdiv - Karlovo'],
    "1605": ['Пловдив - Кричим', 'Plovdiv - Krichim'],
    "1606": ['Пловдив - Лъки', 'Plovdiv - Laki'],
    "1607": ['Пловдив - Марица', 'Plovdiv - Maritsa'],
    "1608": ['Пловдив - Перущица', 'Plovdiv - Perushtitsa'],
    "1609": ['Пловдив - Пловдив', 'Plovdiv - Plovdiv'],
    "1610": ['Пловдив - Първомай', 'Plovdiv - Parvomay'],
    "1611": ['Пловдив - Раковски', 'Plovdiv - Rakovski'],
    "1612": ['Пловдив - Родопи', 'Plovdiv - Rodopi'],
    "1613": ['Пловдив - Садово', 'Plovdiv - Sadovo'],
    "1614": ['Пловдив - Стамболийски', 'Plovdiv - Stamboliyski'],
    "1615": ['Пловдив - Съединение', 'Plovdiv - Saedinenie'],
    "1616": ['Пловдив - Хисаря', 'Plovdiv - Hisarya'],
    "1617": ['Пловдив - Куклен', 'Plovdiv - Kuklen'],
    "1618": ['Пловдив - Сопот', 'Plovdiv - Sopot'],
    "1701": ['Разград - Завет', 'Razgrad - Zavet'],
    "1702": ['Разград - Исперих', 'Razgrad - Isperih'],
    "1703": ['Разград - Кубрат', 'Razgrad - Kubrat'],
    "1704": ['Разград - Лозница', 'Razgrad - Loznitsa'],
    "1705": ['Разград - Разград', 'Razgrad - Razgrad'],
    "1706": ['Разград - Самуил', 'Razgrad - Samuil'],
    "1707": ['Разград - Цар Калоян', 'Razgrad - Tsar Kaloyan'],
    "1801": ['Русе - Борово', 'Ruse - Borovo'],
    "1802": ['Русе - Бяла', 'Ruse - Byala'],
    "1803": ['Русе - Вятово', 'Ruse - Vyatovo'],
    "1804": ['Русе - Две могили', 'Ruse - Dve mogili'],
    "1805": ['Русе - Иваново', 'Ruse - Ivanovo'],
    "1806": ['Русе - Русе', 'Ruse - Ruse'],
    "1807": ['Русе - Сливо поле', 'Ruse - Slivo pole'],
    "1808": ['Русе - Ценово', 'Ruse - Tsenovo'],
    "1901": ['Силистра - Алфатар', 'Silistra - Alfatar'],
    "1902": ['Силистра - Главница', 'Silistra - Glavnitsa'],
    "1903": ['Силистра - Дулово', 'Silistra - Dulovo'],
    "1904": ['Силистра - Кайнарджа', 'Silistra - Kaynardzha'],
    "1905": ['Силистра - Силистра', 'Silistra - Silistra'],
    "1906": ['Силистра - Ситово', 'Silistra - Sitovo'],
    "1907": ['Силистра - Тутракан', 'Silistra - Tutrakan'],
    "2001": ['Сливен - Котел', 'Sliven - Kotel'],
    "2002": ['Сливен - Нова Загора', 'Sliven - Nova Zagora'],
    "2003": ['Сливен - Сливен', 'Sliven - Sliven'],
    "2004": ['Сливен - Твърдица', 'Sliven - Tvarditsa'],
    "2101": ['Смолян - Баните', 'Smolyan - Banite'],
    "2102": ['Смолян - Борино', 'Smolyan - Borino'],
    "2103": ['Смолян - Девин', 'Smolyan - Devin'],
    "2104": ['Смолян - Доспат', 'Smolyan - Dospat'],
    "2105": ['Смолян - Златоград', 'Smolyan - Zlatograd'],
    "2106": ['Смолян - Мадан', 'Smolyan - Madan'],
    "2107": ['Смолян - Неделино', 'Smolyan - Nedelino'],
    "2108": ['Смолян - Рудозем', 'Smolyan - Rudozem'],
    "2109": ['Смолян - Смолян', 'Smolyan - Smolyan'],
    "2110": ['Смолян - Чепеларе', 'Smolyan - Chepelare'],
    "2201": ['София - София', 'Sofia - Sofia'],
    "2301": ['Софийска област - Антон', 'Sofia oblast - Anton'],
    "2302": ['Софийска област - Божурище', 'Sofia oblast - Bozhurishte'],
    "2303": ['Софийска област - Ботевград', 'Sofia oblast - Botevgrad'],
    "2304": ['Софийска област - Годеч', 'Sofia oblast - Godech'],
    "2305": ['Софийска област - Горна Малина', 'Sofia oblast - Gorna Malina'],
    "2306": ['Софийска област - Долна баня', 'Sofia oblast - Dolna banya'],
    "2307": ['Софийска област - Драгоман', 'Sofia oblast - Dragoman'],
    "2308": ['Софийска област - Елин Пелин', 'Sofia oblast - Elin Pelin'],
    "2309": ['Софийска област - Етрополе', 'Sofia oblast - Etropole'],
    "2310": ['Софийска област - Златица', 'Sofia oblast - Zlatitsa'],
    "2311": ['Софийска област - Ихтиман', 'Sofia oblast - Ihtiman'],
    "2312": ['Софийска област - Копривщица', 'Sofia oblast - Koprivshtitsa'],
    "2313": ['Софийска област - Костенец', 'Sofia oblast - Kostenets'],
    "2314": ['Софийска област - Костинброд', 'Sofia oblast - Kostinbrod'],
    "2315": ['Софийска област - Мирково', 'Sofia oblast - Mirkovo'],
    "2316": ['Софийска област - Пирдоп', 'Sofia oblast - Pirdop'],
    "2317": ['Софийска област - Правец', 'Sofia oblast - Pravets'],
    "2318": ['Софийска област - Самоков', 'Sofia oblast - Samokov'],
    "2319": ['Софийска област - Своге', 'Sofia oblast - Svoge'],
    "2320": ['Софийска област - Сливница', 'Sofia oblast - Slivnitsa'],
    "2321": ['Софийска област - Чавдар', 'Sofia oblast - Chavdar'],
    "2322": ['Софийска област - Челопеч', 'Sofia oblast - Chelopech'],
    "2401": ['Стара Загора - Братя Даскалови', 'Stara Zagora - Bratya Daskalovi'],
    "2402": ['Стара Загора - Гурково', 'Stara Zagora - Gurkovo'],
    "2403": ['Стара Загора - Гълъбово', 'Stara Zagora - Galabovo'],
    "2404": ['Стара Загора - Казанлък', 'Stara Zagora - Kazanlak'],
    "2405": ['Стара Загора - Мъглиж', 'Stara Zagora - Maglizh'],
    "2406": ['Стара Загора - Николаево', 'Stara Zagora - Nikolaevo'],
    "2407": ['Стара Загора - Опан', 'Stara Zagora - Opan'],
    "2408": ['Стара Загора - Павел баня', 'Stara Zagora - Pavel banya'],
    "2409": ['Стара Загора - Раднево', 'Stara Zagora - Radnevo'],
    "2410": ['Стара Загора - Стара Загора', 'Stara Zagora - Stara Zagora'],
    "2411": ['Стара Загора - Чирпан', 'Stara Zagora - Chirpan'],
    "2501": ['Търговище - Антоново', 'Targovishte - Antonovo'],
    "2502": ['Търговище - Омуртаг', 'Targovishte - Omurtag'],
    "2503": ['Търговище - Опака', 'Targovishte - Opaka'],
    "2504": ['Търговище - Попово', 'Targovishte - Popovo'],
    "2505": ['Търговище - Търговище', 'Targovishte - Targovishte'],
    "2601": ['Хасково - Димитровград', 'Haskovo - Dimitrovgrad'],
    "2602": ['Хасково - Ивайловград', 'Haskovo - Ivaylovgrad'],
    "2603": ['Хасково - Любимец', 'Haskovo - Lyubimets'],
    "2604": ['Хасково - Маджарово', 'Haskovo - Madzharovo'],
    "2605": ['Хасково - Минерални бани', 'Haskovo - Mineralni bani'],
    "2606": ['Хасково - Свиленград', 'Haskovo - Svilengrad'],
    "2607": ['Хасково - Симеоновград', 'Haskovo - Simeonovgrad'],
    "2608": ['Хасково - Стамболово', 'Haskovo - Stambolovo'],
    "2609": ['Хасково - Тополовград', 'Haskovo - Topolovgrad'],
    "2610": ['Хасково - Харманли', 'Haskovo - Harmanli'],
    "2611": ['Хасково - Хасково', 'Haskovo - Haskovo'],
    "2701": ['Шумен - Велики Преслав', 'Shumen - Veliki Preslav'],
    "2702": ['Шумен - Венец', 'Shumen - Venetz'],
    "2703": ['Шумен - Върбица', 'Shumen - Varbitsa'],
    "2704": ['Шумен - Каолиново', 'Shumen - Kaolinovo'],
    "2705": ['Шумен - Каспичан', 'Shumen - Kaspichan'],
    "2706": ['Шумен - Никола Козлево', 'Shumen - Nikola Kozlevo'],
    "2707": ['Шумен - Нови пазар', 'Shumen - Novi Pazar'],
    "2708": ['Шумен - Смядово', 'Shumen - Smyadovo'],
    "2709": ['Шумен - Хитрино', 'Shumen - Hitrino'],
    "2710": ['Шумен - Шумен', 'Shumen - Shumen'],
    "2801": ['Ямбол - Болярово', 'Yambol - Bolyarovo'],
    "2802": ['Ямбол - Елхово', 'Yambol - Elhovo'],
    "2803": ['Ямбол - Стралджа', 'Yambol - Straldza'],
    "2804": ['Ямбол - Тунджа', 'Yambol - Tundzha'],
    "2805": ['Ямбол - Ямбол', 'Yambol - Yambol'],
};

const constructLocaleHealthRegions = (locale, index) => {
    return {
        healthRegions: Object.keys(healthRegionKeys).reduce((acc, key) => {
            acc[key] = healthRegionKeys[key][index];
            return acc;
        }, {})
    };
};

export const healthRegionMessages = {
    [LOCALES.ENGLISH]: constructLocaleHealthRegions(LOCALES.ENGLISH, 1),
    [LOCALES.BULGARIAN]: constructLocaleHealthRegions(LOCALES.BULGARIAN, 0),
};
