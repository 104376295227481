var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Col, Row } from "antd";
import React from "react";
import DashboardWelcomeCard from "./DashboardWelcomeCard";
import DashboardCalendarCard from "./DashboardCalendarCard";
import DashboardPatientsCard from "./DashboardPatientsCard";
import DashboardStatistcsCard from "./DashboardStatistcsCard";
var DashboardDesktop = function () {
    return (_jsxs(_Fragment, { children: [_jsx(Row, __assign({ gutter: [32, 32] }, { children: _jsx(Col, __assign({ span: 24 }, { children: _jsx(DashboardWelcomeCard, {}) })) })), _jsxs(Row, __assign({ gutter: [32, 32] }, { children: [_jsx(Col, __assign({ span: 12 }, { children: _jsx(DashboardCalendarCard, {}) })), _jsx(Col, __assign({ span: 12 }, { children: _jsx(DashboardPatientsCard, {}) }))] })), _jsx(Row, __assign({ gutter: [32, 32] }, { children: _jsx(Col, __assign({ span: 24 }, { children: _jsx(DashboardStatistcsCard, {}) })) }))] }));
};
export default DashboardDesktop;
