var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { BarChartOutlined } from "@ant-design/icons";
import { Pie } from "@ant-design/plots";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { MedicalStaffApiFactory } from "dentobook-api-typescript-client/api";
var DashboardStatisticsCard = function () {
    var _a = useState(0), receptionists = _a[0], setReceptionists = _a[1];
    var _b = useState(0), doctors = _b[0], setDoctors = _b[1];
    var _c = useState(0), assistants = _c[0], setAssistants = _c[1];
    var pieDataMap = [
        {
            type: 'Лекари',
            value: doctors,
        },
        {
            type: 'Асистенти',
            value: assistants,
        },
        {
            type: 'Рецепционисти',
            value: receptionists,
        },
    ];
    var pieConfig = {
        appendPadding: 10,
        data: pieDataMap,
        angleField: 'value',
        colorField: 'type',
        radius: 0.9,
        label: {
            type: 'inner'
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    };
    var apiConfig = new Configuration({ basePath: '/api' });
    var medicalApi = MedicalStaffApiFactory(apiConfig, '/api');
    useEffect(function () {
        medicalApi.fetchMedicalStaffCount()
            .then(function (res) {
            setReceptionists(res.data.receptionists);
            setDoctors(res.data.doctors);
            setAssistants(res.data.assistants);
        })
            .catch(function (err) { return console.log(err); });
    }, []);
    return (_jsxs(Card, __assign({ bordered: true, bodyStyle: { padding: '0' }, className: "dentobook-dashboard-card dentobook-dashboard-calendar-card" }, { children: [_jsxs("span", __assign({ className: "font-24" }, { children: [_jsx("span", __assign({ className: "text-blue mr-1" }, { children: _jsx(BarChartOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "statistics" })] })), _jsx(Pie, __assign({}, pieConfig))] })));
};
export default DashboardStatisticsCard;
