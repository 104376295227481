export var calculatePayments = function (toothTreatments) {
    var total = 0;
    var discount = 0;
    var laboratory = 0;
    var paid = 0;
    var due = 0;
    for (var _i = 0, toothTreatments_1 = toothTreatments; _i < toothTreatments_1.length; _i++) {
        var toothTreatment = toothTreatments_1[_i];
        var proceduresPrice = 0;
        var proceduresDiscounts = 0;
        var proceduresLaboratoryPrice = 0;
        for (var _a = 0, _b = toothTreatment.procedures || []; _a < _b.length; _a++) {
            var procedure = _b[_a];
            proceduresPrice += procedure.price - procedure.price * (procedure.discount / 100) + procedure.laboratoryPrice;
            proceduresLaboratoryPrice += procedure.laboratoryPrice;
            proceduresDiscounts += procedure.price - procedure.price * (procedure.discount / 100);
            if (procedure.paidAt) {
                paid += procedure.price - procedure.price * (procedure.discount / 100) +
                    procedure.laboratoryPrice;
            }
            else {
                due += procedure.price - procedure.price * (procedure.discount / 100) +
                    procedure.laboratoryPrice;
            }
        }
        total += toothTreatment.price - toothTreatment.price * (toothTreatment.discount / 100) + toothTreatment.laboratoryPrice + proceduresPrice;
        var toothTreatmentPriceWithDiscount = toothTreatment.price - toothTreatment.price * (toothTreatment.discount / 100);
        discount += toothTreatmentPriceWithDiscount + proceduresDiscounts;
        laboratory += toothTreatment.laboratoryPrice + proceduresLaboratoryPrice;
        if (toothTreatment.paidAt) {
            paid += toothTreatment.price - toothTreatment.price * (toothTreatment.discount / 100) +
                toothTreatment.laboratoryPrice;
        }
        else {
            due += toothTreatment.price - toothTreatment.price * (toothTreatment.discount / 100) +
                toothTreatment.laboratoryPrice;
        }
    }
    return {
        total: total,
        laboratory: laboratory,
        discount: discount,
        paid: paid,
        due: due
    };
};
