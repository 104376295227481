var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Row, Space, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import AccessControl from "../components/common/AccessControl";
import { accessibilityRoles } from "../utils/accessUtils";
import DetailWrapper from "../components/common/DetailWrapper";
import GeneralData from "../components/nzis/GeneralData";
import ExcuseNotes from "../components/nzis/ExcuseNotes";
import React, { useEffect, useState } from "react";
import SignatureClientErrorModal from "../components/common/SignatureClientErrorModal";
import { InfoCircleOutlined } from "@ant-design/icons";
import { esignAppEndpoint } from "../i18n/nzis-utils";
var Nzis = function () {
    var _a = useState(false), isSignatureModalOpen = _a[0], setSignatureModalOpen = _a[1];
    var items = [
        {
            label: _jsx(FormattedMessage, { id: "all_data" }),
            key: 'all_data',
            children: _jsx(GeneralData, {})
        },
        {
            label: _jsx(FormattedMessage, { id: "medical_prescriptions" }),
            key: 'medical_prescriptions',
            children: _jsx(GeneralData, {})
        },
        {
            label: _jsx(FormattedMessage, { id: "excuse_notes" }),
            key: 'excuse_notes',
            children: _jsx(ExcuseNotes, {})
        },
    ];
    useEffect(function () {
        fetch(esignAppEndpoint, {
            method: 'post',
            headers: {
                'Accept': 'application/xml',
                'Content-Type': 'application/xml'
            },
            body: '<h1>Test</h1>'
        })
            .then(function (res) { return res.text(); })
            .then(function (res) {
            if (res === 'No certificate is chosen.') {
                setSignatureModalOpen(true);
            }
        })
            .catch(function () { return setSignatureModalOpen(true); });
    }, []);
    return (_jsxs(AccessControl, __assign({ accessibleFor: [accessibilityRoles.CLINIC_ADMIN] }, { children: [_jsx(SignatureClientErrorModal, { isModalOpen: isSignatureModalOpen, setIsModalOpen: setSignatureModalOpen }), _jsxs(DetailWrapper, { children: [_jsx(Row, __assign({ className: "mb-2" }, { children: _jsx(Col, __assign({ span: 12 }, { children: _jsxs(Space, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: "nzis" }) }), _jsx(Button, __assign({ type: "link", style: { marginLeft: -15, marginBottom: 0, fontSize: '16px', color: '#1444E1' }, onClick: function () { return setSignatureModalOpen(true); } }, { children: _jsx(InfoCircleOutlined, {}) }))] }) })) })), _jsx(Tabs, { items: items }, "main-information")] })] })));
};
export default Nzis;
