var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Col, Row } from "antd";
import { FormattedMessage } from "react-intl";
import '../assets/css/Dentobook.css';
import DetailWrapper from "../components/common/DetailWrapper";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
var Settings = function () {
    return (_jsx(AccessControl, __assign({ accessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR] }, { children: _jsx(DetailWrapper, __assign({ navigateBack: undefined }, { children: _jsx(Row, __assign({ className: "mb-2" }, { children: _jsx(Col, __assign({ span: 12 }, { children: _jsx("h3", { children: _jsx(FormattedMessage, { id: "settings" }) }) })) })) })) })));
};
export default Settings;
