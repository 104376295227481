var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Col, Form, Input, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { isMobileOnly } from "react-device-detect";
var ClinicSearchForm = function (_a) {
    var keyword = _a.keyword, setKeyword = _a.setKeyword, loadData = _a.loadData, onAddButtonClick = _a.onAddButtonClick;
    var intl = useIntl();
    return (_jsxs(Form, __assign({ layout: "horizontal", className: "mt-2" }, { children: [_jsxs(Row, __assign({ gutter: 16 }, { children: [_jsx(Col, __assign({ flex: 5 }, { children: _jsx(Form.Item, __assign({ name: "keyword", className: "dentobook-form-item" }, { children: _jsx(Input, { value: keyword, placeholder: intl.formatMessage({ id: 'search' }), onChange: function (e) { return setKeyword(e.target.value); }, prefix: _jsx(SearchOutlined, { style: { fontSize: 24 } }) }) })) })), _jsx(Col, __assign({ flex: 0 }, { children: _jsxs(Button, __assign({ shape: "round", className: isMobileOnly ? "mobile-add-btn" : "dentobook-btn-primary dentobook-btn-medium", style: { float: 'right', marginTop: '5px', marginLeft: '16px' }, onClick: function () { return onAddButtonClick(); }, type: "primary" }, { children: [_jsx(PlusOutlined, {}), " ", _jsx(FormattedMessage, { id: "add" })] })) }))] })), _jsx(Button, { type: "link", htmlType: "submit", onClick: function () { return loadData(true); } })] })));
};
export default ClinicSearchForm;
