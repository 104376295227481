var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card } from "antd";
import { FormattedMessage } from "react-intl";
import { LeftOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { ExitPromptContext } from "../../context/ExitPromptContext";
import { useNavigate } from "react-router-dom";
var DetailWrapper = function (props) {
    var _a = useContext(ExitPromptContext), isFormDirty = _a.isFormDirty, setExitModalOpen = _a.setExitModalOpen, setExitModalAction = _a.setExitModalAction;
    var navigate = useNavigate();
    var handleClick = function (e) {
        e.preventDefault();
        if (props.navigateBack) {
            if (isFormDirty) {
                setExitModalOpen(true);
                setExitModalAction(props.navigateBack);
            }
            else {
                navigate(props.navigateBack);
            }
        }
    };
    return (_jsxs(Card, __assign({ bordered: false, style: props.removeVerticalScroll ? { overflowY: 'hidden' } : {}, className: "dentobook-content disable-side-scroll" }, { children: [props.navigateBack ?
                _jsxs(Button, __assign({ className: "back-link", type: "link", onClick: handleClick }, { children: [_jsx("span", __assign({ className: "text-black" }, { children: _jsx(LeftOutlined, {}) })), _jsx("span", __assign({ className: "text-blue font-weight-500 ml-05" }, { children: _jsx(FormattedMessage, { id: "back" }) }))] }))
                : null, _jsx("div", { children: props.children })] })));
};
export default DetailWrapper;
