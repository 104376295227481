var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from "antd";
import { isMobileOnly } from 'react-device-detect';
var Tooth = function (props) {
    var renderWithTooltip = function (content) {
        if (isMobileOnly) {
            return content;
        }
        else {
            return _jsx(Tooltip, __assign({ title: props.tooltipContent }, { children: content }));
        }
    };
    return (_jsxs("div", __assign({ className: 'dentobook-tooth' + (props.selected ? ' dentobook-selected-tooth' : '') + (props.multiSelectionMode ? ' dentobook-multiselection-mode' : '') }, { children: [((!props.milk && props.position < 30) || (props.milk && props.position < 66)) &&
                renderWithTooltip(_jsx("div", __assign({ className: 'position-label top-orientation' + (!props.selected && props.statusLabel ? ' dark-header' : ''), style: !props.selected && props.headerColor ? { background: props.headerColor } : {} }, { children: props.position }))), ((!props.milk && props.position > 30) || (props.milk && props.position > 66)) &&
                renderWithTooltip(_jsx("div", __assign({ className: 'status-label mt-8', style: { marginBottom: -32 } }, { children: _jsx("span", __assign({ style: !props.selected && props.statusLabel ? {
                            border: '1px solid ' + props.statusColor
                        } : {} }, { children: props.statusLabel })) }))), renderWithTooltip(_jsx("div", __assign({ className: 'tooth-image' }, { children: _jsx("img", { src: require("../../assets/teeth/".concat(props.position, ".svg")), alt: "Tooth ".concat(props.position) }) }))), ((!props.milk && props.position > 30) || (props.milk && props.position > 66)) &&
                renderWithTooltip(_jsx("div", __assign({ className: 'position-label bottom-orientation' + (!props.selected && props.statusLabel ? ' dark-header' : ''), style: !props.selected && props.headerColor ? { background: props.headerColor } : {} }, { children: props.position }))), ((!props.milk && props.position < 30) || (props.milk && props.position < 66)) &&
                renderWithTooltip(_jsx("div", __assign({ className: 'status-label mb-4', style: { marginTop: -32 } }, { children: _jsx("span", __assign({ style: !props.selected && props.statusLabel ? {
                            border: '1px solid ' + props.statusColor
                        } : {} }, { children: props.statusLabel })) })))] })));
};
export default Tooth;
