import { LOCALES } from './locales';

const fieldKeys = {
    id: ['ID', 'Идентификационен номер'],
    date:['Date', 'Дата'],
    fear: ['Fear', 'Страх'],
    town: ['Town', 'Град'],
    email: ['Email', 'Имейл'],
    notes: ['Notes', 'Бележки'],
    phone: ['Phone', 'Телефон'],
    avatar: ['Avatar', 'Аватар'],
    gender: ['Gender', 'Пол'],
    country: ['Country', 'Държава'],
    nursing: ['Nursing', 'Кърмене'],
    isSmoker: ['Smoker', 'Пушач'],
    lastHour: ['Last Hour', 'Последен час'],
    lastName: ['Last Name', 'Фамилно име'],
    nextHour: ['Next Hour', 'Следващ час'],
    birthDate: ['Birth Date', 'Дата на раждане'],
    createdAt: ['Created At', 'Дата на създаване'],
    deletedAt: ['Deleted At', 'Дата на изтриване'],
    firstName: ['First Name', 'Име'],
    lastVisit: ['Last Visit', 'Последно посещение'],
    updatedAt: ['Updated At', 'Дата на актуализация'],
    addressOne: ['Address One', 'Адрес 1'],
    addressTwo: ['Address Two', 'Адрес 2'],
    anesthesia: ['Anesthesia', 'Анестезия'],
    conditions: ['Conditions', 'Условия'],
    secondName: ['Second Name', 'Второ име'],
    visitPeriod: ['Visit Period', 'Период на посещение'],
    patientSource: ['Patient Source', 'Източник на пациент'],
    photoEntities: ['Photo Entities', 'Фото обекти'],
    pregnancyDate: ['Pregnancy Date', 'Дата на бременност'],
    pregnancyPlan: ['Pregnancy Plan', 'План за бременност'],
    organizationId: ['Organization ID', 'Идентификационен номер на организацията'],
    regularHygiene: ['Regular Hygiene', 'Редовна хигиена'],
    lastVisitReason: ['Last Visit Reason', 'Причина за последното посещение'],
    patientSourceName: ['Patient Source Name', 'Име на източника на пациента'],
    healthInsuranceCompanyId: ['Health Insurance Company ID', 'Идентификационен номер на здравноосигурителната компания'],
    end: ['End', 'Край'],
    start: ['Start', 'Начало'],
    title: ['Title', 'Заглавие'],
    doctor: ['Doctor', 'Лекар'],
    userId: ['User ID', 'Идентификационен номер на потребителя'],
    patientId: ['Patient ID', 'Идентификационен номер на пациента'],
    createdAtCalendarEvent: ['Created At', 'Дата на създаване'],
    updatedAtCalendarEvent: ['Updated At', 'Дата на актуализация'],
    dentalCabinet: ['Dental Cabinet', 'Зъболекарски кабинет'],
    position: ['Position', 'Позиция'],
    orderNumber: ['Order Number', 'Поръчков номер'],
    toothStatuses: ['Tooth Statuses', 'Статус на зъбите'],
    toothTreatments: ['Tooth Treatments', 'Лечение на зъби'],
    statusId: ['Status ID', 'Идентификационен номер на статуса'],
    sequenceId: ['Sequence ID', 'Идентификационен номер на последователността'],
    treatmentId: ['Treatment ID', 'Идентификационен номер на лечението'],
    medicalStaffId: ['Medical Staff ID', 'Идентификационен номер на медицинския персонал'],
    laboratoryPrice: ['Laboratory Price', 'Цена на лабораторията'],
    procedureTreatments: ['Procedure Treatments', 'Лечение на процедури'],
    price: ['Price', 'Цена'],
    paidAt: ['Paid At', 'Дата на плащане'],
    status: ['Status', 'Статус'],
    discount: ['Discount', 'Отстъпка'],
    tooth: ['Tooth', 'Зъб'],
    home: ['Home', "Начало"],
    patient: ['Patient' , 'Пациент'],
    patients: ['Patients' , 'Пациенти'],
    calendarEvent: ['Calendar Event' , 'Календарно събитие'],
    false: ['False' , 'Не'],
    male: ['Male' , 'Мъж'],
    female: ['Female' , 'Жена'],
    in_progress: ['In progress', 'В процес на изпълнение'],
    done: ['Done', 'Завършен'],
    canceled: ['Canceled', 'Отменен'],
    offered: ['Offered', 'Предложен'],
    nothing: ['N/A', "Няма Данни"],
    treatmentTooth: ['Treatment Tooth', 'Лечение на зъби'],
};

const constructLocaleMessages = (_locale, index) => {
    return {
        fields: Object.keys(fieldKeys).reduce((acc, key) => {
            acc[key] = fieldKeys[key][index];
            return acc;
        }, {})
    };
};

export const messages = {
    [LOCALES.ENGLISH]: constructLocaleMessages(LOCALES.ENGLISH, 0),
    [LOCALES.BULGARIAN]: constructLocaleMessages(LOCALES.BULGARIAN, 1),
};
