var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Card, Col, Modal, QRCode, Row } from 'antd';
import "../../assets/css/Dentobook.css";
import "../../assets/css/PatientPhotos.css";
import { FormattedMessage } from "react-intl";
import { Navigate } from "react-router-dom";
import '../../assets/css/PatientCreateModal.css';
import { isMobileOnly } from "react-device-detect";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { SelfRegisterApiFactory } from "dentobook-api-typescript-client/api";
var PatientCreateModal = function (_a) {
    var isModalOpen = _a.isModalOpen, setIsModalOpen = _a.setIsModalOpen;
    var _b = useState(0), mode = _b[0], setMode = _b[1];
    var _c = useState(undefined), createUrl = _c[0], setCreateUrl = _c[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var selfRegisterApi = SelfRegisterApiFactory(apiConfig, '/api');
    var baseUrl = window.location.origin;
    useEffect(function () {
        setMode(0);
    }, [isModalOpen]);
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    if (mode === 2) {
        return _jsx(Navigate, { to: "/patients/create" });
    }
    var fetchCreationUrlId = function () {
        selfRegisterApi.fetchPatientCreateUrlId()
            .then(function (res) {
            setMode(1);
            setCreateUrl(res.data);
        })
            .catch(function (err) { return console.log(err); });
    };
    var formDesktop = (_jsx(Modal, __assign({ transitionName: "", className: "dentobook-modal", title: _jsx(FormattedMessage, { id: "add_patient" }), width: 690, footer: null, open: isModalOpen, onCancel: handleCancel }, { children: _jsx("div", { children: mode === 1 ?
                _jsxs("div", __assign({ className: "text-center mt-12 mb-112", style: {
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center'
                    } }, { children: [_jsx("p", __assign({ className: "font-16 font-weight-500 mb-24" }, { children: _jsx(FormattedMessage, { id: "qr_description" }) })), _jsx(QRCode, { value: "".concat(baseUrl, "/patients/self-register/").concat(createUrl === null || createUrl === void 0 ? void 0 : createUrl.id, "/"), bordered: false })] }))
                :
                    _jsxs(Row, __assign({ gutter: 24, className: "mb-112 pl-16", style: { marginTop: -32 } }, { children: [_jsx(Col, __assign({ md: 12 }, { children: _jsxs(Card, __assign({ bordered: false, className: "dentobook-qr-manual-card", onClick: fetchCreationUrlId }, { children: [_jsx("span", __assign({ className: "font-20 font-weight-500 text-center" }, { children: _jsx(FormattedMessage, { id: "qr_code_generator" }) })), _jsxs("div", __assign({ className: "flex-group" }, { children: [_jsx("img", { alt: "qr-create", src: require('../../assets/common/qr-create.svg').default }), _jsx("br", {}), _jsx("span", __assign({ className: "font-weight-400 text-align-center" }, { children: _jsx(FormattedMessage, { id: "qr_code_generator_description" }) }))] }))] })) })), _jsx(Col, __assign({ md: 12 }, { children: _jsx(Card, __assign({ bordered: false, className: "dentobook-qr-manual-card", onClick: function () { return setMode(2); } }, { children: _jsxs("div", __assign({ className: "flex-group" }, { children: [_jsx("span", __assign({ className: "font-20 font-weight-500 text-center" }, { children: _jsx(FormattedMessage, { id: "manual_input" }) })), _jsx("img", { alt: "manually-create", className: "text-center", src: require('../../assets/common/manually-create.svg').default }), _jsx("br", {}), _jsx("span", __assign({ className: "font-weight-400 text-align-center" }, { children: _jsx(FormattedMessage, { id: "manual_input_description" }) }))] })) })) }))] })) }) })));
    var formMobile = (_jsx(Modal, __assign({ transitionName: "", className: "dentobook-modal", title: _jsx(FormattedMessage, { id: "add_patient" }), width: 690, footer: null, styles: { body: { overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' } }, open: isModalOpen, onCancel: handleCancel }, { children: _jsx("div", { children: mode === 1 ?
                _jsxs("div", __assign({ style: {
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    } }, { children: [_jsx("p", __assign({ className: "font-16 font-weight-500 mt-112" }, { children: _jsx(FormattedMessage, { id: "qr_description" }) })), _jsx(QRCode, { value: "".concat(baseUrl, "/patients/self-register/").concat(createUrl === null || createUrl === void 0 ? void 0 : createUrl.id, "/"), bordered: false })] }))
                :
                    _jsxs(Row, __assign({ gutter: 24, className: "pl-16", style: { marginTop: -72 } }, { children: [_jsx(Col, __assign({ md: 12 }, { children: _jsxs(Card, __assign({ bordered: false, className: "dentobook-qr-manual-card", onClick: fetchCreationUrlId }, { children: [_jsx("span", __assign({ className: "font-20 font-weight-500 text-center" }, { children: _jsx(FormattedMessage, { id: "qr_code_generator" }) })), _jsxs("div", __assign({ className: "flex-group" }, { children: [_jsx("img", { alt: "qr-create", src: require('../../assets/common/qr-create.svg').default }), _jsx("br", {}), _jsx("span", __assign({ className: "font-weight-400 text-align-center" }, { children: _jsx(FormattedMessage, { id: "qr_code_generator_description" }) }))] }))] })) })), _jsx(Col, __assign({ md: 12 }, { children: _jsx(Card, __assign({ bordered: false, className: "dentobook-qr-manual-card", onClick: function () { return setMode(2); } }, { children: _jsxs("div", __assign({ className: "flex-group" }, { children: [_jsx("span", __assign({ className: "font-20 font-weight-500 text-center" }, { children: _jsx(FormattedMessage, { id: "manual_input" }) })), _jsx("img", { alt: "manually-create", className: "text-center", src: require('../../assets/common/manually-create.svg').default }), _jsx("br", {}), _jsx("span", __assign({ className: "font-weight-400 text-align-center" }, { children: _jsx(FormattedMessage, { id: "manual_input_description" }) }))] })) })) }))] })) }) })));
    return isMobileOnly ? formMobile : formDesktop;
};
export default PatientCreateModal;
