var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext } from 'react';
import { Calendar } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import dayjs from "dayjs";
import { Tooltip } from "antd";
import { DentobookApplicationContext } from "../../context/DentobookApplicationContext";
export var CustomEvent = function (props) {
    var locale = useContext(DentobookApplicationContext).locale;
    var _a = props.event, title = _a.title, notes = _a.notes, start = _a.start, end = _a.end;
    var _b = title.split('\n'), patientName = _b[0], doctorName = _b[1];
    var doctorPrefix = locale === 'BG' ? 'Д-р ' : 'Dr. ';
    var formattedStart = dayjs(start).format('HH:mm');
    var formattedEnd = dayjs(end).format('HH:mm');
    var tooltipTitle = locale === 'BG' ? 'Бележки' : 'Notes';
    var tooltipDoctor = locale === 'BG' ? 'Доктор' : 'Doctor';
    var tooltipPatient = locale === 'BG' ? 'Пациент' : 'Patient';
    var tooltipFrom = locale === 'BG' ? 'От' : 'Start time';
    var tooltipTo = locale === 'BG' ? 'До' : 'End time';
    var eventContent = (_jsxs("div", { children: [_jsx("div", __assign({ className: "dentobook-calendar-event-patient" }, { children: patientName })), _jsxs("div", __assign({ className: "dentobook-calendar-event-doctor" }, { children: [doctorPrefix, doctorName] }))] }));
    return notes ? (_jsx(Tooltip, __assign({ title: _jsxs("div", __assign({ className: "tooltip-content" }, { children: [_jsxs("p", { children: [_jsxs("strong", { children: [tooltipDoctor, ":"] }), " ", doctorPrefix + ' ' + doctorName] }), _jsxs("p", { children: [_jsxs("strong", { children: [tooltipPatient, ":"] }), " ", patientName] }), _jsxs("p", { children: [_jsxs("strong", { children: [tooltipFrom, ":"] }), " ", formattedStart] }), _jsxs("p", { children: [_jsxs("strong", { children: [tooltipTo, ":"] }), " ", formattedEnd] }), _jsxs("p", { children: [_jsxs("strong", { children: [tooltipTitle, ":"] }), " ", notes] })] })), placement: "top", arrow: true }, { children: eventContent }))) : eventContent;
};
var DnDCalendar = withDragAndDrop(Calendar);
var MemoizedCalendar = memo(function (_a) {
    var events = _a.events, onEventResize = _a.onEventResize, onEventDrop = _a.onEventDrop, onSelectSlot = _a.onSelectSlot, onSelectEvent = _a.onSelectEvent, eventPropGetter = _a.eventPropGetter, localizer = _a.localizer, date = _a.date, components = _a.components, view = _a.view;
    return (_jsx(DnDCalendar, { components: __assign(__assign({}, components), { event: CustomEvent }), onEventResize: onEventResize, onSelectSlot: onSelectSlot, onSelectEvent: onSelectEvent, events: events, onEventDrop: onEventDrop, eventPropGetter: eventPropGetter, selectable: true, date: date, resizable: true, className: "custom-calendar", localizer: localizer, defaultView: "week", view: view ? 'day' : 'week', views: ['week', 'day'], style: { height: '700px' }, min: new Date(0, 0, 0, 8, 0), max: new Date(0, 0, 0, 19, 0) }));
});
export default MemoizedCalendar;
