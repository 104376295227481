var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import '../../assets/css/Dentobook.css';
import '../../assets/css/Clinic.css';
import { Button, Col, Form, Input, Modal, Row, Select, Space, Table } from 'antd';
import { CaretDownOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { MedicalStaffApiFactory, NZIZApiFactory } from 'dentobook-api-typescript-client/api';
import { accessibilityRoles } from "../../utils/accessUtils";
import ClinicSearchForm from "./ClinicSearchFrom";
import AccessControl from "../common/AccessControl";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { FormattedMessage, useIntl } from "react-intl";
import { isMobileOnly } from "react-device-detect";
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { useForm } from "antd/es/form/Form";
var Status = function () {
    var intl = useIntl();
    var form = useForm()[0];
    var Option = Select.Option;
    var _a = useState(''), newStatusName = _a[0], setNewStatusName = _a[1];
    var _b = useState(''), newStatusLabel = _b[0], setNewStatusLabel = _b[1];
    var _c = useState(''), newStatusColor = _c[0], setNewStatusColor = _c[1];
    var _d = useState(''), newNzisCondition = _d[0], setNewNzisCondition = _d[1];
    var _e = useState(''), editStatusId = _e[0], setEditStatusId = _e[1];
    var _f = useState(false), editMode = _f[0], setEditMode = _f[1];
    var _g = useState(false), isDeleteModalVisible = _g[0], setIsDeleteModalVisible = _g[1];
    var _h = useState([]), statuses = _h[0], setStatuses = _h[1];
    var _j = useState(''), keyword = _j[0], setKeyword = _j[1];
    var _k = useState(false), isAddStatusModalVisible = _k[0], setIsAddStatusModalVisible = _k[1];
    var _l = useState([]), displayedStatuses = _l[0], setDisplayedStatuses = _l[1];
    var _m = useState([]), nzisToothConditionCodes = _m[0], setNzisToothConditionCodes = _m[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var medicalApi = MedicalStaffApiFactory(apiConfig, '/api');
    var nzisApi = NZIZApiFactory(apiConfig, '/api');
    var showAddModal = function () { return setIsAddStatusModalVisible(true); };
    var status = {};
    var showEditModal = function (status) {
        var _a, _b, _c, _d, _e;
        if (status) {
            form.setFieldsValue({
                condition: status.name,
                nzisCondition: status.nzisCondition
            });
            setEditStatusId((_a = status.id) !== null && _a !== void 0 ? _a : '');
            setNewStatusName((_b = status.name) !== null && _b !== void 0 ? _b : '');
            setNewStatusLabel((_c = status.label) !== null && _c !== void 0 ? _c : '');
            setNewStatusColor((_d = status.color) !== null && _d !== void 0 ? _d : '');
            setNewNzisCondition((_e = status.nzisCondition) !== null && _e !== void 0 ? _e : '');
            setEditMode(true);
        }
    };
    useEffect(function () {
        loadData();
    }, []);
    var loadData = function () {
        medicalApi.fetchMedicalStatuses()
            .then(function (response) {
            setStatuses(response.data.sort(function (statusOne, statusTwo) { var _a; return (_a = statusOne.name) === null || _a === void 0 ? void 0 : _a.localeCompare(statusTwo.name); }));
            setDisplayedStatuses(response.data);
            console.log(response.data);
            nzisApi.fetchToothConditionCodes()
                .then(function (res) {
                setNzisToothConditionCodes(res.data);
            })
                .catch(function (err) { return console.log(err); });
        })
            .catch(function (error) { return console.error("Failed to load statuses:", error); });
    };
    useEffect(function () {
        if (keyword) {
            var filteredDentalCabinet = statuses.filter(function (status) { var _a; return (_a = status.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(keyword.toLowerCase()); });
            setDisplayedStatuses(filteredDentalCabinet);
        }
        else {
            setDisplayedStatuses(statuses);
        }
    }, [statuses, keyword]);
    var onAddButtonClick = function () {
        showAddModal();
    };
    var showDeleteModal = function (status) {
        var _a, _b, _c, _d, _e;
        if (status) {
            setEditStatusId((_a = status.id) !== null && _a !== void 0 ? _a : '');
            setNewStatusName((_b = status.name) !== null && _b !== void 0 ? _b : '');
            setNewStatusLabel((_c = status.label) !== null && _c !== void 0 ? _c : '');
            setNewStatusColor((_d = status.color) !== null && _d !== void 0 ? _d : '');
            setNewNzisCondition((_e = status.nzisCondition) !== null && _e !== void 0 ? _e : '');
        }
        setIsDeleteModalVisible(true);
    };
    var resetState = function () {
        setEditStatusId('');
        setNewStatusName('');
        setNewStatusLabel('');
        setNewNzisCondition('');
        form.setFieldsValue({
            condition: '',
            nzisCondition: ''
        });
        setEditMode(false);
        setIsDeleteModalVisible(false);
        setIsAddStatusModalVisible(false);
    };
    var closeAddStatusModal = function () {
        setIsAddStatusModalVisible(false);
    };
    var handleCancel = function () {
        closeAddStatusModal();
        // Reset state
        setEditStatusId('');
        setNewStatusName('');
        setNewStatusLabel('');
        setNewStatusColor('');
        setNewNzisCondition('');
        form.setFieldsValue({
            condition: '',
            nzisCondition: ''
        });
        setEditMode(false);
    };
    var handleAddOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, form.validateFields()];
                case 1:
                    _a.sent();
                    handleCreateStatus();
                    closeAddStatusModal();
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleCreateStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    status.name = newStatusName;
                    status.label = newStatusLabel;
                    status.color = newStatusColor;
                    status.nzisCondition = newNzisCondition;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, medicalApi.createMedicalStatus(status)];
                case 2:
                    _a.sent();
                    loadData();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Failed to create Status:", error_1);
                    return [3 /*break*/, 4];
                case 4:
                    resetState();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleEditStatusOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_2, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 7, , 8]);
                    return [4 /*yield*/, form.validateFields()];
                case 1:
                    _a.sent();
                    status.id = editStatusId;
                    status.name = newStatusName;
                    status.label = newStatusLabel;
                    status.color = newStatusColor;
                    status.nzisCondition = newNzisCondition;
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 5, , 6]);
                    if (!(status.id != null)) return [3 /*break*/, 4];
                    return [4 /*yield*/, medicalApi.updateMedicalStatus(status.id, status)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    loadData();
                    return [3 /*break*/, 6];
                case 5:
                    error_2 = _a.sent();
                    console.error("Failed to create status:", error_2);
                    return [3 /*break*/, 6];
                case 6:
                    resetState();
                    return [3 /*break*/, 8];
                case 7:
                    err_2 = _a.sent();
                    console.log(err_2);
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var handleEditStatusCancel = function () {
        resetState();
    };
    var handleDeleteStatusOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log(status.id + "DELETE");
                    status.id = editStatusId;
                    status.name = newStatusName;
                    status.label = newStatusLabel;
                    status.color = newStatusColor;
                    status.nzisCondition = newNzisCondition;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    if (!(status.id != null)) return [3 /*break*/, 3];
                    return [4 /*yield*/, medicalApi.deleteMedicalStatus(status.id)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    loadData();
                    return [3 /*break*/, 5];
                case 4:
                    error_3 = _a.sent();
                    console.error("Failed to delete status:", error_3);
                    return [3 /*break*/, 5];
                case 5:
                    resetState();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteStatusCancel = function () {
        resetState();
    };
    var modalContent = _jsx("div", __assign({ className: "anamnesis-modal-group" }, { children: _jsxs(Form, __assign({ form: form, layout: "vertical" }, { children: [_jsx(Form.Item, __assign({ name: "condition", rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }], className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "naming" }) }, { children: _jsx(Input, { value: newStatusName, onChange: function (e) { return setNewStatusName(e.target.value); } }) })), _jsx(Form.Item, __assign({ name: "nzisCondition", rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }], className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "nzis_condition" }) }, { children: _jsx(Select, __assign({ suffixIcon: _jsx(CaretDownOutlined, {}), size: "large", className: "dentobook-input", value: newNzisCondition, onChange: function (value) { return setNewNzisCondition(value); } }, { children: nzisToothConditionCodes.sort(function (statusOne, statuseTwo) { var _a; return (_a = statusOne.descriptionBG) === null || _a === void 0 ? void 0 : _a.localeCompare(statuseTwo.descriptionBG); })
                            .map(function (status) { return (_jsx(Option, __assign({ value: status.key }, { children: status.descriptionBG }), status.key)); }) })) })), _jsxs(Row, __assign({ gutter: 24 }, { children: [_jsx(Col, __assign({ flex: 2.5 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "abbreviation" }) }, { children: _jsx(Input, { value: newStatusLabel, onChange: function (e) { return setNewStatusLabel(e.target.value); } }) })) })), _jsx(Col, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "color" }) }, { children: _jsx(Select, __assign({ suffixIcon: _jsx(CaretDownOutlined, {}), style: !isMobileOnly ? { width: 300 } : { width: '90vw' }, size: "large", className: "dentobook-input", value: newStatusColor, onChange: function (value) { return setNewStatusColor(value); } }, { children: getAllHexColors().map(function (color) { return (_jsx(Option, __assign({ value: color }, { children: _jsxs(Space, { children: [_jsx("div", { style: {
                                                        backgroundColor: color,
                                                        borderRadius: '10px',
                                                        width: '20px',
                                                        height: '20px',
                                                    } }), _jsx("span", { children: color })] }) }), uuidv4())); }) })) })) })] }))] })) }));
    return (_jsxs(AccessControl, __assign({ accessibleFor: [accessibilityRoles.CLINIC_ADMIN, accessibilityRoles.MEDICAL_DOCTOR, accessibilityRoles.MEDICAL_ASSISTANT, accessibilityRoles.RECEPTIONIST] }, { children: [_jsx(Col, { children: _jsx(ClinicSearchForm, { keyword: keyword, setKeyword: setKeyword, loadData: loadData, onAddButtonClick: onAddButtonClick }) }), _jsxs(Table, __assign({ dataSource: displayedStatuses, pagination: false, style: { overflowX: 'auto' }, className: "dentobook-table" }, { children: [_jsx(Table.Column, { className: 'custom-column-style', sorter: function (statusOne, statusTwo) { return statusOne.name.localeCompare(statusTwo.name); }, title: _jsx(FormattedMessage, { id: "conditions" }), render: function (status) { return _jsx("span", __assign({ className: "font-weight-500" }, { children: status.name })); } }, "name"), _jsx(Table.Column, { sorter: function (statusOne, statusTwo) { return statusOne.label.localeCompare(statusTwo.label); }, className: 'custom-column-style', title: _jsx(FormattedMessage, { id: "abbreviation" }), render: function (status) { return _jsx("span", __assign({ className: "font-weight-500" }, { children: status.label })); } }, "id"), _jsx(Table.Column, { sorter: function (statusOne, statusTwo) { return statusOne.color.localeCompare(statusTwo.color); }, className: 'custom-column-style', title: _jsx(FormattedMessage, { id: "color" }), render: function (status) { return (_jsxs("div", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: [_jsx("div", { style: {
                                        backgroundColor: getHexColor(status.color),
                                        borderRadius: '10px',
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '10px',
                                    } }), _jsx("span", { children: getHexColor(status.color) })] }))); } }, "color"), _jsx(Table.Column, { title: _jsx("div", {}), className: 'custom-column-style', render: function (status) {
                            return (_jsxs("div", __assign({ style: { display: 'flex', flexDirection: 'row', alignItems: 'right' } }, { children: [_jsx(Button, { className: "text-black font-20 ml-1", type: "link", icon: _jsx(EditOutlined, {}), onClick: function () { return showEditModal(status); } }), _jsx(Button, { className: "text-black font-20", type: "link", icon: _jsx(DeleteOutlined, {}), onClick: function () { return showDeleteModal(status); } })] })));
                        } }, "actions")] })), isMobileOnly ? _jsx("div", { style: { marginBottom: 150 } }) : null, _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, title: _jsx(FormattedMessage, { id: "addStatus" }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: isAddStatusModalVisible, onOk: handleAddOk, onCancel: handleCancel, className: "dentobook-modal" }, { children: modalContent })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, title: _jsx(FormattedMessage, { id: "editStatus" }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: editMode, onOk: handleEditStatusOk, onCancel: handleEditStatusCancel, className: "dentobook-modal" }, { children: modalContent })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, className: "dentobook-modal", title: _jsx(FormattedMessage, { id: "deleteStatus" }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: isDeleteModalVisible, onOk: handleDeleteStatusOk, onCancel: handleDeleteStatusCancel }, { children: _jsx("p", { children: _jsx(FormattedMessage, { id: "statusDeleteConfirmation" }) }) }))] })));
};
export default Status;
var colorToHexMap = {
    red: "#FF0000",
    lightpurple: "#D8BFD8",
    lightblue: "#ADD8E6",
    grey: "#808080",
    darkgrey: "#A9A9A9",
    black: "#000000",
    purple: "#800080",
    orange: "#FFA500",
    green: "#a3ffb4"
};
function getAllHexColors() {
    return Array.from(new Set(Object.values(colorToHexMap)));
}
function getHexColor(colorName) {
    var hexColorPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    if (!colorName) {
        return '';
    }
    if (hexColorPattern.test(colorName)) {
        return colorName;
    }
    return colorToHexMap[colorName] || colorName;
}
