var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import '../assets/css/Dentobook.css';
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { MedicalStaffApi } from "dentobook-api-typescript-client/api";
import DetailWrapper from "../components/common/DetailWrapper";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
import MedicalStaffDetailsItem from "./MedicalStaffDetailsItem";
var MedicalStaffDetails = function () {
    var id = useParams().id;
    var _a = useState(false), editMode = _a[0], setEditMode = _a[1];
    var _b = useState({}), medicalStaff = _b[0], setMedicalStaff = _b[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var medicalStaffApi = new MedicalStaffApi(apiConfig, '/api');
    var items = [
        {
            label: _jsx(FormattedMessage, { id: "main_information" }),
            key: 'main-information',
            children: _jsx(MedicalStaffDetailsItem, { medicalStaff: medicalStaff, setMedicalStaff: setMedicalStaff, editMode: editMode, setEditMode: setEditMode })
        },
        {
            label: _jsx(FormattedMessage, { id: "finances" }),
            key: 'finances',
            children: _jsx("h1", { children: "Finances..." })
        },
        {
            label: _jsx(FormattedMessage, { id: "work_time" }),
            key: 'work_time',
            children: _jsx("h1", { children: "Work time..." })
        }
    ];
    useEffect(function () {
        if (id == null) {
            return;
        }
        medicalStaffApi.fetchMedicalStaffById(id)
            .then(function (response) {
            setMedicalStaff(response.data);
        })
            .catch(function (err) {
            console.log(err);
        });
    }, []);
    if (!medicalStaff.id) {
        return null;
    }
    return (_jsx(AccessControl, __assign({ accessibleFor: [accessibilityRoles.CLINIC_ADMIN, accessibilityRoles.RECEPTIONIST] }, { children: _jsxs(DetailWrapper, __assign({ navigateBack: "/medical/staff" }, { children: [_jsx("h3", { children: medicalStaff.firstName + ' ' + medicalStaff.lastName }), _jsx(Tabs, { defaultActiveKey: "main-information", items: items })] })) })));
};
export default MedicalStaffDetails;
