var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import '../assets/css/Dentobook.css';
import DetailWrapper from "../components/common/DetailWrapper";
import Cabinets from '../components/clinic/Cabinets';
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
import Status from "../components/clinic/Status";
import General from "../components/clinic/General";
import Anamnesis from "../components/clinic/Anamnesis";
import Treatments from "../components/clinic/Treatments";
import Documents from "../components/clinic/Documents";
import { useState } from "react";
import GoogleCredentials from "../components/clinic/GoogleCredentials";
import { isMobileOnly } from "react-device-detect";
var Clinic = function () {
    var _a = useState(false), isEditMode = _a[0], setIsEditMode = _a[1];
    var items = [
        {
            label: _jsx(FormattedMessage, { id: "general" }),
            key: 'general',
            children: _jsx(General, { isEditMode: isEditMode, setIsEditMode: setIsEditMode })
        },
        {
            label: _jsx(FormattedMessage, { id: "cabinet" }),
            key: 'app',
            disabled: isEditMode,
            children: _jsx(Cabinets, {})
        },
        {
            label: _jsx(FormattedMessage, { id: "conditions" }),
            key: 'conditions',
            disabled: isEditMode,
            children: _jsx(Status, {})
        },
        {
            label: _jsx(FormattedMessage, { id: "operations" }),
            key: 'operations',
            disabled: isEditMode,
            children: _jsx(Treatments, {})
        },
        {
            label: _jsx(FormattedMessage, { id: "anamnesisLabel" }),
            key: 'anamnesis',
            disabled: isEditMode,
            children: _jsx(Anamnesis, {})
        },
        {
            label: _jsx(FormattedMessage, { id: "documents" }),
            key: 'documents',
            disabled: isEditMode,
            children: _jsx(Documents, {})
        },
        {
            label: _jsx(FormattedMessage, { id: "googleCredentials" }),
            key: 'google',
            disabled: isEditMode,
            children: _jsx(GoogleCredentials, {})
        },
    ];
    return (_jsx(AccessControl, __assign({ accessibleFor: [accessibilityRoles.CLINIC_ADMIN] }, { children: _jsxs(DetailWrapper, { children: [_jsx(Row, __assign({ className: "mb-2" }, { children: _jsx(Col, __assign({ span: 12 }, { children: _jsx("h3", { children: _jsx(FormattedMessage, { id: "dental_clinic" }) }) })) })), _jsx(Tabs, { items: items }, "main-information"), isMobileOnly ? _jsx("div", { style: { marginBottom: 150 } }) : null] }) })));
};
export default Clinic;
