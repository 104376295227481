import { LOCALES } from './locales';

const townKeys = {
    '01': ['Blagoevgrad', 'Благоевград'],
    '02': ['Burgas', 'Бургас'],
    '03': ['Varna', 'Варна'],
    '04': ['Veliko Tarnovo', 'Велико Търново'],
    '05': ['Vidin', 'Видин'],
    '06': ['Vratsa', 'Враца'],
    '07': ['Gabrovo', 'Габрово'],
    '08': ['Dobrich', 'Добрич'],
    '09': ['Kardzhali', 'Кърджали'],
    '10': ['Kyustendil', 'Кюстендил'],
    '11': ['Lovech', 'Ловеч'],
    '12': ['Montana', 'Монтана'],
    '13': ['Pazardzhik', 'Пазарджик'],
    '14': ['Pernik', 'Перник'],
    '15': ['Pleven', 'Плевен'],
    '16': ['Plovdiv', 'Пловдив'],
    '17': ['Razgrad', 'Разград'],
    '18': ['Ruse', 'Русе'],
    '19': ['Silistra', 'Силистра'],
    '20': ['Sliven', 'Сливен'],
    '21': ['Smolyan', 'Смолян'],
    '22': ['Sofia City', 'София Град'],
    '23': ['Sofia', 'София Област'],
    '24': ['Stara Zagora', 'Стара Загора'],
    '25': ['Targovishte', 'Търговище'],
    '26': ['Haskovo', 'Хасково'],
    '27': ['Shumen', 'Шумен'],
    '28': ['Yambol', 'Ямбол']
};

const additionalTownMappings = {
    'BLG': ['Blagoevgrad', 'Благоевград'],
    'PER': ['Pernik', 'Перник'],
    'VID': ['Vidin', 'Видин'],
    'VRC': ['Vratsa', 'Враца'],
    'GAB': ['Gabrovo', 'Габрово'],
    'DOB': ['Dobrich', 'Добрич'],
    'KRZ': ['Kardzhali', 'Кърджали'],
    'KNL': ['Kyustendil', 'Кюстендил'],
    'LOV': ['Lovech', 'Ловеч'],
    'MON': ['Montana', 'Монтана'],
    'PAZ': ['Pazardzhik', 'Пазарджик'],
    'BGS': ['Burgas', 'Бургас'],
    'PVN': ['Pleven', 'Плевен'],
    'VTR': ['Veliko Tarnovo', 'Велико Търново'],
    'PDV': ['Plovdiv', 'Пловдив'],
    'RSE': ['Ruse', 'Русе'],
    'SLS': ['Silistra', 'Силистра'],
    'SLV': ['Sliven', 'Сливен'],
    'SML': ['Smolyan', 'Смолян'],
    'SFO': ['Sofia', 'София'],
    'SOF': ['Sofia City', 'София (столица)'],
    'SZR': ['Stara Zagora', 'Стара Загора'],
    'TGV': ['Targovishte', 'Търговище'],
    'HKV': ['Haskovo', 'Хасково'],
    'SHU': ['Shumen', 'Шумен'],
    'JAM': ['Yambol', 'Ямбол'],
    'RAZ': ['Razgrad', 'Разград'],
    'VAR': ['Varna', 'Варна'],
};

const constructLocaleMessages = (_locale, index) => {
    return {
        towns: Object.keys(townKeys).reduce((acc, key) => {
            acc[key] = townKeys[key][index];
            return acc;
        }, {})
    };
};

const constructAdditionalLocaleMessages = (_locale, index) => ({
    townCounty: Object.keys(additionalTownMappings).reduce((acc, key) => {
        acc[key] = additionalTownMappings[key][index];
        return acc;
    }, {}),
});



export const additionalMessages = {
    [LOCALES.ENGLISH]: constructAdditionalLocaleMessages(LOCALES.ENGLISH, 0),
    [LOCALES.BULGARIAN]: constructAdditionalLocaleMessages(LOCALES.BULGARIAN, 1),
};

export const messages = {
    [LOCALES.ENGLISH]: constructLocaleMessages(LOCALES.ENGLISH, 0),
    [LOCALES.BULGARIAN]: constructLocaleMessages(LOCALES.BULGARIAN, 1),
};

const getTownDisplayName = (townKey, locale) => {
    const localeMessages = messages[locale] || {};
    const townMessages = localeMessages.towns || {};
    return townMessages[townKey] || "Unknown";
};

export const getAdditionalKeyByTownKey = (townKey, locale) => {
    const townName = getTownDisplayName(townKey, locale);

    const localeAdditionalMessages = additionalMessages[locale] || {};
    const additionalTownCountyMessages = localeAdditionalMessages.townCounty || {};

    const additionalKey = Object.keys(additionalTownCountyMessages).find(key =>
        additionalTownCountyMessages[key].includes(townName)
    );

    return additionalKey || townKey;
};

