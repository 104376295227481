import {LOCALES} from "./locales";

const translations = {
    uin: ['УИН на лекар', 'doctor UIN'],
    treatmentDate: ['дата на лечението', 'treatment date'],
    patientHealthRegion: ['здравен регион на пациент', 'patient health region'],
    patientEgn: ['ЕГН на пациент', 'patient egn'],
    patientBirthdate: ['рождена дата на пациент', 'patient birth date'],
    patientGender: ['пол на пациент', 'patient gender'],
    patientFirstName: ['име на пациент', 'patient first name'],
    patientLastName: ['фамилия на пациент', 'patient last name'],
    patientCountry: ['държава на пациент', 'patient country'],
    patientHealthArea: ['здравен район на пациент', 'patient health area'],
    patientEkatte: ['ЕКАТТЕ на пациент', 'patient ekatte'],
    patientTown: ['град на пациент', 'patient city'],
    patientAddress: ['адрес на пациент', 'patient address'],
    specialisationCode: ['код на специализация на лекар', 'medical staff specialisation code'],
    practiceNumber: ['номер на практика', 'practice number'],
    nrn: ['НРН на лечение', 'treatment NRN'],
};

const getIndex = locale => {
    let idx = 0;
    if (locale === LOCALES.BULGARIAN) {
        idx = 0;
    } else if (locale === LOCALES.ENGLISH) {
        idx = 1
    }

    return idx;
}

export const esignAppEndpoint = 'http://127.0.0.1:4343/xml/sign';

export const getToken = async nzisApi => {
    const challengeXmlResponse = await nzisApi.fetchNzisChallenge();

    const challenge = challengeXmlResponse.data;

    const signedChallengeResponse = await fetch(esignAppEndpoint, {
        method: 'post',
        headers: {
            'Accept': 'application/xml',
            'Content-Type': 'application/xml'
        },
        body: challenge
    });

    const signedChallenge = await signedChallengeResponse.text();

    return nzisApi.fetchNzisAccessToken(signedChallenge);
};

export const getQualificationCode = specialisationCode => {
    const mapping = {
        "61": "2079",
        "64": "2081",
        "62": "2083",
        "68": "3088",
    };

    return mapping[String(specialisationCode)] || "";
};

export const validateNzisTreatmentCreateRequest = (medicalStaff, patient, treatment, locale) => {
    const idx = getIndex(locale);
    const fields = [];

    if (!medicalStaff.uin) fields.push(translations.uin[idx]);
    if (!treatment.date) fields.push(translations.treatmentDate[idx]);
    if (!patient.healthRegion) fields.push(translations.patientHealthRegion[idx]);
    if (!patient.egn) fields.push(translations.patientEgn[idx]);
    if (!patient.birthDate) fields.push(translations.patientBirthdate[idx]);
    if (!patient.gender) fields.push(translations.patientGender[idx]);
    if (!patient.firstName) fields.push(translations.patientFirstName[idx]);
    if (!patient.lastName) fields.push(translations.patientLastName[idx]);
    if (!patient.country) fields.push(translations.patientCountry[idx]);
    if (!patient.healthArea) fields.push(translations.patientHealthArea[idx]);
    if (!patient.ekatte) fields.push(translations.patientEkatte[idx]);
    if (!patient.town) fields.push(translations.patientTown[idx]);
    if (!patient.addressOne) fields.push(translations.patientAddress[idx]);
    if (!medicalStaff.specialisationCode) fields.push(translations.specialisationCode[idx]);

    return fields;
}

export const validateNzisTreatmentAugmentRequest = (medicalStaff, patient, treatment, locale) => {
    const idx = getIndex(locale);
    const fields = [];

    if (!medicalStaff.uin) fields.push(translations.uin[idx]);
    if (!treatment.date) fields.push(translations.treatmentDate[idx]);
    if (!patient.healthRegion) fields.push(translations.patientHealthRegion[idx]);
    if (!treatment.nrnDental) fields.push(translations.specialisationCode[idx]);

    return fields;
}

export const validateNzisTreatmentCancelRequest = (treatment, locale) => {
    const idx = getIndex(locale);
    const fields = [];

    if (!treatment.nrnDental) fields.push(translations.specialisationCode[idx]);

    return fields;
}

export const validateNzisMedicalNoticeIssueRequest = (medicalStaff, patient, treatment, locale) => {
    const idx = getIndex(locale);
    const fields = [];

    if (!patient.healthRegion) fields.push(translations.patientHealthRegion[idx]);
    if (!patient.egn) fields.push(translations.patientEgn[idx]);
    if (!patient.birthDate) fields.push(translations.patientBirthdate[idx]);
    if (!patient.gender) fields.push(translations.patientGender[idx]);
    if (!patient.firstName) fields.push(translations.patientFirstName[idx]);
    if (!patient.lastName) fields.push(translations.patientLastName[idx]);
    if (!patient.country) fields.push(translations.patientCountry[idx]);
    if (!patient.healthArea) fields.push(translations.patientHealthArea[idx]);
    if (!patient.ekatte) fields.push(translations.patientEkatte[idx]);
    if (!patient.town) fields.push(translations.patientTown[idx]);
    if (!patient.addressOne) fields.push(translations.patientAddress[idx]);
    if (!medicalStaff.specialisationCode) fields.push(translations.specialisationCode[idx]);
    if (!treatment.nrnDental) fields.push(translations.specialisationCode[idx]);

    return fields;
}