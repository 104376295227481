var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Table } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { PatientApiFactory } from "dentobook-api-typescript-client/api";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { isMobileOnly } from "react-device-detect";
var ClinicGeneralCard = function (_a) {
    var type = _a.type, showModal = _a.showModal, onClose = _a.onClose, keyword = _a.keyword;
    var intl = useIntl();
    var _b = useState(''), newConditiontitle = _b[0], setNewConditiontitle = _b[1];
    var _c = useState(''), editConditionId = _c[0], setEditConditionId = _c[1];
    var _d = useState(false), editMode = _d[0], setEditMode = _d[1];
    var _e = useState(false), isDeleteModalVisible = _e[0], setIsDeleteModalVisible = _e[1];
    var _f = useState([]), conditions = _f[0], setConditions = _f[1];
    var _g = useState([]), displayedConditions = _g[0], setDisplayedConditions = _g[1];
    var _h = useState(false), loading = _h[0], setLoading = _h[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var patientApi = PatientApiFactory(apiConfig, '/api');
    var condition = {};
    var showEditModal = function (condition) {
        var _a, _b;
        if (condition) {
            setEditConditionId((_a = condition.id) !== null && _a !== void 0 ? _a : '');
            setNewConditiontitle((_b = condition.title) !== null && _b !== void 0 ? _b : '');
            setEditMode(true);
        }
    };
    useEffect(function () {
        loadData();
    }, [type]);
    var loadData = function () {
        setLoading(true);
        patientApi.fetchPatientConditions()
            .then(function (response) {
            var filteredConditions = response.data.filter(function (condition) { return condition.type === type; });
            setConditions(filteredConditions);
            setDisplayedConditions(filteredConditions);
        })
            .catch(function (error) { return console.error("Failed to load conditions:", error); })
            .finally(function () { return setLoading(false); });
    };
    useEffect(function () {
        if (keyword) {
            var filteredConditions = conditions.filter(function (condition) { var _a; return (_a = condition.title) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(keyword.toLowerCase()); });
            setDisplayedConditions(filteredConditions);
        }
        else {
            setDisplayedConditions(conditions);
        }
    }, [conditions, keyword]);
    var showDeleteModal = function (ConditionId) {
        setEditConditionId(ConditionId);
        setIsDeleteModalVisible(true);
    };
    var resetState = function () {
        setEditConditionId('');
        setNewConditiontitle('');
        setEditMode(false);
        setIsDeleteModalVisible(false);
    };
    var closeAddConditionModal = function () {
        onClose();
    };
    var handleCancel = function () {
        closeAddConditionModal();
        setEditConditionId('');
        setNewConditiontitle('');
        setEditMode(false);
    };
    var handleAddOk = function () {
        handleCreateCondition();
        closeAddConditionModal();
    };
    var handleCreateCondition = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    condition.title = newConditiontitle;
                    condition.type = type;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, patientApi.createPatientCondition(condition)];
                case 2:
                    _a.sent();
                    loadData();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Failed to create Condition:", error_1);
                    return [3 /*break*/, 4];
                case 4:
                    resetState();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleEditConditionOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    condition.title = newConditiontitle;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    if (!(editConditionId != null)) return [3 /*break*/, 3];
                    return [4 /*yield*/, patientApi.updateCondition(editConditionId, condition)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    loadData();
                    return [3 /*break*/, 5];
                case 4:
                    error_2 = _a.sent();
                    console.error("Failed to create Status:", error_2);
                    return [3 /*break*/, 5];
                case 5:
                    resetState();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleEditConditionCancel = function () {
        resetState();
    };
    var handleDeleteConditionOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!(editConditionId != null)) return [3 /*break*/, 2];
                    return [4 /*yield*/, patientApi.deleteCondition(editConditionId)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    loadData();
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.error("Failed to delete condition:", error_3);
                    return [3 /*break*/, 4];
                case 4:
                    resetState();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteConditionCancel = function () {
        resetState();
    };
    var getLabel = function (type) {
        switch (type.toLowerCase()) {
            case 'illness':
                return intl.formatMessage({ id: 'illnesses' });
            case 'allergy':
                return intl.formatMessage({ id: 'allergies' });
            case 'medication':
                return intl.formatMessage({ id: 'medication' });
            default:
                return intl.formatMessage({ id: 'medication' });
        }
    };
    var typeCamelCase = type.toString().toUpperCase()[0] + type.toString().toLowerCase().substring(1);
    var modalContent = _jsxs("div", __assign({ className: "anamnesis-modal-group" }, { children: [_jsx("div", __assign({ className: "input-group" }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input" }, { children: _jsx(Input, { value: getLabel(typeCamelCase), className: "custom-input" }) })) })), _jsx("div", __assign({ className: "input-group" }, { children: _jsx(Form, __assign({ layout: "vertical" }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "naming" }) }, { children: _jsx(Input, { value: newConditiontitle, onChange: function (e) { return setNewConditiontitle(e.target.value); }, className: "custom-input" }) })) })) }))] }));
    if (loading) {
        return _jsx("span", __assign({ className: "mt-4" }, { children: _jsx(FormattedMessage, { id: "loading" }) }));
    }
    return (_jsxs("div", __assign({ style: isMobileOnly ? { height: '40vh', width: 'auto', overflow: 'scroll', marginBottom: 100 } : {} }, { children: [_jsxs(Table, __assign({ dataSource: displayedConditions, pagination: false, className: "dentobook-table", style: { marginTop: '5px' } }, { children: [_jsx(Table.Column, { width: 800, className: 'custom-column-style', title: _jsx(FormattedMessage, { id: type.toString().toLowerCase() }), render: function (Condition) { return _jsx("span", __assign({ className: "font-weight-500" }, { children: Condition.title })); }, sorter: function (medicationOne, medicationTwo) { return medicationTwo.title.localeCompare(medicationOne.title); } }, "id"), _jsx(Table.Column, { width: isMobileOnly ? 500 : 100, title: _jsx("div", {}), className: 'custom-column-style', render: function (Condition) {
                            return (_jsxs("div", __assign({ style: { display: 'unset', alignItems: 'right' } }, { children: [_jsx(Button, { className: "text-black font-20 ml-1", type: "link", icon: _jsx(EditOutlined, {}), style: { marginRight: '10px' }, onClick: function () { return showEditModal(Condition); } }), _jsx(Button, { className: "text-black font-20", type: "link", icon: _jsx(DeleteOutlined, {}), onClick: function () { return showDeleteModal(Condition.id); } })] })));
                        } }, "actions")] })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, title: _jsx(FormattedMessage, { id: "add" + typeCamelCase }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: showModal, onOk: handleAddOk, onCancel: handleCancel, className: "dentobook-modal" }, { children: modalContent })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, title: _jsx(FormattedMessage, { id: 'edit' + typeCamelCase }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: editMode, onOk: handleEditConditionOk, onCancel: handleEditConditionCancel, className: "dentobook-modal" }, { children: modalContent })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, title: _jsx(FormattedMessage, { id: 'delete' + typeCamelCase }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: isDeleteModalVisible, onOk: handleDeleteConditionOk, onCancel: handleDeleteConditionCancel, className: "dentobook-modal" }, { children: _jsx("p", { children: _jsx(FormattedMessage, { id: type.toString().toLowerCase() + "DeleteConfirmation" }) }) }))] })));
};
export default ClinicGeneralCard;
