import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Alert } from 'antd';
import { FormattedMessage } from "react-intl";
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../../context/AlertContext';
import { CloseOutlined } from "@ant-design/icons";
var CustomAlert = function () {
    var _a = React.useContext(AlertContext), visible = _a.visible, setVisible = _a.setVisible, error = _a.error, setError = _a.setError, successMessage = _a.successMessage, redirectUrl = _a.redirectUrl;
    var navigate = useNavigate();
    var handleClick = function () {
        if (redirectUrl) {
            navigate(redirectUrl);
        }
        else {
            setError('');
            setVisible(false);
        }
    };
    return (_jsx(Alert, { style: visible || error ? { display: '' } : { display: 'none' }, className: "dentobook-alert fixed-alert clickable-alert", message: error || (successMessage || _jsx(FormattedMessage, { id: "success_message" })), type: error ? "error" : 'success', closeIcon: _jsx(CloseOutlined, {}), onClose: function () {
            setError('');
            setVisible(false);
        }, showIcon: true, onClick: handleClick }));
};
export default CustomAlert;
