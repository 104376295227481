var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CalendarEventSyncStatusEnum } from "dentobook-api-typescript-client/api";
import dayjs from "dayjs";
export function mapCalendarEventToWashEvent(calendarEvent) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var doctorId = (_b = (_a = calendarEvent.doctor) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '';
    var roomId = (_d = (_c = calendarEvent.dentalCabinet) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : '';
    var patientIdOrName = (_f = (_e = calendarEvent.patient) !== null && _e !== void 0 ? _e : calendarEvent.unregisteredPatient) !== null && _f !== void 0 ? _f : '';
    return {
        createdAt: (_g = calendarEvent.createdAt) !== null && _g !== void 0 ? _g : '',
        id: (_h = calendarEvent.id) !== null && _h !== void 0 ? _h : '',
        syncStatus: (_j = calendarEvent.syncStatus) !== null && _j !== void 0 ? _j : '',
        title: (_k = calendarEvent.title) !== null && _k !== void 0 ? _k : '',
        googleId: (_l = calendarEvent.googleId) !== null && _l !== void 0 ? _l : '',
        start: isoStringToDate(calendarEvent.start),
        end: isoStringToDate(calendarEvent.end),
        user: (_m = calendarEvent.userId) !== null && _m !== void 0 ? _m : 0,
        patient: patientIdOrName,
        doctor: doctorId,
        dentalCabinet: roomId,
        notes: (_o = calendarEvent.notes) !== null && _o !== void 0 ? _o : '',
        color: ''
    };
}
export function mapWashEventToCalendarEvent(washEvent) {
    // Assuming the IDs are UUIDs and using a simple regex pattern to check
    var isUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    // Check if the patient field is an ID or a name
    var isExistingPatient = isUuid.test(washEvent.patient);
    var patientId = '';
    var unregisteredPatient = '';
    if (isExistingPatient) {
        // It's an ID, so we set it to patientId
        patientId = washEvent.patient;
    }
    else {
        // It's a name, so we set it to unregisteredPatient
        unregisteredPatient = washEvent.patient;
    }
    var syncStatus = Object.values(CalendarEventSyncStatusEnum).includes(washEvent.syncStatus)
        ? washEvent.syncStatus
        : CalendarEventSyncStatusEnum.Synced;
    return {
        id: washEvent.id,
        syncStatus: syncStatus,
        title: washEvent.title,
        googleId: washEvent.googleId,
        start: dateToLocalISO(washEvent.start),
        end: dateToLocalISO(washEvent.end),
        userId: washEvent.user,
        patient: patientId,
        unregisteredPatient: unregisteredPatient,
        doctor: {
            id: washEvent.doctor,
            // ... add any other default properties if necessary
        },
        notes: washEvent.notes,
        dentalCabinet: {
            id: washEvent.dentalCabinet,
            // ... add any other default properties if necessary
        },
    };
}
export function mapCalendarEventsToWashEvents(calendarEvents) {
    return calendarEvents.map(function (calendarEvent) { return mapCalendarEventToWashEvent(calendarEvent); });
}
export function dateToLocalISO(date) {
    var off = date.getTimezoneOffset();
    return (new Date(date.getTime() - off * 60 * 1000).toISOString());
}
export function isoStringToDate(isoString) {
    if (!isoString) {
        return new Date();
    }
    var parts = isoString.split(/[-T:.Z]/).map(Number);
    if (parts.length < 6) {
        return new Date(); // Invalid ISO string format
    }
    return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
}
export var getDoctorColor = function (doctorId) {
    var index = Math.abs(stringHashCode(doctorId) % doctorColors.length);
    return doctorColors[index];
};
var doctorColors = [
    '#8b24a566', '#2CE3E366', '#3862ee66', '#3b486366', '#52c41a66',
    '#1890ff66', '#ff4d4f66', '#faad1466', '#9a9a9a66'
];
function stringHashCode(s) {
    var hash = 0;
    if (s.length === 0) {
        return hash;
    }
    for (var i = 0; i < s.length; i++) {
        var character = s.charCodeAt(i);
        hash = ((hash << 5) - hash) + character;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}
export var updatePatientHoursOnDelete = function (calendarEvent, patientApi) { return __awaiter(void 0, void 0, void 0, function () {
    var patientResult, patient, eventStart, eventEnd, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(calendarEvent.patient != null)) return [3 /*break*/, 6];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 6]);
                return [4 /*yield*/, patientApi.fetchPatient(calendarEvent.patient)];
            case 2:
                patientResult = _a.sent();
                patient = patientResult.data;
                eventStart = dayjs(calendarEvent.start).format("YYYY-MM-DD HH:mm:ss");
                eventEnd = dayjs(calendarEvent.end).format("YYYY-MM-DD HH:mm:ss");
                if (patient.lastHour && dayjs(patient.lastHour).isSame(eventEnd, 'minute')) {
                    patient.lastHour = undefined;
                }
                if (patient.nextHour && dayjs(patient.nextHour).isSame(eventStart, 'minute')) {
                    patient.nextHour = undefined;
                }
                if (!(patient.id != null)) return [3 /*break*/, 4];
                return [4 /*yield*/, patientApi.updatePatient(patient.id, patient)];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [3 /*break*/, 6];
            case 5:
                error_1 = _a.sent();
                console.error("Error fetching or updating patient", error_1);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}); };
export var deleteCalendarEvent = function (appointment, patientApi, calendarApi) { return __awaiter(void 0, void 0, void 0, function () {
    var error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, updatePatientHoursOnDelete(appointment, patientApi)];
            case 1:
                _a.sent();
                if (!(appointment.id != null)) return [3 /*break*/, 3];
                return [4 /*yield*/, calendarApi.deleteCalendarEvent(appointment.id)];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [3 /*break*/, 5];
            case 4:
                error_2 = _a.sent();
                console.error("Error deleting calendar event", error_2);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); };
