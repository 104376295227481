var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal } from "antd";
import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import '../assets/css/Dentobook.css';
import { FormattedMessage } from "react-intl";
import PatientForm from "../components/patient/PatientForm";
import DetailWrapper from "../components/common/DetailWrapper";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
import { isMobileOnly } from "react-device-detect";
import { CloseOutlined } from "@ant-design/icons";
import { ExitPromptContext } from "../context/ExitPromptContext";
var PatientCreate = function () {
    var setFormDirty = useContext(ExitPromptContext).setFormDirty;
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1];
    var _b = useState(false), goBack = _b[0], setGoBack = _b[1];
    var patient = {};
    var handleOk = function () {
        setFormDirty(false);
        setIsModalOpen(false);
        setGoBack(true);
    };
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    if (goBack) {
        return _jsx(Navigate, { to: "/patients" });
    }
    var footer = _jsxs("div", __assign({ className: "modal-btn-wrapper" }, { children: [_jsx(Button, __assign({ className: "modal-btn", onClick: handleCancel }, { children: _jsx(FormattedMessage, { id: "cancel" }) })), _jsx(Button, __assign({ className: "modal-btn", type: "primary", onClick: handleOk }, { children: _jsx(FormattedMessage, { id: "confirm" }) }))] }));
    return (_jsxs(AccessControl, __assign({ notAccessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR, accessibilityRoles.PATIENT] }, { children: [_jsx(Modal, __assign({ transitionName: "", footer: footer, className: "dentobook-modal", title: _jsx(FormattedMessage, { id: "add_patient" }), closeIcon: _jsx(CloseOutlined, { className: 'icon-24px', onClick: handleCancel }), open: isModalOpen, onOk: handleOk, onCancel: handleCancel }, { children: _jsx("h3", __assign({ className: "dentobook-content font-weight-500 font-24 text-align-center " + (isMobileOnly ? "mt-112" : "") }, { children: _jsx(FormattedMessage, { id: "unsaved_changes" }) })) })), _jsxs(DetailWrapper, __assign({ navigateBack: "/patients" }, { children: [_jsx("h2", { children: _jsx(FormattedMessage, { id: "add_patient" }) }), _jsx(PatientForm, { setEditMode: function () { }, mode: 2, openModal: function () { return setIsModalOpen(true); }, patient: patient })] }))] })));
};
export default PatientCreate;
