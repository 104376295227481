var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Badge, Button, Divider, Dropdown, Modal, Popover, Space } from "antd";
import { langItems } from "../../utils/utils";
import { FormattedMessage } from "react-intl";
import { BellOutlined, CaretDownFilled, InfoCircleOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { DentobookApplicationContext } from "../../context/DentobookApplicationContext";
import UserIcon from "../common/UserIcon";
import { MEDICAL_PHOTO_URL_PREFIX, PHOTO_URL_SUFFIX } from "../common/Constants";
import { isMobileOnly } from "react-device-detect";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { NotificationsApiFactory } from "dentobook-api-typescript-client/api";
import "../../assets/css/NotificationStyles.css";
export var RightHeaderMenu = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = React.useContext(DentobookApplicationContext), locale = _l.locale, setLocale = _l.setLocale, principal = _l.principal;
    var _m = useState(false), logoutDialogOpen = _m[0], setLogoutDialogOpen = _m[1];
    var _o = useState(false), visible = _o[0], setVisible = _o[1];
    var _p = useState([]), notificationsList = _p[0], setNotificationsList = _p[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var notificationsApiFactory = NotificationsApiFactory(apiConfig, '/api');
    var numNotifications = notificationsList.length;
    var _q = useState(false), isExpanded = _q[0], setIsExpanded = _q[1];
    useEffect(function () {
        var fetchNotifications = function () {
            var _a;
            if ((_a = principal === null || principal === void 0 ? void 0 : principal.user) === null || _a === void 0 ? void 0 : _a.medicalStaffId) {
                notificationsApiFactory.getNotifications(principal.user.medicalStaffId)
                    .then(function (response) {
                    setNotificationsList(response.data);
                })
                    .catch(function (error) { return console.error("Error fetching notifications:", error); });
            }
            else {
                console.error("MedicalStaffId is null");
            }
        };
        fetchNotifications();
        var intervalId = setInterval(fetchNotifications, 5000);
        return function () { return clearInterval(intervalId); }; // Clean up interval on component unmount
    }, []);
    var handleNotificationClick = function (entityId, notificationId) {
        notificationsApiFactory.markSingleNotificationAsRead(entityId, notificationId)
            .then(function () {
            window.location.href = '/patients/' + entityId;
        })
            .catch(function (error) {
            console.error("Error marking notification as read: ", error);
        });
    };
    var handleVisibility = function () {
        setVisible(!visible);
    };
    var sortedNotifications = __spreadArray([], notificationsList, true).sort(function (a, b) {
        var _a, _b;
        var dateA = new Date((_a = a.createdAt) !== null && _a !== void 0 ? _a : 0);
        var dateB = new Date((_b = b.createdAt) !== null && _b !== void 0 ? _b : 0);
        return dateB.getTime() - dateA.getTime();
    });
    var content = numNotifications === 0 ? (_jsx("div", __assign({ className: "notification-list" }, { children: _jsx("div", __assign({ className: "notification-item" }, { children: _jsx("span", { children: _jsx(FormattedMessage, { id: "no_notifications" }) }) })) }))) : (_jsxs("div", __assign({ className: "notification-list" }, { children: [(isExpanded ? sortedNotifications : sortedNotifications.slice(0, 3)).map(function (notification, index) { return (_jsx("div", __assign({ className: "notification-item ".concat(index !== sortedNotifications.length - 1 ? 'with-divider' : '') }, { children: _jsxs("div", __assign({ className: "notification-content" }, { children: [_jsx("div", __assign({ className: "info-icon custom-icon" }, { children: _jsx(InfoCircleOutlined, { className: "anticon" }) })), _jsx("span", { children: _jsx(FormattedMessage, { id: "new_patient" }) }), _jsx("a", __assign({ href: "#", onClick: function (e) {
                                e.preventDefault();
                                if (notification.id && notification.entityId) {
                                    handleNotificationClick(notification.entityId, notification.id);
                                }
                                else {
                                    console.error("Either notification.id or notification.entityId is undefined.");
                                }
                            }, className: "notification-link" }, { children: _jsx(FormattedMessage, { id: "here" }) })), _jsx("div", __assign({ className: "notification-date" }, { children: formatDate(notification.createdAt) }))] })) }), notification.id)); }), sortedNotifications.length > 3 && !isExpanded && (_jsxs("button", __assign({ className: "expand-notification-list plain-button", tabIndex: 0, onClick: function (e) {
                    e.stopPropagation();
                    setIsExpanded(true);
                }, onKeyDown: function (e) {
                    if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsExpanded(true);
                    }
                } }, { children: [_jsx(FormattedMessage, { id: "show_all" }), " ", notificationsList.length, " ", _jsx(FormattedMessage, { id: "notifications" })] }))), isExpanded && (_jsx("button", __assign({ className: "collapse-notification-list plain-button", tabIndex: 0, onClick: function (e) {
                    e.stopPropagation();
                    setIsExpanded(false);
                }, onKeyDown: function (e) {
                    if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsExpanded(false);
                    }
                } }, { children: _jsx(FormattedMessage, { id: "collapse" }) })))] })));
    var promptLogout = function () {
        setLogoutDialogOpen(true);
    };
    var handleLogoutOk = function () {
        setLogoutDialogOpen(false);
        axios.post("/api/logout")
            .then(function () {
            window.location.href = "/";
        })
            .catch(function () {
            window.location.href = "/";
        });
    };
    var handleLogoutCancel = function () {
        setLogoutDialogOpen(false);
    };
    var localeItems = langItems.map(function (l) { return ({
        key: l.label,
        label: l.label,
        onClick: function () {
            setLocale(l.value);
            localStorage.setItem('dentobook-lang', l.value);
        }
    }); });
    var userItems = [
        {
            key: '1',
            label: (_jsxs(Space, __assign({ className: "mt-2" }, { children: [_jsx("div", { children: ((_a = principal === null || principal === void 0 ? void 0 : principal.medicalPerson) === null || _a === void 0 ? void 0 : _a.avatar) ?
                            _jsx("img", { className: "user-avatar-img", width: 10, src: MEDICAL_PHOTO_URL_PREFIX + ((_b = principal === null || principal === void 0 ? void 0 : principal.medicalPerson) === null || _b === void 0 ? void 0 : _b.id) + PHOTO_URL_SUFFIX, alt: "avatar" }) :
                            _jsx(UserIcon, { size: "xs", rounded: true }) }), _jsxs(Space, __assign({ direction: "vertical", className: "user-items-header" }, { children: [_jsxs("span", __assign({ className: "dentobook-content font-14 font-weight-500 font-family-roboto" }, { children: [(_c = principal === null || principal === void 0 ? void 0 : principal.user) === null || _c === void 0 ? void 0 : _c.firstName, " ", (_d = principal === null || principal === void 0 ? void 0 : principal.user) === null || _d === void 0 ? void 0 : _d.lastName] })), _jsx("span", __assign({ className: "font-10 font-weight-400 font-family-roboto" }, { children: _jsx(FormattedMessage, { id: (_e = principal === null || principal === void 0 ? void 0 : principal.user) === null || _e === void 0 ? void 0 : _e.role }) }))] }))] }))),
        },
        {
            key: '2',
            label: (_jsx("span", __assign({ className: "font-family-roboto" }, { children: _jsx(FormattedMessage, { id: "profile" }) }))),
            icon: _jsx(UserOutlined, { style: { fontSize: 16 } })
        },
        {
            key: '3',
            label: (_jsx("span", __assign({ className: "font-family-roboto" }, { children: _jsx(FormattedMessage, { id: "logout" }) }))),
            icon: _jsx(LogoutOutlined, { style: { fontSize: 16 } }),
            onClick: function () {
                promptLogout();
            }
        }
    ];
    return (_jsxs("div", __assign({ className: "nav-items" }, { children: [_jsxs("div", __assign({ style: { display: 'flex', flexDirection: 'row', alignItems: 'center' } }, { children: [_jsx(Dropdown, __assign({ menu: { items: localeItems }, className: "langs-dropdown" }, { children: _jsx("button", __assign({ className: "dropdown-text dentobook-link font-family-roboto plain-button", onKeyDown: function (e) { return e.preventDefault(); }, onClick: function (e) { return e.preventDefault(); } }, { children: _jsxs(Space, { children: [locale, _jsx(CaretDownFilled, { width: 100 })] }) })) })), (principal === null || principal === void 0 ? void 0 : principal.user) ?
                        _jsx(Popover, __assign({ className: "popover-style", content: content, trigger: "hover", defaultOpen: visible, afterOpenChange: handleVisibility, placement: "bottomLeft" }, { children: _jsx(Badge, __assign({ count: numNotifications, color: "blue" }, { children: _jsx(BellOutlined, { className: "notifications", onClick: handleVisibility }) })) }))
                        : null] })), (principal === null || principal === void 0 ? void 0 : principal.user) ?
                _jsxs(_Fragment, { children: [_jsx(Divider, { type: "vertical", className: "divider" }), _jsx(Dropdown, __assign({ menu: { items: userItems }, rootClassName: "langs-dropdown-root", className: "langs-dropdown", overlayClassName: "user-dropdown" }, { children: _jsx("button", __assign({ className: "dropdown-text dentobook-link plain-button", onKeyDown: function (e) { return e.preventDefault(); }, onClick: function (e) { return e.preventDefault(); } }, { children: _jsxs(Space, { children: [((_f = principal === null || principal === void 0 ? void 0 : principal.medicalPerson) === null || _f === void 0 ? void 0 : _f.avatar) ?
                                            _jsx("img", { className: "user-avatar-img", width: 10, src: MEDICAL_PHOTO_URL_PREFIX + ((_g = principal === null || principal === void 0 ? void 0 : principal.medicalPerson) === null || _g === void 0 ? void 0 : _g.id) + PHOTO_URL_SUFFIX, alt: "avatar" }) :
                                            _jsx("svg", __assign({ viewBox: "64 64 896 896", focusable: "false", "data-icon": "user", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true" }, { children: _jsx("path", { d: "M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z" }) })), _jsxs("span", __assign({ className: "ml-4 font-14 font-family-roboto" }, { children: [(_h = principal === null || principal === void 0 ? void 0 : principal.user) === null || _h === void 0 ? void 0 : _h.firstName, " ", ((_k = (_j = principal === null || principal === void 0 ? void 0 : principal.user) === null || _j === void 0 ? void 0 : _j.lastName) === null || _k === void 0 ? void 0 : _k.charAt(0)) + '.'] }))] }) })) }))] })
                : null, _jsx(Modal, __assign({ transitionName: "", className: "logout-modal", open: logoutDialogOpen, footer: null, width: "100vw", style: { height: '100vh' }, closable: false }, { children: _jsxs("div", __assign({ className: "mt-10", style: {
                        display: 'flex',
                        width: '100vw',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    } }, { children: [_jsx("img", { src: require('../../assets/logo.png'), height: 90, alt: "" }), _jsx("p", __assign({ className: "font-family-roboto font-weight-500 font-24 text-align-center mt-4", style: !isMobileOnly ? { width: '30%' } : { width: '100%' } }, { children: _jsx(FormattedMessage, { id: "logoutConfirm" }) })), _jsxs(Space, __assign({ className: "mt-4" }, { children: [_jsx(Button, __assign({ className: "font-16 font-weight-500 modal-btn-large", onClick: handleLogoutCancel }, { children: _jsx(FormattedMessage, { id: "cancel" }) })), _jsx(Button, __assign({ className: "font-16 font-weight-500 modal-btn-large", type: "primary", onClick: handleLogoutOk }, { children: _jsx(FormattedMessage, { id: "logout" }) }))] }))] })) }))] })));
};
var formatDate = function (createdAtString) {
    var date = new Date(createdAtString);
    return "".concat(date.toLocaleTimeString().substring(0, 5), " ").concat(date.toLocaleDateString());
};
export default RightHeaderMenu;
