var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row, Select } from 'antd';
import "../../assets/css/Dentobook.css";
import "../../assets/css/PatientPhotos.css";
import { FormattedMessage, useIntl } from "react-intl";
// @ts-ignore
import Tags from '@yaireo/tagify/dist/react.tagify';
import { CaretDownOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { PatientApiFactory } from "dentobook-api-typescript-client/api";
import { Configuration } from "dentobook-api-typescript-client/configuration";
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { AlertContext } from "../../context/AlertContext";
import { isMobileOnly } from "react-device-detect";
import { useForm } from "antd/es/form/Form";
var PatientTreatmentStatusModal = function (_a) {
    var mode = _a.mode, appointment = _a.appointment, loadData = _a.loadData, statuses = _a.statuses, selectedTableStatus = _a.selectedTableStatus, selectedStatuses = _a.selectedStatuses, allTeethStatuses = _a.allTeethStatuses, allTeethPositions = _a.allTeethPositions, toothObjs = _a.toothObjs, title = _a.title, isModalOpen = _a.isModalOpen, setIsModalOpen = _a.setIsModalOpen;
    var intl = useIntl();
    var _b = useContext(AlertContext), setVisible = _b.setVisible, setError = _b.setError;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(''), fieldsError = _d[0], setFieldsError = _d[1];
    var _e = useState([]), positions = _e[0], setPositions = _e[1];
    var _f = useState([]), conditions = _f[0], setConditions = _f[1];
    var _g = useState(), notes = _g[0], setNotes = _g[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var patientApi = PatientApiFactory(apiConfig, '/api');
    var tagifyRef = useRef();
    var richTextEditorToolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'align': [] }],
    ];
    var form = useForm()[0];
    var toothObj = toothObjs.length && toothObjs[0] ? toothObjs[0] : {};
    useEffect(function () {
        setPositions(toothObjs.filter(function (toothObj) { return toothObj; }).map(function (toothObj) { return toothObj === null || toothObj === void 0 ? void 0 : toothObj.position; }));
        var quillElement = document.getElementsByClassName('ql-editor')[0];
        if (!isModalOpen || mode === 'create') {
            setConditions([{
                    uid: uuidv4(),
                    id: '',
                    nzisId: ''
                }]);
            if (notes && quillElement) {
                quillElement.innerHTML = '';
            }
            setNotes('');
        }
        else {
            var temp = allTeethStatuses.filter(function (statusObj) { var _a; return ((_a = statusObj.tooth) === null || _a === void 0 ? void 0 : _a.id) === toothObj.id; });
            if (temp.length && quillElement) {
                quillElement.innerHTML = temp[0].notes || '';
                setNotes(temp[0].notes);
            }
        }
    }, [isModalOpen]);
    useEffect(function () {
        if (!selectedStatuses || !selectedStatuses.length) {
            setConditions([{
                    uid: uuidv4(),
                    id: '',
                    nzisId: ''
                }]);
            return;
        }
        if (selectedStatuses.length) {
            var toothStatus = allTeethStatuses.filter(function (statusObject) { var _a; return ((_a = statusObject.tooth) === null || _a === void 0 ? void 0 : _a.id) === toothObj.id; })[0];
            setNotes((toothStatus === null || toothStatus === void 0 ? void 0 : toothStatus.notes) || '');
            var conditionsArray = [];
            for (var _i = 0, selectedStatuses_1 = selectedStatuses; _i < selectedStatuses_1.length; _i++) {
                var selectedStatus = selectedStatuses_1[_i];
                var uid = uuidv4();
                var id = selectedStatus.id;
                var nzisId = '';
                if (mode === 'edit') {
                    nzisId = selectedTableStatus.nzisStatusId;
                }
                conditionsArray.push({ uid: uid, id: id, nzisId: nzisId });
                form.setFieldValue('condition-' + uid, id);
            }
            setConditions(conditionsArray);
            return;
        }
        if (statuses.length === 0) {
            return;
        }
        setConditions([{
                uid: uuidv4(),
                id: '',
                nzisId: ''
            }]);
    }, [selectedStatuses]);
    var handleCancel = function () {
        setFieldsError('');
        setIsModalOpen(false);
    };
    var handleOk = function () {
        form.validateFields()
            .then(function () {
            var invalidToothFlag = false;
            var teethToPost = tagifyRef.current.value.map(function (element) {
                var whitelist = allTeethPositions.map(function (toothPosition) { return toothPosition === null || toothPosition === void 0 ? void 0 : toothPosition.position.toString(); });
                return allTeethPositions.filter(function (toothObj) {
                    var toothPosition = +(toothObj === null || toothObj === void 0 ? void 0 : toothObj.position);
                    var tagifyValue = +element.value;
                    if (whitelist.indexOf(element.value) === -1) {
                        invalidToothFlag = true;
                    }
                    return toothPosition === tagifyValue;
                })[0];
            });
            if (invalidToothFlag) {
                setFieldsError(intl.formatMessage({ id: 'invalid_tooth_position' }));
                return;
            }
            if (conditions.length === 0) {
                return;
            }
            if (!appointment) {
                console.log("appointment id is undefined.....");
                return;
            }
            var dataToPost = [];
            for (var _i = 0, teethToPost_1 = teethToPost; _i < teethToPost_1.length; _i++) {
                var toothToPost = teethToPost_1[_i];
                for (var _a = 0, conditions_1 = conditions; _a < conditions_1.length; _a++) {
                    var conditionToPost = conditions_1[_a];
                    dataToPost.push({
                        tooth: toothToPost,
                        statusId: conditionToPost.id,
                        nzisStatusId: conditionToPost.nzisId,
                        notes: notes,
                    });
                }
            }
            if (teethToPost.length === 0) {
                for (var _b = 0, conditions_2 = conditions; _b < conditions_2.length; _b++) {
                    var conditionToPost = conditions_2[_b];
                    dataToPost.push({
                        tooth: undefined,
                        statusId: conditionToPost.id,
                        nzisStatusId: conditionToPost.nzisId,
                        notes: notes
                    });
                }
            }
            setLoading(true);
            if (mode === 'create') {
                patientApi.createToothStatus(appointment, dataToPost)
                    .then(function () {
                    setLoading(false);
                    setVisible(true);
                    setTimeout(function () { return setVisible(false); }, 10000);
                    loadData();
                    setIsModalOpen(false);
                })
                    .catch(function (err) { return console.log(err); });
            }
            else {
                console.log({ appointment: appointment, a: selectedTableStatus.id, b: dataToPost[0] });
                patientApi.updateToothStatus(appointment, selectedTableStatus.id, dataToPost[0])
                    .then(function () {
                    setLoading(false);
                    setVisible(true);
                    setTimeout(function () { return setVisible(false); }, 10000);
                    loadData();
                    setIsModalOpen(false);
                })
                    .catch(function (err) { return console.log(err); });
            }
        })
            .catch(function (err) {
            console.log(err);
            var blankFieldExists = Object.values(err.values).filter(function (item) { return !item; }).length > 0;
            if (blankFieldExists) {
                setError(intl.formatMessage({ id: 'all_fields_required' }));
                setTimeout(function () { return setError(''); }, 10000);
            }
            return;
        });
    };
    var handleFilter = function (input, option) { return option.label.toLowerCase().includes(input.toLowerCase()); };
    var conditionOptions = statuses.sort(function (statusOne, statuseTwo) { var _a; return (_a = statusOne.name) === null || _a === void 0 ? void 0 : _a.localeCompare(statuseTwo.name); })
        .map(function (status) {
        return { value: status.id, label: status.name };
    });
    var modalCreateTitle = useIntl().formatMessage({ id: 'add_condition_title' });
    var footer = _jsxs("div", __assign({ className: "modal-btn-wrapper" }, { children: [_jsx(Button, __assign({ className: "modal-btn", onClick: handleCancel }, { children: _jsx(FormattedMessage, { id: "cancel" }) })), _jsx(Button, __assign({ className: "modal-btn", type: "primary", onClick: handleOk }, { children: _jsx(FormattedMessage, { id: "confirm" }) }))] }));
    return (_jsx(Modal, __assign({ transitionName: "", className: 'dentobook-modal', footer: footer, okButtonProps: {
            className: "modal-btn"
        }, cancelButtonProps: {
            className: "modal-btn"
        }, title: title !== null && title !== void 0 ? title : modalCreateTitle, width: 800, bodyStyle: { overflowY: 'auto', maxHeight: 'calc(100vh - 300px)' }, confirmLoading: loading, closeIcon: _jsx(CloseOutlined, { className: 'icon-24px', onClick: handleCancel }), open: isModalOpen }, { children: _jsxs(Form, __assign({ layout: "vertical", form: form }, { children: [fieldsError && _jsx("p", __assign({ className: "text-red" }, { children: fieldsError })), _jsx(Form.Item, __assign({ label: _jsx(FormattedMessage, { id: "position" }) }, { children: _jsx("div", __assign({ className: "borderless teeth-tagify" }, { children: _jsx(Tags, { onChange: function () { return setPositions(tagifyRef.current.value); }, value: positions, tagifyRef: tagifyRef, whitelist: allTeethPositions.map(function (toothPosition) { var _a; return (_a = toothPosition.position) === null || _a === void 0 ? void 0 : _a.toString(); }), enforceWhitelist: false, disabled: mode === 'edit', settings: {
                                enforceWhitelist: false,
                                blacklist: [],
                                maxTags: 100,
                                dropdown: {
                                    enabled: 0
                                },
                                callbacks: {}
                            } }) })) })), conditions.map(function (condition) { return (_jsxs(_Fragment, { children: [_jsx(Row, __assign({ gutter: 12 }, { children: _jsx(Col, __assign({ md: 24 }, { children: _jsx(Form.Item, __assign({ name: "condition-" + condition.uid, className: "dentobook-form-item input-full-width", label: _jsx(FormattedMessage, { id: "condition" }), rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Select, { showSearch: true, optionFilterProp: "label", placeholder: _jsx(FormattedMessage, { id: "selectPlaceholder" }), size: "large", className: "dentobook-input", style: isMobileOnly ? { width: '88vw' } : {}, suffixIcon: _jsx(CaretDownOutlined, {}), filterOption: handleFilter, options: conditionOptions, value: condition.id, onChange: function (e) {
                                            setConditions(conditions.map(function (cond) {
                                                if (cond.uid === condition.uid) {
                                                    cond.id = e;
                                                }
                                                return cond;
                                            }));
                                        }, allowClear: true }) }), condition.uid) })) })), conditions.length > 1 ? _jsx(Button, { className: "text-black", icon: _jsx(DeleteOutlined, {}), type: "link", onClick: function () { return setConditions(conditions.filter(function (cond) { return cond.uid !== condition.uid; })); } }) : null] })); }), _jsx(Form.Item, __assign({ label: _jsx(FormattedMessage, { id: "notes" }), name: "notes", initialValue: notes, className: "dentobook-form-item dentobook-simple-input mt-12" }, { children: _jsx(ReactQuill, { id: "notesQuill", value: notes, onChange: function (e) { return setNotes(e); }, modules: {
                            toolbar: richTextEditorToolbarOptions
                        } }) }))] })) })));
};
export default PatientTreatmentStatusModal;
