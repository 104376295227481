var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Table } from "antd";
import React, { useEffect, useState } from "react";
import { UserApiFactory } from 'dentobook-api-typescript-client/api';
import { Configuration } from 'dentobook-api-typescript-client/configuration';
import { FormattedMessage } from "react-intl";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
var Users = function () {
    var _a = useState([]), users = _a[0], setUsers = _a[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var userApi = UserApiFactory(apiConfig, '/api');
    useEffect(function () {
        userApi.fetchAllUsers()
            .then(function (response) {
            var userData = response.data.map(function (item, idx) { return (__assign({ key: idx }, item)); });
            return setUsers(userData);
        })
            .catch(function (error) {
            if (error.code === "ERR_NETWORK") {
                window.location.href = "".concat(window.location.origin.replace(window.location.port, '9000'));
            }
        });
    }, []);
    return (_jsx(AccessControl, __assign({ accessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR] }, { children: _jsx(Card, __assign({ title: _jsx(FormattedMessage, { id: "users" }), bordered: false }, { children: _jsxs(Table, __assign({ dataSource: users }, { children: [_jsx(Table.Column, { dataIndex: "firstName", title: _jsx(FormattedMessage, { id: "first_name" }) }, "firstName"), _jsx(Table.Column, { dataIndex: "lastName", title: _jsx(FormattedMessage, { id: "last_name" }) }, "lastName"), _jsx(Table.Column, { dataIndex: "phone", title: _jsx(FormattedMessage, { id: "phone" }) }, "phone")] })) })) })));
};
export default Users;
