export const checkFormDirty = (currentState, initialState) => {
    for (const fieldItem of currentState) {
        const fieldName = fieldItem.name[0];
        let fieldValue = fieldItem.value;
        let initialFieldValue = initialState[fieldName];

        if ((!initialFieldValue && typeof initialFieldValue !== 'number') ||
            (Array.isArray(initialFieldValue) && !initialFieldValue.length)
            || initialFieldValue === '<p><br></p>') {
            initialFieldValue = '';
        }
        if ((!fieldValue && typeof fieldValue !== 'number')
            || (Array.isArray(fieldValue) && !fieldValue.length)
            || fieldValue === '<p><br></p>') {
            fieldValue = '';
        }
        if (initialFieldValue.toString() !== fieldValue.toString()) {
            return true;
        }
    }

    return false;
};

export const arraysEqual = (arrOne, arrTwo) => {
    if (arrOne.length !== arrTwo.length) return false;

    let sortedArrOne = arrOne.slice().sort();
    let sortedArrTwo = arrTwo.slice().sort();

    for (let i = 0; i < sortedArrOne.length; i++) {
        if (sortedArrOne[i] !== sortedArrTwo[i]) return false;
    }
    return true;
}
