import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { DentobookApplicationContext } from "../../context/DentobookApplicationContext";
import { bitwiseOr, isAccessibleFor, isNotAccessibleFor } from "../../utils/accessUtils";
var AccessControl = function (props) {
    var principal = React.useContext(DentobookApplicationContext).principal;
    var _a = useState(false), accessible = _a[0], setAccessible = _a[1];
    useEffect(function () {
        if (props.accessibleFor) {
            setAccessible(isAccessibleFor(principal === null || principal === void 0 ? void 0 : principal.user, bitwiseOr(props.accessibleFor)));
        }
        else if (props.notAccessibleFor) {
            setAccessible(isNotAccessibleFor(principal === null || principal === void 0 ? void 0 : principal.user, bitwiseOr(props.notAccessibleFor)));
        }
    }, [principal]);
    return (_jsx(_Fragment, { children: accessible &&
            _jsx(_Fragment, { children: props.children }) }));
};
export default AccessControl;
