var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Space, Table } from "antd";
import { FormattedMessage } from "react-intl";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { MedicalStaffApiFactory } from "dentobook-api-typescript-client/api";
import DetailWrapper from "../components/common/DetailWrapper";
import { Link, useNavigate } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { PlusOutlined } from "@ant-design/icons";
import SearchForm from "../components/common/SearchForm";
import InfiniteScroll from 'react-infinite-scroll-component';
import UserIcon from "../components/common/UserIcon";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
import { MEDICAL_PHOTO_URL_PREFIX, PHOTO_URL_SUFFIX } from "../components/common/Constants";
var MedicalStaffComp = function () {
    var navigate = useNavigate();
    var _a = useState(''), keyword = _a[0], setKeyword = _a[1];
    var _b = useState('id'), sortBy = _b[0], setSortBy = _b[1];
    var _c = useState(1), page = _c[0], setPage = _c[1];
    var _d = useState(window.innerHeight), height = _d[0], setHeight = _d[1];
    var _e = useState([]), medicalStaff = _e[0], setMedicalStaff = _e[1];
    var _f = useState(0), totalElements = _f[0], setTotalElements = _f[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var medicalStaffApi = MedicalStaffApiFactory(apiConfig, '/api');
    useEffect(function () {
        setHeight(window.innerHeight * 0.01);
        var handleResize = function () {
            setHeight(window.innerHeight * 0.01);
        };
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    useEffect(function () {
        loadData(true, sortBy, keyword);
    }, []);
    var loadData = function (refresh, sortBy, keyword) {
        if (refresh) {
            setPage(1);
        }
        else {
            setPage(page + 1);
        }
        medicalStaffApi.fetchMedicalStaff(keyword, sortBy || 'id', refresh ? 1 : page + 1, 6)
            .then(function (response) {
            console.log(response);
            if (refresh) {
                setMedicalStaff(response.data.medicalStaff);
            }
            else {
                setMedicalStaff(medicalStaff.concat(response.data.medicalStaff));
            }
            setTotalElements(response.data.totalElements);
        });
    };
    return (_jsx(AccessControl, __assign({ accessibleFor: [accessibilityRoles.CLINIC_ADMIN, accessibilityRoles.RECEPTIONIST] }, { children: _jsxs(DetailWrapper, __assign({ navigateBack: undefined, removeVerticalScroll: true }, { children: [_jsxs(Row, __assign({ className: "mb-2" }, { children: [_jsx(Col, __assign({ span: 12 }, { children: _jsx("h3", __assign({ style: { width: '140%' } }, { children: _jsx(FormattedMessage, { id: "medical_staff" }) })) })), _jsx(Col, __assign({ span: 12 }, { children: _jsx(Link, __assign({ style: { float: 'right', marginTop: '24px' }, to: "/medical/staff/create" }, { children: _jsx("div", __assign({ className: isMobileOnly ? "mobile-btn-wrapper" : "" }, { children: _jsxs(Button, __assign({ shape: "round", className: isMobileOnly ? "mobile-add-btn" : "dentobook-btn-primary dentobook-btn-medium", type: "primary" }, { children: [_jsx(PlusOutlined, {}), " ", _jsx(FormattedMessage, { id: "add" })] })) })) })) }))] })), _jsx(SearchForm, { entity: "medical", keyword: keyword, setKeyword: setKeyword, sortBy: sortBy, setSortBy: setSortBy, loadData: loadData }), medicalStaff.length === 0 ? _jsx("b", __assign({ className: "text-center" }, { children: _jsx(FormattedMessage, { id: "no_data" }) })) :
                    _jsx("div", __assign({ id: "scrollableDiv", style: {
                            height: "".concat(height * 80 - (!isMobileOnly ? 170 : 300), "px"),
                            width: '100%',
                            overflow: 'scroll',
                            position: 'absolute'
                        } }, { children: _jsx(InfiniteScroll, __assign({ scrollableTarget: "scrollableDiv", dataLength: medicalStaff.length, next: function () { return loadData(false, sortBy, keyword); }, hasMore: medicalStaff.length < totalElements, loader: _jsx("h4", { children: _jsx(FormattedMessage, { id: "loading" }) }), style: { overflow: 'hidden' }, refreshFunction: function () { return loadData(true, sortBy, keyword); }, pullDownToRefresh: true }, { children: isMobileOnly ?
                                _jsxs(Table, __assign({ dataSource: medicalStaff, pagination: false, className: "dentobook-table", onRow: function (person) {
                                        return {
                                            onClick: function () {
                                                navigate('/medical/staff/' + person.id);
                                            }
                                        };
                                    } }, { children: [_jsx(Table.Column, { render: function (person) { return person.firstName + ' ' + person.lastName; }, title: _jsx(FormattedMessage, { id: "name" }) }, "name"), _jsx(Table.Column, { render: function (person) { return _jsx(FormattedMessage, { id: person.role }); }, title: _jsx(FormattedMessage, { id: "role" }) }, "role")] }))
                                :
                                    _jsx(Row, __assign({ gutter: 24 }, { children: medicalStaff.map(function (person) { return (_jsx(Col, __assign({ lg: 8 }, { children: _jsx(Card, __assign({ className: "medical-staff-card", onClick: function () { return navigate('/medical/staff/' + person.id); } }, { children: _jsxs(Space, __assign({ direction: "vertical", className: "medical-staff-container" }, { children: [_jsx("div", __assign({ className: "mb-4" }, { children: !person.avatar ? _jsx(UserIcon, { size: "medium", rounded: true }) :
                                                                _jsx("img", { className: "photo-img-medical-staff", width: 60, src: MEDICAL_PHOTO_URL_PREFIX + person.id + PHOTO_URL_SUFFIX, alt: "avatar" }) })), _jsx("span", __assign({ className: "font-weight-500 font-16 font-family-roboto" }, { children: person.firstName + " " + (person.secondName || "") + " " + person.lastName })), _jsx("span", __assign({ className: "font-weight-400 font-14 font-family-roboto" }, { children: _jsx(FormattedMessage, { id: person.role }) }))] })) })) }), person.id)); }) })) })) }))] })) })));
};
export default MedicalStaffComp;
