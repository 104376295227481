var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import '../../assets/css/Dentobook.css';
import { Link, useNavigate } from "react-router-dom";
import { ExitPromptContext } from "../../context/ExitPromptContext";
var DentobookLink = function (_a) {
    var to = _a.to, _b = _a.cssClassName, cssClassName = _b === void 0 ? '' : _b, cb = _a.cb, children = _a.children;
    var _c = useContext(ExitPromptContext), isFormDirty = _c.isFormDirty, setExitModalOpen = _c.setExitModalOpen, setExitModalAction = _c.setExitModalAction;
    var navigate = useNavigate();
    var handleClick = function (e) {
        e.preventDefault();
        if (isFormDirty) {
            setExitModalOpen(true);
            setExitModalAction(to);
            return;
        }
        if (cb) {
            cb();
        }
        navigate(to);
    };
    return (_jsx(Link, __assign({ to: '/', className: cssClassName, onClick: handleClick }, { children: children })));
};
export default DentobookLink;
