var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { PatientApiFactory } from 'dentobook-api-typescript-client/api';
import { Configuration } from 'dentobook-api-typescript-client/configuration';
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { isMobileOnly } from 'react-device-detect';
import '../assets/css/Dentobook.css';
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import UserIcon from "../components/common/UserIcon";
import DetailWrapper from "../components/common/DetailWrapper";
import InfiniteScroll from "react-infinite-scroll-component";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
import PatientCreateModal from "../components/patient/PatientCreateModal";
import { PATIENT_PHOTO_URL_PREFIX, PHOTO_URL_SUFFIX } from "../components/common/Constants";
import SearchForm from "../components/common/SearchForm";
var Patients = function () {
    var _a = useState(''), keyword = _a[0], setKeyword = _a[1];
    var _b = useState('id'), sortBy = _b[0], setSortBy = _b[1];
    var _c = useState('asc'), order = _c[0], setOrder = _c[1];
    var _d = useState([]), patients = _d[0], setPatients = _d[1];
    var _e = useState(0), totalElements = _e[0], setTotalElements = _e[1];
    var _f = useState(false), isPatientCreateModalOpen = _f[0], setIsPatientCreateModalOpen = _f[1];
    var _g = useState(0), page = _g[0], setPage = _g[1];
    var _h = useState(window.innerHeight), height = _h[0], setHeight = _h[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var patientApi = PatientApiFactory(apiConfig, '/api');
    var navigate = useNavigate();
    useEffect(function () {
        setHeight(window.innerHeight * 0.01);
        var handleResize = function () {
            setHeight(window.innerHeight * 0.01);
        };
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    useEffect(function () {
        loadData(true, sortBy, keyword);
    }, []);
    var loadData = function (refresh, sortBy, keyword) {
        if (refresh) {
            setPage(0);
        }
        else {
            setPage(function (prevState) { return prevState + 1; });
        }
        patientApi.fetchAllPatients(keyword, sortBy, order, refresh ? 0 : page + 1, 15).then(function (response) {
            console.log(response);
            if (typeof response.data.pagesCount === 'undefined'
                || typeof response.data.patients === 'undefined'
                || typeof response.data.totalElements === 'undefined') {
                return;
            }
            setTotalElements(response.data.totalElements);
            var itemsData = response.data.patients.map(function (item) { return (__assign(__assign({ key: item.id }, item), { name: item.firstName + ' ' + item.lastName, lastHour: item.lastHour ? dayjs(item.lastHour).format('DD.MM.YYYY') : "", nextHour: item.nextHour ? dayjs(item.nextHour).format('DD.MM.YYYY') : "" })); });
            if (refresh) {
                setPatients(itemsData);
            }
            else {
                setPatients(patients.concat(itemsData));
            }
        });
    };
    return (_jsxs(AccessControl, __assign({ notAccessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR, accessibilityRoles.PATIENT] }, { children: [_jsx(PatientCreateModal, { isModalOpen: isPatientCreateModalOpen, setIsModalOpen: setIsPatientCreateModalOpen }), _jsxs(DetailWrapper, __assign({ navigateBack: undefined, removeVerticalScroll: true }, { children: [_jsxs(Row, __assign({ className: "mb-2" }, { children: [_jsx(Col, __assign({ span: 12 }, { children: _jsx("h3", { children: _jsx(FormattedMessage, { id: "patients" }) }) })), _jsx(Col, __assign({ span: 12 }, { children: _jsx("div", __assign({ className: isMobileOnly ? "mobile-btn-wrapper" : "" }, { children: _jsxs(Button, __assign({ shape: "round", id: "add-button", className: isMobileOnly ? "mobile-add-btn" : "dentobook-btn-primary dentobook-btn-medium", style: { float: 'right', marginTop: '24px' }, onClick: function () { return setIsPatientCreateModalOpen(true); }, type: "primary" }, { children: [_jsx(PlusOutlined, {}), " ", _jsx(FormattedMessage, { id: "add" })] })) })) }))] })), _jsx(SearchForm, { entity: "patient", keyword: keyword, setKeyword: setKeyword, sortBy: sortBy, setSortBy: setSortBy, loadData: loadData }), isMobileOnly ? _jsx("div", { className: "horizontal-scroll-indicator" }) : null, _jsx("div", __assign({ id: "scrollableDiv", style: {
                            height: "".concat(height * 80 - (!isMobileOnly ? 170 : 300), "px"),
                            width: '100%',
                            overflow: 'scroll',
                            position: 'absolute'
                        } }, { children: _jsx(InfiniteScroll, __assign({ scrollableTarget: "scrollableDiv", dataLength: patients.length, next: function () { return loadData(false, sortBy, keyword); }, hasMore: patients.length < totalElements, style: { overflow: 'hidden' }, loader: _jsx("h4", { children: _jsx(FormattedMessage, { id: "loading" }) }), refreshFunction: function () { return loadData(true, sortBy, keyword); }, pullDownToRefresh: true }, { children: _jsxs(Table, __assign({ dataSource: patients, pagination: false, className: "dentobook-table dentobook-scrollable-table", onRow: function (patient) {
                                    return {
                                        onClick: function () {
                                            navigate('/patients/' + patient.id);
                                        }
                                    };
                                } }, { children: [_jsx(Table.Column, { width: "20%", render: function (patient) { return (_jsxs("div", __assign({ className: "username-icon" }, { children: [!patient.avatar ? _jsx(UserIcon, { size: "small" }) :
                                                    _jsx("img", { className: "thumbnail-img", width: 10, src: PATIENT_PHOTO_URL_PREFIX + patient.id + PHOTO_URL_SUFFIX, alt: "avatar" }), _jsx("span", __assign({ className: "font-weight-500" }, { children: patient.name }))] }))); }, title: _jsx(FormattedMessage, { id: "name" }), sorter: function (patient1, patient2, sortOrder) {
                                            setSortBy("name");
                                            setOrder(sortOrder === 'ascend' ? 'asc' : 'desc');
                                            return patient1.name.localeCompare(patient2.name);
                                        } }, "name"), _jsx(Table.Column, { width: "20%", title: "ID", sorter: function (patient1, patient2, sortOrder) {
                                            setSortBy("id");
                                            setOrder(sortOrder === 'ascend' ? 'asc' : 'desc');
                                            return patient1.id.localeCompare(patient2.id);
                                        }, render: function (patient) { return _jsx("span", __assign({ className: "font-weight-500" }, { children: patient.id.substring(0, 5) })); } }, "id"), _jsx(Table.Column, { width: "15%", render: function (patient) { return _jsx("span", __assign({ className: "font-weight-500" }, { children: patient.phone })); }, title: _jsx(FormattedMessage, { id: "phone" }) }, "phone"), _jsx(Table.Column, { title: "Email", width: "15%", render: function (patient) { return _jsx("span", __assign({ className: "font-weight-500" }, { children: patient.email })); } }, "email"), _jsx(Table.Column, { width: "15%", title: _jsx(FormattedMessage, { id: "lastAppointment" }), sorter: function (patient1, patient2, sortOrder) {
                                            setSortBy("lastHour");
                                            setOrder(sortOrder === 'ascend' ? 'asc' : 'desc');
                                            return patient1.lastHour.localeCompare(patient2.lastHour);
                                        }, render: function (patient) { return (_jsx(Link, __assign({ to: "/patients/" + patient.id }, { children: _jsx("span", __assign({ className: "font-weight-700" }, { children: patient.lastHour })) }))); } }, "lastAppointment"), _jsx(Table.Column, { width: "15%", title: _jsx(FormattedMessage, { id: "nextAppointment" }), sorter: function (patient1, patient2, sortOrder) {
                                            setSortBy("nextHour");
                                            setOrder(sortOrder === 'ascend' ? 'asc' : 'desc');
                                            return patient1.nextHour.localeCompare(patient2.nextHour);
                                        }, render: function (patient) { return (_jsx(Link, __assign({ to: "/patients/" + patient.id }, { children: _jsx("span", __assign({ className: "font-weight-700" }, { children: patient.nextHour })) }))); } }, "nextAppointment")] })) })) })), _jsx("div", { id: "bottom-div", style: { bottom: 0, position: 'absolute' } })] }))] })));
};
export default Patients;
