var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Upload } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { DefaultApiFactory, InvoicesApiFactory, OrganizationApiFactory, } from "dentobook-api-typescript-client/api";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import '../../assets/css/Dentobook.css';
import { CameraOutlined, CaretDownOutlined, CloseOutlined, EnvironmentOutlined, FileTextOutlined, InfoCircleOutlined, MedicineBoxOutlined } from "@ant-design/icons";
import UserIcon from "../common/UserIcon";
import FormButtons from "../common/FormButtons";
import { AlertContext } from "../../context/AlertContext";
import { useNavigate } from "react-router-dom";
import { messages } from "../../i18n/health-area";
import { healthRegionMessages } from "../../i18n/health-regions";
import { isMobileOnly } from "react-device-detect";
import { ExitPromptContext } from "../../context/ExitPromptContext";
import { checkFormDirty } from "../../i18n/utils";
var GeneralForm = function (_a) {
    var organization = _a.organization, onClose = _a.onClose;
    var intl = useIntl();
    var PHOTO_URL_PREFIX = '/api/organizations/';
    var PHOTO_URL_SUFFIX = '/avatar/';
    var setFormDirty = useContext(ExitPromptContext).setFormDirty;
    var _b = useContext(AlertContext), setVisible = _b.setVisible, setError = _b.setError;
    var duplicateEmailError = false;
    var _c = useState(''), imagePreview = _c[0], setImagePreview = _c[1];
    var _d = useState(), imagePreviewJsx = _d[0], setImagePreviewJsx = _d[1];
    var _e = useState(organization), formOrganizationDetails = _e[0], setFormOrganizationDetails = _e[1];
    var _f = useState(undefined), avatar = _f[0], setAvatar = _f[1];
    var _g = useState(false), avatarMouseIn = _g[0], setAvatarMouseIn = _g[1];
    var _h = useState([]), filteredRegions = _h[0], setFilteredRegions = _h[1];
    var _j = useState(false), isModalVisible = _j[0], setIsModalVisible = _j[1];
    var _k = useState(true), isInvoiceNumberEditable = _k[0], setIsInvoiceNumberEditable = _k[1];
    var _l = useState(true), isProformaInvoiceNumberEditable = _l[0], setIsProformaInvoiceNumberEditable = _l[1];
    var _m = useState('invoice'), modalType = _m[0], setModalType = _m[1];
    var handleMouseEnter = function () { return setAvatarMouseIn(true); };
    var handleMouseLeave = function () { return setAvatarMouseIn(false); };
    var handleKeyDown = function (event) {
        if (event.key === 'Enter' || event.key === ' ') {
            setAvatarMouseIn(function (prev) { return !prev; });
        }
    };
    var _o = useState(false), isModalOpen = _o[0], setIsModalOpen = _o[1];
    var translations = messages[intl.locale].towns;
    var apiConfig = new Configuration({ basePath: '/api' });
    var organizationApi = OrganizationApiFactory(apiConfig, '/api');
    var invoiceConfigApi = DefaultApiFactory(apiConfig, '/api');
    var invoicesApi = InvoicesApiFactory(apiConfig, '/api');
    var navigate = useNavigate();
    var form = Form.useForm()[0];
    var handleHealthAreaChange = function (value) {
        // Update filtered regions immediately and reset healthRegion
        updateFilteredRegions(value);
        setFormOrganizationDetails(function (prevState) { return (__assign(__assign({}, prevState), { healthArea: value, healthRegion: '' // Reset healthRegion when healthArea changes
         })); });
        form.setFieldsValue({ healthRegion: '' });
    };
    var handleHealthRegionChange = function (value) {
        setFormOrganizationDetails(__assign(__assign({}, formOrganizationDetails), { healthRegion: value }));
    };
    var updateFilteredRegions = function (selectedArea) {
        var regions = Object.entries(healthRegionMessages[intl.locale].healthRegions)
            .filter(function (_a) {
            var key = _a[0];
            return key.startsWith(selectedArea);
        })
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            return ({ key: key, value: value });
        });
        setFilteredRegions(regions);
    };
    var showModal = function (modalType) {
        setModalType(modalType);
        setIsModalVisible(true);
    };
    var handleModalOk = function () {
        setIsModalVisible(false);
    };
    var handleModalCancel = function () {
        setIsModalVisible(false);
    };
    var postInvoiceConfig = function (invoiceNumber, organizationId, documentType) { return __awaiter(void 0, void 0, void 0, function () {
        var invoiceConfig;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(invoiceNumber && organizationId)) return [3 /*break*/, 2];
                    invoiceConfig = {
                        prefix: '',
                        lastNumber: parseInt(invoiceNumber, 10),
                        digitsCount: invoiceNumber.length,
                        documentType: documentType,
                        clinicId: organizationId,
                        formattedNumber: invoiceNumber,
                        invoiceNumber: invoiceNumber
                    };
                    // @ts-ignore
                    return [4 /*yield*/, invoiceConfigApi.invoicesInvoiceConfigPost(invoiceConfig)];
                case 1:
                    // @ts-ignore
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var _p = useState(undefined), formInitialState = _p[0], setFormInitialState = _p[1];
    useEffect(function () {
        if (!formInitialState) {
            setFormInitialState(form.getFieldsValue(true));
        }
    }, [form]);
    useEffect(function () {
        var checkInvoiceConfig = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, invoiceResponse, proformaResponse, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        if (!(organization.id != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                invoicesApi.getInvoiceConfiguration(organization.id, "INVOICE"),
                                invoicesApi.getInvoiceConfiguration(organization.id, "PROFORMA_INVOICE")
                            ])];
                    case 1:
                        _a = _b.sent(), invoiceResponse = _a[0], proformaResponse = _a[1];
                        setIsInvoiceNumberEditable(!(invoiceResponse === null || invoiceResponse === void 0 ? void 0 : invoiceResponse.data));
                        setIsProformaInvoiceNumberEditable(!(proformaResponse === null || proformaResponse === void 0 ? void 0 : proformaResponse.data));
                        _b.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        console.error('Error fetching invoice configurations:', error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        checkInvoiceConfig();
    }, [organization.id]);
    useEffect(function () {
        if (formOrganizationDetails.healthArea) {
            updateFilteredRegions(formOrganizationDetails.healthArea);
        }
    }, [intl.locale, formOrganizationDetails.healthArea]);
    useEffect(function () {
        if (imagePreview) {
            setImagePreviewJsx(_jsx("input", { type: "button", onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onKeyDown: handleKeyDown, className: "avatar-upload-photo-img", style: {
                    backgroundImage: "url(".concat(imagePreview, ")"),
                    width: '150px',
                    height: '150px',
                    backgroundSize: 'cover',
                    border: 'none',
                    cursor: 'pointer'
                }, alt: "avatar" }));
        }
        else if (!organization.avatar) {
            setImagePreviewJsx(_jsx("button", __assign({ onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onKeyDown: handleKeyDown, className: "avatar-upload-photo-img", style: { border: 'none', cursor: 'pointer', background: 'none', marginLeft: -6 } }, { children: _jsx(UserIcon, { size: "large", className: "mb-4" }) })));
        }
        else {
            setImagePreviewJsx(_jsx("input", { type: "button", onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onKeyDown: handleKeyDown, className: "avatar-upload-photo-img", style: {
                    backgroundImage: "url(".concat(PHOTO_URL_PREFIX + organization.id + PHOTO_URL_SUFFIX, ")"),
                    width: '150px',
                    height: '150px',
                    backgroundSize: 'cover',
                    border: 'none',
                    cursor: 'pointer'
                }, alt: "avatar" }));
        }
    }, [imagePreview, organization.avatar]);
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var myHeaders, checkAndPostInvoiceConfig_1, updateOrganizationAvatar_1, handleOrganizationResult, result, organizationId, error_2, result, organizationId, error_3, err_1, blankFieldExists;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 15, , 16]);
                    return [4 /*yield*/, form.validateFields()];
                case 2:
                    _a.sent();
                    myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    checkAndPostInvoiceConfig_1 = function (organizationId, invoiceNumber, documentType) { return __awaiter(void 0, void 0, void 0, function () {
                        var invoiceConfig, error_4;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 4, , 5]);
                                    return [4 /*yield*/, invoicesApi.getInvoiceConfiguration(organizationId, documentType)];
                                case 1:
                                    invoiceConfig = _a.sent();
                                    if (!!(invoiceConfig === null || invoiceConfig === void 0 ? void 0 : invoiceConfig.data)) return [3 /*break*/, 3];
                                    return [4 /*yield*/, postInvoiceConfig(invoiceNumber, organizationId, documentType)];
                                case 2:
                                    _a.sent();
                                    _a.label = 3;
                                case 3: return [3 /*break*/, 5];
                                case 4:
                                    error_4 = _a.sent();
                                    console.error("Error fetching ".concat(documentType, " configuration:"), error_4);
                                    return [3 /*break*/, 5];
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); };
                    updateOrganizationAvatar_1 = function (organizationId, avatarFile) { return __awaiter(void 0, void 0, void 0, function () {
                        var error_5;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!avatarFile) return [3 /*break*/, 5];
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, organizationApi.updateOrganizationAvatar(organizationId, avatarFile)];
                                case 2:
                                    _a.sent();
                                    setVisible(true);
                                    setTimeout(function () { return setVisible(false); }, 10000);
                                    navigate('/clinic', { replace: true });
                                    return [3 /*break*/, 4];
                                case 3:
                                    error_5 = _a.sent();
                                    console.log('Error updating avatar', error_5);
                                    return [3 /*break*/, 4];
                                case 4: return [3 /*break*/, 6];
                                case 5:
                                    setVisible(true);
                                    setTimeout(function () { return setVisible(false); }, 10000);
                                    navigate('/clinic', { replace: true });
                                    _a.label = 6;
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); };
                    handleOrganizationResult = function (organizationId) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!formOrganizationDetails.invoiceNumber) return [3 /*break*/, 2];
                                    return [4 /*yield*/, checkAndPostInvoiceConfig_1(organizationId, formOrganizationDetails.invoiceNumber, "INVOICE")];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2:
                                    if (!formOrganizationDetails.proformaInvoiceNumber) return [3 /*break*/, 4];
                                    return [4 /*yield*/, checkAndPostInvoiceConfig_1(organizationId, formOrganizationDetails.proformaInvoiceNumber, "PROFORMA_INVOICE")];
                                case 3:
                                    _a.sent();
                                    _a.label = 4;
                                case 4: return [4 /*yield*/, updateOrganizationAvatar_1(organizationId, avatar || null)];
                                case 5:
                                    _a.sent();
                                    onClose();
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    if (!!organization.id) return [3 /*break*/, 9];
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 7, , 8]);
                    return [4 /*yield*/, organizationApi.createOrganization(formOrganizationDetails)];
                case 4:
                    result = _a.sent();
                    organizationId = result.data.id;
                    if (!(organizationId != null)) return [3 /*break*/, 6];
                    return [4 /*yield*/, handleOrganizationResult(organizationId)];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    error_2 = _a.sent();
                    console.log('Error creating organization', error_2);
                    return [3 /*break*/, 8];
                case 8: return [3 /*break*/, 14];
                case 9:
                    _a.trys.push([9, 13, , 14]);
                    return [4 /*yield*/, organizationApi.updateOrganization(organization.id, formOrganizationDetails)];
                case 10:
                    result = _a.sent();
                    organizationId = result.data.id;
                    if (!(organizationId != null)) return [3 /*break*/, 12];
                    return [4 /*yield*/, handleOrganizationResult(organizationId)];
                case 11:
                    _a.sent();
                    _a.label = 12;
                case 12: return [3 /*break*/, 14];
                case 13:
                    error_3 = _a.sent();
                    console.log('Error updating organization', error_3);
                    return [3 /*break*/, 14];
                case 14: return [3 /*break*/, 16];
                case 15:
                    err_1 = _a.sent();
                    blankFieldExists = Object.values(err_1.values).filter(function (item) { return !item; }).length > 0;
                    if (blankFieldExists) {
                        setError(intl.formatMessage({ id: 'all_fields_required' }));
                        setTimeout(function () { return setError(''); }, 10000);
                    }
                    return [3 /*break*/, 16];
                case 16: return [2 /*return*/];
            }
        });
    }); };
    var props = {
        name: 'file',
        customRequest: function () {
        },
        showUploadList: false,
        onChange: function (info) {
            setAvatar(info.file.originFileObj);
            setImagePreview(URL.createObjectURL(info.file.originFileObj));
        },
    };
    var handleOk = function () {
        setFormDirty(false);
        setIsModalOpen(false);
        onClose();
    };
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    var footer = _jsxs("div", __assign({ className: "modal-btn-wrapper" }, { children: [_jsx(Button, __assign({ className: "modal-btn", onClick: handleCancel }, { children: _jsx(FormattedMessage, { id: "cancel" }) })), _jsx(Button, __assign({ className: "modal-btn", type: "primary", onClick: handleOk }, { children: _jsx(FormattedMessage, { id: "confirm" }) }))] }));
    return (_jsxs(_Fragment, { children: [_jsx(Modal, __assign({ transitionName: "", footer: footer, className: "dentobook-modal", title: _jsx(FormattedMessage, { id: !organization.id ? "org_create" : "org_edit" }), closeIcon: _jsx(CloseOutlined, { className: 'icon-24px', onClick: handleCancel }), open: isModalOpen, onOk: handleOk, onCancel: handleCancel }, { children: _jsx("h3", __assign({ className: "dentobook-content font-weight-500 font-24 text-align-center " + (isMobileOnly ? "mt-112" : "") }, { children: _jsx(FormattedMessage, { id: "unsaved_changes" }) })) })), _jsxs(Form, __assign({ form: form, name: "horizontal_login", layout: "vertical", onFieldsChange: function (changedFields, allFields) { return setFormDirty(checkFormDirty(allFields, formInitialState)); } }, { children: [_jsxs("h4", { children: [_jsx("span", __assign({ className: "text-blue mr-4" }, { children: _jsx(MedicineBoxOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "main_information" })] }), _jsx(Form.Item, __assign({ label: _jsx(FormattedMessage, { id: "logo" }), className: "dentobook-form-item dentobook-simple-input" }, { children: _jsx(Upload, __assign({}, props, { children: _jsxs("div", __assign({ className: avatarMouseIn ? "avatar-upload-photo-mask" : "" }, { children: [_jsx(CameraOutlined, { className: avatarMouseIn ? "avatar-upload-icon" : "d-none" }), imagePreviewJsx] })) })) })), _jsx(Row, __assign({ gutter: 16, className: "mt-2" }, { children: _jsx(Col, __assign({ flex: 0.31 }, { children: _jsx(Form.Item, __assign({ label: _jsx(FormattedMessage, { id: "name" }), className: "dentobook-form-item dentobook-simple-input input-full-width ", name: "FirstName", rules: [
                                    { max: 250, message: _jsx(FormattedMessage, { id: "max_length_250" }) },
                                    {
                                        pattern: /^[a-zA-Zа-яА-Я\-\s\d]+$/,
                                        message: _jsx(FormattedMessage, { id: "invalid_field" })
                                    },
                                    { required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }
                                ], initialValue: formOrganizationDetails.name }, { children: _jsx(Input, { bordered: false, className: "input-ninety-percents dentobook-inputs", value: formOrganizationDetails.name, onChange: function (e) { return formOrganizationDetails.name = e.target.value; } }) })) })) })), _jsx(Divider, {}), _jsxs("h4", { children: [_jsx("span", __assign({ className: "text-blue mr-4" }, { children: _jsx(EnvironmentOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "contact" })] }), _jsxs(Row, __assign({ gutter: [16, 16] }, { children: [_jsxs(Col, __assign({ md: 5 }, { children: [_jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "email" }), name: "email", rules: [{
                                                type: "email",
                                                message: _jsx(FormattedMessage, { id: "invalid_email" })
                                            }], initialValue: formOrganizationDetails.email }, { children: _jsx(Input, { className: "input-ninety-percent", value: formOrganizationDetails.email, onChange: function (e) { return formOrganizationDetails.email = e.target.value; } }) })), duplicateEmailError && (_jsx("span", __assign({ className: "ant-form-item-required text-red" }, { children: _jsx(FormattedMessage, { id: "duplicate_email" }) })))] })), _jsx(Col, __assign({ md: 5 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "phone_label_two" }), name: "phone", rules: [
                                        {
                                            pattern: new RegExp(/^[+()\d\s]+$/gm),
                                            message: _jsx(FormattedMessage, { id: "invalid_phone_number" })
                                        }
                                    ], initialValue: formOrganizationDetails.phone }, { children: _jsx(Input, { type: "tel", className: "dentobook-input input-ninety-percent", value: formOrganizationDetails.phone, onChange: function (e) { return formOrganizationDetails.phone = e.target.value; } }) })) })), _jsx(Col, __assign({ md: 5 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "website" }), name: "website", initialValue: formOrganizationDetails.website }, { children: _jsx(Input, { className: "dentobook-input input-ninety-percent", value: formOrganizationDetails.website, onChange: function (e) { return formOrganizationDetails.website = e.target.value; } }) })) }))] })), _jsxs(Row, __assign({ gutter: [16, 16] }, { children: [_jsx(Col, __assign({ lg: 5 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "town" }), name: "town", rules: [{
                                            pattern: /^([a-zA-Z\u0410-\u044F\s]+)$/,
                                            message: _jsx(FormattedMessage, { id: "letters_only_field" })
                                        },
                                        { type: "string", message: _jsx(FormattedMessage, { id: "invalid_town" }) }], initialValue: formOrganizationDetails.town }, { children: _jsx(Input, { className: "dentobook-input input-ninety-percent", value: formOrganizationDetails.town, onChange: function (e) { return formOrganizationDetails.town = e.target.value; } }) })) })), _jsx(Col, __assign({ lg: 5 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "country" }), name: "country", rules: [{
                                            pattern: /^([a-zA-Z\u0410-\u044F\s]+)$/,
                                            message: _jsx(FormattedMessage, { id: "letters_only_field" })
                                        },
                                        {
                                            type: "string",
                                            message: _jsx(FormattedMessage, { id: "invalid_country" })
                                        }], initialValue: formOrganizationDetails.country }, { children: _jsx(Input, { className: "dentobook-input input-ninety-percent", value: formOrganizationDetails.country, onChange: function (e) { return formOrganizationDetails.country = e.target.value; } }) })) }))] })), _jsxs(Row, { children: [_jsx(Col, __assign({ flex: 4.85 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "addressOne" }), name: "addressOne", initialValue: formOrganizationDetails.address, rules: [{ required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }] }, { children: _jsx(Input, { value: formOrganizationDetails.address, className: "dentobook-input input-ninety-percents", onChange: function (e) { return formOrganizationDetails.address = e.target.value; } }) })) })), _jsx(Col, { flex: 0.15 })] }), _jsx(Divider, {}), _jsxs("h4", { children: [_jsx("span", __assign({ className: "text-blue mr-4" }, { children: _jsx(FileTextOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "company_data" })] }), _jsxs(Row, __assign({ gutter: 24 }, { children: [_jsx(Col, __assign({ md: 12 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-full-width ", label: _jsx(FormattedMessage, { id: "name" }), name: "companyName", initialValue: formOrganizationDetails.companyName }, { children: _jsx(Input, { className: "dentobook-input", value: formOrganizationDetails.companyName, onChange: function (e) { return formOrganizationDetails.companyName = e.target.value; } }) })) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "uic" }), name: "uic", rules: [
                                        {
                                            pattern: new RegExp(/^[\d\s]+$/gm),
                                            message: _jsx(FormattedMessage, { id: "invalid_uic_number" })
                                        }
                                    ], initialValue: formOrganizationDetails.uic }, { children: _jsx(Input, { type: "tel", className: "dentobook-input", value: formOrganizationDetails.uic, onChange: function (e) { return formOrganizationDetails.uic = e.target.value; } }) })) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "rzi_practice_code" }), name: "rzi_practice_code", rules: [
                                        {
                                            pattern: /^[a-zA-Z0-9\s]+$/,
                                            message: _jsx(FormattedMessage, { id: "invalid_rzi_practice_code" })
                                        }
                                    ], initialValue: formOrganizationDetails.rziPracticeCode }, { children: _jsx(Input, { className: "dentobook-input", value: formOrganizationDetails.rziPracticeCode, onChange: function (e) { return formOrganizationDetails.rziPracticeCode = e.target.value; } }) })) }))] })), _jsxs(Row, __assign({ gutter: 24 }, { children: [_jsx(Col, __assign({ md: 12 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-full-width", label: _jsx(FormattedMessage, { id: "healthArea" }), name: "healthArea", initialValue: formOrganizationDetails.healthArea, rules: [
                                        {
                                            pattern: /^[0-9a-zA-Z]*$/,
                                            message: _jsx(FormattedMessage, { id: "invalid_field" })
                                        }, { required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }
                                    ] }, { children: _jsx(Select, __assign({ suffixIcon: _jsx(CaretDownOutlined, { style: { position: 'relative', top: '6px' } }), className: "dentobook-input", value: formOrganizationDetails.healthArea, onChange: handleHealthAreaChange }, { children: Object.entries(translations)
                                            .sort(function (a, b) { return a[1].localeCompare(b[1]); })
                                            .map(function (_a) {
                                            var key = _a[0], value = _a[1];
                                            return (_jsxs(Select.Option, __assign({ value: key }, { children: [" ", _jsx(FormattedMessage, { id: "rhif" }), " ", value] }), key));
                                        }) })) })) })), _jsx(Col, __assign({ md: 12 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input input-full-width mb-32", label: _jsx(FormattedMessage, { id: "healthRegion" }), name: "healthRegion", initialValue: formOrganizationDetails.healthRegion, rules: [
                                        {
                                            message: _jsx(FormattedMessage, { id: "letters_only_field" })
                                        }, { required: true, message: _jsx(FormattedMessage, { id: "required_field" }) }
                                    ] }, { children: _jsx(Select, __assign({ suffixIcon: _jsx(CaretDownOutlined, { style: { position: 'relative', top: '6px' } }), className: "dentobook-input", value: formOrganizationDetails.healthRegion, onChange: handleHealthRegionChange, disabled: !formOrganizationDetails.healthArea }, { children: filteredRegions.map(function (_a) {
                                            var key = _a.key, value = _a.value;
                                            return (_jsx(Select.Option, __assign({ value: key }, { children: value }), key));
                                        }) }), formOrganizationDetails.healthArea) })) }))] })), _jsxs(Row, __assign({ gutter: 24 }, { children: [_jsx(Col, __assign({ flex: 2.4 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "iban" }), name: "iban", initialValue: formOrganizationDetails.iban, rules: [
                                        {
                                            pattern: /^[0-9a-zA-Z]*$/,
                                            message: _jsx(FormattedMessage, { id: "invalid_field" })
                                        },
                                    ] }, { children: _jsx(Input, { className: "dentobook-input", value: formOrganizationDetails.iban, onChange: function (e) { return formOrganizationDetails.iban = e.target.value; } }) })) })), _jsx(Col, __assign({ flex: 2.4 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input mb-64", label: _jsx(FormattedMessage, { id: "accountable_person" }), name: "accountable_person", initialValue: formOrganizationDetails.accountablePerson, rules: [
                                        {
                                            pattern: /^([a-zA-Z\u0410-\u044F\s]+)$/,
                                            message: _jsx(FormattedMessage, { id: "letters_only_field" })
                                        },
                                    ] }, { children: _jsx(Input, { className: "dentobook-input", value: formOrganizationDetails.accountablePerson, onChange: function (e) { return formOrganizationDetails.accountablePerson = e.target.value; } }) })) }))] })), _jsxs(Row, __assign({ gutter: 24 }, { children: [_jsx(Col, __assign({ flex: 1.7 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input", label: _jsxs("span", { children: [_jsx(FormattedMessage, { id: "invoiceNumber" }), _jsx(InfoCircleOutlined, { style: { marginLeft: 8, color: 'blue' }, onClick: function () { return showModal('invoice'); } })] }), name: "invoiceNumber", initialValue: formOrganizationDetails.invoiceNumber, rules: [
                                        {
                                            pattern: /^[0-9a-zA-Z]*$/,
                                            message: _jsx(FormattedMessage, { id: "invalid_field" })
                                        },
                                        {
                                            len: 10,
                                            message: _jsx(FormattedMessage, { id: "invoiceNumber_must_be_10_chars", defaultMessage: "Invoice number must be exactly 10 characters." })
                                        }
                                    ] }, { children: _jsx(Input, { className: "dentobook-input", value: formOrganizationDetails.invoiceNumber, onChange: function (e) { return formOrganizationDetails.invoiceNumber = e.target.value; }, maxLength: 10, disabled: !isInvoiceNumberEditable }) })) })), _jsx(Col, __assign({ flex: 1.5 }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input", label: _jsxs("span", { children: [_jsx(FormattedMessage, { id: "proformaInvoiceNumber" }), _jsx(InfoCircleOutlined, { style: { marginLeft: 8, color: 'blue' }, onClick: function () { return showModal('proforma'); } })] }), name: "proformaInvoiceNumber", initialValue: formOrganizationDetails.proformaInvoiceNumber, rules: [
                                        {
                                            pattern: /^[0-9a-zA-Z]*$/,
                                            message: _jsx(FormattedMessage, { id: "invalid_field" })
                                        },
                                        {
                                            len: 10,
                                            message: _jsx(FormattedMessage, { id: "invoiceNumber_must_be_10_chars", defaultMessage: "Invoice number must be exactly 10 characters." })
                                        }
                                    ] }, { children: _jsx(Input, { className: "dentobook-input", value: formOrganizationDetails.proformaInvoiceNumber, onChange: function (e) { return formOrganizationDetails.proformaInvoiceNumber = e.target.value; }, maxLength: 10, disabled: !isProformaInvoiceNumberEditable }) })) }))] })), _jsx(Modal, __assign({ transitionName: "", className: "dentobook-modal", title: _jsx(FormattedMessage, { id: modalType === 'invoice' ? "invoice_numbering_title" : "proforma_numbering_title" }), open: isModalVisible, onOk: handleModalOk, onCancel: handleModalCancel, footer: [
                            _jsx("div", __assign({ style: { display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx(Button, __assign({ className: "dentobook-btn-secondary modal-btn", onClick: handleModalCancel }, { children: _jsx(FormattedMessage, { id: "close" }) }), "close") }))
                        ] }, { children: _jsx("p", { children: _jsx(FormattedMessage, { id: modalType === 'invoice' ? "invoice_numbering_text" : "proforma_numbering_text" }) }) })), _jsx("br", {}), _jsx("br", {}), _jsxs(FormButtons, { children: [_jsx(Button, __assign({ className: "dentobook-btn-secondary modal-btn", htmlType: "button", onClick: function () { return setIsModalOpen(true); } }, { children: _jsx(FormattedMessage, { id: "cancel" }) })), _jsx(Button, __assign({ className: "dentobook-btn-primary modal-btn", onClick: handleSubmit, htmlType: "submit" }, { children: _jsx(FormattedMessage, { id: "save" }) }))] })] }))] }));
};
export default GeneralForm;
