var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Divider, Row, Space } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import '../../assets/css/Dentobook.css';
import { isMobileOnly } from 'react-device-detect';
import { EnvironmentOutlined, FileTextOutlined, MedicineBoxOutlined } from "@ant-design/icons";
import UserIcon from "../common/UserIcon";
import FormButtons from "../common/FormButtons";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { DentobookApplicationContext } from "../../context/DentobookApplicationContext";
import { OrganizationApiFactory, } from "dentobook-api-typescript-client/api";
import GeneralForm from "./GeneralForm";
import { messages as healthAreaMessages } from "../../i18n/health-area";
import { healthRegionMessages } from "../../i18n/health-regions";
import { useLocation } from "react-router-dom";
var General = function (_a) {
    var _b;
    var isEditMode = _a.isEditMode, setIsEditMode = _a.setIsEditMode;
    var intl = useIntl();
    var location = useLocation();
    var apiConfig = new Configuration({ basePath: '/api' });
    var organizationApi = OrganizationApiFactory(apiConfig, '/api');
    var principal = useContext(DentobookApplicationContext).principal;
    var _c = useState(undefined), organization = _c[0], setOrganization = _c[1];
    var fetchOrganization = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!((_a = principal === null || principal === void 0 ? void 0 : principal.user) === null || _a === void 0 ? void 0 : _a.organizationId))
                        return [2 /*return*/];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, organizationApi.fetchOrganization(principal.user.organizationId)];
                case 2:
                    response = _b.sent();
                    setOrganization(response.data);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    console.error("Failed to load organization:", error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [(_b = principal === null || principal === void 0 ? void 0 : principal.user) === null || _b === void 0 ? void 0 : _b.organizationId, organizationApi]);
    useEffect(function () {
        fetchOrganization();
    }, [location]);
    if (!organization) {
        return _jsx("h1", { children: _jsx(FormattedMessage, { id: "loading" }) });
    }
    if (isEditMode) {
        return _jsx(GeneralForm, { organization: organization, onClose: function () { return setIsEditMode(false); } });
    }
    return (_jsxs(_Fragment, { children: [_jsx(MainInformation, { organization: organization }), _jsx(Divider, {}), _jsx(ContactInformation, { organization: organization }), _jsx(Divider, {}), _jsx(CompanyData, { organization: organization, getHealthAreaDisplayValue: function (key) { return getHealthAreaDisplayValue(key, intl); }, getHealthRegionDisplayValue: function (key) { return getHealthRegionDisplayValue(key, intl); } }), _jsx(FormButtons, { children: _jsx(Button, __assign({ onClick: function () { return setIsEditMode(true); }, className: "edit-btn " + (isMobileOnly ? "mobile-update-btn" : "dentobook-btn-primary"), type: "primary", size: "large", shape: "round" }, { children: _jsx(FormattedMessage, { id: "edit" }) })) })] }));
};
var MainInformation = function (_a) {
    var organization = _a.organization;
    var PHOTO_URL_PREFIX = '/api/organizations/';
    var PHOTO_URL_SUFFIX = '/avatar/';
    return (_jsxs(_Fragment, { children: [_jsxs("h4", { children: [_jsx("span", __assign({ className: "text-blue mr-1" }, { children: _jsx(MedicineBoxOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "main_information" })] }), _jsxs(Space, __assign({ direction: isMobileOnly ? "vertical" : "horizontal" }, { children: [!organization.avatar ? (_jsx(UserIcon, { size: isMobileOnly ? "medium" : "large" })) : (_jsx("img", { className: "photo-img", width: 150, src: "".concat(PHOTO_URL_PREFIX).concat(organization.id).concat(PHOTO_URL_SUFFIX), alt: "avatar" })), _jsxs(Space, __assign({ direction: "vertical", className: isMobileOnly ? "mb-2" : "ml-6 mb-72" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: "name" }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: organization.name }))] }))] }))] }));
};
var ContactInformation = function (_a) {
    var organization = _a.organization;
    return (_jsxs(_Fragment, { children: [_jsxs("h4", { children: [_jsx("span", __assign({ className: "text-blue mr-1" }, { children: _jsx(EnvironmentOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "contact" })] }), _jsxs(Space, __assign({ direction: isMobileOnly ? "vertical" : "horizontal" }, { children: [_jsx(ContactDetail, { labelId: "email", value: organization.email }), _jsx(ContactDetail, { labelId: "phone", value: organization.phone }), _jsx(ContactDetail, { labelId: "website", value: organization.website })] })), _jsx(Row, __assign({ className: "mt-2" }, { children: _jsxs(Space, __assign({ direction: isMobileOnly ? "vertical" : "horizontal" }, { children: [_jsx(ContactDetail, { labelId: "town", value: organization.town }), _jsx(ContactDetail, { labelId: "country", value: organization.country }), _jsx(ContactDetail, { labelId: "address", value: organization.address })] })) }))] }));
};
var ContactDetail = function (_a) {
    var labelId = _a.labelId, value = _a.value;
    return (_jsxs(Space, __assign({ direction: "vertical", className: isMobileOnly ? "" : "ml-6" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: labelId }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: value || _jsx(FormattedMessage, { id: "not_available" }) }))] })));
};
var CompanyData = function (_a) {
    var organization = _a.organization, getHealthAreaDisplayValue = _a.getHealthAreaDisplayValue, getHealthRegionDisplayValue = _a.getHealthRegionDisplayValue;
    return (_jsxs(_Fragment, { children: [_jsxs("h4", { children: [_jsx("span", __assign({ className: "text-blue mr-1" }, { children: _jsx(FileTextOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "company_data" })] }), _jsxs(Space, __assign({ className: "mb-6", direction: isMobileOnly ? "vertical" : "horizontal" }, { children: [_jsx(CompanyDetail, { labelId: "name", value: organization.companyName }), _jsx(CompanyDetail, { labelId: "uic", value: organization.uic }), _jsx(CompanyDetail, { labelId: "rzi_practice_code", value: organization.rziPracticeCode }), _jsx(CompanyDetail, { labelId: "healthArea", value: organization.healthArea ? getHealthAreaDisplayValue(organization.healthArea) : undefined })] })), _jsx(Row, __assign({ className: "mt-2 mb-8" }, { children: _jsxs(Space, __assign({ className: "mb-6", direction: isMobileOnly ? "vertical" : "horizontal" }, { children: [_jsx(CompanyDetail, { labelId: "healthRegion", value: organization.healthRegion ? getHealthRegionDisplayValue(organization.healthRegion) : undefined }), _jsx(CompanyDetail, { labelId: "iban", value: organization.iban }), _jsx(CompanyDetail, { labelId: "accountable_person", value: organization.accountablePerson })] })) }))] }));
};
var CompanyDetail = function (_a) {
    var labelId = _a.labelId, value = _a.value;
    return (_jsxs(Space, __assign({ direction: "vertical", className: isMobileOnly ? "" : "ml-6" }, { children: [_jsxs("span", __assign({ className: "body-large-regular" }, { children: [_jsx(FormattedMessage, { id: labelId }), ":"] })), _jsx("span", __assign({ className: "body-large-medium" }, { children: value || _jsx(FormattedMessage, { id: "not_available" }) }))] })));
};
var getDisplayValueForKey = function (key, messageObject, type, intl) {
    var messagesForLocale = messageObject[intl.locale] || {};
    var typeMessages = messagesForLocale[type] || {};
    return typeMessages[key] || key;
};
var getHealthAreaDisplayValue = function (key, intl) {
    return getDisplayValueForKey(key, healthAreaMessages, 'towns', intl);
};
var getHealthRegionDisplayValue = function (key, intl) {
    return getDisplayValueForKey(key, healthRegionMessages, 'healthRegions', intl);
};
export default General;
