var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Modal } from 'antd';
import "../../assets/css/Dentobook.css";
import "../../assets/css/PatientPhotos.css";
import { FormattedMessage } from "react-intl";
import { CloseOutlined } from '@ant-design/icons';
import { InvoicesApiFactory } from "dentobook-api-typescript-client/api";
import { Configuration } from "dentobook-api-typescript-client/configuration";
var PatientInvoiceVoidModal = function (_a) {
    var proforma = _a.proforma, invoice = _a.invoice, loadData = _a.loadData, isModalOpen = _a.isModalOpen, setIsModalOpen = _a.setIsModalOpen;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var invoicesApi = InvoicesApiFactory(apiConfig, '/api');
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    var handleOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!invoice) {
                console.log('invoice is undefined');
                return [2 /*return*/];
            }
            setLoading(true);
            invoicesApi.updateInvoice(invoice.id, __assign(__assign({}, invoice), { status: !proforma ? 'CANCELLED' : 'CANCELLED_PROFORMA' }))
                .then(function () {
                setIsModalOpen(false);
                loadData();
            })
                .catch(function (err) { return console.error(err); })
                .finally(function () { return setLoading(false); });
            return [2 /*return*/];
        });
    }); };
    return (_jsx(Modal, __assign({ transitionName: "", className: 'dentobook-modal dentobook-small-modal', okButtonProps: {
            className: "modal-btn danger-btn"
        }, cancelButtonProps: {
            className: "modal-btn"
        }, confirmLoading: loading, width: 600, okText: _jsx(FormattedMessage, { id: "void_button" }), cancelText: _jsx(FormattedMessage, { id: "cancel" }), closeIcon: _jsx(CloseOutlined, { className: 'icon-24px', onClick: handleCancel }), open: isModalOpen, onOk: handleOk, onCancel: handleCancel }, { children: _jsx("div", __assign({ style: { width: '100%', display: 'flex', justifyContent: 'center' } }, { children: _jsx("h3", __assign({ className: "font-24 font-weight-500 text-center text-align-center", style: { width: !proforma ? 400 : 430 } }, { children: _jsx(FormattedMessage, { id: !proforma ? "void_message" : "proforma_void_message" }) })) })) })));
};
export default PatientInvoiceVoidModal;
