var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import '../assets/css/Dentobook.css';
import DetailWrapper from "../components/common/DetailWrapper";
import { Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { Navigate } from "react-router-dom";
import OrganizationForm from "../components/organizations/OrganizationForm";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
var OrganizationCreate = function () {
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1];
    var _b = useState(false), goBack = _b[0], setGoBack = _b[1];
    var clinic = {};
    var handleOk = function () {
        setIsModalOpen(false);
        setGoBack(true);
    };
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    if (goBack) {
        return _jsx(Navigate, { to: "/organizations" });
    }
    return (_jsxs(AccessControl, __assign({ accessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR] }, { children: [_jsxs(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, okText: _jsx(FormattedMessage, { id: "confirm" }), cancelText: _jsx(FormattedMessage, { id: "cancel" }), open: isModalOpen, onOk: handleOk, onCancel: handleCancel }, { children: [_jsx("br", {}), _jsx("h3", __assign({ className: "dentobook-content font-weight-500 font-24 text-align-center" }, { children: _jsx(FormattedMessage, { id: "unsaved_changes" }) }))] })), _jsxs(DetailWrapper, __assign({ navigateBack: "/organizations" }, { children: [_jsx("h2", { children: _jsx(FormattedMessage, { id: "org_create" }) }), _jsx(OrganizationForm, { openModal: function () { return setIsModalOpen(true); }, clinic: clinic, setEditMode: undefined })] }))] })));
};
export default OrganizationCreate;
