var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import '../assets/css/Dentobook.css';
import DetailWrapper from "../components/common/DetailWrapper";
import { Button, Col, Form, Input, Row, Table } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { OrganizationApiFactory } from "dentobook-api-typescript-client/api";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
var Organizations = function () {
    var _a = useState([]), organizations = _a[0], setOrganizations = _a[1];
    var _b = useState(''), keyword = _b[0], setKeyword = _b[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var organizationApi = OrganizationApiFactory(apiConfig, '/api');
    var navigate = useNavigate();
    useEffect(function () {
        loadData();
    }, []);
    var loadData = function () {
        organizationApi.fetchAllOrganizations(keyword)
            .then(function (res) {
            setOrganizations(res.data);
        })
            .catch(function (err) { return console.log(err); });
    };
    return (_jsx(AccessControl, __assign({ accessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR] }, { children: _jsxs(DetailWrapper, __assign({ navigateBack: undefined }, { children: [_jsxs(Row, __assign({ className: "mb-2" }, { children: [_jsx(Col, __assign({ span: 12 }, { children: _jsx("h3", { children: _jsx(FormattedMessage, { id: "clinics" }) }) })), _jsx(Col, __assign({ span: 12 }, { children: _jsx(Link, __assign({ style: { float: 'right', marginTop: '25px' }, to: "/organizations/create" }, { children: _jsx("div", __assign({ className: isMobileOnly ? "mobile-btn-wrapper" : "" }, { children: _jsxs(Button, __assign({ shape: "round", className: isMobileOnly ? "mobile-add-btn" : "dentobook-btn-primary dentobook-btn-medium", type: "primary" }, { children: [_jsx(PlusOutlined, {}), " ", _jsx(FormattedMessage, { id: "add" })] })) })) })) }))] })), _jsxs(Form, __assign({ layout: "horizontal" }, { children: [_jsx(Form.Item, __assign({ name: "keyword", className: "dentobook-form-item" }, { children: _jsx(Input, { value: keyword, placeholder: useIntl().formatMessage({ id: 'search' }), onChange: function (e) { return setKeyword(e.target.value); }, prefix: _jsx(SearchOutlined, { style: { fontSize: 24 } }) }) })), _jsx(Button, { type: "link", htmlType: "submit", onClick: loadData })] })), _jsxs(Table, __assign({ dataSource: organizations, pagination: false, className: "dentobook-table", onRow: function (organization) {
                        return {
                            onClick: function (e) {
                                navigate('/organizations/' + organization.id);
                            }
                        };
                    } }, { children: [_jsx(Table.Column, { render: function (organization) { return organization.name; }, title: _jsx(FormattedMessage, { id: "name" }) }, "name"), _jsx(Table.Column, { render: function (organization) { return organization.id; }, title: _jsx(FormattedMessage, { id: "ID" }) }, "id")] }))] })) })));
};
export default Organizations;
