var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { CalendarOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { getDoctorColor, mapCalendarEventsToWashEvents } from "../../utils/calendarUtils";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { CalendarApiFactory, MedicalStaffApiFactory, PatientApiFactory } from "dentobook-api-typescript-client/api";
import dayjs from "dayjs";
var DashboardCalendarCard = function () {
    var _a = useState([]), appointments = _a[0], setAppointments = _a[1];
    var _b = useState(new Map()), patients = _b[0], setPatients = _b[1];
    var _c = useState(new Map()), medicalStaff = _c[0], setMedicalStaff = _c[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var calendarApi = CalendarApiFactory(apiConfig, '/api');
    var patientApi = PatientApiFactory(apiConfig, '/api');
    var medicalApi = MedicalStaffApiFactory(apiConfig, '/api');
    useEffect(function () {
        fetchCalendarEvents();
    }, []);
    var fetchCalendarEvents = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, events, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, calendarApi.fetchLastThreeCalendarEvents()];
                case 1:
                    res = _a.sent();
                    events = mapCalendarEventsToWashEvents(res.data);
                    setAppointments(events);
                    return [4 /*yield*/, fetchPatients()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchPatients = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, patientMap, _i, _a, patient, err_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, patientApi.fetchAllPatients('', 'id', 'asc', 0, 10000)];
                case 1:
                    res = _b.sent();
                    patientMap = new Map();
                    for (_i = 0, _a = res.data.patients; _i < _a.length; _i++) {
                        patient = _a[_i];
                        patientMap.set(patient.id, patient);
                    }
                    setPatients(patientMap);
                    return [4 /*yield*/, fetchMedicalStaff()];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _b.sent();
                    console.log(err_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchMedicalStaff = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, medicalStaffMap, _i, _a, doctor, err_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, medicalApi.fetchMedicalStaff('', 'id', 1, 10000)];
                case 1:
                    res = _b.sent();
                    medicalStaffMap = new Map();
                    for (_i = 0, _a = res.data.medicalStaff; _i < _a.length; _i++) {
                        doctor = _a[_i];
                        medicalStaffMap.set(doctor.id, doctor);
                    }
                    setMedicalStaff(medicalStaffMap);
                    return [3 /*break*/, 3];
                case 2:
                    err_3 = _b.sent();
                    console.log(err_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Card, __assign({ bordered: true, style: { height: 336, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }, bodyStyle: { padding: '0', flex: 1, display: 'flex', flexDirection: 'column' }, className: "dentobook-dashboard-card dentobook-dashboard-calendar-card" }, { children: [_jsxs("span", __assign({ className: "font-24 dentobook-card-heading" }, { children: [_jsx("span", __assign({ className: "text-blue mr-1" }, { children: _jsx(CalendarOutlined, {}) })), " ", _jsx(FormattedMessage, { id: "nextHours" })] })), _jsxs("div", __assign({ className: "mt-2 mb-24", style: { flex: 1 } }, { children: [_jsx("div", { className: "divider-line" }), appointments.map(function (appointment) {
                        var patient = patients === null || patients === void 0 ? void 0 : patients.get(appointment.patient);
                        var doctor = medicalStaff === null || medicalStaff === void 0 ? void 0 : medicalStaff.get(appointment.doctor);
                        var patientName = patient ? ((patient === null || patient === void 0 ? void 0 : patient.firstName) + ' ' + (patient === null || patient === void 0 ? void 0 : patient.lastName)) : appointment.patient;
                        if (patientName.length > 70) {
                            patientName = patientName.substring(0, 70).trim() + '...';
                        }
                        return (_jsxs(React.Fragment, { children: [_jsxs("div", __assign({ className: "calendar-tile", style: { backgroundColor: getDoctorColor(appointment.doctor) } }, { children: [_jsx("div", __assign({ className: "font-family-roboto font-weight-700 text-black font-10" }, { children: patientName })), _jsxs("div", __assign({ className: "font-family-roboto font-weight-400 text-black font-10" }, { children: [_jsx(FormattedMessage, { id: "dr" }), " ", ' ' + (doctor === null || doctor === void 0 ? void 0 : doctor.firstName) + ' ' + (doctor === null || doctor === void 0 ? void 0 : doctor.lastName)] })), _jsx("div", __assign({ className: "font-family-roboto font-weight-400 text-black font-10" }, { children: dayjs(appointment.start).format('HH:mm') + ' - ' + dayjs(appointment.end).format('HH:mm') }))] })), _jsx("div", { className: "divider-line" })] }, appointment.id));
                    })] })), _jsx(Link, __assign({ to: "/calendar", className: "dentobook-btn-secondary dentobook-btn-medium", style: { margin: "auto", width: '170px', marginBottom: '0px', marginTop: '-15px' } }, { children: _jsx(FormattedMessage, { id: 'toCalendar' }) }))] })));
};
export default DashboardCalendarCard;
