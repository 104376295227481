var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Col, Form, Input, Row, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
var SearchForm = function (_a) {
    var entity = _a.entity, keyword = _a.keyword, setKeyword = _a.setKeyword, sortBy = _a.sortBy, setSortBy = _a.setSortBy, loadData = _a.loadData;
    return (_jsx(Form, __assign({ layout: "horizontal", className: "mb-12", id: "search-form" }, { children: _jsxs(Row, __assign({ gutter: 16 }, { children: [_jsx(Col, __assign({ flex: 5 }, { children: _jsx(Form.Item, __assign({ name: "keyword", className: "dentobook-form-item" }, { children: _jsx(Input, { value: keyword, placeholder: useIntl().formatMessage({ id: 'search' }), onChange: function (e) {
                                var value = e.target.value;
                                setKeyword(value);
                                loadData(true, sortBy, value);
                            }, prefix: _jsx(SearchOutlined, { style: { fontSize: 24 } }) }) })) })), _jsx(Col, __assign({ flex: 0 }, { children: _jsx(Form.Item, __assign({ name: "sortBy", className: "dentobook-form-item sort-by-input" }, { children: _jsxs(Select, __assign({ size: "large", allowClear: true, value: sortBy, suffixIcon: _jsx(CaretDownOutlined, {}), onChange: function (e) {
                                setSortBy(e);
                                loadData(true, e, keyword);
                            }, placeholder: useIntl().formatMessage({ id: 'sortBy' }) }, { children: [_jsx(Select.Option, __assign({ value: "name" }, { children: _jsx(FormattedMessage, { id: "name" }) })), _jsx(Select.Option, __assign({ value: "id" }, { children: "ID" })), entity !== 'medical' ?
                                    _jsx(Select.Option, __assign({ value: "lastHour" }, { children: _jsx(FormattedMessage, { id: "last_hour" }) })) : null, entity !== 'medical' ?
                                    _jsx(Select.Option, __assign({ value: "nextHour" }, { children: _jsx(FormattedMessage, { id: "next_hour" }) })) : null] })) })) }))] })) })));
};
export default SearchForm;
