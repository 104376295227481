import {LOCALES} from './locales';

export const messages = {
    [LOCALES.ENGLISH]: {
        home: 'Home',
        user_content: 'User specific information will be shown here',
        users: 'Users',
        user: 'User',
        patients: 'Patients',
        first_name: 'First name',
        surname: 'Surname',
        last_name: 'Last name',
        phone: 'Phone',
        phone_label: 'Phone (+359)',
        phone_label_two: 'Phone',
        dashboard: 'Dashboard',
        logout: 'Sign Out',
        registration_error: 'Registration failed',
        logoutConfirm: 'Are you sure you want to Sign Out?',
        settings: 'Settings',
        register: 'Register',
        components: 'Components',
        name: 'Name',
        lastAppointment: 'Last Appointment',
        nextAppointment: 'Next Appointment',
        search: 'Search',
        sortBy: 'Sort by',
        filterBy: 'Filter by',
        order: 'Order',
        ascending: 'Ascending',
        descending: 'Descending',
        filter: 'Filter',
        resetFilter: 'ResetFilter',
        diagnose: 'Condition',
        registeredOn: 'Registered on',
        email: 'Email',
        town: 'Town',
        country: 'Country',
        addressOne: 'Address 1',
        addressTwo: 'Address 2',
        notes: 'Notes',
        birth_date: 'Birth Date',
        gender: 'Gender',
        source: 'Source',
        source_name: 'Source name',
        last_hour: 'Last hour',
        next_hour: 'Next hour',
        doctors: 'Dentists',
        personal_information: 'Personal Information',
        registration: 'Registration',
        contact: 'Contact details',
        cancel: 'Cancel',
        save: 'Save',
        payments: 'Payments',
        paid: 'Paid',
        obligations: 'Obligations',
        address: 'Address',
        edit: 'Edit',
        required_field: 'The field is required.',
        invalid_tooth_position: 'Invalid tooth position.',
        invalid_email: 'Invalid email.',
        gender_male: 'Male',
        gender_female: 'Female',
        main_information: 'Main information',
        anamnesis: 'Treatment',
        treatment: 'Treatment',
        treatments: 'Treatments',
        finances: 'Finances',
        photos: 'Photos',
        back: 'Back',
        add: 'Add',
        add_patient: 'Add patient',
        unsaved_changes: 'There are unsaved changes. Are you sure you want to continue?',
        confirm: 'Confirm',
        invalid_phone_number: 'Invalid phone number.',
        invalid_field: 'Invalid field.',
        years_old: 'years old',
        year_old: 'year old',
        levs: 'BGN',
        success_message: 'Your changes were successfully saved.',
        not_available: 'N/A',
        nzis_confirm: 'Confirm and send data to NHIS',
        unexpected_error: 'An error occured.',
        x_ray_photos: 'X-Ray photos',
        patient_photos: 'Patient photos',
        choose_image_category: 'Choose image category',
        category: 'Category',
        choose: 'Choose',
        drag_or_choose: 'Drag and drop photos',
        delete_text: 'Delete',
        move_text: 'Move',
        confirmation_text: 'Are you sure you want to do the following action?',
        delete_patient: 'Delete patient',
        medical_staff: 'Medical staff',
        egn: 'EGN',
        add_medical_staff: 'Add doctor',
        update_medical_staff: 'Update doctor',
        specialisation: 'Specialization',
        uin: 'UIN',
        work_time: 'Work time',
        dentist: 'Dentist',
        all_loaded: 'All data is loaded.',
        loading: 'Loading...',
        no_data: 'No data',
        upload_avatar: 'Upload avatar',
        clinics: 'Clinics',
        add_clinic: 'Add clinic',
        parent_org: 'Parent',
        org_conflict: 'Clinic already exists',
        org_details: 'Clinic details',
        org_edit: 'Edit clinic',
        org_create: 'Add clinic',
        assistant: 'Assistant',
        duplicate_email: 'Email is already taken',
        letters_only_field: 'The field can contain letters only.',
        letters_and_numbers_only: 'The field can contain letters and numbers only.',
        digits_only_field: 'The field can contain digits only.',
        discount_too_large: 'Discount must not exceed 100%',
        patient: 'Patient',
        company: 'Company',
        social_network: 'Social Network',
        calendar: 'Calendar',
        cabinet: 'Cabinet',
        googleCredentials: 'Google Credentials',
        general: 'General',
        appointments: 'Appointments',
        choose_appointment: 'Choose appointment',
        milkTeeth: 'Baby Teeth',
        permTeeth: 'Permanent',
        description: 'Description',
        date: 'Date',
        fromDate: 'From date',
        position: 'Position',
        condition: 'Condition',
        nzis_condition: 'NHIS naming',
        nzis_treatment_label: 'NHIS treatment',
        nzis_condition_label: 'NHIS condition',
        add_condition: 'Add condition',
        add_condition_title: 'Add condition',
        add_appointment: 'Add appointment',
        edit_appointment: 'Edit appointment',
        tooth_status: 'Tooth status',
        treatment_plan: 'Treatment',
        procedure: 'Procedure',
        dental_doctor: 'Dentist',
        sum: 'Price',
        status: 'Status',
        treatment_label: 'Treatment',
        overall_price: 'Price',
        price: 'Price',
        discount: 'Discount (%)',
        discounts: 'Discount',
        discount_label: 'Discount',
        overall_laboratory_price: 'Laboratory price',
        procedures: 'Procedures',
        laboratory_price: 'Laboratory price',
        add_procedure: 'Add procedure',
        add_plan_title: 'Add treatment',
        all_fields_required: 'Please fill in all required fields.',
        fill_all_required_fields: 'Please fill in all required fields.',
        treatment_of: 'Тreatment of',
        in_progress: 'In Progress',
        canceled: 'Canceled',
        done: 'Done',
        offered: 'Offered',
        qr_code_generator: 'QR code generator',
        manual_input: 'Manual input',
        qr_code_generator_description: 'Choose this option if you would like the patient to enter their information',
        manual_input_description: 'Choose this option if you would like to enter the patient\'s information manually',
        general_medical_history: 'General medical history',
        general_illness_suffering: 'Do you suffer from a general illness?',
        medications_taking: 'Do you take medications?',
        medications: 'Medications',
        allergy_having: 'Do you have allergies? (food, medications, etc.)',
        allergies: 'Allergies',
        pregnancy_planning: 'To the ladies: Do you plan pregnancy?',
        yes_ans: 'Yes',
        no_ans: 'No',
        pregnant_ans: 'I am pregnant',
        nursing_question: 'To the ladies: Are you currently breastfeeding?',
        anamensis_title: 'Oral history and oral hygiene habits',
        anesthetic_question: 'Have you been given a local anesthetic for dental treatment?',
        higiene_question: 'Do you regularly clean tartar and plaque at your dentist?',
        social_anamnezis: 'Social history',
        dentist_visit_question: 'How often do you visit the dentist?',
        dentist_last_visit_question: 'When was the last dental visit?',
        fear_question: 'Are you afraid of dental treatment?',
        fear_ans: 'Sometimes (depends on the procedure)',
        dentist_visit_ans1: 'At least twice a year',
        dentist_visit_ans2: 'Once a year with prevention purpose',
        dentist_visit_ans3: 'Less than once a year',
        dentist_visit_ans4: 'Only when I have a problem',
        illnesses: 'Illnesses',
        create_button_text: 'Create',
        reason: 'Reason',
        anesthetic_question2: 'Have they been given a local anesthetic for dental treatment?',
        is_health_insured_question: 'Is the patient health insured?',
        last_visit_patient_quesiton: 'When was the patient\'s last dental visit?',
        frequency_quesiton: 'How often does the patient visit the dentist?',
        is_patient_afraid_question: 'Is the patient afraid of dental treatment?',
        smoker_question: 'Is the patient a smoker?',
        yes: 'Yes',
        no: 'No',
        sometimes: 'Sometimes',
        twice_a_year: 'Twice a year',
        once_a_year: 'Once a year',
        rarely: 'Rarely',
        only_when_necessary: 'Only when necessary',
        insurance_question: 'Do you have health insurance?',
        insurer: 'Insurer',
        smoker_question2: 'Are you a smoker?',
        activity_log: 'Activity log',
        action: 'Action',
        today: 'Today',
        details: 'Details',
        role: 'Role',
        PLATFORM_ADMINISTRATOR: 'Platform Admin',
        CLINIC_ADMIN: 'Clinic Admin',
        MEDICAL_DOCTOR: 'Doctor',
        MEDICAL_ASSISTANT: 'Assistant',
        RECEPTIONIST: 'Receptionist',
        PATIENT: 'Patient',
        company_data: 'Company data',
        logo: 'Logo',
        uic: 'UIC',
        vat: 'VAT',
        rzi_practice_code: 'RZI practice code',
        naming: 'Name',
        accountable_person: 'Аccountable person',
        conditions: 'Conditions',
        action_CREATE_PATIENT: 'Created Patient',
        action_UPDATE_PATIENT: 'Updated Patient',
        action_DELETE_PATIENT: 'Deleted Patient',
        action_CREATE_PATIENT_PHOTO: 'Created Patient Photo',
        action_UPDATE_PATIENT_PHOTO: 'Updated Patient Photo',
        action_DELETE_PATIENT_PHOTO: 'Deleted Patient Photo',
        action_CREATE_PATIENT_MEDICAL_HISTORY: 'Created Patient Medical History',
        action_UPDATE_PATIENT_MEDICAL_HISTORY: 'Updated Patient Medical History',
        action_DELETE_PATIENT_MEDICAL_HISTORY: 'Deleted Patient Medical History',
        action_CREATE_USER: 'Created User',
        action_UPDATE_USER: 'Updated User',
        action_DELETE_USER: 'Deleted User',
        action_CREATE_ORGANIZATION: 'Created Organization',
        action_UPDATE_ORGANIZATION: 'Updated Organization',
        action_DELETE_ORGANIZATION: 'Deleted Organization',
        action_CREATE_CALENDAR_EVENT: 'Created Calendar Event',
        action_UPDATE_CALENDAR_EVENT: 'Updated Calendar Event',
        action_DELETE_CALENDAR_EVENT: 'Deleted Calendar Event',
        action_CREATE_MEDICAL_STAFF: 'Created Medical Staff',
        action_UPDATE_MEDICAL_STAFF: 'Updated Medical Staff',
        action_DELETE_MEDICAL_STAFF: 'Deleted Medical Staff',
        action_CREATE_DENTAL_OFFICE: 'Created Dental Office',
        action_UPDATE_DENTAL_OFFICE: 'Updated Dental Office',
        action_DELETE_DENTAL_OFFICE: 'Deleted Dental Office',
        action_CREATE_PROCEDURE: 'Created Procedure',
        action_UPDATE_PROCEDURE: 'Updated Procedure',
        action_DELETE_PROCEDURE: 'Deleted Procedure',
        action_CREATE_TREATMENT: 'Created Treatment',
        action_UPDATE_TREATMENT: 'Updated Treatment',
        action_DELETE_TREATMENT: 'Deleted Treatment',
        action_CREATE_APPOINTMENT: 'Created Appointment',
        action_UPDATE_APPOINTMENT: 'Updated Appointment',
        action_DELETE_APPOINTMENT: 'Deleted Appointment',
        action_CREATE_CONDITION: 'Created Condition',
        action_UPDATE_CONDITION: 'Updated Condition',
        action_DELETE_CONDITION: 'Deleted Condition',
        action_CREATE_PROCEDURE_TREATMENT: 'Created Procedure Treatment',
        action_UPDATE_PROCEDURE_TREATMENT: 'Updated Procedure Treatment',
        action_DELETE_PROCEDURE_TREATMENT: 'Deleted Procedure Treatment',
        action_CREATE_STATUS_TOOTH: 'Created Status Tooth',
        action_UPDATE_STATUS_TOOTH: 'Updated Status Tooth',
        action_DELETE_STATUS_TOOTH: 'Deleted Status Tooth',
        action_CREATE_TOOTH: 'Created Tooth',
        action_UPDATE_TOOTH: 'Updated Tooth',
        action_DELETE_TOOTH: 'Deleted Tooth',
        action_CREATE_TREATMENT_TOOTH: 'Created Treatment Tooth',
        action_UPDATE_TREATMENT_TOOTH: 'Updated Treatment Tooth',
        action_DELETE_TREATMENT_TOOTH: 'Deleted Treatment Tooth',
        dental_clinic: 'Dental Clinic',
        website: 'Website',
        cabinets: 'Cabinets',
        abbreviation: 'Аbbreviation',
        color: 'Color',
        invalid_vat_number: 'Invalid VAT number.',
        invalid_uic_number: 'Invalid UIC number.',
        invalid_rzi_practice_code: 'Invalid RZI practice code.',
        create: 'Create',
        update: 'Update',
        delete: 'Delete',
        before: 'Before',
        after: 'After',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        start: 'Start',
        end: 'End',
        id: 'Id',
        title: 'Title',
        userId: 'User Id',
        dentalCabinet: 'Dental Cabinet',
        doctor: 'Doctor',
        difference: 'Difference',
        asc: 'Ascending',
        desc: 'Descending',
        addCabinet: 'Add Cabinet',
        enterCabinetName: 'Enter new cabinet name',
        editCabinet: 'Edit cabinet',
        editStatus: 'Edit Status',
        enterStatusName: 'Enter status name',
        enterStatusAbbreviation: 'Enter status abbreviation ',
        enterStatusColor: 'Enter status color',
        statusDeleteConfirmation: 'Are you sure you want to delete this status?',
        deleteStatus: 'Delete status',
        deleteCabinet: 'Delete cabinet',
        cabinetDeleteConfirmation: 'Are you sure you want to delete this cabinet?',
        addStatus: 'Add status',
        enterTreatmentName: 'Enter treatment name',
        enterTreatmentPrice: 'Enter treatment price',
        editTreatment: 'Edit treatment',
        tratmentDeleteConfirmation: "Are you sure you want to delete this treatment?",
        deleteTreatment: 'Delete treatment',
        addProcedure: 'Add procedure',
        enterProcedureName: 'Enter new procedure name',
        enterProcedurePrice: 'Enter procedure price',
        editProcedure: 'Edit procedure',
        editProcedureName: 'Edit new procedure name',
        editProcedurePrice: 'Edit procedure price',
        deleteProcedure: 'Delete procedure',
        procedureDeleteConfirmation: 'Are you sure you want to delete this procedure?',
        enterIllnessName: "Enter illness name",
        editIllness: 'Edit illness',
        addIllness: 'Add illness',
        illnessDeleteConfirmation: 'Are you sure you want to delete this Illness?',
        addAllergy: 'Add allergy',
        enterAllergyName: "Enter allergy name",
        editAllergy: 'Edit allergy',
        deleteAllergy: 'Delete allergy',
        deleteCalendarEvent: 'Delete event',
        allergyDeleteConfirmation: 'Are you sure you want to delete this allergy?',
        eventDeleteConfirmation: 'Are you sure you want to delete this event?',
        deleteMedication: 'Delete medication',
        medicationDeleteConfirmation: 'Are you sure you want to delete this medication?',
        addMedication: 'Add medication',
        enterMedicationName: "Enter medication name",
        editMedication: 'Edit medication',
        medication: 'Medication',
        illness: 'Illness',
        allergy: 'Allergy',
        deleteIllness: 'Delete illness',
        invalid_url: 'Page could not be found',
        link_expired: 'The link has expired.',
        successfully_registered: 'You were successfully registered',
        avatar_upload_error: 'There was a problem with uploading your avatar',
        selectPatient: 'Select patient',
        selectPlaceholder: 'Select',
        selectPatientValidation: 'Please select a patient.',
        from: 'From',
        to: 'To',
        nzis_data_fail: 'There is a problem with sending data to NHIS.',
        nzis_data_success: 'Data was successfully sent to NHIS.',
        generalCalendar: 'General calendar',
        appointmentHour: 'Add an appointment',
        welcome: 'Welcome',
        nextHours: 'Next appointments',
        statistics: 'Statistics',
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        saveNew: 'Sign new',
        anamnesisLabel: 'Anamnesis',
        toCalendar: 'To the calendar',
        dr: 'Dr.',
        proforma_create: 'Create proforma',
        invoice_create: 'Create invoice',
        paidFinance: 'Paid',
        unpaidFinance: 'Unpaid',
        totalPrice: 'Total',
        laboratory: 'Laboratory',
        due: 'Due',
        edit_patient: 'Update patient',
        total: 'Total',
        procedure_calculated_in_treatment: 'Procedure is calculated in the treatment price',
        procedure_not_calculated_in_treatment: 'Procedure must be paid separately',
        totalTwo: 'Total',
        treatmentPriceTooLow: 'Treatment price cannot be lower than overall procedures price',
        delete_appointment: 'Delete appointment',
        qr_description: 'Let the patient scan the QR code and fill in the form. Usage of a personal device is possible.',
        new_patient: ' New patient is created',
        patient_updated: 'The patient was updated successfully',
        here: ' here',
        show_all: 'Show all',
        notifications: 'notifications',
        collapse: 'Collapse',
        profile: 'Profile',
        invalid_image_format: 'The file format is invalid. Allowed file formats are jpg, jpeg, png.',
        invalid_image_size: 'File size must not exceed 1 MB',
        no_notifications: 'No new notifications',
        endTime_error: 'End Time should be after Event Start Time.',
        event_select_alert: 'You cannot select a time in the past.',
        event_move_alert: 'You cannot move an event to a time in the past.',
        max_length_250: 'Max lenght is 250 characters.',
        max_length_150: 'Max lenght is 250 characters.',
        invalid_address: 'Invalid address.',
        invalid_town: 'Invalid town.',
        invalid_country: 'Invalid country.',
        close: 'Close',
        no_selected_appointment: 'Please select an appointment.',
        iban: 'IBAN',
        invoiceNumber: 'Start invoice number',
        proformaInvoiceNumber: 'Start proforma invoice number',
        duplicated_appointment: 'Appointment with the same name already exists.',
        nzis: 'NHIS',
        from_date: 'Start date',
        unsent: 'Unsent',
        sent: 'Sent',
        send_to_nzis: 'Send to NHIS',
        all_data: 'Patients with unsent data',
        medical_prescriptions: 'Medical prescriptions',
        excuse_notes: 'Medical notes',
        operations: 'Operations',
        operation: 'Operation',
        healthArea: 'Health area',
        healthRegion: 'Health region',
        ekkatte: 'EKKATTE',
        all: 'All',
        ambulatory_sheet: 'Ambulatory sheet',
        diagnose_label: 'Condition',
        tooth: 'Tooth',
        operation_code: 'Operation code',
        unsent_single: 'Unsent',
        sent_single: 'Sent',
        select_at_least_one_item: 'Please select at lease one item.',
        specialization1: 'General Practitioner in Dental Medicine',
        specialization2: 'Pediatric Dental Medicine',
        specialization3: 'Oral Surgery',
        specialization4: 'Maxillofacial Surgery / Dental, Oral and Maxillofacial Surgery',
        rhif: 'RHIF',
        specialisation_code: 'Specialisation code',
        ambulatory_list: 'Ambulatory List - Blank',
        informed_consent: 'Informed Consent',
        nhis_consent: 'NHIS - Consent for Access to EHR',
        documents: 'Documents',
        profession: 'Profession',
        no_diagnose: 'No condition',
        no_treatment: 'No treatment',
        cancellation_reason_placeholder: 'Type the cancellation reason here',
        cancellation_reason: 'Cancellation reason',
        cookies_policy: 'Cookies policy',
        usage_rules: 'Terms of use',
        terms_and_conditions: 'Terms and conditions',
        gdpr: 'GDPR',
        create_note: 'Create note',
        start_date: 'Start date',
        end_date: 'End date',
        invalid_photo_image_size: 'File size must not exceed 12 MB.',
        serve_to: 'To serve to',
        english_letters_only_field: 'This field must contain latin letters only',
        signature_client_not_found: 'The signature client is not found!',
        signature_client_start: 'Please run Dentobook eSign App or download it and install it from',
        here_msg: 'here',
        addCredentials: 'Set Google Credentials',
        googleWarning: 'Your Google Client ID is not configured. Please set it to re-authenticate.',
        reAuth: 'Your session has expired. Please re-authenticate with Google.',
        googleLogin: 'Login with Google.',
        clientId: 'clientId',
        clientSecret: 'clientSecret',
        editCredentials: 'Edit Google Credentials',
        identifierAlert: 'The EGN does not match the birth date.',
        patientEmailAlert: 'Patient email is not available. Event in Google Calendar can not be created. Proceeding with regular calendar event creation/update',
        done_operations: 'Done operations',
        invoices: 'Invoices',
        proforma_invoices: 'Proforma invoices',
        maturity: 'Maturity',
        price_value: 'Value',
        paid_price_value: 'Paid',
        payment_type: 'Payment method',
        canceled_finance: 'Canceled',
        paid_invoice: 'Paid',
        unpaid_invoice: 'Unpaid',
        canceled_invoice: 'Canceled',
        cash_payment_type: 'Cash',
        card_payment_type: 'By card',
        paid_obligations: 'Paid',
        create_invoice: 'Create invoice',
        payment_deadline: 'Payment deadline',
        payment_method: 'Payment method',
        vat_label: 'VAT',
        bank_transfer: 'Bank transfer',
        create_proforma_invoice: 'Create proforma invoice',
        update_proforma_invoice: 'Proforma',
        tooth_status_delete_confirm: 'Are you sure you want to delete this tooth status?',
        tooth_treatment_delete_confirm: 'Are you sure you want ot delete this treatment?',
        leave_page_title: 'Leaving page',
        leave_page: 'Leave page',
        leave_page_message: 'Navigating away from this page will discard any unsaved information. Please save your changes before leaving.',
        field_too_long_message: 'Value is too long',
        proforma_void_message: 'Are you sure you want to void this proforma invoice?',
        void_message: 'Are you sure you want to void this invoice?',
        void_button: 'Void',
        invoice_generate_confirm: 'Are you sure you want to generate an invoice from the proforma invoice?',
        generate_btn: 'Generate',
        generate_invoice_tooltip: 'Generate invoice',
        edit_tooltip: 'Edit',
        print_tooltip: 'Print',
        nzis_mandatory_fields: 'Please complete the following fields in the patient profile: [FIELDS]. Once you have filled in the fields, you will be able to proceed.',
        invoice_numbering_title: 'Changing invoices numbering',
        invoice_numbering_text: 'Dentobook generates an unique 10-digits number for each issued invoice. If there are no invoices in the database, the first number will be 0000000001 and each next will be incremented by 1. If this is not your first invoice, change the number that starts the counting.',
        proforma_numbering_title: 'Changing proforma invoices numbering',
        proforma_numbering_text: 'Dentobook generates an unique 10-digits number for each issued proforma invoice. If there are no proforma invoices in the database, the first number will be 0000000001 and each next will be incremented by 1. If this is not your first proforma invoice, change the number that starts the counting.',
    },
    [LOCALES.BULGARIAN]: {
        home: 'Начало',
        user_content: 'Специфична за потребителя информация ще бъде показана тук',
        users: 'Потребители',
        user: 'Потребител',
        patients: 'Пациенти',
        first_name: 'Име',
        surname: 'Презиме',
        last_name: 'Фамилия',
        phone: 'Телефон',
        phone_label: 'Телефон (+359)',
        phone_label_two: 'Телефон',
        dashboard: 'Начална страница',
        settings: 'Настройки',
        logout: 'Изход',
        logoutConfirm: 'Сигурни ли сте, че искате да излезете от профила си?',
        register: 'Регистрация',
        components: 'Компоненти',
        name: 'Име',
        lastAppointment: 'Последен час',
        nextAppointment: 'Следващ час',
        search: 'Търсене',
        sortBy: 'Сортирай по',
        filterBy: 'Филтрирай по',
        order: 'Ред',
        ascending: 'Възходящ',
        descending: 'Низходящ',
        filter: 'Филтър',
        resetFilter: 'Нулиране на филтър',
        diagnose: 'Общо състояние',
        registeredOn: 'Регистриран на',
        email: 'Имейл',
        town: 'Град',
        country: 'Държава',
        addressOne: 'Адрес 1',
        addressTwo: 'Адрес 2',
        notes: 'Бележки',
        birth_date: 'Дата на раждане',
        gender: 'Пол',
        source: 'Източник',
        source_name: 'Име на източник',
        last_hour: 'Последен час',
        next_hour: 'Следващ час',
        doctors: 'Дентални лекари',
        personal_information: 'Лична информация',
        registration: 'Регистрация',
        contact: 'Контакт',
        cancel: 'Откажи',
        save: 'Запази',
        payments: 'Плащания',
        paid: 'Платени',
        obligations: 'Задължения',
        address: 'Адрес',
        edit: 'Редактирай',
        required_field: 'Полето е задължително.',
        invalid_tooth_position: 'Невалидна позиция на зъб.',
        invalid_email: 'Невалиден имейл.',
        gender_male: 'Мъж',
        gender_female: 'Жена',
        main_information: 'Основна информация',
        anamnesis: 'Лечение',
        anamnesisLabel: 'Анамнеза',
        treatment: 'Лечение',
        treatments: 'Лечения',
        finances: 'Финанси',
        photos: 'Снимки',
        back: 'Назад',
        add: 'Добави',
        add_patient: 'Добави пациент',
        unsaved_changes: 'Има незапазени промени. Сигурни ли сте, че искате да продължите без да запазите?',
        confirm: 'Потвърди',
        invalid_phone_number: 'Невалиден телефонен номер.',
        invalid_field: 'Невалидно поле.',
        years_old: 'години',
        year_old: 'година',
        levs: 'лв.',
        success_message: 'Направените промени са успешно запазени.',
        not_available: 'Няма',
        nzis_confirm: 'Потвърди и изпрати към НЗИС',
        unexpected_error: 'Възникна грешка.',
        x_ray_photos: 'Рентгенови снимки',
        patient_photos: 'Снимки пациент',
        choose_image_category: 'Избери категория снимки',
        category: 'Категория',
        choose: 'Избери',
        drag_or_choose: 'Изберете или провлачете снимки',
        delete_text: 'Изтрий',
        move_text: 'Премести',
        confirmation_text: 'Сигурен/на ли сте, че искате да извършите зададеното действие?',
        delete_patient: 'Изтрий пациент',
        medical_staff: 'Дентални лекари',
        egn: 'ЕГН',
        add_medical_staff: 'Добави лекар',
        update_medical_staff: 'Редактирай лекар',
        registration_error: 'Неуспешна регистрация',
        specialisation: 'Специалност',
        uin: 'УИН',
        work_time: 'Работно време',
        dentist: 'Зъболекар',
        all_loaded: 'Всички данни са заредени.',
        loading: 'Зареждане...',
        no_data: 'Няма данни',
        upload_avatar: 'Качи профилна снимка',
        clinics: 'Клиники',
        add_clinic: 'Добави клиника',
        naming: 'Наименование',
        parent_org: 'Родителска клиника',
        org_conflict: 'Вече има такава съществуваща клиника',
        org_details: 'Детайли за клиника',
        org_edit: 'Редактирай клиника',
        org_create: 'Добави клиника',
        assistant: 'Асистент',
        duplicate_email: 'Въведеният имейл вече е използван',
        letters_only_field: 'Полето може да съдържа само букви.',
        letters_and_numbers_only: 'Полето може да съдържа само букви и цифри.',
        digits_only_field: 'Полето може да съдържа само цифри.',
        discount_too_large: 'Отстъпката не може да надвишава 100%',
        patient: 'Пациент',
        company: 'Компания',
        social_network: 'Социална мрежа',
        calendar: 'Календар',
        cabinet: 'Кабинет',
        googleCredentials: 'Google Credentials',
        general: 'Основни',
        appointments: 'Прегледи',
        choose_appointment: 'Избери преглед',
        milkTeeth: 'Млечни',
        permTeeth: 'Постоянни',
        description: 'Описание',
        date: 'Дата',
        fromDate: 'От дата',
        position: 'Позиция',
        condition: 'Състояние',
        nzis_condition: 'Състояние по НЗИС',
        nzis_treatment_label: 'Дейност по НЗИС',
        nzis_condition_label: 'Състояние по НЗИС',
        add_condition: 'Добави състояние',
        add_condition_title: 'Добавяне на състояние',
        add_appointment: 'Добави преглед',
        edit_appointment: 'Редактирай преглед',
        tooth_status: 'Зъбен статус',
        treatment_plan: 'Лечение',
        procedure: 'Процедура',
        dental_doctor: 'Дентален лекар',
        sum: 'Сума',
        status: 'Статус',
        treatment_label: 'Дейност',
        overall_price: 'Цена',
        price: 'Цена',
        discount: 'Отстъпка (%)',
        discounts: 'Отстъпки',
        discount_label: 'Отстъпка',
        overall_laboratory_price: 'Разход лаборатория',
        procedures: 'Процедури',
        laboratory_price: 'Разход лаборатория',
        add_procedure: 'Добави процедура',
        add_plan_title: 'Добавяне на дейност',
        all_fields_required: 'Моля въведете всички задължителни полета.',
        fill_all_required_fields: 'Моля попълнете всички задължителни полета',
        treatment_of: 'Лечение на',
        in_progress: 'В процес',
        canceled: 'Отменено',
        done: 'Изпълнено',
        offered: 'Предложено',
        qr_code_generator: 'QR код генератор',
        manual_input: 'Ръчно въвеждане',
        qr_code_generator_description: 'Изберете тази опция ако искате пациента да въведе сам цялата информация',
        manual_input_description: 'Изберете тази опция ако искате ръчно да въведете цялата информация за пациента',
        general_medical_history: 'Обща медицинска анамнеза',
        general_illness_suffering: 'Страдате ли от общо заболаване?',
        medications_taking: 'Приемате ли медикаменти?',
        medications: 'Медикаменти',
        allergy_having: 'Имате ли алергии (към храни, лекарства, прах и др.)',
        allergies: 'Алергии',
        pregnancy_planning: 'Към дамите: Планирате ли бременност?',
        yes_ans: 'Да',
        no_ans: 'Не',
        pregnant_ans: 'Бременна съм',
        nursing_question: 'Към дамите: Кърмите ли в момента?',
        anamensis_title: 'Орална анамнеза и орално-хигиенни навици',
        anesthetic_question: 'Поставяна ли Ви е локална (местна) упойка по повод дентално лечение?',
        higiene_question: 'Почиствате ли редовно зьбен камък и плака при Вашия дентален лекар?',
        social_anamnezis: 'Социална анамнеза',
        dentist_visit_question: 'Колко често посещавате дентален лекар?',
        dentist_last_visit_question: 'Кога беше последното посещение по повод дентално лечение?',
        fear_question: 'Изпитвате ли страх от дентално лечение?',
        fear_ans: 'Понякога (в зависимост от процедурата)',
        dentist_visit_ans1: 'Поне два пъти годишно',
        dentist_visit_ans2: 'Веднъж годишно с цел профилактика',
        dentist_visit_ans3: 'По-рядко от веднъж годишно',
        dentist_visit_ans4: 'Само, когато имам проблем',
        illnesses: 'Заболявания',
        reason: 'Причина',
        total: 'Общо',
        procedure_calculated_in_treatment: 'Процедурата е калкулирана в лечението',
        procedure_not_calculated_in_treatment: 'Процедурата се заплаща допълнително',
        totalTwo: 'Тотал',
        treatmentPriceTooLow: 'Цената на лечението не може да бъде по-ниска от сбора на процедурите',
        anesthetic_question2: 'Поставяна ли е локална (местна) упойка по повод дентално лечение?',
        is_health_insured_question: 'Има ли пациента допълнително здравно осигуряване?',
        frequency_quesiton: 'Колко често пациента посещава дентален лекар?',
        last_visit_patient_quesiton: 'Кога е било последното посещение на пациента по повод дентално лечение?',
        is_patient_afraid_question: 'Изпитва ли страх от дентално лечение?',
        smoker_question: 'Пушач ли е пациента?',
        yes: 'Да',
        no: 'Не',
        sometimes: 'Понякога',
        twice_a_year: 'Два пъти в годината',
        once_a_year: 'Веднъж в годината',
        rarely: 'Рядко',
        only_when_necessary: 'Само при необходимост',
        insurance_question: 'Имате ли допълнително здравно осигуряване?',
        insurer: 'Застраховател',
        smoker_question2: 'Пушач ли сте?',
        activity_log: 'История на промените',
        action: 'Действие',
        details: 'Детайли',
        today: 'Днес',
        role: 'Роля',
        PLATFORM_ADMINISTRATOR: 'Администратор на платформа',
        CLINIC_ADMIN: 'Администратор на клиника',
        MEDICAL_DOCTOR: 'Лекар',
        MEDICAL_ASSISTANT: 'Асистент',
        RECEPTIONIST: 'Рецепционист',
        PATIENT: 'Пациент',
        company_data: 'Фирмени данни',
        logo: 'Лого',
        uic: 'ЕИК',
        vat: 'ДДС номер',
        rzi_practice_code: 'РЗИ код на практика',
        accountable_person: 'МОЛ',
        conditions: 'Състояния',
        action_CREATE_PATIENT: 'Създаден пациент',
        action_UPDATE_PATIENT: 'Обновен пациент',
        action_DELETE_PATIENT: 'Изтрит пациент',
        action_CREATE_PATIENT_PHOTO: 'Създадена снимка на пациент',
        action_UPDATE_PATIENT_PHOTO: 'Обновена снимка на пациент',
        action_DELETE_PATIENT_PHOTO: 'Изтрита снимка на пациент',
        action_CREATE_PATIENT_MEDICAL_HISTORY: 'Създадена медицинска история на пациента',
        action_UPDATE_PATIENT_MEDICAL_HISTORY: 'Обновена медицинска история на пациента',
        action_DELETE_PATIENT_MEDICAL_HISTORY: 'Изтрита медицинска история на пациента',
        action_CREATE_USER: 'Създаден потребител',
        action_UPDATE_USER: 'Обновен потребител',
        action_DELETE_USER: 'Изтрит потребител',
        action_CREATE_ORGANIZATION: 'Създадена организация',
        action_UPDATE_ORGANIZATION: 'Обновена организация',
        action_DELETE_ORGANIZATION: 'Изтрита организация',
        action_CREATE_CALENDAR_EVENT: 'Създадено събитие в календара',
        action_UPDATE_CALENDAR_EVENT: 'Обновено събитие в календара',
        action_DELETE_CALENDAR_EVENT: 'Изтрито събитие в календара',
        action_CREATE_MEDICAL_STAFF: 'Създаден медицински персонал',
        action_UPDATE_MEDICAL_STAFF: 'Обновен медицински персонал',
        action_DELETE_MEDICAL_STAFF: 'Изтрит медицински персонал',
        action_CREATE_DENTAL_OFFICE: 'Създаден стоматологичен кабинет',
        action_UPDATE_DENTAL_OFFICE: 'Обновен стоматологичен кабинет',
        action_DELETE_DENTAL_OFFICE: 'Изтрит стоматологичен кабинет',
        action_CREATE_PROCEDURE: 'Създадена процедура',
        action_UPDATE_PROCEDURE: 'Обновена процедура',
        action_DELETE_PROCEDURE: 'Изтрита процедура',
        action_CREATE_TREATMENT: 'Създадено лечение',
        action_UPDATE_TREATMENT: 'Обновено лечение',
        action_DELETE_TREATMENT: 'Изтрито лечение',
        action_CREATE_APPOINTMENT: 'Създаден час за прием',
        action_UPDATE_APPOINTMENT: 'Обновен час за прием',
        action_DELETE_APPOINTMENT: 'Изтрит час за прием',
        action_CREATE_CONDITION: 'Създадено състояние',
        action_UPDATE_CONDITION: 'Обновено състояние',
        action_DELETE_CONDITION: 'Изтрито състояние',
        action_CREATE_PROCEDURE_TREATMENT: 'Създаденo процедурно лечение',
        action_UPDATE_PROCEDURE_TREATMENT: 'Обновенo процедурно лечение',
        action_DELETE_PROCEDURE_TREATMENT: 'Изтритo процедурно лечение',
        action_CREATE_STATUS_TOOTH: 'Създадено състояние на зъб',
        action_UPDATE_STATUS_TOOTH: 'Обновено състояние на зъб',
        action_DELETE_STATUS_TOOTH: 'Изтрито състояние на зъб',
        action_CREATE_TOOTH: 'Създаден зъб',
        action_UPDATE_TOOTH: 'Обновен зъб',
        action_DELETE_TOOTH: 'Изтрит зъб',
        action_CREATE_TREATMENT_TOOTH: 'Създадено лечение на зъб',
        action_UPDATE_TREATMENT_TOOTH: 'Обновено лечение на зъб',
        action_DELETE_TREATMENT_TOOTH: 'Изтрито лечение на зъб',
        dental_clinic: 'Дентална клиника',
        website: 'Уебсайт',
        cabinets: 'Кабинети',
        abbreviation: 'Съкращение',
        color: 'Цвят',
        invalid_vat_number: 'Невалиден ДДС номер.',
        invalid_uic_number: 'Невалиден ЕИК номер.',
        create: 'Създаване',
        update: 'Обновяване',
        delete: 'Изтриване',
        before: 'Преди',
        after: 'След',
        createdAt: 'Създадено на',
        updatedAt: 'Обновено на',
        start: 'Начало',
        end: 'Край',
        id: 'ИД',
        title: 'Заглавие',
        userId: 'Потребителско ИД',
        dentalCabinet: 'Кабинет',
        doctor: 'Doctor',
        difference: 'Разлика',
        asc: ' възходящo',
        desc: ' низходящo',
        addCabinet: 'Добавете кабинет',
        enterCabinetName: 'Въведете име на кабинет',
        editCabinet: 'Редактирайте кабинет',
        editStatus: 'Редактирайте състояние',
        enterStatusName: 'Въведете име на състояние',
        enterStatusAbbreviation: 'Въведете съкращение на състояние',
        enterStatusColor: 'Въведете цвят на състояние',
        statusDeleteConfirmation: 'Сигурни ли сте, че искате да изтриете това състояние?',
        deleteStatus: 'Изтрийте състояние',
        deleteCabinet: 'Изтрийте кабинет',
        cabinetDeleteConfirmation: 'Сигурни ли сте, че искате да изтриете този кабинет?',
        addStatus: 'Добавете състояние',
        enterTreatmentName: 'Въведете име на лечение',
        enterTreatmentPrice: 'Въведете цена на лечение',
        editTreatment: 'Редактирайте дейност',
        tratmentDeleteConfirmation: "Сигурни ли сте, че искате да изтриете тази дейност?",
        deleteTreatment: 'Изтрийте дейност',
        addProcedure: 'Добавете процедура',
        enterProcedureName: "Въведете име на процедура",
        enterProcedurePrice: 'Въведете цена на процедура',
        editProcedure: 'Редактирайте процедура',
        editProcedureName: 'Редактирайте процедура',
        editProcedurePrice: 'Редактирайте цена на процедура',
        deleteProcedure: 'Изтрийте процедура',
        procedureDeleteConfirmation: 'Сигурни ли сте, че искате да изтриете тази процедура?',
        enterIllnessName: "Въведете име на заболяване",
        editIllness: 'Редактирайте заболяване',
        addIllness: 'Добавете заболяване',
        illnessDeleteConfirmation: 'Сигурни ли сте, че искате да изтриете това заболяване?',
        addAllergy: 'Добавете алергия',
        enterAllergyName: "Въведете име на алергия",
        editAllergy: 'Редактирайте алергия',
        invalid_rzi_practice_code: 'Невалиден РЗИ код на практика.',
        deleteAllergy: 'Изтрийте алергия',
        deleteCalendarEvent: 'Изтрийте събитие',
        allergyDeleteConfirmation: 'Сигурни ли сте, че искате да изтриете тази алергия?',
        eventDeleteConfirmation: 'Сигурни ли сте, че искате да изтриете това събитие?',
        deleteMedication: 'Изтрийте медикамент',
        medicationDeleteConfirmation: 'Сигурни ли сте, че искате да изтриете този медикамент?',
        addMedication: 'Добавете медикамент',
        enterMedicationName: "Въведете име на медикамент",
        editMedication: 'Редактирайте медикамент',
        medication: 'Mедикамент',
        illness: 'Заболяване',
        allergy: 'Алергия',
        deleteIllness: 'Изтрийте заболяване',
        invalid_url: 'Няма такава страница',
        link_expired: 'Линкът е изтекъл',
        successfully_registered: 'Регистриран сте успешно',
        avatar_upload_error: 'Имаше проблем при запазването на Вашия аватар',
        selectPatient: 'Изберете пациент',
        selectPlaceholder: 'Избери',
        selectPatientValidation: 'Моля изберете пациент.',
        from: 'От',
        to: 'До',
        nzis_data_fail: 'Има проблем при изпращането към НЗИС.',
        nzis_data_success: 'Данните бяха изпратени успешно към НЗИС.',
        generalCalendar: 'Общ календар',
        appointmentHour: 'Добави час',
        welcome: 'Здравейте',
        nextHours: 'Следващи часове',
        statistics: 'Статистика',
        sunday: 'Неделя',
        monday: 'Понеделник',
        tuesday: 'Вторник',
        wednesday: 'Сряда',
        thursday: 'Четвъртък',
        friday: 'Петък',
        saturday: 'Събота',
        saveNew: 'Запиши нов',
        toCalendar: 'Към календар',
        dr: 'Д-р',
        proforma_create: 'Създай проформа',
        invoice_create: 'Създай фактура',
        create_button_text: 'Създай',
        paidFinance: 'Платено',
        unpaidFinance: 'Неплатено',
        totalPrice: 'Общо',
        laboratory: 'Лаборатория',
        due: 'Дължимо',
        edit_patient: 'Редактирай пациент',
        delete_appointment: 'Изтрий преглед',
        qr_description: 'Помолете пациента да сканира QR кода и да попълни формата. Възможно е изполването на лично устройство.',
        profile: 'Профил',
        new_patient: ' Създаден е нов пациент, вижте',
        patient_updated: 'Пациентът беше обновен успешно, вижте',
        here: ' тук',
        show_all: 'Покажете всички',
        notifications: 'известия',
        collapse: 'Свиване на менюто',
        invalid_image_format: 'Форматът на файла е невалиден. Валидните формати са jpeg, jpg, png.',
        invalid_image_size: 'Размерът на файла не трябва да превишава 1 MB.',
        invalid_photo_image_size: 'Размерът на файла не трябва да превишава 12 MB.',
        no_notifications: 'Няма нови известия',
        endTime_error: 'Времето за край трябва да е след времето за начало на събитието.',
        event_select_alert: 'Не можете да избирате минал период от време.',
        event_move_alert: 'Не може да насрочвате събитие за минал период от време.',
        max_length_250: 'Максималната дължина е 250 символа.',
        max_length_150: 'Максималната дължина е 150 символа.',
        invalid_address: 'Невалиден адрес.',
        invalid_town: 'Невалиден град.',
        close: 'Затвори',
        invalid_country: 'Невалидна държава.',
        no_selected_appointment: 'Моля изберете преглед.',
        iban: 'IBAN',
        invoiceNumber: 'Начален номер за фактури',
        proformaInvoiceNumber: 'Начален номер за проформа фактури',
        duplicated_appointment: 'Преглед със същото име вече съществува.',
        nzis: 'НЗИС',
        from_date: 'От дата',
        unsent: 'Неизпратени',
        sent: 'Изпратени',
        send_to_nzis: 'Изпрати към НЗИС',
        all_data: 'Пациенти с неизпратени данни',
        medical_prescriptions: 'Медицински рецепти',
        excuse_notes: 'Извинителни бележки',
        operations: 'Дейности',
        operation: 'Дейност',
        healthArea: 'Здравна област',
        healthRegion: 'Здравен регион',
        ekkatte: 'ЕККАТТЕ',
        specialization1: 'Общопрактикуващ лекар по дентална медицина',
        specialization2: 'Детска дентална медицина',
        specialization3: 'Орална хирургия',
        specialization4: 'Лицево-челюстна хирургуя / Дентална, орална и лицево-челюстна хирургуя ',
        rhif: 'РЗОК',
        specialisation_code: 'Код на специалност',
        all: 'Всички',
        ambulatory_sheet: 'Амбулаторен лист',
        diagnose_label: 'Състояние',
        tooth: 'Зъб',
        operation_code: 'Код дейност',
        unsent_single: 'Неизпратено',
        sent_single: 'Изпратено',
        select_at_least_one_item: 'Моля изберете поне един ред.',
        ambulatory_list: 'Амбулаторен лист - бланка',
        informed_consent: 'Информирано съгласие',
        nhis_consent: 'НЗИС - Съгласие на пациента за достъп до неговото ЕЗД',
        documents: 'Документи',
        profession: 'Професия',
        no_diagnose: 'Няма състояние',
        no_treatment: 'Няма дейност',
        cancellation_reason_placeholder: 'Въведете причина за анулирането тук',
        cancellation_reason: 'Причина за анулиране',
        cookies_policy: 'Политика за бисквитки',
        usage_rules: 'Правила за използване',
        terms_and_conditions: 'Общи условия',
        gdpr: 'GDPR',
        create_note: 'Създай бележка',
        start_date: 'Начална дата',
        end_date: 'Крайна дата',
        serve_to: 'Да послужи пред',
        english_letters_only_field: 'Това поле трябва да съдържа само латински букви',
        signature_client_not_found: 'Приложението за синхронизация на електронен подпис не е открито!',
        signature_client_start: 'Моля, стартирайте Dentobook eSign App или го свалете и инсталирайте от',
        here_msg: 'тук',
        addCredentials: 'Добавете Google Credentials',
        googleWarning: 'Вашият клиентски идентификатор в Google не е конфигуриран. Моля, настройте го за повторно удостоверяване.',
        reAuth: 'Сесията ви е изтекла. Моля, удостоверете се повторно с Google.',
        googleLogin: 'Влезте с Google.',
        clientId: 'Client Id',
        clientSecret: 'Client Secret',
        editCredentials: 'Редактирайте Google Credentials',
        identifierAlert: 'ЕГН не съвпада с дата на раждане моля коригирайте данните.',
        patientEmailAlert: 'Имейлът на пациента не е наличен. Събитието в Google Календар не може да бъде създадено. Продължава се със създаването/актуализирането на редовното събитие в календара.',
        done_operations: 'Изпълнени дейности',
        invoices: 'Фактури',
        proforma_invoices: 'Проформа фактури',
        maturity: 'Падеж',
        price_value: 'Стойност',
        paid_price_value: 'Платено',
        payment_type: 'Тип на плащане',
        canceled_finance: 'Анулирано',
        paid_invoice: 'Платена',
        unpaid_invoice: 'Неплатена',
        canceled_invoice: 'Анулирана',
        cash_payment_type: 'В брой',
        card_payment_type: 'С карта',
        paid_obligations: 'Платени',
        create_invoice: 'Създай фактура',
        payment_deadline: 'Срок на плащане',
        payment_method: 'Начин на плащане',
        vat_label: 'ДДС',
        bank_transfer: 'Банков превод',
        create_proforma_invoice: 'Създай проформа фактура',
        update_proforma_invoice: 'Проформа',
        tooth_status_delete_confirm: 'Сигурни ли сте, че искате да изтриете този зъбен статус?',
        tooth_treatment_delete_confirm: 'Сигурни ли сте, че искате да изтриете това лечение?',
        leave_page_title: 'Напускане на страница',
        leave_page: 'Напусни страница',
        leave_page_message: 'Напускането на тази страница ще изтрие всички незапазени данни. Моля, запазете промените преди да напуснете.',
        field_too_long_message: 'Стойността е прекалено дълга.',
        proforma_void_message: 'Сигурни ли сте, че искате да анулирате тази проформа фактура?',
        void_message: 'Сигурни ли сте, че искате да анулирате тази фактура?',
        void_button: 'Анулирай',
        invoice_generate_confirm: 'Сигурни ли сте, че искате да генерирате фактура от проформа фактура?',
        generate_btn: 'Генерирай',
        generate_invoice_tooltip: 'Генерирай фактура',
        edit_tooltip: 'Редактирай',
        print_tooltip: 'Принтирай',
        nzis_mandatory_fields: 'Попълнете следните полета в пациентския профил: [FIELDS]. След като попълните полетата, ще можете да продължите.',
        invoice_numbering_title: 'Промяна на номерацията на фактурите',
        invoice_numbering_text: 'Dentobook генерира уникален 10-разреден последователен номер за всяка издадена фактура. При липсата на фактури, съхранени в базата данни, първият номер ще бъде 0000000001, а всеки следващ - с единица по-голям. Ако това не е първата Ви фактура, променете номера, от който започва броенето.',
        proforma_numbering_title: 'Промяна на номерацията на проформа фактурите',
        proforma_numbering_text: 'Dentobook генерира уникален 10-разреден последователен номер за всяка издадена проформа фактура. При липсата на проформа фактури, съхранени в базата данни, първият номер ще бъде 0000000001, а всеки следващ - с единица по-голям. Ако това не е първата Ви проформа фактура, променете номера, от който започва броенето.',
    }
};