var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal, Spin, Tabs } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import '../assets/css/Dentobook.css';
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { CalendarApiFactory, PatientApiFactory } from "dentobook-api-typescript-client/api";
import PatientDetailsItem from "../components/patient/PatientDetailsItem";
import DetailWrapper from "../components/common/DetailWrapper";
import { CloseOutlined, DeleteOutlined, LoadingOutlined, PrinterOutlined } from "@ant-design/icons";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
import PatientTreatment from "../components/patient/PatientTreatment";
import PatientPhotos from "../components/patient/PatientPhotos";
import { isMobileOnly } from "react-device-detect";
import { AlertContext } from "../context/AlertContext";
import PatientNzis from "../components/patient/PatientNzis";
import { DentobookApplicationContext } from "../context/DentobookApplicationContext";
import PatientFinancesTabs from "../components/patient/PatientFinancesTabs";
var PatientDetails = function () {
    var _a, _b;
    var principal = useContext(DentobookApplicationContext).principal;
    var navigate = useNavigate();
    var id = useParams().id;
    var location = useLocation();
    var setVisible = useContext(AlertContext).setVisible;
    var _c = useState(false), editMode = _c[0], setEditMode = _c[1];
    var _d = useState({}), patient = _d[0], setPatient = _d[1];
    var _e = useState('main-information'), activeKey = _e[0], setActiveKey = _e[1];
    var _f = useState({}), selectedAppointment = _f[0], setSelectedAppointment = _f[1];
    var _g = useState(false), loading = _g[0], setLoading = _g[1];
    var _h = useState(false), isModalOpen = _h[0], setIsModalOpen = _h[1];
    var spinnerIcon = _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true });
    useEffect(function () {
        var searchParams = new URLSearchParams(location.search);
        var tab = searchParams.get("tab");
        if (tab) {
            setActiveKey(tab);
        }
    }, [location.search]);
    var items = [
        {
            label: _jsx(FormattedMessage, { id: "main_information" }),
            key: 'main-information',
            children: _jsx(PatientDetailsItem, { patient: patient, setPatient: setPatient, editMode: editMode, setEditMode: setEditMode })
        },
        {
            label: _jsx(FormattedMessage, { id: "anamnesis" }),
            key: 'anamnesis',
            children: _jsx(PatientTreatment, { selectedAppointment: selectedAppointment, setSelectedAppointment: setSelectedAppointment, patient: patient })
        },
        {
            label: _jsx(FormattedMessage, { id: "finances" }),
            key: 'finances',
            children: _jsx(PatientFinancesTabs, { patient: patient })
        },
        {
            label: _jsx(FormattedMessage, { id: "photos" }),
            key: 'photos',
            children: _jsx(PatientPhotos, { patient: patient })
        }
    ];
    if (((_a = principal === null || principal === void 0 ? void 0 : principal.user) === null || _a === void 0 ? void 0 : _a.role) === 'CLINIC_ADMIN' || ((_b = principal === null || principal === void 0 ? void 0 : principal.user) === null || _b === void 0 ? void 0 : _b.role) === 'MEDICAL_DOCTOR') {
        items.splice(2, 0, {
            label: _jsx(FormattedMessage, { id: "nzis" }),
            key: 'nzis',
            children: _jsx(PatientNzis, { patient: patient })
        });
    }
    var apiConfig = new Configuration({ basePath: '/api' });
    var patientApi = PatientApiFactory(apiConfig, '/api');
    var calendarApi = CalendarApiFactory(apiConfig, '/api');
    useEffect(function () {
        if (id == null) {
            return;
        }
        patientApi.fetchPatient(id)
            .then(function (response) {
            setPatient(response.data);
        })
            .catch(function (err) {
            console.log(err);
        });
    }, []);
    var generatePdf = function () {
        setLoading(true);
        patientApi.generatePdfForPatient(selectedAppointment.id)
            .then(function (pdfResponse) {
            setLoading(false);
            window.open(pdfResponse.request.responseURL);
        })
            .catch(function () { return setLoading(false); });
    };
    var handleOk = function () {
        patientApi.deletePatient(patient.id)
            .then(function () {
            setIsModalOpen(false);
            setVisible(true);
            setTimeout(function () { return setVisible(false); }, 10000);
            navigate('/patients');
            if (patient.id != null) {
                calendarApi.deletePatientCalendarEvents(patient.id);
            }
        })
            .catch(function (err) { return console.log(err); });
    };
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    var footer = _jsxs("div", __assign({ className: "modal-btn-wrapper" }, { children: [_jsx(Button, __assign({ className: "modal-btn", onClick: handleCancel }, { children: _jsx(FormattedMessage, { id: "cancel" }) })), _jsx(Button, __assign({ className: "modal-btn", type: "primary", onClick: handleOk }, { children: _jsx(FormattedMessage, { id: "confirm" }) }))] }));
    if (!patient.id) {
        return null;
    }
    return (_jsxs(AccessControl, __assign({ notAccessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR, accessibilityRoles.PATIENT] }, { children: [_jsx(Modal, __assign({ transitionName: "", footer: footer, className: "dentobook-modal", title: _jsx(FormattedMessage, { id: "delete_patient" }), closeIcon: _jsx(CloseOutlined, { className: 'icon-24px', onClick: handleCancel }), open: isModalOpen, onOk: handleOk, onCancel: handleCancel }, { children: _jsx("h3", __assign({ className: "dentobook-content font-weight-500 font-24 text-align-center " + (isMobileOnly ? "mt-112" : "") }, { children: _jsx(FormattedMessage, { id: "confirmation_text" }) })) })), _jsx(Spin, { indicator: spinnerIcon, spinning: loading, style: { position: "fixed", top: "50%", left: "50%", zIndex: "99999" } }), _jsxs(DetailWrapper, __assign({ navigateBack: "/patients" }, { children: [_jsxs("div", __assign({ className: "top-right-btns", style: { marginTop: -64 } }, { children: [_jsx(AccessControl, __assign({ accessibleFor: [accessibilityRoles.CLINIC_ADMIN] }, { children: _jsx(Button, { className: "text-black font-20", icon: _jsx(DeleteOutlined, { style: { fontSize: '140%' } }), size: "large", type: "link", onClick: function () { return setIsModalOpen(true); } }) })), ' ', _jsx(Button, { className: "text-black font-20 ml-2", icon: _jsx(PrinterOutlined, { style: { fontSize: '140%' } }), size: "large", disabled: activeKey !== 'anamnesis' || !selectedAppointment.id, type: "link", onClick: function () {
                                    generatePdf();
                                } })] })), _jsx("h3", { children: patient.firstName + ' ' + patient.lastName }), _jsx(Tabs, { activeKey: activeKey, onChange: function (key) { return setActiveKey(key); }, destroyInactiveTabPane: true, defaultActiveKey: "main-information", items: items })] }))] })));
};
export default PatientDetails;
