var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Table } from 'antd';
import { GoogleOAuthApiFactory } from 'dentobook-api-typescript-client/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { EditOutlined } from "@ant-design/icons";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { accessibilityRoles } from "../../utils/accessUtils";
import AccessControl from "../common/AccessControl";
import ClinicSearchForm from "./ClinicSearchFrom";
var GoogleCredentials = function () {
    var intl = useIntl();
    var apiConfig = new Configuration({ basePath: '/api' });
    var googleOAuthApi = GoogleOAuthApiFactory(apiConfig, '/api');
    var _a = useState(false), isAddModalVisible = _a[0], setIsAddModalVisible = _a[1];
    var _b = useState(false), isEditModalVisible = _b[0], setIsEditModalVisible = _b[1];
    var _c = useState(''), clientId = _c[0], setClientId = _c[1];
    var _d = useState(''), clientSecret = _d[0], setClientSecret = _d[1];
    var _e = useState(''), keyword = _e[0], setKeyword = _e[1];
    var _f = useState(), config = _f[0], setConfig = _f[1];
    var _g = useState([]), dataSource = _g[0], setDataSource = _g[1];
    var showAddModal = function () { return setIsAddModalVisible(true); };
    var room = {};
    var showEditModal = function () { return setIsEditModalVisible(true); };
    useEffect(function () {
        loadData();
    }, []);
    var loadData = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, googleOAuthApi.getGoogleClientConfig().then(function (response) {
                        console.log('response.data:', response.data);
                        setConfig(response.data);
                        var data = response.data;
                        setDataSource(data ? [data] : []);
                        console.log('config:', config);
                        console.log('dataSource:', dataSource);
                    })
                        .catch(function (error) {
                        console.error('Failed to fetch data:', error);
                        setDataSource([]); // Set an empty array on error
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleEditGoogleClientConfig = function () {
        if (config) {
            showEditModal();
        }
    };
    var handleGoogleClientConfig = function () { return __awaiter(void 0, void 0, void 0, function () {
        var googleClientConfigRequest, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    googleClientConfigRequest = {
                        clientId: clientId,
                        clientSecret: clientSecret
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, googleOAuthApi.setGoogleClientConfig(googleClientConfigRequest).then(function (response) {
                            loadData();
                        })
                            .catch(function (error) {
                            console.error('Failed to send clientId and secred:', error);
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleAddOk = function () {
        handleGoogleClientConfig();
        setIsAddModalVisible(false);
    };
    var handleEditOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var updatedConfig, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!config) return [3 /*break*/, 4];
                    updatedConfig = {
                        clientId: clientId,
                        clientSecret: clientSecret,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, googleOAuthApi.setGoogleClientConfig(updatedConfig).then(function (response) {
                            loadData();
                            setIsEditModalVisible(false);
                        })
                            .catch(function (error) {
                            console.error('Failed to send clientId and secred:', error);
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCancel = function () {
        setIsAddModalVisible(false);
        setIsEditModalVisible(false);
    };
    var onAddButtonClick = function () {
        showAddModal();
    };
    var modalContent = _jsx("div", __assign({ className: "anamnesis-modal-group" }, { children: _jsxs(Form, __assign({ layout: "vertical" }, { children: [_jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "clientId" }) }, { children: _jsx(Input, { value: clientId, onChange: function (e) { return setClientId(e.target.value); } }) })), _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "clientSecret" }) }, { children: _jsx(Input, { value: clientSecret, onChange: function (e) { return setClientSecret(e.target.value); } }) }))] })) }));
    return (_jsxs(AccessControl, __assign({ accessibleFor: [accessibilityRoles.CLINIC_ADMIN, accessibilityRoles.MEDICAL_DOCTOR, accessibilityRoles.MEDICAL_ASSISTANT, accessibilityRoles.RECEPTIONIST] }, { children: [_jsx(Col, { children: _jsx(ClinicSearchForm, { keyword: keyword, setKeyword: setKeyword, loadData: loadData, onAddButtonClick: onAddButtonClick }) }), _jsxs(Table, __assign({ dataSource: dataSource, pagination: false, className: "dentobook-table cabinets-table", rowKey: "clientId" }, { children: [_jsx(Table.Column, { className: 'custom-column-style', title: _jsx(FormattedMessage, { id: "googleCredentials" }), render: function (text, record) { return (_jsx("span", __assign({ className: "font-weight-500", style: { filter: 'blur(4px)', userSelect: 'none' } }, { children: record.clientId }))); } }, clientId), _jsx(Table.Column, { title: _jsx("div", {}), className: 'custom-column-style', render: function (text, record) { return (_jsx("div", __assign({ style: { display: 'unset', alignItems: 'right', float: 'right' } }, { children: _jsx(Button, { className: "text-black font-20 ml-1", type: "link", icon: _jsx(EditOutlined, {}), onClick: function () { return handleEditGoogleClientConfig(); }, style: { marginLeft: '10px' } }, "".concat(record.clientId, "-edit")) }))); } }, "actions")] })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, title: _jsx(FormattedMessage, { id: "addCredentials" }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: isAddModalVisible, onOk: handleAddOk, onCancel: handleCancel, className: "dentobook-modal" }, { children: modalContent })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, title: _jsx(FormattedMessage, { id: "editCredentials" }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: isEditModalVisible, onOk: handleEditOk, onCancel: handleCancel, className: "dentobook-modal" }, { children: modalContent }))] })));
};
export default GoogleCredentials;
