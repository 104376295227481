export const allCountries = [
    {"code": "AF", "name_bg": "Афганистан", "name_en": "Afghanistan"},
    {"code": "AL", "name_bg": "Албания", "name_en": "Albania"},
    {"code": "DZ", "name_bg": "Алжир", "name_en": "Algeria"},
    {"code": "AD", "name_bg": "Андора", "name_en": "Andorra"},
    {"code": "AO", "name_bg": "Ангола", "name_en": "Angola"},
    {"code": "AG", "name_bg": "Антигуа и Барбуда", "name_en": "Antigua and Barbuda"},
    {"code": "AR", "name_bg": "Аржентина", "name_en": "Argentina"},
    {"code": "AM", "name_bg": "Армения", "name_en": "Armenia"},
    {"code": "AU", "name_bg": "Австралия", "name_en": "Australia"},
    {"code": "AT", "name_bg": "Австрия", "name_en": "Austria"},
    {"code": "AZ", "name_bg": "Азербайджан", "name_en": "Azerbaijan"},
    {"code": "BS", "name_bg": "Бахами", "name_en": "Bahamas"},
    {"code": "BH", "name_bg": "Бахрейн", "name_en": "Bahrain"},
    {"code": "BD", "name_bg": "Бангладеш", "name_en": "Bangladesh"},
    {"code": "BB", "name_bg": "Барбадос", "name_en": "Barbados"},
    {"code": "BY", "name_bg": "Беларус", "name_en": "Belarus"},
    {"code": "BE", "name_bg": "Белгия", "name_en": "Belgium"},
    {"code": "BZ", "name_bg": "Белиз", "name_en": "Belize"},
    {"code": "BJ", "name_bg": "Бенин", "name_en": "Benin"},
    {"code": "BT", "name_bg": "Бутан", "name_en": "Bhutan"},
    {"code": "BO", "name_bg": "Боливия", "name_en": "Bolivia"},
    {"code": "BA", "name_bg": "Босна и Херцеговина", "name_en": "Bosnia and Herzegovina"},
    {"code": "BW", "name_bg": "Ботсвана", "name_en": "Botswana"},
    {"code": "BR", "name_bg": "Бразилия", "name_en": "Brazil"},
    {"code": "BN", "name_bg": "Бруней", "name_en": "Brunei"},
    {"code": "BG", "name_bg": "България", "name_en": "Bulgaria"},
    {"code": "BF", "name_bg": "Буркина Фасо", "name_en": "Burkina Faso"},
    {"code": "BI", "name_bg": "Бурунди", "name_en": "Burundi"},
    {"code": "CV", "name_bg": "Кабо Верде", "name_en": "Cabo Verde"},
    {"code": "KH", "name_bg": "Камбоджа", "name_en": "Cambodia"},
    {"code": "CM", "name_bg": "Камерун", "name_en": "Cameroon"},
    {"code": "CA", "name_bg": "Канада", "name_en": "Canada"},
    {"code": "CF", "name_bg": "Централноафриканска република", "name_en": "Central African Republic"},
    {"code": "TD", "name_bg": "Чад", "name_en": "Chad"},
    {"code": "CL", "name_bg": "Чили", "name_en": "Chile"},
    {"code": "CN", "name_bg": "Китай", "name_en": "China"},
    {"code": "CO", "name_bg": "Колумбия", "name_en": "Colombia"},
    {"code": "KM", "name_bg": "Коморски острови", "name_en": "Comoros"},
    {"code": "CG", "name_bg": "Конго", "name_en": "Congo"},
    {"code": "CD", "name_bg": "Демократична република Конго", "name_en": "Democratic Republic of the Congo"},
    {"code": "CR", "name_bg": "Коста Рика", "name_en": "Costa Rica"},
    {"code": "HR", "name_bg": "Хърватия", "name_en": "Croatia"},
    {"code": "CU", "name_bg": "Куба", "name_en": "Cuba"},
    {"code": "CY", "name_bg": "Кипър", "name_en": "Cyprus"},
    {"code": "CZ", "name_bg": "Чехия", "name_en": "Czech Republic"},
    {"code": "DK", "name_bg": "Дания", "name_en": "Denmark"},
    {"code": "DJ", "name_bg": "Джибути", "name_en": "Djibouti"},
    {"code": "DM", "name_bg": "Доминика", "name_en": "Dominica"},
    {"code": "DO", "name_bg": "Доминиканска република", "name_en": "Dominican Republic"},
    {"code": "EC", "name_bg": "Еквадор", "name_en": "Ecuador"},
    {"code": "EG", "name_bg": "Египет", "name_en": "Egypt"},
    {"code": "SV", "name_bg": "Салвадор", "name_en": "El Salvador"},
    {"code": "GQ", "name_bg": "Екваториална Гвинея", "name_en": "Equatorial Guinea"},
    {"code": "ER", "name_bg": "Еритрея", "name_en": "Eritrea"},
    {"code": "EE", "name_bg": "Естония", "name_en": "Estonia"},
    {"code": "SZ", "name_bg": "Есватини", "name_en": "Eswatini"},
    {"code": "ET", "name_bg": "Етиопия", "name_en": "Ethiopia"},
    {"code": "FJ", "name_bg": "Фиджи", "name_en": "Fiji"},
    {"code": "FI", "name_bg": "Финландия", "name_en": "Finland"},
    {"code": "FR", "name_bg": "Франция", "name_en": "France"},
    {"code": "GA", "name_bg": "Габон", "name_en": "Gabon"},
    {"code": "GM", "name_bg": "Гамбия", "name_en": "Gambia"},
    {"code": "GE", "name_bg": "Грузия", "name_en": "Georgia"},
    {"code": "DE", "name_bg": "Германия", "name_en": "Germany"},
    {"code": "GH", "name_bg": "Гана", "name_en": "Ghana"},
    {"code": "GR", "name_bg": "Гърция", "name_en": "Greece"},
    {"code": "GD", "name_bg": "Гренада", "name_en": "Grenada"},
    {"code": "GT", "name_bg": "Гватемала", "name_en": "Guatemala"},
    {"code": "GN", "name_bg": "Гвинея", "name_en": "Guinea"},
    {"code": "GW", "name_bg": "Гвинея-Бисау", "name_en": "Guinea-Bissau"},
    {"code": "GY", "name_bg": "Гаяна", "name_en": "Guyana"},
    {"code": "HT", "name_bg": "Хаити", "name_en": "Haiti"},
    {"code": "HN", "name_bg": "Хондурас", "name_en": "Honduras"},
    {"code": "HU", "name_bg": "Унгария", "name_en": "Hungary"},
    {"code": "IS", "name_bg": "Исландия", "name_en": "Iceland"},
    {"code": "IN", "name_bg": "Индия", "name_en": "India"},
    {"code": "ID", "name_bg": "Индонезия", "name_en": "Indonesia"},
    {"code": "IR", "name_bg": "Иран", "name_en": "Iran"},
    {"code": "IQ", "name_bg": "Ирак", "name_en": "Iraq"},
    {"code": "IE", "name_bg": "Ирландия", "name_en": "Ireland"},
    {"code": "IL", "name_bg": "Израел", "name_en": "Israel"},
    {"code": "IT", "name_bg": "Италия", "name_en": "Italy"},
    {"code": "JM", "name_bg": "Ямайка", "name_en": "Jamaica"},
    {"code": "JP", "name_bg": "Япония", "name_en": "Japan"},
    {"code": "JO", "name_bg": "Йордания", "name_en": "Jordan"},
    {"code": "KZ", "name_bg": "Казахстан", "name_en": "Kazakhstan"},
    {"code": "KE", "name_bg": "Кения", "name_en": "Kenya"},
    {"code": "KI", "name_bg": "Кирибати", "name_en": "Kiribati"},
    {"code": "KP", "name_bg": "Северна Корея", "name_en": "North Korea"},
    {"code": "KR", "name_bg": "Южна Корея", "name_en": "South Korea"},
    {"code": "KW", "name_bg": "Кувейт", "name_en": "Kuwait"},
    {"code": "KG", "name_bg": "Киргизстан", "name_en": "Kyrgyzstan"},
    {"code": "LA", "name_bg": "Лаос", "name_en": "Laos"},
    {"code": "LV", "name_bg": "Латвия", "name_en": "Latvia"},
    {"code": "LB", "name_bg": "Ливан", "name_en": "Lebanon"},
    {"code": "LS", "name_bg": "Лесото", "name_en": "Lesotho"},
    {"code": "LR", "name_bg": "Либерия", "name_en": "Liberia"},
    {"code": "LY", "name_bg": "Либия", "name_en": "Libya"},
    {"code": "LI", "name_bg": "Лихтенщайн", "name_en": "Liechtenstein"},
    {"code": "LT", "name_bg": "Литва", "name_en": "Lithuania"},
    {"code": "LU", "name_bg": "Люксембург", "name_en": "Luxembourg"},
    {"code": "MG", "name_bg": "Мадагаскар", "name_en": "Madagascar"},
    {"code": "MW", "name_bg": "Малави", "name_en": "Malawi"},
    {"code": "MY", "name_bg": "Малайзия", "name_en": "Malaysia"},
    {"code": "MV", "name_bg": "Малдиви", "name_en": "Maldives"},
    {"code": "ML", "name_bg": "Мали", "name_en": "Mali"},
    {"code": "MT", "name_bg": "Малта", "name_en": "Malta"},
    {"code": "MH", "name_bg": "Маршалови острови", "name_en": "Marshall Islands"},
    {"code": "MR", "name_bg": "Мавритания", "name_en": "Mauritania"},
    {"code": "MU", "name_bg": "Мавриций", "name_en": "Mauritius"},
    {"code": "MX", "name_bg": "Мексико", "name_en": "Mexico"},
    {"code": "FM", "name_bg": "Микронезия", "name_en": "Micronesia"},
    {"code": "MD", "name_bg": "Молдова", "name_en": "Moldova"},
    {"code": "MC", "name_bg": "Монако", "name_en": "Monaco"},
    {"code": "MN", "name_bg": "Монголия", "name_en": "Mongolia"},
    {"code": "ME", "name_bg": "Черна гора", "name_en": "Montenegro"},
    {"code": "MA", "name_bg": "Мароко", "name_en": "Morocco"},
    {"code": "MZ", "name_bg": "Мозамбик", "name_en": "Mozambique"},
    {"code": "MM", "name_bg": "Мианмар", "name_en": "Myanmar"},
    {"code": "NA", "name_bg": "Намибия", "name_en": "Namibia"},
    {"code": "NR", "name_bg": "Науру", "name_en": "Nauru"},
    {"code": "NP", "name_bg": "Непал", "name_en": "Nepal"},
    {"code": "NL", "name_bg": "Нидерландия", "name_en": "Netherlands"},
    {"code": "NZ", "name_bg": "Нова Зеландия", "name_en": "New Zealand"},
    {"code": "NI", "name_bg": "Никарагуа", "name_en": "Nicaragua"},
    {"code": "NE", "name_bg": "Нигер", "name_en": "Niger"},
    {"code": "NG", "name_bg": "Нигерия", "name_en": "Nigeria"},
    {"code": "MK", "name_bg": "Северна Македония", "name_en": "North Macedonia"},
    {"code": "NO", "name_bg": "Норвегия", "name_en": "Norway"},
    {"code": "OM", "name_bg": "Оман", "name_en": "Oman"},
    {"code": "PK", "name_bg": "Пакистан", "name_en": "Pakistan"},
    {"code": "PW", "name_bg": "Палау", "name_en": "Palau"},
    {"code": "PA", "name_bg": "Панама", "name_en": "Panama"},
    {"code": "PG", "name_bg": "Папуа Нова Гвинея", "name_en": "Papua New Guinea"},
    {"code": "PY", "name_bg": "Парагвай", "name_en": "Paraguay"},
    {"code": "PE", "name_bg": "Перу", "name_en": "Peru"},
    {"code": "PH", "name_bg": "Филипини", "name_en": "Philippines"},
    {"code": "PL", "name_bg": "Полша", "name_en": "Poland"},
    {"code": "PT", "name_bg": "Португалия", "name_en": "Portugal"},
    {"code": "QA", "name_bg": "Катар", "name_en": "Qatar"},
    {"code": "RO", "name_bg": "Румъния", "name_en": "Romania"},
    {"code": "RU", "name_bg": "Русия", "name_en": "Russia"},
    {"code": "RW", "name_bg": "Руанда", "name_en": "Rwanda"},
    {"code": "KN", "name_bg": "Сейнт Китс и Невис", "name_en": "Saint Kitts and Nevis"},
    {"code": "LC", "name_bg": "Сейнт Лусия", "name_en": "Saint Lucia"},
    {"code": "VC", "name_bg": "Сейнт Винсент и Гренадини", "name_en": "Saint Vincent and the Grenadines"},
    {"code": "WS", "name_bg": "Самоа", "name_en": "Samoa"},
    {"code": "SM", "name_bg": "Сан Марино", "name_en": "San Marino"},
    {"code": "ST", "name_bg": "Сао Томе и Принсипи", "name_en": "Sao Tome and Principe"},
    {"code": "SA", "name_bg": "Саудитска Арабия", "name_en": "Saudi Arabia"},
    {"code": "SN", "name_bg": "Сенегал", "name_en": "Senegal"},
    {"code": "RS", "name_bg": "Сърбия", "name_en": "Serbia"},
    {"code": "SC", "name_bg": "Сейшели", "name_en": "Seychelles"},
    {"code": "SL", "name_bg": "Сиера Леоне", "name_en": "Sierra Leone"},
    {"code": "SG", "name_bg": "Сингапур", "name_en": "Singapore"},
    {"code": "SK", "name_bg": "Словакия", "name_en": "Slovakia"},
    {"code": "SI", "name_bg": "Словения", "name_en": "Slovenia"},
    {"code": "SB", "name_bg": "Соломонови острови", "name_en": "Solomon Islands"},
    {"code": "SO", "name_bg": "Сомалия", "name_en": "Somalia"},
    {"code": "ZA", "name_bg": "Южна Африка", "name_en": "South Africa"},
    {"code": "SS", "name_bg": "Южен Судан", "name_en": "South Sudan"},
    {"code": "ES", "name_bg": "Испания", "name_en": "Spain"},
    {"code": "LK", "name_bg": "Шри Ланка", "name_en": "Sri Lanka"},
    {"code": "SD", "name_bg": "Судан", "name_en": "Sudan"},
    {"code": "SR", "name_bg": "Суринам", "name_en": "Suriname"},
    {"code": "SE", "name_bg": "Швеция", "name_en": "Sweden"},
    {"code": "CH", "name_bg": "Швейцария", "name_en": "Switzerland"},
    {"code": "SY", "name_bg": "Сирия", "name_en": "Syria"},
    {"code": "TW", "name_bg": "Тайван", "name_en": "Taiwan"},
    {"code": "TJ", "name_bg": "Таджикистан", "name_en": "Tajikistan"},
    {"code": "TZ", "name_bg": "Танзания", "name_en": "Tanzania"},
    {"code": "TH", "name_bg": "Тайланд", "name_en": "Thailand"},
    {"code": "TL", "name_bg": "Източен Тимор", "name_en": "Timor-Leste"},
    {"code": "TG", "name_bg": "Того", "name_en": "Togo"},
    {"code": "TO", "name_bg": "Тонга", "name_en": "Tonga"},
    {"code": "TT", "name_bg": "Тринидад и Тобаго", "name_en": "Trinidad and Tobago"},
    {"code": "TN", "name_bg": "Тунис", "name_en": "Tunisia"},
    {"code": "TR", "name_bg": "Турция", "name_en": "Turkey"},
    {"code": "TM", "name_bg": "Туркменистан", "name_en": "Turkmenistan"},
    {"code": "TV", "name_bg": "Тувалу", "name_en": "Tuvalu"},
    {"code": "UG", "name_bg": "Уганда", "name_en": "Uganda"},
    {"code": "UA", "name_bg": "Украйна", "name_en": "Ukraine"},
    {"code": "AE", "name_bg": "Обединени арабски емирства", "name_en": "United Arab Emirates"},
    {"code": "GB", "name_bg": "Обединено кралство", "name_en": "United Kingdom"},
    {"code": "US", "name_bg": "Съединени щати", "name_en": "United States"},
    {"code": "UY", "name_bg": "Уругвай", "name_en": "Uruguay"},
    {"code": "UZ", "name_bg": "Узбекистан", "name_en": "Uzbekistan"},
    {"code": "VU", "name_bg": "Вануату", "name_en": "Vanuatu"},
    {"code": "VE", "name_bg": "Венецуела", "name_en": "Venezuela"},
    {"code": "VN", "name_bg": "Виетнам", "name_en": "Vietnam"},
    {"code": "YE", "name_bg": "Йемен", "name_en": "Yemen"},
    {"code": "ZM", "name_bg": "Замбия", "name_en": "Zambia"},
    {"code": "ZW", "name_bg": "Зимбабве", "name_en": "Zimbabwe"}
];
