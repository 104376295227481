var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import '../assets/css/Dentobook.css';
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { OrganizationApi } from "dentobook-api-typescript-client/api";
import DetailWrapper from "../components/common/DetailWrapper";
import OrganizationDetailsView from "../components/organizations/OrganizationDetailsView";
import OrganizationForm from "../components/organizations/OrganizationForm";
import { accessibilityRoles } from "../utils/accessUtils";
import AccessControl from "../components/common/AccessControl";
var OrganizationDetails = function () {
    var id = useParams().id;
    var _a = useState({}), organization = _a[0], setOrganization = _a[1];
    var _b = useState(false), editMode = _b[0], setEditMode = _b[1];
    var _c = useState(false), isModalOpen = _c[0], setIsModalOpen = _c[1];
    var _d = useState(false), goBack = _d[0], setGoBack = _d[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var organizationApi = new OrganizationApi(apiConfig, '/api');
    useEffect(function () {
        if (id == null) {
            return;
        }
        organizationApi.fetchOrganization(id)
            .then(function (response) {
            setOrganization(response.data);
        })
            .catch(function (err) {
            console.log(err);
        });
    }, []);
    var handleOk = function () {
        setIsModalOpen(false);
        setGoBack(true);
    };
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    if (goBack) {
        return _jsx(Navigate, { to: "/organizations" });
    }
    if (!organization.id) {
        return null;
    }
    return (_jsxs(AccessControl, __assign({ accessibleFor: [accessibilityRoles.PLATFORM_ADMINISTRATOR] }, { children: [_jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, okText: _jsx(FormattedMessage, { id: "confirm" }), cancelText: _jsx(FormattedMessage, { id: "cancel" }), open: isModalOpen, onOk: handleOk, onCancel: handleCancel }, { children: _jsx("h3", __assign({ className: "dentobook-content font-weight-500 font-24 text-align-center" }, { children: _jsx(FormattedMessage, { id: "unsaved_changes" }) })) })), _jsx(DetailWrapper, __assign({ navigateBack: "/organizations" }, { children: editMode ?
                    _jsxs(_Fragment, { children: [_jsx("h2", { children: _jsx(FormattedMessage, { id: "org_edit" }) }), _jsx(OrganizationForm, { clinic: organization, openModal: function () { return setIsModalOpen(true); } })] })
                    :
                        _jsx(OrganizationDetailsView, { organization: organization, setEditMode: setEditMode }) }))] })));
};
export default OrganizationDetails;
