import dayjs from "dayjs";
export var calculateTotalPrice = function (price, discount, laboratoryPrice, cb) {
    if (!isNaN(price) && !isNaN(discount) && !isNaN(laboratoryPrice)) {
        var priceCalculation = price - (price * (discount / 100)) + laboratoryPrice;
        cb(priceCalculation);
    }
    else {
        cb(0);
    }
};
export function transliterateCyrillicToLatin(text) {
    if (!text) {
        return '';
    }
    var mapping = {
        'А': 'A', 'а': 'a',
        'Б': 'B', 'б': 'b',
        'В': 'V', 'в': 'v',
        'Г': 'G', 'г': 'g',
        'Д': 'D', 'д': 'd',
        'Е': 'E', 'е': 'e',
        'Ж': 'Zh', 'ж': 'zh',
        'З': 'Z', 'з': 'z',
        'И': 'I', 'и': 'i',
        'Й': 'Y', 'й': 'y',
        'К': 'K', 'к': 'k',
        'Л': 'L', 'л': 'l',
        'М': 'M', 'м': 'm',
        'Н': 'N', 'н': 'n',
        'О': 'O', 'о': 'o',
        'П': 'P', 'п': 'p',
        'Р': 'R', 'р': 'r',
        'С': 'S', 'с': 's',
        'Т': 'T', 'т': 't',
        'У': 'U', 'у': 'u',
        'Ф': 'F', 'ф': 'f',
        'Х': 'H', 'х': 'h',
        'Ц': 'Ts', 'ц': 'ts',
        'Ч': 'Ch', 'ч': 'ch',
        'Ш': 'Sh', 'ш': 'sh',
        'Щ': 'Sht', 'щ': 'sht',
        'Ъ': 'A', 'ъ': 'a',
        'Ь': 'Y', 'ь': 'y',
        'Ю': 'Yu', 'ю': 'yu',
        'Я': 'Ya', 'я': 'ya',
    };
    return text.split('').map(function (char) { return mapping[char] || char; }).join('');
}
export function transliterateLatinToCyrillic(text) {
    if (text === undefined) {
        return '';
    }
    var reverseMapping = {
        'A': 'А', 'a': 'а',
        'B': 'Б', 'b': 'б',
        'V': 'В', 'v': 'в',
        'G': 'Г', 'g': 'г',
        'D': 'Д', 'd': 'д',
        'E': 'Е', 'e': 'е',
        'Zh': 'Ж', 'zh': 'ж',
        'Z': 'З', 'z': 'з',
        'I': 'И', 'i': 'и',
        'Y': 'Й', 'y': 'й',
        'K': 'К', 'k': 'к',
        'L': 'Л', 'l': 'л',
        'M': 'М', 'm': 'м',
        'N': 'Н', 'n': 'н',
        'O': 'О', 'o': 'о',
        'P': 'П', 'p': 'п',
        'R': 'Р', 'r': 'р',
        'S': 'С', 's': 'с',
        'T': 'Т', 't': 'т',
        'U': 'У', 'u': 'у',
        'F': 'Ф', 'f': 'ф',
        'H': 'Х', 'h': 'х',
        'Ts': 'Ц', 'ts': 'ц',
        'Ch': 'Ч', 'ch': 'ч',
        'Sh': 'Ш', 'sh': 'ш',
        'Sht': 'Щ', 'sht': 'щ',
        'Yu': 'Ю', 'yu': 'ю',
        'Ya': 'Я', 'ya': 'я',
    };
    var regExp = new RegExp(Object.keys(reverseMapping).sort(function (a, b) { return b.length - a.length; }).join('|'), 'gi');
    return text.replace(regExp, function (match) { return reverseMapping[match] || match; });
}
export function validateEgnWithBirthDate(egn, birthDate) {
    if (!egn || !birthDate) {
        console.error("Invalid input: EGN or birth date is missing.");
        return false;
    }
    var year = parseInt(egn.substring(0, 2), 10);
    var month = parseInt(egn.substring(2, 4), 10);
    var day = parseInt(egn.substring(4, 6), 10);
    var fullYear;
    if (month > 40) {
        fullYear = 2000 + year;
        month -= 40;
    }
    else if (month > 20) {
        fullYear = 1800 + year;
        month -= 20;
    }
    else {
        fullYear = 1900 + year;
    }
    var birthDateDayjs = dayjs(birthDate);
    if (!birthDateDayjs.isValid()) {
        birthDateDayjs = dayjs(birthDate, "YYYY-MM-DD");
        if (!birthDateDayjs.isValid()) {
            console.error("Invalid birth date format.");
            return false;
        }
    }
    return birthDateDayjs.year() === fullYear &&
        birthDateDayjs.month() + 1 === month &&
        birthDateDayjs.date() === day;
}
