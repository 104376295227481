var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Modal, Table } from 'antd';
import "../../assets/css/Dentobook.css";
import "../../assets/css/PatientPhotos.css";
import { FormattedMessage, useIntl } from "react-intl";
import { DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons";
import ToothComp from "./Tooth";
import PatientTreatmentPlanModal from "./PatientTreatmentPlanModal";
import { MedicalStaffApiFactory, PatientApiFactory } from "dentobook-api-typescript-client/api";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import dayjs from "dayjs";
import { AlertContext } from "../../context/AlertContext";
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { isMobile, isMobileOnly } from "react-device-detect";
var PatientTreatmentPlanCard = function (_a) {
    var multiSelectionMode = _a.multiSelectionMode, appointment = _a.appointment, nzisActivities = _a.nzisActivities, nzisToothConditionCodes = _a.nzisToothConditionCodes, statuses = _a.statuses, patient = _a.patient, teethPositions = _a.teethPositions, fetchTeeth = _a.fetchTeeth;
    var PERMANENT_TEETH = 'permanentTeeth';
    var MILK_TEETH = 'milkTeeth';
    var intl = useIntl();
    var setVisible = useContext(AlertContext).setVisible;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState('create'), mode = _c[0], setMode = _c[1];
    var _d = useState([]), selectedTeeth = _d[0], setSelectedTeeth = _d[1];
    var _e = useState(false), isConfirmModalOpen = _e[0], setConfirmModalOpen = _e[1];
    var _f = useState(false), isTreatmentModalOpen = _f[0], setIsTreatmentModalOpen = _f[1];
    var _g = useState({}), selectedTreatment = _g[0], setSelectedTreatment = _g[1];
    var _h = useState(), treatments = _h[0], setTreatments = _h[1];
    var _j = useState(), medicalStatuses = _j[0], setMedicalStatuses = _j[1];
    var _k = useState([]), teethTreatments = _k[0], setTeethTreatments = _k[1];
    var _l = useState(), procedures = _l[0], setProcedures = _l[1];
    var _m = useState(), doctors = _m[0], setDoctors = _m[1];
    var _o = useState('00.00'), price = _o[0], setPrice = _o[1];
    var _p = useState('00.00'), laboratoryPrice = _p[0], setLaboratoryPrice = _p[1];
    var _q = useState('00.00'), discount = _q[0], setDiscount = _q[1];
    var _r = useState('00.00'), total = _r[0], setTotal = _r[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var patientApi = PatientApiFactory(apiConfig, '/api');
    var medicalApi = MedicalStaffApiFactory(apiConfig, '/api');
    var statusColors = new Map([
        ['in_progress', 'rgba(152, 200, 255, 1)'],
        ['done', 'rgba(145, 242, 145, 1)'],
        ['canceled', 'rgba(255, 175, 175, 1)'],
        ['offered', 'rgba(245, 229, 120, 1)']
    ]);
    var statusWidths = new Map([
        ['in_progress', 84],
        ['done', 98],
        ['canceled', 92],
        ['offered', 110]
    ]);
    useEffect(function () {
        if (!isTreatmentModalOpen) {
            setSelectedTeeth([]);
        }
    }, [isTreatmentModalOpen]);
    useEffect(function () {
        if (!appointment) {
            return;
        }
        loadData();
    }, []);
    useEffect(function () {
        if (!appointment) {
            return;
        }
        loadData();
    }, [appointment]);
    useEffect(function () {
        if (!multiSelectionMode && selectedTeeth.length) {
            openTreatmentModal(selectedTeeth, undefined);
        }
    }, [multiSelectionMode]);
    var loadData = function () {
        setLoading(true);
        medicalApi.fetchMedicalProcedures()
            .then(function (res) {
            var proceduresMap = new Map();
            for (var _i = 0, _a = res.data; _i < _a.length; _i++) {
                var item = _a[_i];
                proceduresMap.set(item.id, item.name);
            }
            setProcedures(proceduresMap);
            medicalApi.fetchMedicalStaff("", "name", 1, 10000)
                .then(function (res) {
                if (res.data.medicalStaff) {
                    var medicalStaffMap = new Map();
                    for (var _i = 0, _a = res.data.medicalStaff; _i < _a.length; _i++) {
                        var item = _a[_i];
                        medicalStaffMap.set(item.id, item.firstName + ' ' + item.lastName);
                    }
                    setDoctors(medicalStaffMap);
                }
                medicalApi.fetchMedicalTreatments()
                    .then(function (res) {
                    var treatmentsMap = new Map();
                    for (var _i = 0, _a = res.data; _i < _a.length; _i++) {
                        var item = _a[_i];
                        treatmentsMap.set(item.id, item);
                    }
                    setTreatments(treatmentsMap);
                    medicalApi.fetchMedicalStatuses()
                        .then(function (res) {
                        var statusesMap = new Map();
                        for (var _i = 0, _a = res.data; _i < _a.length; _i++) {
                            var item = _a[_i];
                            statusesMap.set(item.id, item);
                        }
                        setMedicalStatuses(statusesMap);
                        patientApi.fetchPatientTreatments(appointment.id)
                            .then(function (res) {
                            setTeethTreatments(res.data);
                            var priceValue = 0;
                            var discountValue = 0;
                            var laboratoryValue = 0;
                            var totalValue = 0;
                            for (var _i = 0, _a = res.data; _i < _a.length; _i++) {
                                var toothTreatment = _a[_i];
                                var proceduresPrice = 0;
                                var proceduresDiscounts = 0;
                                var proceduresLaboratoryPrice = 0;
                                var proceduresTotalPrice = 0;
                                for (var _b = 0, _c = toothTreatment.procedures || []; _b < _c.length; _b++) {
                                    var procedure = _c[_b];
                                    proceduresPrice += procedure.price;
                                    proceduresLaboratoryPrice += procedure.laboratoryPrice;
                                    proceduresDiscounts += procedure.price - procedure.price * (procedure.discount / 100);
                                    proceduresTotalPrice += procedure.price - procedure.price * (procedure.discount / 100) + procedure.laboratoryPrice;
                                }
                                priceValue += toothTreatment.price + proceduresPrice;
                                var toothTreatmentPriceWithDiscount = toothTreatment.price - toothTreatment.price * (toothTreatment.discount / 100);
                                discountValue += toothTreatmentPriceWithDiscount + proceduresDiscounts;
                                laboratoryValue += toothTreatment.laboratoryPrice + proceduresLaboratoryPrice;
                                var totalTreatmentPrice = toothTreatment.price - toothTreatment.price * (toothTreatment.discount / 100) + toothTreatment.laboratoryPrice;
                                totalValue += totalTreatmentPrice + proceduresTotalPrice;
                            }
                            setPrice(priceValue.toFixed(2));
                            setLaboratoryPrice(laboratoryValue.toFixed(2));
                            setDiscount(discountValue.toFixed(2));
                            setTotal(totalValue.toFixed(2));
                            fetchTeeth()
                                .then(function () { return setLoading(false); });
                        })
                            .catch(function (err) { return console.log(err); });
                    })
                        .catch(function (err) { return console.log(err); });
                })
                    .catch(function (err) { return console.log(err); });
            });
        })
            .catch(function (err) { return console.log(err); });
    };
    var treatmentColumns = [
        {
            title: useIntl().formatMessage({ id: 'date' }),
            dataIndex: 'date',
            key: 'date',
            sorter: function (dataOne, dataTwo) {
                var tokensOne = dataOne.date.split('.');
                var tokensTwo = dataTwo.date.split('.');
                var firstDate = tokensOne[2] + '-' + tokensOne[1] + '-' + tokensOne[0];
                var secondDate = tokensTwo[2] + '-' + tokensTwo[1] + '-' + tokensTwo[0];
                return firstDate.localeCompare(secondDate);
            },
            width: '10%'
        },
        {
            title: useIntl().formatMessage({ id: 'condition' }),
            dataIndex: 'condition',
            key: 'condition',
            width: '25%'
        },
        {
            title: useIntl().formatMessage({ id: 'tooth' }),
            dataIndex: 'tooth',
            key: 'tooth',
            sorter: function (dataOne, dataTwo) {
                if (!dataOne.tooth || !dataTwo.tooth) {
                    return 1;
                }
                return dataOne.tooth.localeCompare(dataTwo.tooth);
            },
            width: '10%'
        },
        {
            title: useIntl().formatMessage({ id: 'operation' }),
            dataIndex: 'procedure',
            key: 'procedure',
            width: '20%'
        },
        {
            title: useIntl().formatMessage({ id: 'sum' }),
            dataIndex: 'sum',
            key: 'sum',
            width: '10%'
        },
        {
            title: useIntl().formatMessage({ id: 'status' }),
            dataIndex: 'status',
            key: 'status',
            width: '14%'
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: '11%'
        },
    ];
    var procedureColumns = [
        {
            title: useIntl().formatMessage({ id: 'procedure' }),
            dataIndex: 'procedure',
            key: 'procedure'
        },
        {
            title: useIntl().formatMessage({ id: 'sum' }),
            dataIndex: 'sum',
            key: 'sum',
            width: 120
        },
        {
            title: useIntl().formatMessage({ id: 'status' }),
            dataIndex: 'status',
            key: 'status',
            width: 240
        },
    ];
    var data = teethTreatments.map(function (toothTreatment, index) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        toothTreatment.procedures = (_a = toothTreatment.procedures) === null || _a === void 0 ? void 0 : _a.map(function (pr) {
            return __assign(__assign({}, pr), { uid: uuidv4() });
        });
        var treatmentObject = treatments === null || treatments === void 0 ? void 0 : treatments.get(toothTreatment.treatmentId);
        var statusName = toothTreatment.statusId ? (_b = medicalStatuses === null || medicalStatuses === void 0 ? void 0 : medicalStatuses.get(toothTreatment.statusId)) === null || _b === void 0 ? void 0 : _b.name : '';
        var proceduresArr = (_c = toothTreatment.procedures) === null || _c === void 0 ? void 0 : _c.map(function (procedureItem) {
            var _a, _b, _c, _d;
            var procedureName = procedures === null || procedures === void 0 ? void 0 : procedures.get(procedureItem.procedureId);
            var color = statusColors.get(procedureItem.status.toLowerCase());
            return {
                key: uuidv4(),
                condition: statusName,
                tooth: (_b = (_a = toothTreatment.tooth) === null || _a === void 0 ? void 0 : _a.position) === null || _b === void 0 ? void 0 : _b.toString(),
                treatmentId: toothTreatment.id,
                procedure: _jsx("div", { children: procedureName }),
                sum: _jsx("div", { children: (((_c = procedureItem.price) === null || _c === void 0 ? void 0 : _c.toFixed(2)) || '00.00') + ' лв.' }),
                status: _jsx("div", __assign({ style: {
                        width: statusWidths.get(procedureItem.status.toLowerCase()),
                        background: color,
                        borderRadius: 6,
                        padding: 4,
                        textAlign: 'center'
                    } }, { children: _jsx(FormattedMessage, { id: ((_d = procedureItem.status) !== null && _d !== void 0 ? _d : '').toLowerCase() }) }))
            };
        });
        var toothPosition = teethPositions.filter(function (toothPos) { var _a; return toothPos.id === ((_a = toothTreatment.tooth) === null || _a === void 0 ? void 0 : _a.id); })[0];
        var doctor = doctors === null || doctors === void 0 ? void 0 : doctors.get(toothTreatment.medicalStaffId);
        var color = statusColors.get(toothTreatment.status.toLowerCase());
        if (!treatmentObject) {
            return {
                key: uuidv4(),
                condition: undefined,
                tooth: undefined,
                treatmentId: undefined,
                procedure: undefined,
                dental_doctor: undefined,
                date: undefined,
                sum: undefined,
                status: undefined,
                procedures: undefined,
                actions: undefined,
            };
        }
        return {
            key: uuidv4(),
            condition: statusName,
            tooth: (_e = (_d = toothTreatment.tooth) === null || _d === void 0 ? void 0 : _d.position) === null || _e === void 0 ? void 0 : _e.toString(),
            treatmentId: toothTreatment.id,
            procedure: _jsx("div", { children: (_f = treatmentObject.name) !== null && _f !== void 0 ? _f : '' }),
            dental_doctor: _jsx("div", { children: doctor }),
            date: dayjs(toothTreatment.date).format('DD.MM.YYYY'),
            sum: _jsx("div", { children: (((_g = toothTreatment.price) === null || _g === void 0 ? void 0 : _g.toFixed(2)) || '00.00') + ' лв.' }),
            status: _jsx("div", __assign({ style: {
                    width: statusWidths.get(toothTreatment.status.toLowerCase()),
                    background: color,
                    borderRadius: 6,
                    padding: 4,
                    textAlign: 'center'
                } }, { children: _jsx(FormattedMessage, { id: ((_h = toothTreatment.status) !== null && _h !== void 0 ? _h : '').toLowerCase() }) })),
            procedures: proceduresArr,
            actions: _jsxs("div", { children: [_jsx(Button, __assign({ className: "btn-no-border", style: { padding: 10 }, ghost: false, size: "large", onClick: function (event) {
                            event.stopPropagation();
                            openTreatmentModal(!toothPosition ? [] : [toothPosition], toothTreatment);
                        } }, { children: _jsx(EditOutlined, {}) })), ' ', _jsx(Button, __assign({ className: "btn-no-border", style: { padding: 10 }, ghost: false, size: "large", onClick: function (event) {
                            event.stopPropagation();
                            setSelectedTreatment(toothTreatment);
                            setConfirmModalOpen(true);
                        } }, { children: _jsx(DeleteOutlined, {}) }))] }),
            toothPositionId: toothPosition ? (toothPosition.id ? toothPosition.id : '') : '',
        };
    });
    var tabList = [
        {
            key: PERMANENT_TEETH,
            tab: useIntl().formatMessage({ id: 'permTeeth' }),
        },
        {
            key: MILK_TEETH,
            tab: useIntl().formatMessage({ id: 'milkTeeth' }),
        },
    ];
    var _s = useState(PERMANENT_TEETH), activeTabKey = _s[0], setActiveTabKey = _s[1];
    var openTreatmentModal = function (teethPositions, toothTreatment) {
        setSelectedTeeth(teethPositions);
        setSelectedTreatment(toothTreatment || {});
        if (toothTreatment) {
            setMode('edit');
        }
        else {
            setMode('create');
        }
        setIsTreatmentModalOpen(true);
    };
    if (loading) {
        return (_jsx("h1", { children: _jsx(FormattedMessage, { id: "loading" }) }));
    }
    var expandedRow = function (row) {
        if (!row.procedures.length) {
            return null;
        }
        return _jsx(Table, { showHeader: false, columns: procedureColumns, dataSource: row.procedures, pagination: false });
    };
    var handleConfirmOk = function () {
        if (!appointment) {
            console.log("appointment id is undefined.....");
            return;
        }
        patientApi.deletePatientTreatment(appointment.id, selectedTreatment.id)
            .then(function () {
            setVisible(true);
            setTimeout(function () { return setVisible(false); }, 10000);
            loadData();
        })
            .catch(function (err) { return console.log(err); });
        setConfirmModalOpen(false);
    };
    var handleConfirmCancel = function () {
        setSelectedTreatment({});
        setConfirmModalOpen(false);
    };
    var tableFooter = function () { return (_jsxs("div", __assign({ className: "dentobook-finances-table-footer" }, { children: [_jsxs("span", { children: [_jsx(FormattedMessage, { id: "totalPrice" }), ": ", price, " ", _jsx(FormattedMessage, { id: "levs" })] }), _jsxs("span", { children: [_jsx(FormattedMessage, { id: "discount_label" }), ": ", discount, " ", _jsx(FormattedMessage, { id: "levs" })] }), _jsxs("strong", { children: [_jsx(FormattedMessage, { id: "totalTwo" }), ": ", total, " ", _jsx(FormattedMessage, { id: "levs" })] })] }))); };
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, __assign({ transitionName: "", okText: _jsx(FormattedMessage, { id: "confirm" }), className: "dentobook-modal", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, cancelText: _jsx(FormattedMessage, { id: "cancel" }), open: isConfirmModalOpen, onOk: handleConfirmOk, onCancel: handleConfirmCancel }, { children: [_jsx("br", {}), _jsx("strong", { children: _jsx(FormattedMessage, { id: "tooth_treatment_delete_confirm" }) })] })), _jsx(PatientTreatmentPlanModal, { appointment: appointment.id, mode: mode, patient: patient, statuses: statuses, selectedTreatment: selectedTreatment, loadData: loadData, allTeethPositions: teethPositions, toothObjs: selectedTeeth, isModalOpen: isTreatmentModalOpen, setIsModalOpen: setIsTreatmentModalOpen, title: selectedTeeth.map(function (tooth) { var _a; return (_a = tooth === null || tooth === void 0 ? void 0 : tooth.position) === null || _a === void 0 ? void 0 : _a.toString(); }).join(', ') }), _jsx(Card, __assign({ tabList: tabList, rootClassName: "patient-treatment", defaultActiveTabKey: activeTabKey, onTabChange: function (key) { return setActiveTabKey(key); }, tabBarExtraContent: _jsxs(Button, __assign({ shape: "round", className: "dentobook-btn-secondary dentobook-btn-medium color-primary", type: "default", onClick: function () { return openTreatmentModal([], undefined); } }, { children: [_jsx(PlusOutlined, {}), _jsx(FormattedMessage, { id: "add" })] })), style: {
                    overflow: isMobileOnly ? 'scroll' : 'initial',
                    height: 'auto',
                    marginBottom: isMobile ? 200 : 80
                } }, { children: _jsxs("div", { children: [_jsx("div", __assign({ className: activeTabKey === 'milkTeeth' ? "milk-teeth-card" : "permanent-teeth-card" }, { children: teethPositions
                                .filter(function (toothPos) {
                                if (activeTabKey === PERMANENT_TEETH)
                                    return toothPos.position && toothPos.position < 50;
                                else
                                    return toothPos.position && toothPos.position > 50;
                            })
                                .map(function (toothPosition) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                                var tooltipMessage;
                                var color;
                                var localTreatmentCount = 0;
                                var toothTreatment;
                                if (toothPosition.toothTreatments && toothPosition.toothTreatments.length > 0) {
                                    toothTreatment = toothPosition.toothTreatments[0];
                                    if (toothPosition.toothTreatments.length > 1) {
                                        tooltipMessage = (_jsx("div", { children: toothPosition.toothTreatments.map(function (treatment, index) {
                                                var _a, _b;
                                                var treatmentName;
                                                if (treatment.treatmentId != null) {
                                                    treatmentName = (_b = (_a = treatments === null || treatments === void 0 ? void 0 : treatments.get(treatment.treatmentId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : intl.formatMessage({ id: 'treatment_name_not_found' });
                                                    localTreatmentCount++;
                                                }
                                                color = statusColors.get(treatment.status.toLowerCase());
                                                return _jsx("div", { children: "".concat(intl.formatMessage({ id: 'treatment_label' }), ": ").concat(treatmentName) }, treatment.id);
                                            }) }));
                                    }
                                    else {
                                        // Handle a single treatment.
                                        toothTreatment = toothPosition.toothTreatments[0];
                                        var treatmentName = void 0;
                                        if (toothTreatment.treatmentId != null) {
                                            treatmentName = (_b = (_a = treatments === null || treatments === void 0 ? void 0 : treatments.get(toothTreatment.treatmentId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : intl.formatMessage({ id: 'treatment_name_not_found' });
                                            localTreatmentCount++;
                                        }
                                        color = statusColors.get(toothTreatment.status.toLowerCase());
                                        tooltipMessage = "".concat(intl.formatMessage({ id: 'treatment_label' }), ": ").concat(treatmentName);
                                    }
                                }
                                else {
                                    tooltipMessage = intl.formatMessage({ id: 'no_treatment' });
                                }
                                var toothStatusesLength = (_c = toothPosition.toothStatuses) === null || _c === void 0 ? void 0 : _c.length;
                                var toothStatus = (_d = toothPosition.toothStatuses) === null || _d === void 0 ? void 0 : _d.sort(function (itemOne, itemTwo) { var _a; return (_a = itemOne.createdAt) === null || _a === void 0 ? void 0 : _a.localeCompare(itemTwo.createdAt); })[toothStatusesLength - 1];
                                var statusObject;
                                if (toothStatus && ((_e = toothPosition.toothTreatments) === null || _e === void 0 ? void 0 : _e.length) === 0) {
                                    statusObject = statuses.find(function (statusObj) { return statusObj.id === toothStatus.statusId; });
                                }
                                var showToothStatus = ((_f = toothPosition.toothTreatments) === null || _f === void 0 ? void 0 : _f.length) === 0
                                    && ((_g = toothPosition.toothStatuses) === null || _g === void 0 ? void 0 : _g.length) > 0;
                                var statusLabel = '';
                                if (showToothStatus) {
                                    statusLabel = (_h = statusObject === null || statusObject === void 0 ? void 0 : statusObject.label) !== null && _h !== void 0 ? _h : '';
                                }
                                else if (!showToothStatus && ((_j = toothPosition.toothTreatments) === null || _j === void 0 ? void 0 : _j.length) > 0) {
                                    statusLabel = (_l = (_k = toothPosition.toothTreatments) === null || _k === void 0 ? void 0 : _k.length.toString()) !== null && _l !== void 0 ? _l : '';
                                }
                                return (_jsx("button", __assign({ className: "plain-button", onKeyDown: function (e) { return e.preventDefault(); }, onClick: function () {
                                        if (multiSelectionMode && selectedTeeth.indexOf(toothPosition) === -1) {
                                            setSelectedTeeth(selectedTeeth.concat(toothPosition));
                                        }
                                        else if (multiSelectionMode && selectedTeeth.indexOf(toothPosition) > -1) {
                                            setSelectedTeeth(selectedTeeth.filter(function (tooth) { return tooth.id !== toothPosition.id; }));
                                        }
                                        else {
                                            setSelectedTeeth([]);
                                            openTreatmentModal([toothPosition], undefined);
                                        }
                                    } }, { children: _jsx(ToothComp, { position: toothPosition.position || 0, statusLabel: statusLabel, statusColor: showToothStatus ? statusObject === null || statusObject === void 0 ? void 0 : statusObject.color : color, headerColor: showToothStatus ? undefined : color, milk: activeTabKey === 'milkTeeth', selected: multiSelectionMode && selectedTeeth.indexOf(toothPosition) > -1, multiSelectionMode: multiSelectionMode, tooltipContent: tooltipMessage }, toothPosition.id) }), toothPosition.id));
                            }) })), _jsx(Table, { locale: { emptyText: intl.formatMessage({ id: 'no_data' }) }, footer: tableFooter, className: (activeTabKey === 'milkTeeth' ? "milk-teeth-table" : "permanent-teeth-table") + " teeth-table pt-2", columns: treatmentColumns, dataSource: data.filter(function (item) { return item.actions; }), expandable: {
                                expandIcon: function (props) {
                                    if (!props.expandable) {
                                        return null;
                                    }
                                    if (props.expanded) {
                                        return _jsx("button", __assign({ style: { color: 'black' }, className: "plain-button", onKeyDown: function (e) { return e.preventDefault(); }, onClick: function (e) {
                                                props.onExpand(props.record, e);
                                            } }, { children: _jsx(DownOutlined, {}) }));
                                    }
                                    return _jsx("button", __assign({ style: { color: 'black' }, className: "plain-button", onKeyDown: function (e) { return e.preventDefault(); }, onClick: function (e) {
                                            props.onExpand(props.record, e);
                                        } }, { children: _jsx(UpOutlined, {}) }));
                                },
                                expandedRowRender: expandedRow,
                                rowExpandable: function (record) { return record.procedures.length > 0; }
                            }, pagination: false, onRow: function (record) { return ({
                                onClick: function () {
                                    var toothPosition = teethPositions.find(function (toothPosition) { return toothPosition.id === record.toothPositionId; });
                                    var toothTreatment = teethTreatments.find(function (toothTreatment) { return toothTreatment.id === record.treatmentId; });
                                    openTreatmentModal(!toothPosition ? [] : [toothPosition], toothTreatment);
                                },
                            }); } })] }) }))] }));
};
export default PatientTreatmentPlanCard;
