var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Table } from 'antd';
import { DentalCabinetsApiFactory, DentalCabinetApi } from 'dentobook-api-typescript-client/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { accessibilityRoles } from "../../utils/accessUtils";
import AccessControl from "../common/AccessControl";
import ClinicSearchForm from "./ClinicSearchFrom";
var Cabinets = function () {
    var intl = useIntl();
    var apiConfig = new Configuration({ basePath: '/api' });
    var dentalCabinetApiFactory = DentalCabinetsApiFactory(apiConfig, '/api');
    var dentalCabinetApi = new DentalCabinetApi(apiConfig, '/api');
    var _a = useState(), newCabinetName = _a[0], setNewCabinetName = _a[1];
    var _b = useState([]), rooms = _b[0], setRooms = _b[1];
    var _c = useState(false), isAddModalVisible = _c[0], setIsAddModalVisible = _c[1];
    var _d = useState(false), isEditModalVisible = _d[0], setIsEditModalVisible = _d[1];
    var _e = useState(false), isDeleteModalVisible = _e[0], setIsDeleteModalVisible = _e[1];
    var _f = useState(), cabinetToDelete = _f[0], setCabinetToDelete = _f[1];
    var _g = useState(), cabinetToEdit = _g[0], setCabinetToEdit = _g[1];
    var _h = useState(''), keyword = _h[0], setKeyword = _h[1];
    var _j = useState([]), displayedRooms = _j[0], setDisplayedRooms = _j[1];
    var showAddModal = function () { return setIsAddModalVisible(true); };
    var room = {};
    var showEditModal = function () { return setIsEditModalVisible(true); };
    useEffect(function () {
        loadData();
    }, []);
    var loadData = function () {
        dentalCabinetApiFactory.fetchAllDentalCabinets()
            .then(function (response) {
            setRooms(response.data);
            setDisplayedRooms(response.data);
        })
            .catch(function (error) { return console.error("Failed to load rooms:", error); });
    };
    useEffect(function () {
        if (keyword) {
            var filteredRooms = rooms.filter(function (room) { var _a; return (_a = room.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(keyword.toLowerCase()); });
            setDisplayedRooms(filteredRooms);
        }
        else {
            setDisplayedRooms(rooms);
        }
    }, [rooms, keyword]);
    var handleDeleteCabinet = function (index) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, dentalCabinetApi.deleteDentalCabinet(index)];
                case 1:
                    _a.sent();
                    loadData();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Failed to delete room:", error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleEditCabinet = function (roomId) {
        var _a, _b;
        var roomToEdit = rooms.find(function (room) { return room.id === roomId; });
        if (roomToEdit) {
            setCabinetToEdit((_a = roomToEdit.id) !== null && _a !== void 0 ? _a : '');
            setNewCabinetName((_b = roomToEdit.name) !== null && _b !== void 0 ? _b : '');
            showEditModal();
        }
    };
    var handleDeleteOk = function () {
        if (cabinetToDelete !== undefined) {
            handleDeleteCabinet(cabinetToDelete);
            setIsDeleteModalVisible(false);
        }
    };
    var showDeleteModal = function (index) {
        setCabinetToDelete(index);
        setIsDeleteModalVisible(true);
    };
    var handleCreateRoom = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    room.name = newCabinetName;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, dentalCabinetApi.createDentalCabinet(room)];
                case 2:
                    _a.sent();
                    loadData();
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error("Failed to create room:", error_2);
                    return [3 /*break*/, 4];
                case 4:
                    setNewCabinetName('');
                    return [2 /*return*/];
            }
        });
    }); };
    var handleAddOk = function () {
        handleCreateRoom();
        console.log(room);
        setIsAddModalVisible(false);
    };
    var handleEditOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var updatedRoom, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!cabinetToEdit) return [3 /*break*/, 4];
                    updatedRoom = {
                        id: cabinetToEdit,
                        name: newCabinetName,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, dentalCabinetApi.updateDentalCabinet(cabinetToEdit, updatedRoom)];
                case 2:
                    _a.sent();
                    loadData();
                    setIsEditModalVisible(false);
                    setNewCabinetName('');
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.error("Failed to update room:", error_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCancel = function () {
        setIsAddModalVisible(false);
        setIsEditModalVisible(false);
        setIsDeleteModalVisible(false);
        setNewCabinetName('');
    };
    var onAddButtonClick = function () {
        showAddModal();
    };
    var modalContent = _jsx("div", __assign({ className: "anamnesis-modal-group" }, { children: _jsx(Form, __assign({ layout: "vertical" }, { children: _jsx(Form.Item, __assign({ className: "dentobook-form-item dentobook-simple-input", label: _jsx(FormattedMessage, { id: "name" }) }, { children: _jsx(Input, { value: newCabinetName, onChange: function (e) { return setNewCabinetName(e.target.value); } }) })) })) }));
    return (_jsxs(AccessControl, __assign({ accessibleFor: [accessibilityRoles.CLINIC_ADMIN, accessibilityRoles.MEDICAL_DOCTOR, accessibilityRoles.MEDICAL_ASSISTANT, accessibilityRoles.RECEPTIONIST] }, { children: [_jsx(Col, { children: _jsx(ClinicSearchForm, { keyword: keyword, setKeyword: setKeyword, loadData: loadData, onAddButtonClick: onAddButtonClick }) }), _jsxs(Table, __assign({ dataSource: displayedRooms, pagination: false, className: "dentobook-table cabinets-table" }, { children: [_jsx(Table.Column, { className: 'custom-column-style', title: _jsx(FormattedMessage, { id: "cabinets" }), render: function (room) { return _jsx("span", __assign({ className: "font-weight-500" }, { children: room.name }), room.name); }, sorter: function (roomOne, roomTwo) { return roomOne.name.localeCompare(roomTwo.name); } }, room.id), _jsx(Table.Column, { title: _jsx("div", {}), className: 'custom-column-style', render: function (room) {
                            return (_jsxs("div", __assign({ style: { display: 'unset', alignItems: 'right', float: 'right' } }, { children: [_jsx(Button, { className: "text-black font-20 ml-1", type: "link", icon: _jsx(EditOutlined, {}), onClick: function () { return handleEditCabinet(room.id); }, style: { marginRight: '10px' } }, room.id + "-edit"), _jsx(Button, { className: "text-black font-20", type: "link", icon: _jsx(DeleteOutlined, {}), onClick: function () { return showDeleteModal(room.id); } }, room.id + "-delete")] })));
                        } }, room.id)] })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, title: _jsx(FormattedMessage, { id: "addCabinet" }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: isAddModalVisible, onOk: handleAddOk, onCancel: handleCancel, className: "dentobook-modal" }, { children: modalContent })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, title: _jsx(FormattedMessage, { id: "editCabinet" }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: isEditModalVisible, onOk: handleEditOk, onCancel: handleCancel, className: "dentobook-modal" }, { children: modalContent })), _jsx(Modal, __assign({ transitionName: "", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, className: "dentobook-modal", title: _jsx(FormattedMessage, { id: "deleteCabinet" }), okText: intl.formatMessage({ id: 'save' }), cancelText: intl.formatMessage({ id: 'cancel' }), open: isDeleteModalVisible, onOk: handleDeleteOk, onCancel: handleCancel }, { children: _jsx("p", { children: _jsx(FormattedMessage, { id: "cabinetDeleteConfirmation" }) }) }))] })));
};
export default Cabinets;
