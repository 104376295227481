var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Modal, Table } from 'antd';
import "../../assets/css/Dentobook.css";
import "../../assets/css/PatientPhotos.css";
import { FormattedMessage, useIntl } from "react-intl";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import ToothComp from "./Tooth";
import PatientTreatmentStatusModal from "./PatientTreatmentStatusModal";
import { Configuration } from "dentobook-api-typescript-client/configuration";
import { PatientApiFactory } from "dentobook-api-typescript-client/api";
import { AlertContext } from "../../context/AlertContext";
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { isMobile, isMobileOnly } from "react-device-detect";
var PatientToothStatusCard = function (_a) {
    var multiSelectionMode = _a.multiSelectionMode, appointment = _a.appointment, nzisToothConditionCodes = _a.nzisToothConditionCodes, teethPositions = _a.teethPositions, fetchTeeth = _a.fetchTeeth, statuses = _a.statuses;
    var MILK_TEETH = 'milkTeeth';
    var PERMANENT_TEETH = 'permanentTeeth';
    var intl = useIntl();
    var setVisible = useContext(AlertContext).setVisible;
    var _b = useState('create'), mode = _b[0], setMode = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), isTreatmentModalOpen = _d[0], setIsTreatmentModalOpen = _d[1];
    var _e = useState(false), isConfirmModalOpen = _e[0], setConfirmModalOpen = _e[1];
    var _f = useState([]), selectedTeeth = _f[0], setSelectedTeeth = _f[1];
    var _g = useState([]), selectedToothStatuses = _g[0], setSelectedToothStatuses = _g[1];
    var _h = useState({}), selectedTableStatus = _h[0], setSelectedTableStatus = _h[1];
    var _j = useState([]), teethStatuses = _j[0], setTeethStatuses = _j[1];
    var apiConfig = new Configuration({ basePath: '/api' });
    var patientApi = PatientApiFactory(apiConfig, '/api');
    useEffect(function () {
        if (!appointment) {
            return;
        }
        loadData();
    }, []);
    useEffect(function () {
        if (!appointment) {
            return;
        }
        loadData();
    }, [appointment]);
    useEffect(function () {
        if (!multiSelectionMode && selectedTeeth.length) {
            openTreatmentModal(selectedTeeth, undefined);
        }
    }, [multiSelectionMode]);
    useEffect(function () {
        if (!isTreatmentModalOpen) {
            setSelectedTeeth([]);
        }
    }, [isTreatmentModalOpen]);
    var loadData = function () {
        if (!appointment.id) {
            return;
        }
        setLoading(true);
        patientApi.fetchPatientToothStatuses(appointment.id)
            .then(function (res) {
            setTeethStatuses(res.data);
            fetchTeeth()
                .then(function () { return setLoading(false); });
        })
            .catch(function (err) { return console.log(err); });
    };
    var columns = [
        {
            title: useIntl().formatMessage({ id: 'date' }),
            dataIndex: 'date',
            key: 'date',
            sorter: function (dataOne, dataTwo) {
                var tokensOne = dataOne.date.split('.');
                var tokensTwo = dataTwo.date.split('.');
                var firstDate = tokensOne[2] + '-' + tokensOne[1] + '-' + tokensOne[0];
                var secondDate = tokensTwo[2] + '-' + tokensTwo[1] + '-' + tokensTwo[0];
                return firstDate.localeCompare(secondDate);
            },
            width: '15%'
        },
        {
            title: useIntl().formatMessage({ id: 'condition' }),
            dataIndex: 'condition',
            key: 'condition',
            width: '35%'
        },
        {
            title: useIntl().formatMessage({ id: 'tooth' }),
            dataIndex: 'tooth',
            key: 'tooth',
            sorter: function (dataOne, dataTwo) { return dataOne.tooth.localeCompare(dataTwo.tooth); },
            width: '39%'
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: '11%'
        },
    ];
    var data = teethStatuses
        .sort(function (toothStatusOne, toothStatusTwo) {
        var _a, _b, _c, _d;
        if (!((_a = toothStatusOne.tooth) === null || _a === void 0 ? void 0 : _a.id) || !((_b = toothStatusTwo.tooth) === null || _b === void 0 ? void 0 : _b.id)) {
            return 0;
        }
        return (_c = toothStatusOne.tooth) === null || _c === void 0 ? void 0 : _c.id.localeCompare((_d = toothStatusTwo.tooth) === null || _d === void 0 ? void 0 : _d.id);
    }).map(function (toothStatus) {
        var _a, _b, _c;
        if (!teethPositions.length || !statuses.length) {
            return undefined;
        }
        var toothPosition = teethPositions.filter(function (toothPosition) { var _a; return toothPosition.id === ((_a = toothStatus.tooth) === null || _a === void 0 ? void 0 : _a.id); })[0];
        var status = statuses.filter(function (statusObject) { return statusObject.id === toothStatus.statusId; });
        if (status.length === 0) {
            return undefined;
        }
        var toothPositionNumber = toothPosition ? (_a = toothPosition.position) === null || _a === void 0 ? void 0 : _a.toString() : '';
        var tokens = (_b = toothStatus.createdAt) === null || _b === void 0 ? void 0 : _b.split('T')[0].split('-');
        var toothPositionDate;
        if (!tokens) {
            toothPositionDate = '';
        }
        else {
            toothPositionDate = tokens[2] + '.' + tokens[1] + '.' + tokens[0];
        }
        return {
            key: uuidv4(),
            date: toothPositionDate,
            conditionId: status[0].id,
            condition: status[0].name,
            tooth: toothPositionNumber,
            actions: _jsxs("div", __assign({ style: { display: 'flex', flexDirection: 'row' } }, { children: [_jsx(Button, __assign({ className: "btn-no-border", ghost: false, size: "large", style: { padding: 10 }, onClick: function (event) {
                            event.stopPropagation();
                            openTreatmentModal(!toothPosition ? [] : [toothPosition], toothStatus);
                        } }, { children: _jsx(EditOutlined, {}) })), ' ', _jsx(Button, __assign({ className: "btn-no-border", style: { padding: 10 }, ghost: false, size: "large", onClick: function (event) {
                            event.stopPropagation();
                            openConfirmModal(toothStatus);
                        } }, { children: _jsx(DeleteOutlined, {}) }))] })),
            toothPositionId: (_c = toothStatus.tooth) === null || _c === void 0 ? void 0 : _c.id,
        };
    })
        .filter(function (data) { return typeof data !== 'undefined'; });
    var tabList = [
        {
            key: PERMANENT_TEETH,
            tab: useIntl().formatMessage({ id: 'permTeeth' }),
        },
        {
            key: MILK_TEETH,
            tab: useIntl().formatMessage({ id: 'milkTeeth' }),
        },
    ];
    var _k = useState(PERMANENT_TEETH), activeTabKey = _k[0], setActiveTabKey = _k[1];
    var openTreatmentModal = function (teethPositions, toothStatus) {
        if (teethPositions.length === 0) {
            if (toothStatus) {
                var toothStatusesArray_1 = teethStatuses
                    .filter(function (statusObj) { return !statusObj.tooth && statusObj.statusId === toothStatus.statusId; })
                    .map(function (item) { return item.statusId; });
                var statusesArray = statuses
                    .filter(function (statusObj) { return toothStatusesArray_1.indexOf(statusObj.id) > -1; });
                setMode('edit');
                setSelectedTableStatus(toothStatus);
                setSelectedToothStatuses(statusesArray);
            }
            else {
                setMode('create');
            }
            setSelectedTeeth([]);
        }
        else if (teethPositions.length === 1 && teethPositions[0] && teethPositions[0].id) {
            if (toothStatus) {
                var toothStatusesArray_2 = teethStatuses
                    .filter(function (statusObj) {
                    var _a, _b;
                    return ((_a = statusObj.tooth) === null || _a === void 0 ? void 0 : _a.id) === ((_b = toothStatus.tooth) === null || _b === void 0 ? void 0 : _b.id)
                        && statusObj.statusId === toothStatus.statusId;
                })
                    .map(function (item) { return item.statusId; });
                var statusesArray = statuses
                    .filter(function (statusObj) { return toothStatusesArray_2.indexOf(statusObj.id) > -1; });
                setMode('edit');
                setSelectedTableStatus(toothStatus);
                setSelectedToothStatuses(statusesArray);
            }
            else {
                setMode('create');
            }
            setSelectedTeeth([teethPositions[0]]);
        }
        else {
            setMode('create');
            setSelectedTeeth(teethPositions);
        }
        setIsTreatmentModalOpen(true);
    };
    var openConfirmModal = function (toothStatus) {
        setConfirmModalOpen(true);
        setSelectedTableStatus(toothStatus);
    };
    if (loading) {
        return (_jsx("h1", { children: _jsx(FormattedMessage, { id: "loading" }) }));
    }
    var handleConfirmOk = function () {
        setConfirmModalOpen(false);
        if (!appointment) {
            return;
        }
        patientApi.deleteToothStatus(appointment.id, selectedTableStatus.id)
            .then(function () {
            setVisible(true);
            setTimeout(function () { return setVisible(false); }, 10000);
            loadData();
        })
            .catch(function (err) { return console.log(err); });
    };
    var handleConfirmCancel = function () {
        setConfirmModalOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, __assign({ transitionName: "", okText: _jsx(FormattedMessage, { id: "confirm" }), className: "dentobook-modal", okButtonProps: {
                    className: "modal-btn"
                }, cancelButtonProps: {
                    className: "modal-btn"
                }, cancelText: _jsx(FormattedMessage, { id: "cancel" }), open: isConfirmModalOpen, onOk: handleConfirmOk, onCancel: handleConfirmCancel }, { children: [_jsx("br", {}), _jsx("strong", { children: _jsx(FormattedMessage, { id: "tooth_status_delete_confirm" }) })] })), _jsx(PatientTreatmentStatusModal, { allTeethPositions: teethPositions, mode: mode, selectedTableStatus: selectedTableStatus, appointment: appointment.id, toothObjs: selectedTeeth, loadData: loadData, allTeethStatuses: teethStatuses, selectedStatuses: selectedToothStatuses, statuses: statuses, isModalOpen: isTreatmentModalOpen, setIsModalOpen: setIsTreatmentModalOpen, title: selectedTeeth.map(function (tooth) { var _a; return (_a = tooth === null || tooth === void 0 ? void 0 : tooth.position) === null || _a === void 0 ? void 0 : _a.toString(); }).join(', ') }), _jsx(Card, __assign({ tabList: tabList, rootClassName: "patient-treatment", defaultActiveTabKey: activeTabKey, onTabChange: function (key) { return setActiveTabKey(key); }, tabBarExtraContent: _jsxs(Button, __assign({ shape: "round", className: "dentobook-btn-secondary dentobook-btn-medium color-primary", type: "default", onClick: function () { return openTreatmentModal([], undefined); } }, { children: [_jsx(PlusOutlined, {}), _jsx(FormattedMessage, { id: "add" })] })), style: {
                    overflow: isMobileOnly ? 'scroll' : 'initial',
                    height: 'auto',
                    marginBottom: isMobile ? 200 : 80
                } }, { children: _jsxs("div", { children: [_jsx("div", __assign({ className: activeTabKey === MILK_TEETH ? "milk-teeth-card" : "permanent-teeth-card" }, { children: teethPositions
                                .filter(function (toothPos) {
                                if (activeTabKey === PERMANENT_TEETH)
                                    return toothPos.position && toothPos.position < 50;
                                else
                                    return toothPos.position && toothPos.position > 50;
                            })
                                .map(function (toothPosition) {
                                var _a, _b;
                                var toothStatus = (_a = toothPosition.toothStatuses) === null || _a === void 0 ? void 0 : _a.sort(function (statusOne, statusTwo) { var _a; return (_a = statusOne.createdAt) === null || _a === void 0 ? void 0 : _a.localeCompare(statusTwo.createdAt); })[((_b = toothPosition.toothStatuses) === null || _b === void 0 ? void 0 : _b.length) - 1];
                                var statusObject;
                                if (toothStatus) {
                                    statusObject = statuses.find(function (statusObj) { return statusObj.id === toothStatus.statusId; });
                                }
                                var tooltipMessage;
                                if (toothPosition.toothStatuses && toothPosition.toothStatuses.length > 1) {
                                    tooltipMessage = (_jsxs("div", { children: [toothPosition.toothStatuses.map(function (status, index) {
                                                var foundStatusObject = statuses.find(function (statusObj) { return statusObj.id === status.statusId; });
                                                return foundStatusObject && (_jsx("div", { children: "".concat(intl.formatMessage({ id: 'condition' }), ": ").concat(foundStatusObject.name) }, status.id));
                                            }), !toothPosition.toothStatuses.length &&
                                                _jsx("div", { children: intl.formatMessage({ id: 'no_diagnose' }) })] }));
                                }
                                else {
                                    // For a single status or no status, use the previously found statusObject.
                                    tooltipMessage = statusObject
                                        ? "".concat(intl.formatMessage({ id: 'condition' }), ": ").concat(statusObject.name)
                                        : intl.formatMessage({ id: 'no_diagnose' });
                                }
                                return (_jsx("button", __assign({ className: "plain-button", onKeyDown: function (e) { return e.preventDefault(); }, onClick: function () {
                                        if (multiSelectionMode && selectedTeeth.indexOf(toothPosition) === -1) {
                                            setSelectedTeeth(selectedTeeth.concat(toothPosition));
                                        }
                                        else if (multiSelectionMode && selectedTeeth.indexOf(toothPosition) > -1) {
                                            setSelectedTeeth(selectedTeeth.filter(function (tooth) { return tooth.id !== toothPosition.id; }));
                                        }
                                        else {
                                            setSelectedTeeth([]);
                                            openTreatmentModal(!toothPosition ? [] : [toothPosition], undefined);
                                        }
                                    } }, { children: _jsx("button", __assign({ onKeyDown: function (e) { return e.preventDefault(); }, className: "plain-button", onClick: function () {
                                            if (multiSelectionMode && selectedTeeth.indexOf(toothPosition) === -1) {
                                                setSelectedTeeth(selectedTeeth.concat(toothPosition));
                                            }
                                            else if (multiSelectionMode && selectedTeeth.indexOf(toothPosition) > -1) {
                                                setSelectedTeeth(selectedTeeth.filter(function (tooth) { return tooth.id !== toothPosition.id; }));
                                            }
                                            else {
                                                setSelectedTeeth([]);
                                                openTreatmentModal(!toothPosition ? [] : [toothPosition], undefined);
                                            }
                                        } }, { children: _jsx(ToothComp, { position: toothPosition.position || 0, statusLabel: statusObject === null || statusObject === void 0 ? void 0 : statusObject.label, selected: multiSelectionMode && selectedTeeth.indexOf(toothPosition) > -1, statusColor: statusObject === null || statusObject === void 0 ? void 0 : statusObject.color, milk: activeTabKey === MILK_TEETH, multiSelectionMode: multiSelectionMode, tooltipContent: tooltipMessage }, toothPosition.id) }), toothPosition.id) }), toothPosition.id));
                            }) })), _jsx(Table, { locale: { emptyText: intl.formatMessage({ id: 'no_data' }) }, className: "".concat(activeTabKey === MILK_TEETH ? "milk-teeth-table" : "permanent-teeth-table", " teeth-table pt-2"), pagination: false, columns: columns, dataSource: data, onRow: function (record) { return ({
                                onClick: function () {
                                    var toothPosition = teethPositions.find(function (tp) { return tp.id === record.toothPositionId; });
                                    var toothStatus = teethStatuses.find(function (toothStatus) {
                                        var _a;
                                        return ((_a = toothStatus.tooth) === null || _a === void 0 ? void 0 : _a.id) === record.toothPositionId
                                            && toothStatus.statusId === record.conditionId;
                                    });
                                    openTreatmentModal(!toothPosition ? [] : [toothPosition], toothStatus);
                                },
                            }); } })] }) }))] }));
};
export default PatientToothStatusCard;
