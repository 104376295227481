import * as React from 'react';
export var AlertContext = React.createContext({
    error: '',
    setError: function () { },
    visible: false,
    setVisible: function () { },
    successMessage: '',
    setSuccessMessage: function () { },
    redirectUrl: null,
    setRedirectUrl: function () { }
});
